import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
import { AuthenticationService, UserService } from '@app/core/services';

const log = new Logger('ForgetPassword');

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {
  error: string;
  isLoading: boolean = false;
  activateUserForm: FormGroup;
  apiDetails: any;
  userData: any;
  step: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.userData = this.userService.getTempNewUserData();
    this.userService.setTempNewUserData('');
    if (!this.userData) {
      this.router.navigate(['register']);
    }
    this.activateUserForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
    this.getPin();
  }
  ngOnInit() {
    this.step = 1;
  }
  getPin() {
    this.isLoading = true;
    try {
      this.authenticationService
        .forgetPassword({ email: this.userData.email })
        .subscribe(
          (response: any) => {
            this.isLoading = false;
            if (!response.success) {
              log.debug(`Forget Password error:`, response.details);
              this.error = response.details;
              return;
            }
            this.apiDetails = response;
            this.error = undefined;
            this.userService.setTempNewUserData('');
            this.isLoading = false;
          },
          (e: any) => {
            this.isLoading = false;
            log.debug(`Forget Password error:`, e);
            this.error = e.error.detail;
            this.step = 3;
          }
        );
    } catch (e) {
      this.error = e;
      this.step = 3;
    }
  }
  createUser() {
    this.isLoading = true;
    try {
      const form = this.activateUserForm.value;
      const payload = {
        token: this.apiDetails.token,
        pinPrefix: this.apiDetails.pinPrefix,
        password: this.userData.password,
        pin: form.code
      };

      this.authenticationService.resetPassword(payload).subscribe(
        (response: any) => {
          this.isLoading = false;
          if (!response.success) {
            log.debug(`doActionCode error:`, response.details);
            this.error = response.details;
            return;
          }
          this.step = 2;
          this.error = undefined;
        },
        (e: any) => {
          this.isLoading = false;
          log.debug(`doActionCode error:`, e);
          this.error = e.error.detail;
          this.step = 3;
        }
      );
    } catch (e) {
      this.error = e;
      this.step = 3;
    }
  }
}
