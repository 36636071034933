import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

import {
  WorkflowService,
  DocumentService,
  WORKFLOW_STEP_STATUS,
  WORKFLOW_STATUS,
  AuthorizationService,
  WorkflowBuildService
} from '@app/core/services';
import { Logger, WorkflowContact, WorkflowFile } from '@app/core/class';

import { AlertSubscription } from '@app/core/subscriptions';
import * as moment from 'moment';
import { debounce, Cancelable } from 'lodash';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';

const log = new Logger('WorkflowListPage');

@Component({
  selector: 'app-workflow-list-page',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListPageComponent implements OnInit {
  /**
   * template of modal for loading
   */
  @ViewChild('modalLoading') loading: ElementRef;

  @ViewChild('modalCancel') modalCancel: ElementRef;

  /**
   * collection with data
   */
  collectionDocument: any;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for pagination
   */
  pagination: any;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current user for edit/delete
   */
  item: any;

  /**
   * Data User logged
   */
  userLogged: any;

  /**
   * modal
   */
  modalRef: any;

  details: any;
  loadingDetails: boolean = false;

  currentArea: string;
  currentPage: number;
  currentRoute: string;
  currentSort: string;
  currentSortBy: string;
  currentPeriodFilter: string;

  statusFilterConfig: Array<any>;

  /**
   * Status workflow-step
   */
  yourStatusStepConfig: Array<any>;

  /**
   * Status workflow
   */
  statusWorkflowConfig: Array<any>;

  queryStringFilter: any = {};

  searchField: string = '';
  searchStatus: number | string = '';
  searchPeriod: number | string = '';

  statusWorkflow: number | string = '';
  yourStatusStep: number | string = '';

  searchDebounce: (() => void) & Cancelable;

  isReceived: boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private workflowService: WorkflowService,
    private workflowBuildService: WorkflowBuildService,
    private documentService: DocumentService,
    private alertSubscription: AlertSubscription,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.collectionDocument = { items: [] };
    this.modalRef = { ref: null, data: { text: '' } };
    this.pagination = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
    this.currentRoute = '/manager/manage/workflow';

    this.searchDebounce = debounce(this.search.bind(this), 300);
  }

  ngOnInit() {
    this.workflowService.endpoint = '/workflow-step';
    if (this.route.snapshot.data['isReceived']) {
      this.isReceived = true;
    }
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );

    this.authorizationService.user.subscribe((user: any) => {
      this.userLogged = user;
    });

    combineLatest(this.route.params, this.route.queryParams)
      .pipe(map((params, qparams) => ({ params, qparams })))
      .subscribe((p: any) => {
        this.currentArea = p.params[0].area || '';

        if (p.params.page) {
          this.currentPage = p.params.page;
        }

        if (p.params.sort) {
          this.currentSort = p.params.sort;
        }

        if (p.params.sortBy) {
          this.currentSortBy = p.params.sortBy;
        }

        if (this.route.snapshot.queryParamMap.has('filter')) {
          this.searchStatus = parseInt(
            this.route.snapshot.queryParamMap.get('filter')
          );
          if (this.searchStatus < 0) {
            this.searchStatus = '';
          }
          this.search();
        } else {
          this.getData(
            {
              area: this.currentArea,
              page: p.qparams.page,
              sortBy: p.qparams.sortBy,
              sort: p.qparams.sort
            },
            false
          );
        }
      });

    this.sortFields = { id: 'DESC', status: 'DESC' };
  }

  checkCurrentArea() {
    return this.currentArea !== 'me';
  }

  /**
   * provides the data of workflows with params
   *
   * @return void
   */
  getData(params: any, isFiltered: boolean = true) {
    const payload = {
      page: params.page ? params.page : 1,
      area: params.area ? params.area : 'all',
      sortBy: params.sortBy ? params.sortBy : 'id',
      sort: params.sort ? params.sort : 'desc'
    };
    if (payload.area === 'me') {
      this.getMyWorkflows(payload, isFiltered);
    } else {
      this.getWorkflows(payload, isFiltered);
    }
  }

  search(page: number = 1) {
    this.queryStringFilter = {};

    const filter = [];
    const sort = [];

    const params = { page, area: 'me' };

    if (this.currentArea !== 'me') {
      delete params.area;
    }

    if (this.searchPeriod) {
      const format = 'YYYYMMDD';

      const from = moment();

      from.subtract(this.searchPeriod, 'days');

      const value = from.format(format) + '000000';

      if (this.currentArea !== 'me') {
        filter.push({ type: 'innerjoin', field: 'workflow', alias: 'wd' });

        filter.push({
          alias: 'wd',
          type: 'gte',
          field: 'dateCreated',
          value,
          direction: 'desc'
        });
      } else {
        filter.push({
          type: 'gte',
          field: 'dateCreated',
          value,
          direction: 'desc'
        });
      }
    }

    if (this.searchField) {
      if (this.currentArea !== 'me') {
        filter.push({
          type: 'innerjoin',
          field: 'workflow',
          alias: 'ws'
        });
        if (!isNaN(parseInt(this.searchField))) {
          filter.push({
            type: 'eq',
            alias: 'ws',
            field: 'id',
            value: this.searchField
          });
        } else {
          filter.push({
            type: 'like',
            alias: 'ws',
            field: 'name',
            value: `%${this.searchField}%`
          });
        }
      } else {
        if (!isNaN(parseInt(this.searchField))) {
          filter.push({
            type: 'eq',
            field: 'id',
            value: this.searchField
          });
        } else {
          filter.push({
            type: 'like',
            field: 'name',
            value: `%25${this.searchField}%25`
          });
        }
      }
    }

    if (this.yourStatusStep) {
      filter.push({
        type: 'eq',
        field: 'status',
        value: this.yourStatusStep,
        direction: 'desc'
      });
    }

    if (this.statusWorkflow) {
      filter.push({
        type: 'eq',
        field: 'workflow',
        alias: 'w',
        child: 'status',
        value: this.statusWorkflow,
        direction: 'desc'
      });
    }

    if (this.searchStatus) {
      filter.push({
        type: 'eq',
        field: 'status',
        value: this.searchStatus,
        direction: 'desc'
      });
    }

    if (filter.length > 0) {
      sort.push({ type: 'field', field: 'id', direction: 'desc' });
    }

    this.queryStringFilter = {
      filter,
      'order-by': sort,
      page: 1
    };

    this.getData(params, filter.length > 0);
  }

  /**
   * provides the data of workflows with me
   *
   * @return void
   */
  getWorkflows(params: any, isFiltered: boolean) {
    try {
      let queryString;
      this.configList = [
        {
          name: '',
          klass: '',
          type: 'icon',
          fieldId: 'workflow.status'
        },
        {
          name: 'ID',
          klass: '',
          type: 'text',
          fieldId: 'workflow.id'
        },
        {
          name: 'Criado por',
          klass: '',
          type: 'text',
          fieldId: 'workflow.user.name'
        },
        {
          name: 'Nome',
          klass: '',
          type: 'text',
          fieldId: 'workflow.name'
        },
        {
          name: 'Data',
          klass: '',
          type: 'text',
          fieldId: 'workflow.date.dateCreated.formated_date',
          fieldKey: 'id',
          isSortable: true
        },
        {
          name: 'Seu status',
          klass: '',
          type: 'status',
          fieldId: 'step.status',
          fieldKey: 'status',
          isSortable: true
        },
        {
          name: 'Status do fluxo',
          klass: '',
          type: 'status',
          fieldId: 'workflow.status',
          isSortable: true
        },
        {
          name: 'Ações',
          type: 'custom-select',
          icon: 'fa fa-sliders-h',
          width: '5%',
          isRestrict: true,
          options: {
            changeLabel: true,
            items: [
              {
                name: 'Assinar',
                action: 'sign',
                canField: 'step.status.id',
                can: [WORKFLOW_STEP_STATUS.WAITING]
              },
              {
                name: 'Detalhes',
                action: 'view',
                canField: 'step.status.id',
                can: [
                  WORKFLOW_STEP_STATUS.APPROVED,
                  WORKFLOW_STEP_STATUS.WAITING
                ]
              },
              {
                name: 'Detalhes',
                action: 'view',
                canField: 'workflow.status.id',
                can: [WORKFLOW_STATUS.CANCELLED]
              },
              {
                name: 'Baixar Documentos',
                action: 'download',
                canField: 'workflow.status.id',
                can: [WORKFLOW_STATUS.COMPLETED]
              },
              {
                name: 'Baixar Página de Assinaturas',
                action: 'download-info',
                canField: 'workflow.status.id',
                can: [WORKFLOW_STATUS.COMPLETED]
              },
              {
                name: 'Iniciar Workflow',
                action: 'init',
                canField: 'workflow.status.id',
                can: [WORKFLOW_STATUS.DRAFT]
              }
            ]
          }
        }
      ];

      this.statusWorkflowConfig = [
        {
          value: '',
          label: 'Status do fluxo'
        },
        {
          value: 6,
          label: 'Assinado'
        },
        {
          value: 1,
          label: 'Aguardando assinatura'
        },
        {
          value: 2,
          label: 'Cancelado'
        },
        {
          value: 3,
          label: 'Expirado'
        },
        {
          value: 4,
          label: 'Rejeitado'
        }
      ];

      this.yourStatusStepConfig = [
        {
          value: '',
          label: 'Seu Status'
        },
        {
          value: 4,
          label: 'Assinado'
        },
        {
          value: 1,
          label: 'Aguardando assinatura'
        },
        {
          value: 5,
          label: 'Rejeitado'
        },
        {
          value: 3,
          label: 'Processando'
        }
      ];

      if (!isFiltered) {
        queryString = {
          'order-by': [
            {
              type: 'field',
              field: params.sortBy,
              direction: params.sort
            }
          ],
          page: params.page
        };
      } else {
        queryString = this.queryStringFilter;
        queryString.page = params.page;
      }

      this.workflowService.getAll(queryString).subscribe(
        (response: any) => {
          log.debug(`Success [getWorkflows]:`, response);
          response.items = response.items.map((item: any) => {
            item.step.status.class = item.step.status.text.action;
            if (item.workflow.status.id === 2) {
              item.step.status.class = `${
                item.step.status.text.action
              } cancelled-line-through`;
            }
            return item;
          });
          this.collectionDocument = response;
          this.pagination = {
            totalItems: response.total_items,
            pageCount: response.page_count,
            pageSize: response.page_size,
            currentPage: response.page
          };
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
        },
        (error: any) => {
          log.debug(`Error [getWorkflows]:`, error);
          this.collectionDocument = { items: [] };
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      log.debug(`Error [getWorkflows]:`, e);
      this.collectionDocument = { items: [] };
      this.modalRef.ref.close();
    }
  }

  /**
   * provides the data of my workflows
   *
   * @return void
   */
  getMyWorkflows(params: any, isFiltered: boolean) {
    try {
      let queryString;
      this.configList = [
        {
          name: '',
          klass: '',
          type: 'icon',
          fieldId: 'status'
        },
        {
          name: 'ID',
          klass: '',
          type: 'text',
          fieldId: 'id'
        },
        {
          name: 'Nome',
          klass: '',
          type: 'text',
          fieldId: 'name'
        },
        {
          name: 'Data',
          klass: '',
          type: 'text',
          fieldId: 'date.dateCreated.formated_date',
          fieldKey: 'id',
          isSortable: true
        },
        {
          name: 'Status',
          klass: '',
          type: 'status',
          fieldId: 'status',
          fieldKey: 'status',
          isSortable: true
        },
        {
          name: 'Ações',
          type: 'custom-select',
          icon: 'fa fa-sliders-h',
          width: '5%',
          isRestrict: true,
          options: {
            changeLabel: true,
            items: [
              {
                name: 'Detalhes',
                action: 'view',
                canField: 'status.id',
                can: [
                  WORKFLOW_STATUS.DRAFT,
                  WORKFLOW_STATUS.CIRCULATING,
                  WORKFLOW_STATUS.CANCELLED,
                  WORKFLOW_STATUS.EXPIRED,
                  WORKFLOW_STATUS.PENDING,
                  WORKFLOW_STATUS.REJECTED,
                  WORKFLOW_STATUS.COMPLETED
                ]
              },
              {
                name: 'Baixar Documentos',
                action: 'download',
                canField: 'status.id',
                can: [
                  WORKFLOW_STATUS.DRAFT,
                  WORKFLOW_STATUS.CIRCULATING,
                  WORKFLOW_STATUS.CANCELLED,
                  WORKFLOW_STATUS.EXPIRED,
                  WORKFLOW_STATUS.PENDING,
                  WORKFLOW_STATUS.REJECTED,
                  WORKFLOW_STATUS.COMPLETED
                ]
              },
              {
                name: 'Baixar Página de Assinaturas',
                action: 'download-info',
                canField: 'status.id',
                can: [
                  WORKFLOW_STATUS.DRAFT,
                  WORKFLOW_STATUS.CIRCULATING,
                  WORKFLOW_STATUS.CANCELLED,
                  WORKFLOW_STATUS.EXPIRED,
                  WORKFLOW_STATUS.PENDING,
                  WORKFLOW_STATUS.REJECTED,
                  WORKFLOW_STATUS.COMPLETED
                ]
              },
              {
                name: 'Cancelar',
                action: 'cancel',
                canField: 'status.id',
                can: [
                  WORKFLOW_STATUS.DRAFT,
                  WORKFLOW_STATUS.CIRCULATING,
                  WORKFLOW_STATUS.EXPIRED,
                  WORKFLOW_STATUS.PENDING,
                  WORKFLOW_STATUS.REJECTED
                ]
              },
              {
                name: 'Reenviar notificação',
                action: 'wakeup',
                canField: 'status.id',
                can: [WORKFLOW_STATUS.CIRCULATING]
              },
              {
                name: 'Iniciar Workflow',
                action: 'init',
                canField: 'status.id',
                can: [WORKFLOW_STATUS.DRAFT]
              },
              {
                name: 'Reutilizar',
                action: 'clone',
                canField: 'status.id',
                can: [
                  WORKFLOW_STATUS.DRAFT,
                  WORKFLOW_STATUS.CIRCULATING,
                  WORKFLOW_STATUS.CANCELLED,
                  WORKFLOW_STATUS.EXPIRED,
                  WORKFLOW_STATUS.PENDING,
                  WORKFLOW_STATUS.REJECTED,
                  WORKFLOW_STATUS.COMPLETED
                ]
              }
            ]
          }
        }
      ];

      this.statusFilterConfig = [
        {
          value: '',
          label: 'Todos'
        },
        {
          value: 6,
          label: 'Assinado'
        },
        {
          value: 1,
          label: 'Aguardando assinatura'
        },
        {
          value: 2,
          label: 'Cancelado'
        },
        {
          value: 3,
          label: 'Expirado'
        },
        {
          value: 4,
          label: 'Rejeitado'
        }
      ];

      if (!isFiltered) {
        queryString = {
          'order-by': [
            {
              type: 'field',
              field: params.sortBy,
              direction: params.sort
            }
          ],
          page: params.page
        };
      } else {
        queryString = this.queryStringFilter;
        queryString.page = params.page;
      }

      this.documentService.getAll(queryString).subscribe(
        (response: any) => {
          log.debug(`Success [getMyWorkflows]:`, response);
          this.collectionDocument = response;
          this.pagination = {
            totalItems: response.total_items,
            pageCount: response.page_count,
            pageSize: response.page_size,
            currentPage: response.page
          };
          this.modalRef.ref.close();
        },
        (error: any) => {
          log.debug(`Error [getMyWorkflows]:`, error);
          this.collectionDocument = { items: [] };
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      log.debug(`Error [getMyWorkflows]:`, e);
      this.collectionDocument = { items: [] };
      this.modalRef.ref.close();
    }
  }

  /**
   * Action grab all selected items
   *
   * @param (any) items selected
   */
  getSelectedItems(items: any) {
    this.selectedItems = items;
  }

  /**
   * Action grab clicked item
   *
   * @param (any) clicked item
   */
  getClickedItem(item: any) {
    let id;

    this.workflowService.endpoint = '/workflow';
    id = item.workflow ? item.workflow.id : null;

    if (this.isReceived) {
      this.workflowService.endpoint = '/workflow-step';
      if (item.step && item.step.id) {
        id = item.step.id;
      }
      if (!id) {
        id = this.getFirstWorkflowStepsId(item.step);
      }
    }

    if (!id) {
      return;
    }

    try {
      this.loadingDetails = true;
      this.workflowService
        .getWorkflow(id)
        .pipe(
          finalize(() => {
            this.loadingDetails = false;
          })
        )
        .subscribe(
          (response: any) => {
            log.debug(`Success [getClickedItem]:`, response);
            this.details = response;
          },
          (error: any) => {
            log.debug(`Error [getClickedItem]:`, error);
            this.details = error;
          }
        );
    } catch (e) {
      log.debug(`Error [getClickedItem]:`, e);
      this.details = e;
    }
  }

  /**
   * Get the first workflow step id
   * @param workflowSteps array of workflowSteps
   */
  getFirstWorkflowStepsId(workflowSteps: any[]): string {
    let id = null;
    if (workflowSteps && workflowSteps.length) {
      id = workflowSteps[0].id;
    }
    return id;
  }

  /**
   * Action grab data
   *
   * @param (any) items selected
   */
  doAction(event: any) {
    log.debug(`doAction:`, event);

    if (!event) {
      return;
    }

    if (event.data.workflowSteps) {
      event.data.step = event.data.workflowSteps[0];
    }

    if (event.type === 'sign') {
      const hash = btoa(
        JSON.stringify({
          u: this.userLogged.username,
          id: event.data.step.id
        })
      );

      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: `/subscribe/${hash}`
      }).dispatchEvent(
        new MouseEvent(`click`, {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    }

    if (event.type === 'view') {
      const data = this.isReceived ? event.data.step : event.data;
      const routeType = this.isReceived ? 'workflow-step' : 'workflow';
      this.router.navigate([`/manager/manage/${routeType}/detail/${data.id}`], {
        replaceUrl: true
      });
    }

    if (event.type === 'init') {
      this.onActionInitWorkflow(event.data);
    }

    if (event.type === 'wakeup') {
      this.onActionWakeupWorkflow(event.data);
    }

    if (event.type === 'cancel') {
      this.open(this.modalCancel, 'sm', event);
    }

    if (event.type === 'clone') {
      this.onActionCloneWorkflow(event.data);
    }

    if (event.type === 'download') {
      const data = this.isReceived ? event.data.step : event.data;
      const routeType = this.isReceived ? 'workflow-step' : 'workflow';
      this.getDocumentForDownload(data, routeType);
    }

    if (event.type === 'download-info') {
      const data = this.isReceived ? event.data.step : event.data;
      const routeType = this.isReceived ? 'workflow-step' : 'workflow';
      this.getDocumentForDownload(data, routeType, true);
    }
  }

  /**
   * Get Document For Download
   *
   * @param (any) event with data of workflow
   * @return void
   */
  getDocumentForDownload(
    event: any,
    routeType: any,
    isSignatureDocument?: Boolean
  ) {
    try {
      setTimeout(
        (() => {
          this.modalRef.data.text = 'obtendo dados do documento...';
          this.modalRef.ref = this.open(this.loading);
        }).bind(this)
      );

      let ID = '0';

      if (event && event.step && event.step.id) {
        ID = event.step.id;
      }

      if (event && event.id) {
        ID = event.id;
      }

      if (routeType === 'workflow') {
        this.workflowService.endpoint = '/workflow';
      }
      this.workflowService.getWorkflow(ID).subscribe(
        (response: any) => {
          log.debug(`getDocumentForDownload:`, response);
          this.modalRef.ref.close();
          if (response.documents && response.documents.length > 0) {
            response.documents.forEach((doc: any) => {
              if (isSignatureDocument) {
                Object.assign(document.createElement('a'), {
                  target: '_blank',
                  href: doc.signaturePageLink
                }).dispatchEvent(
                  new MouseEvent(`click`, {
                    bubbles: true,
                    cancelable: true,
                    view: window
                  })
                );
                return true;
              }
              const originalFile = doc.originalFile;
              const signedFile = doc.signedFile;
              const downloadLink = signedFile
                ? signedFile.link
                : originalFile.link;

              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: downloadLink
              }).dispatchEvent(
                new MouseEvent(`click`, {
                  bubbles: true,
                  cancelable: true,
                  view: window
                })
              );
            });
          } else {
            this.alertSubscription.update({
              type: 'error',
              message:
                'Não foi possível efetuar o donwload do seu documento. Tente novamente!'
            });
          }
        },
        (error: any) => {
          log.debug(`getDocumentForDownload error js:`, error);
          this.modalRef.ref.close();
          this.alertSubscription.update({
            type: 'error',
            message: 'Ocorreu um erro ao baixar o documento!'
          });
        }
      );
    } catch (e) {
      log.debug(`getDocumentForDownload error:`, e);
      this.modalRef.ref.close();
      this.alertSubscription.update({
        type: 'error',
        message: 'Ocorreu um erro ao baixar o documento!'
      });
    }
  }

  /**
   * Init workflow
   *
   * @param (any) event with data of workflow
   * @return void
   */
  onActionInitWorkflow(event: any) {
    try {
      setTimeout(
        (() => {
          this.modalRef.data.text = 'iniciando o workflow...';
          this.modalRef.ref = this.open(this.loading);
        }).bind(this)
      );

      this.documentService.update(event.id, { status: 1 }).subscribe(
        (response: any) => {
          log.debug(`onActionInitWorkflow:`, response);

          if (response.id) {
            this.alertSubscription.update({
              type: 'success',
              message: 'Workflow iniciado com sucesso!'
            });

            this.getData(
              {
                sort: this.currentSort,
                sortBy: this.currentSortBy,
                page: this.currentPage,
                area: 'me'
              },
              false
            );
          } else {
            this.alertSubscription.update({
              type: 'success',
              message: 'Ocorreu um erro ao iniciar o workflow!'
            });
          }
        },
        (error: any) => {
          log.debug(`onActionInitWorkflow error js:`, error);

          this.alertSubscription.update({
            type: 'error',
            message: 'Ocorreu um erro ao iniciar o workflow!'
          });
        }
      );
    } catch (e) {
      log.debug(`onActionInitWorkflow error:`, e);
      this.alertSubscription.update({
        type: 'error',
        message: 'Ocorreu um erro ao iniciar o workflow!'
      });
    }
  }

  onActionWakeupWorkflow(event: any) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'Reenviando workflow...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );
    this.documentService.getById(`${event.id}?q=wakeup`, {}).subscribe(
      (response: any) => {
        if (response.workflow.id) {
          this.alertSubscription.update({
            type: 'success',
            message: 'Workflow reenviado com sucesso!'
          });
        } else {
          this.alertSubscription.update({
            type: 'error',
            message: 'Ocorreu um erro ao reenviar o Workflow!'
          });
        }
        this.modalRef.ref.close();
      },
      (error: any) => {
        this.alertSubscription.update({
          type: 'error',
          message: 'Ocorreu um erro ao reenviar o Workflow!'
        });
      }
    );
  }

  onActionCloneWorkflow(event: any) {
    this.documentService.getById(event.id).subscribe(async (response: any) => {
      const { documents, workflow } = response;

      for (const document of documents) {
        const workflowFile = await WorkflowFile.MakeWorkflowFileFromURL(
          document.file.link
        );
        workflowFile.setIdFile(document.file.id);
        workflowFile.setName(document.name);
        workflowFile.setFolderId(document.folder.id);

        if (document.dueDate) {
          workflowFile.setDueDate(document.dueDate.date);
        }

        for (const recipient of document.steps.recipients) {
          let contact = this.workflowBuildService.findContactByEmail(
            recipient.email
          );

          if (!contact) {
            contact = new WorkflowContact(
              {
                email: recipient.email,
                id: recipient.id,
                name: recipient.name
              },
              recipient.action
            );
            this.workflowBuildService.addContact(contact);
          }

          for (const field of recipient.fields) {
            const page = workflowFile.getPage(field.page);
            const f = WorkflowDroppedFieldsStrategy.MakeDroppedField(
              field.type,
              contact,
              recipient.signatureType
            );
            f.setViewportProportion(1);
            f.setSize(field.width, field.height);
            f.setCoordinates(field.x, field.y);
            page.addField(f);
          }
        }

        this.workflowBuildService.addFile(workflowFile);
        this.workflowBuildService.setFileAsVisible(workflowFile);
      }

      this.workflowBuildService.setSettings({
        autoInitiate: workflow.autoInitiate,
        autoRemind: workflow.autoRemind,
        sla: workflow.sla,
        message: workflow.message,
        name: workflow.name,
        priority: workflow.priority,
        dueDate: workflow.dueDate ? workflow.dueDate.date : null
      });

      this.router.navigate(['/manager/workflow']);
    });
  }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    this.sortFields[field] = this.reorder(this.sortFields[field]);

    if (this.sortFields[field]) {
      setTimeout(
        (() => {
          this.modalRef.data.text = 'carregando dados...';
          this.modalRef.ref = this.open(this.loading);
        }).bind(this)
      );

      const payload = {
        sort: this.sortFields[field],
        sortBy: field,
        page: 1
      };

      let currentRoute = this.currentRoute;
      if (this.currentArea) {
        currentRoute += `/${this.currentArea}`;
      }

      this.currentSort = payload.sort;
      this.currentSortBy = payload.sortBy;
      this.router.navigate([currentRoute], {
        replaceUrl: true,
        queryParams: payload
      });

      payload['area'] = this.currentArea;
      this.getData(payload, false);
    }
  }

  /**
   * Action for change page
   *
   * @param (number) of page
   */
  onChangePage(pageNumber: number) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );

    this.search(pageNumber);
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  private open(content: any, size?: any, data?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    modalRef.result.then(result => {
      if (result === 'save') {
        this.cancelWorkflow(data);
        return;
      }
      if (result === 'cancel') {
        return;
      }
    });
    return modalRef;
  }

  private cancelWorkflow(data: any) {
    try {
      const payload = {
        status: 2
      };
      this.modalRef.ref = this.open(this.loading);
      this.documentService.update(data.data.id, payload).subscribe(
        (response: any) => {
          window.location.reload();
        },
        (error: any) => {
          log.debug(`doSubmitCancelForm:`, error);
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      this.modalRef.ref.close();
    }
  }
}
