import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Authentication, Country, State, User } from '@app/core/models';
import { Logger } from '@app/core/class';
import { Plan } from '@app/core/models/plan.model';
import {
  CountryService,
  PlansService,
  StateService,
  AuthorizationService,
  UserService,
  RemainingUserBalanceService,
  TagManageService
} from '@app/core/services';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardComponent } from '@app/landing/components';

const log = new Logger('PlansPage');

@Component({
  selector: 'app-plans-page',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansPageComponent implements OnInit {
  @ViewChild('modalLoading') loading: ElementRef;
  @ViewChild('modalFeedbackPayment') feedbackPayment: ElementRef;
  @ViewChild('modalCardFeedBack') cardFeedBack: ElementRef;
  @ViewChild(CreditCardComponent) creditCardComponent: CreditCardComponent;

  isLoading: boolean = false;

  planChoosed: boolean = false;
  isOnFeedback: boolean = false;
  logged: Authentication.SignupUserResponse;
  selectedId: number;
  photo: User.UserMetadata;
  error: string;
  stateList: Array<State.FormPayload>;
  countryList: Array<Country.FormPayload>;
  planInfo: User.UserPlanPayload;
  modalRef: any;

  choosedPlan: Plan.Plans;
  data: Plan.Plans[];
  feedBack: string;

  constructor(
    private planService: PlansService,
    private userService: UserService,
    private countryService: CountryService,
    private stateService: StateService,
    private modalService: NgbModal,
    private authorizationService: AuthorizationService,
    private remainingUserBalance: RemainingUserBalanceService,
    private tagManageService: TagManageService
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando planos...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );
    this.getPlans();
    this.getRegisterData();

    this.logged = this.authorizationService.getUser();
    this.userService.getPlanInfo().subscribe(body => (this.planInfo = body));
    this.userService
      .getAllMetadata()
      .pipe(
        map((body: Array<User.UserMetadata>) => {
          return body.filter((e: User.UserMetadata) => e.dataKey === 'picture');
        })
      )
      .subscribe((response: Array<User.UserMetadata>) => {
        this.photo = response.length ? response[0] : null;
      });
  }

  public getPlans(): void {
    this.planService
      .getPlans()
      .pipe(
        map((body: Plan.PlanCollection) =>
          body.plan.filter(
            (plan: Plan.Plans) =>
              plan.status === 1 &&
              plan.paymentMethod != null &&
              !plan.paymentMethod.includes('free')
          )
        )
      )
      .subscribe(
        (response: Plan.Plans[]) => {
          log.debug(`Success [getAllPlans]: ${response}`);
          this.data = response;
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
        },
        (error: any) => {
          log.debug(`Error [getAllPlans]: ${error}`);
          this.data = [];
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
        }
      );
  }

  open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return modalRef;
  }

  modalClose() {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  getRegisterData() {
    const states = this.stateService.list();
    this.stateList = [{ id: '', name: 'Estado' } as any].concat(states);

    const countries = this.countryService.list();
    this.countryList = [{ id: '', name: 'País' } as any].concat(countries);
  }

  choosePlan() {
    this.planChoosed = false;
    this.error = null;
    this.selectedId = null;
  }

  changePlan(plan: any) {
    this.choosedPlan = plan;
    this.selectedId = plan.id;
    this.planChoosed = true;
  }

  shouldResetRecaptchaCredCard() {
    this.creditCardComponent.shouldResetRecaptchaAndForm();
  }

  async pay(creditCard: Plan.CreditCard) {
    try {
      this.modalRef.data.text = 'Processando pedido...';
      this.modalRef.ref = this.open(this.loading);
      const payload: Plan.PlanSubscriptionPayload = {
        paymentMethod: 'credit_card',
        idPlan: this.selectedId,
        customer: {
          document: creditCard.document,
          address: creditCard.billingAddress
        },
        card: creditCard,
        recaptcha: creditCard.recaptcha
      };
      this.isLoading = true;

      const plan = await this.userService.getPlanInfo().toPromise();
      this.modalRef.data.text = 'Cancelando o plano atual...';
      if (plan) {
        await this.planService.cancelPlan(plan.id).toPromise();
        this.addPlan(payload);
      } else {
        this.addPlan(payload);
      }
    } catch (e) {
      this.modalClose();
      this.isLoading = false;
      this.shouldResetRecaptchaCredCard();
      this.tagManageService.sendEvent({
        event: 'event',
        value: `${this.choosedPlan.name} error`
      });
      this.feedBack =
        'Não foi possível cancelar seu plano atual, Tente novamente ou entrar em contato conosco pelo chat disponível no canto inferior direito desta página.';
      this.open(this.cardFeedBack);
      log.debug(`Error [createPlan]: ${e}`);
    }
  }

  addPlan(payload: any) {
    this.modalRef.data.text = 'Processando pagamento...';
    this.planService
      .subscription(payload)
      .pipe(
        finalize(() => {
          this.modalClose();
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: Array<any>) => {
          log.debug(`Success [createPlan]: ${response}`);
          this.modalClose();
          this.isOnFeedback = true;
          this.open(this.feedbackPayment);
          /**
           * User remaining balance
           */
          this.remainingUserBalance.getPlanUser();
        },
        (error: any) => {
          this.modalClose();
          this.shouldResetRecaptchaCredCard();
          this.tagManageService.sendEvent({
            event: 'event',
            value: `${this.choosedPlan.name} error`
          });
          this.feedBack = error.error.detail;
          this.open(this.cardFeedBack, 'md');
          log.debug(`Error [createPlan]: ${error}`);
        }
      );
  }
}
