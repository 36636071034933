/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../default/header/header.component.ngfactory";
import * as i3 from "../default/header/header.component";
import * as i4 from "../manager/header/header.component.ngfactory";
import * as i5 from "../manager/header/header.component";
import * as i6 from "../../../core/services/authentication.service";
import * as i7 from "../../../core/services/authorization.service";
import * as i8 from "../../../core/services/business-unit-metadata.service";
import * as i9 from "../../../core/services/user.service";
import * as i10 from "../../../core/subscriptions/users";
import * as i11 from "../../../core/subscriptions/photo";
import * as i12 from "../../../core/services/workflow-build.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../core/services/remaining-user-balance.service";
import * as i15 from "@angular/common";
import * as i16 from "../manager/footer/footer.component.ngfactory";
import * as i17 from "../manager/footer/footer.component";
import * as i18 from "./public.component";
var styles_LayoutPublicComponent = [i0.styles];
var RenderType_LayoutPublicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutPublicComponent, data: {} });
export { RenderType_LayoutPublicComponent as RenderType_LayoutPublicComponent };
function View_LayoutPublicComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-default-header", [], null, null, null, i2.View_LayoutDefaultHeaderComponent_0, i2.RenderType_LayoutDefaultHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutDefaultHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_LayoutPublicComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-manager-header", [], null, null, null, i4.View_LayoutManagerHeaderComponent_0, i4.RenderType_LayoutManagerHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.LayoutManagerHeaderComponent, [i6.AuthenticationService, i7.AuthorizationService, i8.BusinessMetadataService, i9.UserService, i10.UserSubscription, i11.PhotoSubscription, i12.WorkflowBuildService, i13.Router, i14.RemainingUserBalanceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutPublicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutPublicComponent_1)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutPublicComponent_2)), i1.ɵdid(6, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i13.RouterOutlet, [i13.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-layout-manager-footer", [], null, null, null, i16.View_LayoutManagerFooterComponent_0, i16.RenderType_LayoutManagerFooterComponent)), i1.ɵdid(12, 114688, null, 0, i17.LayoutManagerFooterComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLogged; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLogged; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); _ck(_v, 12, 0); }, null); }
export function View_LayoutPublicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-public", [], null, null, null, View_LayoutPublicComponent_0, RenderType_LayoutPublicComponent)), i1.ɵdid(1, 114688, null, 0, i18.LayoutPublicComponent, [i7.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutPublicComponentNgFactory = i1.ɵccf("app-public", i18.LayoutPublicComponent, View_LayoutPublicComponent_Host_0, {}, {}, []);
export { LayoutPublicComponentNgFactory as LayoutPublicComponentNgFactory };
