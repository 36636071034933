import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WORKFLOW_CONTACT_ACTION, AuthorizationService, WorkflowBuildService, COUNTRY_CODES } from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowContact } from '@app/core/class';
var WorkflowBuildContactFormComponent = /** @class */ (function () {
    function WorkflowBuildContactFormComponent(formBuilder, modalService, authorizationService, workflowBuildService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.authorizationService = authorizationService;
        this.workflowBuildService = workflowBuildService;
        this.contactAdded = new EventEmitter();
        this.createForm();
    }
    WorkflowBuildContactFormComponent.prototype.ngOnInit = function () {
        this.nameInput.nativeElement.focus();
    };
    WorkflowBuildContactFormComponent.prototype.isEnabledWhatsapp = function () {
        if (this.authorizationService.getUser()) {
            return this.authorizationService.getUser().twilioBuEnabled;
        }
        return false;
    };
    WorkflowBuildContactFormComponent.prototype.isEnabledWhatsappIntegration = function () {
        return this.workflowBuildService.getWhatsApp();
    };
    WorkflowBuildContactFormComponent.prototype.shouldDisableWhatsapp = function (contact) {
        contact.notifyWhatsapp = false;
        return contact;
    };
    WorkflowBuildContactFormComponent.prototype.onContactsSelected = function (contacts) {
        for (var _i = 0, contacts_1 = contacts; _i < contacts_1.length; _i++) {
            var contact = contacts_1[_i];
            this.addContact(this.shouldDisableWhatsapp(contact));
        }
        this.emitContactAdded();
    };
    WorkflowBuildContactFormComponent.prototype.isIntegration = function () {
        return this.authorizationService.isIntegration();
    };
    WorkflowBuildContactFormComponent.prototype.addMyselfToSign = function () {
        var myself = this.authorizationService.getUser();
        this.addContact({
            id: parseInt(myself.id),
            name: myself.name,
            email: myself.email,
            whatsappNumber: myself.whatsappNumber,
            notifyWhatsapp: false
        });
        this.emitContactAdded();
    };
    WorkflowBuildContactFormComponent.prototype.submitContact = function () {
        var contact = this.contactForm.value;
        contact.whatsappNumber = contact.whatsappNumber
            ? "" + COUNTRY_CODES.BR + contact.whatsappNumber
            : '';
        this.addContact(contact, contact.action, contact.hasPhotoSign);
        this.emitContactAdded();
    };
    WorkflowBuildContactFormComponent.prototype.addContact = function (contact, action, hasPhotoSign) {
        var workflowContact = WorkflowContact.MakeWorkflowContact(contact, action, hasPhotoSign);
        this.workflowBuildService.addContact(workflowContact);
    };
    WorkflowBuildContactFormComponent.prototype.formControlValidation = function (control) {
        return !control.pristine && control.invalid;
    };
    /**
     * Open the contact modal
     */
    WorkflowBuildContactFormComponent.prototype.openModal = function (content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            size: 'lg',
            keyboard: false,
            centered: true,
            backdrop: 'static'
        });
    };
    WorkflowBuildContactFormComponent.prototype.isValidInputWhatsapp = function () {
        return (!this.contactFormAdd.whatsappNumber.value ||
            this.contactFormAdd.whatsappNumber.value.length !== 11);
    };
    Object.defineProperty(WorkflowBuildContactFormComponent.prototype, "contactFormAdd", {
        get: function () {
            return this.contactForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    WorkflowBuildContactFormComponent.prototype.createForm = function () {
        this.contactForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(250)
                ])
            ],
            whatsappNumber: [''],
            notifyWhatsapp: [false],
            hasPhotoSign: [false],
            action: [WORKFLOW_CONTACT_ACTION.SIGN, Validators.required]
        });
    };
    WorkflowBuildContactFormComponent.prototype.emitContactAdded = function () {
        this.contactAdded.emit();
    };
    return WorkflowBuildContactFormComponent;
}());
export { WorkflowBuildContactFormComponent };
