import { Injectable } from '@angular/core';
import { Segment } from '@app/core/models/market-segment.model';

@Injectable()
export class MarketsegmentService {
  list(): Array<Segment.MarketSegmentPayload> {
    return [
      { id: '1', name: 'Construção' },
      { id: '2', name: 'Contabilidade e tributação' },
      { id: '3', name: 'Estudante' },
      { id: '4', name: 'Governo' },
      { id: '5', name: 'Intituições de Ensino' },
      { id: '6', name: 'Jurídico' },
      { id: '7', name: 'Prestação de Serviço' },
      { id: '8', name: 'Saúde' },
      { id: '9', name: 'Sem fins lucrativos' },
      { id: '10', name: 'Serviços comerciais / consultoria' },
      { id: '11', name: 'Serviços Financeiros' },
      { id: '12', name: 'Setor Imobiliário' },
      { id: '13', name: 'Tecnologia' },
      { id: '14', name: 'Varejo' },
      { id: '15', name: 'Outros' }
    ];
  }
}
