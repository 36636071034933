import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnChanges {
  @Input() theme: string;

  @Input() message: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message.currentValue) {
      setTimeout(() =>
        document
          .getElementById('feedbackComponent')
          .scrollIntoView({ behavior: 'smooth' })
      );

      setTimeout(() => {
        this.message = null;
      }, 4000);
    }
  }
}
