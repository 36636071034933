<div class="text-custom">
  <p>
    Ao escolher essa ação os destinatários precisam
    <strong>rubricar página por página manualmente</strong>.
  </p>
  <p>
    Deseja replicar a rubrica em todas as páginas do documento? Essa ação não
    poderá ser desfeita.
  </p>
</div>

<div class="text-right">
  <button class="btn btn-default btn-sm" (click)="modal.close('cancel')">
    Não
  </button>
  <button class="btn btn btn-secondary btn-sm" (click)="doSubmit()">Sim</button>
</div>
