<div class="page page-activities">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li><a routerLink="/manager">Página Inicial</a></li>
          <li class="current">
            <a routerLink="/manager/activities">Relatórios</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="page-description">
          <h2>Visão global</h2>
          <p class="form-inline">
            <small>Os gráficos refletem as atividades nos últimos</small>
            <select class="form-control form-control-sm">
              <option> 12 meses </option>
            </select>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="activities-statistics">
          <app-statistics
            [available]="available"
            [showHidden]="true"
          ></app-statistics>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="activities-chart">
          <app-chart
            [title]="'Atividades do ano'"
            [type]="'donnut'"
            [showFooter]="false"
            [filterOptions]="filterOptions"
            [data]="dataChartActivities"
          ></app-chart>
        </div>
      </div>

      <div class="col-12 col-lg-7">
        <div class="activities-volume">
          <app-chart
            [title]="'Volume de envios'"
            [type]="'line'"
            [showFooter]="true"
            [filterOptions]="filterOptions"
            [data]="dataChartVolume"
            (filter)="filterChartVolume($event)"
          ></app-chart>
        </div>
      </div>
    </div>
  </div>
</div>
