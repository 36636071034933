import { of } from 'rxjs';
var credentialsKey = 'credentials';
var usersKey = 'user';
/**
 * Provides a base for authentication workflow.
 */
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService() {
        this._credentials = {};
        this._user = null;
        this._tempGuestUser = null;
        this._tempAccessToken = null;
        this._isIntegration = false;
        var savedCredentials = localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
        var savedUser = localStorage.getItem(usersKey);
        if (savedUser) {
            this._user = JSON.parse(savedUser);
        }
    }
    /**
     * Checks is reset token is valid.
     * @return True if the token is valid.
     */
    AuthorizationService.prototype.getResetToken = function (token) {
        if (token === 'o53McgvNZ6scR72vKZLX') {
            return of(true);
        }
        else {
            return of(false);
        }
    };
    /**
     * Checks is the user is authenticated.
     * @return True if the user is authenticated.
     */
    AuthorizationService.prototype.isAuthenticated = function () {
        var credentialsVal = localStorage.getItem(credentialsKey);
        // Nega acesso se não houver credenciais salvas no localStorage
        if (!credentialsVal) {
            return false;
        }
        var credentials = JSON.parse(credentialsVal);
        // Verifica se token foi expirado.
        return !this.hasExpiredToken(credentials);
    };
    AuthorizationService.prototype.isGuest = function () {
        return !!this._tempAccessToken;
    };
    AuthorizationService.prototype.isIntegration = function (isIntegration) {
        if (typeof isIntegration !== 'undefined') {
            this._isIntegration = isIntegration;
        }
        return this._isIntegration;
    };
    AuthorizationService.prototype.getAccessToken = function () {
        if (this._tempAccessToken) {
            return this._tempAccessToken;
        }
        return this._credentials ? this._credentials.access_token : null;
    };
    AuthorizationService.prototype.setTempAccessToken = function (accessToken) {
        this._tempAccessToken = accessToken;
    };
    AuthorizationService.prototype.setTempGuestUser = function (user) {
        this._tempGuestUser = user;
    };
    AuthorizationService.prototype.getUser = function () {
        return this._tempGuestUser || this._user;
    };
    Object.defineProperty(AuthorizationService.prototype, "credentials", {
        /**
         * Gets the user credentials.
         * @return The user credentials or null if the user is not authenticated.
         */
        get: function () {
            return this._credentials;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizationService.prototype, "user", {
        /**
         * Gets the user information.
         * @return The user credentials or null if the user is not authenticated.
         */
        get: function () {
            var data = localStorage.getItem(usersKey);
            if (data) {
                return of(JSON.parse(data));
            }
            return of(null);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param credentials The user credentials.
     */
    AuthorizationService.prototype.setCredentials = function (credentials) {
        this._credentials = credentials || null;
        if (credentials) {
            localStorage.setItem(credentialsKey, JSON.stringify(credentials));
        }
        else {
            localStorage.removeItem(credentialsKey);
        }
    };
    AuthorizationService.prototype.hasExpiredToken = function (credentials) {
        if (!credentials.expires_at) {
            return false;
        }
        var now = new Date();
        var expires_at = new Date(credentials.expires_at);
        return now.getTime() > expires_at.getTime();
    };
    /**
     * Sets the user informations.
     * @param credentials The user credentials.
     */
    AuthorizationService.prototype.setUser = function (user) {
        if (user) {
            localStorage.setItem(usersKey, JSON.stringify(user));
            var savedUser = localStorage.getItem(usersKey);
            if (savedUser) {
                this._user = JSON.parse(savedUser);
            }
        }
        else {
            localStorage.removeItem(usersKey);
        }
    };
    AuthorizationService.prototype.deleteCredentials = function () {
        localStorage.removeItem(credentialsKey);
    };
    AuthorizationService.prototype.deleteUser = function () {
        localStorage.removeItem(usersKey);
        localStorage.removeItem('localData');
    };
    return AuthorizationService;
}());
export { AuthorizationService };
