<div class="field-block field-name">
  <div class="mt-1">
    <span>Rubrica</span>
    <span
      class="copy-icon"
      title="Replicar rubrica em todas as páginas"
      (click)="toAction()"
      ><i class="far fa-copy"></i
    ></span>
  </div>
  <div class="simple-text">{{ contact.email }}</div>
</div>

<ng-template #feedbackRubric let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Replicar rubrica'"
  >
    <app-feedback-rubric [modal]="modal"></app-feedback-rubric>
  </app-modal-action>
</ng-template>
