<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text" title="Pesquisar">
      <i class="fas fa-search"></i>
    </div>
  </div>
  <input
    type="text"
    class="form-control"
    placeholder="Pesquisar pasta"
    [formControl]="queryField"
    (keyup)="searchDebounce()"
  />
</div>

<ng-container *ngIf="isSpinner()">
  <div class="overFlow">
    <app-lists
      [collection]="folders"
      [fields]="configList"
      [sortableFields]="sortFields"
      [enableDetails]="true"
      [hover]="true"
      (choosed)="getSelectedFolde($event)"
    ></app-lists>
  </div>
</ng-container>

<ng-container *ngIf="!isSpinner()">
  <div class="spinner">
    <i class="fas fa-circle-notch fa-spin"></i> Carregando dados
  </div>
</ng-container>

<div class="text-center mt-5">
  <button
    class="btn btn-sm btn-secondary"
    (click)="moveDocument()"
    [disabled]="shouldEnabledButton()"
  >
    Adicionar
  </button>
</div>
