<div class="box">
  <div class="box-content">
    <div class="box-logo">
      <label class="file-btn">
        <div class="mask" (click)="openFileSelector(modalCropImage)">
          <i class="fa fa-plus-circle"></i>
          <div class="background"></div>
        </div>
        <span *ngIf="!spinnerLogo && !logo"
          ><i class="icon icon-cloud i-upload"></i> Upload marca</span
        >
        <img *ngIf="logo" [src]="logo" class="img-fluid" />
        <i class="fas fa-circle-notch fa-spin spin" *ngIf="spinnerLogo"></i>

        <input
          type="file"
          (change)="imageFileChangeEvent($event)"
          accept=".png, .jpg"
        />
      </label>
    </div>
    <p>Personalize seus e-mails com a sua marca.</p>
    <p>
      Formatos suportados: <strong>JPG</strong> e <strong>PNG</strong> -
      Proporção 190x33.
    </p>
  </div>
</div>

<ng-template #modalCropImage let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Recortar imagem'"
  >
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 1"
      [resizeToWidth]="190"
      [resizeToHeight]="25"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      format="png"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <div class="row mt-5">
      <div class="col-6 col-sm-4 col-md-4 mb-1">
        <button
          class="btn btm-sm btn-outline-success"
          (click)="toggleContainWithinAspectRatio()"
        >
          {{ containWithinAspectRatio ? 'Proporção' : 'Preenchimento' }}
        </button>
      </div>
      <div class="col-6 col-sm-4 col-md-4 mb-1">
        <button
          class="btn btn-secondary"
          (click)="uploadFile()"
          [disabled]="!croppedImage"
        >
          <i class="fas fa-circle-notch fa-spin" *ngIf="spinnerButton"></i>
          Salvar
        </button>
      </div>
      <div class="col-6 col-sm-3 col-md-3 mb-1">
        <button class="btn btn-default" (click)="closeModal()">Fechar</button>
      </div>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalError let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Aviso!'"
  >
    <div class="d-flex justify-content-center mb-5">
      <p class="font-weight-bold" style="font-size: 1rem;">
        Não é possivel realizar upload do arquivo selecionado.
      </p>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-secondary" (click)="closeModal()">Fechar</button>
    </div>
  </app-modal-action>
</ng-template>
