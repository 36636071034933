/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rejection-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./rejection-form.component";
var styles_RejectionFormComponent = [i0.styles];
var RenderType_RejectionFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RejectionFormComponent, data: {} });
export { RenderType_RejectionFormComponent as RenderType_RejectionFormComponent };
function View_RejectionFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "error"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          O motivo da rejei\u00E7\u00E3o \u00E9 obrigat\u00F3rio\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.f.reason.errors.required; _ck(_v, 2, 0, currVal_0); }); }
export function View_RejectionFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "form-modal-signature"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 29, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.doSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(4, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "textarea", [["class", "form-control form-control-sm"], ["formControlName", "reason"], ["placeholder", "Por favor, descreva aqui o motivo da sua rejei\u00E7\u00E3o..."], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "is-invalid": 0 }), i1.ɵdid(13, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(15, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(17, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RejectionFormComponent_1)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-outline-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Cancelar\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Enviar\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.rejectForm; _ck(_v, 4, 0, currVal_7); var currVal_15 = "form-control form-control-sm"; var currVal_16 = _ck(_v, 12, 0, (_co.f.reason.errors && !_co.f.reason.pristine)); _ck(_v, 11, 0, currVal_15, currVal_16); var currVal_17 = "reason"; _ck(_v, 15, 0, currVal_17); var currVal_18 = (_co.f.reason.errors && !_co.f.reason.pristine); _ck(_v, 20, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 17).ngClassValid; var currVal_13 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_19 = (_co.rejectForm.invalid || _co.isLoading); _ck(_v, 28, 0, currVal_19); }); }
export function View_RejectionFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rejection-form", [], null, null, null, View_RejectionFormComponent_0, RenderType_RejectionFormComponent)), i1.ɵdid(1, 49152, null, 0, i4.RejectionFormComponent, [i2.FormBuilder], null, null)], null, null); }
var RejectionFormComponentNgFactory = i1.ɵccf("app-rejection-form", i4.RejectionFormComponent, View_RejectionFormComponent_Host_0, { isLoading: "isLoading", modal: "modal" }, {}, []);
export { RejectionFormComponentNgFactory as RejectionFormComponentNgFactory };
