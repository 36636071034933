import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TagManageService } from '@app/core/services';

@Component({
  selector: 'app-payment-feedback',
  templateUrl: './payment-feedback.component.html',
  styleUrls: ['./payment-feedback.component.scss']
})
export class PaymentFeedbackComponent implements OnInit {
  @Input() modal: any;
  @Input() plan: any;

  constructor(
    public router: Router,
    private tagManageService: TagManageService
  ) {}

  ngOnInit() {
    this.tagManageService.sendEvent({
      event: 'event',
      value: `${this.plan.name} success`
    });
  }

  profile() {
    this.modal.close();
    this.router.navigate(['/manager/profile']);
  }
}
