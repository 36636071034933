import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { parseDate } from '@app/core/helpers/date';
import {
  WORKFLOW_STEP_ACTION,
  WORKFLOW_TEXT_STATUS,
  WORKFLOW_STEP_TEXT_STATUS,
  WORKFLOW_STEP_NEXT_ACTION,
  WORKFLOW_SIGNATURE_TYPE,
  WORKFLOW_STEP_STATUS,
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_STATUS
} from '@app/core/services/workflow.enum';
import { AuthorizationService } from './authorization.service';
import { HttpClient } from '@angular/common/http';
import { Workflow, WorkflowStep } from '../models';

/**
 * Provides a base for workflow.
 */
@Injectable()
export class WorkflowService extends BaseAPI {
  constructor(
    protected httpClient: HttpClient,
    protected authorizationService: AuthorizationService
  ) {
    super(httpClient);
    this.endpoint = '/workflow-step';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Gets all data of a service
   */
  getAll(queryString?: any): Observable<any> {
    if (this.endpoint === '/workflow-step') {
      queryString['hideExtrametadata'] = 1;
    }

    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this._parseListWorkflow(response);
      })
    );
  }

  getSignatureInPerson(
    queryString?: any
  ): Observable<WorkflowStep.SignatureInPersonCollection> {
    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this.parseSignatureInPerson(response);
      })
    );
  }

  filterByPeriod(period: string): Observable<any> {
    let filter = `?filter[0][type]=innerjoin&
    filter[0][field]=workflow&
    filter[0][alias]=w&
    filter[1][type]=gte&
    filter[1][alias]=w&
    filter[1][field]=dateCreated&
    filter[1][value]=${period}`;

    if (this.endpoint === '/workflow-step') {
      filter += '&hideExtrametadata=1';
    }

    return this.httpClient.get(`${this.endpoint}${filter}`).pipe(
      map((response: any) => {
        return this._parseListWorkflow(response);
      })
    );
  }

  countByStatus(status: number): Observable<any> {
    const endpoint = '/workflow';
    const filter = `?filter[0][field]=status&filter[0][type]=eq&filter[0][value]=${status}&pageSize=1`;
    return this.httpClient.get(`${endpoint}${filter}`).pipe(
      map((response: any) => {
        return response.total_items;
      })
    );
  }

  countByStatusAndPeriod(status: number, period: string): Observable<any> {
    let filter = `?filter[0][field]=status&
    filter[0][type]=eq&
    filter[0][value]=${status}&
    filter[1][type]=gte&
    filter[1][alias]=w&
    filter[1][field]=workflow&
    filter[1][child]=dateCreated&
    filter[1][value]=${period}&
    order-by[0][type]=field&
    order-by[0][field]=id&
    order-by[0][direction]=desc`;

    if (this.endpoint === '/workflow-step') {
      filter += '&hideExtrametadata=1';
    }

    return this.httpClient.get(`${this.endpoint}${filter}`).pipe(
      map((response: any) => {
        return response.total_items;
      })
    );
  }

  /**
   * Gets workflow of a service
   */
  getWorkflow(queryString: string, q?: any): Observable<any> {
    let options;
    if (q) {
      options = { params: q };
    }
    return this.httpClient.get(`${this.endpoint}/${queryString}`, options).pipe(
      map((body: any) => {
        return this._parseWorkflow(body);
      })
    );
  }

  /**
   * Gets document of a service
   */
  assignDocument(code: number, data: any): Observable<any> {
    return this.httpClient.patch(`${this.endpoint}/${code}`, data).pipe(
      map((body: any) => {
        return this.mapFields(body, true);
      })
    );
  }

  /**
   * Gets document of a service
   */
  createDocument(data: any): Observable<any> {
    return this.httpClient.post(this.endpoint, data).pipe(
      map((body: any) => {
        return this.mapFields(body, true);
      })
    );
  }

  /**
   * Parse list of workflow data and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseListWorkflow(data: any): any {
    const items = data._embedded.workflow_step.map((item: any) => {
      return {
        step: {
          id: item.id,
          action: {
            id: item.action,
            type: WORKFLOW_STEP_ACTION[item.action],
            text: WORKFLOW_STEP_NEXT_ACTION[item.action]
          },
          latitude: item.latitude,
          longitude: item.longitude,
          reason: item.reason,
          signatureType: {
            id: item.signatureType,
            text: WORKFLOW_SIGNATURE_TYPE[item.signatureType]
          },
          status: {
            id: item.status,
            text: this._parseStepText(
              item._embedded.workflow.status,
              item.status
            )
          },
          dateStatus: parseDate(item.status),
          metadata: item.metadata
        },
        workflow: {
          id: item._embedded.workflow.id,
          priority: item._embedded.workflow.priority,
          autoRemind: item._embedded.workflow.autoRemind,
          sla: item._embedded.workflow.sla,
          message: item._embedded.workflow.message,
          name: item._embedded.workflow.name,
          status: {
            id: item._embedded.workflow.status,
            text: WORKFLOW_TEXT_STATUS[item._embedded.workflow.status]
          },
          date: {
            dueDate: parseDate(item._embedded.workflow.dueDate),
            dateCreated: parseDate(item._embedded.workflow.dateCreated),
            dateStatus: parseDate(item._embedded.workflow.dateStatus),
            dateFinished: parseDate(item._embedded.workflow.dateFinished)
          },
          user: this._parseUser(item._embedded.workflow._embedded.user)
        }
      };
    });

    return {
      items: items,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items,
      _raw: data._embedded,
      _links: data._links
    };
  }

  private parseSignatureInPerson(
    data: any
  ): WorkflowStep.SignatureInPersonCollection {
    let items: WorkflowStep.SignatureInPerson[] = [];

    if (data.total_items > 0) {
      items = data._embedded.workflow_step.map((item: any) => {
        const extraMetadata = JSON.parse(item.extraMetadata);

        return {
          id: item.id,
          action: item.action,
          hasPhotoSign: item.hasPhotoSign,
          latitude: item.latitude,
          longitude: item.longitude,
          reason: item.reason,
          signatureType: item.signatureType,
          status: {
            id: item.status,
            text: WORKFLOW_STEP_TEXT_STATUS[item.status]
          },
          dateStatus: parseDate(item.dateStatus),
          extraMetadata: extraMetadata,
          workflow: {
            id: item._embedded.workflow.id,
            name: item._embedded.workflow.name,
            message: item._embedded.workflow.message,
            priority: item._embedded.workflow.priority,
            sla: item._embedded.workflow.sla,
            status: {
              id: item._embedded.workflow.status,
              text: WORKFLOW_TEXT_STATUS[item._embedded.workflow.status]
            },
            autoRemind: item._embedded.workflow.autoRemind,
            dateCreated: parseDate(item._embedded.workflow.dateCreated),
            dateFinished: parseDate(item._embedded.workflow.dateFinished),
            dateStatus: parseDate(item._embedded.workflow.dateStatus),
            dueDate: parseDate(item._embedded.workflow.dueDate),
            user: {
              id: item._embedded.workflow._embedded.user.id,
              name: item._embedded.workflow._embedded.user.name,
              email: item._embedded.workflow._embedded.user.email
            }
          }
        };
      });
    }

    return {
      items: items,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items
    };
  }

  /**
   * Parse of workflow data and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseWorkflow(data: any): any {
    const embeddedDocuments = data._embedded.documents;
    const user = this._getCurrentSubscriber(embeddedDocuments);

    const documents = embeddedDocuments.map((item: any) => {
      const recipients = this._parseSubscribers(item.workflowSteps);
      const document = {
        id: item.id,
        docId: item.docId,

        name: item.name,
        status: item.status,
        folder: item._embedded.folder,
        file: {
          id: item._embedded.originalFile.id,
          checksum: item._embedded.originalFile.checksum,
          link: item._embedded.originalFile._links.download.href
        },
        steps: {
          recipients: recipients,
          workflowSteps: item.workflowSteps,
          total: item.workflowSteps.length,
          sign: this._filterSubscribers(recipients, [
            WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.WAITING].action,
            WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.NOT_STARTED].action
          ]),
          signed: this._filterSubscribers(recipients, [
            WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.APPROVED].action
          ]),
          rejected: this._filterSubscribers(recipients, [
            WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.REJECTED].action
          ])
        },
        signature: this._getCurrentSignature(user)
      };

      if (item._embedded.originalFile) {
        document['originalFile'] = {
          id: item._embedded.originalFile.id,
          checksum: item._embedded.originalFile.checksum,
          link: item._embedded.originalFile._links.download.href
        };
      }

      if (item._embedded.file) {
        document['signedFile'] = {
          id: item._embedded.file.id,
          checksum: item._embedded.file.checksum,
          link: item._embedded.file._links.download.href
        };
      }

      const signaturePageId = () => {
        const data = item.docId.split('checker?q=');
        return data[1];
      };
      document['signaturePageId'] = signaturePageId();

      const signaturePageLink = () => {
        const apiLink = document.file.link.split('/file?q=')[0];
        return (
          apiLink + '/document?q=download&docId=' + document['signaturePageId']
        );
      };
      document['signaturePageLink'] = signaturePageLink();

      return document;
    });

    const workflow = {
      detail: data.detail,
      owner: data._embedded.user,
      currentUser: user,
      workflow: {
        id: data.id,
        sla: data.sla,
        type: data.type,
        status: data.status,
        priority: data.priority,
        message: data.message,
        autoRemind: data.autoRemind,
        download:
          embeddedDocuments[0]._embedded.originalFile._links.download.href,
        date: {
          dueDate: parseDate(data.dueDate),
          dateCreated: parseDate(data.dateCreated)
        }
      },
      documents: documents
    };

    if (embeddedDocuments[0]._embedded.file) {
      workflow.workflow.download =
        embeddedDocuments[0]._embedded.file._links.download.href;
    }

    return workflow;
  }

  /**
   * Get data of current Subscriber
   *
   * @param (any) subscribers collection
   */
  private _getCurrentSubscriber(documents: any) {
    const workflowSteps: any = {};

    documents.forEach((document: any) => {
      if (document.workflowSteps && document.workflowSteps.length) {
        document.workflowSteps.forEach((step: any) => {
          workflowSteps[step.id] = step;
        });
      }
    });

    const subscribers: any[] = Object.values(workflowSteps).sort(
      (a: any, b: any) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }
    );

    const currentUser = subscribers.find(
      element => element.status === WORKFLOW_STEP_STATUS.WAITING
    );
    const info = currentUser || subscribers[0];

    const output: any = {
      action: info.action,
      hasPhotoSign: info.hasPhotoSign,
      fields: info.fields,
      signatureType: info.signatureType,
      latitude: info.latitude,
      longitude: info.longitude,
      reason: info.reason,
      status: info.status,
      user: this._parseUser(info._embedded.user),
      date: parseDate(info.dateStatus)
    };

    const documentfields = documents.reduce((ret: any, doc: any) => {
      const step = doc.workflowSteps.filter(
        (stepItem: any) => output.user.id === stepItem._embedded.user.id
      );
      if (step && step.length) {
        const doc_fields = step[0].fields.map((field: any) => {
          field.document = {
            id: doc.id,
            name: doc.name
          };
          return field;
        });
        ret = ret.concat(doc_fields);
      }
      return ret;
    }, []);
    output.documentsFields = documentfields;

    return output;
  }

  /**
   * Get data of signature
   *
   * @param (any) subscriber
   */
  private _getCurrentSignature(subscriber: any) {
    if (!subscriber) {
      return null;
    }

    const signature = subscriber.fields.filter((item: any) => {
      return WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE === item.type;
    });

    return signature.length > 0 ? signature[0] : null;
  }

  /**
   * Parse of document creator data and adjusts to
   * default for printing the component
   *
   * @param (any) user object
   */
  private _parseUser(user: any) {
    return {
      id: user.id,
      name: user.name,
      initial: this._getInitials(user.name),
      email: user.email
    };
  }

  private _getInitials(name: string) {
    const slices = name.split(' ');
    const size = slices.length;
    let initials = slices[0].charAt(0);

    if (size > 1) {
      initials += slices[size - 1].charAt(0);
    }

    return initials;
  }

  /**
   * Parse of subscriber data and adjusts to
   * default for printing the component
   *
   * @param (any) subscribers collection
   */
  private _parseSubscribers(subscribers: any) {
    return subscribers.map((item: any) => {
      return {
        id: item._embedded.user.id,
        name: item._embedded.user.name,
        email: item._embedded.user.email,
        status: WORKFLOW_STEP_TEXT_STATUS[item.status],
        fields: item.fields,
        details: {
          action: {
            id: item.action,
            type: WORKFLOW_STEP_ACTION[item.action],
            text: WORKFLOW_STEP_NEXT_ACTION[item.action]
          },
          latitude: item.latitude,
          longitude: item.longitude,
          reason: item.reason,
          signatureType: item.signatureType,
          extraMetadata: item.extraMetadata,
          dateStatus: parseDate(item.dateStatus),
          status: {
            id: item.status,
            text: WORKFLOW_STEP_TEXT_STATUS[item.status]
          }
        }
      };
    });
  }

  /**
   * filter on subscribers to group by type
   *
   * @param (any) subscribers collection
   * @param (any) group of type
   */
  private _filterSubscribers(subscribers: any, group: any) {
    return subscribers.filter((elem: any, i: number) => {
      return group.indexOf(elem.status.action) !== -1;
    });
  }

  private _parseStepText(workflowId: number, stepId: number) {
    if (workflowId === WORKFLOW_STATUS.CIRCULATING) {
      return WORKFLOW_STEP_TEXT_STATUS[stepId];
    }

    return WORKFLOW_TEXT_STATUS[workflowId];
  }
}
