import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Logger } from '../class/logger.class';
import { AuthRoutingPath } from '@app/auth/auth-routing.path';
import { AuthorizationService } from '../services/authorization.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (
      this.authorizationService.isAuthenticated() ||
      this.authorizationService.isGuest()
    ) {
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    const loginRoute = `${AuthRoutingPath.AUTH}/${AuthRoutingPath.AUTH_LOGIN}`;
    this.router.navigate([loginRoute], {
      queryParams: { redirect: state.url },
      replaceUrl: true
    });
    return false;
  }
}
