import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { Event } from '@app/core/models/event.model';
import { HttpClient } from '@angular/common/http';

export const EVENT_STATUS = {
  1: { text: 'Aguardando Assinatura', id: 'wait' },
  2: { text: 'Assinado', id: 'sign' }
};

export const EVENT_ACTION = {
  resend: 'Enviar novamente',
  check: 'Verificar documento'
};

/**
 * Provides a base for events/notification list.
 */
@Injectable()
export class EventsService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/mock/events.json';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Gets all data of a service
   * @param filterObject (optional) arguments for filter data.
   */
  getAll(filterObject?: object): Observable<any> {
    return super.getAll(filterObject).pipe(
      map((response: any) => {
        return this._parseData(response);
      })
    );
  }

  /**
   * Filter all data according to the period
   * @param (number) period number of days
   * @param (Event.EventData) period of filter
   */
  filterItems(period: number, data: any) {
    const current = new Date();

    const from = new Date();
    from.setDate(from.getDate() - period);

    return data.filter((item: any) => {
      const date = new Date(item.date);

      return (
        date.getTime() >= from.getTime() && date.getTime() <= current.getTime()
      );
    });
  }

  /**
   * Parse of service data and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseData(items: any): Event.EventData {
    // to do: setar dinamicamente o locale
    moment.locale('pt');
    return items.map((item: any) => {
      return {
        name: item.name,
        initials: this._initials(item.name),
        date: item.date,
        time: moment(new Date(item.date)).fromNow(),
        document: item.document,
        status: EVENT_STATUS[item.status],
        action: item.action,
        actionText: EVENT_ACTION[item.action]
      };
    });
  }

  /**
   * Returns the initials of a name
   *
   * @param (string) name that will be parsed
   * @return (string) name that was parsed
   */
  private _initials(name: string): string {
    const fullname = name.split(' ');
    if (fullname.length === 1) {
      return name.substr(0, 2).toUpperCase();
    } else {
      let index = 1;
      if (fullname[index].length < 3) {
        index++;
      }

      return `${fullname[0].substr(0, 1)}${fullname[index].substr(0, 1)}`;
    }
  }
}
