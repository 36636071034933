import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Logger } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@app/core/helpers/date';

import {
  DocumentService,
  WORKFLOW_STATUS,
  WORKFLOW_TEXT_STATUS
} from '@app/core/services';

const log = new Logger('WorkflowDetailPage');

const LOCAL_STEP = {
  ALL_DOCUMENTS: 0,
  DOCUMENT_SELECT: 1,
  ALL_SIGNATURE_SHEETS: 2,
  SIGNATURE_SHEET_SELECTED: 3
};
@Component({
  selector: 'app-workflow-detail-page',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailPageComponent implements OnInit, OnDestroy {
  LOCAL_STEP = LOCAL_STEP;

  /**
   * template of modal for edit recipient
   */
  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * Workflow id
   */
  workflowID: string;

  /**
   * Document data
   */
  workflow: any;

  workflowStatus: any;

  /**
   * modal
   */
  modalRef: any;

  /**
   * Subscriber
   */
  subscriber = new Subject();

  apiService: any;
  url: any;

  file: any;

  formattedDueDate: string;

  constructor(
    private documentService: DocumentService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {
    this.workflowStatus = {
      id: 0,
      label: ''
    };

    this.url = this.route.url;
    setTimeout(
      (() => {
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );

    this.workflowID = this.route.snapshot.params['id'];

    try {
      let workflowStepObserver: Observable<any>;

      if (this.url.getValue()[1].path === 'workflow') {
        workflowStepObserver = this.documentService.getById(this.workflowID);
      } else {
        workflowStepObserver = this.documentService.getWorkflowStepById(
          this.workflowID
        );
      }

      const subscription = workflowStepObserver.subscribe(
        (response: any) => {
          log.debug(`ngInit getWorkflowStep:`, response);

          this.workflow = response;
          this.workflowStatus = {
            id: response.workflow.status,
            label: WORKFLOW_TEXT_STATUS[response.workflow.status].text
          };
          this.file = this.workflow.documents[0];

          const dueDate = response.workflow.dueDate;
          this.formattedDueDate = dueDate
            ? (dueDate.formattedDate = formatDate(dueDate.date, 'DD/MM/YYYY'))
            : '';

          if (!!subscription) {
            subscription.unsubscribe();
          }

          setTimeout(
            (() => {
              this.modalRef.ref.close();
            }).bind(this),
            4000
          );
        },
        (erro: any) => {
          log.debug(`ngInit getWorkflow error:`, erro);

          if (!!subscription) {
            subscription.unsubscribe();
          }

          this.modalRef.ref.close();
          this.router.navigateByUrl('/manager/manage/workflow', {
            state: {
              error: true
            }
          });
        }
      );
    } catch (e) {
      log.debug(`ngInit getWorkflow js error:`, e);
      this.modalRef.ref.close();
      this.router.navigateByUrl('/manager/manage/workflow', {
        state: {
          error: true
        }
      });
    }
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return modalRef;
  }

  ngOnDestroy() {
    this.subscriber.next();
    this.subscriber.complete();
  }

  public getFileLink(doc: any): string {
    if (!doc || !doc.hasOwnProperty('file')) {
      return '';
    }

    if (doc.hasOwnProperty('signedFile')) {
      return doc.signedFile.link;
    }

    return doc.file.link;
  }

  public optionToDownloadFiles(type: number, doc: any): void {
    let link = '';

    switch (type) {
      case LOCAL_STEP.ALL_DOCUMENTS: {
        doc.forEach((doc: any) => {
          link = this.getFileLink(doc);
          this.downloadFiles(link);
        });
        break;
      }

      case LOCAL_STEP.ALL_SIGNATURE_SHEETS: {
        doc.forEach((doc: any) => {
          if (doc.signaturePageLink) {
            this.downloadFiles(doc.signaturePageLink);
          }
        });
        break;
      }

      case LOCAL_STEP.DOCUMENT_SELECT: {
        link = this.getFileLink(doc);
        this.downloadFiles(link);
        break;
      }

      case LOCAL_STEP.SIGNATURE_SHEET_SELECTED: {
        if (doc.signaturePageLink) {
          this.downloadFiles(doc.signaturePageLink);
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  private downloadFiles(link: string): void {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: link
    }).dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  }

  public isSigned(): boolean {
    return this.workflowStatus.id === WORKFLOW_STATUS.COMPLETED;
  }
}
