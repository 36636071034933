<header>
  <div class="navbar navbar-light">
    <div class="wrapper">
      <div class="brand-nav">
        <h1><a href="/" class="brand large">Assine Online</a></h1>

        <button
          class="navbar-toggler"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="menuPrimary"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div
        class="collapse navbar-collapse"
        id="menuPrimary"
        [ngbCollapse]="isCollapsed"
      >
        <ul class="menu-primary">
          <li class="mobile">
            <a href="/checker">
              <i class="fas fa-check"></i>
              <span class="text">Validador de assinatura</span>
            </a>
          </li>
          <li class="mobile">
            <a href="/auth">
              <i class="fa fa-lock fa-fw"></i>
              <span class="text">Fazer Login</span>
            </a>
          </li>
          <li class="mobile">
            <a routerLink="/register">
              <i class="fa fa-user-plus fa-fw"></i>
              <span class="text">Criar uma conta</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="actions">
      <a routerLink="/register" class="btn btn-sm btn-secondary"
        >Criar uma conta</a
      >
      <a href="/checker" class="btn btn-sm btn-default"
        >Validador de assinatura</a
      >
      <a href="/auth" class="btn btn-sm btn-default">Fazer login</a>
    </div>
  </div>
</header>
