import { PolicyDocument } from '@app/core/models';

export class Documents {
  public static cookies(): PolicyDocument.File {
    const cookies: PolicyDocument.File = {
      title: 'Politica de Cookies',
      path: '/assets/policy/Politica de Cookies.pdf',
      filename: 'Politica de Cookies.pdf'
    };

    return cookies;
  }

  public static privacyPolicy(): PolicyDocument.File {
    const privacyPolicy: PolicyDocument.File = {
      title: 'Politica de Privacidade',
      path: '/assets/policy/Politica de Privacidade.pdf',
      filename: 'Politica de Privacidade.pdf'
    };

    return privacyPolicy;
  }

  public static termsOfUse(): PolicyDocument.File {
    const termsOfUse: PolicyDocument.File = {
      title: 'Termo de uso Assine Online',
      path: '/assets/policy/Termo de uso Assine Online.pdf',
      filename: 'Termo de uso Assine Online.pdf'
    };

    return termsOfUse;
  }
}
