<div class="form-change-password form-modal">
  <form (ngSubmit)="doSubmit()" [formGroup]="passwordForm" novalidate>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label> Senha atual </label>
          <input
            type="password"
            class="form-control form-control-sm"
            [ngClass]="{
              'is-invalid':
                f.currentPassword.errors && !f.currentPassword.pristine
            }"
            formControlName="currentPassword"
          />
          <div *ngIf="f.currentPassword.errors && !f.currentPassword.pristine">
            <span class="error" [hidden]="!f.currentPassword.errors.required">
              A senha atual é obrigatória
            </span>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label> Nova senha </label>
          <input
            type="password"
            class="form-control form-control-sm"
            [ngClass]="{
              'is-invalid': f.newPassword.errors && !f.newPassword.pristine
            }"
            formControlName="newPassword"
          />
          <div *ngIf="f.newPassword.errors && !f.newPassword.pristine">
            <span class="error" [hidden]="!f.newPassword.errors.required">
              A nova senha é obrigatória
            </span>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label> Confirmar nova senha </label>
          <input
            type="password"
            class="form-control form-control-sm"
            [ngClass]="{
              'is-invalid':
                f.confirmNewPassword.errors && !f.confirmNewPassword.pristine
            }"
            formControlName="confirmNewPassword"
          />
          <div
            *ngIf="
              f.confirmNewPassword.errors && !f.confirmNewPassword.pristine
            "
          >
            <span
              class="error"
              [hidden]="!f.confirmNewPassword.errors.unmatched"
            >
              A confirmação da senha deve ser igual a senha
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-default"
          (click)="modal.close('cancel')"
        >
          Cancelar
        </button>
        <button
          class="btn btn-secondary btn-sm"
          type="submit"
          [disabled]="passwordForm.invalid || isLoading"
        >
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>
