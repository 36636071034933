import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Folder } from '../models/folder.model';

@Injectable()
export class FolderService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/folder';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Gets all data of a service
   */
  getAll(querystring?: any): Observable<Folder.FolderCollection> {
    return this.httpClient
      .get(this.endpoint + (querystring ? querystring : ''))
      .pipe(
        map((response: any) => {
          return this._parseFolders(response);
        })
      );
  }

  /**
   * Gets all data of a service
   */
  searchFolder(queryString?: any): Observable<Folder.FolderCollection> {
    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this._parseFolders(response);
      })
    );
  }

  /**
   * Rename folder
   */
  renameFolder(id: number, name: string): Observable<any> {
    return this.httpClient.patch(`${this.endpoint}/${id}`, { name });
  }

  /**
   * Create a default folder
   */
  createDefaultFolder(): Observable<any> {
    const payload = {
      name: 'default',
      path: 'default'
    };

    return super.create(payload).pipe(
      map((response: any) => {
        return this._parseFolders(response);
      })
    );
  }

  moveFolderDocument(idDoc: string, idFolder: object) {
    return this.httpClient.patch(`/document/${idDoc}`, idFolder);
  }

  public getFolderDocuments(id: any, page: any): Observable<any> {
    return this.httpClient.get(
      '/document?filter[0][type]=eq&filter[0][field]=folder&filter[0][value]=' +
        id +
        '&page=' +
        page +
        '&pageSize=28'
    );
  }

  /**
   * Parse list of folders and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseFolders(data: any): Folder.FolderCollection {
    let folders: Folder.Folder[] = [];

    if (data.total_items > 0) {
      folders = data._embedded.folder.map((folder: Folder.Folder) => {
        let folderIntegration = null;
        if (
          folder.folderIntegration &&
          !this.isEmptyObject(folder.folderIntegration)
        ) {
          folderIntegration = {
            id: folder.folderIntegration.id,
            metadata: JSON.parse(folder.folderIntegration.metadata)
          };
        }
        const { id, name, path, businessUnit } = folder;

        return {
          id,
          name,
          path,
          businessUnit,
          owner: folder._embedded.user,
          folderIntegration: folderIntegration
        };
      });
    }

    return {
      items: folders,
      default: folders.length > 0 ? folders[0] : null,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items,
      _raw: data._embedded,
      _links: data._links
    };
  }

  public isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
}
