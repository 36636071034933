/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./action-feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./action-feedback.component";
var styles_ActionFeedbackComponent = [i0.styles];
var RenderType_ActionFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionFeedbackComponent, data: {} });
export { RenderType_ActionFeedbackComponent as RenderType_ActionFeedbackComponent };
export function View_ActionFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "col-12 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "row d-flex justify-content-end "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "col-12 d-flex justify-content-end "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-default m-1"], ["title", "Cancelar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shouldModalClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          Cancelar\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-danger btn-sm m-1"], ["title", "Excluir"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          Excluir\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 3, 0, currVal_0); }); }
export function View_ActionFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-action-feedback", [], null, null, null, View_ActionFeedbackComponent_0, RenderType_ActionFeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.ActionFeedbackComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionFeedbackComponentNgFactory = i1.ɵccf("app-action-feedback", i2.ActionFeedbackComponent, View_ActionFeedbackComponent_Host_0, { modal: "modal", text: "text", payload: "payload" }, {}, []);
export { ActionFeedbackComponentNgFactory as ActionFeedbackComponentNgFactory };
