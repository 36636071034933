import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../class/logger.class';
import { AuthorizationService } from '../services/authorization.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private authorizationService: AuthorizationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(error => this.errorHandler(error)));
  }

  /**
   * If HTTP status error is 401, clean Local Storage and redirect to /
   * Commented user key removeItem call because we have to use `username` field on login form
   */
  private errorHandler(
    response: HttpResponse<any>
  ): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }

    if (response.status === 401 && response.url.indexOf('/oauth') === -1) {
      log.error('Token expired - Redirecting to /');
      this.authorizationService.deleteCredentials();
      this.authorizationService.deleteUser();
      location.assign('/');
    }

    throw response;
  }
}
