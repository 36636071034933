<div class="form-contact form-modal">
  <form
    (ngSubmit)="deleteContact()"
    [formGroup]="deleteContactForm"
    novalidate
    [hidden]="!selected"
  >
    <div class="row">
      <p>
        O contato <strong>{{ selected?.name }}</strong> está sendo
        <strong>Excluído</strong>. A página será atualizada.
      </p>
    </div>

    <div class="row">
      <div class="col-12 text-center mt-5 mb-3">
        <button class="btn btn-default" (click)="modal.close('cancel')">
          Cancelar
        </button>
        <button class="btn btn-danger btn-sm" type="submit">Excluir</button>
      </div>
    </div>
  </form>
</div>
