<div class="document-wrapper">
  <div class="row">
    <div class="col col-md-7 d-flex justify-content-end">
      <div class="document-toolbar" *ngIf="showToolbar">
        <ul>
          <li>
            <a [href]="getFileURL()" title="download">
              <i class="fa fa-download"></i>
            </a>
          </li>
          <li>
            <a (click)="printFile()" title="imprimir">
              <i class="fa fa-print"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col col-md-5 d-flex justify-content-end">
      <div class="d-flex justify-content-end sign-location" *ngIf="recipients">
        <button
          type="button"
          class="button"
          (click)="changeToPageExpectingSignature((pageSign = pageSign - 1))"
          [disabled]="pageSign === -1 || pageSign === 0"
          title="Página anterior"
        >
          <i class="fa fa-angle-left"></i>
        </button>

        <span title="Localizar assinatura">Localizar assinatura</span>

        <button
          type="button"
          class="button"
          (click)="changeToPageExpectingSignature((pageSign = pageSign + 1))"
          [disabled]="
            pageSign === getTotalPage() || pageSign === getTotalPage() - 1
          "
          title="Próxima página"
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="document-navigation top text-center"
    *ngIf="isLoaded && (navigation === 'top' || navigation === 'both')"
  >
    <div class="document-navigation__content">
      <button
        type="button"
        class="button"
        title="Primeira página"
        (click)="changePage(1)"
        [disabled]="page === 1"
      >
        <i class="fa fa-angle-double-left"></i>
      </button>
      <button
        type="button"
        class="button"
        title="Página anterior"
        (click)="changePage(page - 1)"
        [disabled]="page === 1"
      >
        <i class="fa fa-angle-left"></i>
      </button>
      <span><strong>Páginas</strong> {{ page }}/{{ getPDFTotalPages() }}</span>
      <button
        type="button"
        class="button"
        title="Próxima página"
        (click)="changePage(page + 1)"
        [disabled]="page === getPDFTotalPages()"
      >
        <i class="fa fa-angle-right"></i>
      </button>
      <button
        type="button"
        class="button"
        title="Última página"
        (click)="changePage(getPDFTotalPages())"
        [disabled]="page === getPDFTotalPages()"
      >
        <i class="fa fa-angle-double-right"></i>
      </button>
    </div>
  </div>

  <div class="pdf-loading d-flex align-items-center" *ngIf="isRendering">
    <div class="progress-wrapper">
      <div class="progress">
        <div
          class="progress-bar bg-secondary"
          role="progressbar"
          [ngStyle]="{ 'width.%': renderProgress }"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>

  <div id="document-block" class="document-container" *ngIf="file || fileUrl">
    <app-workflow-droppable-fields-zone
      [pdfTotalPages]="this.getPDFTotalPages()"
    >
      <pdf-viewer
        [src]="getFileURL()"
        [page]="page"
        [original-size]="false"
        [fit-to-page]="true"
        [show-all]="false"
        [render-text]="false"
        [autoresize]="true"
        (after-load-complete)="afterLoadComplete($event)"
        (on-progress)="onProgress($event)"
        (page-rendered)="onPageRendered($event)"
        style="display: block; height: 100%;"
      ></pdf-viewer>
    </app-workflow-droppable-fields-zone>
  </div>

  <div
    class="document-file-not-found text-center"
    [hidden]="!showUpload || hasFiles() || hasFileReady()"
  >
    <app-upload
      buttonText="Começar"
      dragDropText="Selecione ou arraste e solte seus documentos aqui para começar"
      showModalStatus="true"
      [layout]="'clean'"
    ></app-upload>
  </div>

  <div
    class="document-navigation text-center"
    *ngIf="isLoaded && (navigation === 'bottom' || navigation === 'both')"
  >
    <button
      type="button"
      class="button"
      title="Primeira página"
      (click)="changePage(1)"
      [disabled]="page === 1"
    >
      <i class="fa fa-angle-double-left"></i>
    </button>
    <button
      type="button"
      class="button"
      title="Página anterior"
      (click)="changePage(page - 1)"
      [disabled]="page === 1"
    >
      <i class="fa fa-angle-left"></i>
    </button>
    <span><strong>Páginas</strong> {{ page }}/{{ getPDFTotalPages() }}</span>
    <button
      type="button"
      class="button"
      title="Próxima página"
      (click)="changePage(page + 1)"
      [disabled]="page === getPDFTotalPages()"
    >
      <i class="fa fa-angle-right"></i>
    </button>
    <button
      type="button"
      class="button"
      title="Última página"
      (click)="changePage(getPDFTotalPages())"
      [disabled]="page === getPDFTotalPages()"
    >
      <i class="fa fa-angle-double-right"></i>
    </button>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="'Carregando documento...'"
  >
  </app-modal>
</ng-template>
