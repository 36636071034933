/**
 * Constants of document
 */
export const WORKFLOW_STEP_ACTION = {
  0: { text: 'Assinante', action: 'sign' },
  1: { text: 'Aprovador', action: 'approve' },
  2: { text: 'Visualizador', action: 'view' }
};

export const WORKFLOW_TEXT_PRIORITY = {
  0: { text: 'Normal', action: 'normal' },
  1: { text: 'Urgente', action: 'urgent' },
  2: { text: 'Muito Urgente', action: 'very_urgent' }
};

export const WORKFLOW_STEP_TEXT_STATUS = {
  0: { text: 'Aguardando assinatura', action: 'waiting' },
  1: { text: 'Aguardando Assinatura', action: 'waiting' },
  2: { text: 'Aguardando Assinatura', action: 'waiting' },
  3: { text: 'Processando', action: 'processing' },
  4: { text: 'Assinado', action: 'done' },
  5: { text: 'Rejeitado', action: 'reject' }
};

export const WORKFLOW_TEXT_STATUS = {
  0: { text: 'Rascunho', action: 'draft' },
  1: { text: 'Aguardando assinatura', action: 'circulating' },
  2: { text: 'Cancelado', action: 'cancelled' },
  3: { text: 'Expirado', action: 'expired' },
  4: { text: 'Pendente', action: 'pending' },
  5: { text: 'Rejeitado', action: 'reject' },
  6: { text: 'Assinado', action: 'sign' }
};

export const WORKFLOW_STEP_NEXT_ACTION = {
  0: { text: 'Assinar', action: 'resend' },
  1: { text: 'Verificar documento', action: 'check' },
  5: { text: 'Rejeitado', action: 'check' }
};

export const WORKFLOW_SIGNATURE_TYPE = {
  0: { text: 'Assinatura Eletrônica', action: 'eletronic' },
  1: { text: 'Assinatura com BirdID', action: 'digital' },
  2: { text: 'Assinatura Presencial', action: 'in_preson' },
  3: { text: 'Assinatura Rubrica', action: 'rubric' },
  4: { text: 'Assinatura Carimbo', action: 'stamp' }
};

export const WORKFLOW_STATUS = {
  DRAFT: 0,
  CIRCULATING: 1,
  CANCELLED: 2,
  EXPIRED: 3,
  PENDING: 4,
  REJECTED: 5,
  COMPLETED: 6
};

export const WORKFLOW_STEP_STATUS = {
  NOT_STARTED: 0,
  WAITING: 1,
  WAITING_START: 2,
  PROCESSING: 3,
  APPROVED: 4,
  REJECTED: 5
};

export const WORKFLOW_CONTACT_ACTION = {
  SIGN: 0,
  APPROVE: 1,
  VIEW: 2
};

export const WORKFLOW_SIGNATURE = {
  ELETRONIC: 0,
  DIGITAL: 1,
  IN_PERSON: 2,
  RUBRIC: 3,
  STAMP: 4
};

export const WORKFLOW_PRIORITY = {
  NORMAL: 0,
  URGENT: 1,
  VERY_URGENT: 2
};

export const WORKFLOW_PAGE_STEPS = {
  BUILD: 0,
  REVIEW: 1,
  CONFIRMATION: 2
};

export const WORKFLOW_STEP_FIELDS = {
  TYPE_NAME_TEXT: 0,
  TYPE_EMAIL_TEXT: 1,
  TYPE_CELLPHONE_TEXT: 2,
  TYPE_DOCUMENT_TEXT: 3,
  TYPE_DATE_TEXT: 4,
  TYPE_BUSINESS_UNIT_TEXT: 5,
  TYPE_PLAIN_TEXT: 6,
  TYPE_PLAIN_TEXT_REQUIRED: 7,
  TYPE_VISIBLE_SIGNATURE: 8,
  QR_CODE: 9,
  QR_CODE_SHARE: 10,
  TYPE_DATE_SIGNATURE_TEXT: 11,
  TYPE_COMBOBOX: 12,
  TYPE_COMBOBOX_REQUIRED: 13,
  TYPE_CHECKBOX: 14,
  TYPE_CHECKBOX_REQUIRED: 15,
  TYPE_INVISIBLE_SIGNATURE: 16,
  TYPE_VISIBLE_SIGNATURE_INLINE: 17,
  TYPE_PLAIN_TEXT_SPECIAL_NAME: 18,
  TYPE_RUBRIC: 19,
  TYPE_STAMP: 20
};

export const VISIBLE_SIGNATURES = [
  WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
  WORKFLOW_STEP_FIELDS.TYPE_STAMP
];

export const CHECK_WORKFLOW_STEP = {
  WAITING: 'waiting',
  PROCESSING: 'processing',
  REJECT: 'reject',
  DONE: 'done'
};

export const FILES = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};

export const EVENT_TYPES = {
  FLOW_PENDING_ACTION: 'Um fluxo de assinatura requer a sua atenção',
  FLOW_FINISHED: 'Fluxo finalizado com sucesso!'
};

export const COUNTRY_CODES = {
  BR: '55'
};

export const WORKFLOW_STEP_REPORT = {
  0: { text: 'Não iniciado', action: 'not starded' },
  1: { text: 'Aguardando Assinatura', action: 'waiting' },
  2: { text: 'Aguardando Assinatura', action: 'awaiting process start' },
  3: { text: 'Processando', action: 'processing' },
  4: { text: 'Assinado', action: 'approved' },
  5: { text: 'Rejeitado', action: 'reject' }
};

export const SIGNATURE_TYPE_INFO = {
  0: { text: 'Desenhada', action: 'drawn' },
  1: { text: 'Importada', action: 'uploaded' },
  2: { text: 'Gerada', action: 'generated' }
};

export const SIGNATURE_TYPE = {
  DRAWING: 0,
  UPLOADING: 1,
  GENERATING: 2,
  RUBRIC: 3,
  STAMP: 4,
  GENERIC: 20
};

export const SELECTION_TYPE = {
  SIGN: 'sign',
  WIEW: 'view',
  INIT: 'init',
  WAKEUP: 'wakeup',
  CANCEL: 'cancel',
  CLONE: 'clone',
  DOWNLOAD: 'download'
};
