import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-in-text',
  templateUrl: './in-text.component.html'
})
export class InTextComponent implements OnInit {
  @Output() public cancel = new EventEmitter();
  @Output() public submit = new EventEmitter();

  private formBuilder: FormBuilder;

  public propertiesField: FormGroup;

  constructor(formBuilder: FormBuilder) {
    this.cancel = new EventEmitter<null>();
    this.formBuilder = formBuilder;
  }

  ngOnInit() {
    this.createForm();
  }

  public onSubmit(): boolean {
    this.submit.emit(this.propertiesField.value);
    return false;
  }

  public onCancel(): boolean {
    this.cancel.emit();
    return false;
  }

  private createForm(): void {
    this.propertiesField = this.formBuilder.group({
      title: [''],
      description: ['']
    });
  }
}
