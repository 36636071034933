import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUploaderModule } from 'ngx-uploader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularDraggableModule } from 'angular2-draggable';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureFieldComponent } from '@app/shared/components/signature/signature-type-form/signature-field/signature-field.component';

import { Page404Component } from '@app/shared/pages';
import { SignActionPipe, CapitalizePipe } from '@app/core/pipes';
import {
  DropdownLanguagesComponent,
  PushNotificationComponent,
  PlanDetailComponent,
  UploadComponent,
  ModalComponent,
  ModalActionComponent,
  SubscribersComponent,
  ListEventsComponent,
  RejectionFormComponent,
  SignatureTypeFormComponent,
  PaginationComponent,
  WorkflowDroppableFieldsZoneComponent,
  WorkflowDroppableFieldComponent,
  WorkflowInPersonSignatureFieldComponent,
  WorkflowInPersonSignatureFieldFormComponent,
  WorkflowEletronicSignatureFieldComponent,
  WorkflowDigitalSignatureFieldComponent,
  WorkflowDroppableTextFieldComponent,
  WorkflowDroppableSignDateFieldComponent,
  WorkflowDroppableSharedQrCodeFieldComponent,
  WorkflowDroppableQrCodeFieldComponent,
  WorkflowDroppableNameFieldComponent,
  WorkflowDroppableEmailFieldComponent,
  WorkflowDraggableFieldListComponent,
  WorkflowDroppableVisibleSignatureFieldComponent,
  WorkflowBuildContactFormComponent,
  WorkflowBuildContactSelectionComponent,
  WorkflowBuildContactsComponent,
  ContactsAddComponent,
  WorkflowDroppableRubricFieldComponent,
  CookiesComponent,
  ToggleSwitchComponent
} from '@app/shared/components';

import {
  LayoutSimpleComponent,
  LayoutManagerComponent,
  LayoutDefaultComponent,
  LayoutDefaultHeaderComponent,
  LayoutSimpleHeaderComponent,
  LayoutSimpleFooterComponent,
  LayoutManagerHeaderComponent,
  LayoutManagerFooterComponent
} from '@app/shared/layouts';

import { FeedbackComponent } from './components/feedback/feedback.component';
import { FooterComponent } from './layouts/default/footer/footer.component';
import { LayoutPublicComponent } from './layouts/public/public.component';
import { WorkflowDocumentComponent } from './components/workflow/workflow-document.component';
import { WorkflowDroppableInlineSignatureFieldComponent } from './components/workflow/droppable-fields-zone/droppable-field/fields/inline-signature/inline-signature.component';
import { VisibleSignatureComponent } from './components/visible-signature/visible-signature.component';
import { ChooseSubscriptionComponent } from './components/visible-signature/choose-subscription/choose-subscription.component';
import { DrawSignatureComponent } from './components/visible-signature/draw-signature/draw-signature.component';
import { UploadSignatureComponent } from './components/visible-signature/upload-signature/upload-signature.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { InTextComponent } from './components/workflow/droppable-fields-zone/droppable-field/fields/text/in-text/in-text.component';
import { RemainingUserBalanceComponent } from './components/remaining-user-balance/remaining-user-balance.component';
import { FeedbackRubricComponent } from './components/workflow/droppable-fields-zone/droppable-field/fields/rubric/feedback-rubric/feedback-rubric.component';
import { WorkflowStampSignatureFieldComponent } from './components/workflow/droppable-fields-zone/droppable-field/fields/stamp-signature/stamp-signature.component';
import { FeedbackStampComponent } from './components/workflow/droppable-fields-zone/droppable-field/fields/stamp-signature/feedback-stamp/feedback-stamp.component';

@NgModule({
  declarations: [
    // layouts
    LayoutSimpleComponent,
    LayoutManagerComponent,
    LayoutDefaultComponent,
    LayoutPublicComponent,

    // components of layouts
    LayoutDefaultHeaderComponent,
    LayoutSimpleHeaderComponent,
    LayoutSimpleFooterComponent,
    LayoutManagerHeaderComponent,
    LayoutManagerFooterComponent,

    // pages
    Page404Component,

    // components
    DropdownLanguagesComponent,
    ListEventsComponent,
    PushNotificationComponent,
    UploadComponent,
    ModalComponent,
    SubscribersComponent,
    ModalActionComponent,
    PlanDetailComponent,
    FeedbackComponent,
    CookiesComponent,
    ToggleSwitchComponent,

    RejectionFormComponent,
    SignatureTypeFormComponent,

    PaginationComponent,
    SignatureFieldComponent,

    // Workflow Components
    WorkflowDocumentComponent,
    // contacts
    WorkflowBuildContactsComponent,
    WorkflowBuildContactFormComponent,
    WorkflowBuildContactSelectionComponent,
    // drag-n-drop
    WorkflowDraggableFieldListComponent,
    WorkflowDroppableFieldsZoneComponent,
    WorkflowDroppableFieldComponent,
    WorkflowDroppableTextFieldComponent,
    WorkflowDroppableSignDateFieldComponent,
    WorkflowDroppableQrCodeFieldComponent,
    WorkflowDroppableSharedQrCodeFieldComponent,
    WorkflowDroppableNameFieldComponent,
    WorkflowDroppableEmailFieldComponent,
    // drag-n-drop signatures
    WorkflowDroppableVisibleSignatureFieldComponent,
    WorkflowEletronicSignatureFieldComponent,
    WorkflowDigitalSignatureFieldComponent,
    WorkflowInPersonSignatureFieldComponent,
    WorkflowInPersonSignatureFieldFormComponent,
    WorkflowDroppableInlineSignatureFieldComponent,
    WorkflowDroppableRubricFieldComponent,
    WorkflowStampSignatureFieldComponent,

    // Visible Signature PAD
    VisibleSignatureComponent,
    ChooseSubscriptionComponent,
    DrawSignatureComponent,
    UploadSignatureComponent,

    // pipes
    SignActionPipe,
    CapitalizePipe,
    FooterComponent,

    ContactsAddComponent,
    InTextComponent,
    RemainingUserBalanceComponent,
    FeedbackRubricComponent,
    FeedbackStampComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgxUploaderModule,
    DragDropModule,
    AngularDraggableModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    TranslateModule.forChild(),
    NgxMaskModule.forRoot(),
    SignaturePadModule,
    NgSelectModule
  ],
  entryComponents: [ModalComponent, ModalActionComponent],
  exports: [
    DropdownLanguagesComponent,
    ListEventsComponent,
    PushNotificationComponent,
    UploadComponent,
    ModalComponent,
    ModalActionComponent,
    PlanDetailComponent,
    SubscribersComponent,
    FeedbackComponent,
    RejectionFormComponent,
    SignatureTypeFormComponent,
    SignActionPipe,
    CapitalizePipe,
    PaginationComponent,
    LayoutManagerFooterComponent,

    // Workflow Components
    WorkflowDocumentComponent,
    // contacts
    WorkflowBuildContactsComponent,
    WorkflowBuildContactFormComponent,
    WorkflowBuildContactSelectionComponent,
    // drag-n-drop
    WorkflowDraggableFieldListComponent,
    WorkflowDroppableFieldsZoneComponent,
    WorkflowDroppableFieldComponent,
    WorkflowDroppableTextFieldComponent,
    WorkflowDroppableSignDateFieldComponent,
    WorkflowDroppableQrCodeFieldComponent,
    WorkflowDroppableSharedQrCodeFieldComponent,
    WorkflowDroppableNameFieldComponent,
    WorkflowDroppableEmailFieldComponent,
    // drag-n-drop signatures
    WorkflowDroppableVisibleSignatureFieldComponent,
    WorkflowEletronicSignatureFieldComponent,
    WorkflowDigitalSignatureFieldComponent,
    WorkflowInPersonSignatureFieldComponent,
    WorkflowInPersonSignatureFieldFormComponent,
    WorkflowDroppableInlineSignatureFieldComponent,

    // Visible Signature PAD
    VisibleSignatureComponent,
    ChooseSubscriptionComponent,
    DrawSignatureComponent,
    UploadSignatureComponent,
    ContactsAddComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
