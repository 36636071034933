<div class="field-block field-email">
  <div class="simple-text">{{ properties }}</div>
</div>

<div class="actions-list">
  <button
    title="Clique para editar o campo"
    (click)="open(addOptionalFieldsModal)"
    class="actions-list__item"
  >
    <i class="fa fa-pen"></i>
  </button>
</div>

<ng-template #addOptionalFieldsModal let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Campos opcionais'"
  >
    <app-in-text (cancel)="onCancel()" (submit)="onSubmit($event)">
    </app-in-text>
  </app-modal-action>
</ng-template>
