import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { AuthorizationService, WorkflowBuildService, FeedbackService, UserService } from '@app/core/services';
export var PAGE_STEPS = {
    STEP_ERROR: -1,
    STEP_BUILD: 0,
    STEP_REVIEW: 1,
    STEP_FINISH: 2
};
var WorkflowPageComponent = /** @class */ (function () {
    function WorkflowPageComponent(authorizationService, workflowBuildService, feedbackService, router, userService) {
        this.authorizationService = authorizationService;
        this.workflowBuildService = workflowBuildService;
        this.feedbackService = feedbackService;
        this.router = router;
        this.userService = userService;
        this.STEP_ERROR = PAGE_STEPS.STEP_ERROR;
        this.STEP_BUILD = 1;
        this.STEP_REVIEW = 2;
        this.STEP_FINISH = 3;
        this.buildURL = '/manager/workflow';
        this.reviewURL = '/manager/workflow/review';
        this.finishURL = '/manager/workflow/finish';
        /**
         * Feedback message
         */
        this.feedback = { message: null, theme: null };
    }
    WorkflowPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.myUser();
        this.feedbackSubscription = this.feedbackService
            .observeFeedback()
            .subscribe(function (feedback) {
            _this.feedback = feedback;
        });
        this.fetchDefaultFolder();
    };
    WorkflowPageComponent.prototype.ngOnDestroy = function () {
        if (!!this.feedbackSubscription) {
            this.feedbackSubscription.unsubscribe();
        }
        this.workflowBuildService.emptyFiles();
    };
    WorkflowPageComponent.prototype.hasWorkflowfiles = function () {
        return this.workflowBuildService.hasFiles();
    };
    WorkflowPageComponent.prototype.stepClass = function (step) {
        var isOnBuild = this.router.isActive(this.buildURL, false);
        var isOnReview = this.router.isActive(this.reviewURL, false);
        var isOnFinish = this.router.isActive(this.finishURL, false);
        var curStep = 0;
        if (isOnFinish) {
            curStep = 4;
        }
        else if (isOnReview) {
            curStep = 2;
        }
        else if (isOnBuild) {
            curStep = 1;
        }
        return { current: curStep === step, done: curStep > step };
    };
    WorkflowPageComponent.prototype.fetchDefaultFolder = function () {
        var _this = this;
        var subs = from(this.workflowBuildService.fetchDefaultFolder()).subscribe(function (defaultFolderId) {
            _this.workflowBuildService.setDefaultFolderId(defaultFolderId.id);
            _this.workflowBuildService.setDefaultFolderName(defaultFolderId.name);
            subs.unsubscribe();
        });
    };
    WorkflowPageComponent.prototype.myUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userService.me().toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return WorkflowPageComponent;
}());
export { WorkflowPageComponent };
