import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContactService } from '@app/core/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacts-add',
  templateUrl: './contacts-add.component.html',
  styleUrls: ['./contacts-add.component.scss']
})
export class ContactsAddComponent implements OnInit {
  contacts: any = [];

  @Input() selectedContacts?: any = [];

  @Input() blackList?: Array<number> = [];

  @Output() addContact? = new EventEmitter();

  @Output() removeContact? = new EventEmitter();

  contactForm: FormGroup;

  showForm: boolean;

  constructor(
    private contactService: ContactService,
    private formBuilder: FormBuilder
  ) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.contactService.getAll().subscribe((response: any) => {
      this.contacts = this.contactsFilter(response.items);
    });
    this.showForm = false;
  }

  onSearch(value: any) {
    const queryString = {
      filter: {}
    };
    if (value) {
      queryString.filter = [
        {
          type: 'innerjoin',
          field: 'userTwo',
          alias: 'u'
        },
        {
          type: 'like',
          alias: 'u',
          field: 'name',
          value: `${value}%`
        }
      ];
    }
    this.contactService.getAll(queryString).subscribe((response: any) => {
      this.contacts = this.contactsFilter(response.items);
    });
  }

  contactsFilter(items: Array<any>) {
    if (!this.blackList) {
      return items;
    }
    return items.filter((item: any) => {
      return !this.blackList.includes(item.userId);
    });
  }

  onAdd(value: any) {
    this.addContact.emit(value);
  }
  onRemove(value: any) {
    this.removeContact.emit(value);
  }
  createContact() {
    this.contactService.addContact(this.contactForm.value).subscribe(() => {
      this.contactService.getAll().subscribe((response: any) => {
        this.contacts = this.contactsFilter(response.items);
      });
    });
    this.showForm = false;
    this.contactForm.reset();
  }
}
