import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  /**
   * Total of items in database
   */
  @Input() totalItems: number;

  /**
   * Total of pages
   */
  @Input() pageCount: number;

  /**
   * Current Page
   */
  @Input() currentPage: number;

  /**
   * Change Page
   */
  @Output() doPage? = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  /**
   * Go to next page
   */
  doNext() {
    if (this.currentPage < this.pageCount) {
      this.doPage.emit(this.currentPage + 1);
    }
  }

  /**
   * Go to prev page
   */
  doPrev() {
    if (this.currentPage > 1) {
      this.doPage.emit(this.currentPage - 1);
    }
  }

  /**
   * Change Page
   *
   * @param (number) number of page
   */
  changePage(p: number) {
    this.doPage.emit(p);
  }
}
