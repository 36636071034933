import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService, WorkflowTemplateService, WorkflowBuildService } from '@app/core/services';
import { Logger, WorkflowContact, WorkflowFile } from '@app/core/class';
import * as moment from 'moment';
import { debounce } from 'lodash';
import { FormBuilder, Validators } from '@angular/forms';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
var log = new Logger('TemplatesPageComponent');
var TemplatesPageComponent = /** @class */ (function () {
    function TemplatesPageComponent(authorizationService, workflowTemplateService, workflowBuildService, modalService, route, router, formBuilder) {
        this.authorizationService = authorizationService;
        this.workflowTemplateService = workflowTemplateService;
        this.workflowBuildService = workflowBuildService;
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        /**
         * selected items of list
         */
        this.selectedItems = [];
        this.loadingDetails = false;
        this.queryStringFilter = {};
        this.searchField = '';
        this.searchPeriod = '';
        this.collectionDocument = { items: [] };
        this.modalRef = { ref: null, data: { text: '' } };
        this.pagination = {
            totalItems: 0,
            pageCount: 0,
            pageSize: 0,
            currentPage: 1
        };
        this.currentRoute = '/manager/manage/templates';
        this.searchDebounce = debounce(this.search.bind(this), 300);
        this.createRenameWorkflowForm();
    }
    TemplatesPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando dados...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.authorizationService.user.subscribe(function (user) {
            _this.userLogged = user;
        });
        combineLatest(this.route.params, this.route.queryParams)
            .pipe(map(function (params, qparams) { return ({ params: params, qparams: qparams }); }))
            .subscribe(function (p) {
            if (p.params.page) {
                _this.currentPage = p.params.page;
            }
            if (p.params.sort) {
                _this.currentSort = p.params.sort;
            }
            if (p.params.sortBy) {
                _this.currentSortBy = p.params.sortBy;
            }
            _this.getData({
                page: p.qparams.page,
                sortBy: p.qparams.sortBy,
                sort: p.qparams.sort
            }, false);
        });
        this.sortFields = { id: 'DESC', status: 'DESC' };
    };
    /**
     * provides the data of workflows with params
     *
     * @return void
     */
    TemplatesPageComponent.prototype.getData = function (params, isFiltered) {
        if (isFiltered === void 0) { isFiltered = true; }
        var payload = {
            page: params.page ? params.page : 1,
            area: params.area ? params.area : 'all',
            sortBy: params.sortBy ? params.sortBy : 'id',
            sort: params.sort ? params.sort : 'desc'
        };
        this.getWorkflowTemplates(payload, isFiltered);
    };
    TemplatesPageComponent.prototype.search = function (page) {
        if (page === void 0) { page = 1; }
        this.queryStringFilter = {};
        var filter = [];
        var sort = [];
        var params = { page: page };
        if (this.searchPeriod) {
            var format = 'YYYYMMDD';
            var from = moment();
            from.subtract(this.searchPeriod, 'days');
            var value = from.format(format) + '000000';
            filter.push({
                type: 'gte',
                field: 'dateCreated',
                value: value,
                direction: 'desc'
            });
        }
        if (this.searchField) {
            filter.push({
                type: 'like',
                field: 'name',
                value: "%" + this.searchField + "%"
            });
        }
        if (filter.length > 0) {
            sort.push({ type: 'field', field: 'id', direction: 'desc' });
        }
        this.queryStringFilter = {
            filter: filter,
            'order-by': sort,
            page: 1
        };
        this.getData(params, filter.length > 0);
    };
    /**
     * provides the data of workflow templates
     *
     * @return void
     */
    TemplatesPageComponent.prototype.getWorkflowTemplates = function (params, isFiltered) {
        var _this = this;
        try {
            var queryString = void 0;
            this.configList = [
                {
                    name: 'Nome',
                    klass: '',
                    type: 'text',
                    fieldId: 'name'
                },
                {
                    name: 'Data',
                    klass: '',
                    type: 'text',
                    fieldId: 'dateCreated.formated_date',
                    fieldKey: 'id',
                    isSortable: true
                },
                {
                    name: 'Ações',
                    type: 'select',
                    icon: 'fa fa-sliders-h',
                    width: '9%',
                    isRestrict: true,
                    options: {
                        changeLabel: true,
                        items: [
                            {
                                name: 'Utilizar',
                                action: 'use'
                            },
                            // TODO: descomentar esta opção quando o backend
                            //       aceitar as requisições de atualização.
                            // {
                            //   name: 'Renomear',
                            //   action: 'rename'
                            // },
                            {
                                name: 'Excluir',
                                action: 'remove'
                            }
                        ]
                    }
                }
            ];
            if (!isFiltered) {
                queryString = {
                    'order-by': [
                        {
                            type: 'field',
                            field: params.sortBy,
                            direction: params.sort
                        }
                    ],
                    page: params.page
                };
            }
            else {
                queryString = this.queryStringFilter;
                queryString.page = params.page;
            }
            this.workflowTemplateService.getAll(queryString).subscribe(function (response) {
                log.debug("Success [getWorkflowTemplates]:", response);
                _this.collectionDocument = response;
                _this.pagination = {
                    totalItems: response.total_items,
                    pageCount: response.page_count,
                    pageSize: response.page_size,
                    currentPage: response.page
                };
                if (_this.modalRef.ref) {
                    _this.modalRef.ref.close();
                }
            }, function (error) {
                log.debug("Error [getWorkflowTemplates]:", error);
                _this.collectionDocument = { items: [] };
                _this.modalRef.ref.close();
            });
        }
        catch (e) {
            log.debug("Error [getWorkflowTemplates]:", e);
            this.collectionDocument = { items: [] };
            this.modalRef.ref.close();
        }
    };
    /**
     * Action grab all selected items
     *
     * @param (any) items selected
     */
    TemplatesPageComponent.prototype.getSelectedItems = function (items) {
        this.selectedItems = items;
    };
    /**
     * Action grab clicked item
     *
     * @param (any) clicked item
     */
    TemplatesPageComponent.prototype.getClickedItem = function (item) {
        var _this = this;
        var id;
        if (item.step && item.step.id) {
            id = item.step.id;
        }
        if (!id) {
            return;
        }
        try {
            this.loadingDetails = true;
            this.workflowTemplateService
                .getWorkflowTemplateById(id)
                .pipe(finalize(function () {
                _this.loadingDetails = false;
            }))
                .subscribe(function (response) {
                log.debug("Success [getClickedItem]:", response);
                _this.details = response;
            }, function (error) {
                log.debug("Error [getClickedItem]:", error);
                _this.details = error;
            });
        }
        catch (e) {
            log.debug("Error [getClickedItem]:", e);
            this.details = e;
        }
    };
    /**
     * Action grab data
     *
     * @param (any) items selected
     */
    TemplatesPageComponent.prototype.doAction = function (event) {
        log.debug("doAction:", event);
        switch (event.type) {
            case 'use':
                this.doActionUse(event);
                break;
            case 'rename':
                this.renameWorkflowForm.setValue({
                    id: event.data.id,
                    name: event.data.name
                });
                this.item = event.data;
                this.open(this.modalRenameTemplate);
                break;
            case 'remove':
                this.item = event.data;
                this.open(this.modalDeleteTemplate);
                break;
            default:
                break;
        }
    };
    /**
     * Action sort list
     *
     * @param (string) field sort
     */
    TemplatesPageComponent.prototype.doSort = function (field) {
        var _this = this;
        this.sortFields[field] = this.reorder(this.sortFields[field]);
        if (this.sortFields[field]) {
            setTimeout((function () {
                _this.modalRef.data.text = 'carregando dados...';
                _this.modalRef.ref = _this.open(_this.loading);
            }).bind(this));
            var payload = {
                sort: this.sortFields[field],
                sortBy: field,
                page: 1
            };
            this.currentSort = payload.sort;
            this.currentSortBy = payload.sortBy;
            this.router.navigate([this.currentRoute], {
                replaceUrl: true,
                queryParams: payload
            });
            this.getData(payload, false);
        }
    };
    /**
     * Action for change page
     *
     * @param (number) of page
     */
    TemplatesPageComponent.prototype.onChangePage = function (pageNumber) {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando dados...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.search(pageNumber);
    };
    TemplatesPageComponent.prototype.submitRenameWorkflow = function () {
        var _this = this;
        this.workflowTemplateService
            .renameWorkflowTemplate(this.item.id, this.item.name)
            .subscribe(function (response) {
            var template = _this.collectionDocument.items.find(function (i) { return i.id === _this.item.id; });
            template.name = response._embedded.workflow_template.name;
            _this.item = null;
            _this.modalService.dismissAll();
        });
    };
    TemplatesPageComponent.prototype.submitRemoveTemplate = function () {
        var _this = this;
        this.workflowTemplateService
            .deleteWorkflowTemplate(this.item.id)
            .subscribe(function () {
            _this.collectionDocument.items = _this.collectionDocument.items.filter(function (i) { return i.id !== _this.item.id; });
            _this.item.id = null;
            _this.modalService.dismissAll();
        });
    };
    TemplatesPageComponent.prototype.doActionUse = function (event) {
        var _this = this;
        this.open(this.loading);
        this.workflowTemplateService
            .getWorkflowTemplateById(event.data.id)
            .subscribe(function (_a) {
            var template = _a.template;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var _i, _b, document_1, workflowFile, _c, _d, step, contact, _e, _f, field, page, f;
                return tslib_1.__generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            _i = 0, _b = template.files;
                            _g.label = 1;
                        case 1:
                            if (!(_i < _b.length)) return [3 /*break*/, 4];
                            document_1 = _b[_i];
                            return [4 /*yield*/, WorkflowFile.MakeWorkflowFileFromURL(document_1._embedded.file._links.download.href)];
                        case 2:
                            workflowFile = _g.sent();
                            workflowFile.setName(document_1.name);
                            workflowFile.setIdFile(document_1.idFile);
                            workflowFile.setFolderId(document_1.idFolder);
                            if (document_1.dueDate) {
                                workflowFile.setDueDate(document_1.dueDate);
                            }
                            for (_c = 0, _d = document_1.workflowSteps; _c < _d.length; _c++) {
                                step = _d[_c];
                                contact = this.workflowBuildService.findContactByEmail(step.user.email);
                                if (!contact) {
                                    contact = new WorkflowContact({
                                        email: step.user.email,
                                        id: step.user.id,
                                        name: step.user.name
                                    }, step.action);
                                    this.workflowBuildService.addContact(contact);
                                }
                                for (_e = 0, _f = step.fields; _e < _f.length; _e++) {
                                    field = _f[_e];
                                    page = workflowFile.getPage(field.page);
                                    f = WorkflowDroppedFieldsStrategy.MakeDroppedField(field.type, contact, step.signatureType);
                                    f.setViewportProportion(1);
                                    f.setSize(field.width, field.height);
                                    f.setCoordinates(field.x, field.y);
                                    page.addField(f);
                                }
                            }
                            this.workflowBuildService.addFile(workflowFile);
                            this.workflowBuildService.setFileAsVisible(workflowFile);
                            _g.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4:
                            this.workflowBuildService.setSettings({
                                autoInitiate: template.autoInitiate,
                                message: template.message,
                                name: template.name,
                                priority: template.priority,
                                dueDate: template.dueDate
                            });
                            this.modalService.dismissAll();
                            this.router.navigate(['/manager/workflow']);
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    TemplatesPageComponent.prototype.formControlValidation = function (control) {
        return !control.pristine && control.invalid;
    };
    /**
     * reorder field
     *
     * @param (string) field sort
     * @return string
     */
    TemplatesPageComponent.prototype.reorder = function (field) {
        return field === 'ASC' ? 'DESC' : 'ASC';
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    TemplatesPageComponent.prototype.open = function (content, size, data) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    TemplatesPageComponent.prototype.createRenameWorkflowForm = function () {
        this.renameWorkflowForm = this.formBuilder.group({
            id: [],
            name: ['', Validators.required]
        });
    };
    return TemplatesPageComponent;
}());
export { TemplatesPageComponent };
