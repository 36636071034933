import * as tslib_1 from "tslib";
import { environment } from '@env/environment';
import { WorkflowFilePage } from './workflow-file-page.class';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { generateFileBase64, generateFileSha1, generateRandomString } from '../helpers';
/**
 * A single file build instruction when building the workflow
 */
var WorkflowFile = /** @class */ (function () {
    function WorkflowFile(file) {
        this.idFolder = 0;
        this.name = '';
        this.base64Content = '';
        this.sha1Sum = '';
        this.downloadURL = '';
        this.file = file;
        this.pages = new Map();
        this.pageObserver = new Subject();
    }
    WorkflowFile.MakeWorkflowFile = function (file) {
        return new WorkflowFile(file);
    };
    WorkflowFile.MakeWorkflowFileFromURL = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.open('GET', url);
                        xhr.send();
                        xhr.onreadystatechange = function () {
                            if (xhr.readyState !== XMLHttpRequest.DONE) {
                                return;
                            }
                            if (xhr.status === 200) {
                                var workflowFile_1 = WorkflowFile.MakeWorkflowFile(null);
                                workflowFile_1.setDownloadURL(url);
                                WorkflowFile.GenerateBase64AndSha1OfFile(xhr.response)
                                    .then(function (_a) {
                                    var base64 = _a[0], sha1 = _a[1];
                                    workflowFile_1.setBase64(base64);
                                    workflowFile_1.setSha1(sha1);
                                    resolve(workflowFile_1);
                                })
                                    .catch(reject);
                            }
                            else {
                                reject({
                                    error: xhr.statusText,
                                    code: xhr.status,
                                    response: xhr.response
                                });
                            }
                        };
                    })];
            });
        });
    };
    WorkflowFile.GenerateBase64AndSha1OfFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, Promise.all([generateFileBase64(file), generateFileSha1(file)])];
            });
        });
    };
    WorkflowFile.prototype.setFolderId = function (folderId) {
        this.idFolder = folderId;
    };
    WorkflowFile.prototype.getFolderId = function () {
        return this.idFolder;
    };
    WorkflowFile.prototype.setIdFile = function (id) {
        this.idFile = id;
    };
    WorkflowFile.prototype.getIdFile = function () {
        return this.idFile;
    };
    WorkflowFile.prototype.setName = function (name) {
        if (!name) {
            this.name = generateRandomString() + '.pdf';
            return;
        }
        if (!name.includes('.')) {
            this.name = name + ".pdf";
            return;
        }
        if (!name.includes('.pdf')) {
            var fileName = name.split('.');
            this.name = fileName[0].toString() + '.pdf';
            return;
        }
        this.name = name;
    };
    WorkflowFile.prototype.getName = function () {
        return this.name;
    };
    WorkflowFile.prototype.setDueDate = function (dueDate) {
        this.dueDate = moment(dueDate);
    };
    WorkflowFile.prototype.getDueDate = function () {
        if (!this.dueDate) {
            return '';
        }
        return this.dueDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    };
    WorkflowFile.prototype.setBase64 = function (base64) {
        this.base64Content = base64;
    };
    WorkflowFile.prototype.getBase64 = function () {
        return this.base64Content;
    };
    WorkflowFile.prototype.setSha1 = function (sha1) {
        this.sha1Sum = sha1;
    };
    WorkflowFile.prototype.getSha1 = function () {
        return this.sha1Sum;
    };
    WorkflowFile.prototype.getFile = function () {
        return this.file;
    };
    WorkflowFile.prototype.setDownloadURL = function (endpoint, uuid) {
        if (uuid) {
            var apiURL = environment.apiURL + environment.apiVersion;
            this.downloadURL = "" + apiURL + endpoint + "?q=" + uuid;
        }
        else {
            this.downloadURL = endpoint;
        }
    };
    WorkflowFile.prototype.getDownloadURL = function () {
        return this.downloadURL;
    };
    WorkflowFile.prototype.getCurrentPage = function () {
        return this.currentPage;
    };
    WorkflowFile.prototype.setCurrentPage = function (pageNumber) {
        this.currentPage = this.getPage(pageNumber);
        this.pushUpdateOfChangedPage();
    };
    WorkflowFile.prototype.observePageChange = function () {
        return this.pageObserver.asObservable();
    };
    WorkflowFile.prototype.getPages = function () {
        var pages = [];
        this.pages.forEach(function (page) { return pages.push(page); });
        return pages;
    };
    WorkflowFile.prototype.getPage = function (pageNumber) {
        var page = this.pages.get(pageNumber);
        if (!page) {
            page = new WorkflowFilePage(pageNumber);
            this.pages.set(pageNumber, page);
        }
        return page;
    };
    WorkflowFile.prototype.getAllFields = function () {
        var fields = [];
        for (var _i = 0, _a = this.getPages(); _i < _a.length; _i++) {
            var page = _a[_i];
            for (var _b = 0, _c = page.getFields(); _b < _c.length; _b++) {
                var field = _c[_b];
                fields.push(field);
            }
        }
        return fields;
    };
    WorkflowFile.prototype.pushUpdateOfChangedPage = function () {
        this.pageObserver.next(this.currentPage);
    };
    return WorkflowFile;
}());
export { WorkflowFile };
