<div class="document-finish">
  <div class="row">
    <div class="col-md-12">
      <div class="document-hero">
        <h3>Documento enviado!</h3>
        <p>
          O documento foi enviado com sucesso aos seus destinatários de forma
          segura e eficiente. <br class="visible-lg" />
          Aproveite para acompanhar o andamento da coleta de assinaturas
        </p>
        <a [routerLink]="detailURL + workflowId" class="btn btn-secondary"
          >Acompanhar andamento</a
        >
        <div style="margin-top: 15px;">
          <a [routerLink]="buildURL" class="new-document">Novo Documento</a>
        </div>
      </div>
    </div>
  </div>
</div>
