import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EVENT_TYPES } from '@app/core/services';

@Component({
  selector: 'app-custom-subject-mail-form',
  templateUrl: './custom-subject-mail-form.component.html',
  styleUrls: ['./custom-subject-mail-form.component.scss']
})
export class CustomSubjectMailFormComponent implements OnInit {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  @Input() item: any;

  /**
   * Data of current user
   */

  /**
   * Data of Form
   */
  customSubjectForm: FormGroup;

  modalForm: any;

  eventsTypeKeys: any[] = Object.keys(EVENT_TYPES);
  eventsTypes: any = EVENT_TYPES;

  constructor(private formBuilder: FormBuilder) {}

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    const toAction: any = {
      action: 'save',
      form: 'customSubject',
      formData: this.customSubjectForm.value
    };

    if (this.item) {
      toAction.id = this.item.id;
      toAction.action = 'edit';
    }

    this.modal.close(toAction);
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.customSubjectForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    const customSubject = this.item ? this.item : {};
    this.customSubjectForm = this.formBuilder.group({
      event: [customSubject.event || '', Validators.required],
      subject: [customSubject.subject || '', Validators.required]
    });
  }

  ngOnInit() {
    this.createForm();
  }
}
