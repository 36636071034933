import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@app/core/services';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {
  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {}

  isAuthenticated() {
    return this.authorizationService.isAuthenticated();
  }
}
