import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';

import { IntegrationComponent } from './integration.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { WorkflowPageComponent } from './pages';

import { ManagerModule } from '@app/manager';
import { IntegrationErrorPageComponent } from './pages/workflow/error/error.component';
import { IntegrationBuildPageComponent } from './pages/workflow/build/build.component';
import { IntegrationFinishPageComponent } from './pages/workflow/finish/finish.component';
import { IntegrationReviewPageComponent } from './pages/workflow/review/review.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularDraggableModule } from 'angular2-draggable';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    IntegrationRoutingModule,
    ManagerModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AngularDraggableModule
  ],
  exports: [],
  declarations: [
    IntegrationComponent,
    WorkflowPageComponent,
    IntegrationErrorPageComponent,
    IntegrationBuildPageComponent,
    IntegrationReviewPageComponent,
    IntegrationFinishPageComponent
  ],
  providers: []
})
export class IntegrationModule {}
