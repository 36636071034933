<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div class="container">
  <div class="row mt-2">
    <div class="col-12"><h4>Informe suas credenciais DocuWare</h4></div>
  </div>

  <form (ngSubmit)="doSubmit()" [formGroup]="dataForm" novalidate>
    <div class="form-row mt-4">
      <div class="form-group col-md-6">
        <label>Id cabinet</label>
        <input
          type="text"
          class="form-control"
          placeholder="Informe id cabinet"
          formControlName="fileCabinetId"
          [ngClass]="{
            'is-invalid': f.fileCabinetId.errors && !f.fileCabinetId.pristine
          }"
        />
        <div *ngIf="f.fileCabinetId.errors && !f.fileCabinetId.pristine">
          <span class="error" [hidden]="!f.fileCabinetId.errors.required">
            O Id cabinet é obrigatório
          </span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Base url</label>
        <input
          type="text"
          class="form-control"
          placeholder="Informe a base url"
          formControlName="serverUrl"
          [ngClass]="{
            'is-invalid': f.serverUrl.errors && !f.serverUrl.pristine
          }"
        />
        <div *ngIf="f.serverUrl.errors && !f.serverUrl.pristine">
          <span class="error" [hidden]="!f.serverUrl.errors.required">
            O server url é obrigatório
          </span>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Usuário</label>
        <input
          type="text"
          class="form-control"
          placeholder="Informe usuário"
          formControlName="username"
          [ngClass]="{
            'is-invalid': f.username.errors && !f.username.pristine
          }"
        />
        <div *ngIf="f.username.errors && !f.username.pristine">
          <span class="error" [hidden]="!f.username.errors.required">
            O nome do usuário é obrigatório
          </span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Senha</label>
        <input
          type="password"
          class="form-control"
          placeholder="Informe sua senha"
          formControlName="password"
          [ngClass]="{
            'is-invalid': f.password.errors && !f.password.pristine
          }"
        />
        <div *ngIf="f.password.errors && !f.password.pristine">
          <span class="error" [hidden]="!f.password.errors.required">
            O campo senha é obrigatório
          </span>
        </div>
      </div>
    </div>

    <div class="flex" *ngIf="integrationData">
      <div class="info">
        <div>
          <i class="fas fa-check-circle"></i> Você já possui integração com a
          DocuWare.
        </div>
      </div>

      <div>
        <span class="btn-delete text-nowrap" (click)="onDelete()"
          >Excluir integração</span
        >
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 p-2">
        <div class="row d-flex justify-content-end ">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-outline-success m-1"
              title="Cancelar"
              [disabled]="spinnerWait"
              (click)="modal.close('cancel')"
            >
              Cancelar
            </button>

            <button
              type="submit"
              class="btn btn-secondary m-1"
              title="Salvar"
              [disabled]="dataForm.invalid"
            >
              <i class="fas fa-circle-notch fa-spin" *ngIf="spinnerWait"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #modalDelete let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="message">
      <p>
        Tem certeza que deseja excluir a integração? Essa ação não poderá ser
        desfeita.
      </p>
    </div>

    <div class="row mt-3">
      <div class="col-12 p-2">
        <div class="row d-flex justify-content-end ">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-default m-1"
              title="Cancelar"
              (click)="modalClose()"
            >
              Cancelar
            </button>

            <button
              type="button"
              class="btn btn-danger btn-sm m-1"
              title="Excluir"
              (click)="toDeleteIntegration()"
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-modal-action>
</ng-template>
