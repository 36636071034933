/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./droppable-fields-zone.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./droppable-field/droppable-field.component.ngfactory";
import * as i3 from "./droppable-field/droppable-field.component";
import * as i4 from "../../../../core/services/workflow-build.service";
import * as i5 from "@angular/cdk/drag-drop";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/common";
import * as i8 from "./droppable-fields-zone.component";
import * as i9 from "../../../../core/services/feedback.service";
var styles_WorkflowDroppableFieldsZoneComponent = [i0.styles];
var RenderType_WorkflowDroppableFieldsZoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDroppableFieldsZoneComponent, data: {} });
export { RenderType_WorkflowDroppableFieldsZoneComponent as RenderType_WorkflowDroppableFieldsZoneComponent };
function View_WorkflowDroppableFieldsZoneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-field", [], null, [[null, "spreadFieldAllPages"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("spreadFieldAllPages" === en)) {
        var pd_0 = (_co.onSpreadFieldAllPages(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_WorkflowDroppableFieldComponent_0, i2.RenderType_WorkflowDroppableFieldComponent)), i1.ɵdid(1, 245760, null, 0, i3.WorkflowDroppableFieldComponent, [i4.WorkflowBuildService], { field: [0, "field"] }, { spreadFieldAllPages: "spreadFieldAllPages" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WorkflowDroppableFieldsZoneComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dropzoneElem: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["cdkDropList", ""], ["class", "document-dropzone cdk-drop-list"], ["style", "display: block;"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.onDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i5.CDK_DROP_LIST, null, [i5.CdkDropList]), i1.ɵdid(3, 1196032, null, 1, i5.CdkDropList, [i1.ElementRef, i5.DragDropRegistry, i1.ChangeDetectorRef, [2, i6.Directionality], [3, i5.CdkDropListGroup], [2, i7.DOCUMENT], i5.DragDrop], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 2, { _draggables: 1 }), i1.ɵprd(256, null, i5.CdkDropListGroup, undefined, []), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["dropzone", 1]], null, 4, "div", [["class", "dropped-fields-zone"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowDroppableFieldsZoneComponent_1)), i1.ɵdid(10, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.fields; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = i1.ɵnov(_v, 3).disabled; var currVal_2 = i1.ɵnov(_v, 3)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 3)._dropListRef.isReceiving(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_WorkflowDroppableFieldsZoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-fields-zone", [], null, null, null, View_WorkflowDroppableFieldsZoneComponent_0, RenderType_WorkflowDroppableFieldsZoneComponent)), i1.ɵdid(1, 245760, null, 0, i8.WorkflowDroppableFieldsZoneComponent, [i4.WorkflowBuildService, i9.FeedbackService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowDroppableFieldsZoneComponentNgFactory = i1.ɵccf("app-workflow-droppable-fields-zone", i8.WorkflowDroppableFieldsZoneComponent, View_WorkflowDroppableFieldsZoneComponent_Host_0, { pdfTotalPages: "pdfTotalPages" }, {}, ["*"]);
export { WorkflowDroppableFieldsZoneComponentNgFactory as WorkflowDroppableFieldsZoneComponentNgFactory };
