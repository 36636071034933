import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutDefault } from '@app/shared/layouts';
import { LandingRoutingPath } from './landing-routing.path';

import { LandingComponent } from './landing.component';
import { RegisterComponent } from './pages/register/register.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';

const routes: Routes = [
  LayoutDefault.childRoutes([
    {
      path: '',
      redirectTo: '/manager/dashboard',
      pathMatch: 'full'
    },
    {
      path: LandingRoutingPath.LANDING_REGISTER,
      component: RegisterComponent
    },
    {
      path: LandingRoutingPath.LANDING_PAYMENT_CARD,
      component: PaymentCardComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LandingRoutingModule {}
