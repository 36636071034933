import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SubscribeComponent } from './subscribe.component';
import { SubscribeRoutingModule } from './subscribe-routing.module';
import { SubscribeListComponent } from './components/subscribe-list/subscribe-list.component';
import { CertificateChooserComponent } from './components/certificate-chooser/certificate-chooser.component';

import { SharedModule } from '@app/shared/shared.module';
import { FeedbackSubscribeComponent } from './components/feedback-subscribe/feedback-subscribe/feedback-subscribe.component';
import { SelectInPersonSignatureComponent } from './components/select-in-person-signature/select-in-person-signature.component';
import { PhotoSignComponent } from './components/photo-sign/photo-sign.component';
import { CameraComponent } from './components/photo-sign/camera/camera.component';
import { AcceptPolicyComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    SubscribeRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    SubscribeComponent,
    SubscribeListComponent,
    CertificateChooserComponent,
    FeedbackSubscribeComponent,
    SelectInPersonSignatureComponent,
    PhotoSignComponent,
    CameraComponent,
    AcceptPolicyComponent
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SubscribeModule {}
