const positionToPercentage = (plane: any) => ({
  x: (plane.position.x / plane.size.width).toFixed(2),
  y: (plane.position.y / plane.size.height).toFixed(2)
});

const toApiPosition = (plane: any) => {
  return plane.position;
};

const fromApiToPercentage = (position: any, size: any) => {
  return {
    x: position.x / size.width,
    y: position.y / size.height
  };
};

export default {
  positionToPercentage,
  toApiPosition,
  fromApiToPercentage
};
