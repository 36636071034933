<div class="field-block field-sign">
  <div>
    <div class="simple-text">
      <div class="mt-1">
        <span class="signature-type-title">Assinatura Eletrônica</span>
      </div>
      <div>{{ contact.email }}</div>
    </div>
  </div>
</div>
