<div class="form-personal mt-3">
  <div class="box">
    <div class="box-header"><h3>Detalhes de plano e pagamento</h3></div>

    <div class="box-content">
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <div class="form-group">
            <h5>Plano atual</h5>
            <h6>{{ planInfo.planType }}</h6>
            <li
              class="description"
              *ngFor="let descriptions of planInfo.planDescription"
            >
              {{ descriptions }}
            </li>
          </div>
          <div class="form-group">
            <button
              class="btn btn-sm btn-outline-danger"
              (click)="toSubmit()"
              *ngIf="
                !planInfo.isDefault &&
                logged.roleDefault === 'admin' &&
                paymentInfo !== undefined
              "
            >
              Cancelar meu plano
            </button>
          </div>
        </div>

        <div class="col-12 col-md-6 mt-2">
          <div class="form-group">
            <h5>Pagamento</h5>

            <div *ngIf="planInfo.isDefault">
              <p>Você está inscrito no plano gratuito. Aproveite!</p>
            </div>

            <div *ngIf="logged.roleDefault === 'user'">
              <p>Você está vinculado a uma organização!</p>
            </div>

            <div *ngIf="logged.roleDefault === 'admin' && paymentInfo">
              <p>
                Sua próxima cobrança será no dia
                <strong>{{ paymentInfo.renewDate }}</strong
                >, no valor de
                <strong
                  >R$ {{ paymentInfo.price }},{{ paymentInfo.cents }}</strong
                >
              </p>
            </div>
          </div>

          <div
            class="row mt-4"
            *ngIf="logged.roleDefault === 'admin' && paymentInfo"
          >
            <div class="col-2">
              <i
                class="fab fa-cc-mastercard"
                *ngIf="paymentInfo.brand === 'Mastercard'"
                style="font-size:30px;"
              ></i>
              <i
                class="fab fa-cc-visa"
                *ngIf="paymentInfo.brand === 'Visa'"
                style="font-size:30px;"
              ></i>
              <i
                class="fab fa-cc-amex"
                *ngIf="paymentInfo.brand === 'Amex'"
                style="font-size:30px;"
              ></i>
              <i
                class="fas fa-credit-card"
                *ngIf="
                  paymentInfo.brand !== 'Amex' &&
                  paymentInfo.brand !== 'Visa' &&
                  paymentInfo.brand !== 'Mastercard'
                "
              ></i>
            </div>
            <div class="col-10">
              <span
                >Cartão {{ paymentInfo.brand }} que termina com
                <strong>{{ paymentInfo.cardNumber }} de </strong></span
              >
              <span
                ><strong>{{ paymentInfo.holder }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modaCancelPlan let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="message">
      <p>
        Se cancelar o plano a ação não pode ser desfeita, o plano gratuito e
        adicionado.
      </p>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-default" (click)="modalClose()">Manter</button>
      <button class="btn btn-danger btn-sm" (click)="cancelPlan()">
        Cancelar
      </button>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalErrorCancel let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="message">
      <h5>Não foi possível cancelar o plano.</h5>
      <p class="icon"><i class="icon-document-error"></i></p>
      <p class="text">
        Você também pode entrar em contato conosco pelo chat disponível no canto
        inferior direito desta página.
      </p>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-default" (click)="modalClose()">Fechar</button>
      <button class="btn btn-danger btn-sm" (click)="retryCancellation()">
        Tentar novamente
      </button>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalSuccessCancel let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="message">
      <p class="text">Você está inscrito no plano gratuito. Aproveite!</p>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-secondary btn-sm" (click)="reloadPage()">
        Fechar
      </button>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
