<app-cookies *ngIf="shouoldShowCookie()"></app-cookies>

<footer>
  <div class="info">
    &#9400; 2019. Assine Online | CNPJ: 34.878.053/0001-21 | E-mail:
    contato@assine.online
  </div>

  <div class="networks">
    <ul>
      <li>
        <a
          [href]="termsOfUse.path"
          [download]="termsOfUse.filename"
          [title]="termsOfUse.title"
          >Termos de uso</a
        >
      </li>
      <li>
        <a
          [href]="cookies.path"
          [download]="cookies.filename"
          [title]="cookies.title"
          >Política de cookies</a
        >
      </li>
      <li>
        <a
          [href]="privacyPolicy.path"
          [download]="privacyPolicy.filename"
          [title]="privacyPolicy.title"
          >Política de privacidade</a
        >
      </li>
    </ul>
  </div>
</footer>
