<div class="contacts-list">
  <div class="row mb-3">
    <div class="col-12">
      <div class="buttons">
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="doCreate()"
        >
          Adicionar novo contato
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text"><i class="fas fa-search"></i></div>
        </div>
        <input
          [(ngModel)]="searchName"
          (keyup)="getContactsByName()"
          type="text"
          class="form-control"
          placeholder="Pesquise contato por nome"
        />
      </div>
    </div>

    <div class="col-12 col-md-4 filters">
      <span class="filter-label"> Filtros: </span>
      <select
        class="form-control"
        [(ngModel)]="searchStatus"
        (change)="getContactsByName()"
      >
        <option value="">Todos</option>
        <option value="1">Habilitado</option>
        <option value="0">Desabilitado</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="contact-table">
        <app-lists
          [collection]="collection"
          [fields]="configList"
          [pagination]="pagination"
          [sortableFields]="sortFields"
          (selected)="getSelectedItems($event)"
          (activity)="doAction($event)"
          (sort)="doSort($event)"
          (doChangePage)="onChangePage($event)"
        ></app-lists>
      </div>
    </div>
  </div>
</div>

<ng-template #modalDeleteContact let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="modalTitle"
  >
    <app-contact-delete-form
      [modal]="modal"
      [selected]="selected"
      [isLoading]="isLoading"
    ></app-contact-delete-form>
  </app-modal-action>
</ng-template>

<ng-template #modalCreateContact let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="modalTitle"
  >
    <app-contact-form
      [modal]="modal"
      [selected]="selected"
      [isLoading]="isLoading"
    ></app-contact-form>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
