/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-rubric.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feedback-rubric.component";
var styles_FeedbackRubricComponent = [i0.styles];
var RenderType_FeedbackRubricComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackRubricComponent, data: {} });
export { RenderType_FeedbackRubricComponent as RenderType_FeedbackRubricComponent };
export function View_FeedbackRubricComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "text-custom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Ao escolher essa a\u00E7\u00E3o os destinat\u00E1rios precisam\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["rubricar p\u00E1gina por p\u00E1gina manualmente"])), (_l()(), i1.ɵted(-1, null, [".\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Deseja replicar a rubrica em todas as p\u00E1ginas do documento? Essa a\u00E7\u00E3o n\u00E3o\n    poder\u00E1 ser desfeita.\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-default btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    N\u00E3o\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn btn-secondary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sim"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_FeedbackRubricComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-rubric", [], null, null, null, View_FeedbackRubricComponent_0, RenderType_FeedbackRubricComponent)), i1.ɵdid(1, 114688, null, 0, i2.FeedbackRubricComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackRubricComponentNgFactory = i1.ɵccf("app-feedback-rubric", i2.FeedbackRubricComponent, View_FeedbackRubricComponent_Host_0, { modal: "modal" }, {}, []);
export { FeedbackRubricComponentNgFactory as FeedbackRubricComponentNgFactory };
