import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../models';
import { UserService } from './user.service';

@Injectable()
export class RemainingUserBalanceService {
  public planUser = new EventEmitter<User.UserPlanPayload>();

  constructor(private userService: UserService) {}

  public getPlanUser(): void {
    this.userService.getPlanInfo().subscribe((body: User.UserPlanPayload) => {
      this.planUser.emit(body);
    });
  }
}
