<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div class="page page-users">
  <div class="container">
    <h2>Gerenciamento de Usuários</h2>
    <p>Veja aqui todos os usuários vinculados a sua conta empresarial</p>

    <div class="form-row">
      <div class="col-md-6 mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fas fa-search"></i></div>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Pesquise aqui"
            [formControl]="queryField"
            (keyup)="searchDebounce()"
          />
        </div>
      </div>

      <div class="col-md-6 mb-3 d-flex justify-content-end">
        <div class="d-flex justify-content-center align-items-center">
          <a routerLink="/manager/profile">Voltar</a>
        </div>

        <button
          type="button"
          class="btn btn-sm btn-secondary ml-4"
          (click)="checkMmaximumUserInPlan()"
        >
          Adicionar usuário
        </button>
      </div>
    </div>

    <app-lists
      [collection]="users"
      [fields]="configList"
      [pagination]="pagination"
      [sortableFields]="sortFields"
      (selected)="getSelectedItems($event)"
      (activity)="doAction($event)"
      (sort)="doSort($event)"
      (doChangePage)="onChangePage($event)"
    ></app-lists>
  </div>

  <ng-template #modalCreateUser let-modal>
    <app-modal-action
      [modal]="modal"
      [typeModal]="'simple green'"
      [showHeader]="true"
      [showFooter]="false"
      [showClose]="false"
      [title]="'Convidar usuário'"
    >
      <app-linked-user-form
        [isLoading]="isLoading"
        [modal]="modal"
      ></app-linked-user-form>
    </app-modal-action>
  </ng-template>

  <ng-template #modalRemoveUser let-modal>
    <app-modal-action
      [modal]="modal"
      [typeModal]="'simple red'"
      [showHeader]="true"
      [showFooter]="false"
      [showClose]="false"
      [title]="'Aviso!'"
    >
      <div class="row">
        <div class="col-12 feed">
          <p>
            Tem certeza que deseja excluir o usuário
            <strong>{{ deleteUser.email }}</strong> da sua organização? Essa
            ação não poderá ser desfeita.
          </p>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-default m-1" (click)="closeModal()">
            Cancelar
          </button>
          <button class="btn btn-danger btn-sm m-1" (click)="removeUser()">
            Excluir
          </button>
        </div>
      </div>
    </app-modal-action>
  </ng-template>

  <ng-template #modalFeedbackMaxUser let-modal>
    <app-modal-action
      [modal]="modal"
      [typeModal]="'simple green'"
      [showHeader]="true"
      [showFooter]="false"
      [showClose]="false"
      [title]="'Aviso!'"
    >
      <div class="message">
        <h5>Não foi possível convidar o usuário.</h5>
        <p class="text">
          O seu plano é {{ paymentInfo.name }}, permite adicionar apenas
          {{ paymentInfo.maxUsers }} convites.
          <strong
            ><a href="javascript:void(0);" (click)="navigeteToPlans()"
              >Clique aqui</a
            ></strong
          >
          e veja mais opções de planos.
        </p>
      </div>
      <div class="col-12 text-center">
        <button
          class="btn btn-secondary btn-sm"
          (click)="modal.close('cancel')"
        >
          Ok
        </button>
      </div>
    </app-modal-action>
  </ng-template>

  <ng-template #modalLoading let-modal>
    <app-modal
      [modal]="modal"
      [type]="'loading'"
      [showHeader]="false"
      [showFooter]="false"
      [showClose]="false"
      [title]=""
      [text]="modalRef.data.text"
    >
    </app-modal>
  </ng-template>
</div>
