<app-feedback
  [theme]="feedback.type"
  [message]="feedback.message"
></app-feedback>

<div class="page page-documents">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li class="active"><a routerLink="/manager"> Página inicial </a></li>
          <li class="active">
            <a routerLink="/manager/manage"> Documentos e Pastas </a>
          </li>
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a routerLink="/manager/manage/workflow"> Recebidos </a>
          </li>
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a routerLink="/manager/manage/workflow/me"> Enviados </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/manager/manage/templates"> Modelos </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/manager/manage/contacts"> Contatos </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/manager/manage/folders"> Pastas </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/manager/manage/workflow-report"> Relatório </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/manager/manage/signature-in-person">
              Assinatura presencial
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-navigation-lists [pages]="pages"> </app-navigation-lists>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><router-outlet></router-outlet></div>
    </div>
  </div>
</div>
