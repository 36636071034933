import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';

@Component({
  selector: 'app-workflow-stamp-signature-field',
  templateUrl: './stamp-signature.component.html',
  styleUrls: ['../../droppable-field.component.scss']
})
export class WorkflowStampSignatureFieldComponent extends WorkflowBaseDroppedField {
  @Output() public spreadFieldAllPages? = new EventEmitter<number>();

  @ViewChild('feedbackStamp') public feedbackStamp: ElementRef;

  private click: number = 2;

  constructor(public modalService: NgbModal) {
    super();
  }

  public fieldSpread(): void {
    this.spreadFieldAllPages.emit(this.click);
  }

  public toAction(): void {
    this.open(this.feedbackStamp, 'md');
  }

  public open(content: any, size?: any): void {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-title',
        centered: true,
        keyboard: false,
        backdrop: 'static',
        size: size || 'lg'
      })

      .result.then(result => {
        if (result.action === 'save') {
          if (result.form === 'feedback-stamp') {
            this.fieldSpread();
            return;
          }
        }

        if (result === 'cancel') {
          return;
        }
      });
  }
}
