<app-layout-default-header
  *ngIf="!isAuthenticated()"
></app-layout-default-header>
<app-layout-manager-header
  *ngIf="isAuthenticated()"
></app-layout-manager-header>
<div class="page">
  <div class="container pt-5 pb-5">
    <div class="box">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div class="p-5">
            <h2 class="text-success">
              Ops!<br />
              Parece que esta página não existe. [404]
            </h2>

            <br />

            <p>Às vezes erros acontecem. Pode perdoar a gente nesse aqui?</p>
            <p>Para voltar à página inicial é só clicar no botão abaixo.</p>

            <a
              *ngIf="isAuthenticated()"
              href="/manager/dashboard"
              class="btn btn-secondary"
              >Página Inicial</a
            >
            <a *ngIf="!isAuthenticated()" href="/" class="btn btn-secondary"
              >Página Inicial</a
            >
          </div>
        </div>
        <div class="col-md-7">
          <div class="p-5 text-right">
            <img src="assets/images/alert.svg" class="alert-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-layout-default-footer
  *ngIf="!isAuthenticated()"
></app-layout-default-footer>
<app-layout-manager-footer
  *ngIf="isAuthenticated()"
></app-layout-manager-footer>
