<div class="document-review">
  <div class="row">
    <div class="col-md-4">
      <div class="box multifiles" *ngIf="hasWorkflowFiles()">
        <form action="" class="form">
          <div class="form-row">
            <div class="form-group col-12 col-lg-12">
              <select
                class="form-control"
                [(ngModel)]="selectedWorkflowFile"
                [ngModelOptions]="{ standalone: true }"
                (change)="onChangeWorkflowFile(selectedWorkflowFile)"
              >
                <option
                  *ngFor="let workflowFile of workflowFiles"
                  [ngValue]="workflowFile"
                >
                  {{ workflowFile.getName() }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="box">
        <div class="box-content preview">
          <app-workflow-document [showUpload]="false"></app-workflow-document>
        </div>
      </div>
      <br />
    </div>
    <div class="col-md-8">
      <div class="box">
        <div class="box-header">
          <h3>Detalhes do Documento: {{ selectedWorkflowFile?.getName() }}</h3>
        </div>
        <div class="box-content">
          <form
            (ngSubmit)="doSubmit()"
            [formGroup]="workflowFileForm"
            novalidate
          >
            <fieldset [disabled]="isSavingWorkflow">
              <div class="row">
                <div
                  [ngClass]="
                    showFolderSelector ? 'col-12 col-xl-5' : 'col-12 col-xl-8'
                  "
                >
                  <div class="form-group">
                    <label>Nome do documento</label>
                    <input
                      class="form-control"
                      placeholder=""
                      formControlName="name"
                      (keyup)="saveFileSettings()"
                    />
                  </div>
                </div>
                <div class="col-6 col-xl-3" *ngIf="showFolderSelector">
                  <div class="form-group">
                    <label>Pasta do documento</label>

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div
                          class="input-group-text suspend"
                          title="Pesquisar pasta"
                          (click)="openModalListFolders(modalListFolders)"
                        >
                          <i class="fas fa-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        formControlName="nameFolder"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div class="box">
        <div class="box-header"><h3>Destinatários</h3></div>
        <div class="box-content">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4 recipient"
              *ngFor="let contact of workflowContacts; let i = index"
            >
              <div class="action">
                {{ contact.getAction() | signAction: 'text' }}
              </div>
              <div class="name">{{ i + 1 }} - {{ contact.name }}</div>
              <div class="email">{{ contact.email }}</div>

              <ng-container
                *ngIf="
                  (isEnabledWhatsapp() || isEnabledWhatsappIntegration()) &&
                  contact.notifyWhatsapp
                "
              >
                <p>
                  {{ formatWhatsapp(contact.whatsappNumber)
                  }}<i class="fab fa-whatsapp ml-1"></i>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <form
        (ngSubmit)="doSubmit()"
        [formGroup]="workflowSettingsForm"
        novalidate
      >
        <fieldset [disabled]="isSavingWorkflow">
          <div class="document-form">
            <div class="box">
              <div class="box-header"><h3>Detalhes do Fluxo</h3></div>
              <div class="box-content">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Nome do Fluxo</label>
                      <input
                        class="form-control"
                        placeholder="Escreva aqui o nome do fluxo"
                        formControlName="name"
                        maxlength="60"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Prazo para a assinatura</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onfocus="(this.type='date')"
                        placeholder="Data"
                        [min]="minDueDate"
                        [ngClass]="{
                          'is-invalid': isFormControlValid(
                            workflowSettingsForm.controls.dueDate
                          )
                        }"
                        formControlName="dueDate"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label> Mensagem </label>
                      <textarea
                        type="text"
                        class="form-control form-control-sm bg-white"
                        placeholder="Escreva aqui sua mensagem"
                        rows="6"
                        maxlength="2000"
                        title="Deixe sua mensagem com no máximo 2000 caracteres"
                        formControlName="message"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="ShowSaveTemplate()">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="form-check"
                        title="Guarda todos os arquivos, assinantes e campos para reutilização futura"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          formControlName="saveTemplate"
                          id="save-template"
                        />
                        <label class="form-check-label" for="save-template">
                          Salvar como modelo
                        </label>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="document-action text-right mt-4">
            <button
              type="button"
              (click)="goToBuildPage()"
              class="btn btn-outline-success"
            >
              Voltar
            </button>
            <button type="submit" class="btn btn-secondary ml-2">
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="isSavingWorkflow"
              ></i>
              Salvar e Enviar
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<ng-template #error let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="true"
    [showClose]="false"
    [showBtnCancel]="true"
    [showBtnSave]="false"
    [btnClassCancel]="'btn-secondary'"
    [btnActionCancel]="'Ok'"
    [title]="'Erro!'"
  >
    <p>
      Não é possível executar a ação solicitada. É necessário ter ao menos um
      destinatário.
    </p>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalObj.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalLoadingClose let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="true"
    [title]="modalObj.data.title"
    [text]="modalObj.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalListFolders let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Pastas'"
  >
    <div class="input-group mb-4">
      <div class="input-group-prepend">
        <div class="input-group-text" title="Pesquisar">
          <i class="fas fa-search"></i>
        </div>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Pesquisar pasta"
        [formControl]="queryField"
        (keyup)="searchDebounce()"
      />
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nome</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="let item of folders?.items"
          (click)="forSelectedFolder(item)"
        >
          <th [class.active]="item.id === idSelection">{{ item.id }}</th>
          <th [class.active]="item.id === idSelection">
            {{ item.name === 'default' ? 'Padrão' : item.name }}
          </th>
        </tr>
        <div class="information">
          <i class="fas fa-circle-notch fa-spin" *ngIf="spinnerWait"></i>
          <p class="text-monospace" *ngIf="!folders?.page > 0">
            <strong>Pasta não encontrada</strong>
          </p>
        </div>
      </tbody>
    </table>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-secondary"
        (click)="useSelectedFolder()"
        [disabled]="!inFolderSelection"
      >
        Adicionar
      </button>
    </div>
  </app-modal-action>
</ng-template>
