import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { matchFields } from '@app/core/helpers';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of Form
   */
  passwordForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'change-password',
      formData: this.passwordForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.passwordForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.passwordForm = this.formBuilder.group(
      {
        currentPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
          ]
        ],
        confirmNewPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
          ]
        ]
      },
      { validator: matchFields('newPassword', 'confirmNewPassword') }
    );
  }
}
