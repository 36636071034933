<div class="folder-list-container">
  <div class="row">
    <div
      *ngFor="let document of collection"
      class="col-12 col-md-6 col-lg-4 col-xl-3"
    >
      <div class="slot">
        <div class="cell">
          <div
            style="width: 100%; padding-left: 15px;"
            (click)="goAction('view', document._embedded.workflow)"
          >
            <i
              class="far fa-file file-icon"
              *ngIf="!document._embedded.file"
            ></i>
            <i
              class="fas fa-file file-icon"
              *ngIf="document._embedded.file"
              style="color:#327253"
            ></i>
            <span> {{ document._embedded.originalFile.name }} </span>
          </div>

          <div
            style="position: absolute; right: 5px; top: 14px; width: 22px; text-align: center;"
            ngbDropdown
            class="d-inline-block handle-icon"
            *ngIf="true"
          >
            <i
              class="fas fa-ellipsis-v"
              ngbDropdownToggle
              style="font-size: 20px;"
            ></i>
            <div ngbDropdownMenu>
              <button
                class="dropdown-item"
                *ngIf="selectedFolder.isOwner"
                (click)="goAction('view', document._embedded.workflow)"
              >
                Detalhes
              </button>

              <button
                class="dropdown-item"
                (click)="
                  goAction('originalFile', document._embedded.originalFile)
                "
              >
                Download original
              </button>

              <button
                class="dropdown-item"
                (click)="goAction('signedFile', document._embedded.file)"
                *ngIf="document._embedded.file"
              >
                Download assinado
              </button>

              <button
                class="dropdown-item"
                (click)="goAction('toMove', document)"
                [disabled]="!moveButtonState()"
                [title]="titlePhrase(moveButtonState())"
              >
                Mover para
                <i class="fas fa-lock ml-4" *ngIf="!moveButtonState()"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pagination">
  <app-pagination
    [totalItems]="pagination.totalItems"
    [pageCount]="pagination.pageCount"
    [currentPage]="pagination.currentPage"
    (doPage)="doPage($event)"
  ></app-pagination>
</div>
