import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  AuthorizationService,
  WorkflowTemplateService,
  WorkflowBuildService,
  COUNTRY_CODES
} from '@app/core/services';
import { WorkflowContact } from '@app/core/class';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';

@Component({
  selector: 'app-workflow-contacts',
  templateUrl: './workflow-contacts.component.html',
  styleUrls: ['./workflow-contacts.component.scss']
})
export class WorkflowBuildContactsComponent implements OnInit {
  /**
   * Feedback action
   */
  @Output()
  public doFeedback? = new EventEmitter();

  @ViewChild('modalEditContact')
  public modalEditContact: ElementRef;

  /**
   * Informs if form is show or hide
   */
  public showContactForm: boolean;

  public contactEditForm: FormGroup;

  public contacts: WorkflowContact[];

  // Contact selected to show droppable fields
  private selectedContact: WorkflowContact;

  public queryField = new FormControl();

  constructor(
    private authorizationService: AuthorizationService,
    private workflowTemplateService: WorkflowTemplateService,
    private workflowBuildService: WorkflowBuildService,
    private modalService: NgbModal,
    protected formBuilder: FormBuilder
  ) {
    this.authorizationService = authorizationService;
    this.workflowTemplateService = workflowTemplateService;
    this.workflowBuildService = workflowBuildService;

    this.showContactForm = false;
    this.contacts = [];

    this.createEditContactForm();
  }

  public ngOnInit() {
    this.contacts = this.workflowBuildService.getContacts();
    this.workflowBuildService.observeContacts().subscribe(contacts => {
      this.contacts = contacts;

      this.setLastContactAsSelected();
    });
  }

  public isEnabledWhatsapp(): boolean {
    if (this.authorizationService.getUser()) {
      return this.authorizationService.getUser().twilioBuEnabled;
    }
    return false;
  }

  public isEnabledWhatsappIntegration(): boolean {
    return this.workflowBuildService.getWhatsApp();
  }

  private replaceDDI(phone: string): string {
    if (!phone) {
      return null;
    }
    const ddi = phone.replace('+', '').replace('55', '');
    return ddi;
  }

  public toggleContactForm(): void {
    this.showContactForm = !this.showContactForm;
  }

  public hasContacts(): boolean {
    return this.contacts.length > 0;
  }

  public totalContacts(): number {
    return this.contacts.length;
  }

  public onContactAdded(): void {
    this.toggleContactForm();
  }

  public isContactSelected(contact: WorkflowContact): boolean {
    return this.selectedContact === contact;
  }

  public toggleSelectedContact(contact: WorkflowContact): void {
    if (!contact.isSigner()) {
      return;
    }

    if (this.selectedContact === contact) {
      this.selectedContact = null;
      return;
    }

    this.selectedContact = contact;
  }

  public setLastContactAsSelected(): void {
    if (this.hasContacts()) {
      this.selectedContact = this.contacts[this.contacts.length - 1];
    }
  }

  public removeContact(contact: WorkflowContact): void {
    this.workflowBuildService.removeContact(contact);
  }

  public canAddSigners(): boolean {
    if (!this.authorizationService.isIntegration()) {
      return true;
    }

    return this.workflowTemplateService.canAddSigners();
  }

  public openContactEditForm(contact: WorkflowContact, $event: any): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.contactEditForm.setValue({
      _original: contact,
      name: contact.name,
      email: contact.email,
      whatsappNumber: this.replaceDDI(contact.whatsappNumber) || '',
      notifyWhatsapp: contact.notifyWhatsapp || false,
      hasPhotoSign: contact.hasPhotoSign || false
    });

    this.contactEditForm.clearValidators();
    this.modalService.open(this.modalEditContact, {
      ariaLabelledBy: 'modal-title',
      size: 'sm',
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
  }

  public formControlValidation(control: AbstractControl): boolean {
    return !control.pristine && control.invalid;
  }

  public isValidInputWhatsapp(): boolean {
    return (
      !this.contactForm.whatsappNumber.value ||
      this.contactForm.whatsappNumber.value.length !== 11
    );
  }

  public toggleSwitchPhoto(contact: WorkflowContact): void {
    this.contactEditForm.setValue({
      _original: [contact],
      email: contact.email,
      name: contact.name,
      whatsappNumber: contact.whatsappNumber,
      notifyWhatsapp: contact.notifyWhatsapp,
      hasPhotoSign: contact.hasPhotoSign
    });

    this.submitEditContact();
  }

  public submitEditContact(): void {
    const contact = this.contactEditForm.value._original as WorkflowContact;

    const {
      name,
      email,
      whatsappNumber,
      notifyWhatsapp,
      hasPhotoSign
    } = this.contactEditForm.value;
    contact.name = name;
    contact.email = email;
    contact.whatsappNumber = `${COUNTRY_CODES.BR}${whatsappNumber}`;
    contact.notifyWhatsapp = notifyWhatsapp;
    contact.hasPhotoSign = hasPhotoSign;

    this.modalService.dismissAll();
  }

  get contactForm() {
    return this.contactEditForm.controls;
  }

  private createEditContactForm(): any {
    this.contactEditForm = this.formBuilder.group({
      _original: [],
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      whatsappNumber: [''],
      notifyWhatsapp: [''],
      hasPhotoSign: ['']
    });
  }
}
