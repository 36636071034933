import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserSubscription {
  private subject = new Subject<any>();

  update(data: any) {
    this.subject.next(data);
  }

  getUser(): Observable<any> {
    return this.subject.asObservable();
  }
}
