import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WorkflowContact } from '@app/core/class';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Output() user = new EventEmitter();

  @Input() public name: string;

  @Input() public data: WorkflowContact;

  public checkField = new FormControl();

  constructor() {}

  public ngOnInit(): void {
    this.setValueInput();
  }

  public setValueInput(): void {
    this.checkField.setValue(this.data.getHasPhotoSign());
  }

  public onSubmit(): void {
    this.data.hasPhotoSign = !this.checkField.value;
    this.user.emit(this.data);
  }
}
