import { Component, Input } from '@angular/core';
import { BuildPageComponent } from '@app/manager/pages';

@Component({
  selector: 'app-workflow-integration-build-page',
  templateUrl: '../../../../manager/pages/workflow/build/build.component.html',
  styleUrls: ['../../../../manager/pages/workflow/build/build.component.scss']
})
export class IntegrationBuildPageComponent extends BuildPageComponent {
  public showUpload: boolean = false;

  public reviewURL: string = '/integration/workflow/review';
}
