<div class="row comp_">
  <div class="col align-self-center">
    <div class="box-upload mt-3">
      <label class="upload-button">
        <div *ngIf="!checkssign" class="upload-text">
          <i class="fas fa-file-upload"></i>

          <div class="mt-2"><span>Enviar carimbo</span></div>
        </div>

        <div *ngIf="checkssign"><img [src]="signature" height="150" /></div>

        <input
          type="file"
          (change)="inputFileChange($event)"
          accept=".png, .jpg"
        />
      </label>
    </div>
    <p>Formatos aceitos: JPG, PNG.</p>
  </div>
</div>
