/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./upload-signature.component";
var styles_UploadSignatureComponent = [i0.styles];
var RenderType_UploadSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadSignatureComponent, data: {} });
export { RenderType_UploadSignatureComponent as RenderType_UploadSignatureComponent };
function View_UploadSignatureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "upload-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n          "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enviar carimbo"])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], null, null); }
function View_UploadSignatureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "150"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signature; _ck(_v, 1, 0, currVal_0); }); }
export function View_UploadSignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "row comp_"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "col align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "box-upload mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "label", [["class", "upload-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadSignatureComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadSignatureComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["accept", ".png, .jpg"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.inputFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Formatos aceitos: JPG, PNG."])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.checkssign; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.checkssign; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_UploadSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-signature", [], null, null, null, View_UploadSignatureComponent_0, RenderType_UploadSignatureComponent)), i1.ɵdid(1, 114688, null, 0, i3.UploadSignatureComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadSignatureComponentNgFactory = i1.ɵccf("app-upload-signature", i3.UploadSignatureComponent, View_UploadSignatureComponent_Host_0, {}, { dataSignUploadEvent: "dataSignUploadEvent" }, []);
export { UploadSignatureComponentNgFactory as UploadSignatureComponentNgFactory };
