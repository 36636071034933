<div class="form-folder form-modal">
  <form (ngSubmit)="doSubmit()" [formGroup]="folderForm" novalidate>
    <div class="row form-modal">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Nome da pasta*</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': f.name.errors && !f.name.pristine
                }"
                formControlName="name"
              />
              <div *ngIf="f.name.errors && !f.name.pristine">
                <span class="error" [hidden]="!f.name.errors.required">
                  O nome é obrigatório
                </span>
              </div>
            </div>

            <ng-container *ngIf="shouldShowInfoIntegration()">
              <div class="mb-3">
                <input
                  type="checkbox"
                  id="check"
                  name="check"
                  (click)="toggleIntegrationWithFolder()"
                />
                <label for="check">Criar integração com a pasta</label>
              </div>

              <div
                *ngIf="hasIntegrationWithFolder()"
                [formGroup]="folderForm.get('metadata')"
              >
                <div class="form-group">
                  <label
                    >Código da integração
                    <i [title]="infoText()" class="fa fa-info-circle"></i
                  ></label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="file_cabinet_id"
                  />
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-5 mb-3">
            <button
              type="button"
              class="btn btn-default"
              (click)="modal.close('cancel')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-secondary btn-sm"
              type="submit"
              [disabled]="folderForm.invalid || isLoading"
            >
              Criar pasta
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
