import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ConfirmBusinessFormComponent,
  ConfirmPersonalFormComponent
} from '@app/auth/components';
import { SharedModule } from '@app/shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { ConfirmAccountComponent } from './pages/confirm-account/confirm-account.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ActivateUserComponent } from './pages/activate-user/activate-user.component';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmChangeBusinessUnitComponent } from './pages/confirm-change-business-unit/confirm-change-business-unit.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ActivateUserComponent,
    ConfirmAccountComponent,
    ConfirmBusinessFormComponent,
    ConfirmPersonalFormComponent,
    ConfirmChangeBusinessUnitComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    TranslateModule,
    SharedModule,
    NgxMaskModule,
    NgbModule,
    Ng2TelInputModule
  ]
})
export class AuthModule {}
