import {
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_SIGNATURE
} from '@app/core/services/workflow.enum';
import { NameField } from './fields/name-field.class';
import { EletronicSignatureField } from './fields/eletronic-signature-field.class';
import { DigitalSignatureField } from './fields/digital-signature-field.class';
import { InPersonSignatureField } from './fields/in-person-signature-field.class';
import { EmailField } from './fields/email-field.class';
import { QRCodeField } from './fields/qr-code-field.class';
import { SharedQRCodeField } from './fields/shared-qr-code-field.class';
import { SignDateField } from './fields/sign-date-field.class';
import { WorkflowContact } from '@app/core/class/workflow-contact.class';
import { IWorkflowDroppedField } from './workflow-dropped-field.interface';
import { InlineSignatureField } from './fields/inline-signature.class';
import { TextField } from './fields/text-field.class';
import { RubricSignatureField } from './fields/rubric-signature-field.class';
import { StampSignatureField } from './fields/stamp-signature-field.class';

export class WorkflowDroppedFieldsStrategy {
  public static MakeDroppedField(
    type: number,
    contact: WorkflowContact,
    signatureType: number = null
  ): IWorkflowDroppedField {
    switch (type) {
      case WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE:
        switch (signatureType) {
          case WORKFLOW_SIGNATURE.ELETRONIC:
            return new EletronicSignatureField(contact);

          case WORKFLOW_SIGNATURE.DIGITAL:
            return new DigitalSignatureField(contact);

          case WORKFLOW_SIGNATURE.IN_PERSON:
            return new InPersonSignatureField(contact);

          default:
            throw new Error(
              `The informed signature type ${signatureType} is invalid!`
            );
        }

      case WORKFLOW_STEP_FIELDS.TYPE_NAME_TEXT:
        return new NameField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_EMAIL_TEXT:
        return new EmailField(contact);

      case WORKFLOW_STEP_FIELDS.QR_CODE:
        return new QRCodeField(contact);

      case WORKFLOW_STEP_FIELDS.QR_CODE_SHARE:
        return new SharedQRCodeField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT:
        return new TextField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT:
        return new SignDateField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE:
        return new InlineSignatureField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_RUBRIC:
        return new RubricSignatureField(contact);

      case WORKFLOW_STEP_FIELDS.TYPE_STAMP:
        return new StampSignatureField(contact);

      default:
        throw new Error(`The informed field type ${type} is invalid!`);
    }
  }
}
