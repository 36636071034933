import * as tslib_1 from "tslib";
import { WORKFLOW_STEP_FIELDS, WORKFLOW_SIGNATURE } from '@app/core/services/workflow.enum';
import { EletronicSignatureField } from './eletronic-signature-field.class';
var DigitalSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(DigitalSignatureField, _super);
    function DigitalSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
        _this.signatureType = WORKFLOW_SIGNATURE.DIGITAL;
        return _this;
    }
    return DigitalSignatureField;
}(EletronicSignatureField));
export { DigitalSignatureField };
