import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EVENT_TYPES } from '@app/core/services';
var CustomSubjectMailFormComponent = /** @class */ (function () {
    function CustomSubjectMailFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.eventsTypeKeys = Object.keys(EVENT_TYPES);
        this.eventsTypes = EVENT_TYPES;
    }
    /**
     * Data selection action by clicking the form submit button
     */
    CustomSubjectMailFormComponent.prototype.doSubmit = function () {
        var toAction = {
            action: 'save',
            form: 'customSubject',
            formData: this.customSubjectForm.value
        };
        if (this.item) {
            toAction.id = this.item.id;
            toAction.action = 'edit';
        }
        this.modal.close(toAction);
    };
    Object.defineProperty(CustomSubjectMailFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.customSubjectForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    CustomSubjectMailFormComponent.prototype.createForm = function () {
        var customSubject = this.item ? this.item : {};
        this.customSubjectForm = this.formBuilder.group({
            event: [customSubject.event || '', Validators.required],
            subject: [customSubject.subject || '', Validators.required]
        });
    };
    CustomSubjectMailFormComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    return CustomSubjectMailFormComponent;
}());
export { CustomSubjectMailFormComponent };
