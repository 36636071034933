import { WorkflowFile } from './workflow-file.class';
import { WorkflowContact } from './workflow-contact.class';
import { Workflow } from '../models/workflow.model';
import { WorkflowFilePage } from './workflow-file-page.class';
import { WorkflowDroppedFieldsStrategy } from './workflow-dropped-fields/workflow-dropped-field-strategy.class';
import {
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_SIGNATURE
} from '../services/workflow.enum';
import { IWorkflowDroppedField } from './workflow-dropped-fields/workflow-dropped-field.interface';
import { WorkflowDroppedField } from './workflow-dropped-fields/workflow-dropped-field.class';

export class WorkflowBuilder {
  private files: WorkflowFile[] = [];
  private contacts: WorkflowContact[] = [];

  private constructor(files: WorkflowFile[], contacts: WorkflowContact[]) {
    this.files = files;
    this.contacts = contacts;
  }

  public static MakeWorkflowBuilder(
    files: WorkflowFile[],
    contacts: WorkflowContact[]
  ): WorkflowBuilder {
    return new WorkflowBuilder(files, contacts);
  }

  public compile(settings: Workflow.Settings): Workflow.Settings {
    const workflowSettings: Workflow.Settings = Object.assign(
      {
        autoInitiate: 1,
        files: []
      } as Workflow.Settings,
      settings
    );

    for (const file of this.files) {
      let specialFields: Workflow.SpecialField[] = [];
      const steps: Workflow.WorkflowStep[] = [];

      const fields = file.getAllFields();

      this.shouldChangeSubscription(file.getAllFields());

      specialFields = this.extractSpecialFields(fields);

      // This will create the steps, each contact is one step
      this.contacts.forEach((contact, index) => {
        const step = this.extractStep(file.getPages(), contact);
        if (step) {
          step.index = index;
          steps.push(step);
        }
      });

      const compiledFile: Workflow.File = {
        idFile: file.getIdFile(),
        idFolder: file.getFolderId(),
        name: file.getName(),
        dueDate: file.getDueDate(),
        specialFields,
        workflowSteps: steps
      };

      workflowSettings.files.push(compiledFile);
    }

    return workflowSettings;
  }

  private shouldChangeSubscription(fields: IWorkflowDroppedField[]) {
    fields.forEach((signature: any) => {
      if (signature.signatureType === WORKFLOW_SIGNATURE.STAMP) {
        signature.signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
      }
    });
  }

  private signatureByContact(contact: WorkflowContact): number {
    let contactSignatureType;
    // Search in each file added
    for (const file of this.files) {
      // For a signature field
      for (const field of file.getAllFields()) {
        // If there is at least one signature, set to true
        if (field.isSignature() && field.getContact() === contact) {
          contactSignatureType = field.getSignatureType();
          break;
        }
      }
      if (contactSignatureType) {
        break;
      }
    }

    return contactSignatureType;
  }

  private extractSpecialFields(
    fields: IWorkflowDroppedField[]
  ): Workflow.SpecialField[] {
    const specialFields: Workflow.SpecialField[] = [];

    const droppedSpecialFields = fields.filter(field => field.isSpecialField());

    for (const field of droppedSpecialFields) {
      const specialField: Workflow.SpecialField = {
        type: field.getType(),
        x: field.getRawX(),
        y: field.getRawY(),
        height: field.getRawHeight(),
        width: field.getRawWidth(),
        page: field.getPageNumber()
      };
      specialFields.push(specialField);
    }

    return specialFields;
  }

  private extractStep(
    pages: WorkflowFilePage[],
    contact: WorkflowContact
  ): Workflow.WorkflowStep | null {
    const contactFields: IWorkflowDroppedField[] = [];

    for (const page of pages) {
      for (const field of page.getFieldsOfContact(contact)) {
        if (field.isSpecialField()) {
          continue;
        }

        contactFields.push(field);
      }
    }

    const signatureFieldIdx = contactFields.findIndex(f => f.isSignature());

    if (signatureFieldIdx === -1) {
      return null;
    }

    const step: Workflow.WorkflowStep = {
      user: {
        name: contact.name,
        email: contact.email,
        whatsappNumber: contact.whatsappNumber,
        notifyWhatsapp: contact.notifyWhatsapp
      },
      extraMetadata: contact.getExtraMetadata(),
      action: contact.getAction(),
      hasPhotoSign: contact.getHasPhotoSign(),
      signatureType: contactFields[signatureFieldIdx].getSignatureType(),
      fields: []
    };

    // if (contact.id) {
    //   step.idUser = contact.id;
    //   delete step.user;
    // }

    for (const contactField of contactFields) {
      const field = this.extractStepField(contactField);
      step.fields.push(field);
    }

    return step;
  }

  private extractStepField(
    field: IWorkflowDroppedField
  ): Workflow.WorkflowStepsField {
    return {
      type: field.getType(),
      x: field.getRawX(),
      y: field.getRawY(),
      height: field.getRawHeight(),
      width: field.getRawWidth(),
      page: field.getPageNumber(),
      properties: field.getPropertiesField()
    };
  }
}
