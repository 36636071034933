import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

import {
  UserService,
  CountryService,
  StateService,
  SignatureService,
  AuthorizationService,
  PlansService,
  AuthenticationService,
  BusinessService,
  SIGNATURE_TYPE
} from '@app/core/services';
import {
  Authentication,
  Country,
  State,
  TypeOfSignature,
  User
} from '@app/core/models';
import { UserSubscription } from '@app/core/subscriptions';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SIGNATORY_CATEGORIES } from '@app/core/enums/SignatoryCategories';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfilePageComponent implements OnInit {
  @ViewChild('modalLoading') loading: ElementRef;

  logged: Authentication.SignupUserResponse;

  photo: User.UserMetadata;

  planInfo: User.UserPlanPayload;

  paymentInfo: User.UserPaymentInfo;

  isLoading: boolean = false;

  isLoadingProfile: boolean = false;

  isLoadingModal: boolean = false;

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  countryList: Array<Country.FormPayload>;

  stateList: Array<State.FormPayload>;

  modalRef: any;

  userSignProfile: any;
  signatureProfileForm: FormGroup;
  signatoryProfileList: any;
  profileFieldsModel: any;
  userProfileFields: any;
  signatureProfileIsChanged: any;

  public typeOfSignature: TypeOfSignature.Payload;

  constructor(
    private businessService: BusinessService,
    private countryService: CountryService,
    private userService: UserService,
    private stateService: StateService,
    private planService: PlansService,
    private authenticationService: AuthenticationService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private userSubscription: UserSubscription,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder
  ) {
    this.userSubscription.getUser().subscribe(data => (this.logged = data));
    this.modalRef = { ref: null, data: { text: '' } };
    this.typeOfSignature = {
      name: 'Assinatura visivel',
      sign: SIGNATURE_TYPE.GENERIC
    };
  }

  ngOnInit() {
    this.logged = this.authorizationService.getUser();
    this.userSignProfile = {};
    this.userProfileFields = [];
    this.signatureProfileIsChanged = false;

    this.userService.getPlanInfo().subscribe((body: any) => {
      this.planInfo = body;
    });

    this.userService
      .getPaymentInfo()
      .subscribe(body => (this.paymentInfo = body));
    this.userService
      .getAllMetadata()
      .pipe(
        map((body: Array<User.UserMetadata>) => {
          return body.filter((e: User.UserMetadata) => e.dataKey === 'picture');
        })
      )

      .subscribe((response: Array<User.UserMetadata>) => {
        this.photo = response.length ? response[0] : null;
      });

    this.countryList = this.countryService.list();
    this.stateList = this.stateService.list();

    this.signatoryProfileList = Object.values(SIGNATORY_CATEGORIES);
    this.signatoryProfileList.unshift({
      id: '0',
      label: 'Padrão'
    });
    this._loadSignProfile();
    this.signatureProfileIsChanged = false;
  }

  checkAdminOrUser(): boolean {
    if (this.logged.roleDefault === 'admin') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Action by clicking in submit business form
   *
   * @param (any) form with data
   */
  doSubmitBusinessForm(form: any) {
    const values = form.values;

    this.businessService
      .update(this.logged.businessUnit.id.toString(), values.businessUnit)
      .subscribe(
        data => {
          this.feedbackTheme = 'success';
          this.feedbackMessage = 'Detalhes da conta atualizado com sucesso.';
          Object.assign(this.logged.businessUnit, data);
        },
        error => {
          this.feedbackTheme = 'error';
          this.feedbackMessage =
            `Não foi possível atualizar os dados desta conta.` +
            ` Tente novamente`;
        }
      );
  }

  /**
   * Action by clicking in submit personal form
   *
   * @param (any) form with data
   */
  doSubmitPersonalForm(form: any) {
    const values = form.values;

    this.userService.update(this.logged.id.toString(), values).subscribe(
      data => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Detalhes da conta atualizado com sucesso.';
        Object.assign(this.logged, data);
      },
      error => {
        this.feedbackTheme = 'error';
        this.feedbackMessage =
          `Não foi possível atualizar os dados desta conta.` +
          ` Tente novamente`;
      }
    );
  }

  /**
   * Action by clicking in submit change password form
   *
   * @param (User.ChangePasswordFormPayload) form with data
   */
  doSubmitChangePasswordForm(form: User.ChangePasswordFormPayload) {
    this.userService
      .update(this.logged.id.toString(), {
        oldPassword: form.currentPassword,
        password: form.newPassword
      })
      .subscribe(
        () => {
          this.feedbackTheme = 'success';
          this.feedbackMessage = 'Senha modificada com sucesso.';
        },
        error => {
          this.feedbackTheme = 'error';
          this.feedbackMessage = `Não foi possível modificar sua senha. Tente novamente.`;
        }
      );
  }

  logout() {
    this.userService.removeUserLocalData();
    this.authenticationService.logout().subscribe(() => location.assign('/'));
  }

  disableUser(form: User.CancelAccountFormPayload) {
    this.modalRef.data.text = 'Desativando sua conta, aguarde...';
    this.userService.update(this.logged.id.toString(), form).subscribe(
      () => {
        this.logout();
      },
      error => {
        this.modalRef.ref.close();
        this.feedbackTheme = 'error';
        this.feedbackMessage = `Não foi possível desativar sua conta.`;
      }
    );
  }

  /**
   * Action by clicking in submit cancel account form
   *
   * @param (User.CancelAccountFormPayload) form with data
   */
  doSubmitCancelAccountForm(form: User.CancelAccountFormPayload) {
    this.modalRef.data.text = 'Processando...';
    this.modalRef.ref = this.openLoading(this.loading);

    delete form.reason;

    if (this.planInfo.id) {
      this.planService.cancelPlan(this.planInfo.id).subscribe(
        () => {
          this.disableUser(form);
        },
        error => {
          this.modalRef.ref.close();
          this.feedbackTheme = 'error';
          this.feedbackMessage = `Não foi possível desativar sua conta. Tente novamente.`;
        }
      );
    } else {
      this.disableUser(form);
    }
  }

  /**
   * Action by clicking in submit signature form
   *
   * @param (any) form with data
   */
  doSubmitSignatureForm(form: any) {
    this.signatureService.create(form).subscribe(
      () => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Assinatura adicionada com sucesso!';
      },
      error => {
        this.feedbackTheme = 'error';
        this.feedbackMessage = `Não foi possível adicionar sua assinatura. Tente novamente.`;
      }
    );
  }

  openLoading(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any) {
    this.modalService
      .open(content.modal, {
        ariaLabelledBy: 'modal-title',
        centered: true,
        keyboard: false,
        backdrop: 'static',
        size: content.size
      })
      .result.then(result => {
        if (result.action === 'save') {
          if (result.form === 'cancel-account') {
            this.doSubmitCancelAccountForm(result.formData);
            return;
          }

          if (result.form === 'change-password') {
            this.doSubmitChangePasswordForm(result.formData);
            return;
          }

          if (result.form === 'signature') {
            this.doSubmitSignatureForm(result.formData);
            return;
          }
        }

        if (result === 'cancel') {
          return;
        }
      });
  }

  /**
   * Action to update Feedback component
   *
   * @param (any) Object data to update Feedback component
   * @return void
   */
  updateFeedback(event: any) {
    this.feedbackTheme = event.theme;
    this.feedbackMessage = event.message;
  }

  _loadSignProfile(profileId?: any) {
    this.signatureProfileIsChanged = true;
    this.userService.getUserSignProfile().then((signProfile: any) => {
      const group: any = {};
      group.selectedProfile = [];
      if (!signProfile.signatoryCategory) {
        this.userService.setUserSignProfileId(0);
        this.userSignProfile = {
          signatoryCategory: {
            id: 0
          }
        };
        this._makeSignatureProfileForm(group);
      } else {
        let userProfileDataFields: any = '';
        this.userSignProfile = signProfile;

        if (profileId) {
          this.userSignProfile.signatoryCategory.id = profileId;
        }

        if (this.userSignProfile.fields) {
          userProfileDataFields = this.userSignProfile.fields[
            this.userSignProfile.signatoryCategory.id
          ];
        }

        const fields = this._getFieldsFromSignatoryProfileList(
          this.userSignProfile.signatoryCategory.id
        );
        Object.keys(fields).forEach((key: any) => {
          const userValue = userProfileDataFields[key] || '';
          group[key] = [userValue, Validators.required];
        });

        group.selectedProfile = [
          this.userSignProfile.signatoryCategory.id,
          Validators.required
        ];
        this._makeSignatureProfileForm(group);
      }
      this.signatureProfileForm
        .get('selectedProfile')
        .setValue(this.userSignProfile.signatoryCategory.id);
      this.signatureProfileForm.valueChanges.subscribe(e => {
        this.signatureProfileIsChanged = true;
      });
    });
  }

  _getFieldsFromSignatoryProfileList(categoryId: any) {
    const fields = {};
    const profile = this.signatoryProfileList
      .filter((item: any) => {
        return parseInt(item.id) === parseInt(categoryId);
      })
      .shift();
    if (profile) {
      if (profile.signatoryData) {
        profile.signatoryData.forEach((field: any) => {
          fields[field.oid] = '';
        });
      }
    }
    return fields;
  }

  _makeSignatureProfileForm(group: any) {
    this.signatureProfileForm = this.formBuilder.group(group);
  }

  doSubmitSignatureProfileForm() {
    this.isLoadingProfile = true;
    const fields: any = this.signatureProfileForm.controls;
    if (!fields.selectedProfile.value) {
      this.updateFeedback({
        theme: 'error',
        message: 'Por Favor, escolha um perfil de assinatura!'
      });
      return false;
    }
    const profileId: any = fields.selectedProfile.value;
    const profileFields = {};
    Object.keys(fields).forEach((key: any) => {
      if (key === 'selectedProfile') {
        return false;
      }
      profileFields[key] = fields[key].value;
    });
    this.userService
      .setUserSignProfileFieldsData(profileId, profileFields)
      .then((f: any) => {
        this.userService.setUserSignProfileId(profileId);
        this.isLoadingProfile = false;
        this.signatureProfileIsChanged = false;
        this.updateFeedback({
          theme: 'success',
          message: 'Perfil de assinatura atualizado com sucesso!'
        });
      });
  }
}
