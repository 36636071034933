<div class="container-fluid">
  <div *ngIf="activated == 1">
    <h3>Confirmar troca de corporação</h3>
    <p class="font_1 mt-3">
      Para confirmar sua troca de corporação no Assine Online, clique no botão
      abaixo:
    </p>
    <div class="d-flex justify-content-start mt-4">
      <button
        class="btn btn-secondary btn-sm "
        type="submit"
        (click)="doSubmit()"
      >
        Confirmar
      </button>
    </div>
  </div>

  <div *ngIf="activated == 2">
    <h4>Troca de corporação confirmada</h4>
    <p class="font_1 mt-3">
      Agora você faz parte de uma corporação, envie arquivos em poucos passos,
      sem complicação.
    </p>

    <button class="btn btn-secondary mt-2" routerLink="/auth/login">
      Fazer login
    </button>
  </div>

  <div *ngIf="activated == 3">
    <h4>Ops! Algo de errado aconteceu.</h4>
    <p class="font_1 mt-3">
      Não conseguimos registrar a troca de corporação à qual você foi convidado,
      favor refaça o processo.
    </p>
  </div>
</div>
