import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WorkflowContact } from '@app/core/class';
import {
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_SIGNATURE
} from '@app/core/services/workflow.enum';

export class StampSignatureField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_STAMP;
    this.signatureType = WORKFLOW_SIGNATURE.STAMP;
    this.minHeight = this.height = 40;
    this.minWidth = this.width = 140;
  }

  public isSignature(): boolean {
    return true;
  }

  public shouldKeepAspectRatio(): boolean {
    return true;
  }
}
