import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
var BusinessService = /** @class */ (function (_super) {
    tslib_1.__extends(BusinessService, _super);
    function BusinessService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/business-unit';
        _this.apiFieldsMap = {
            name: 'name',
            email: 'email',
            document: 'document',
            country: 'country',
            state: 'state',
            city: 'city',
            address: 'address',
            zipCode: 'zipCode'
        };
        _this.fieldsRequired = [
            'name',
            'email',
            'password',
            'document',
            'country',
            'state',
            'city',
            'address',
            'zipCode'
        ];
        return _this;
    }
    return BusinessService;
}(BaseAPI));
export { BusinessService };
