<div class="subscribe-list">
  <div class="section-title">
    <h3><i class="fa fa-user-edit"></i> <span translate>Assinantes</span></h3>
  </div>
  <div class="section-content">
    <div class="section-content-item mt-2" *ngIf="!recipients.length">
      <span class="item waiting ">
        <i class="fa fa-signature"></i> Não há assinaturas para este documento
      </span>
    </div>

    <div class="section-content-item" *ngIf="sign.length > 0">
      <h4><span translate>Faltam Assinar</span></h4>
      <ul [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
        <li class="item waiting" *ngFor="let item of sign">
          <span class="item-icon">
            <i *ngIf="item.status.action === 'waiting'" class="fa fa-clock"></i>
            <i *ngIf="item.status.action === 'done'" class="fa fa-check"></i>
            <i *ngIf="item.status.action === 'reject'" class="fa fa-ban"></i>
          </span>

          <span class="item-text">
            <span class="item-text-signature">{{
              checkSignatureName(item)
            }}</span>

            <ng-container *ngIf="!checkSubscriptionType(item)">
              <span class="item-text-name">{{ item.name }}</span>
              <!-- <span class="item-text-email">{{ item.email }}</span> -->
            </ng-container>

            <ng-container *ngIf="checkSubscriptionType(item)">
              <span class="item-text-name">{{
                item.details.extraMetadata.name
              }}</span>
              <span class="item-text-email">{{ formatDocument(item) }}</span>
            </ng-container>

            <span class="item-text-status"> {{ item.status?.text }} </span>
          </span>
        </li>
      </ul>
    </div>
    <div class="section-content-item" *ngIf="signed.length > 0">
      <h4><span translate>Assinaram</span></h4>
      <ul [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
        <li class="item done" *ngFor="let item of signed">
          <span class="item-icon"> <i class="fa fa-check"></i> </span>
          <span class="item-text">
            <span class="item-text-signature">{{
              checkSignatureName(item)
            }}</span>

            <ng-container *ngIf="!checkSubscriptionType(item)">
              <span class="item-text-name">{{ item.name }}</span>
              <!-- <span class="item-text-email">{{ item.email }}</span> -->
            </ng-container>

            <ng-container *ngIf="checkSubscriptionType(item)">
              <span class="item-text-name">{{
                item.details.extraMetadata.name
              }}</span>
              <span class="item-text-email">{{
                item.details.extraMetadata.document
              }}</span>
            </ng-container>

            <span class="item-text-status">{{ item.status?.text }}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="section-content-item" *ngIf="rejected.length > 0">
      <h4><span translate>Rejeitaram</span></h4>
      <ul [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
        <li class="item done" *ngFor="let item of rejected">
          <span class="item-icon"> <i class="fa fa-ban"></i> </span>
          <span class="item-text">
            <span class="item-text-signature">{{
              checkSignatureName(item)
            }}</span>

            <ng-container *ngIf="!checkSubscriptionType(item)">
              <span class="item-text-name">{{ item.name }}</span>
              <!-- <span class="item-text-email">{{ item.email }}</span> -->
            </ng-container>

            <ng-container *ngIf="checkSubscriptionType(item)">
              <span class="item-text-name">{{
                item.details.extraMetadata.name
              }}</span>
              <span class="item-text-email">{{
                item.details.extraMetadata.document
              }}</span>
            </ng-container>

            <span class="item-text-status">{{ item.status?.text }}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="section-content-item" *ngIf="processing.length > 0">
      <h4><span translate>Processando</span></h4>
      <ul [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
        <li class="item done" *ngFor="let item of processing">
          <span class="item-icon"> <i class="fa fa-clock"></i> </span>
          <span class="item-text">
            <span class="item-text-signature">{{
              checkSignatureName(item)
            }}</span>

            <ng-container *ngIf="!checkSubscriptionType(item)">
              <span class="item-text-name">{{ item.name }}</span>
              <!-- <span class="item-text-email">{{ item.email }}</span> -->
            </ng-container>

            <ng-container *ngIf="checkSubscriptionType(item)">
              <span class="item-text-name">{{
                item.details.extraMetadata.name
              }}</span>
              <span class="item-text-email">{{
                item.details.extraMetadata.document
              }}</span>
            </ng-container>

            <span class="item-text-status">{{ item.status?.text }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
