/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organization-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/modal/modal-action/modal-action.component.ngfactory";
import * as i3 from "../../../shared/components/modal/modal-action/modal-action.component";
import * as i4 from "../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i5 from "ngx-image-cropper";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./organization-logo.component";
import * as i9 from "../../../core/services/business-unit-metadata.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
var styles_OrganizationLogoComponent = [i0.styles];
var RenderType_OrganizationLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganizationLogoComponent, data: {} });
export { RenderType_OrganizationLogoComponent as RenderType_OrganizationLogoComponent };
function View_OrganizationLogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon icon-cloud i-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload marca"]))], null, null); }
function View_OrganizationLogoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logo; _ck(_v, 0, 0, currVal_0); }); }
function View_OrganizationLogoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin spin"]], null, null, null, null, null))], null, null); }
function View_OrganizationLogoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_OrganizationLogoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(1, 0, null, null, 29, "app-modal-action", [], null, null, null, i2.View_ModalActionComponent_0, i2.RenderType_ModalActionComponent)), i1.ɵdid(2, 49152, null, 0, i3.ModalActionComponent, [], { modal: [0, "modal"], showHeader: [1, "showHeader"], showFooter: [2, "showFooter"], showClose: [3, "showClose"], typeModal: [4, "typeModal"], title: [5, "title"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "image-cropper", [["format", "png"]], [[4, "text-align", null], [2, "disabled", null]], [[null, "imageCropped"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 5).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_6 = (_co.loadImageFailed() !== false);
        ad = (pd_6 && ad);
    } return ad; }, i4.View_ImageCropperComponent_0, i4.RenderType_ImageCropperComponent)), i1.ɵdid(5, 638976, null, 0, i5.ImageCropperComponent, [i6.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"], resizeToHeight: [5, "resizeToHeight"], roundCropper: [6, "roundCropper"], onlyScaleDown: [7, "onlyScaleDown"], containWithinAspectRatio: [8, "containWithinAspectRatio"] }, { imageCropped: "imageCropped", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵted(-1, 0, ["\n\n    "])), (_l()(), i1.ɵeld(7, 0, null, 0, 22, "div", [["class", "row mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "col-6 col-sm-4 col-md-4 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btm-sm btn-outline-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleContainWithinAspectRatio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["\n          ", "\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "col-6 col-sm-4 col-md-4 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "button", [["class", "btn btn-secondary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationLogoComponent_5)), i1.ɵdid(20, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n          Salvar\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "col-6 col-sm-3 col-md-3 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fechar"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; var currVal_4 = "simple green"; var currVal_5 = "Recortar imagem"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _co.imageChangedEvent; var currVal_9 = "png"; var currVal_10 = true; var currVal_11 = (4 / 1); var currVal_12 = 190; var currVal_13 = 25; var currVal_14 = false; var currVal_15 = true; var currVal_16 = _co.containWithinAspectRatio; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_19 = _co.spinnerButton; _ck(_v, 20, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = i1.ɵnov(_v, 5).alignImage; var currVal_7 = i1.ɵnov(_v, 5).disabled; _ck(_v, 4, 0, currVal_6, currVal_7); var currVal_17 = (_co.containWithinAspectRatio ? "Propor\u00E7\u00E3o" : "Preenchimento"); _ck(_v, 12, 0, currVal_17); var currVal_18 = !_co.croppedImage; _ck(_v, 17, 0, currVal_18); }); }
function View_OrganizationLogoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(1, 0, null, null, 14, "app-modal-action", [], null, null, null, i2.View_ModalActionComponent_0, i2.RenderType_ModalActionComponent)), i1.ɵdid(2, 49152, null, 0, i3.ModalActionComponent, [], { modal: [0, "modal"], showHeader: [1, "showHeader"], showFooter: [2, "showFooter"], showClose: [3, "showClose"], typeModal: [4, "typeModal"], title: [5, "title"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "div", [["class", "d-flex justify-content-center mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "font-weight-bold"], ["style", "font-size: 1rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        N\u00E3o \u00E9 possivel realizar upload do arquivo selecionado.\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, 0, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fechar"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = false; var currVal_3 = true; var currVal_4 = "simple red"; var currVal_5 = "Aviso!"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_OrganizationLogoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalError: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 40, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 37, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 22, "div", [["class", "box-logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 19, "label", [["class", "file-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "mask"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileSelector(i1.ɵnov(_v, 43)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-plus-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationLogoComponent_1)), i1.ɵdid(17, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationLogoComponent_2)), i1.ɵdid(20, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationLogoComponent_3)), i1.ɵdid(23, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(25, 0, null, null, 0, "input", [["accept", ".png, .jpg"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.imageFileChangeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personalize seus e-mails com a sua marca."])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(32, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Formatos suportados: "])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["JPG"])), (_l()(), i1.ɵted(-1, null, [" e "])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PNG"])), (_l()(), i1.ɵted(-1, null, [" -\n      Propor\u00E7\u00E3o 190x33.\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(0, [["modalCropImage", 2]], null, 0, null, View_OrganizationLogoComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(0, [[1, 2], ["modalError", 2]], null, 0, null, View_OrganizationLogoComponent_6)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.spinnerLogo && !_co.logo); _ck(_v, 17, 0, currVal_0); var currVal_1 = _co.logo; _ck(_v, 20, 0, currVal_1); var currVal_2 = _co.spinnerLogo; _ck(_v, 23, 0, currVal_2); }, null); }
export function View_OrganizationLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organization-logo", [], null, null, null, View_OrganizationLogoComponent_0, RenderType_OrganizationLogoComponent)), i1.ɵdid(1, 114688, null, 0, i8.OrganizationLogoComponent, [i9.BusinessMetadataService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganizationLogoComponentNgFactory = i1.ɵccf("app-organization-logo", i8.OrganizationLogoComponent, View_OrganizationLogoComponent_Host_0, {}, { setFeedbackMessage: "setFeedbackMessage" }, []);
export { OrganizationLogoComponentNgFactory as OrganizationLogoComponentNgFactory };
