import { EletronicSignatureField } from './eletronic-signature-field.class';
import { WorkflowContact } from '../../workflow-contact.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';

export class InlineSignatureField extends EletronicSignatureField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE;
  }
}
