import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-delete-form',
  templateUrl: './contact-delete-form.component.html'
})
export class ContactDeleteFormComponent implements OnInit {
  /**
   * Data of Form
   */
  deleteContactForm: FormGroup;

  /**
   * Data of current user
   */
  @Input() selected?: any;

  /**
   * Informs if loading is active or not
   */
  @Input() isLoading = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Delete contact
   */
  deleteContact() {
    const payload = {
      id: this.selected.id
    };

    this.modal.close({
      action: 'delete',
      form: 'contact',
      formData: payload
    });
  }
  private createForm() {
    this.deleteContactForm = this.formBuilder.group({});
  }

  ngOnInit() {}
}
