import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@app/core/services';
import { Logger } from '@app/core/class';

const log = new Logger('Auth');

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  removeRegister = false;

  constructor(
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    if (this.authorizationService.isAuthenticated()) {
      log.debug(`user logged, redirect to /manager`);
      window.location.href = 'manager';
      return;
    }

    if (this.route.snapshot.data.removeRegister) {
      this.removeRegister = this.route.snapshot.data.removeRegister;
    }
  }
}
