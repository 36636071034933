import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-stamp',
  templateUrl: './feedback-stamp.component.html',
  styleUrls: ['./feedback-stamp.component.scss']
})
export class FeedbackStampComponent implements OnInit {
  @Input() modal: any;

  constructor() {}

  public ngOnInit(): void {}

  public doSubmit(): void {
    this.modal.close({
      action: 'save',
      form: 'feedback-stamp',
      formData: true
    });
  }
}
