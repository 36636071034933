import { Component, OnInit } from '@angular/core';
import { PolicyDocument } from '@app/core/models';
import { Documents } from 'assets/policy/document';

@Component({
  selector: 'app-layout-manager-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class LayoutManagerFooterComponent implements OnInit {
  public year: number = new Date().getFullYear();

  public termsOfUse: PolicyDocument.File;
  public cookies: PolicyDocument.File;
  public privacyPolicy: PolicyDocument.File;

  constructor() {
    this.termsOfUse = Documents.termsOfUse();
    this.cookies = Documents.cookies();
    this.privacyPolicy = Documents.privacyPolicy();
  }

  ngOnInit() {}
}
