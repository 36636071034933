import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { TokenSession } from '@app/core/models';
import { AccessToken } from '@app/core/services/access-token.service';
import * as moment from 'moment';

@Component({
  selector: 'app-access-token-event',
  templateUrl: './access-token-event.component.html',
  styleUrls: ['./access-token-event.component.scss']
})
export class AccessTokenEventComponent implements OnInit {
  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  /**
   * object of modal
   */
  @Input() modal: any;

  public date: string;

  session = new FormControl();

  public data: FormGroup;

  public spinnerWait: boolean = false;

  public responseDate: TokenSession.Session;

  public dueDate: string;

  constructor(
    private accessToken: AccessToken,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.createForm();
    this.data.get('dueDate').setValue(this.validTokenExpirationDate());
  }

  public copyTokenToClipboard(accessToken: any): void {
    accessToken.select();
    document.execCommand('copy');
    accessToken.setSelectionRange(0, 0);
  }

  public validTokenExpirationDate(): string {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // because January is 0!
    const yyyy = today.getFullYear() + 1;
    const now = yyyy + '-' + mm + '-' + dd + ' ' + '23:59:00';

    return now;
  }

  public doSubmit(): void {
    this.spinnerWait = true;

    const payload: TokenSession.SessionPayload = {
      password: this.data.value.password,
      expires: this.data.value.dueDate
    };

    this.accessToken.createAccessToken(payload).subscribe(
      (reponse: TokenSession.Session) => {
        this.responseDate = reponse;
        this.session.setValue(this.responseDate.accessToken);
        this.data.get('password').setValue('');
        this.dueDate = moment(this.responseDate.expires.date).format(
          'DD/MM/YYYY'
        );
        this.spinnerWait = false;
      },
      (error: any) => {
        this.spinnerWait = false;
        if (error.error.status === 400) {
          this.spinnerWait = false;
          this.feedbackTheme = 'error';
          this.feedbackMessage =
            'A credencial informada não confere, Tente novamente';
        } else {
          this.spinnerWait = false;
          this.feedbackTheme = 'error';
          this.feedbackMessage =
            'Não foi possível gerar o token de acesso, Tente novamente';
        }
      }
    );
  }

  /**
   * Form creation and validation configuration
   */
  private createForm(): void {
    this.data = this.formBuilder.group({
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)])
      ],
      dueDate: ['']
    });
  }
}
