import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Logger } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import cartesian from '@app/core/helpers/cartesian';
import { WorkflowService, DocumentService, SignatureService, WORKFLOW_CONTACT_ACTION, WORKFLOW_SIGNATURE, WORKFLOW_STATUS, WORKFLOW_STEP_STATUS, WORKFLOW_STEP_FIELDS, AuthorizationService, UserService, WORKFLOW_TEXT_STATUS } from '@app/core/services';
import { DatePipe } from '@angular/common';
import { BusinessMetadataService } from '@app/core/services/business-unit-metadata.service';
import { SIGNATURE_TYPE } from '@app/core/services/workflow.enum';
import { WorkflowDocumentComponent } from '@app/shared/components/workflow/workflow-document.component';
var log = new Logger('Subscribe');
var finished = [
    WORKFLOW_STATUS.CANCELLED,
    WORKFLOW_STATUS.EXPIRED,
    WORKFLOW_STATUS.REJECTED,
    WORKFLOW_STATUS.COMPLETED
];
var LOCAL_STEP = {
    INITIAL: 0,
    AWAITING_SIGNATURE: 1,
    GENERIC_ERROR: 2,
    EXPIRED: 3,
    APPROVED: 4,
    REJECTED: 5,
    CANCELLED: 6,
    NO_GEOLOCATION: 7,
    PROCESSING: 8,
    BIRDID_CREDENTIAL_ERROR: 9
};
var SubscribeComponent = /** @class */ (function () {
    function SubscribeComponent(authorizationService, workflowService, documentService, signatureService, userService, modalService, route, element, businessMetadataService) {
        this.authorizationService = authorizationService;
        this.workflowService = workflowService;
        this.documentService = documentService;
        this.signatureService = signatureService;
        this.userService = userService;
        this.modalService = modalService;
        this.route = route;
        this.element = element;
        this.businessMetadataService = businessMetadataService;
        this.WORKFLOW_STEP_FIELDS = WORKFLOW_STEP_FIELDS;
        this.WORKFLOW_SIGNATURE = WORKFLOW_SIGNATURE;
        this.WORKFLOW_STEP_STATUS = WORKFLOW_STEP_STATUS;
        this.WORKFLOW_STATUS = WORKFLOW_STATUS;
        this.LOCAL_STEP = LOCAL_STEP;
        this.SIGNATURE_TYPE = SIGNATURE_TYPE;
        /**
         * Check document has loaded
         */
        this.hasLoaded = false;
        /**
         * Step of assign
         */
        this.step = LOCAL_STEP.INITIAL;
        /**
         * Current Page selected
         */
        this.currentPage = 1;
        /**
         * Number of pages
         */
        this.totalPages = 0;
        /**
         * Enable edit
         */
        this.enableEdit = false;
        /**
         * Current zoom
         */
        this.currentZoom = 0;
        /**
         * Subscriber
         */
        this.subscriber = new Subject();
        this.isGuest = false;
        this.pageSize = {
            width: 0,
            height: 0
        };
        this.documentToolsContainerSize = {
            width: 0,
            height: 0
        };
        this.containerProportion = 1;
        this.loadDocument = true;
        this.signAcceptPolicy = false;
        this.modalRef = { ref: null, data: { text: '' } };
    }
    SubscribeComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlParam, signData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.pageRubricControl = { signedPage: [], documentsTotalPages: [] };
                this.position = {
                    lat: '-1.0',
                    lon: '-1.0'
                };
                this.cartesian = cartesian;
                try {
                    urlParam = atob(this.route.snapshot.params.token);
                    this.workflowId = JSON.parse(urlParam).id;
                    this.signerStep = JSON.parse(urlParam).u;
                    if (!this.workflowId) {
                        this.redirectToLanding();
                        return [2 /*return*/];
                    }
                    signData = JSON.parse(urlParam);
                    if (signData.a) {
                        this.authorizationService.setTempAccessToken(signData.a);
                        this.isGuest = true;
                    }
                    return [2 /*return*/, new Promise(function (resolve) {
                            setTimeout(function () {
                                _this.fetchGuestUserInfo().subscribe(function () {
                                    _this.modalRef.data.text = 'Obtendo a sua localização...';
                                    _this.modalRef.ref = _this.open(_this.loading);
                                    _this._getCurrentLocation();
                                    resolve();
                                });
                            });
                        })];
                }
                catch (e) {
                    log.debug("init:", e);
                    this.step = LOCAL_STEP.GENERIC_ERROR;
                }
                return [2 /*return*/];
            });
        });
    };
    SubscribeComponent.prototype.hasUserLoggedLocalStorage = function () {
        var logged;
        this.authorizationService.user.subscribe(function (user) {
            logged = user;
        });
        return logged && logged.email === this.userLogged.email;
    };
    SubscribeComponent.prototype.shouldShowLgpdModal = function () {
        if (!this.getLoggedUserAlreadySigned() &&
            !this.isProcessingWorkstep() &&
            !this.hasUserLoggedLocalStorage()) {
            this.open(this.modalAcceptPolicy, 'sm');
            return;
        }
        this.setSignAcceptPolicy(true);
    };
    SubscribeComponent.prototype.setSignAcceptPolicy = function (value) {
        this.signAcceptPolicy = value;
    };
    SubscribeComponent.prototype.getSignAcceptPolicy = function () {
        return !this.signAcceptPolicy;
    };
    SubscribeComponent.prototype.isEnabledFaceToFaceSignature = function () {
        return environment.featureFlags.faceToFaceSignature;
    };
    /**
     * Set current page
     *
     * @param (any) number of page
     * @return void
     */
    SubscribeComponent.prototype.setCurrentPage = function (data) {
        this.currentPage = data.page;
        this.totalPages = data.total;
        if (this.hasSubscribeRubric()) {
            this.setDocumentTotalPage();
        }
    };
    SubscribeComponent.prototype.getUserRubric = function () {
        var pagesRubric = {};
        this.workflow.currentUser.documentsFields
            .filter(function (field) { return field.type === WORKFLOW_STEP_FIELDS.TYPE_RUBRIC; })
            .forEach(function (field) {
            pagesRubric[field.document.id + "-" + field.page] = field;
        });
        return pagesRubric;
    };
    SubscribeComponent.prototype.getTotalPages = function () {
        return this.pageRubricControl.documentsTotalPages.reduce(function (sum, currentValue) { return sum + currentValue; }, 0);
    };
    SubscribeComponent.prototype.setDocumentTotalPage = function () {
        var _this = this;
        var index = this.workflow.documents.findIndex(function (document) {
            return document.id === _this.document.id;
        });
        this.pageRubricControl.documentsTotalPages[index] = this.totalPages;
    };
    SubscribeComponent.prototype.getNextRubricPage = function () {
        var _this = this;
        var nextPage = this.currentPage;
        var pages = this.getRubricFromDocument(this.document)
            .map(function (page) { return parseInt(page.split('-')[1]); })
            .sort(function (a, b) {
            return a - b;
        });
        pages.every(function (page) {
            if (page > _this.currentPage) {
                nextPage = page;
                return false;
            }
            return true;
        });
        return nextPage;
    };
    SubscribeComponent.prototype.toRubricPage = function () {
        this.pageRubricControl.signedPage.push({
            pageNumber: this.currentPage,
            documentId: this.document.id
        });
        this.workflowDocument.changePage(this.getNextRubricPage());
    };
    SubscribeComponent.prototype.getRubricFromDocument = function (document) {
        return Object.keys(this.getUserRubric())
            .filter(function (page) { return page.includes(document.id); })
            .sort();
    };
    SubscribeComponent.prototype.isCurrentpageRubricSigned = function () {
        var _this = this;
        var rubricsInPage = this.getRubricFromDocument(this.document).filter(function (page) { return page.includes("-" + _this.currentPage); });
        if (!rubricsInPage.length) {
            return true;
        }
        var signedPage = this.pageRubricControl.signedPage.find(function (page) {
            return (page.documentId === _this.document.id &&
                page.pageNumber === _this.currentPage);
        });
        return signedPage !== undefined;
    };
    SubscribeComponent.prototype.shouildRubricMessage = function () {
        return (this.hasSubscribeRubric() &&
            !this.isAllPageRubricSigned() &&
            !this.isSigned());
    };
    SubscribeComponent.prototype.isAllPageRubricSigned = function () {
        if (!this.hasSubscribeRubric()) {
            return true;
        }
        return (this.pageRubricControl.signedPage.length === this.hasSubscribeRubric());
    };
    SubscribeComponent.prototype.hasSubscribeRubric = function () {
        var rubrics = this.getUserRubric();
        return Object.keys(rubrics).length;
    };
    SubscribeComponent.prototype.countTotalFieldRubric = function () {
        var rubricFields = this.workflow.currentUser.documentsFields.filter(function (field) {
            return field.type === WORKFLOW_STEP_FIELDS.TYPE_RUBRIC;
        });
        return rubricFields.length;
    };
    SubscribeComponent.prototype.chooseSubscription = function (type) {
        switch (type) {
            case SIGNATURE_TYPE.RUBRIC:
                this.typeOfSignature = {
                    name: 'Crie sua rubrica',
                    sign: SIGNATURE_TYPE.RUBRIC
                };
                this.open(this.modalVisibleSignature);
                break;
            case SIGNATURE_TYPE.STAMP:
                this.typeOfSignature = {
                    name: 'Faça o upload da imagem do seu carimbo',
                    sign: SIGNATURE_TYPE.STAMP
                };
                this.open(this.modalVisibleSignature);
                break;
            case SIGNATURE_TYPE.GENERIC:
                this.typeOfSignature = {
                    name: 'Crie sua assinatura',
                    sign: SIGNATURE_TYPE.GENERIC
                };
                this.open(this.modalVisibleSignature);
                break;
            default:
                console.error("The informed signature type " + type + " is invalid!");
                break;
        }
    };
    SubscribeComponent.prototype.checkExtraMetadata = function (docs) {
        for (var _i = 0, docs_1 = docs; _i < docs_1.length; _i++) {
            var doc = docs_1[_i];
            for (var _a = 0, _b = doc.steps.workflowSteps; _a < _b.length; _a++) {
                var steps = _b[_a];
                if (steps.extraMetadata) {
                    if (steps.extraMetadata.photo) {
                        return true;
                    }
                    return false;
                }
            }
        }
    };
    SubscribeComponent.prototype.checkNotStepSigner = function () {
        if (this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.NOT_STARTED) {
            return true;
        }
        return false;
    };
    SubscribeComponent.prototype.shouldNotUserSign = function () {
        return this.isCirculating() && this.checkNotStepSigner();
    };
    SubscribeComponent.prototype.hasStampSignature = function () {
        return (this.workflow.currentUser.fields.find(function (field) { return field.type === WORKFLOW_STEP_FIELDS.TYPE_STAMP; }) !== undefined);
    };
    SubscribeComponent.prototype.hasElectronicSignature = function () {
        return (this.workflow.currentUser.fields.find(function (field) {
            return field.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
        }) !== undefined);
    };
    SubscribeComponent.prototype.createSignatureOrStamp = function () {
        if (this.hasStampSignature()) {
            this.chooseSubscription(SIGNATURE_TYPE.STAMP);
            return;
        }
        this.chooseSubscription(SIGNATURE_TYPE.GENERIC);
    };
    SubscribeComponent.prototype.shoulCreateSignature = function () {
        return ((this.hasStampSignature() && !this.checkStampData()) ||
            (this.hasElectronicSignature() && !this.checkSignatureData()));
    };
    /**
     * Get Data of workflow
     */
    SubscribeComponent.prototype.getWorkflowData = function () {
        var _this = this;
        try {
            this.modalRef.data.text = 'baixando dados do documento...';
            this.workflowService.getWorkflow(this.workflowId).subscribe(function (response) {
                log.debug("Subscribe response:", response);
                _this.getSignatureList();
                _this.workflow = response;
                if (_this.workflow.owner._embedded.business_unit._embedded) {
                    _this.businessMetadataService.inputBrand(_this.workflow.owner._embedded.business_unit._embedded
                        .business_unit_metata[0]);
                }
                _this.document = _this.workflow.documents[0];
                _this.recipients = _this.document.steps.recipients;
                if (!response.workflow.id) {
                    log.debug("Subscribe error (service):", response.detail);
                    _this.hasLoaded = true;
                    _this.step = LOCAL_STEP.GENERIC_ERROR;
                }
                if (finished.indexOf(response.status) !== -1) {
                    _this.step = LOCAL_STEP.EXPIRED;
                }
                else {
                    switch (response.currentUser.status) {
                        case WORKFLOW_STEP_STATUS.APPROVED:
                            _this.step = LOCAL_STEP.APPROVED;
                            break;
                        case WORKFLOW_STEP_STATUS.PROCESSING:
                            _this.step = LOCAL_STEP.PROCESSING;
                            break;
                        case WORKFLOW_STEP_STATUS.REJECTED:
                            _this.step = LOCAL_STEP.REJECTED;
                            break;
                        case WORKFLOW_STATUS.CANCELLED:
                            _this.step = LOCAL_STEP.CANCELLED;
                            break;
                        default:
                            _this.step = LOCAL_STEP.AWAITING_SIGNATURE;
                            _this.getSignatureList();
                            break;
                    }
                    if (_this.step !== LOCAL_STEP.AWAITING_SIGNATURE) {
                        _this.hasLoaded = true;
                    }
                }
                _this.modalRef.ref.close();
                _this.shouldShowLgpdModal();
            }, function (error) {
                log.debug("Subscribe error:", error);
                _this.modalRef.ref.close();
                _this.hasLoaded = true;
                _this.step = LOCAL_STEP.GENERIC_ERROR;
            });
        }
        catch (e) {
            this.step = LOCAL_STEP.GENERIC_ERROR;
            log.debug("Subscribe js error:", e);
            this.modalRef.ref.close();
        }
    };
    SubscribeComponent.prototype.closeModal = function () {
        if (this.modalRef.ref) {
            this.modalRef.ref.close();
        }
    };
    SubscribeComponent.prototype.getSignatureUserCurrent = function () {
        return "" + this.currentSignature.path + this.currentSignature.service + "?access_token=" + this.currentSignature.token + "&q=preview";
    };
    SubscribeComponent.prototype.getSignatureUserRubric = function () {
        return "" + this.currentRubric.path + this.currentRubric.service + "?access_token=" + this.currentRubric.token + "&q=preview";
    };
    SubscribeComponent.prototype.getSignatureUserStamp = function () {
        return "" + this.currentStamp.path + this.currentStamp.service + "?access_token=" + this.currentStamp.token + "&q=preview";
    };
    SubscribeComponent.prototype.checkRubricData = function () {
        if (!(this.currentRubric === undefined)) {
            return true;
        }
        return false;
    };
    SubscribeComponent.prototype.checkStampData = function () {
        if (!(this.currentStamp === undefined)) {
            return true;
        }
        return false;
    };
    SubscribeComponent.prototype.checkSignatureData = function () {
        if (!(this.currentSignature === undefined)) {
            return true;
        }
        return false;
    };
    /**
     * Get Signature list
     */
    SubscribeComponent.prototype.getSignatureList = function (closeModal) {
        var _this = this;
        try {
            this.modalRef.data.text = 'verificando perfis de assinatura...';
            var filter = {
                filter: [
                    {
                        type: 'eq',
                        field: 'isDefault',
                        value: 1
                    }
                ]
            };
            this.signatureService
                .getAll(filter)
                .pipe(finalize(function () {
                _this.hasLoaded = true;
            }))
                .subscribe(function (response) {
                log.debug("getSignatureList:", response);
                var accessToken = _this.authorizationService.getAccessToken();
                if (response.current) {
                    _this.currentSignature = response.current;
                    _this.currentSignature.path =
                        environment.apiURL + environment.apiVersion;
                    _this.currentSignature.service =
                        '/visible-signature/' + response.current.id;
                    _this.currentSignature.token = accessToken;
                }
                if (response.rubric) {
                    _this.currentRubric = response.rubric;
                    _this.currentRubric.path =
                        environment.apiURL + environment.apiVersion;
                    _this.currentRubric.service =
                        '/visible-signature/' + response.rubric.id;
                    _this.currentRubric.token = accessToken;
                }
                if (response.stamp) {
                    _this.currentStamp = response.stamp;
                    _this.currentStamp.path =
                        environment.apiURL + environment.apiVersion;
                    _this.currentStamp.service =
                        '/visible-signature/' + response.stamp.id;
                    _this.currentStamp.token = accessToken;
                }
                if (closeModal) {
                    _this.enableEdit = false;
                    _this.modalRef.ref.close();
                }
            }, function (error) {
                log.debug("getSignatureList error:", error);
            });
        }
        catch (e) {
            log.debug("getSignatureList js error:", e);
        }
    };
    /**
     * Action by clicking in submit signature form
     *
     * @param (any) form with data
     */
    SubscribeComponent.prototype.doSubmitSignatureForm = function (form) {
        var _this = this;
        try {
            this.modalRef.ref = this.open(this.loading);
            this.signatureService.create(form).subscribe(function (response) {
                log.debug("doSubmitSignatureForm success:", response);
                if (response.id) {
                    _this.getSignatureList(true);
                }
                else {
                    _this.step = LOCAL_STEP.GENERIC_ERROR;
                    _this.modalRef.ref.close();
                }
            }, function (error) {
                log.debug("doSubmitSignatureForm error:", error);
                _this.step = LOCAL_STEP.GENERIC_ERROR;
                _this.modalRef.ref.close();
            });
        }
        catch (e) {
            log.debug("doSubmitSignatureForm js error:", e);
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
        }
    };
    /**
     * Action by clicking in submit rejection form
     *
     * @param (any) form with data
     */
    SubscribeComponent.prototype.doSubmitRejectionForm = function (form) {
        var _this = this;
        try {
            var payload = {
                status: 5,
                latitude: this.position.lat,
                longitude: this.position.lon,
                reason: form.reason
            };
            this.modalRef.ref = this.open(this.loading);
            this.workflowService.update(this.workflowId, payload).subscribe(function (response) {
                if (!response.id) {
                    log.debug("doSubmitRejectionForm:", response);
                    _this.step = LOCAL_STEP.GENERIC_ERROR;
                    _this.modalRef.ref.close();
                    return false;
                }
                _this.modalRef.ref.close();
                _this.step = LOCAL_STEP.REJECTED;
                _this.hasLoaded = true;
                _this.doActionReload();
            }, function (error) {
                log.debug("doSubmitRejectionForm:", error);
                _this.modalRef.ref.close();
                _this.step = LOCAL_STEP.GENERIC_ERROR;
            });
        }
        catch (e) {
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
        }
    };
    /**
     * Action by clicking in submit cancel form
     */
    SubscribeComponent.prototype.doSubmitCancelForm = function () {
        var _this = this;
        try {
            var payload = {
                status: 2
            };
            this.modalRef.ref = this.open(this.loading);
            this.documentService.update(this.workflow.workflow.id, payload).subscribe(function (response) {
                if (!response.id) {
                    log.debug("doSubmitCancelForm:", response);
                    _this.step = LOCAL_STEP.GENERIC_ERROR;
                    _this.modalRef.ref.close();
                    return false;
                }
                _this.modalRef.ref.close();
                _this.step = 6;
                _this.doActionReload();
            }, function (error) {
                log.debug("doSubmitCancelForm:", error);
                _this.modalRef.ref.close();
                _this.step = LOCAL_STEP.GENERIC_ERROR;
            });
        }
        catch (e) {
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
        }
    };
    SubscribeComponent.prototype.toCheckPhotoSign = function () {
        if (this.workflow.currentUser.hasPhotoSign) {
            return true;
        }
        return false;
    };
    SubscribeComponent.prototype.doActionEdit = function (status, scrollTo) {
        this.enableEdit = status;
        if (scrollTo) {
            var $el = document.getElementById('sign');
            $el.scrollIntoView({ behavior: 'smooth' });
        }
    };
    SubscribeComponent.prototype.doActionFinish = function (query) {
        var _this = this;
        this.queryFinish = query;
        if (this.toCheckPhotoSign() && !this.queryFinish.hasPhotoSign) {
            this.open(this.modalPhotoSign, 'md');
            return;
        }
        try {
            this.modalRef.ref = this.open(this.loading);
            var element = this.element;
            var payload = {
                status: 4,
                latitude: this.position.lat,
                longitude: this.position.lon,
                reason: '.',
                extraMetadata: {
                    handSigning: ''
                },
                hasPhotoSign: ''
            };
            if (this.queryFinish.hasPhotoSign) {
                payload.hasPhotoSign = this.queryFinish.hasPhotoSign;
            }
            var querystring = '';
            if (query) {
                querystring = '?';
                if (query.document) {
                    querystring += "username=" + query.document + "&";
                }
                if (query.otp) {
                    querystring += "otp=" + query.otp + "&";
                }
                if (query.type) {
                    querystring += "adapter=" + query.type;
                }
                if (query.signature) {
                    querystring = '';
                    if (query.signature === WORKFLOW_SIGNATURE.IN_PERSON) {
                        payload.extraMetadata.handSigning = this.faceToFaceSubscription;
                    }
                }
            }
            if (this.workflow.currentUser.documentsFields) {
                var index = 0;
                var excludedFields = [
                    WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
                    WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE,
                    WORKFLOW_STEP_FIELDS.TYPE_INVISIBLE_SIGNATURE
                ];
                for (var _i = 0, _a = this.workflow.currentUser.documentsFields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    // Skip this fields when creating the query
                    if (excludedFields.indexOf(field.type) !== -1) {
                        continue;
                    }
                    if (field.type === WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT ||
                        field.type === WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT_REQUIRED) {
                        if (!field.tempValue && !field.value) {
                            this.modalRef.ref.close();
                            alert('É necessário que os campos de textos sejam preenchidos');
                            return;
                        }
                    }
                    if (field.type === WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT) {
                        var datePipe = new DatePipe(environment.defaultLanguage);
                        field.tempValue = datePipe.transform(this.workflow.currentUser.date.date, 'dd/MM/yyyy H:mm');
                    }
                    var fieldValue = encodeURIComponent(field.tempValue || field.value);
                    if (fieldValue != null && fieldValue !== '') {
                        querystring += querystring ? '&' : '?';
                        querystring += "field[" + index + "][name]=" + field.name + "&";
                        querystring += "field[" + index + "][value]=" + fieldValue;
                    }
                    index++;
                }
            }
            this.workflowService
                .update("" + this.workflowId + querystring, payload)
                .subscribe(function (response) {
                if (!response.id) {
                    log.debug("doActionFinish:", response);
                    _this.step = LOCAL_STEP.GENERIC_ERROR;
                    return false;
                }
                var that = _this;
                if (_this.authorizationService.isAuthenticated()) {
                    _this.modalRef.data.text = 'Processando assinatura...';
                    var checkSignature_1 = setInterval(function () {
                        var _this = this;
                        that.workflowService.getWorkflow(that.workflowId).subscribe(function (resp) {
                            if (resp.currentUser.status === 4) {
                                clearInterval(checkSignature_1);
                                _this.modalRef.ref.close();
                                _this.step = LOCAL_STEP.APPROVED;
                            }
                        }, function (error) {
                            log.debug("Subscribe error:", error);
                        });
                    }, 5000);
                }
                else {
                    _this.modalRef.ref.close();
                    _this.step = LOCAL_STEP.APPROVED;
                    _this.stepFromUser = true;
                }
                _this.doActionReload();
            }, function (error) {
                log.debug("doActionFinish:", error);
                switch (error.status) {
                    case 400:
                        if (error.error.detail ===
                            'Signature Engine error, check credentials!') {
                            _this.modalRef.ref.close();
                            _this.step = LOCAL_STEP.BIRDID_CREDENTIAL_ERROR;
                        }
                        else {
                            _this.modalRef.ref.close();
                            _this.step = LOCAL_STEP.GENERIC_ERROR;
                        }
                        break;
                    default:
                        _this.modalRef.ref.close();
                        _this.step = LOCAL_STEP.GENERIC_ERROR;
                        break;
                }
            });
        }
        catch (e) {
            this.modalRef.ref.close();
            this.step = LOCAL_STEP.GENERIC_ERROR;
        }
    };
    SubscribeComponent.prototype.doActionReload = function () {
        window.location.reload();
    };
    SubscribeComponent.prototype.doAction = function (action) {
        var user = this.workflow.currentUser;
        if (!this.getLoggedUserAlreadySigned()) {
            switch (action) {
                case 'reject':
                    return ((user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
                        user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
                        user.user.id !== this.workflow.owner.id);
                case 'cancel':
                    return ((user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
                        user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
                        user.user.id === this.workflow.owner.id);
                case 'sign':
                    return (user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
                        user.signatureType === WORKFLOW_SIGNATURE.ELETRONIC);
                case 'sign-digital-edit':
                    return (user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
                        user.signatureType === WORKFLOW_SIGNATURE.DIGITAL &&
                        this.currentSignature &&
                        !this.enableEdit);
                case 'sign-eletronic-edit':
                    return (user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
                        user.signatureType === WORKFLOW_SIGNATURE.ELETRONIC &&
                        !this.shoulCreateSignature() &&
                        !this.enableEdit);
                case 'create-signature-to-sign':
                    return ((user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
                        user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
                        this.shoulCreateSignature());
                case 'sign-face-to-face':
                    return (user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
                        user.signatureType === WORKFLOW_SIGNATURE.IN_PERSON);
                case 'rubric':
                    return ((user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
                        user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
                        this.hasSubscribeRubric());
                default:
                    return false;
            }
        }
        return false;
    };
    SubscribeComponent.prototype.onActionZoom = function (direction) {
        if (direction === 1) {
            if (this.currentZoom < 9) {
                this.currentZoom++;
            }
        }
        if (direction === 0) {
            if (this.currentZoom > 0) {
                this.currentZoom--;
            }
        }
        log.debug("onActionZoom:", this.currentZoom);
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    SubscribeComponent.prototype.open = function (content, size) {
        var _this = this;
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        modalRef.result.then(function (result) {
            if (result && result.action === 'save') {
                if (result.form === 'rejection') {
                    _this.doSubmitRejectionForm(result.formData);
                    return;
                }
                if (result.form === 'certificate-chooser') {
                    _this.doActionFinish(result.formData);
                    return;
                }
                if (result.form === 'signature') {
                    _this.doSubmitSignatureForm(result.formData);
                    return;
                }
                if (result.form === 'faceToFaceSubscription') {
                    _this.faceToFaceSubscription = result.formData;
                    return;
                }
                if (result.form === 'photoSignToSign') {
                    _this.queryFinish.hasPhotoSign = result.formData.photoSign;
                    _this.doActionFinish(_this.queryFinish);
                    return;
                }
                if (result.form === 'acceptPolicy') {
                    _this.setSignAcceptPolicy(result.formData);
                    return;
                }
            }
            if (result === 'save') {
                _this.doSubmitCancelForm();
                return;
            }
            if (result === 'cancel') {
                return;
            }
        });
        return modalRef;
    };
    SubscribeComponent.prototype._getCurrentLocation = function () {
        var _this = this;
        var options = {
            enableHighAccuracy: true,
            timeout: 600000,
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(function (position) {
            _this.position = {
                lat: position.coords.latitude,
                lon: position.coords.longitude
            };
            _this.getWorkflowData();
        }, function (error) {
            log.debug("getCurrentLocation:", error);
            _this.getWorkflowData();
        }, options);
    };
    SubscribeComponent.prototype.ngOnDestroy = function () {
        this.subscriber.next();
        this.subscriber.complete();
    };
    SubscribeComponent.prototype.getFileLink = function (doc) {
        if (!doc) {
            return '';
        }
        if (doc.hasOwnProperty('signedFile')) {
            return doc.signedFile.link;
        }
        return doc.file.link;
    };
    SubscribeComponent.prototype.downloadFiles = function () {
        this.workflow.documents.forEach(function (doc) {
            if (doc.signedFile) {
                var link = doc.signedFile.link;
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: link
                }).dispatchEvent(new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window
                }));
            }
        });
    };
    SubscribeComponent.prototype.setLoadDocument = function ($event) {
        this.loadDocument = true;
    };
    SubscribeComponent.prototype.loadPageSize = function (size) {
        this.pageSize = size;
        this.loadDocument = false;
        var documentToolsEl = this.documentToolsEl.nativeElement;
        this.documentToolsContainerSize = {
            width: documentToolsEl.clientWidth,
            height: documentToolsEl.clientHeight
        };
        var proportion = this.calcProportion(this.pageSize.width, this.documentToolsContainerSize.width);
        this.setContainerProportion(proportion);
    };
    SubscribeComponent.prototype.getSignStyle = function (tool) {
        return {
            width: (tool.width / this.pageSize.width) * 100 + '%',
            height: (tool.height / this.pageSize.height) * 100 + '%',
            top: this.cartesian.fromApiToPercentage(tool, this.pageSize).y * 100 + '%',
            left: this.cartesian.fromApiToPercentage(tool, this.pageSize).x * 100 + '%'
        };
    };
    SubscribeComponent.prototype.isProcessingWorkstep = function () {
        if (this.getActiveWorkstep()) {
            return (this.getActiveWorkstep().status === WORKFLOW_STEP_STATUS.PROCESSING);
        }
        return false;
    };
    SubscribeComponent.prototype.isSigned = function () {
        return this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.APPROVED;
    };
    SubscribeComponent.prototype.isCirculating = function () {
        return this.workflow.workflow.status === WORKFLOW_STATUS.CIRCULATING;
    };
    SubscribeComponent.prototype.workflowStatusToText = function () {
        return WORKFLOW_TEXT_STATUS[this.workflow.workflow.status].text.toLowerCase();
    };
    SubscribeComponent.prototype.getLoggedUserAlreadySigned = function () {
        if (this.getActiveWorkstep()) {
            var actualWorkstep = this.getActiveWorkstep();
            return actualWorkstep.status === WORKFLOW_STEP_STATUS.APPROVED;
        }
        return false;
    };
    SubscribeComponent.prototype.shouldShowActionButtons = function () {
        return (this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.WAITING &&
            this.workflow.workflow.status === WORKFLOW_STATUS.CIRCULATING);
    };
    SubscribeComponent.prototype.getToolPositionTransform = function (field) {
        var position = this.calcPosition(field);
        return "translate3d(" + position.x + "px, " + position.y + "px, 0px)";
    };
    SubscribeComponent.prototype.getToolSize = function (field) {
        return this.calcSize(field);
    };
    SubscribeComponent.prototype.calcPosition = function (field) {
        return {
            x: Math.round(field.x * this.containerProportion),
            y: Math.round(field.y * this.containerProportion)
        };
    };
    SubscribeComponent.prototype.calcSize = function (field) {
        return {
            width: Math.round(field.width * this.containerProportion),
            height: Math.round(field.height * this.containerProportion)
        };
    };
    SubscribeComponent.prototype.setContainerProportion = function (proportion) {
        this.containerProportion = proportion;
    };
    SubscribeComponent.prototype.calcProportion = function (pageSizeWidth, containerWidth) {
        return 1 - (pageSizeWidth - containerWidth) / pageSizeWidth;
    };
    SubscribeComponent.prototype.redirectToLanding = function () {
        window.location.href = '/';
    };
    SubscribeComponent.prototype.fetchGuestUserInfo = function () {
        var _this = this;
        if (this.authorizationService.isGuest()) {
            return this.userService.me().pipe(tap(function () {
                _this.userLogged = _this.authorizationService.getUser();
            }));
        }
        return this.authorizationService.user.pipe(tap(function (user) {
            _this.userLogged = user;
        }));
    };
    SubscribeComponent.prototype.signatureCorrespondingToWorkstep = function (recipient) {
        if (recipient.details.signatureType === WORKFLOW_SIGNATURE.IN_PERSON) {
            if (this.getActiveWorkstep()) {
                var actualWorkstep = this.getActiveWorkstep();
                if (actualWorkstep.extraMetadata) {
                    return (actualWorkstep.extraMetadata.document ===
                        recipient.details.extraMetadata.document);
                }
            }
            return false;
        }
        return true;
    };
    SubscribeComponent.prototype.getActiveWorkstep = function () {
        var _this = this;
        return this.document.steps.workflowSteps.find(function (step) { return step.id === _this.workflowId; });
    };
    SubscribeComponent.prototype.recipientUserIsTheSameInCurrentWorkstep = function (recipient) {
        if (this.getActiveWorkstep()) {
            var actualWorkstep = this.getActiveWorkstep();
            return (recipient.id === actualWorkstep._embedded.user.id &&
                recipient.details.status.id === WORKFLOW_STEP_STATUS.WAITING);
        }
        return false;
    };
    SubscribeComponent.prototype.isEnableToSignEdit = function (recipient, field) {
        return (this.currentSignature &&
            !this.enableEdit &&
            this.recipientUserIsTheSameInCurrentWorkstep(recipient) &&
            field.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
            recipient.status.action !== 'done' &&
            this.isCirculating() &&
            !this.shouldNotUserSign());
    };
    return SubscribeComponent;
}());
export { SubscribeComponent };
