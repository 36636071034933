import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { WORKFLOW_STEP_FIELDS, WorkflowBuildService } from '@app/core/services';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';

@Component({
  selector: 'app-workflow-droppable-field',
  templateUrl: './droppable-field.component.html',
  styleUrls: ['./droppable-field.component.scss']
})
export class WorkflowDroppableFieldComponent implements OnInit, OnDestroy {
  WORKFLOW_STEP_FIELDS = WORKFLOW_STEP_FIELDS;

  @ViewChild('itemDraggable')
  public itemDraggableElement: ElementRef;

  @Input()
  public field: IWorkflowDroppedField;

  @Output()
  public spreadFieldAllPages? = new EventEmitter<any>();

  public width: number = 0;
  public height: number = 0;

  private workflowBuildService: WorkflowBuildService;

  private coordinateSubscription: Subscription;

  private spreadClickTimes: number = 0;

  constructor(workflowBuildService: WorkflowBuildService) {
    this.workflowBuildService = workflowBuildService;
  }

  public ngOnInit(): void {
    this.ajustSize();
    this.ajustPosition();

    const visibleFile = this.workflowBuildService.getVisibleFile();
    const page = visibleFile.getCurrentPage();

    this.coordinateSubscription = page.observeProportion().subscribe(() => {
      this.ajustSize();
      this.ajustPosition();
    });
  }

  public ngOnDestroy(): void {
    if (this.coordinateSubscription) {
      this.coordinateSubscription.unsubscribe();
    }
  }

  public remove(): void {
    const visibleFile = this.workflowBuildService.getVisibleFile();
    const page = visibleFile.getCurrentPage();
    page.removeField(this.field);
  }

  public spread($action?: number): void {
    if ($action && $action === 2) {
      this.spreadClickTimes = $action;
    }

    if (this.spreadClickTimes === 0 || this.spreadClickTimes === 1) {
      this.spreadClickTimes = this.spreadClickTimes + 1;
    }

    setTimeout(() => {
      this.spreadClickTimes = 0;
    }, 800);

    if (this.spreadClickTimes === 2) {
      this.field.setSpreadOnPages(!this.field.getSpreadOnPages());
      this.spreadFieldAllPages.emit(this.field);
    }
  }

  public onDragEnd($event: CdkDragEnd): void {
    const transformStyle = $event.source.element.nativeElement.style.transform;
    const { x, y } = this.calcDragEndPosition(transformStyle);
    this.field.setCoordinates(x, y);
  }

  public onResizeStop($event: IResizeEvent): void {
    this.width = $event.size.width;
    this.height = $event.size.height;
    this.field.setSize(this.width, this.height);
  }

  private ajustSize(): void {
    this.width = this.field.getWidth();
    this.height = this.field.getHeight();
  }

  private ajustPosition(): void {
    const x = this.field.getX();
    const y = this.field.getY();
    const transform = `translate3d(${x}px, ${y}px, 0px)`;
    this.itemDraggableElement.nativeElement.style.transform = transform;
  }

  private calcDragEndPosition(transform: string): { x: number; y: number } {
    /**
     * Change something like this:
     *    translate3d(10px, 5px, 0px) translate3d(-3px, 8px, 0px)
     * To this:
     *    10px,5px,0px;-3px,8px,0px
     *
     */
    const transformed = transform
      .replace(/translate3d\((.*?)\)/gm, '$1;')
      .replace(/ /g, '')
      .replace(/;$/, '');

    // Then break each translate3d to sum each position
    // of x, y and z. Except we ignore the z axis.
    const transforms = transformed.split(';');

    const xs: number[] = [];
    const ys: number[] = [];

    // Even if there is only one translate3d or a dozen,
    // we store all values of their x and y axis.
    for (const transform of transforms) {
      const pixels = transform
        .replace(/\((.*)\)/, '')
        .replace(/px/g, '')
        .split(',');
      xs.push(parseInt(pixels[0]));
      ys.push(parseInt(pixels[1]));
    }

    // Then sum everything up to get the accurate position
    // of the field relative to the PDF document
    const x = xs.reduce((prevX, curX) => prevX + curX);
    const y = ys.reduce((prevY, curY) => prevY + curY);

    return { x, y };
  }
}
