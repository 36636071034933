import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var SignDateField = /** @class */ (function (_super) {
    tslib_1.__extends(SignDateField, _super);
    function SignDateField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT;
        _this.minHeight = _this.height = 28;
        _this.minWidth = _this.width = 158;
        return _this;
    }
    return SignDateField;
}(WorkflowDroppedField));
export { SignDateField };
