<div class="form-modal-signature">
  <form (ngSubmit)="doSubmit()" [formGroup]="rejectForm" novalidate>
    <div class="form-group">
      <textarea
        class="form-control form-control-sm"
        rows="4"
        [ngClass]="{
          'is-invalid': f.reason.errors && !f.reason.pristine
        }"
        placeholder="Por favor, descreva aqui o motivo da sua rejeição..."
        formControlName="reason"
      ></textarea>
      <div *ngIf="f.reason.errors && !f.reason.pristine">
        <span class="error" [hidden]="!f.reason.errors.required">
          O motivo da rejeição é obrigatório
        </span>
      </div>
    </div>

    <div class="buttons">
      <button class="btn btn-outline-success" (click)="modal.close('cancel')">
        Cancelar
      </button>
      <button
        class="btn btn-secondary"
        [disabled]="rejectForm.invalid || isLoading"
      >
        Enviar
      </button>
    </div>
  </form>
</div>
