import { Component, Input, OnInit } from '@angular/core';
import {
  WORKFLOW_SIGNATURE,
  WORKFLOW_STATUS,
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_STEP_STATUS
} from '@app/core/services';

const LOCAL_STEP = {
  INITIAL: 0,
  AWAITING_SIGNATURE: 1,
  GENERIC_ERROR: 2,
  EXPIRED: 3,
  APPROVED: 4,
  REJECTED: 5,
  CANCELLED: 6,
  NO_GEOLOCATION: 7,
  PROCESSING: 8,
  BIRDID_CREDENTIAL_ERROR: 9
};

@Component({
  selector: 'app-feedback-subscribe',
  templateUrl: './feedback-subscribe.component.html',
  styleUrls: ['./feedback-subscribe.component.scss']
})
export class FeedbackSubscribeComponent implements OnInit {
  LOCAL_STEP = LOCAL_STEP;

  @Input() step: number;
  @Input() workflow: number;
  @Input() stepFromUser: any;

  constructor() {}

  ngOnInit() {}

  doActionReload() {
    window.location.reload();
  }
}
