import { IWorkflowDroppedField } from './workflow-dropped-fields/workflow-dropped-field.interface';
import { IDraggableField } from '@app/core/models';
import { Observable, Subject } from 'rxjs';
import { WorkflowContact } from './workflow-contact.class';

export interface IDimensions {
  width: number;
  height: number;
}

export class WorkflowFilePage {
  private page: number;
  private fields: IWorkflowDroppedField[];
  private fieldsObserver: Subject<IWorkflowDroppedField[]>;
  private proportionObserver: Subject<number>;

  /**
   * Percentage value from 0 to 1 of the difference between
   * the original page size and the rendered div size
   */
  private viewportProportion: number = 1;

  constructor(page: number) {
    this.fields = [];
    this.page = page;
    this.fieldsObserver = new Subject<IWorkflowDroppedField[]>();
    this.proportionObserver = new Subject<number>();
  }

  /**
   * Calculate the proportion of the reduction or increase
   * of the rendered PDF in the browser related to the
   * original size of the PDF page. This way, when dropping the
   * fields, it renders the real size when the final PDF is
   * signed.
   *
   * @param dropzoneWidth Width of the dropzone in pixels
   * @param pageWidth Width of the original PDF in pixels
   */
  public calcPageViewportProportion(
    dropzoneWidth: number,
    pageWidth: number
  ): void {
    // Save the percentage amount that the dropped fields should be reduced/increased
    this.viewportProportion = 1 - (pageWidth - dropzoneWidth) / pageWidth;
    for (const field of this.fields) {
      field.setViewportProportion(this.viewportProportion);
    }
    this.pushUpdateOfProportionChange();
  }

  public getPageNumber(): number {
    return this.page;
  }

  public getProportion(): number {
    return this.viewportProportion;
  }

  public addField(field: IWorkflowDroppedField): void {
    field.setPageNumber(this.page);
    this.fields.push(field);
    this.pushUpdateOfFields();
  }

  public getFields(): IWorkflowDroppedField[] {
    return this.fields;
  }

  public getFieldsOfContact(contact: WorkflowContact): IWorkflowDroppedField[] {
    return this.fields.filter(f => f.getContact() === contact);
  }

  public observeFields(): Observable<IWorkflowDroppedField[]> {
    return this.fieldsObserver.asObservable();
  }

  public removeField(field: IWorkflowDroppedField): void {
    this.fields = this.fields.filter(f => f !== field);
    this.pushUpdateOfFields();
  }

  public removeFieldsOfContact(contact: WorkflowContact): void {
    this.fields = this.fields.filter(f => f.getContact() !== contact);
    this.pushUpdateOfFields();
  }

  public observeProportion(): Observable<number> {
    return this.proportionObserver.asObservable();
  }

  private pushUpdateOfFields(): void {
    this.fieldsObserver.next(this.fields);
  }

  private pushUpdateOfProportionChange(): void {
    this.proportionObserver.next(this.viewportProportion);
  }
}
