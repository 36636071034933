import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/models';

@Component({
  selector: 'app-personal-form',
  templateUrl: './personal-form.component.html',
  styleUrls: ['./personal-form.component.scss']
})
export class PersonalFormComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('cellphone') cellphone: ElementRef;

  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * Data of current user
   */
  @Input() selected: User.FormPayload;

  /**
   * Callback with data of submit
   */
  @Output() doActionSubmit? = new EventEmitter();

  /**
   * Data of Form
   */
  userForm: FormGroup;

  /**
   * Show button Organization
   */
  buttonOrganization: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngAfterViewInit() {
    if (this.cellphone) {
      this.cellphone.nativeElement.parentElement.style = 'display: block;';
    }
  }

  ngOnInit() {}

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.doActionSubmit.emit({ values: this.userForm.value });
  }

  getNumber(number: any) {
    this.userForm.value.cellphone = number;
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.userForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      cellphone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11)
        ])
      ],
      document: [''],
      roleDefault: ['']
    });
  }

  /**
   * Fill the form.
   * Need to remove de + from the
   *  to not conflict with ngmask and reactive form validation.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      if (
        this.selected.cellphone !== null &&
        this.selected.cellphone !== undefined &&
        this.selected.cellphone.length > 11
      ) {
        this.selected.cellphone = this.selected.cellphone.substr(3);
      }
      this.userForm.patchValue(this.selected);
    }
  }
}
