<div class="message">
  <p>{{ text }}</p>
</div>

<div class="row mt-3">
  <div class="col-12 p-2">
    <div class="row d-flex justify-content-end ">
      <div class="col-12 d-flex justify-content-end ">
        <button
          type="button"
          class="btn btn-default m-1"
          title="Cancelar"
          (click)="shouldModalClose()"
        >
          Cancelar
        </button>

        <button
          type="button"
          class="btn btn-danger btn-sm m-1"
          title="Excluir"
          (click)="toSubmit()"
        >
          Excluir
        </button>
      </div>
    </div>
  </div>
</div>
