import { Pipe, PipeTransform } from '@angular/core';
import { WORKFLOW_STEP_ACTION } from '@app/core/services/workflow.enum';

/*
 * Returns name and code of a sign action
 * Usage:
 *   value | signAction:"value"
 * Example:
 *   {{ 2 | signAction:"text" }}
 */
@Pipe({
  name: 'signAction'
})
export class SignActionPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    if (args === 'text') {
      return WORKFLOW_STEP_ACTION[value].text;
    } else if (args === 'action') {
      return WORKFLOW_STEP_ACTION[value].action;
    } else {
      return WORKFLOW_STEP_ACTION[value];
    }
  }
}
