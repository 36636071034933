import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var LinkedUserFormComponent = /** @class */ (function () {
    function LinkedUserFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.createForm();
    }
    /**
     * Data selection action by clicking the form submit button
     */
    LinkedUserFormComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'user',
            formData: this.userForm.value
        });
    };
    Object.defineProperty(LinkedUserFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.userForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    LinkedUserFormComponent.prototype.createForm = function () {
        this.userForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        });
    };
    LinkedUserFormComponent.prototype.ngOnInit = function () { };
    return LinkedUserFormComponent;
}());
export { LinkedUserFormComponent };
