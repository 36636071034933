import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableRubricFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableRubricFieldComponent, _super);
    function WorkflowDroppableRubricFieldComponent(modalService) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.spreadFieldAllPages = new EventEmitter();
        _this.click = 2;
        return _this;
    }
    WorkflowDroppableRubricFieldComponent.prototype.fieldSpread = function () {
        this.spreadFieldAllPages.emit(this.click);
    };
    WorkflowDroppableRubricFieldComponent.prototype.toAction = function () {
        this.open(this.feedbackRubric, 'md');
    };
    WorkflowDroppableRubricFieldComponent.prototype.open = function (content, size) {
        var _this = this;
        this.modalService
            .open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        })
            .result.then(function (result) {
            if (result.action === 'save') {
                if (result.form === 'feedback-rubric') {
                    _this.fieldSpread();
                    return;
                }
            }
            if (result === 'cancel') {
                return;
            }
        });
    };
    return WorkflowDroppableRubricFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableRubricFieldComponent };
