import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IInPersonSignatureForm } from './in-person-signature-form.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-workflow-in-person-signature-field-form',
  templateUrl: './in-person-signature-form.component.html',
  styleUrls: [
    './in-person-signature-form.component.scss',
    '../../../../droppable-field.component.scss'
  ]
})
export class WorkflowInPersonSignatureFieldFormComponent implements OnInit {
  @Input()
  public metadata: IInPersonSignatureForm;

  @Output()
  public submit: EventEmitter<IInPersonSignatureForm>;

  @Output()
  public cancel: EventEmitter<null>;

  public date = Date.now();

  public metadataForm: FormGroup;

  private formBuilder: FormBuilder;

  constructor(formBuilder: FormBuilder) {
    this.submit = new EventEmitter<IInPersonSignatureForm>();
    this.cancel = new EventEmitter<null>();

    this.formBuilder = formBuilder;
  }

  public ngOnInit() {
    this.createForm();

    if (this.metadata) {
      this.metadataForm.setValue(this.metadata);
    }
  }

  public onSubmit(): boolean {
    this.submit.emit(this.metadataForm.value);
    return false;
  }

  public onCancel(): boolean {
    this.cancel.emit();
    return false;
  }

  private createForm(): void {
    this.metadataForm = this.formBuilder.group({
      name: ['', Validators.required],
      document: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      email: [null, Validators.email],
      photo: [false]
    });
  }
}
