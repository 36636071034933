import { Component, OnInit } from '@angular/core';

import { StatisticService } from '@app/core/services';
import { Layout, User } from '@app/core/models';

@Component({
  selector: 'app-activities-page',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesPageComponent implements OnInit {
  /**
   * Available data of counters
   */
  available: Array<Layout.ManagerWidgetStatistics> = [];

  /**
   * Options for render filter
   */
  filterOptions: Layout.ChartFilterOptions;

  /**
   * Data of an external service for rendering the volume chart
   */
  dataChartVolume: any;

  /**
   * Data of an external service for rendering the activity chart
   */
  dataChartActivities: any;

  constructor(private statisticService: StatisticService) {}

  ngOnInit() {
    this.statisticService.getData().subscribe(statistic => {
      this.available = statistic;
    });

    this.filterOptions = {
      options: [
        {
          id: '',
          name: 'Todo o tempo'
        },
        {
          id: '30',
          name: '30 dias'
        },
        {
          id: '60',
          name: '60 dias'
        },
        {
          id: '90',
          name: '90 dias'
        }
      ]
    };

    this.dataChartVolume = [];
    this.dataChartActivities = [];
  }

  /**
   * provides the action of filtering the volume chart
   *
   * @param (string) period of filter
   * @return void
   */
  filterChartVolume(period: string) {
    // to do: Filter data of service service
    // console.log('>>>', period);

    this.dataChartVolume = ['new data updated'];
    this.dataChartActivities = ['new data updated'];
  }
}
