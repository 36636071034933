<section class="page hero-background">
  <div class="hero"></div>
  <div class="container">
    <div class="change-plan"><a href="/register">Trocar Plano</a></div>
    <div class="row">
      <div class="col">
        <div class="info">
          <h2>Plano Básico</h2>
          <h6>Diminua os custos e a burocracia do seu negócio</h6>
        </div>
      </div>
    </div>

    <div class="row main-content">
      <div class="col-4 choosed-plan">
        <div class="plan-box green-box">
          <p class="plan-name">Básico</p>
          <div class="price">
            <p class="currency">R$</p>
            <p class="plan-price">50</p>
            <p>/mês</p>
          </div>
        </div>
      </div>

      <div class="col-8 payment-option">
        <!-- <app-credit-card></app-credit-card> -->
      </div>
    </div>
  </div>
</section>
