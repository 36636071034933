import { OnChanges, EventEmitter } from '@angular/core';
var ChartComponent = /** @class */ (function () {
    function ChartComponent() {
        /**
         * allows viewing the footer with filters
         */
        this.showFooter = true;
        /**
         * Callback of filter
         */
        this.filter = new EventEmitter();
    }
    /**
     * Provides update of chart data
     */
    ChartComponent.prototype.ngOnChanges = function () {
        // to do: parse data of service
        // console.log('>>> updated', this.data);
    };
    Object.defineProperty(ChartComponent.prototype, "filterSelected", {
        /**
         * Inserts the default filter value
         * @return string
         */
        get: function () {
            return '30';
        },
        /**
         * provides the necessary management in select filter action
         *
         * @param (string) period of filter
         * @return EventEmitter
         */
        set: function (period) {
            this.filter.emit({ value: period });
        },
        enumerable: true,
        configurable: true
    });
    return ChartComponent;
}());
export { ChartComponent };
