import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckerRoutingPath } from './checker-routing.path';
import { CheckerComponent } from './checker.component';
import { LayoutPublic } from '@app/shared/layouts/public/public.service';

const routes: Routes = [
  LayoutPublic.childRoutes([
    {
      path: CheckerRoutingPath.CHECKER,
      component: CheckerComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CheckerRoutingModule {}
