import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from '@kiwigrid/ngx-highcharts';
import { ChartsModule } from 'ng2-charts';
import { HighchartsStatic } from '@kiwigrid/ngx-highcharts/dist/HighchartsService';
import { NgXDonutChartModule } from 'ngx-donutchart';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ImageCropperModule } from 'ngx-image-cropper';

import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';

import { ManagerComponent } from './manager.component';
import { CreditCardComponent } from '../landing/components/credit-card/credit-card.component';
import { PaymentFeedbackComponent } from '../landing/components/payment-feedback/payment-feedback.component';
import { ManagerRoutingModule } from './manager-routing.module';

import {
  StatisticsComponent,
  EventsComponent,
  MobileStoresComponent,
  ChartComponent,
  DonnutChartComponent,
  LineChartComponent,
  ChangePasswordFormComponent,
  CancelAccountFormComponent,
  PersonalFormComponent,
  UserFormComponent,
  CreateDocumentFormComponent,
  ContactFormComponent,
  ProfileDataComponent,
  ListsComponent,
  FolderListComponent,
  NavigationListsComponent,
  DepartmentFormComponent,
  TeamFormComponent,
  MemberFormComponent,
  FolderFormComponent,
  ShareFolderFormComponent,
  DocumentsStatusMenuComponent,
  DetailsComponent,
  IntegrationDocuwareFormComponent,
  MoveFolderComponent,
  RenameFolderFormComponent,
  ChangeIntegrationFolderComponent
} from './components';

import {
  WorkflowPageComponent,
  WorkflowListPageComponent,
  ManagePageComponent,
  ActivitiesPageComponent,
  ContactsPageComponent,
  DashboardPageComponent,
  DepartmentsPageComponent,
  DocumentsPageComponent,
  ProfilePageComponent,
  PlansPageComponent,
  UsersPageComponent,
  BuildPageComponent,
  ReviewPageComponent,
  FinishPageComponent,
  TeamsPageComponent,
  MembersPageComponent,
  FoldersPageComponent,
  CheckSignComponent,
  CheckSignCodeResultComponent,
  DetailPageComponent,
  SignatureInPersonComponent
} from './pages';

import { TemplateListComponent } from './components/lists/template-list/template-list.component';
import { PaymentInfoComponent } from './components/forms/payment-info/payment-info.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { LinkedUserFormComponent } from './components/forms/linked-user-form/linked-user-form.component';
import { DocumentFolderListComponent } from './components/lists/document-folder-list/document-folder-list.component';
import { ContactDeleteFormComponent } from './components/forms/contact-delete-form/contact-delete-form.component';
import { TemplatesPageComponent } from './pages/manage/templates/templates.component';
import { OrganizationFormComponent } from './components/forms/organization-form/organization-form.component';
import { OrganizationLogoComponent } from './components/organization-logo/organization-logo.component';
import { UpdateEasySignComponent } from './components/forms/update-easy-sign-form/update-easy-sign.component';
import { AdditionalUserComponent } from './components/additional-user/additional-user.component';
import { AccessTokenEventComponent } from './components/forms/access-token-event/access-token-event.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomSubjectMailComponent } from './pages/custom-subject-mail/custom-subject-mail.component';
import { CustomSubjectMailFormComponent } from './components/forms/custom-subject-mail-form/custom-subject-mail-form.component';
import { WorkflowReportComponent } from './pages/workflow-report/workflow-report.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ActionFeedbackComponent } from './components/action-feedback/action-feedback.component';

/**
 * Workaround for AOT with highchart
 *
 * @see https://github.com/gevgeny/angular2-highcharts/issues/160
 */
declare let require: any;
export function highchartsFactory() {
  const hc = require('highcharts');
  const dd = require('highcharts/modules/drilldown');
  dd(hc);

  return hc;
}

@NgModule({
  declarations: [
    ManagerComponent,

    // pages
    ActivitiesPageComponent,
    ContactsPageComponent,
    DashboardPageComponent,
    DepartmentsPageComponent,
    DocumentsPageComponent,
    ProfilePageComponent,
    PlansPageComponent,
    UsersPageComponent,
    TeamsPageComponent,
    CreditCardComponent,
    PaymentFeedbackComponent,
    MembersPageComponent,
    FoldersPageComponent,
    CheckSignComponent,
    CheckSignCodeResultComponent,

    ManagePageComponent,
    WorkflowPageComponent,
    WorkflowListPageComponent,
    TemplatesPageComponent,
    BuildPageComponent,
    ReviewPageComponent,
    FinishPageComponent,
    DetailPageComponent,

    // components
    StatisticsComponent,
    EventsComponent,
    ChartComponent,
    LineChartComponent,
    DonnutChartComponent,
    ChangePasswordFormComponent,
    CancelAccountFormComponent,
    ContactFormComponent,
    PersonalFormComponent,
    UserFormComponent,
    CreateDocumentFormComponent,

    MobileStoresComponent,
    ProfileDataComponent,
    ListsComponent,
    NavigationListsComponent,
    FolderListComponent,
    DepartmentFormComponent,
    TeamFormComponent,
    MemberFormComponent,
    FolderFormComponent,
    ShareFolderFormComponent,
    DocumentsStatusMenuComponent,
    DetailsComponent,
    TemplateListComponent,
    PaymentInfoComponent,
    LinkedUserFormComponent,
    DocumentFolderListComponent,
    ContactDeleteFormComponent,
    OrganizationFormComponent,
    OrganizationLogoComponent,
    UpdateEasySignComponent,
    AdditionalUserComponent,
    AccessTokenEventComponent,
    CustomSubjectMailComponent,
    CustomSubjectMailFormComponent,
    IntegrationDocuwareFormComponent,
    WorkflowReportComponent,
    MoveFolderComponent,
    RenameFolderFormComponent,
    SignatureInPersonComponent,
    ChangeIntegrationFolderComponent,
    ActionFeedbackComponent
  ],

  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ManagerRoutingModule,
    ChartModule,
    DragDropModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    ChartsModule,
    NgXDonutChartModule,
    NgScrollbarModule,
    ImageCropperModule,
    Ng2TelInputModule,
    TranslateModule.forChild(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],

  exports: [BuildPageComponent, FinishPageComponent, ReviewPageComponent],

  providers: [
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory
    }
  ]
})
export class ManagerModule {}
