import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { WORKFLOW_STEP_REPORT, WORKFLOW_TEXT_STATUS } from './workflow.enum';
var WorkflowReportService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowReportService, _super);
    function WorkflowReportService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/workflow-report';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    WorkflowReportService.prototype.getFileCsv = function (payload) {
        return this.httpClient.get("/workflow-report?period=" + payload.period + "&status=" + payload.status + "&download=" + payload.download + "&received=" + payload.received, { responseType: 'blob' });
    };
    WorkflowReportService.prototype.getReport = function (payload) {
        var _this = this;
        return this.httpClient
            .get("/workflow-report?period=" + payload.period + "&status=" + payload.status + "&download=" + payload.download + "&received=" + payload.received)
            .pipe(map(function (body) {
            return _this.parseWorkflowReport(body);
        }));
    };
    WorkflowReportService.prototype.parseWorkflowReport = function (data) {
        var _this = this;
        var report = [];
        if (data.total_items > 0) {
            report = data._embedded.workflow_report.map(function (report) {
                var autoRemind = report.autoRemind, id = report.id, message = report.message, name = report.name, priority = report.priority;
                return {
                    autoRemind: autoRemind,
                    id: id,
                    message: message,
                    name: name,
                    priority: priority,
                    status: WORKFLOW_TEXT_STATUS[report.status],
                    workflowSteps: _this.getMappedWorkflowStep(report.workflowSteps).join(' | ')
                };
            });
        }
        return {
            report: report,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items
        };
    };
    WorkflowReportService.prototype.getMappedWorkflowStep = function (workflowStep) {
        var _this = this;
        return workflowStep.map(function (step) {
            return _this.firstName(step.user.name) + " - " + WORKFLOW_STEP_REPORT[step.status].text;
        });
    };
    WorkflowReportService.prototype.firstName = function (data) {
        var name = data.split(' ', 1);
        return name;
    };
    return WorkflowReportService;
}(BaseAPI));
export { WorkflowReportService };
