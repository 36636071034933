<header>
  <div class="navbar navbar-dark">
    <div class="container">
      <h1>
        <ng-container *ngIf="logo; else defaultlogo">
          <a (click)="goToManager()" class="logo-img">
            <img [src]="logo" class="img-fluid" />
          </a>
        </ng-container>

        <ng-template #defaultlogo>
          <a
            (click)="goToManager()"
            class="brand large inverse"
            style="cursor: pointer;"
            >Assine Online</a
          >
        </ng-template>
      </h1>
      <div class="actions" *ngIf="!isAuthenticated()">
        <a href="/auth/login" class="btn btn-sm btn-default">Fazer login</a>
        <a href="/register" class="btn btn-criar-conta btn-sm btn-secondary"
          >Criar Conta</a
        >
      </div>
    </div>
  </div>
</header>
