import { Routes, Route } from '@angular/router';

import { LayoutDefaultComponent } from './default.component';

/**
 * Provides helper methods to create routes.
 */
export class LayoutDefault {
  /**
   * Creates routes using the layout default component
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: LayoutDefaultComponent,
      children: routes,
      // Reuse LayoutDefaultComponent instance when navigating between child views
      data: { reuse: true }
    };
  }
}
