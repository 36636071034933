import { Component, OnInit, Input } from '@angular/core';
import { Format } from '@app/core/helpers';
import { Document } from '@app/core/models';
import {
  WORKFLOW_SIGNATURE,
  WORKFLOW_SIGNATURE_TYPE
} from '@app/core/services';

@Component({
  selector: 'app-subscribe-list',
  templateUrl: './subscribe-list.component.html',
  styleUrls: ['./subscribe-list.component.scss']
})
export class SubscribeListComponent implements OnInit {
  WORKFLOW_SIGNATURE = WORKFLOW_SIGNATURE;
  WORKFLOW_SIGNATURE_TYPE = WORKFLOW_SIGNATURE_TYPE;

  /**
   * provides the items list
   */
  @Input() sign: Document.Recipient[];
  @Input() signed: Document.Recipient[];
  @Input() rejected: Document.Recipient[];
  @Input() recipients: Document.Recipient[];
  @Input() PolicySign: boolean;

  public processing: any;

  constructor() {
    this.sign = [];
    this.signed = [];
    this.rejected = [];
    this.recipients = [];
    this.PolicySign = false;
  }

  ngOnInit() {
    this.processing = this.recipients.filter(
      (item: any) => item.status.action === 'processing'
    );
  }

  public checkSignatureName(item: any): string {
    return WORKFLOW_SIGNATURE_TYPE[item.details.signatureType].text;
  }

  public checkSubscriptionType(item: any): boolean {
    return item.details.signatureType === WORKFLOW_SIGNATURE.IN_PERSON;
  }

  public formatDocument(item: any): string {
    const document = Format.cpf(item.details.extraMetadata.document);
    return document;
  }

  public getSignAcceptPolicy(): boolean {
    return this.PolicySign;
  }
}
