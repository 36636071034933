import { Component, OnInit, Input } from '@angular/core';
import {
  FolderVisibilityService,
  AuthorizationService
} from 'app/core/services';

@Component({
  selector: 'app-share-folder-form',
  templateUrl: './share-folder-form.component.html',
  styleUrls: ['./share-folder-form.component.scss']
})
export class ShareFolderFormComponent implements OnInit {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of current folder
   */
  @Input() selected?: any;

  contacts: any;

  sharedContacts: any;
  user: any;
  businessUnit: any;
  organizationShare: any;
  sharedBusinessUnit: any;
  contactsBlackList: Array<Number>;

  constructor(
    private folderVisibilityService: FolderVisibilityService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getFolderVisibility(this.selected.id);
    this.user = this.authorizationService.getUser();
    this.contactsBlackList = [this.user.id];
    if (this.user.businessUnit) {
      this.businessUnit = this.user.businessUnit;
    }
  }

  getFolderVisibility(id: any) {
    this.folderVisibilityService.getAll(id).subscribe((body: any) => {
      this.contacts = body._embedded.folder_visibility.reduce(
        (ret: any, item: any) => {
          if (item._embedded.user) {
            if (item._embedded.user.id !== this.user.id) {
              ret.push(item._embedded.user);
            }
          }
          return ret;
        },
        []
      );
      this.sharedContacts = body._embedded.folder_visibility.reduce(
        (ret: any, item: any) => {
          if (item._embedded.user) {
            if (item._embedded.user.id !== this.user.id) {
              ret.push({
                userId: item._embedded.user.id,
                shareRuleId: item.id
              });
            }
          }
          return ret;
        },
        []
      );
      this.sharedBusinessUnit = body._embedded.folder_visibility
        .reduce((ret: any, item: any) => {
          if (item.type === 0 && item._embedded.businessUnit) {
            if (item._embedded.businessUnit.id === this.businessUnit.id) {
              ret.push({
                businessUnitId: item._embedded.businessUnit.id,
                shareRuleId: item.id
              });
            }
          }
          return ret;
        }, [])
        .shift();
      if (this.sharedBusinessUnit) {
        this.organizationShare = true;
      }
      this.isLoading = false;
    });
  }

  addContact(value: any) {
    if (value.userId) {
      this.folderVisibilityService
        .shareFolderWithContact(this.selected.id, value.userId)
        .then();
    }
  }
  removeContact(event: any) {
    if (event.value.userId) {
      this.sharedContacts.forEach((item: any) => {
        if (item.userId === event.value.userId) {
          this.folderVisibilityService
            .removeSharingRule(item.shareRuleId)
            .then();
        }
      });
    }
  }
  updateOrganizationShare(event: any) {
    if (this.organizationShare) {
      this.folderVisibilityService
        .shareFolderWithOrganization(this.selected.id)
        .subscribe((result: any) => {
          this.sharedBusinessUnit.shareRuleId = result.id;
        });
    } else {
      this.folderVisibilityService
        .removeSharingRule(this.sharedBusinessUnit.shareRuleId)
        .then();
    }
  }
}
