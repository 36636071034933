/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./additional-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./additional-user.component";
var styles_AdditionalUserComponent = [i0.styles];
var RenderType_AdditionalUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdditionalUserComponent, data: {} });
export { RenderType_AdditionalUserComponent as RenderType_AdditionalUserComponent };
function View_AdditionalUserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "div", [["class", "box mt-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "box-header button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Configura\u00E7\u00F5es da organiza\u00E7\u00E3o"])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 30, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 27, "div", [["class", "row link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "link__item"], ["routerLink", "/manager/users"], ["title", "Convidar usu\u00E1rio para organiza\u00E7\u00E3o"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Usu\u00E1rios da organiza\u00E7\u00E3o"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "link__item"], ["title", "Integra\u00E7\u00E3o API Assine online"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(_co.modalAccessToken, "lg") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Integra\u00E7\u00E3o API"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(23, 0, null, null, 4, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [["class", "link__item"], ["title", " Integra\u00E7\u00E3o DocuWare"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(_co.modalIntegrationDocuware, "lg") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Integra\u00E7\u00E3o DocuWare"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(29, 0, null, null, 5, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(31, 0, null, null, 2, "span", [["class", "link__item"], ["routerLink", "/manager/custom-subject-mail"], ["title", "Personalizar assunto do e-mail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Personalizar assunto do e-mail"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "/manager/users"; _ck(_v, 13, 0, currVal_0); var currVal_1 = "/manager/custom-subject-mail"; _ck(_v, 32, 0, currVal_1); }, null); }
export function View_AdditionalUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "box mt-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "box-header button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Configura\u00E7\u00F5es do usu\u00E1rio"])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 22, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 19, "div", [["class", "row link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "link__item"], ["title", "Criar sua assinatura digital"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(_co.modalVisibleSignature, "lg") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Criar sua assinatura"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "link__item"], ["title", "Altera minha senha"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(_co.modalChangePassword) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Alterar minha Senha"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "link__item"], ["title", "Cancelar minha conta"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(_co.modalCancelAccount) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar minha conta"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdditionalUserComponent_1)), i1.ɵdid(32, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roleDefault; _ck(_v, 32, 0, currVal_0); }, null); }
export function View_AdditionalUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-additional-user", [], null, null, null, View_AdditionalUserComponent_0, RenderType_AdditionalUserComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdditionalUserComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdditionalUserComponentNgFactory = i1.ɵccf("app-additional-user", i4.AdditionalUserComponent, View_AdditionalUserComponent_Host_0, { selected: "selected", planInfo: "planInfo", modalChangePassword: "modalChangePassword", modalCancelAccount: "modalCancelAccount", modalVisibleSignature: "modalVisibleSignature", modalAccessToken: "modalAccessToken", modalIntegrationDocuware: "modalIntegrationDocuware" }, { modalAction: "modalAction" }, []);
export { AdditionalUserComponentNgFactory as AdditionalUserComponentNgFactory };
