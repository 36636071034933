<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="footer-help">
          <div>Precisa de Ajuda?</div>
          <a href="mailto:suporte@assine.online" class="secondary"
            >suporte@assine.online</a
          >
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="footer-copyright" title="Versão %%VERSION%%">
          <h6>Copyright &copy; {{ year }} Assine Online</h6>
        </div>
      </div>
    </div>
  </div>
</footer>
