import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPI } from '../class';
import { Folder } from '../models';

@Injectable()
export class FolderIntegrationService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/folder-integration';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  public createFolderIntegration(
    payload: Folder.FolderIntegrationRequest
  ): Observable<any> {
    const toSend = {
      folder: payload.folder,
      metadata: JSON.stringify(payload.metadata)
    };

    return super.create(toSend).pipe(
      map((body: any) => {
        return this.mapFields(body, true);
      })
    );
  }

  public updateFolderIntergation(
    payload: Folder.FolderIntegrationPayload
  ): Observable<any> {
    const toSend = {
      metadata: JSON.stringify(payload.metadata)
    };

    return super.update(payload.idFolderIntegration, toSend).pipe(
      map((response: any) => {
        return this.mapFields(response, true);
      })
    );
  }

  public deleteIntegrationWithFolder(id: string): Observable<any> {
    return super.delete(id);
  }

  public listFolderIntegration(): Observable<
    Folder.FolderCollectionIntegration
  > {
    return super.getAll().pipe(
      map((response: any) => {
        return this.parseFolders(response);
      })
    );
  }

  private parseFolders(data: any): Folder.FolderCollectionIntegration {
    let folders: Folder.FolderIntegration[] = [];

    if (data.total_items > 0) {
      folders = data._embedded.folder_integration.map((folder: any) => {
        return {
          id: folder.id,
          metadata: folder.metadata,
          folderIntegration: {
            id: folder._embedded.folder.id,
            name: folder._embedded.folder.name
          }
        };
      });
    }

    return {
      folderIntegration: folders,
      total_items: data.total_items
    };
  }
}
