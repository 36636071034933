import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-workflow-integration-error-page',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class IntegrationErrorPageComponent implements OnInit {
  public error: string = '';

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.error = this.route.snapshot.queryParams['error'] || 'What? No...';
  }
}
