import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Layout } from '@app/core/models';

@Component({
  selector: 'app-layout-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class LayoutSimpleComponent implements OnInit {
  /**
   * Setup config for print elements in Layout
   */
  configLayout: Layout.SimpleConfigLayout;

  constructor(private route: ActivatedRoute) {
    this.configLayout = {
      footerLayout: 'simple', // simple OR default
      headerLayout: 'simple', // simple OR default
      removeInfoBar: false
    };
  }

  ngOnInit() {
    if (this.route.snapshot.data.attr) {
      this.configLayout = this.route.snapshot.data.attr;
    }
  }
}
