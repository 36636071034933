import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WorkflowContact } from '@app/core/class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';

export class SignDateField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT;
    this.minHeight = this.height = 28;
    this.minWidth = this.width = 158;
  }
}
