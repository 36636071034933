<div class="toolbar" *ngIf="workflow">
  <div class="container">
    <ng-container *ngIf="shouildRubricMessage()">
      <p>Você tem {{ countTotalFieldRubric() }} rubricas neste documento.</p>
    </ng-container>

    <ng-container *ngIf="!shouildRubricMessage()">
      <p
        translate
        *ngIf="step === LOCAL_STEP.AWAITING_SIGNATURE && isCirculating()"
      >
        Este documento requer a assinatura de
        {{ (workflow?.documents)[0]?.steps?.total }} pessoa(s)
      </p>

      <p
        translate
        *ngIf="
          (step === LOCAL_STEP.APPROVED && !stepFromUser) || !isCirculating()
        "
      >
        Este fluxo encontra-se <strong>{{ workflowStatusToText() }}</strong
        >, não é possível mais assinar.
      </p>
    </ng-container>
  </div>
</div>

<div class="page-document" [ngClass]="{ 'document-loaded': workflow }">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 col-xl-9">
        <div
          class="document-wrapper"
          [ngClass]="{ 'document-error': !workflow }"
        >
          <div class="document-details">
            <div
              *ngIf="
                (step === LOCAL_STEP.AWAITING_SIGNATURE && hasLoaded) ||
                  (step === LOCAL_STEP.APPROVED && !stepFromUser);
                else feedback
              "
            >
              <h2 [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
                ID: {{ workflow.workflow.id }}
              </h2>

              <div *ngIf="workflow.documents.length > 1">
                <form action="" class="form">
                  <div class="form-row">
                    <div class="form-group col-12 col-lg-12">
                      <select
                        class="form-control"
                        [(ngModel)]="document"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="setLoadDocument($event)"
                      >
                        <option
                          *ngFor="let f of workflow.documents"
                          [ngValue]="f"
                        >
                          {{ f.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>

              <ng-container *ngIf="document">
                <div
                  class="description"
                  *ngIf="workflow.documents.length === 1"
                >
                  <p [ngClass]="{ 'blur-text': getSignAcceptPolicy() }">
                    Documento: {{ document?.name }}
                  </p>
                </div>
                <div
                  class="alert alert-warning mb-3"
                  role="alert"
                  *ngIf="
                    doAction('sign-face-to-face') &&
                    isCirculating() &&
                    checkExtraMetadata(workflow.documents) &&
                    isEnabledFaceToFaceSignature()
                  "
                >
                  Assinatura presencial requer uma foto por tanto, deve ser
                  feita pelo aplicativo para smartphones.<br />
                  Verifique na loja de aplicativos para o seu aparelho.
                </div>

                <div
                  class="alert alert-warning mb-3"
                  role="alert"
                  *ngIf="
                    doAction('sign-face-to-face') &&
                    isCirculating() &&
                    !isEnabledFaceToFaceSignature()
                  "
                >
                  Assinatura presencial deve ser feita pelo aplicativo para
                  smartphones.<br />
                  Verifique na loja de aplicativos para o seu aparelho.
                </div>

                <div
                  class="alert alert-warning mb-3"
                  role="alert"
                  *ngIf="shouldNotUserSign()"
                >
                  <span
                    ><strong>Ainda não é a sua vez de assinar.</strong
                    ><br />Aguarde a conclusão das assinaturas dos demais
                    destinatários.</span
                  >
                </div>

                <div
                  *ngIf="
                    step === LOCAL_STEP.APPROVED &&
                    !stepFromUser &&
                    workflow.documents.length > 1
                  "
                >
                  <button
                    type="button"
                    class="btn btn-secondary mb-3"
                    title="Baixar todos os documentos"
                    (click)="downloadFiles()"
                  >
                    Baixar todos os documentos
                  </button>
                </div>

                <div class="buttons" *ngIf="shouldShowActionButtons()">
                  <button
                    type="button"
                    (click)="open(modalRejection)"
                    class="btn btn-outline-success"
                    *ngIf="doAction('reject')"
                  >
                    Rejeitar
                  </button>

                  <button
                    type="button"
                    (click)="open(modalCancel, 'sm')"
                    class="btn btn-danger"
                    *ngIf="doAction('cancel') && !enableEdit"
                  >
                    Cancelar
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    *ngIf="doAction('rubric')"
                    (click)="toRubricPage()"
                    [disabled]="isCurrentpageRubricSigned() || loadDocument"
                  >
                    Rubricar página {{ currentPage }}
                  </button>

                  <button
                    type="button"
                    (click)="createSignatureOrStamp()"
                    class="btn btn-secondary"
                    *ngIf="doAction('create-signature-to-sign')"
                  >
                    {{
                      hasStampSignature() ? 'Criar carimbo' : 'Criar Assinatura'
                    }}
                  </button>

                  <button
                    type="button"
                    (click)="open(modalCertificateChooser)"
                    class="btn btn-secondary"
                    [disabled]="!isAllPageRubricSigned()"
                    *ngIf="doAction('sign-digital-edit')"
                  >
                    Assinar
                  </button>

                  <button
                    type="button"
                    (click)="doActionFinish({ type: 'AATL' })"
                    class="btn btn-secondary"
                    [disabled]="!isAllPageRubricSigned()"
                    *ngIf="doAction('sign-eletronic-edit')"
                  >
                    Assinar
                  </button>

                  <ng-container
                    *ngIf="
                      !checkExtraMetadata(workflow.documents) &&
                      isEnabledFaceToFaceSignature()
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="doActionFinish({ signature: 2 })"
                      [disabled]="!isAllPageRubricSigned()"
                      *ngIf="
                        doAction('sign-face-to-face') && faceToFaceSubscription
                      "
                    >
                      Assinar
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="open(modalSelectInPersonSignature)"
                      *ngIf="
                        doAction('sign-face-to-face') && !faceToFaceSubscription
                      "
                    >
                      Criar assinatura
                    </button>
                  </ng-container>
                </div>

                <article
                  id="document-0"
                  class="document-entry"
                  [ngClass]="{ 'blur-text': getSignAcceptPolicy() }"
                >
                  <div class="document-container">
                    <app-workflow-document
                      [fileUrl]="getFileLink(document)"
                      [showToolbar]="true"
                      [showUpload]="false"
                      [recipients]="recipients"
                      (pageSize)="loadPageSize($event)"
                      (pageChange)="setCurrentPage($event)"
                      navigation="bottom"
                    ></app-workflow-document>
                    <div
                      #documentTools
                      class="document-tools"
                      [ngClass]="{ 'current-signed': !shoulCreateSignature() }"
                      [style.zoom]="1 + currentZoom / 10"
                    >
                      <ng-container
                        *ngFor="let recipient of document.steps.recipients"
                      >
                        <ng-container *ngFor="let field of recipient.fields">
                          <div
                            *ngIf="
                              field.type !==
                                WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
                              field.page === currentPage
                            "
                            class="component"
                            [style.transform]="getToolPositionTransform(field)"
                            [style.width.px]="getToolSize(field).width"
                            [style.height.px]="getToolSize(field).height"
                          >
                            <p
                              *ngIf="
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT;
                                else
                              "
                            >
                              {{
                                workflow.currentUser.date.date
                                  | date: 'dd/MM/yyyy H:mm'
                              }}
                            </p>

                            <div
                              *ngIf="
                                field.type ===
                                WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT
                              "
                            >
                              <p
                                *ngIf="
                                  userLogged.id === recipient.id;
                                  else elseValue
                                "
                              >
                                <input
                                  class="input-data"
                                  [id]="field.name"
                                  type="text"
                                  [value]="field.value"
                                  [(ngModel)]="field.tempValue || field.value"
                                  title="{{
                                    field.properties !== null
                                      ? field.properties.description
                                      : 'Texto livre'
                                  }}"
                                  placeholder="{{
                                    field.properties !== null
                                      ? field.properties.title
                                      : 'Digite aqui'
                                  }}"
                                />
                              </p>

                              <ng-template #elseValue>
                                <p class="text-data">
                                  {{
                                    field.value
                                      ? field.value
                                      : 'A preencher por outros'
                                  }}
                                </p>
                              </ng-template>
                            </div>
                          </div>

                          <div
                            *ngIf="
                              field.type === WORKFLOW_STEP_FIELDS.TYPE_STAMP &&
                              field.page === currentPage
                            "
                            class="rubric"
                            [style.transform]="getToolPositionTransform(field)"
                            [style.width.px]="getToolSize(field).width"
                            [style.height.px]="getToolSize(field).height"
                          >
                            <ng-container
                              *ngIf="
                                userLogged.id === recipient.id &&
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_STAMP &&
                                recipient.status.action !== 'done' &&
                                isCirculating() &&
                                checkStampData()
                              "
                            >
                              <button
                                title="Clique para editar a Carimbo"
                                (click)="
                                  chooseSubscription(SIGNATURE_TYPE.STAMP)
                                "
                                class="signature-actions-list__item"
                              >
                                <i class="fa fa-pen"></i>
                              </button>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_STAMP &&
                                userLogged.id === recipient.id &&
                                checkStampData()
                              "
                            >
                              <div
                                class="box-rubric"
                                title="Carimbo do assinante {{
                                  recipient?.name
                                }}"
                              >
                                <img src="{{ getSignatureUserStamp() }}" />
                              </div>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_STAMP &&
                                userLogged.id !== recipient.id
                              "
                            >
                              <div class="text-left">
                                <small class="font-weight-light">Carimbo</small>
                              </div>

                              <div
                                [ngStyle]="{
                                  'top.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).y * 100,
                                  'left.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).x * 100
                                }"
                              >
                                <div class="sign-text">
                                  <div class="info">
                                    {{ recipient?.name }} <br />
                                    {{ recipient?.email }}
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                userLogged.id === recipient.id &&
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_STAMP &&
                                !checkStampData()
                              "
                            >
                              <h2 class="text-left">Carimbo</h2>
                              <div
                                title="Clique aqui para criar sua carimbo"
                                class="rubric-area"
                                (click)="
                                  chooseSubscription(SIGNATURE_TYPE.STAMP)
                                "
                              >
                                <div class="rubric-preview">
                                  <i class="fa fa-pen-square"></i>
                                </div>
                                <p class="ml-2">
                                  {{ recipient?.name }} <br />
                                  {{ recipient?.email }}
                                </p>
                              </div>
                            </ng-container>
                          </div>

                          <div
                            class="sign"
                            [ngClass]="{
                              'sign-area': !recipientUserIsTheSameInCurrentWorkstep(
                                recipient
                              )
                            }"
                            id="sign"
                            *ngIf="
                              field.page === currentPage &&
                              field.type ===
                                WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
                              signatureCorrespondingToWorkstep(recipient)
                            "
                            [style.transform]="getToolPositionTransform(field)"
                            [style.width.px]="getToolSize(field).width"
                            [style.height.px]="getToolSize(field).height"
                          >
                            <div
                              *ngIf="
                                recipient.details.signatureType ===
                                  WORKFLOW_SIGNATURE.IN_PERSON &&
                                userLogged.id === recipient.id
                              "
                              style="height: 100%;"
                            >
                              <button
                                *ngIf="
                                  !checkExtraMetadata(workflow.documents) &&
                                  isCirculating() &&
                                  isEnabledFaceToFaceSignature()
                                "
                                (click)="open(modalSelectInPersonSignature)"
                                title="Clique para editar a assinatura"
                                class="signature-actions-list__item"
                              >
                                <i class="fa fa-pen"></i>
                              </button>
                              <div class="preview">
                                <div class="local-sign-text">
                                  <div *ngIf="recipient.details.extraMetadata">
                                    <ng-container
                                      *ngIf="
                                        !faceToFaceSubscription &&
                                        !recipient.details.extraMetadata
                                          .handSigning
                                      "
                                    >
                                      Assinante Presencial<br />
                                      <em
                                        style="font-style: unset;"
                                        [title]="
                                          recipient.details.extraMetadata
                                            .document
                                        "
                                      >
                                        {{
                                          recipient.details.extraMetadata.name
                                        }}
                                      </em>
                                    </ng-container>

                                    <ng-container
                                      *ngIf="faceToFaceSubscription"
                                    >
                                      <img [src]="faceToFaceSubscription" />
                                      <div class="sign-face-to-face">
                                        <p>
                                          {{
                                            recipient.details.extraMetadata
                                              .name
                                          }}<br />
                                          {{
                                            recipient.details.extraMetadata
                                              .document
                                          }}
                                        </p>
                                      </div>
                                    </ng-container>

                                    <ng-container
                                      *ngIf="
                                        recipient.details.extraMetadata
                                          .handSigning
                                      "
                                    >
                                      <img
                                        [src]="
                                          recipient.details.extraMetadata
                                            .handSigning
                                        "
                                      />
                                      <div class="sign-face-to-face">
                                        <p>
                                          {{
                                            recipient.details.extraMetadata
                                              .name
                                          }}<br />{{
                                            recipient.details.extraMetadata
                                              .document
                                          }}
                                        </p>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                                <span>Powered by Assine Online</span>
                              </div>
                            </div>

                            <div
                              *ngIf="
                                recipient.details.signatureType ===
                                  WORKFLOW_SIGNATURE.ELETRONIC ||
                                recipient.details.signatureType ===
                                  WORKFLOW_SIGNATURE.DIGITAL
                              "
                              style="height: 100%;"
                            >
                              <div class="signature-actions-list">
                                <button
                                  title="Clique para editar a assinatura"
                                  *ngIf="isEnableToSignEdit(recipient, field)"
                                  (click)="
                                    chooseSubscription(SIGNATURE_TYPE.GENERIC)
                                  "
                                  class="signature-actions-list__item"
                                >
                                  <i class="fa fa-pen"></i>
                                </button>
                              </div>

                              <div
                                class="preview"
                                *ngIf="
                                  currentSignature &&
                                  !enableEdit &&
                                  field.type ===
                                    WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
                                  userLogged.id === recipient.id
                                "
                              >
                                <img
                                  src="{{ getSignatureUserCurrent() }}"
                                  *ngIf="currentSignature"
                                />
                                <span>Powered by Assine Online</span>
                              </div>

                              <div
                                *ngIf="
                                  field.type ===
                                    WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
                                  userLogged.id !== recipient.id
                                "
                                [ngStyle]="{
                                  'top.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).y * 100,
                                  'left.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).x * 100
                                }"
                              >
                                <div class="sign-text">
                                  <div>
                                    <div class="info">
                                      {{ recipient?.name }}
                                    </div>
                                    <div class="info">
                                      {{ recipient?.email }}
                                    </div>
                                    <div *ngIf="recipient?.details.dateStatus">
                                      <div class="info">
                                        {{
                                          recipient?.details.dateStatus
                                            ?.formated_date
                                        }}
                                      </div>
                                      <div class="info">
                                        {{
                                          recipient?.details.dateStatus
                                            ?.formated_hour
                                        }}
                                        {{
                                          recipient?.details.dateStatus?._date
                                            ?.timezone
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="edit"
                              *ngIf="
                                (enableEdit || !currentSignature) &&
                                userLogged.id === recipient.id &&
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE
                              "
                            >
                              <h2 class="text-left">Assinatura</h2>
                              <div
                                title="Clique aqui para criar sua assinatura"
                                (click)="
                                  chooseSubscription(SIGNATURE_TYPE.GENERIC)
                                "
                              >
                                <div class="sign-preview">
                                  <i class="fa fa-pen-square"></i>
                                </div>
                                <div class="sign-text">
                                  <p>
                                    {{ recipient?.name }}<br />
                                    {{ recipient?.email }}<br />
                                    {{
                                      recipient?.details.dateStatus
                                        ?.formated_date
                                    }}<br />
                                    {{
                                      recipient?.details.dateStatus
                                        ?.formated_hour
                                    }}
                                    {{
                                      recipient?.details.dateStatus?._date
                                        ?.timezone
                                    }}<br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="rubric"
                            *ngIf="
                              field.page === currentPage &&
                              field.type === WORKFLOW_STEP_FIELDS.TYPE_RUBRIC
                            "
                            [style.transform]="getToolPositionTransform(field)"
                            [style.width.px]="getToolSize(field).width"
                            [style.height.px]="getToolSize(field).height"
                          >
                            <ng-container
                              *ngIf="
                                userLogged.id === recipient.id &&
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_RUBRIC &&
                                recipient.status.action !== 'done' &&
                                isCirculating() &&
                                checkRubricData()
                              "
                            >
                              <button
                                title="Clique para editar a rubrica"
                                (click)="
                                  chooseSubscription(SIGNATURE_TYPE.RUBRIC)
                                "
                                class="signature-actions-list__item"
                              >
                                <i class="fa fa-pen"></i>
                              </button>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_RUBRIC &&
                                userLogged.id === recipient.id &&
                                recipient.status.action !== 'done' &&
                                checkRubricData()
                              "
                            >
                              <div
                                class="box-rubric"
                                title="Rubrica do assinante {{
                                  recipient?.name
                                }}"
                              >
                                <img src="{{ getSignatureUserRubric() }}" />
                              </div>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_RUBRIC &&
                                userLogged.id !== recipient.id &&
                                recipient.status.action !== 'done'
                              "
                            >
                              <div class="text-left">
                                <small class="font-weight-light">Rubrica</small>
                              </div>
                              <div
                                class="sign-area"
                                [ngStyle]="{
                                  'top.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).y * 100,
                                  'left.%':
                                    cartesian.fromApiToPercentage(
                                      field,
                                      pageSize
                                    ).x * 100
                                }"
                              >
                                <div class="sign-text">
                                  <div class="info">
                                    {{ recipient?.name }} <br />
                                    {{ recipient?.email }}
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container
                              *ngIf="
                                userLogged.id === recipient.id &&
                                field.type ===
                                  WORKFLOW_STEP_FIELDS.TYPE_RUBRIC &&
                                !checkRubricData()
                              "
                            >
                              <h2 class="text-left">Rubrica</h2>
                              <div
                                title="Clique aqui para criar sua rubrica"
                                class="rubric-area"
                                (click)="
                                  chooseSubscription(SIGNATURE_TYPE.RUBRIC)
                                "
                              >
                                <div class="rubric-preview">
                                  <i class="fa fa-pen-square"></i>
                                </div>
                                <p class="ml-2">
                                  {{ recipient?.name }} <br />
                                  {{ recipient?.email }}
                                </p>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </article>
              </ng-container>
            </div>

            <ng-template #feedback>
              <app-feedback-subscribe
                [step]="step"
                [workflow]="workflow"
                [stepFromUser]="stepFromUser"
              ></app-feedback-subscribe>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 col-xl-3">
        <div class="subscribe-list-container" *ngIf="workflow && hasLoaded">
          <app-subscribe-list
            [sign]="document.steps.sign"
            [signed]="document.steps.signed"
            [rejected]="document.steps.rejected"
            [recipients]="document.steps.recipients"
            [PolicySign]="getSignAcceptPolicy()"
          ></app-subscribe-list>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalVisibleSignature let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'signature green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="typeOfSignature.name"
  >
    <app-visible-signature
      [modal]="modal"
      [data]="workflow?.currentUser?.user"
      [typeOfSignature]="typeOfSignature"
    ></app-visible-signature>
  </app-modal-action>
</ng-template>

<ng-template #modalSelectInPersonSignature let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'signature green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Crie sua assinatura'"
  >
    <app-select-in-person-signature
      [modal]="modal"
    ></app-select-in-person-signature>
  </app-modal-action>
</ng-template>

<ng-template #modalCertificateChooser let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'signature green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'BirdID'"
  >
    <app-certificate-chooser [user]="userLogged" [modal]="modal">
    </app-certificate-chooser>
  </app-modal-action>
</ng-template>

<ng-template #modalRejection let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'signature green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Qual o motivo da rejeição'"
  >
    <app-rejection-form [modal]="modal"></app-rejection-form>
  </app-modal-action>
</ng-template>

<ng-template #modalCancel let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="true"
    [showClose]="false"
    [showBtnCancel]="true"
    [showBtnSave]="true"
    [disableBtnSave]="false"
    [btnClassCancel]="'btn-outline-success'"
    [btnActionCancel]="'Não'"
    [btnActionSave]="'Sim'"
    [title]="'Aviso!'"
  >
    <p>Deseja realmente cancelar este workflow?</p>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalPhotoSign let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Assinatura com foto'"
  >
    <app-photo-sign [modal]="modal"></app-photo-sign>
  </app-modal-action>
</ng-template>

<ng-template #modalAcceptPolicy let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Envelope recebido'"
  >
    <app-accept-policy
      [owner]="workflow.owner"
      [modal]="modal"
    ></app-accept-policy>
  </app-modal-action>
</ng-template>
