/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-feedback.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../core/services/tag-manage.service";
var styles_PaymentFeedbackComponent = [i0.styles];
var RenderType_PaymentFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentFeedbackComponent, data: {} });
export { RenderType_PaymentFeedbackComponent as RenderType_PaymentFeedbackComponent };
export function View_PaymentFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "payment-feedback-template"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/images/feedback-check.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "feedback-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Parab\u00E9ns!"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "feedback-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seu pedido foi conclu\u00EDdo com sucesso"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    A partir de agora voc\u00EA pode enviar documentos com mais agilidade, seguran\u00E7a\n    e economia.\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "p-4 mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.profile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_PaymentFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-feedback", [], null, null, null, View_PaymentFeedbackComponent_0, RenderType_PaymentFeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentFeedbackComponent, [i3.Router, i4.TagManageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentFeedbackComponentNgFactory = i1.ɵccf("app-payment-feedback", i2.PaymentFeedbackComponent, View_PaymentFeedbackComponent_Host_0, { modal: "modal", plan: "plan" }, {}, []);
export { PaymentFeedbackComponentNgFactory as PaymentFeedbackComponentNgFactory };
