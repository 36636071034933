import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthorizationService } from '../services/authorization.service';

/**
 * Prefixes all requests with `environment.apiURL`.
 */
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authorizationService: AuthorizationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authorizationService.getAccessToken();

    request = request.clone({
      headers: request.headers.append('Authorization', `Bearer ${accessToken}`)
    });

    return next.handle(request);
  }
}
