import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenSession } from '../models';

@Injectable()
export class AccessToken extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/access-token';
  }

  /**
   * Create access token
   */
  createAccessToken(payload: any): Observable<TokenSession.Session> {
    return this.httpClient.post(this.endpoint, payload).pipe(
      map((body: any) => {
        const responseDate: TokenSession.Session = {
          id: body.id,
          accessToken: body.accessToken,
          expires: body.expires,
          type: body.type,
          title: body.title,
          status: body.status,
          detail: body.detail
        };

        return responseDate;
      })
    );
  }
}
