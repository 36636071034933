import { Component, OnInit } from '@angular/core';
import { FinishPageComponent } from '@app/manager/pages';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService, WorkflowBuildService } from '@app/core/services';

@Component({
  selector: 'app-workflow-integration-finish-page',
  templateUrl: './finish.component.html',
  styleUrls: ['../../../../manager/pages/workflow/finish/finish.component.scss']
})
export class IntegrationFinishPageComponent extends FinishPageComponent
  implements OnInit {
  public buildURL: string = '/integration/workflow';

  constructor(
    authorizationService: AuthorizationService,
    workflowBuildService: WorkflowBuildService,
    router: Router,
    private route: ActivatedRoute
  ) {
    super(authorizationService, workflowBuildService, router);
  }

  public ngOnInit(): void {
    this.workflowId = this.workflowBuildService.getWorkflowId();

    if (!this.workflowId) {
      this.navigateToWorkflowBuild();
      return;
    }

    const callbackUrl = this.callbackUrl();
    if (callbackUrl) {
      setTimeout(() => {
        this.workflowBuildService.setWorkflowId(null);
        window.location.href = callbackUrl;
      }, 2500);
    }
  }

  public callbackUrl(): string {
    let cbUrl: string = this.route.snapshot.queryParams['callbackUrl'];

    if (!cbUrl) {
      return null;
    }

    const templateId = this.route.snapshot.queryParams['id'];

    const [uri, hash] = cbUrl.split('#');

    let url = uri;

    // Checa se já possui algum query param na url de callback
    if (url.includes('?')) {
      url += '&';
    } else {
      url += '?';
    }

    cbUrl = `${url}id=${templateId}`;

    if (hash) {
      cbUrl += `#${hash}`;
    }

    return cbUrl;
  }
}
