import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/services';

@Component({
  selector: 'app-confirm-change-business-unit',
  templateUrl: './confirm-change-business-unit.component.html',
  styleUrls: ['./confirm-change-business-unit.component.scss']
})
export class ConfirmChangeBusinessUnitComponent implements OnInit {
  /**
   *Define if the new account is generated from an invite Security token
   *
   */
  token: string;

  /**
   * feedback
   */
  activated: number;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.activated = 1;
  }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];
  }

  doSubmit() {
    const payload = { token: this.token };
    this.processToken(payload);
  }

  processToken(payload: any) {
    this.authenticationService.confirmChangeBusinessUnit(payload).subscribe(
      () => {
        this.activated = 2;
      },
      (error: any) => {
        this.activated = 3;
      }
    );
  }
}
