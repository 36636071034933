<div class="field-block field-name">
  <div class="mt-1">
    <span>Carimbo</span>
    <span
      class="copy-icon"
      title="Replicar carimbo em todas as páginas"
      (click)="toAction()"
      ><i class="far fa-copy"></i
    ></span>
  </div>
  <div class="simple-text">{{ contact.email }}</div>
</div>

<ng-template #feedbackStamp let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Replicar carimbo'"
  >
    <app-feedback-stamp [modal]="modal"></app-feedback-stamp>
  </app-modal-action>
</ng-template>
