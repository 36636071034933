import * as tslib_1 from "tslib";
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
var RubricSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(RubricSignatureField, _super);
    function RubricSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_RUBRIC;
        _this.minHeight = _this.height = 40;
        _this.minWidth = _this.width = 140;
        return _this;
    }
    return RubricSignatureField;
}(WorkflowDroppedField));
export { RubricSignatureField };
