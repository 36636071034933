import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutDefault } from '@app/shared/layouts';

import { AuthRoutingPath } from './auth-routing.path';
import { AuthComponent } from './auth.component';

import { LoginComponent } from './pages/login/login.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ConfirmAccountComponent } from './pages/confirm-account/confirm-account.component';
import { ActivateUserComponent } from './pages/activate-user/activate-user.component';
import { ConfirmChangeBusinessUnitComponent } from './pages/confirm-change-business-unit/confirm-change-business-unit.component';

const routes: Routes = [
  LayoutDefault.childRoutes([
    {
      path: AuthRoutingPath.AUTH,
      redirectTo: `${AuthRoutingPath.AUTH}/${AuthRoutingPath.AUTH_LOGIN}`,
      pathMatch: 'full'
    },
    {
      path: AuthRoutingPath.AUTH,
      component: AuthComponent,
      children: [
        {
          path: AuthRoutingPath.AUTH_LOGIN,
          component: LoginComponent
        },
        {
          path: AuthRoutingPath.AUTH_FORGET_PASSWORD,
          component: ForgetPasswordComponent
        },
        {
          path: AuthRoutingPath.AUTH_CONFIRM_ACCOUNT,
          component: ConfirmAccountComponent
        },
        {
          path: AuthRoutingPath.AUTH_CONFIRM_CHANGE_BUSINESS_UNIT,
          component: ConfirmChangeBusinessUnitComponent
        },
        {
          path: AuthRoutingPath.AUTH_ACTIVATE_USER,
          component: ActivateUserComponent
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule {}
