export class ManagerRoutingPath {
  static readonly MANAGER = 'manager';
  static readonly MANAGER_DASHBOARD = 'dashboard';
  static readonly MANAGER_WORKFLOW = 'workflow';
  static readonly MANAGER_WORKFLOW_REVIEW = 'review';
  static readonly MANAGER_WORKFLOW_FINISH = 'finish';
  static readonly MANAGER_DOCUMENT_CREATE = 'create';
  static readonly MANAGER_WORKFLOW_DETAIL = 'manage/workflow/detail/:id';
  static readonly MANAGER_WORKFLOW_STEP_DETAIL =
    'manage/workflow-step/detail/:id';
  static readonly MANAGER_MANAGE = 'manage';
  static readonly MANAGER_MANAGE_WORKFLOW = 'workflow';
  static readonly ACTIVITIES_REPORT = 'activities';
  static readonly PROFILE = 'profile';
  static readonly PLANS = 'plans';
  static readonly CHECK_SIGN = 'check-sign';
  static readonly CODE_RESULT = 'code-result';
  static readonly UPGRADE = 'upgrade';
  static readonly USERS = 'users';
  static readonly CUSTOM_SUBJECT_MAIL = 'custom-subject-mail';
  static readonly TEMPLATES = 'templates';
  static readonly CONTACTS = 'contacts';
  static readonly DOCUMENTS = 'workflow/:type';
  static readonly DEPARTMENTS = 'departments';
  static readonly TEAMS = 'teams';
  static readonly MEMBERS = 'members';
  static readonly FOLDERS = 'folders';
  static readonly WORKFLOW_REPORT = 'workflow-report';
  static readonly SIGNATURE_IN_PERSON = 'signature-in-person';
}
