import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface IFeedback {
  message: string;
  theme: string;
}

@Injectable()
export class FeedbackService {
  private feedbackObserver: Subject<IFeedback>;

  constructor() {
    this.feedbackObserver = new Subject<IFeedback>();
  }

  public observeFeedback(): Observable<IFeedback> {
    return this.feedbackObserver.asObservable();
  }

  public feedback(message: string, theme: string): void {
    this.feedbackObserver.next({ message, theme });
  }

  public error(message: string): void {
    this.feedback(message, 'error');
  }

  public success(message: string): void {
    this.feedback(message, 'success');
  }

  public info(message: string): void {
    this.feedback(message, 'info');
  }

  public clear(): void {
    setTimeout(() => {
      this.feedbackObserver.next({ message: null, theme: null });
    }, 4000);
  }
}
