import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
var TestService = /** @class */ (function (_super) {
    tslib_1.__extends(TestService, _super);
    function TestService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/user';
        _this.apiFieldsMap = {
            id: 'id',
            cep: 'cep',
            street: 'rua',
            number: 'numero',
            neighborhood: 'bairro',
            complement: 'complemento'
        };
        _this.fieldsRequired = ['id', 'cep', 'street', 'number', 'bla'];
        return _this;
    }
    return TestService;
}(BaseAPI));
export { TestService };
