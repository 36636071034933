import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkflowService, DocumentService, WORKFLOW_STEP_STATUS, WORKFLOW_STATUS, AuthorizationService, WorkflowBuildService } from '@app/core/services';
import { Logger, WorkflowContact, WorkflowFile } from '@app/core/class';
import { AlertSubscription } from '@app/core/subscriptions';
import * as moment from 'moment';
import { debounce } from 'lodash';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
var log = new Logger('WorkflowListPage');
var WorkflowListPageComponent = /** @class */ (function () {
    function WorkflowListPageComponent(authorizationService, workflowService, workflowBuildService, documentService, alertSubscription, modalService, route, router) {
        this.authorizationService = authorizationService;
        this.workflowService = workflowService;
        this.workflowBuildService = workflowBuildService;
        this.documentService = documentService;
        this.alertSubscription = alertSubscription;
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        /**
         * selected items of list
         */
        this.selectedItems = [];
        this.loadingDetails = false;
        this.queryStringFilter = {};
        this.searchField = '';
        this.searchStatus = '';
        this.searchPeriod = '';
        this.statusWorkflow = '';
        this.yourStatusStep = '';
        this.isReceived = false;
        this.collectionDocument = { items: [] };
        this.modalRef = { ref: null, data: { text: '' } };
        this.pagination = {
            totalItems: 0,
            pageCount: 0,
            pageSize: 0,
            currentPage: 1
        };
        this.currentRoute = '/manager/manage/workflow';
        this.searchDebounce = debounce(this.search.bind(this), 300);
    }
    WorkflowListPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.workflowService.endpoint = '/workflow-step';
        if (this.route.snapshot.data['isReceived']) {
            this.isReceived = true;
        }
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando dados...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.authorizationService.user.subscribe(function (user) {
            _this.userLogged = user;
        });
        combineLatest(this.route.params, this.route.queryParams)
            .pipe(map(function (params, qparams) { return ({ params: params, qparams: qparams }); }))
            .subscribe(function (p) {
            _this.currentArea = p.params[0].area || '';
            if (p.params.page) {
                _this.currentPage = p.params.page;
            }
            if (p.params.sort) {
                _this.currentSort = p.params.sort;
            }
            if (p.params.sortBy) {
                _this.currentSortBy = p.params.sortBy;
            }
            if (_this.route.snapshot.queryParamMap.has('filter')) {
                _this.searchStatus = parseInt(_this.route.snapshot.queryParamMap.get('filter'));
                if (_this.searchStatus < 0) {
                    _this.searchStatus = '';
                }
                _this.search();
            }
            else {
                _this.getData({
                    area: _this.currentArea,
                    page: p.qparams.page,
                    sortBy: p.qparams.sortBy,
                    sort: p.qparams.sort
                }, false);
            }
        });
        this.sortFields = { id: 'DESC', status: 'DESC' };
    };
    WorkflowListPageComponent.prototype.checkCurrentArea = function () {
        return this.currentArea !== 'me';
    };
    /**
     * provides the data of workflows with params
     *
     * @return void
     */
    WorkflowListPageComponent.prototype.getData = function (params, isFiltered) {
        if (isFiltered === void 0) { isFiltered = true; }
        var payload = {
            page: params.page ? params.page : 1,
            area: params.area ? params.area : 'all',
            sortBy: params.sortBy ? params.sortBy : 'id',
            sort: params.sort ? params.sort : 'desc'
        };
        if (payload.area === 'me') {
            this.getMyWorkflows(payload, isFiltered);
        }
        else {
            this.getWorkflows(payload, isFiltered);
        }
    };
    WorkflowListPageComponent.prototype.search = function (page) {
        if (page === void 0) { page = 1; }
        this.queryStringFilter = {};
        var filter = [];
        var sort = [];
        var params = { page: page, area: 'me' };
        if (this.currentArea !== 'me') {
            delete params.area;
        }
        if (this.searchPeriod) {
            var format = 'YYYYMMDD';
            var from = moment();
            from.subtract(this.searchPeriod, 'days');
            var value = from.format(format) + '000000';
            if (this.currentArea !== 'me') {
                filter.push({ type: 'innerjoin', field: 'workflow', alias: 'wd' });
                filter.push({
                    alias: 'wd',
                    type: 'gte',
                    field: 'dateCreated',
                    value: value,
                    direction: 'desc'
                });
            }
            else {
                filter.push({
                    type: 'gte',
                    field: 'dateCreated',
                    value: value,
                    direction: 'desc'
                });
            }
        }
        if (this.searchField) {
            if (this.currentArea !== 'me') {
                filter.push({
                    type: 'innerjoin',
                    field: 'workflow',
                    alias: 'ws'
                });
                if (!isNaN(parseInt(this.searchField))) {
                    filter.push({
                        type: 'eq',
                        alias: 'ws',
                        field: 'id',
                        value: this.searchField
                    });
                }
                else {
                    filter.push({
                        type: 'like',
                        alias: 'ws',
                        field: 'name',
                        value: "%" + this.searchField + "%"
                    });
                }
            }
            else {
                if (!isNaN(parseInt(this.searchField))) {
                    filter.push({
                        type: 'eq',
                        field: 'id',
                        value: this.searchField
                    });
                }
                else {
                    filter.push({
                        type: 'like',
                        field: 'name',
                        value: "%25" + this.searchField + "%25"
                    });
                }
            }
        }
        if (this.yourStatusStep) {
            filter.push({
                type: 'eq',
                field: 'status',
                value: this.yourStatusStep,
                direction: 'desc'
            });
        }
        if (this.statusWorkflow) {
            filter.push({
                type: 'eq',
                field: 'workflow',
                alias: 'w',
                child: 'status',
                value: this.statusWorkflow,
                direction: 'desc'
            });
        }
        if (this.searchStatus) {
            filter.push({
                type: 'eq',
                field: 'status',
                value: this.searchStatus,
                direction: 'desc'
            });
        }
        if (filter.length > 0) {
            sort.push({ type: 'field', field: 'id', direction: 'desc' });
        }
        this.queryStringFilter = {
            filter: filter,
            'order-by': sort,
            page: 1
        };
        this.getData(params, filter.length > 0);
    };
    /**
     * provides the data of workflows with me
     *
     * @return void
     */
    WorkflowListPageComponent.prototype.getWorkflows = function (params, isFiltered) {
        var _this = this;
        try {
            var queryString = void 0;
            this.configList = [
                {
                    name: '',
                    klass: '',
                    type: 'icon',
                    fieldId: 'workflow.status'
                },
                {
                    name: 'ID',
                    klass: '',
                    type: 'text',
                    fieldId: 'workflow.id'
                },
                {
                    name: 'Criado por',
                    klass: '',
                    type: 'text',
                    fieldId: 'workflow.user.name'
                },
                {
                    name: 'Nome',
                    klass: '',
                    type: 'text',
                    fieldId: 'workflow.name'
                },
                {
                    name: 'Data',
                    klass: '',
                    type: 'text',
                    fieldId: 'workflow.date.dateCreated.formated_date',
                    fieldKey: 'id',
                    isSortable: true
                },
                {
                    name: 'Seu status',
                    klass: '',
                    type: 'status',
                    fieldId: 'step.status',
                    fieldKey: 'status',
                    isSortable: true
                },
                {
                    name: 'Status do fluxo',
                    klass: '',
                    type: 'status',
                    fieldId: 'workflow.status',
                    isSortable: true
                },
                {
                    name: 'Ações',
                    type: 'custom-select',
                    icon: 'fa fa-sliders-h',
                    width: '5%',
                    isRestrict: true,
                    options: {
                        changeLabel: true,
                        items: [
                            {
                                name: 'Assinar',
                                action: 'sign',
                                canField: 'step.status.id',
                                can: [WORKFLOW_STEP_STATUS.WAITING]
                            },
                            {
                                name: 'Detalhes',
                                action: 'view',
                                canField: 'step.status.id',
                                can: [
                                    WORKFLOW_STEP_STATUS.APPROVED,
                                    WORKFLOW_STEP_STATUS.WAITING
                                ]
                            },
                            {
                                name: 'Detalhes',
                                action: 'view',
                                canField: 'workflow.status.id',
                                can: [WORKFLOW_STATUS.CANCELLED]
                            },
                            {
                                name: 'Baixar Documentos',
                                action: 'download',
                                canField: 'workflow.status.id',
                                can: [WORKFLOW_STATUS.COMPLETED]
                            },
                            {
                                name: 'Baixar Página de Assinaturas',
                                action: 'download-info',
                                canField: 'workflow.status.id',
                                can: [WORKFLOW_STATUS.COMPLETED]
                            },
                            {
                                name: 'Iniciar Workflow',
                                action: 'init',
                                canField: 'workflow.status.id',
                                can: [WORKFLOW_STATUS.DRAFT]
                            }
                        ]
                    }
                }
            ];
            this.statusWorkflowConfig = [
                {
                    value: '',
                    label: 'Status do fluxo'
                },
                {
                    value: 6,
                    label: 'Assinado'
                },
                {
                    value: 1,
                    label: 'Aguardando assinatura'
                },
                {
                    value: 2,
                    label: 'Cancelado'
                },
                {
                    value: 3,
                    label: 'Expirado'
                },
                {
                    value: 4,
                    label: 'Rejeitado'
                }
            ];
            this.yourStatusStepConfig = [
                {
                    value: '',
                    label: 'Seu Status'
                },
                {
                    value: 4,
                    label: 'Assinado'
                },
                {
                    value: 1,
                    label: 'Aguardando assinatura'
                },
                {
                    value: 5,
                    label: 'Rejeitado'
                },
                {
                    value: 3,
                    label: 'Processando'
                }
            ];
            if (!isFiltered) {
                queryString = {
                    'order-by': [
                        {
                            type: 'field',
                            field: params.sortBy,
                            direction: params.sort
                        }
                    ],
                    page: params.page
                };
            }
            else {
                queryString = this.queryStringFilter;
                queryString.page = params.page;
            }
            this.workflowService.getAll(queryString).subscribe(function (response) {
                log.debug("Success [getWorkflows]:", response);
                response.items = response.items.map(function (item) {
                    item.step.status.class = item.step.status.text.action;
                    if (item.workflow.status.id === 2) {
                        item.step.status.class = item.step.status.text.action + " cancelled-line-through";
                    }
                    return item;
                });
                _this.collectionDocument = response;
                _this.pagination = {
                    totalItems: response.total_items,
                    pageCount: response.page_count,
                    pageSize: response.page_size,
                    currentPage: response.page
                };
                if (_this.modalRef.ref) {
                    _this.modalRef.ref.close();
                }
            }, function (error) {
                log.debug("Error [getWorkflows]:", error);
                _this.collectionDocument = { items: [] };
                _this.modalRef.ref.close();
            });
        }
        catch (e) {
            log.debug("Error [getWorkflows]:", e);
            this.collectionDocument = { items: [] };
            this.modalRef.ref.close();
        }
    };
    /**
     * provides the data of my workflows
     *
     * @return void
     */
    WorkflowListPageComponent.prototype.getMyWorkflows = function (params, isFiltered) {
        var _this = this;
        try {
            var queryString = void 0;
            this.configList = [
                {
                    name: '',
                    klass: '',
                    type: 'icon',
                    fieldId: 'status'
                },
                {
                    name: 'ID',
                    klass: '',
                    type: 'text',
                    fieldId: 'id'
                },
                {
                    name: 'Nome',
                    klass: '',
                    type: 'text',
                    fieldId: 'name'
                },
                {
                    name: 'Data',
                    klass: '',
                    type: 'text',
                    fieldId: 'date.dateCreated.formated_date',
                    fieldKey: 'id',
                    isSortable: true
                },
                {
                    name: 'Status',
                    klass: '',
                    type: 'status',
                    fieldId: 'status',
                    fieldKey: 'status',
                    isSortable: true
                },
                {
                    name: 'Ações',
                    type: 'custom-select',
                    icon: 'fa fa-sliders-h',
                    width: '5%',
                    isRestrict: true,
                    options: {
                        changeLabel: true,
                        items: [
                            {
                                name: 'Detalhes',
                                action: 'view',
                                canField: 'status.id',
                                can: [
                                    WORKFLOW_STATUS.DRAFT,
                                    WORKFLOW_STATUS.CIRCULATING,
                                    WORKFLOW_STATUS.CANCELLED,
                                    WORKFLOW_STATUS.EXPIRED,
                                    WORKFLOW_STATUS.PENDING,
                                    WORKFLOW_STATUS.REJECTED,
                                    WORKFLOW_STATUS.COMPLETED
                                ]
                            },
                            {
                                name: 'Baixar Documentos',
                                action: 'download',
                                canField: 'status.id',
                                can: [
                                    WORKFLOW_STATUS.DRAFT,
                                    WORKFLOW_STATUS.CIRCULATING,
                                    WORKFLOW_STATUS.CANCELLED,
                                    WORKFLOW_STATUS.EXPIRED,
                                    WORKFLOW_STATUS.PENDING,
                                    WORKFLOW_STATUS.REJECTED,
                                    WORKFLOW_STATUS.COMPLETED
                                ]
                            },
                            {
                                name: 'Baixar Página de Assinaturas',
                                action: 'download-info',
                                canField: 'status.id',
                                can: [
                                    WORKFLOW_STATUS.DRAFT,
                                    WORKFLOW_STATUS.CIRCULATING,
                                    WORKFLOW_STATUS.CANCELLED,
                                    WORKFLOW_STATUS.EXPIRED,
                                    WORKFLOW_STATUS.PENDING,
                                    WORKFLOW_STATUS.REJECTED,
                                    WORKFLOW_STATUS.COMPLETED
                                ]
                            },
                            {
                                name: 'Cancelar',
                                action: 'cancel',
                                canField: 'status.id',
                                can: [
                                    WORKFLOW_STATUS.DRAFT,
                                    WORKFLOW_STATUS.CIRCULATING,
                                    WORKFLOW_STATUS.EXPIRED,
                                    WORKFLOW_STATUS.PENDING,
                                    WORKFLOW_STATUS.REJECTED
                                ]
                            },
                            {
                                name: 'Reenviar notificação',
                                action: 'wakeup',
                                canField: 'status.id',
                                can: [WORKFLOW_STATUS.CIRCULATING]
                            },
                            {
                                name: 'Iniciar Workflow',
                                action: 'init',
                                canField: 'status.id',
                                can: [WORKFLOW_STATUS.DRAFT]
                            },
                            {
                                name: 'Reutilizar',
                                action: 'clone',
                                canField: 'status.id',
                                can: [
                                    WORKFLOW_STATUS.DRAFT,
                                    WORKFLOW_STATUS.CIRCULATING,
                                    WORKFLOW_STATUS.CANCELLED,
                                    WORKFLOW_STATUS.EXPIRED,
                                    WORKFLOW_STATUS.PENDING,
                                    WORKFLOW_STATUS.REJECTED,
                                    WORKFLOW_STATUS.COMPLETED
                                ]
                            }
                        ]
                    }
                }
            ];
            this.statusFilterConfig = [
                {
                    value: '',
                    label: 'Todos'
                },
                {
                    value: 6,
                    label: 'Assinado'
                },
                {
                    value: 1,
                    label: 'Aguardando assinatura'
                },
                {
                    value: 2,
                    label: 'Cancelado'
                },
                {
                    value: 3,
                    label: 'Expirado'
                },
                {
                    value: 4,
                    label: 'Rejeitado'
                }
            ];
            if (!isFiltered) {
                queryString = {
                    'order-by': [
                        {
                            type: 'field',
                            field: params.sortBy,
                            direction: params.sort
                        }
                    ],
                    page: params.page
                };
            }
            else {
                queryString = this.queryStringFilter;
                queryString.page = params.page;
            }
            this.documentService.getAll(queryString).subscribe(function (response) {
                log.debug("Success [getMyWorkflows]:", response);
                _this.collectionDocument = response;
                _this.pagination = {
                    totalItems: response.total_items,
                    pageCount: response.page_count,
                    pageSize: response.page_size,
                    currentPage: response.page
                };
                _this.modalRef.ref.close();
            }, function (error) {
                log.debug("Error [getMyWorkflows]:", error);
                _this.collectionDocument = { items: [] };
                _this.modalRef.ref.close();
            });
        }
        catch (e) {
            log.debug("Error [getMyWorkflows]:", e);
            this.collectionDocument = { items: [] };
            this.modalRef.ref.close();
        }
    };
    /**
     * Action grab all selected items
     *
     * @param (any) items selected
     */
    WorkflowListPageComponent.prototype.getSelectedItems = function (items) {
        this.selectedItems = items;
    };
    /**
     * Action grab clicked item
     *
     * @param (any) clicked item
     */
    WorkflowListPageComponent.prototype.getClickedItem = function (item) {
        var _this = this;
        var id;
        this.workflowService.endpoint = '/workflow';
        id = item.workflow ? item.workflow.id : null;
        if (this.isReceived) {
            this.workflowService.endpoint = '/workflow-step';
            if (item.step && item.step.id) {
                id = item.step.id;
            }
            if (!id) {
                id = this.getFirstWorkflowStepsId(item.step);
            }
        }
        if (!id) {
            return;
        }
        try {
            this.loadingDetails = true;
            this.workflowService
                .getWorkflow(id)
                .pipe(finalize(function () {
                _this.loadingDetails = false;
            }))
                .subscribe(function (response) {
                log.debug("Success [getClickedItem]:", response);
                _this.details = response;
            }, function (error) {
                log.debug("Error [getClickedItem]:", error);
                _this.details = error;
            });
        }
        catch (e) {
            log.debug("Error [getClickedItem]:", e);
            this.details = e;
        }
    };
    /**
     * Get the first workflow step id
     * @param workflowSteps array of workflowSteps
     */
    WorkflowListPageComponent.prototype.getFirstWorkflowStepsId = function (workflowSteps) {
        var id = null;
        if (workflowSteps && workflowSteps.length) {
            id = workflowSteps[0].id;
        }
        return id;
    };
    /**
     * Action grab data
     *
     * @param (any) items selected
     */
    WorkflowListPageComponent.prototype.doAction = function (event) {
        log.debug("doAction:", event);
        if (!event) {
            return;
        }
        if (event.data.workflowSteps) {
            event.data.step = event.data.workflowSteps[0];
        }
        if (event.type === 'sign') {
            var hash = btoa(JSON.stringify({
                u: this.userLogged.username,
                id: event.data.step.id
            }));
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: "/subscribe/" + hash
            }).dispatchEvent(new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            }));
        }
        if (event.type === 'view') {
            var data = this.isReceived ? event.data.step : event.data;
            var routeType = this.isReceived ? 'workflow-step' : 'workflow';
            this.router.navigate(["/manager/manage/" + routeType + "/detail/" + data.id], {
                replaceUrl: true
            });
        }
        if (event.type === 'init') {
            this.onActionInitWorkflow(event.data);
        }
        if (event.type === 'wakeup') {
            this.onActionWakeupWorkflow(event.data);
        }
        if (event.type === 'cancel') {
            this.open(this.modalCancel, 'sm', event);
        }
        if (event.type === 'clone') {
            this.onActionCloneWorkflow(event.data);
        }
        if (event.type === 'download') {
            var data = this.isReceived ? event.data.step : event.data;
            var routeType = this.isReceived ? 'workflow-step' : 'workflow';
            this.getDocumentForDownload(data, routeType);
        }
        if (event.type === 'download-info') {
            var data = this.isReceived ? event.data.step : event.data;
            var routeType = this.isReceived ? 'workflow-step' : 'workflow';
            this.getDocumentForDownload(data, routeType, true);
        }
    };
    /**
     * Get Document For Download
     *
     * @param (any) event with data of workflow
     * @return void
     */
    WorkflowListPageComponent.prototype.getDocumentForDownload = function (event, routeType, isSignatureDocument) {
        var _this = this;
        try {
            setTimeout((function () {
                _this.modalRef.data.text = 'obtendo dados do documento...';
                _this.modalRef.ref = _this.open(_this.loading);
            }).bind(this));
            var ID = '0';
            if (event && event.step && event.step.id) {
                ID = event.step.id;
            }
            if (event && event.id) {
                ID = event.id;
            }
            if (routeType === 'workflow') {
                this.workflowService.endpoint = '/workflow';
            }
            this.workflowService.getWorkflow(ID).subscribe(function (response) {
                log.debug("getDocumentForDownload:", response);
                _this.modalRef.ref.close();
                if (response.documents && response.documents.length > 0) {
                    response.documents.forEach(function (doc) {
                        if (isSignatureDocument) {
                            Object.assign(document.createElement('a'), {
                                target: '_blank',
                                href: doc.signaturePageLink
                            }).dispatchEvent(new MouseEvent("click", {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                            return true;
                        }
                        var originalFile = doc.originalFile;
                        var signedFile = doc.signedFile;
                        var downloadLink = signedFile
                            ? signedFile.link
                            : originalFile.link;
                        Object.assign(document.createElement('a'), {
                            target: '_blank',
                            href: downloadLink
                        }).dispatchEvent(new MouseEvent("click", {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        }));
                    });
                }
                else {
                    _this.alertSubscription.update({
                        type: 'error',
                        message: 'Não foi possível efetuar o donwload do seu documento. Tente novamente!'
                    });
                }
            }, function (error) {
                log.debug("getDocumentForDownload error js:", error);
                _this.modalRef.ref.close();
                _this.alertSubscription.update({
                    type: 'error',
                    message: 'Ocorreu um erro ao baixar o documento!'
                });
            });
        }
        catch (e) {
            log.debug("getDocumentForDownload error:", e);
            this.modalRef.ref.close();
            this.alertSubscription.update({
                type: 'error',
                message: 'Ocorreu um erro ao baixar o documento!'
            });
        }
    };
    /**
     * Init workflow
     *
     * @param (any) event with data of workflow
     * @return void
     */
    WorkflowListPageComponent.prototype.onActionInitWorkflow = function (event) {
        var _this = this;
        try {
            setTimeout((function () {
                _this.modalRef.data.text = 'iniciando o workflow...';
                _this.modalRef.ref = _this.open(_this.loading);
            }).bind(this));
            this.documentService.update(event.id, { status: 1 }).subscribe(function (response) {
                log.debug("onActionInitWorkflow:", response);
                if (response.id) {
                    _this.alertSubscription.update({
                        type: 'success',
                        message: 'Workflow iniciado com sucesso!'
                    });
                    _this.getData({
                        sort: _this.currentSort,
                        sortBy: _this.currentSortBy,
                        page: _this.currentPage,
                        area: 'me'
                    }, false);
                }
                else {
                    _this.alertSubscription.update({
                        type: 'success',
                        message: 'Ocorreu um erro ao iniciar o workflow!'
                    });
                }
            }, function (error) {
                log.debug("onActionInitWorkflow error js:", error);
                _this.alertSubscription.update({
                    type: 'error',
                    message: 'Ocorreu um erro ao iniciar o workflow!'
                });
            });
        }
        catch (e) {
            log.debug("onActionInitWorkflow error:", e);
            this.alertSubscription.update({
                type: 'error',
                message: 'Ocorreu um erro ao iniciar o workflow!'
            });
        }
    };
    WorkflowListPageComponent.prototype.onActionWakeupWorkflow = function (event) {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'Reenviando workflow...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.documentService.getById(event.id + "?q=wakeup", {}).subscribe(function (response) {
            if (response.workflow.id) {
                _this.alertSubscription.update({
                    type: 'success',
                    message: 'Workflow reenviado com sucesso!'
                });
            }
            else {
                _this.alertSubscription.update({
                    type: 'error',
                    message: 'Ocorreu um erro ao reenviar o Workflow!'
                });
            }
            _this.modalRef.ref.close();
        }, function (error) {
            _this.alertSubscription.update({
                type: 'error',
                message: 'Ocorreu um erro ao reenviar o Workflow!'
            });
        });
    };
    WorkflowListPageComponent.prototype.onActionCloneWorkflow = function (event) {
        var _this = this;
        this.documentService.getById(event.id).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var documents, workflow, _i, documents_1, document_1, workflowFile, _a, _b, recipient, contact, _c, _d, field, page, f;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        documents = response.documents, workflow = response.workflow;
                        _i = 0, documents_1 = documents;
                        _e.label = 1;
                    case 1:
                        if (!(_i < documents_1.length)) return [3 /*break*/, 4];
                        document_1 = documents_1[_i];
                        return [4 /*yield*/, WorkflowFile.MakeWorkflowFileFromURL(document_1.file.link)];
                    case 2:
                        workflowFile = _e.sent();
                        workflowFile.setIdFile(document_1.file.id);
                        workflowFile.setName(document_1.name);
                        workflowFile.setFolderId(document_1.folder.id);
                        if (document_1.dueDate) {
                            workflowFile.setDueDate(document_1.dueDate.date);
                        }
                        for (_a = 0, _b = document_1.steps.recipients; _a < _b.length; _a++) {
                            recipient = _b[_a];
                            contact = this.workflowBuildService.findContactByEmail(recipient.email);
                            if (!contact) {
                                contact = new WorkflowContact({
                                    email: recipient.email,
                                    id: recipient.id,
                                    name: recipient.name
                                }, recipient.action);
                                this.workflowBuildService.addContact(contact);
                            }
                            for (_c = 0, _d = recipient.fields; _c < _d.length; _c++) {
                                field = _d[_c];
                                page = workflowFile.getPage(field.page);
                                f = WorkflowDroppedFieldsStrategy.MakeDroppedField(field.type, contact, recipient.signatureType);
                                f.setViewportProportion(1);
                                f.setSize(field.width, field.height);
                                f.setCoordinates(field.x, field.y);
                                page.addField(f);
                            }
                        }
                        this.workflowBuildService.addFile(workflowFile);
                        this.workflowBuildService.setFileAsVisible(workflowFile);
                        _e.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.workflowBuildService.setSettings({
                            autoInitiate: workflow.autoInitiate,
                            autoRemind: workflow.autoRemind,
                            sla: workflow.sla,
                            message: workflow.message,
                            name: workflow.name,
                            priority: workflow.priority,
                            dueDate: workflow.dueDate ? workflow.dueDate.date : null
                        });
                        this.router.navigate(['/manager/workflow']);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * Action sort list
     *
     * @param (string) field sort
     */
    WorkflowListPageComponent.prototype.doSort = function (field) {
        var _this = this;
        this.sortFields[field] = this.reorder(this.sortFields[field]);
        if (this.sortFields[field]) {
            setTimeout((function () {
                _this.modalRef.data.text = 'carregando dados...';
                _this.modalRef.ref = _this.open(_this.loading);
            }).bind(this));
            var payload = {
                sort: this.sortFields[field],
                sortBy: field,
                page: 1
            };
            var currentRoute = this.currentRoute;
            if (this.currentArea) {
                currentRoute += "/" + this.currentArea;
            }
            this.currentSort = payload.sort;
            this.currentSortBy = payload.sortBy;
            this.router.navigate([currentRoute], {
                replaceUrl: true,
                queryParams: payload
            });
            payload['area'] = this.currentArea;
            this.getData(payload, false);
        }
    };
    /**
     * Action for change page
     *
     * @param (number) of page
     */
    WorkflowListPageComponent.prototype.onChangePage = function (pageNumber) {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando dados...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.search(pageNumber);
    };
    /**
     * reorder field
     *
     * @param (string) field sort
     * @return string
     */
    WorkflowListPageComponent.prototype.reorder = function (field) {
        return field === 'ASC' ? 'DESC' : 'ASC';
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    WorkflowListPageComponent.prototype.open = function (content, size, data) {
        var _this = this;
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        modalRef.result.then(function (result) {
            if (result === 'save') {
                _this.cancelWorkflow(data);
                return;
            }
            if (result === 'cancel') {
                return;
            }
        });
        return modalRef;
    };
    WorkflowListPageComponent.prototype.cancelWorkflow = function (data) {
        var _this = this;
        try {
            var payload = {
                status: 2
            };
            this.modalRef.ref = this.open(this.loading);
            this.documentService.update(data.data.id, payload).subscribe(function (response) {
                window.location.reload();
            }, function (error) {
                log.debug("doSubmitCancelForm:", error);
                _this.modalRef.ref.close();
            });
        }
        catch (e) {
            this.modalRef.ref.close();
        }
    };
    return WorkflowListPageComponent;
}());
export { WorkflowListPageComponent };
