import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Logger } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import cartesian from '@app/core/helpers/cartesian';

import {
  WorkflowService,
  DocumentService,
  SignatureService,
  WORKFLOW_CONTACT_ACTION,
  WORKFLOW_SIGNATURE,
  WORKFLOW_STATUS,
  WORKFLOW_STEP_STATUS,
  WORKFLOW_STEP_FIELDS,
  AuthorizationService,
  UserService,
  WORKFLOW_TEXT_STATUS
} from '@app/core/services';
import { DatePipe } from '@angular/common';
import { BusinessMetadataService } from '@app/core/services/business-unit-metadata.service';
import { Authentication, Subscribe, TypeOfSignature } from '@app/core/models';
import { SIGNATURE_TYPE } from '@app/core/services/workflow.enum';
import { WorkflowDocumentComponent } from '@app/shared/components/workflow/workflow-document.component';
import { find } from 'lodash';

const log = new Logger('Subscribe');
const finished: Array<number> = [
  WORKFLOW_STATUS.CANCELLED,
  WORKFLOW_STATUS.EXPIRED,
  WORKFLOW_STATUS.REJECTED,
  WORKFLOW_STATUS.COMPLETED
];
const LOCAL_STEP = {
  INITIAL: 0,
  AWAITING_SIGNATURE: 1,
  GENERIC_ERROR: 2,
  EXPIRED: 3,
  APPROVED: 4,
  REJECTED: 5,
  CANCELLED: 6,
  NO_GEOLOCATION: 7,
  PROCESSING: 8,
  BIRDID_CREDENTIAL_ERROR: 9
};

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscribeComponent implements OnInit, OnDestroy {
  WORKFLOW_STEP_FIELDS = WORKFLOW_STEP_FIELDS;
  WORKFLOW_SIGNATURE = WORKFLOW_SIGNATURE;
  WORKFLOW_STEP_STATUS = WORKFLOW_STEP_STATUS;
  WORKFLOW_STATUS = WORKFLOW_STATUS;
  LOCAL_STEP = LOCAL_STEP;
  SIGNATURE_TYPE = SIGNATURE_TYPE;

  /**
   * template of modal for edit recipient
   */
  @ViewChild('modalLoading') loading: ElementRef;

  @ViewChild('documentTools') documentToolsEl: ElementRef;

  @ViewChild('modalPhotoSign') modalPhotoSign: ElementRef;

  @ViewChild('modalVisibleSignature') modalVisibleSignature: ElementRef;

  @ViewChild('modalAcceptPolicy') modalAcceptPolicy: ElementRef;

  @ViewChild(WorkflowDocumentComponent)
  workflowDocument: WorkflowDocumentComponent;

  /**
   * Check document has loaded
   */
  hasLoaded = false;

  /**
   * Current Location of user
   */
  position: any;

  stepFromUser: any;

  /**
   * Step of assign
   */
  step: number = LOCAL_STEP.INITIAL;

  /**
   * Current Page selected
   */
  currentPage: number = 1;

  /**
   * Number of pages
   */
  totalPages: number = 0;

  /**
   * Data of document
   */
  workflow: any;

  /**
   * Enable edit
   */
  enableEdit: boolean = false;

  /**
   * Enable edit
   */
  workflowId: string;

  /**
   * modal
   */
  modalRef: any;

  /**
   * Preview of signature
   */
  currentSignature: any;

  /**
   * Preview of rubric
   */
  currentRubric: any;

  /**
   * Preview of stamp
   */
  currentStamp: any;

  /**
   * Current zoom
   */
  currentZoom: number = 0;

  /**
   * Data User logged
   */
  userLogged: any;

  /**
   * Subscriber
   */
  subscriber = new Subject();

  isGuest: boolean = false;

  faceToFaceSubscription: string;
  extraMetadata: [];

  cartesian: any;

  document: any;

  pageSize: any = {
    width: 0,
    height: 0
  };

  public documentToolsContainerSize: any = {
    width: 0,
    height: 0
  };

  public containerProportion: number = 1;

  public recipients: object;

  private signerStep: string;

  private queryFinish: any;

  public typeOfSignature: TypeOfSignature.Payload;

  public pageRubricControl: Subscribe.RubricPages;

  public loadDocument: boolean = true;

  private signAcceptPolicy: boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private workflowService: WorkflowService,
    private documentService: DocumentService,
    private signatureService: SignatureService,
    private userService: UserService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private element: ElementRef,
    public businessMetadataService: BusinessMetadataService
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
  }

  async ngOnInit(): Promise<void> {
    this.pageRubricControl = { signedPage: [], documentsTotalPages: [] };

    this.position = {
      lat: '-1.0',
      lon: '-1.0'
    };
    this.cartesian = cartesian;
    try {
      const urlParam = atob(this.route.snapshot.params.token);
      this.workflowId = JSON.parse(urlParam).id;
      this.signerStep = JSON.parse(urlParam).u;

      if (!this.workflowId) {
        this.redirectToLanding();
        return;
      }

      // EasySign
      const signData = JSON.parse(urlParam);

      if (signData.a) {
        this.authorizationService.setTempAccessToken(signData.a);
        this.isGuest = true;
      }

      return new Promise<void>(resolve => {
        setTimeout(() => {
          this.fetchGuestUserInfo().subscribe(() => {
            this.modalRef.data.text = 'Obtendo a sua localização...';
            this.modalRef.ref = this.open(this.loading);

            this._getCurrentLocation();
            resolve();
          });
        });
      });
    } catch (e) {
      log.debug(`init:`, e);
      this.step = LOCAL_STEP.GENERIC_ERROR;
    }
  }

  hasUserLoggedLocalStorage() {
    let logged: Authentication.SignupUserResponse;
    this.authorizationService.user.subscribe((user: any) => {
      logged = user;
    });
    return logged && logged.email === this.userLogged.email;
  }

  shouldShowLgpdModal() {
    if (
      !this.getLoggedUserAlreadySigned() &&
      !this.isProcessingWorkstep() &&
      !this.hasUserLoggedLocalStorage()
    ) {
      this.open(this.modalAcceptPolicy, 'sm');
      return;
    }
    this.setSignAcceptPolicy(true);
  }

  setSignAcceptPolicy(value: boolean) {
    this.signAcceptPolicy = value;
  }

  getSignAcceptPolicy() {
    return !this.signAcceptPolicy;
  }

  isEnabledFaceToFaceSignature() {
    return environment.featureFlags.faceToFaceSignature;
  }

  /**
   * Set current page
   *
   * @param (any) number of page
   * @return void
   */
  setCurrentPage(data: any) {
    this.currentPage = data.page;
    this.totalPages = data.total;

    if (this.hasSubscribeRubric()) {
      this.setDocumentTotalPage();
    }
  }

  getUserRubric() {
    const pagesRubric = {};

    this.workflow.currentUser.documentsFields
      .filter((field: any) => field.type === WORKFLOW_STEP_FIELDS.TYPE_RUBRIC)
      .forEach((field: any) => {
        pagesRubric[`${field.document.id}-${field.page}`] = field;
      });

    return pagesRubric;
  }

  getTotalPages() {
    return this.pageRubricControl.documentsTotalPages.reduce(
      (sum: number, currentValue: number) => sum + currentValue,
      0
    );
  }

  setDocumentTotalPage() {
    const index = this.workflow.documents.findIndex((document: any) => {
      return document.id === this.document.id;
    });
    this.pageRubricControl.documentsTotalPages[index] = this.totalPages;
  }

  getNextRubricPage() {
    let nextPage = this.currentPage;

    const pages = this.getRubricFromDocument(this.document)
      .map((page: string) => parseInt(page.split('-')[1]))
      .sort(function(a, b) {
        return a - b;
      });

    pages.every((page: any) => {
      if (page > this.currentPage) {
        nextPage = page;
        return false;
      }
      return true;
    });
    return nextPage;
  }

  toRubricPage() {
    this.pageRubricControl.signedPage.push({
      pageNumber: this.currentPage,
      documentId: this.document.id
    });
    this.workflowDocument.changePage(this.getNextRubricPage());
  }

  getRubricFromDocument(document: any) {
    return Object.keys(this.getUserRubric())
      .filter((page: any) => page.includes(document.id))
      .sort();
  }

  isCurrentpageRubricSigned() {
    const rubricsInPage = this.getRubricFromDocument(this.document).filter(
      (page: any) => page.includes(`-${this.currentPage}`)
    );

    if (!rubricsInPage.length) {
      return true;
    }

    const signedPage = this.pageRubricControl.signedPage.find(page => {
      return (
        page.documentId === this.document.id &&
        page.pageNumber === this.currentPage
      );
    });
    return signedPage !== undefined;
  }

  shouildRubricMessage() {
    return (
      this.hasSubscribeRubric() &&
      !this.isAllPageRubricSigned() &&
      !this.isSigned()
    );
  }

  isAllPageRubricSigned() {
    if (!this.hasSubscribeRubric()) {
      return true;
    }
    return (
      this.pageRubricControl.signedPage.length === this.hasSubscribeRubric()
    );
  }

  hasSubscribeRubric() {
    const rubrics = this.getUserRubric();
    return Object.keys(rubrics).length;
  }

  countTotalFieldRubric() {
    const rubricFields = this.workflow.currentUser.documentsFields.filter(
      (field: any) => {
        return field.type === WORKFLOW_STEP_FIELDS.TYPE_RUBRIC;
      }
    );
    return rubricFields.length;
  }

  chooseSubscription(type: number) {
    switch (type) {
      case SIGNATURE_TYPE.RUBRIC:
        this.typeOfSignature = {
          name: 'Crie sua rubrica',
          sign: SIGNATURE_TYPE.RUBRIC
        };
        this.open(this.modalVisibleSignature);
        break;

      case SIGNATURE_TYPE.STAMP:
        this.typeOfSignature = {
          name: 'Faça o upload da imagem do seu carimbo',
          sign: SIGNATURE_TYPE.STAMP
        };
        this.open(this.modalVisibleSignature);
        break;

      case SIGNATURE_TYPE.GENERIC:
        this.typeOfSignature = {
          name: 'Crie sua assinatura',
          sign: SIGNATURE_TYPE.GENERIC
        };
        this.open(this.modalVisibleSignature);
        break;

      default:
        console.error(`The informed signature type ${type} is invalid!`);
        break;
    }
  }

  checkExtraMetadata(docs: any): boolean {
    for (const doc of docs) {
      for (const steps of doc.steps.workflowSteps) {
        if (steps.extraMetadata) {
          if (steps.extraMetadata.photo) {
            return true;
          }
          return false;
        }
      }
    }
  }

  checkNotStepSigner(): boolean {
    if (this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.NOT_STARTED) {
      return true;
    }
    return false;
  }

  shouldNotUserSign() {
    return this.isCirculating() && this.checkNotStepSigner();
  }

  hasStampSignature() {
    return (
      this.workflow.currentUser.fields.find(
        (field: any) => field.type === WORKFLOW_STEP_FIELDS.TYPE_STAMP
      ) !== undefined
    );
  }

  hasElectronicSignature() {
    return (
      this.workflow.currentUser.fields.find(
        (field: any) =>
          field.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE
      ) !== undefined
    );
  }

  createSignatureOrStamp() {
    if (this.hasStampSignature()) {
      this.chooseSubscription(SIGNATURE_TYPE.STAMP);
      return;
    }
    this.chooseSubscription(SIGNATURE_TYPE.GENERIC);
  }

  shoulCreateSignature() {
    return (
      (this.hasStampSignature() && !this.checkStampData()) ||
      (this.hasElectronicSignature() && !this.checkSignatureData())
    );
  }

  /**
   * Get Data of workflow
   */
  getWorkflowData() {
    try {
      this.modalRef.data.text = 'baixando dados do documento...';

      this.workflowService.getWorkflow(this.workflowId).subscribe(
        (response: any) => {
          log.debug(`Subscribe response:`, response);
          this.getSignatureList();

          this.workflow = response;

          if (this.workflow.owner._embedded.business_unit._embedded) {
            this.businessMetadataService.inputBrand(
              this.workflow.owner._embedded.business_unit._embedded
                .business_unit_metata[0]
            );
          }

          this.document = this.workflow.documents[0];

          this.recipients = this.document.steps.recipients;

          if (!response.workflow.id) {
            log.debug(`Subscribe error (service):`, response.detail);
            this.hasLoaded = true;
            this.step = LOCAL_STEP.GENERIC_ERROR;
          }

          if (finished.indexOf(response.status) !== -1) {
            this.step = LOCAL_STEP.EXPIRED;
          } else {
            switch (response.currentUser.status) {
              case WORKFLOW_STEP_STATUS.APPROVED:
                this.step = LOCAL_STEP.APPROVED;
                break;

              case WORKFLOW_STEP_STATUS.PROCESSING:
                this.step = LOCAL_STEP.PROCESSING;
                break;

              case WORKFLOW_STEP_STATUS.REJECTED:
                this.step = LOCAL_STEP.REJECTED;
                break;

              case WORKFLOW_STATUS.CANCELLED:
                this.step = LOCAL_STEP.CANCELLED;
                break;

              default:
                this.step = LOCAL_STEP.AWAITING_SIGNATURE;
                this.getSignatureList();
                break;
            }

            if (this.step !== LOCAL_STEP.AWAITING_SIGNATURE) {
              this.hasLoaded = true;
            }
          }

          this.modalRef.ref.close();

          this.shouldShowLgpdModal();
        },
        (error: any) => {
          log.debug(`Subscribe error:`, error);
          this.modalRef.ref.close();
          this.hasLoaded = true;
          this.step = LOCAL_STEP.GENERIC_ERROR;
        }
      );
    } catch (e) {
      this.step = LOCAL_STEP.GENERIC_ERROR;
      log.debug(`Subscribe js error:`, e);
      this.modalRef.ref.close();
    }
  }

  closeModal() {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  getSignatureUserCurrent() {
    return `${this.currentSignature.path}${
      this.currentSignature.service
    }?access_token=${this.currentSignature.token}&q=preview`;
  }

  getSignatureUserRubric() {
    return `${this.currentRubric.path}${
      this.currentRubric.service
    }?access_token=${this.currentRubric.token}&q=preview`;
  }

  getSignatureUserStamp() {
    return `${this.currentStamp.path}${
      this.currentStamp.service
    }?access_token=${this.currentStamp.token}&q=preview`;
  }

  checkRubricData() {
    if (!(this.currentRubric === undefined)) {
      return true;
    }
    return false;
  }

  checkStampData() {
    if (!(this.currentStamp === undefined)) {
      return true;
    }
    return false;
  }

  checkSignatureData() {
    if (!(this.currentSignature === undefined)) {
      return true;
    }
    return false;
  }

  /**
   * Get Signature list
   */
  getSignatureList(closeModal?: boolean) {
    try {
      this.modalRef.data.text = 'verificando perfis de assinatura...';

      const filter = {
        filter: [
          {
            type: 'eq',
            field: 'isDefault',
            value: 1
          }
        ]
      };

      this.signatureService
        .getAll(filter)
        .pipe(
          finalize(() => {
            this.hasLoaded = true;
          })
        )
        .subscribe(
          (response: any) => {
            log.debug(`getSignatureList:`, response);

            const accessToken = this.authorizationService.getAccessToken();

            if (response.current) {
              this.currentSignature = response.current;
              this.currentSignature.path =
                environment.apiURL + environment.apiVersion;
              this.currentSignature.service =
                '/visible-signature/' + response.current.id;
              this.currentSignature.token = accessToken;
            }

            if (response.rubric) {
              this.currentRubric = response.rubric;
              this.currentRubric.path =
                environment.apiURL + environment.apiVersion;
              this.currentRubric.service =
                '/visible-signature/' + response.rubric.id;
              this.currentRubric.token = accessToken;
            }

            if (response.stamp) {
              this.currentStamp = response.stamp;
              this.currentStamp.path =
                environment.apiURL + environment.apiVersion;
              this.currentStamp.service =
                '/visible-signature/' + response.stamp.id;
              this.currentStamp.token = accessToken;
            }

            if (closeModal) {
              this.enableEdit = false;
              this.modalRef.ref.close();
            }
          },
          (error: any) => {
            log.debug(`getSignatureList error:`, error);
          }
        );
    } catch (e) {
      log.debug(`getSignatureList js error:`, e);
    }
  }

  /**
   * Action by clicking in submit signature form
   *
   * @param (any) form with data
   */
  doSubmitSignatureForm(form: any) {
    try {
      this.modalRef.ref = this.open(this.loading);
      this.signatureService.create(form).subscribe(
        (response: any) => {
          log.debug(`doSubmitSignatureForm success:`, response);

          if (response.id) {
            this.getSignatureList(true);
          } else {
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
          }
        },
        error => {
          log.debug(`doSubmitSignatureForm error:`, error);
          this.step = LOCAL_STEP.GENERIC_ERROR;
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      log.debug(`doSubmitSignatureForm js error:`, e);
      this.step = LOCAL_STEP.GENERIC_ERROR;
      this.modalRef.ref.close();
    }
  }

  /**
   * Action by clicking in submit rejection form
   *
   * @param (any) form with data
   */
  doSubmitRejectionForm(form: any) {
    try {
      const payload = {
        status: 5,
        latitude: this.position.lat,
        longitude: this.position.lon,
        reason: form.reason
      };
      this.modalRef.ref = this.open(this.loading);
      this.workflowService.update(this.workflowId, payload).subscribe(
        (response: any) => {
          if (!response.id) {
            log.debug(`doSubmitRejectionForm:`, response);
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
            return false;
          }
          this.modalRef.ref.close();
          this.step = LOCAL_STEP.REJECTED;
          this.hasLoaded = true;
          this.doActionReload();
        },
        (error: any) => {
          log.debug(`doSubmitRejectionForm:`, error);
          this.modalRef.ref.close();
          this.step = LOCAL_STEP.GENERIC_ERROR;
        }
      );
    } catch (e) {
      this.step = LOCAL_STEP.GENERIC_ERROR;
      this.modalRef.ref.close();
    }
  }

  /**
   * Action by clicking in submit cancel form
   */
  doSubmitCancelForm() {
    try {
      const payload = {
        status: 2
      };

      this.modalRef.ref = this.open(this.loading);
      this.documentService.update(this.workflow.workflow.id, payload).subscribe(
        (response: any) => {
          if (!response.id) {
            log.debug(`doSubmitCancelForm:`, response);
            this.step = LOCAL_STEP.GENERIC_ERROR;
            this.modalRef.ref.close();
            return false;
          }
          this.modalRef.ref.close();
          this.step = 6;
          this.doActionReload();
        },
        (error: any) => {
          log.debug(`doSubmitCancelForm:`, error);
          this.modalRef.ref.close();
          this.step = LOCAL_STEP.GENERIC_ERROR;
        }
      );
    } catch (e) {
      this.step = LOCAL_STEP.GENERIC_ERROR;
      this.modalRef.ref.close();
    }
  }

  toCheckPhotoSign() {
    if (this.workflow.currentUser.hasPhotoSign) {
      return true;
    }
    return false;
  }

  doActionEdit(status: boolean, scrollTo: boolean) {
    this.enableEdit = status;
    if (scrollTo) {
      const $el = document.getElementById('sign');
      $el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  doActionFinish(query?: any) {
    this.queryFinish = query;

    if (this.toCheckPhotoSign() && !this.queryFinish.hasPhotoSign) {
      this.open(this.modalPhotoSign, 'md');
      return;
    }

    try {
      this.modalRef.ref = this.open(this.loading);

      const element = this.element;

      const payload = {
        status: 4,
        latitude: this.position.lat,
        longitude: this.position.lon,
        reason: '.',
        extraMetadata: {
          handSigning: ''
        },
        hasPhotoSign: ''
      };

      if (this.queryFinish.hasPhotoSign) {
        payload.hasPhotoSign = this.queryFinish.hasPhotoSign;
      }

      let querystring = '';

      if (query) {
        querystring = '?';
        if (query.document) {
          querystring += `username=${query.document}&`;
        }
        if (query.otp) {
          querystring += `otp=${query.otp}&`;
        }
        if (query.type) {
          querystring += `adapter=${query.type}`;
        }
        if (query.signature) {
          querystring = '';
          if (query.signature === WORKFLOW_SIGNATURE.IN_PERSON) {
            payload.extraMetadata.handSigning = this.faceToFaceSubscription;
          }
        }
      }
      if (this.workflow.currentUser.documentsFields) {
        let index = 0;

        const excludedFields = [
          WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
          WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE,
          WORKFLOW_STEP_FIELDS.TYPE_INVISIBLE_SIGNATURE
        ];

        for (const field of this.workflow.currentUser.documentsFields) {
          // Skip this fields when creating the query
          if (excludedFields.indexOf(field.type) !== -1) {
            continue;
          }

          if (
            field.type === WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT ||
            field.type === WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT_REQUIRED
          ) {
            if (!field.tempValue && !field.value) {
              this.modalRef.ref.close();
              alert('É necessário que os campos de textos sejam preenchidos');
              return;
            }
          }

          if (field.type === WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT) {
            const datePipe = new DatePipe(environment.defaultLanguage);
            field.tempValue = datePipe.transform(
              this.workflow.currentUser.date.date,
              'dd/MM/yyyy H:mm'
            );
          }

          const fieldValue = encodeURIComponent(field.tempValue || field.value);
          if (fieldValue != null && fieldValue !== '') {
            querystring += querystring ? '&' : '?';
            querystring += `field[${index}][name]=${field.name}&`;
            querystring += `field[${index}][value]=${fieldValue}`;
          }

          index++;
        }
      }

      this.workflowService
        .update(`${this.workflowId}${querystring}`, payload)
        .subscribe(
          (response: any) => {
            if (!response.id) {
              log.debug(`doActionFinish:`, response);
              this.step = LOCAL_STEP.GENERIC_ERROR;
              return false;
            }

            const that = this;

            if (this.authorizationService.isAuthenticated()) {
              this.modalRef.data.text = 'Processando assinatura...';
              const checkSignature = setInterval(function() {
                that.workflowService.getWorkflow(that.workflowId).subscribe(
                  (resp: any) => {
                    if (resp.currentUser.status === 4) {
                      clearInterval(checkSignature);
                      this.modalRef.ref.close();
                      this.step = LOCAL_STEP.APPROVED;
                    }
                  },
                  (error: any) => {
                    log.debug(`Subscribe error:`, error);
                  }
                );
              }, 5000);
            } else {
              this.modalRef.ref.close();
              this.step = LOCAL_STEP.APPROVED;
              this.stepFromUser = true;
            }

            this.doActionReload();
          },
          (error: any) => {
            log.debug(`doActionFinish:`, error);

            switch (error.status) {
              case 400:
                if (
                  error.error.detail ===
                  'Signature Engine error, check credentials!'
                ) {
                  this.modalRef.ref.close();
                  this.step = LOCAL_STEP.BIRDID_CREDENTIAL_ERROR;
                } else {
                  this.modalRef.ref.close();
                  this.step = LOCAL_STEP.GENERIC_ERROR;
                }
                break;
              default:
                this.modalRef.ref.close();
                this.step = LOCAL_STEP.GENERIC_ERROR;
                break;
            }
          }
        );
    } catch (e) {
      this.modalRef.ref.close();
      this.step = LOCAL_STEP.GENERIC_ERROR;
    }
  }

  doActionReload() {
    window.location.reload();
  }

  doAction(action: string) {
    const user = this.workflow.currentUser;
    if (!this.getLoggedUserAlreadySigned()) {
      switch (action) {
        case 'reject':
          return (
            (user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
              user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
            user.user.id !== this.workflow.owner.id
          );

        case 'cancel':
          return (
            (user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
              user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
            user.user.id === this.workflow.owner.id
          );

        case 'sign':
          return (
            user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
            user.signatureType === WORKFLOW_SIGNATURE.ELETRONIC
          );

        case 'sign-digital-edit':
          return (
            user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
            user.signatureType === WORKFLOW_SIGNATURE.DIGITAL &&
            this.currentSignature &&
            !this.enableEdit
          );

        case 'sign-eletronic-edit':
          return (
            user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
            user.signatureType === WORKFLOW_SIGNATURE.ELETRONIC &&
            !this.shoulCreateSignature() &&
            !this.enableEdit
          );

        case 'create-signature-to-sign':
          return (
            (user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
              user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
            this.shoulCreateSignature()
          );

        case 'sign-face-to-face':
          return (
            user.action === WORKFLOW_CONTACT_ACTION.SIGN &&
            user.signatureType === WORKFLOW_SIGNATURE.IN_PERSON
          );

        case 'rubric':
          return (
            (user.action === WORKFLOW_CONTACT_ACTION.SIGN ||
              user.action === WORKFLOW_CONTACT_ACTION.APPROVE) &&
            this.hasSubscribeRubric()
          );

        default:
          return false;
      }
    }
    return false;
  }

  onActionZoom(direction: number) {
    if (direction === 1) {
      if (this.currentZoom < 9) {
        this.currentZoom++;
      }
    }

    if (direction === 0) {
      if (this.currentZoom > 0) {
        this.currentZoom--;
      }
    }

    log.debug(`onActionZoom:`, this.currentZoom);
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    modalRef.result.then(result => {
      if (result && result.action === 'save') {
        if (result.form === 'rejection') {
          this.doSubmitRejectionForm(result.formData);
          return;
        }

        if (result.form === 'certificate-chooser') {
          this.doActionFinish(result.formData);
          return;
        }

        if (result.form === 'signature') {
          this.doSubmitSignatureForm(result.formData);
          return;
        }

        if (result.form === 'faceToFaceSubscription') {
          this.faceToFaceSubscription = result.formData;
          return;
        }

        if (result.form === 'photoSignToSign') {
          this.queryFinish.hasPhotoSign = result.formData.photoSign;
          this.doActionFinish(this.queryFinish);
          return;
        }

        if (result.form === 'acceptPolicy') {
          this.setSignAcceptPolicy(result.formData);
          return;
        }
      }

      if (result === 'save') {
        this.doSubmitCancelForm();
        return;
      }

      if (result === 'cancel') {
        return;
      }
    });

    return modalRef;
  }

  private _getCurrentLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 600000,
      maximumAge: 0
    };

    navigator.geolocation.getCurrentPosition(
      position => {
        this.position = {
          lat: position.coords.latitude,
          lon: position.coords.longitude
        };
        this.getWorkflowData();
      },
      error => {
        log.debug(`getCurrentLocation:`, error);
        this.getWorkflowData();
      },
      options
    );
  }

  ngOnDestroy() {
    this.subscriber.next();
    this.subscriber.complete();
  }

  public getFileLink(doc: any): string {
    if (!doc) {
      return '';
    }

    if (doc.hasOwnProperty('signedFile')) {
      return doc.signedFile.link;
    }

    return doc.file.link;
  }

  downloadFiles() {
    this.workflow.documents.forEach((doc: any) => {
      if (doc.signedFile) {
        const link = doc.signedFile.link;
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: link
        }).dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
      }
    });
  }

  setLoadDocument($event: Event) {
    this.loadDocument = true;
  }

  loadPageSize(size: any) {
    this.pageSize = size;
    this.loadDocument = false;

    const documentToolsEl = this.documentToolsEl.nativeElement as Element;

    this.documentToolsContainerSize = {
      width: documentToolsEl.clientWidth,
      height: documentToolsEl.clientHeight
    };

    const proportion = this.calcProportion(
      this.pageSize.width,
      this.documentToolsContainerSize.width
    );
    this.setContainerProportion(proportion);
  }

  getSignStyle(tool: any) {
    return {
      width: (tool.width / this.pageSize.width) * 100 + '%',
      height: (tool.height / this.pageSize.height) * 100 + '%',
      top:
        this.cartesian.fromApiToPercentage(tool, this.pageSize).y * 100 + '%',
      left:
        this.cartesian.fromApiToPercentage(tool, this.pageSize).x * 100 + '%'
    };
  }

  isProcessingWorkstep() {
    if (this.getActiveWorkstep()) {
      return (
        this.getActiveWorkstep().status === WORKFLOW_STEP_STATUS.PROCESSING
      );
    }
    return false;
  }

  isSigned() {
    return this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.APPROVED;
  }

  isCirculating() {
    return this.workflow.workflow.status === WORKFLOW_STATUS.CIRCULATING;
  }

  workflowStatusToText(): string {
    return WORKFLOW_TEXT_STATUS[
      this.workflow.workflow.status
    ].text.toLowerCase();
  }

  getLoggedUserAlreadySigned() {
    if (this.getActiveWorkstep()) {
      const actualWorkstep = this.getActiveWorkstep();
      return actualWorkstep.status === WORKFLOW_STEP_STATUS.APPROVED;
    }

    return false;
  }

  shouldShowActionButtons(): boolean {
    return (
      this.workflow.currentUser.status === WORKFLOW_STEP_STATUS.WAITING &&
      this.workflow.workflow.status === WORKFLOW_STATUS.CIRCULATING
    );
  }

  public getToolPositionTransform(field: any): string {
    const position = this.calcPosition(field);
    return `translate3d(${position.x}px, ${position.y}px, 0px)`;
  }

  public getToolSize(field: any): { width: number; height: number } {
    return this.calcSize(field);
  }

  public calcPosition(field: {
    x: number;
    y: number;
  }): { x: number; y: number } {
    return {
      x: Math.round(field.x * this.containerProportion),
      y: Math.round(field.y * this.containerProportion)
    };
  }

  public calcSize(field: {
    width: number;
    height: number;
  }): { width: number; height: number } {
    return {
      width: Math.round(field.width * this.containerProportion),
      height: Math.round(field.height * this.containerProportion)
    };
  }

  private setContainerProportion(proportion: number): void {
    this.containerProportion = proportion;
  }

  private calcProportion(
    pageSizeWidth: number,
    containerWidth: number
  ): number {
    return 1 - (pageSizeWidth - containerWidth) / pageSizeWidth;
  }

  private redirectToLanding() {
    window.location.href = '/';
  }

  private fetchGuestUserInfo() {
    if (this.authorizationService.isGuest()) {
      return this.userService.me().pipe(
        tap(() => {
          this.userLogged = this.authorizationService.getUser();
        })
      );
    }

    return this.authorizationService.user.pipe(
      tap((user: any) => {
        this.userLogged = user;
      })
    );
  }

  signatureCorrespondingToWorkstep(recipient: any) {
    if (recipient.details.signatureType === WORKFLOW_SIGNATURE.IN_PERSON) {
      if (this.getActiveWorkstep()) {
        const actualWorkstep = this.getActiveWorkstep();
        if (actualWorkstep.extraMetadata) {
          return (
            actualWorkstep.extraMetadata.document ===
            recipient.details.extraMetadata.document
          );
        }
      }
      return false;
    }

    return true;
  }

  getActiveWorkstep() {
    return this.document.steps.workflowSteps.find(
      (step: any) => step.id === this.workflowId
    );
  }

  recipientUserIsTheSameInCurrentWorkstep(recipient: any) {
    if (this.getActiveWorkstep()) {
      const actualWorkstep = this.getActiveWorkstep();
      return (
        recipient.id === actualWorkstep._embedded.user.id &&
        recipient.details.status.id === WORKFLOW_STEP_STATUS.WAITING
      );
    }
    return false;
  }

  isEnableToSignEdit(recipient: any, field: any): boolean {
    return (
      this.currentSignature &&
      !this.enableEdit &&
      this.recipientUserIsTheSameInCurrentWorkstep(recipient) &&
      field.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE &&
      recipient.status.action !== 'done' &&
      this.isCirculating() &&
      !this.shouldNotUserSign()
    );
  }
}
