import { WorkflowContact } from '@app/core/class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { QRCodeField } from './qr-code-field.class';

export class SharedQRCodeField extends QRCodeField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.QR_CODE_SHARE;
  }
}
