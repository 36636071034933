import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnChanges {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of current user
   */
  @Input() selected?: any;

  /**
   * Data of Form
   */
  contactForm: FormGroup;

  updateForm: FormGroup;

  newStatus: string;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  doUpdate() {
    const payload = {
      id: this.selected.id,
      status: this.selected.status === 0 ? 1 : 0
    };

    this.modal.close({
      action: 'edit',
      form: 'contact',
      formData: payload
    });
  }
  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'contact',
      formData: this.contactForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.contactForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.contactForm = this.formBuilder.group({
      id: ['', { disable: true }],
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required]
    });
    this.updateForm = this.formBuilder.group({});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected.currentValue) {
      this.newStatus =
        this.selected.status === 0 ? 'HABILITADO' : 'DESABILITADO';

      const { id, name, email } = changes.selected.currentValue;
      this.contactForm.setValue({
        id: id,
        name: name,
        email: email
      });
    }
  }
}
