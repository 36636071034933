import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Logger } from '@app/core/class';
import { ManagerRoutingPath } from '@app/manager';
import { AuthorizationService, MarketsegmentService, UserService } from '@app/core/services';
import { matchFields } from '@app/core/helpers';
import { Documents } from 'assets/policy/document';
import { RecaptchaComponent } from 'ng-recaptcha';
var log = new Logger('RegisterPage');
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(router, formBuilder, authorizationService, userService, marketsegment) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.authorizationService = authorizationService;
        this.userService = userService;
        this.marketsegment = marketsegment;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        /**
         * market segment list
         */
        this.segmentList = this.marketsegment.list();
        this.termsOfUse = Documents.termsOfUse();
        this.privacyPolicy = Documents.privacyPolicy();
    }
    RegisterComponent.prototype.ngAfterViewInit = function () {
        if (this.cellphone) {
            this.cellphone.nativeElement.parentElement.style = 'display: block;';
        }
    };
    RegisterComponent.prototype.ngOnInit = function () {
        this.createForm();
        if (this.authorizationService.isAuthenticated()) {
            log.debug("user logged, redirect to /manager");
            window.location.href = ManagerRoutingPath.MANAGER;
        }
    };
    RegisterComponent.prototype.ngOnDestroy = function () {
        RecaptchaComponent.prototype.ngOnDestroy = function () {
            // reset the captcha to ensure it does not leave anything behind
            // after the component is no longer needed
            this.grecaptchaReset();
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        };
    };
    RegisterComponent.prototype.getNumber = function (number) {
        this.registerForm.value.cellphone = number;
    };
    RegisterComponent.prototype.isChangeLoading = function () {
        this.isLoading = !this.isLoading;
    };
    RegisterComponent.prototype.resetRegisterForm = function () {
        this.registerForm.reset();
    };
    RegisterComponent.prototype.resolved = function (captchaResponse) {
        log.debug("Register [Resolved captcha with response]: " + captchaResponse);
    };
    RegisterComponent.prototype.recaptchaReset = function () {
        grecaptcha.reset();
    };
    RegisterComponent.prototype.shouldResetValueRecaptcha = function () {
        this.registerForm.controls.recaptcha.setValue('');
    };
    RegisterComponent.prototype.shouldResetRecaptchaAndForm = function () {
        this.recaptchaReset();
        this.shouldResetValueRecaptcha();
    };
    RegisterComponent.prototype.payload = function () {
        var payload = {
            name: this.registerForm.value.name,
            position: this.registerForm.value.position,
            segment: this.registerForm.value.segment,
            cellphone: this.registerForm.value.cellphone,
            email: this.registerForm.value.email,
            username: this.registerForm.value.email,
            password: this.registerForm.value.password,
            company: this.registerForm.value.company,
            businessUnit: {
                name: this.registerForm.value.company,
                segmentMarket: this.registerForm.value.segment
            },
            recaptcha: this.registerForm.value.recaptcha
        };
        return payload;
    };
    RegisterComponent.prototype.doSubmit = function () {
        var _this = this;
        var payload = this.payload();
        this.isChangeLoading();
        this.userService.create(payload).subscribe(function (data) {
            log.info("New user ID => " + data.id);
            _this.resetRegisterForm();
            _this.shouldResetRecaptchaAndForm();
            _this.router.navigate(['/auth/login'], {
                queryParams: { register: data.id }
            });
        }, function (err) {
            _this.shouldResetRecaptchaAndForm();
            if (err.error.status === 422 && err.error.reason === '2') {
                _this.userService.setTempNewUserData(_this.registerForm.value);
                _this.router.navigate(['auth/activate-user']);
            }
            _this.isChangeLoading();
            log.error("Error is: " + err.error.detail);
            _this.feedbackMessage = err.error.detail;
            _this.feedbackTheme = 'error';
        });
    };
    RegisterComponent.prototype.isNotValidField = function (controlName) {
        return ((this.f[controlName].errors && !this.f[controlName].pristine) ||
            (this.group.get(controlName).hasError('required') &&
                this.group.get(controlName).touched));
    };
    Object.defineProperty(RegisterComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "group", {
        get: function () {
            return this.registerForm;
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.createForm = function () {
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            email: ['', [Validators.required, Validators.email]],
            cellphone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(11),
                    Validators.maxLength(11)
                ])
            ],
            position: ['', [Validators.required]],
            segment: ['', [Validators.required]],
            company: ['', [Validators.required]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
                ]
            ],
            repeatPassword: [
                '',
                [
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
                ]
            ],
            policy: [
                false,
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.pattern('^([Tt][Rr][Uu][Ee])$')
                ]
            ],
            recaptcha: ['', Validators.required]
        }, { validator: matchFields('password', 'repeatPassword') });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
