<ngb-alert class="mb-3" [type]="feedbackTheme" [dismissible]="false">
  {{ feedbackMessage }}
</ngb-alert>

<h3>Confirmação da conta</h3>

<div *ngIf="resendConfirmation">
  <p>
    Insira seu e-mail de cadastro que reenviaremos um o link de confirmação.
  </p>
  <form
    (ngSubmit)="resendAction()"
    [formGroup]="resendConfirmationForm"
    novalidate
  >
    <div
      class="alert alert-danger mb-2"
      [hidden]="!resendConfirmationFormError || isLoading"
      translate
    >
      {{ resendConfirmationFormError }}
    </div>
    <div
      class="alert alert-danger mb-2"
      [hidden]="!resendConfirmationFormSuccess || isLoading"
      translate
    >
      {{ resendConfirmationFormSuccess }}
    </div>
    <div class="form-group">
      <label class="d-block">
        <input
          type="text"
          class="form-control"
          formControlName="email"
          placeholder="E-mail"
        />
        <small
          [hidden]="
            resendConfirmationForm.controls.email.valid ||
            resendConfirmationForm.controls.email.untouched
          "
          class="text-danger"
          translate
        >
          O endereço de e-mail é inválido
        </small>
      </label>
    </div>
    <p class="text-right">
      <a routerLink="/auth/login">Login</a> |
      <a routerLink="/auth/forget-password">Esqueceu sua senha?</a>
    </p>
    <button
      class="btn btn-secondary btn-sm"
      type="submit"
      [disabled]="resendConfirmationForm.invalid || isLoading"
    >
      <i class="fas fa-spinner fa-spin" [hidden]="!isLoading"></i>
      <span translate>Reenviar</span>
    </button>
  </form>
</div>

<p *ngIf="activated">
  Por favor, realize o login clicando <a routerLink="/auth/login">aqui</a>.
</p>

<div [hidden]="invitation === false || activated">
  <p>Para concluir o seu cadastro, por favor, complete os campos abaixo:</p>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <app-confirm-personal-form
            (formReady)="formInitialized('personal', $event)"
            [hidden]="showForm == 'BUSINESS'"
          ></app-confirm-personal-form>
          <div class="form-button text-right">
            <button
              *ngIf="showForm == 'PERSONAL'"
              class="btn btn-secondary"
              [disabled]="!personalForm.valid"
              (click)="doSubmit()"
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
