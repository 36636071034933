import { FormBuilder, Validators } from '@angular/forms';
var CancelAccountFormComponent = /** @class */ (function () {
    function CancelAccountFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        /**
         * Checks if the form is completed
         */
        this.hasFormFilled = false;
        this.createForm();
    }
    /**
     * Confirm form action
     */
    CancelAccountFormComponent.prototype.doConfirm = function () {
        this.hasFormFilled = true;
    };
    /**
     * Data selection action by clicking the form submit button
     */
    CancelAccountFormComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'cancel-account',
            formData: this.cancelForm.value
        });
    };
    Object.defineProperty(CancelAccountFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.cancelForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    CancelAccountFormComponent.prototype.createForm = function () {
        this.cancelForm = this.formBuilder.group({
            reason: ['', Validators.required],
            status: ['0']
        });
    };
    return CancelAccountFormComponent;
}());
export { CancelAccountFormComponent };
