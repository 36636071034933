import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Authentication, Plan, User } from '@app/core/models';
import { TagManageService } from '@app/core/services';

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.scss']
})
export class PlanDetailComponent implements OnInit {
  /**
   * data of plan
   */
  @Input() data: Plan.Plans;

  @Input() user: Authentication.SignupUserResponse;

  @Input() currentPlan: User.UserPlanPayload;

  @Output() planChoosed = new EventEmitter<number>();

  buttonText: any;

  constructor(private tagManageService: TagManageService) {}

  ngOnInit() {
    if (this.user.businessUnit) {
      this.buttonText = 'Apenas contas Personal';
    } else {
      this.buttonText = 'Apenas contas Business';
    }
  }

  choose(data: Plan.Plans) {
    this.tagManageService.sendEvent({ event: 'event', value: data.name });
    this.planChoosed.emit(data.id);
  }

  isForBusinessAccountsOnly(): boolean {
    if (!this.user || !this.data) {
      return false;
    }

    return (
      (this.user.businessUnit && this.data.type === 1) ||
      (!this.user.businessUnit && this.data.type === 0)
    );
  }

  isCurrentPlan(): boolean {
    if (!this.currentPlan || !this.data) {
      return false;
    }

    return this.currentPlan.idPlan === this.data.id;
  }

  detailClass() {
    const boxClass = {
      'box-disabled': false,
      current: false
    };

    if (!this.data) {
      return boxClass;
    }

    if (this.user) {
      boxClass['box-disabled'] = this.isForBusinessAccountsOnly();
    }

    if (this.currentPlan) {
      boxClass.current = this.isCurrentPlan();
    }

    return boxClass;
  }
}
