/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text.component.scss.shim.ngstyle";
import * as i1 from "../../droppable-field.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../modal/modal-action/modal-action.component.ngfactory";
import * as i4 from "../../../../../modal/modal-action/modal-action.component";
import * as i5 from "./in-text/in-text.component.ngfactory";
import * as i6 from "./in-text/in-text.component";
import * as i7 from "@angular/forms";
import * as i8 from "./text.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_WorkflowDroppableTextFieldComponent = [i0.styles, i1.styles];
var RenderType_WorkflowDroppableTextFieldComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDroppableTextFieldComponent, data: {} });
export { RenderType_WorkflowDroppableTextFieldComponent as RenderType_WorkflowDroppableTextFieldComponent };
function View_WorkflowDroppableTextFieldComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵted(-1, null, ["\n  "])), (_l()(), i2.ɵeld(1, 0, null, null, 6, "app-modal-action", [], null, null, null, i3.View_ModalActionComponent_0, i3.RenderType_ModalActionComponent)), i2.ɵdid(2, 49152, null, 0, i4.ModalActionComponent, [], { modal: [0, "modal"], showHeader: [1, "showHeader"], showFooter: [2, "showFooter"], showClose: [3, "showClose"], typeModal: [4, "typeModal"], title: [5, "title"] }, null), (_l()(), i2.ɵted(-1, 0, ["\n    "])), (_l()(), i2.ɵeld(4, 0, null, 0, 2, "app-in-text", [], null, [[null, "cancel"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.onSubmit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_InTextComponent_0, i5.RenderType_InTextComponent)), i2.ɵdid(5, 114688, null, 0, i6.InTextComponent, [i7.FormBuilder], null, { cancel: "cancel", submit: "submit" }), (_l()(), i2.ɵted(-1, null, ["\n    "])), (_l()(), i2.ɵted(-1, 0, ["\n  "])), (_l()(), i2.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; var currVal_4 = "simple green"; var currVal_5 = "Campos opcionais"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); _ck(_v, 5, 0); }, null); }
export function View_WorkflowDroppableTextFieldComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "field-block field-email"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["\n  "])), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "simple-text"]], null, null, null, null, null)), (_l()(), i2.ɵted(3, null, ["", ""])), (_l()(), i2.ɵted(-1, null, ["\n"])), (_l()(), i2.ɵted(-1, null, ["\n\n"])), (_l()(), i2.ɵeld(6, 0, null, null, 6, "div", [["class", "actions-list"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["\n  "])), (_l()(), i2.ɵeld(8, 0, null, null, 3, "button", [["class", "actions-list__item"], ["title", "Clique para editar o campo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(i2.ɵnov(_v, 14)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["\n    "])), (_l()(), i2.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-pen"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["\n  "])), (_l()(), i2.ɵted(-1, null, ["\n"])), (_l()(), i2.ɵted(-1, null, ["\n\n"])), (_l()(), i2.ɵand(0, [["addOptionalFieldsModal", 2]], null, 0, null, View_WorkflowDroppableTextFieldComponent_1)), (_l()(), i2.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.properties; _ck(_v, 3, 0, currVal_0); }); }
export function View_WorkflowDroppableTextFieldComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-text-field", [], null, null, null, View_WorkflowDroppableTextFieldComponent_0, RenderType_WorkflowDroppableTextFieldComponent)), i2.ɵdid(1, 49152, null, 0, i8.WorkflowDroppableTextFieldComponent, [i9.NgbModal], null, null)], null, null); }
var WorkflowDroppableTextFieldComponentNgFactory = i2.ɵccf("app-workflow-droppable-text-field", i8.WorkflowDroppableTextFieldComponent, View_WorkflowDroppableTextFieldComponent_Host_0, { field: "field" }, {}, []);
export { WorkflowDroppableTextFieldComponentNgFactory as WorkflowDroppableTextFieldComponentNgFactory };
