<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div class="page page-users">
  <div class="container">
    <div>
      <h2>Personalizar assunto do e-mail</h2>
      <div class="row">
        <div class="col-9">
          <p>
            Crie e personalize os assuntos dos e-mails que serão enviados para
            seus destinatários.
          </p>
        </div>
        <div class="col-1">
          <p><a routerLink="/manager/profile">Voltar</a></p>
        </div>
        <div class="col-2">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            (click)="addNewSubject()"
          >
            Adicionar assunto
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="users-profile">
          <!-- <app-profile-data></app-profile-data></div> -->
        </div>
        <div class="col-12">
          <div class="users-list">
            <div class="row">
              <div class="col-12">
                <div class="user-table">
                  <app-lists
                    [collection]="customSubjectMail"
                    [fields]="configList"
                    [sortableFields]="sortFields"
                    (selected)="getSelectedItems($event)"
                    (activity)="doAction($event)"
                    (sort)="doSort($event)"
                  ></app-lists>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #modalConfirmDelete let-modal>
    <app-modal-action
      [modal]="modal"
      [typeModal]="'simple green'"
      [showHeader]="true"
      [showFooter]="false"
      [showClose]="false"
      [title]="'Excluir assunto'"
    >
      <div class="message">
        <p class="text-justify">
          Tem certeza que deseja excluir o assunto? Após a exclusão o asssunto
          voltará para o padrão do Assine.Online.
        </p>
      </div>
      <div class="col-12 text-right">
        <button class="btn btn-default btn-sm" (click)="modal.close('cancel')">
          Cancelar
        </button>

        <button
          class="btn btn-danger btn-sm"
          (click)="modal.close({ action: 'delete' })"
        >
          Excluir
        </button>
      </div>
    </app-modal-action>
  </ng-template>

  <ng-template #modalCreateCustomSubject let-modal>
    <app-modal-action
      [modal]="modal"
      [typeModal]="'simple green'"
      [showHeader]="true"
      [showFooter]="false"
      [showClose]="false"
      [title]="titleModal"
    >
      <app-custom-subject-mail-form
        [isLoading]="isLoading"
        [modal]="modal"
        [item]="selected"
      ></app-custom-subject-mail-form>
    </app-modal-action>
  </ng-template>

  <ng-template #modalLoading let-modal>
    <app-modal
      [modal]="modal"
      [type]="'loading'"
      [showHeader]="false"
      [showFooter]="false"
      [showClose]="false"
      [title]=""
      [text]="modalRef.data.text"
    >
    </app-modal>
  </ng-template>
</div>
