import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { Logger, I18n, LogLevel } from '@app/core/class';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
declare var gtag: any;
const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private i18n: I18n,
    private router: Router
  ) {
    if (environment.production) {
      this.tagManager();
      const navEndEvent$ = router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        gtag('config', environment.googleTagManager, {
          page_path: e.urlAfterRedirects
        });
      });
    }
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    } else if (environment.env === 'testing') {
      Logger.disableLogging();
    }

    // Setup translations
    this.i18n.init(environment.defaultLanguage, environment.supportedLanguages);
  }

  tagManager() {
    const gtagId = environment.googleTagManager;
    const first = document.getElementsByTagName('script')[0];
    const element = document.createElement('script');

    element.async = true;
    element.src = `https://www.googletagmanager.com/gtm.js?id=${gtagId}`;
    first.parentNode.insertBefore(element, first);
  }
}
