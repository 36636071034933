import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-lists',
  templateUrl: './navigation-lists.component.html',
  styleUrls: ['./navigation-lists.component.scss']
})
export class NavigationListsComponent {
  /**
   * Collection with the navigation links
   */
  @Input() pages: any;

  constructor() {}
}
