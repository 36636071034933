/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header.component";
import * as i4 from "../../../../core/services/authorization.service";
import * as i5 from "../../../../core/services/business-unit-metadata.service";
var styles_LayoutSimpleHeaderComponent = [i0.styles];
var RenderType_LayoutSimpleHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutSimpleHeaderComponent, data: {} });
export { RenderType_LayoutSimpleHeaderComponent as RenderType_LayoutSimpleHeaderComponent };
function View_LayoutSimpleHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "logo-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToManager() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "img-fluid"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logo; _ck(_v, 4, 0, currVal_0); }); }
function View_LayoutSimpleHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "brand large inverse"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToManager() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Assine Online"])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], null, null); }
function View_LayoutSimpleHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "btn btn-sm btn-default"], ["href", "/auth/login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fazer login"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "btn btn-criar-conta btn-sm btn-secondary"], ["href", "/register"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Criar Conta"])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, null); }
export function View_LayoutSimpleHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "header", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "navbar navbar-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutSimpleHeaderComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵand(0, [["defaultlogo", 2]], null, 0, null, View_LayoutSimpleHeaderComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutSimpleHeaderComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logo; var currVal_1 = i1.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = !_co.isAuthenticated(); _ck(_v, 15, 0, currVal_2); }, null); }
export function View_LayoutSimpleHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-simple-header", [], null, null, null, View_LayoutSimpleHeaderComponent_0, RenderType_LayoutSimpleHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutSimpleHeaderComponent, [i4.AuthorizationService, i5.BusinessMetadataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutSimpleHeaderComponentNgFactory = i1.ɵccf("app-layout-simple-header", i3.LayoutSimpleHeaderComponent, View_LayoutSimpleHeaderComponent_Host_0, { hideInfoBar: "hideInfoBar", showAuth: "showAuth" }, {}, []);
export { LayoutSimpleHeaderComponentNgFactory as LayoutSimpleHeaderComponentNgFactory };
