<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div *ngIf="!step; else confirmUpdate">
  <div class="form-change-password form-modal">
    <form (ngSubmit)="doSubmit(true)" [formGroup]="data" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Nome completo"
              formControlName="name"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Celular"
              formControlName="cellphone"
              mask="(00) 00000-0000"
            />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn-default"
            (click)="doSubmit(false)"
          >
            Cancelar
          </button>

          <button
            class="btn btn-secondary btn-sm"
            type="submit"
            [disabled]="data.invalid"
          >
            <i class="fas fa-circle-notch fa-spin" *ngIf="spinnerSave"></i>
            Salvar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #confirmUpdate>
  <div class="row">
    <div class="col-12"><p>Detalhes da conta atualizado com sucesso.</p></div>
    <div class="col-12 text-center">
      <button class="btn btn-secondary btn-sm" (click)="refreshPage()">
        Fechar
      </button>
    </div>
  </div>
</ng-template>
