import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Member } from 'app/core/models';
import { MemberService } from 'app/core/services';
import { finalize } from 'rxjs/operators';

const log = new Logger('MembersPage');

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersPageComponent implements OnInit {
  /**
   * loading of form
   */
  isLoading: boolean = false;

  /**
   * collection with data
   */
  collection: Array<Member.MemberPayload>;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * current team for edit/delete
   */

  selectedMember: any;

  constructor(
    private memberService: MemberService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // this.getTeams();

    // this.sortFields = { name: 'ASC', email: 'ASC' };
    this.configList = [
      {
        name: 'Nome',
        klass: '',
        type: 'text',
        fieldId: 'name',
        isSortable: true
      },
      {
        name: 'Email',
        klass: 'text-center',
        type: 'text',
        fieldId: 'email',
        isSortable: true
      },
      {
        name: 'Ações',
        type: 'button',
        icon: 'fa fa-sliders-h',
        options: {
          primary: {
            name: 'Excluir contato',
            action: 'remove'
          }
        }
      }
    ];
  }

  /**
   * provides the data of teams
   *
   * @return void
   */
  // getTeams() {
  //   try {
  //     // this.isLoading = true;
  //     this.memberService
  //       .getAll()
  //       .pipe(
  //         finalize(() => {
  //           // this.isLoading = false;
  //         })
  //       )
  //       .subscribe(
  //         (response: Array<Member.MemberPayload>) => {
  //           log.debug(`Success [getMembers]: ${response}`);
  //           this.collection = response;
  //         },
  //         (error: any) => {
  //           log.debug(`Error [getMembers]: ${error}`);
  //           this.collection = [];
  //         }
  //       );
  //   } catch (e) {
  //     // this.isLoading = false;
  //     this.collection = [];
  //     log.debug(`Error [getMembers]: ${e}`);
  //   }
  // }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any) {
    //   this.modalService
    //     .open(content, {
    //       ariaLabelledBy: 'modal-title',
    //       keyboard: false,
    //       backdrop: 'static'
    //     })
    //     .result.then(result => {
    //       if (result.action === 'save') {
    //         if (result.form === 'user') {
    //           this.doSubmitForm(result.formData);
    //           return;
    //         }
    //       }
    //       if (result === 'cancel') {
    //         return;
    //       }
    //     });
  }

  /**
   * Action by clicking in submit members form
   *
   * @param (any) form with data
   */
  // doSubmitForm(form: any) {
  //   console.log(form);
  // }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    //   this.sortFields[field] = this.reorder(this.sortFields[field]);
    //   console.log(this.sortFields);
  }

  /**
   * Action grab data
   *
   * @param (any) event to process an action
   */
  doAction(event: any) {
    //   if (event.type === 'remove') {
    //     console.log('ID of user to remove of the members', event.data.id);
    //   }
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  // private reorder(field: string) {
  //   return field === 'ASC' ? 'DESC' : 'ASC';
  // }
}
