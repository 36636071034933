<div class="form-personal">
  <form (ngSubmit)="doSubmit()" [formGroup]="userForm" novalidate>
    <div class="box">
      <div class="box-header button"><h3>Detalhes da conta</h3></div>

      <div class="box-content">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Nome completo </label>
              <input
                type="text"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': f.name.errors && !f.name.pristine
                }"
                formControlName="name"
              />
              <div *ngIf="f.name.errors && !f.name.pristine">
                <span class="error" [hidden]="!f.name.errors.required">
                  O nome completo é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label> E-mail </label>
            <input
              class="form-control form-control-sm"
              type="email"
              formControlName="email"
              readonly
            />
          </div>

          <div class="form-group col-md-5 offset-md-1">
            <label> Telefone </label>
            <input
              #cellphone
              type="text"
              ng2TelInput
              class="form-control form-control-sm"
              placeholder="Celular"
              formControlName="cellphone"
              [ng2TelInputOptions]="{
                initialCountry: 'br',
                onlyCountries: ['br']
              }"
              (ng2TelOutput)="getNumber($event)"
              [ngClass]="{
                'is-invalid': f.cellphone.errors && !f.cellphone.pristine
              }"
              mask="(00) 00000-0000"
            />
            <div *ngIf="f.cellphone.errors && !f.cellphone.pristine">
              <span class="error" [hidden]="!f.cellphone.errors.required">
                O telefone é obrigatório
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-1">
          <button
            class="btn btn-secondary btn-sm"
            type="submit"
            [disabled]="userForm.invalid || isLoading"
          >
            <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i> Salvar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
