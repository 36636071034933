/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error.component";
import * as i3 from "@angular/router";
var styles_IntegrationErrorPageComponent = [i0.styles];
var RenderType_IntegrationErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationErrorPageComponent, data: {} });
export { RenderType_IntegrationErrorPageComponent as RenderType_IntegrationErrorPageComponent };
export function View_IntegrationErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "document-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "icon-document-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 9, 0, currVal_0); }); }
export function View_IntegrationErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-integration-error-page", [], null, null, null, View_IntegrationErrorPageComponent_0, RenderType_IntegrationErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.IntegrationErrorPageComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationErrorPageComponentNgFactory = i1.ɵccf("app-workflow-integration-error-page", i2.IntegrationErrorPageComponent, View_IntegrationErrorPageComponent_Host_0, {}, {}, []);
export { IntegrationErrorPageComponentNgFactory as IntegrationErrorPageComponentNgFactory };
