import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SIGNATURE_TYPE } from '@app/core/services';
import { Observable, Subscriber } from 'rxjs';

@Component({
  selector: 'app-upload-signature',
  templateUrl: './upload-signature.component.html',
  styleUrls: ['./upload-signature.component.scss']
})
export class UploadSignatureComponent implements OnInit {
  /**
   * keeps signature
   */
  signature: any;

  /**
   * checks if the signature
   */
  checkssign: boolean = false;

  /**
   * Signature type object Upload
   */
  @Output() dataSignUploadEvent = new EventEmitter();

  public dataSignUpload: Object = {
    type: SIGNATURE_TYPE.UPLOADING,
    sign: ''
  };

  constructor() {}

  inputFileChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.convertToBase64(file);
  }

  /**
   * convert To Base64
   */
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });

    observable.subscribe(data => {
      const dataConvert = data.replace('data:image/jpeg', 'data:image/png');
      this.signature = dataConvert;
      this.dataSignUpload['sign'] = dataConvert;
      this.dataSignUploadEvent.emit({ dataSignature: this.dataSignUpload });
      // console.log(this.dataSignUpload);
      this.checkssign = true;
    });
  }

  /**
   * read file
   */
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();

    if (file) {
      filereader.readAsDataURL(file);
      filereader.onload = () => {
        subscriber.next(filereader.result);
        subscriber.complete();
      };
      filereader.onerror = error => {
        subscriber.error(error);
        subscriber.complete();
      };
    }
  }
  ngOnInit() {}
}
