import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPI } from '../class';
import { BusinessUnitMetadata } from '../models/business-unit-metadata.model';

@Injectable()
export class BusinessMetadataService extends BaseAPI {
  public output = new EventEmitter<BusinessUnitMetadata.OrganizationBrand>();

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/business-unit-metadata';
  }

  createMetadata(
    payload: BusinessUnitMetadata.LogoPayload
  ): Observable<BusinessUnitMetadata.LogoCollection> {
    return this.httpClient.post(this.endpoint, payload).pipe(
      map((body: any) => {
        const logo: BusinessUnitMetadata.LogoCollection = {
          id: body.id,
          dataKey: body.dataKey,
          dataValue: body.dataValue,
          businessUnit: {
            id: body._embedded.businessUnit.id,
            name: body._embedded.businessUnit.name,
            document: body._embedded.businessUnit.document
          }
        };

        return logo;
      })
    );
  }

  updateMetadata(id: number, payload: BusinessUnitMetadata.LogoPayload) {
    return this.httpClient.patch(this.endpoint + `/${id}`, payload).pipe(
      map((logo: BusinessUnitMetadata.LogoCollection) => {
        return logo;
      })
    );
  }

  /**
   * Gets all data of a service
   */
  getMetadata(
    querystring?: any
  ): Observable<BusinessUnitMetadata.LogoCollection> {
    return this.httpClient
      .get(this.endpoint + (querystring ? querystring : ''))
      .pipe(
        map((response: any) => {
          if (!response._embedded.business_unit_metadata[0]) {
            return null;
          }

          const logo: BusinessUnitMetadata.LogoCollection = {
            id: response._embedded.business_unit_metadata[0].id,
            dataKey: response._embedded.business_unit_metadata[0].dataKey,
            dataValue: response._embedded.business_unit_metadata[0].dataValue,
            businessUnit: {
              id:
                response._embedded.business_unit_metadata[0]._embedded
                  .businessUnit.id,
              name:
                response._embedded.business_unit_metadata[0]._embedded
                  .businessUnit.name,
              document:
                response._embedded.business_unit_metadata[0]._embedded
                  .businessUnit.document
            }
          };

          return logo;
        })
      );
  }

  public inputBrand(marca: BusinessUnitMetadata.OrganizationBrand): void {
    this.output.emit(marca);
  }
}
