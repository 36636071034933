import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Contact, Document } from '@app/core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CHECK_WORKFLOW_STEP,
  ContactService,
  WorkflowStepUserUpdateService
} from '@app/core/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';

const log = new Logger('PlansPage');

export const PROBLEM_STEP = {
  SUCCESS: 0,
  ERROR: 1,
  REJECTED: 2
};
@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {
  CHECK_WORKFLOW_STEP = CHECK_WORKFLOW_STEP;
  PROBLEM_STEP = PROBLEM_STEP;

  @ViewChild('modalReplaceSubscriber') replaceSubscriber: ElementRef;
  @ViewChild('modalFeedbabck') modalFeedbabck: ElementRef;
  @ViewChild('modalShowPhoto') modalShowPhoto: ElementRef;

  /**
   * sets whether the items are sortable
   */
  @Input() isSortable?: boolean = false;

  /**
   * provides the items list
   */
  @Input() items: Document.Recipient[];

  /**
   * Action for delete
   */
  @Output() doDelete? = new EventEmitter();

  /**
   * Action for update
   */
  @Output() doEdit? = new EventEmitter();

  /**
   * Action for sort
   */
  @Output() doSort? = new EventEmitter();

  public modalService: NgbModal;

  public photoData: string;

  modalRef: any;

  /**
   * Data of Form
   */
  userForm: FormGroup;

  public isSavingUser: boolean = false;

  public stepId: string = null;

  public stepFeedbabck: number = 0;

  constructor(
    modalService: NgbModal,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private workflowStepUserUpdateService: WorkflowStepUserUpdateService
  ) {
    this.createForm();
    this.modalService = modalService;
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {}

  /**
   * changes the order of a recipient
   */
  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.doSort.emit(this.items);
  }

  /**
   * It performs the deletion action
   */
  public doDeleteAction(recipient: Document.Recipient) {
    this.doDelete.emit(recipient);
  }

  /**
   * It performs the edition action
   */
  public doEditAction(recipient: Document.Recipient) {
    this.doEdit.emit(recipient);
  }

  async changeSubscriberUser(payload: Contact.Contact) {
    try {
      let data: Contact.ContactStep;
      data = await this.prepareContact(payload);
      this.toUpdateWorkflowUser(this.stepId, data);
    } catch (error) {
      this.isSavingUser = false;
      this.toResetForm();
      if (this.modalRef.ref) {
        this.modalRef.ref.close();
      }
      this.stepFeedbabck = PROBLEM_STEP.ERROR;
      this.open(this.modalFeedbabck, 'md');
    }
  }

  private async prepareContact(payload: Contact.Contact) {
    let data: Contact.ContactStep;
    const contact = await this.contactService.getContactByEmail(payload.email).toPromise();
    if (contact) {
      data = {
        id_user: contact.userId,
        name: payload.name
      };
      return data;
    }
    const newContact = await this.addNewContact(payload);
    data = newContact;
    return data;
  }
  private async addNewContact(payload: Contact.Contact) {
    let data: Contact.ContactStep;
    const resp = await this.contactService.addContact(payload).toPromise();
    data = {
      id_user: resp.id,
      name: payload.name
    };
    return data;
  }
  private toUpdateWorkflowUser(stepId: string, data: Contact.ContactStep) {
    this.workflowStepUserUpdateService
      .updateWorkflowUser(stepId, data)
      .subscribe(
        (response: any) => {
          this.toResetForm();
          this.isSavingUser = false;
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }

          this.stepFeedbabck = PROBLEM_STEP.SUCCESS;
          this.open(this.modalFeedbabck, 'md');
          log.debug(`Success [updateRecipient]: ${response}`);
        },
        (error: any) => {
          this.isSavingUser = false;
          this.toResetForm();
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
          this.stepFeedbabck = PROBLEM_STEP.ERROR;
          this.open(this.modalFeedbabck, 'md');
          log.debug(`Error [updateRecipient]: ${error}`);
        }
      );
  }

  public openPhoto(photo: string) {
    this.photoData = photo;
    this.open(this.modalShowPhoto, 'md');
  }

  public open(content: any, size?: any) {
    this.modalRef.ref = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return this.modalRef;
  }

  public reloadPage() {
    window.location.reload();
  }

  public toModalCancel() {
    this.modalRef.ref.close({
      action: 'cancel',
      form: 'changeRecipient',
      formData: ''
    });
    this.isSavingUser = false;
    this.toResetForm();
  }

  public getUserAndStep(step: any) {
    if (step.status.action !== CHECK_WORKFLOW_STEP.WAITING) {
      this.stepFeedbabck = PROBLEM_STEP.REJECTED;
      this.open(this.modalFeedbabck, 'md');
      return;
    }

    this.userForm.setValue({
      name: step.name,
      email: step.email
    });

    this.stepId = step.stepId;
    this.open(this.replaceSubscriber, 'md');
  }

  public doSubmit() {
    this.isSavingUser = true;
    const payload: Contact.Contact = {
      name: this.userForm.value.name,
      email: this.userForm.value.email
    };

    this.changeSubscriberUser(payload);
  }

  /**
   * Reset form
   */
  public toResetForm() {
    this.userForm.reset();
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  public get f() {
    return this.userForm.controls;
  }

  private createForm() {
    this.userForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.minLength(5)])
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  public toGetPhoto(photo: string) {
    const link = document.createElement('a');
    link.download = 'foto';
    link.href = photo;
    // Firefox requires the link to be in the body
    document.body.appendChild(link);
    link.click();
    // remove the link when done
    document.body.removeChild(link);
  }
}
