import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var SelectInPersonSignatureComponent = /** @class */ (function () {
    function SelectInPersonSignatureComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.showSignature = 'draw';
        this.createForm();
    }
    SelectInPersonSignatureComponent.prototype.ngOnInit = function () { };
    SelectInPersonSignatureComponent.prototype.typeSignature = function (event) {
        this.signatureForm.setValue({
            signature: event['dataSignature'].sign
        });
    };
    SelectInPersonSignatureComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'faceToFaceSubscription',
            formData: this.signatureForm.value.signature
        });
    };
    Object.defineProperty(SelectInPersonSignatureComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.signatureForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    SelectInPersonSignatureComponent.prototype.createForm = function () {
        this.signatureForm = this.formBuilder.group({
            signature: ['', Validators.required]
        });
    };
    return SelectInPersonSignatureComponent;
}());
export { SelectInPersonSignatureComponent };
