import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '@app/utils/utils';

@Component({
  selector: 'app-photo-sign',
  templateUrl: './photo-sign.component.html',
  styleUrls: ['./photo-sign.component.scss']
})
export class PhotoSignComponent implements OnInit {
  @Input() modal: any;

  public stepPhoto: boolean;

  private showButtonSubmit: boolean;

  private photoSign: Event;

  constructor() {
    this.stepPhoto = false;
    this.showButtonSubmit = false;
  }

  ngOnInit(): void {}

  public showPhotoSign(): void {
    this.stepPhoto = !this.stepPhoto;
  }

  public showButtonToSubmit(event?: Event): void {
    this.showButtonSubmit = !this.showButtonSubmit;
  }

  public isMobile(): boolean {
    return Utils.isMobile();
  }

  private addPhotoWorkflowStep(event: Event): void {
    this.showButtonToSubmit();
    this.photoSign = event;
  }

  private toSubmit(): void {
    // this.submit.emit(this.photoSign)
    this.modal.close({
      action: 'save',
      form: 'photoSignToSign',
      formData: this.photoSign
    });
  }
}
