import { TranslateService } from '@ngx-translate/core';
import { includes } from 'lodash';
import * as moment from 'moment';
import { Logger } from './logger.class';
import enUS from '@trans/en-US.json';
import ptBR from '@trans/pt-BR.json';
import es from '@trans/es.json';
var log = new Logger('I18n');
var languageKey = 'language';
/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s) {
    return s;
}
var I18n = /** @class */ (function () {
    function I18n(translateService) {
        this.translateService = translateService;
        // Embed languages to avoid extra HTTP requests
        translateService.setTranslation('pt-BR', ptBR);
        translateService.setTranslation('en-US', enUS);
        translateService.setTranslation('es', es);
    }
    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param defaultLanguage The default language to use.
     * @param supportedLanguages The list of supported languages.
     */
    I18n.prototype.init = function (defaultLanguage, supportedLanguages) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';
        this.translateService.onLangChange.subscribe(function (event) {
            localStorage.setItem(languageKey, event.lang);
        });
    };
    Object.defineProperty(I18n.prototype, "language", {
        /**
         * Gets the current language.
         * @return The current language code.
         */
        get: function () {
            return this.translateService.currentLang;
        },
        /**
         * Sets the current language.
         * Note: The current language is saved to the local storage.
         * If no parameter is specified, the language is loaded from local storage (if present).
         * @param language The IETF language code to set.
         */
        set: function (language) {
            language =
                language ||
                    localStorage.getItem(languageKey) ||
                    this.translateService.getBrowserCultureLang();
            var isSupportedLanguage = includes(this.supportedLanguages, language);
            // If no exact match is found, search without the region
            if (language && !isSupportedLanguage) {
                language = language.split('-')[0];
                language =
                    this.supportedLanguages.find(function (supportedLanguage) {
                        return supportedLanguage.startsWith(language);
                    }) || '';
                isSupportedLanguage = Boolean(language);
            }
            // Fallback if language is not supported
            if (!isSupportedLanguage) {
                language = this.defaultLanguage;
            }
            log.debug("Language set to " + language + ", " + language.substr(0, 2));
            this.translateService.use(language);
            moment.locale(language.substr(0, 2));
        },
        enumerable: true,
        configurable: true
    });
    return I18n;
}());
export { I18n };
