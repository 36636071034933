import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-feedback',
  templateUrl: './action-feedback.component.html',
  styleUrls: ['./action-feedback.component.scss']
})
export class ActionFeedbackComponent implements OnInit {
  @Input() modal: any;

  @Input() text: string;

  @Input() payload: string;

  constructor() {}

  ngOnInit() {}

  public shouldModalClose(): void {
    this.modal.close('cancel');
  }

  public toSubmit(): void {
    this.modal.close({
      action: 'save',
      form: 'deleteIntegrationWithFolder',
      formData: this.payload
    });
  }
}
