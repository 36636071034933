import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@app/core/services';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class LayoutPublicComponent implements OnInit {
  isLogged: boolean = false;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.isLogged = this.authorizationService.isAuthenticated();
  }
}
