import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
import { Authentication, Country, State } from '@app/core/models';
import { Plan } from '@app/core/models/plan.model';
import { CreditCardValidator } from 'angular-cc-library';
import { RecaptchaComponent } from 'ng-recaptcha';

const log = new Logger('Payment');
@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit, OnDestroy {
  @Input() stateList: Array<State.FormPayload>;
  @Input() countryList: Array<Country.FormPayload>;
  @Output() infoFilled = new EventEmitter<Plan.CreditCard>();
  @Input() logged: Authentication.SignupUserResponse;

  cardSide: boolean = true;
  cardinfo: any = {};
  cardForm: FormGroup;
  isVisa: boolean = false;
  isMastercard: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.cardForm = this.formBuilder.group({
      document: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)])
      ],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      zipCode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8)
        ])
      ],
      recaptcha: ['', Validators.required],
      holderName: ['', Validators.required],
      number: ['', CreditCardValidator.validateCCNumber],
      expMonth: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2)
        ])
      ],
      expYear: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ])
      ],
      cvv: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3)
        ])
      ]
    });
  }

  ngOnInit() {
    this.provisionalInformation();
  }

  ngOnDestroy() {
    RecaptchaComponent.prototype.ngOnDestroy = function() {
      // reset the captcha to ensure it does not leave anything behind
      // after the component is no longer needed
      this.grecaptchaReset();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
  }

  public resolved(captchaResponse: string): void {
    log.debug(`Resolved captcha with response: ${captchaResponse}`);
  }

  private recaptchaReset(): void {
    grecaptcha.reset();
  }

  private shouldResetValueRecaptcha(): void {
    this.cardForm.controls.recaptcha.setValue('');
  }

  public shouldResetRecaptchaAndForm(): void {
    this.recaptchaReset();
    this.shouldResetValueRecaptcha();
  }

  public onSubmit(): void {
    const form = this.cardForm.value;
    this.infoFilled.emit(<Plan.CreditCard>{
      holderName: form.holderName,
      number: form.number,
      expMonth: form.expMonth,
      expYear: form.expYear.substring(2, 4),
      cvv: form.cvv,
      document: form.document,
      billingAddress: {
        country: form.country,
        state: form.state,
        city: form.city,
        address: form.address,
        zipCode: form.zipCode
      },
      recaptcha: form.recaptcha
    });
  }

  public cardHolderNameToUppercase(): void {
    if (this.cardinfo && this.cardinfo.name) {
      this.cardinfo.name = this.cardinfo.name.toUpperCase();
    }
  }

  get f() {
    return this.cardForm.controls;
  }

  public ValidateCreditCardNumber(): void {
    const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;

    if (visaRegEx.test(this.cardinfo.number)) {
      this.isVisa = true;
      this.isMastercard = false;
    } else if (mastercardRegEx.test(this.cardinfo.number)) {
      this.isMastercard = true;
      this.isVisa = false;
    } else {
      this.isVisa = false;
      this.isMastercard = false;
    }
  }

  public provisionalInformation(): void {
    this.cardForm.setValue({
      document: this.logged.businessUnit.document,
      country: 'BR',
      state: this.logged.businessUnit.state,
      city: this.logged.businessUnit.city,
      address: this.logged.businessUnit.address,
      zipCode: this.logged.businessUnit.zipCode,
      recaptcha: '',
      holderName: '',
      number: '',
      expMonth: '',
      expYear: '',
      cvv: ''
    });
  }
}
