<app-feedback
  [theme]="feedback.type"
  [message]="feedback.message"
></app-feedback>

<div class="page page-coderesult">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li class="active"><a routerLink="/manager"> Página inicial </a></li>
          <li class="active"><a routerLink="/manager/check"> Verificar </a></li>
          <li class="active">
            <a routerLink="/manager/check-sign"> Assinatura </a>
          </li>
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a routerLink="/manager/code-result"> Resultado </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-5 offset-2">
        <h6>Acompanhamento do documento</h6>
        <h2>ID: {{ data.id }}</h2>
        <p>{{ data.name }}</p>
      </div>
      <div class="col-3">
        <div class="box-ok" *ngIf="data.status">
          <p>DOCUMENTO VERIFICADO</p>
          <i class="fa fa-check fa-3x"></i>
        </div>
        <div class="box-error" *ngIf="!data.status">
          <p>DOCUMENTO COM ERROS</p>
          <i class="fa fa-check fa-3x"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-8">
        <div class="code-ok" *ngIf="data.status">
          Está tudo ok com o documento.
        </div>
        <div class="code-error" *ngIf="!data.status">
          As assinaturas estão inválidas e algo no documento foi alterado desde
          que foi assinado.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2">
        <app-upload
          buttonText="Procurar"
          dragDropText="Arraste e solte seus documentos aqui serem verificados."
          showModalStatus="true"
          [layout]="'small'"
          (processFile)="createDocument($event)"
        >
        </app-upload>
      </div>
    </div>
  </div>
</div>
