import { Component, OnInit } from '@angular/core';
import { PolicyDocument } from '@app/core/models';
import { CookiesService } from '@app/core/services';
import { Documents } from 'assets/policy/document';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  private cookieKey: string = '__accept_policy';

  public cookies: PolicyDocument.File;

  constructor(private cookiesService: CookiesService) {
    this.cookies = Documents.cookies();
  }

  ngOnInit() {}

  public toAccept(): void {
    this.cookiesService.set(this.cookieKey, 'true', 30);
  }
}
