import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { matchFields } from '@app/core/helpers';
import { Logger } from '@app/core/class';
import { AuthenticationService } from '@app/core/services';

const log = new Logger('ForgetPassword');

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  step: number = 0;

  error: string;
  isLoading: boolean = false;

  forgetPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;

  apiDetails: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  doAction() {
    this.isLoading = true;
    try {
      this.authenticationService
        .forgetPassword(this.forgetPasswordForm.value)
        .pipe(
          finalize(() => {
            this.forgetPasswordForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (!response.success) {
              log.debug(`Forget Password error:`, response.details);
              this.error = response.details;
              return;
            }

            this.apiDetails = response;
            this.error = undefined;
            this.step = 1;
          },
          (e: any) => {
            log.debug(`Forget Password error:`, e);
            this.error = e.error.detail;
          }
        );
    } catch (e) {
      this.error = e;
    }
  }

  doActionCode() {
    this.isLoading = true;
    try {
      const form = this.resetPasswordForm.value;
      const payload = {
        token: this.apiDetails.token,
        pinPrefix: this.apiDetails.pinPrefix,
        password: form.password,
        pin: form.code
      };

      this.authenticationService
        .resetPassword(payload)
        .pipe(
          finalize(() => {
            this.resetPasswordForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (!response.success) {
              log.debug(`doActionCode error:`, response.details);
              this.error = response.details;
              return;
            }

            this.error = undefined;
            this.step = 2;
          },
          (e: any) => {
            log.debug(`doActionCode error:`, e);
            this.error = e.error.detail;
          }
        );
    } catch (e) {
      this.error = e;
    }
  }

  private createForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        code: ['', Validators.required],
        password: ['', Validators.required],
        repeatPassword: ['', Validators.required]
      },
      { validator: matchFields('password', 'repeatPassword') }
    );
  }
}
