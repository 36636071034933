<div
  class="item-draggable"
  #itemDraggable
  cdkDrag
  cdkDragBoundary=".dropped-fields-zone"
  (cdkDragEnded)="onDragEnd($event)"
>
  <a class="action-close" (click)="remove()">
    <i class="fa fa-times-circle"></i>
  </a>
  <span class="badge badge-secondary contact-index">{{
    field.getContact().getOrderIndex()
  }}</span>
  <div
    [style.width.px]="width"
    [style.height.px]="height"
    [ngResizable]="field.canResize()"
    [rzAspectRatio]="field.shouldKeepAspectRatio()"
    [rzHandles]="'se'"
    [rzMinWidth]="field.getMinWidth()"
    [rzMinHeight]="field.getMinHeight()"
    (rzStop)="onResizeStop($event)"
    rzContainment=".dropped-fields-zone"
  >
    <div
      cdkDragHandle
      class="item-draggable-handle"
      [ngSwitch]="field.getType()"
    >
      <!-- NAME -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_NAME_TEXT">
        <app-workflow-droppable-name-field
          [field]="field"
        ></app-workflow-droppable-name-field>
      </ng-template>

      <!-- EMAIL -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_EMAIL_TEXT">
        <app-workflow-droppable-email-field
          [field]="field"
        ></app-workflow-droppable-email-field>
      </ng-template>

      <!-- TEXT -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT">
        <app-workflow-droppable-text-field
          [field]="field"
        ></app-workflow-droppable-text-field>
      </ng-template>

      <!-- VISIBLE SIGNATURES (Digital, Eletronic, InPerson) -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE">
        <app-workflow-droppable-visible-signature-field
          [field]="field"
          (click)="spread(spreadClickTimes)"
        ></app-workflow-droppable-visible-signature-field>
      </ng-template>

      <!-- QR CODE -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.QR_CODE">
        <app-workflow-droppable-qr-code-field
          [field]="field"
        ></app-workflow-droppable-qr-code-field>
      </ng-template>

      <!-- SHARED QR CODE -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.QR_CODE_SHARE">
        <app-workflow-droppable-shared-qr-code-field
          [field]="field"
        ></app-workflow-droppable-shared-qr-code-field>
      </ng-template>

      <!-- SIGN DATE -->
      <ng-template
        [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT"
      >
        <app-workflow-droppable-sign-date-field
          [field]="field"
        ></app-workflow-droppable-sign-date-field>
      </ng-template>

      <!-- VISIBLE INLINE ELETRONIC SIGNATURE -->
      <ng-template
        [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE"
      >
        <app-workflow-droppable-inline-signature-field
          [field]="field"
        ></app-workflow-droppable-inline-signature-field>
      </ng-template>

      <!-- RUBRIC SIGNATURE -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_RUBRIC">
        <app-workflow-droppable-rubric-field
          [field]="field"
          (spreadFieldAllPages)="spread($event)"
        ></app-workflow-droppable-rubric-field>
      </ng-template>

      <!-- VISIBLE SIGNATURES (Stamp) -->
      <ng-template [ngSwitchCase]="WORKFLOW_STEP_FIELDS.TYPE_STAMP">
        <app-workflow-stamp-signature-field
          [field]="field"
          (spreadFieldAllPages)="spread($event)"
        ></app-workflow-stamp-signature-field>
      </ng-template>
    </div>
  </div>
</div>
