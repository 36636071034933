import { environment } from '@env/environment';
import { WorkflowFilePage } from './workflow-file-page.class';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { IWorkflowDroppedField } from './workflow-dropped-fields/workflow-dropped-field.interface';
import {
  generateFileBase64,
  generateFileSha1,
  generateRandomString
} from '../helpers';

/**
 * A single file build instruction when building the workflow
 */
export class WorkflowFile {
  private file: Blob;
  private idFile: number;
  private idFolder: number = 0;
  private name: string = '';
  private dueDate: moment.Moment;
  private base64Content: string = '';
  private sha1Sum: string = '';
  private downloadURL: string = '';

  private currentPage: WorkflowFilePage;
  private pages: Map<number, WorkflowFilePage>;

  private pageObserver: Subject<WorkflowFilePage>;

  constructor(file?: Blob) {
    this.file = file;
    this.pages = new Map<number, WorkflowFilePage>();
    this.pageObserver = new Subject<WorkflowFilePage>();
  }

  public static MakeWorkflowFile(file: File): WorkflowFile {
    return new WorkflowFile(file);
  }

  public static async MakeWorkflowFileFromURL(
    url: string
  ): Promise<WorkflowFile> {
    return new Promise<WorkflowFile>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.open('GET', url);
      xhr.send();
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
          return;
        }

        if (xhr.status === 200) {
          const workflowFile = WorkflowFile.MakeWorkflowFile(null);
          workflowFile.setDownloadURL(url);

          WorkflowFile.GenerateBase64AndSha1OfFile(xhr.response as Blob)
            .then(([base64, sha1]) => {
              workflowFile.setBase64(base64);
              workflowFile.setSha1(sha1);
              resolve(workflowFile);
            })
            .catch(reject);
        } else {
          reject({
            error: xhr.statusText,
            code: xhr.status,
            response: xhr.response
          });
        }
      };
    });
  }

  public static async GenerateBase64AndSha1OfFile(
    file: Blob
  ): Promise<[string, string]> {
    return Promise.all([generateFileBase64(file), generateFileSha1(file)]);
  }

  public setFolderId(folderId: number): void {
    this.idFolder = folderId;
  }

  public getFolderId(): number {
    return this.idFolder;
  }

  public setIdFile(id: number): void {
    this.idFile = id;
  }

  public getIdFile(): number {
    return this.idFile;
  }

  public setName(name: string): void {
    if (!name) {
      this.name = generateRandomString() + '.pdf';
      return;
    }

    if (!name.includes('.')) {
      this.name = `${name}.pdf`;
      return;
    }

    if (!name.includes('.pdf')) {
      const fileName = name.split('.');
      this.name = fileName[0].toString() + '.pdf';
      return;
    }

    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setDueDate(dueDate: string): void {
    this.dueDate = moment(dueDate);
  }

  public getDueDate(): string {
    if (!this.dueDate) {
      return '';
    }

    return this.dueDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }

  public setBase64(base64: string): void {
    this.base64Content = base64;
  }

  public getBase64(): string {
    return this.base64Content;
  }

  public setSha1(sha1: string): void {
    this.sha1Sum = sha1;
  }

  public getSha1(): string {
    return this.sha1Sum;
  }

  public getFile(): Blob {
    return this.file;
  }

  public setDownloadURL(endpoint: string, uuid?: string) {
    if (uuid) {
      const apiURL = environment.apiURL + environment.apiVersion;
      this.downloadURL = `${apiURL}${endpoint}?q=${uuid}`;
    } else {
      this.downloadURL = endpoint;
    }
  }

  public getDownloadURL() {
    return this.downloadURL;
  }

  public getCurrentPage(): WorkflowFilePage {
    return this.currentPage;
  }

  public setCurrentPage(pageNumber: number): void {
    this.currentPage = this.getPage(pageNumber);
    this.pushUpdateOfChangedPage();
  }

  public observePageChange(): Observable<WorkflowFilePage> {
    return this.pageObserver.asObservable();
  }

  public getPages(): WorkflowFilePage[] {
    const pages: WorkflowFilePage[] = [];
    this.pages.forEach(page => pages.push(page));
    return pages;
  }

  public getPage(pageNumber: number): WorkflowFilePage {
    let page = this.pages.get(pageNumber);

    if (!page) {
      page = new WorkflowFilePage(pageNumber);
      this.pages.set(pageNumber, page);
    }

    return page;
  }

  public getAllFields(): IWorkflowDroppedField[] {
    const fields: IWorkflowDroppedField[] = [];
    for (const page of this.getPages()) {
      for (const field of page.getFields()) {
        fields.push(field);
      }
    }
    return fields;
  }

  public pushUpdateOfChangedPage(): void {
    this.pageObserver.next(this.currentPage);
  }
}
