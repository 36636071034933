<ng-template #content let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="true"
    [showClose]="false"
    [btnActionCancel]="'Cancelar'"
    [btnActionSave]="'Salvar'"
    [title]="'Substituir contador'"
    [disableBtnSave]="!nextItem"
  >
    <p><strong>Contador selecionado:</strong> {{ selectedItem.title }}.</p>
    <p>Selecione abaixo o novo contador:</p>

    <select
      class="form-control form-control-sm"
      [(ngModel)]="nextItem"
      name="nextItem"
    >
      <option *ngFor="let item of unavailable" [value]="item.title">
        {{ item.title }}
      </option>
    </select>
  </app-modal-action>
</ng-template>

<div
  cdkDropList
  cdkDropListOrientation="horizontal"
  cdkDragLockAxis="x"
  class="statistics-list row"
  [ngClass]="{ nowrap: nowrap }"
  (cdkDropListDropped)="drop($event)"
  *ngIf="canEdit"
>
  <ng-container *ngFor="let item of available; let i = index">
    <div class="col-6 col-md-4">
      <div
        cdkDrag
        class="box"
        cdkDragBoundary=".statistics-list"
        *ngIf="showHidden || item.isVisible"
      >
        <div class="box-content">
          <div class="box-action">
            <div class="d-inline-block" ngbDropdown #ngDrop="ngbDropdown">
              <button
                ngbDropdownAnchor
                class="dropdown-action"
                [id]="'dropdownSelectStatistics-' + i"
                (focus)="ngDrop.open()"
              >
                <i class="fa fa-ellipsis-v"></i>
              </button>
              <div
                ngbDropdownMenu
                [attr.aria-labelledby]="'dropdownSelectStatistics-' + i"
              >
                <button
                  class="dropdown-item dropdown-options"
                  (click)="open(content, item)"
                >
                  Substituir
                </button>
              </div>
            </div>
          </div>

          <p class="title">{{ item.title }}</p>
          <p class="number" [ngStyle]="{ color: item.color }">
            {{ item.value }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="statistics-list row" *ngIf="!canEdit">
  <ng-container *ngFor="let item of available">
    <div class="col-4">
      <div
        class="box"
        *ngIf="showHidden || item.isVisible"
        (click)="filter(item.filter)"
      >
        <div class="box-content">
          <p class="title">{{ item.title }}</p>

          <ng-container *ngIf="hasDocumentTotal(item)">
            <p class="number" [ngStyle]="{ color: item.color }">
              {{ item.value }}
            </p>
          </ng-container>

          <ng-container *ngIf="!hasDocumentTotal(item)">
            <p class="number" [ngStyle]="{ color: item.color }">
              <i class="fas fa-circle-notch fa-spin"></i>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
