import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { WorkflowFile } from '@app/core/class';
import {
  PDFDocumentProxy,
  PDFProgressData,
  PDFPageProxy
} from 'ng2-pdf-viewer';
import { WorkflowBuildService, WORKFLOW_STEP_FIELDS } from '@app/core/services';

import { WorkflowDroppableFieldsZoneComponent } from './droppable-fields-zone';
import { PdfFile } from '@app/core/models';

@Component({
  selector: 'app-workflow-document',
  templateUrl: './workflow-document.component.html',
  styleUrls: ['./workflow-document.component.scss']
})
export class WorkflowDocumentComponent implements OnInit, OnChanges {
  WORKFLOW_STEP_FIELDS = WORKFLOW_STEP_FIELDS;

  /**
   * template of modal for edit recipient
   */
  @ViewChild('modalLoading')
  public loading: ElementRef;

  @ViewChild(WorkflowDroppableFieldsZoneComponent)
  public worfklowDropZoneComponent!: WorkflowDroppableFieldsZoneComponent;

  /**
   * Navigation of pages
   *
   * @uses (top | bottom | both)
   */
  @Input()
  public navigation?: string = 'bottom';

  @Input()
  public showUpload?: boolean = true;

  /**
   * Page location component subscribe
   */
  @Input() public recipients?: Object[];

  public pageSign: number = -1;

  public pagesToSign: number[] = [];

  /**
   * Show toobar functions of document
   */
  @Input()
  public showToolbar?: boolean = false;

  @Input()
  public fileUrl?: string = '';

  // Older code retrocompatibility
  @Output()
  public pageSize? = new EventEmitter<any>();

  // Older code retrocompatibility
  @Output()
  public pageChange? = new EventEmitter<any>();

  public page: number = 1;
  public isLoaded: boolean = false;
  public isRendering: boolean = false;
  public renderProgress: number = 0;

  public file: WorkflowFile = null;
  public pdf: PDFDocumentProxy;

  private workflowBuildService: WorkflowBuildService;

  private viewPortParameters: PdfFile.ViewportParameters = { scale: 1 };

  constructor(workflowBuildService: WorkflowBuildService) {
    this.workflowBuildService = workflowBuildService;
  }

  public ngOnInit() {
    this.file = this.workflowBuildService.getVisibleFile();
    this.defineCurrentPageToShow();

    this.workflowBuildService.observeVisibleFile().subscribe(visibleFile => {
      this.isRendering = true;
      this.renderProgress = 0;
      this.file = visibleFile;

      this.defineCurrentPageToShow();

      if (!this.file) {
        this.isLoaded = false;
        this.isRendering = false;
      }
    });
    this.filterPagesToSign();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('fileUrl')) {
      // If a workflow file was not provided but a link instead, we flag
      // the rendering process when the link changes
      if (changes.fileUrl.previousValue !== changes.fileUrl.currentValue) {
        this.isRendering = true;
      }
      return;
    }
  }

  public hasFileReady(): boolean {
    if (this.fileUrl) {
      return true;
    }

    return !!this.file && !!this.pdf && !this.isRendering;
  }

  public hasFiles(): boolean {
    return this.workflowBuildService.getFiles().length > 0;
  }

  public getFileURL(): string {
    if (this.fileUrl) {
      return this.fileUrl;
    }

    if (!this.file) {
      return '';
    }

    return this.file.getDownloadURL() || this.file.getBase64();
  }

  public getPDFTotalPages(): number {
    if (!this.pdf) {
      return 0;
    }

    return this.pdf.numPages;
  }

  /**
   * Calls after document loaded
   *
   * @param (any) pdfdata config of document
   */
  public afterLoadComplete(pdfData: PDFDocumentProxy): void {
    this.isLoaded = true;
    this.pdf = pdfData;

    // Load previous set page or the first one
    let loadPageNumber = 1;
    if (this.file) {
      const currentPage = this.file.getCurrentPage();
      if (currentPage) {
        loadPageNumber = currentPage.getPageNumber();
      }
    }
    this.changePage(loadPageNumber);
  }

  public onProgress(progressData: PDFProgressData): void {
    this.renderProgress = progressData.total;
  }

  public onPageRendered(e: any): void {
    this.isRendering = false;

    if (this.file) {
      this.file.setCurrentPage(this.page);
      this.calcProportion();
    }

    // Older code retrocompatibility
    this.pdf.getPage(this.page).then((p: PDFPageProxy) => {
      const viewport = p.getViewport(this.viewPortParameters);

      this.pageSize.emit({
        width: viewport.width,
        height: viewport.height,
        pageNumber: this.page
      });
    });
  }

  /**
   * Get total page
   */
  public getTotalPage(): number {
    return this.pagesToSign.length;
  }

  /**
   * Filter Pages To Sign
   */
  public filterPagesToSign(): void {
    if (this.recipients) {
      this.recipients.forEach((item: any) => {
        for (const fields of item.fields) {
          if (fields.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE) {
            this.pagesToSign.push(fields.page);
          }
        }
      });

      this.pagesToSign = this.pagesToSign.filter((el, i) => {
        return this.pagesToSign.indexOf(el) === i;
      });
    }
  }

  public changeToPageExpectingSignature(nPage: number): void {
    this.changePage(this.pagesToSign[nPage]);
  }

  public changePage(pageNumber: number): void {
    if (pageNumber < 1) {
      return;
    }

    if (pageNumber > this.getPDFTotalPages()) {
      return;
    }

    if (this.file) {
      this.file.setCurrentPage(pageNumber);
      this.calcProportion();
    }

    this.page = pageNumber;

    // Older code retrocompatibility
    this.pageChange.emit({
      page: pageNumber,
      total: this.getPDFTotalPages()
    });
  }

  /**
   * Print file
   */
  public printFile() {
    const win = window.open(this.getFileURL(), '_blank');

    setTimeout(() => {
      win.print();
    }, 0);
  }

  private defineCurrentPageToShow(): void {
    if (!this.file) {
      this.page = 1;
      return;
    }

    const currentPage = this.file.getCurrentPage();

    if (!currentPage) {
      this.page = 1;
      return;
    }

    this.page = currentPage.getPageNumber();
  }

  private async calcProportion(): Promise<void> {
    const pageSize = await this.getPDFPageSize();
    const dropzoneSize = this.getDropzoneSize();

    const workflowFilePage = this.file.getCurrentPage();
    workflowFilePage.calcPageViewportProportion(
      dropzoneSize.width,
      pageSize.width
    );
  }

  private getDropzoneSize(): { width: number; height: number } {
    const dropzoneElement: Element = this.worfklowDropZoneComponent.dropzoneElem
      .nativeElement;

    return {
      width: dropzoneElement.clientWidth,
      height: dropzoneElement.clientHeight
    };
  }

  private async getPDFPageSize(): Promise<{ width: number; height: number }> {
    const pdfPage = await this.pdf.getPage(this.page);
    const pageViewport = pdfPage.getViewport(this.viewPortParameters);

    return {
      width: pageViewport.width,
      height: pageViewport.height
    };
  }
}
