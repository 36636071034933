import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Folder } from '@app/core/models';
import { FolderService } from '@app/core/services';
import { Cancelable, debounce } from 'lodash';

@Component({
  selector: 'app-move-folder',
  templateUrl: './move-folder.component.html',
  styleUrls: ['./move-folder.component.scss']
})
export class MoveFolderComponent implements OnInit {
  /**
   * object of modal
   */
  @Input() modal: any;

  public queryField = new FormControl();
  public searchDebounce: (() => void) & Cancelable;
  public folders: Folder.Folder[];
  public selectedFolder: object;
  public isEnabled: boolean = false;
  public spinner: boolean = false;

  /**
   * default value for sort fields
   */
  public sortFields: any;

  /**
   * config list with fields
   */
  public configList: Array<any>;

  constructor(private folderService: FolderService) {
    this.searchDebounce = debounce(this.getFolder.bind(this), 300);
  }

  public ngOnInit(): void {
    this.sortFields = { name: 'ASC', email: 'ASC', statusText: 'ASC' };
    this.configList = [
      {
        name: 'ID',
        klass: '',
        type: 'text',
        fieldId: 'id',
        width: '20%',
        isSortable: false
      },
      {
        name: 'Nome da pasta',
        type: 'text',
        fieldId: 'name',
        isSortable: false
      }
    ];
    this.getFolder();
  }

  public isSpinner(): boolean {
    return this.spinner;
  }

  public changeSpinner(): void {
    this.spinner = !this.spinner;
  }

  public getSelectedFolde(event: any): void {
    this.selectedFolder = {
      id: event.id,
      name: event.name
    };
    this.isEnabled = true;
  }

  public moveDocument(): void {
    this.modal.close({
      action: 'save',
      form: 'moveDocument',
      formData: this.selectedFolder
    });
  }

  public shouldEnabledButton(): boolean {
    return !this.isEnabled;
  }

  public getFolder(): void {
    const filter: Array<object> = [];

    if (this.queryField.value) {
      if (!isNaN(parseInt(this.queryField.value))) {
        filter.push({
          type: 'eq',
          field: 'id',
          value: this.queryField.value
        });
      } else {
        filter.push({
          type: 'like',
          field: 'name',
          value: `%${this.queryField.value}%`
        });
      }

      const queryStringFilter = {
        filter
      };

      this.fetchFolders(queryStringFilter);
    } else {
      const queryStringFilter = {
        pageSize: '1000'
      };

      this.fetchFolders(queryStringFilter);
    }
  }

  private fetchFolders(querystring: object): void {
    this.folderService
      .searchFolder(querystring)
      .subscribe((body: Folder.FolderCollection) => {
        this.folders = body.items;
        this.changeSpinner();
      });
  }
}
