/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remaining-user-balance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./remaining-user-balance.component";
import * as i3 from "../../../core/services/remaining-user-balance.service";
var styles_RemainingUserBalanceComponent = [i0.styles];
var RenderType_RemainingUserBalanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemainingUserBalanceComponent, data: {} });
export { RenderType_RemainingUserBalanceComponent as RenderType_RemainingUserBalanceComponent };
export function View_RemainingUserBalanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row balance"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-2 col-md-1 col-lg-5 balance-cred"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-9 col-md-3 col-lg-5 balance-word"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cr\u00E9ditos Restantes"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.planInfo == null) ? null : _co.planInfo.globalSignaturesRemainments); _ck(_v, 5, 0, currVal_0); }); }
export function View_RemainingUserBalanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remaining-user-balance", [], null, null, null, View_RemainingUserBalanceComponent_0, RenderType_RemainingUserBalanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.RemainingUserBalanceComponent, [i3.RemainingUserBalanceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemainingUserBalanceComponentNgFactory = i1.ɵccf("app-remaining-user-balance", i2.RemainingUserBalanceComponent, View_RemainingUserBalanceComponent_Host_0, {}, {}, []);
export { RemainingUserBalanceComponentNgFactory as RemainingUserBalanceComponentNgFactory };
