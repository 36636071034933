/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./feedback.component";
var styles_FeedbackComponent = [i0.styles];
var RenderType_FeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackComponent, data: {} });
export { RenderType_FeedbackComponent as RenderType_FeedbackComponent };
function View_FeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["id", "feedbackComponent"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "feedback", _co.theme); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.message)); _ck(_v, 13, 0, currVal_1); }); }
export function View_FeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback", [], null, null, null, View_FeedbackComponent_0, RenderType_FeedbackComponent)), i1.ɵdid(1, 573440, null, 0, i4.FeedbackComponent, [], null, null)], null, null); }
var FeedbackComponentNgFactory = i1.ɵccf("app-feedback", i4.FeedbackComponent, View_FeedbackComponent_Host_0, { theme: "theme", message: "message" }, {}, []);
export { FeedbackComponentNgFactory as FeedbackComponentNgFactory };
