import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { AlertSubscription } from '@app/core/subscriptions';

@Component({
  selector: 'app-manage-page',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManagePageComponent implements OnInit, OnDestroy {
  /**
   * array to fill the menu of navigation-lists component
   */
  pages: any;

  /**
   * Subscriber
   */
  subscriber = new Subject();

  /**
   * Feedback message
   */
  feedback: any = { message: undefined, type: undefined };

  constructor(private alertSubscription: AlertSubscription) {
    this.pages = [
      {
        name: 'Assinatura Presencial',
        link: '/manager/manage/signature-in-person'
      },
      { name: 'Recebidos', link: '/manager/manage/workflow' },
      { name: 'Enviados', link: '/manager/manage/workflow/me' },
      { name: 'Modelos', link: '/manager/manage/templates' },
      { name: 'Contatos', link: '/manager/manage/contacts' },
      { name: 'Pastas', link: '/manager/manage/folders' },
      { name: 'Relatório', link: '/manager/manage/workflow-report' }
    ];
  }

  ngOnInit() {
    this.alertSubscription
      .getAlerts()
      .pipe(takeUntil(this.subscriber))
      .subscribe((response: any) => {
        this.feedback = { message: '', type: response.type };
        setTimeout(() => (this.feedback = response), 1000);
      });
  }

  ngOnDestroy() {
    this.subscriber.next();
    this.subscriber.complete();
  }
}
