import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS, WORKFLOW_SIGNATURE } from '@app/core/services/workflow.enum';
var StampSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(StampSignatureField, _super);
    function StampSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_STAMP;
        _this.signatureType = WORKFLOW_SIGNATURE.STAMP;
        _this.minHeight = _this.height = 40;
        _this.minWidth = _this.width = 140;
        return _this;
    }
    StampSignatureField.prototype.isSignature = function () {
        return true;
    };
    StampSignatureField.prototype.shouldKeepAspectRatio = function () {
        return true;
    };
    return StampSignatureField;
}(WorkflowDroppedField));
export { StampSignatureField };
