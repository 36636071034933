import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var EmailField = /** @class */ (function (_super) {
    tslib_1.__extends(EmailField, _super);
    function EmailField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_EMAIL_TEXT;
        _this.minHeight = _this.height = 25;
        _this.minWidth = _this.width = 150;
        return _this;
    }
    return EmailField;
}(WorkflowDroppedField));
export { EmailField };
