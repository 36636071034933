import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { Subject, from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { humanizeBytes } from 'ngx-uploader';
import { Logger, WorkflowFile } from '@app/core/class';
import { UploadService, WorkflowBuildService } from '@app/core/services';
var log = new Logger('UploadComponent');
var UploadComponent = /** @class */ (function () {
    function UploadComponent(modalService, uploadService, workflowBuildService) {
        this.modalService = modalService;
        this.uploadService = uploadService;
        this.workflowBuildService = workflowBuildService;
        /**
         * text of button
         */
        this.buttonText = 'Upload';
        /**
         * classes of button
         */
        this.buttonClass = 'btn-sm';
        /**
         * drag'n drop text
         */
        this.dragDropText = 'Arraste e solte os documentos PDF de no máximo 25MB aqui para começar';
        /**
         * show loading
         */
        this.showRealtimeLoading = false;
        /**
         * Open modal for status
         */
        this.showModalStatus = false;
        /**
         * Type of container
         */
        this.layout = 'default';
        /**
         * Should show only the button?
         */
        this.raw = false;
        /**
         * Allowed mimetypes
         */
        this.allowedMimetypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/jpeg',
            'image/png',
            'image/bmp'
        ];
        /**
         * Allowed extensions
         */
        this.allowedExtensions = [
            '.pdf',
            '.doc',
            '.docx',
            '.jpeg',
            '.jpg',
            '.png',
            '.bmp'
        ];
        /**
         * Extension text
         */
        this.extensionText = 'pdf, doc, docx, jpeg, jpg, png, bmp';
        /**
         * Informs that the files are ready to be processed
         */
        this.processFiles = new EventEmitter();
        this.processFile = new EventEmitter();
        /**
         * Informs that the upload of a document has started
         */
        this.uploadStarted = new EventEmitter();
        /**
         * Print a error of upload
         */
        this.error = false;
        /**
         * Subscriber
         */
        this.subscriber = new Subject();
        this.hasDocumentRejected = false;
        this.options = {
            concurrency: 10,
            maxUploads: 10
        };
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
        this.modalRef = {
            ref: null,
            data: { text: '', showClose: false, title: '' }
        };
    }
    /**
     * Processes files, performs status and queue management
     *
     * @param (UploadOutput) output data of each file
     * @return void
     */
    UploadComponent.prototype.onUploadOutput = function (output) {
        var _this = this;
        if (output.file && !this.isFileSupported(output.file)) {
            output.type = 'rejected';
        }
        switch (output.type) {
            case 'allAddedToQueue':
                if (!this.hasDocumentRejected) {
                    setTimeout(function () {
                        _this.modalRef.data.title = '';
                        _this.modalRef.data.text =
                            'Preparando seu(s) documento(s) para análise';
                        _this.modalRef.ref = _this.open(_this.loading);
                    });
                    var length_1 = this.uploadService.getFileQueue().length;
                    this.uploadStarted.emit(length_1);
                    this.doUpload();
                }
                this.hasDocumentRejected = false;
                break;
            case 'addedToQueue':
                this.uploadService.addFileToQueue(output.file);
                break;
            case 'uploading':
                if (!output.file) {
                    break;
                }
                if (this.showModalStatus) {
                    var name_1 = output.file.name;
                    var percentage = output.file.progress.data.percentage;
                    this.modalRef.data.text = "Efetuando upload de: " + name_1 + " (" + percentage + "%)";
                }
                break;
            case 'removed':
                this.uploadService.removeFileFromQueue(output.file);
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'cancelled':
                log.debug("drop:", output);
                this.uploadService.removeFileFromQueue(output.file);
                break;
            case 'rejected':
                this.showUnsupportedFileFormatModal();
                break;
            case 'done':
                var status_1 = output.file.responseStatus;
                if (status_1 !== 201) {
                    this.uploadService.abortAll(this.uploadInput);
                    var message = output.file.response.detail;
                    if (this.modalRef.ref) {
                        this.modalRef.ref.close();
                    }
                    alert("Ops! Ocorreu um problema! [" + status_1 + "]\n" + message);
                    break;
                }
                var subs_1 = from(this.addWorkflowFile(output.file)).subscribe(function () {
                    if (!!subs_1) {
                        subs_1.unsubscribe();
                    }
                    if (_this.uploadService.hasMoreFiles()) {
                        // Chama de novo até esvaziar a fila de arquivos
                        _this.doUpload();
                    }
                    else {
                        if (_this.modalRef.ref) {
                            _this.modalRef.ref.close();
                        }
                        _this.uploadService.emptyQueue();
                        _this.processFiles.emit(null);
                        // Set the last uploaded file as visible
                        var workflowFile = _this.workflowBuildService.findFile(output.file.nativeFile);
                        _this.workflowBuildService.setFileAsVisible(workflowFile);
                    }
                    _this.processFile.emit(output);
                });
                break;
        }
        if (this.showModalStatus && this.modalRef.ref) {
            this.modalRef.ref.result.then(function (data) {
                log.debug("onModal (close):", data);
                _this.modalRef.ref = undefined;
            }, function (reason) {
                _this.modalRef.ref = undefined;
            });
        }
    };
    UploadComponent.prototype.doUpload = function () {
        var _this = this;
        try {
            this.uploadService.uploadFilesOnQueue(this.uploadInput);
        }
        catch (error) {
            setTimeout(function () {
                _this.modalRef.data.title = 'Ops! Ocorreu um erro';
                _this.modalRef.data.text = error.message;
                _this.modalRef.data.showClose = true;
                _this.modalRef.data.showReloadButton = true;
            });
        }
    };
    /**
     * Cancel and remove a file of queue
     *
     * @param (string) id of file
     * @return void
     */
    UploadComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    UploadComponent.prototype.ngOnDestroy = function () {
        this.subscriber.next();
        this.subscriber.complete();
    };
    UploadComponent.prototype.acceptedExtensions = function () {
        if (Array.isArray(this.allowedExtensions)) {
            return this.allowedExtensions.join(',');
        }
        return '';
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    UploadComponent.prototype.open = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    UploadComponent.prototype.extractExtensionFromFilename = function (filename) {
        if (!filename) {
            return '';
        }
        var extension = filename.split('.').pop();
        if (extension === filename) {
            return '';
        }
        return extension;
    };
    UploadComponent.prototype.isFileSupported = function (file) {
        if (!file.type) {
            var extension = this.extractExtensionFromFilename(file.name);
            return this.isExtensionSupported("." + extension);
        }
        return this.isMimetypeSupported(file.type);
    };
    UploadComponent.prototype.isMimetypeSupported = function (mimetype) {
        return this.allowedMimetypes.includes(mimetype);
    };
    UploadComponent.prototype.isExtensionSupported = function (extension) {
        if (!extension) {
            return false;
        }
        return this.allowedExtensions.includes(extension);
    };
    UploadComponent.prototype.showUnsupportedFileFormatModal = function () {
        this.hasDocumentRejected = true;
        this.uploadService.abortAll(this.uploadInput);
        if (!this.showModalStatus) {
            this.error = true;
            return;
        }
        this.modalRef.data.title = 'Este formato de arquivo não é suportado.';
        this.modalRef.data.text = "Selecione arquivos com extens\u00E3o em " + this.extensionText.toUpperCase() + " e tente novamente.";
        this.modalRef.data.showClose = true;
        this.modalRef.data.showReloadButton = true;
        this.modalRef.ref = this.open(this.loading);
    };
    UploadComponent.prototype.addWorkflowFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workflowFile, defaultFolderId, _a, base64, sha1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        workflowFile = WorkflowFile.MakeWorkflowFile(file.nativeFile);
                        workflowFile.setDownloadURL(this.uploadService.getEndpoint(), file.response.uuid);
                        workflowFile.setIdFile(file.response.id);
                        workflowFile.setName(file.nativeFile.name);
                        defaultFolderId = this.workflowBuildService.getDefaultFolderId();
                        workflowFile.setFolderId(defaultFolderId);
                        return [4 /*yield*/, WorkflowFile.GenerateBase64AndSha1OfFile(file.nativeFile)];
                    case 1:
                        _a = _b.sent(), base64 = _a[0], sha1 = _a[1];
                        workflowFile.setBase64(base64);
                        workflowFile.setSha1(sha1);
                        this.workflowBuildService.addFile(workflowFile);
                        return [2 /*return*/];
                }
            });
        });
    };
    return UploadComponent;
}());
export { UploadComponent };
