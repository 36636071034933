import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WorkflowContact } from '@app/core/class';
import {
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_SIGNATURE
} from '@app/core/services/workflow.enum';

export class EletronicSignatureField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
    this.signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
    this.minHeight = this.height = 46;
    this.minWidth = this.width = 200;
  }

  public isSignature(): boolean {
    return true;
  }

  public shouldKeepAspectRatio(): boolean {
    return true;
  }
}
