import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-donnut-chart',
  templateUrl: './donnut-chart.component.html',
  styleUrls: ['./donnut-chart.component.scss']
})
export class DonnutChartComponent implements OnInit, OnChanges {
  /**
   * Provides data that will render the chart
   */
  @Input() data: any;

  /**
   * Provides the string showed inside the donnut
   */
  title = 'Total de 0';

  slices: any[] = [
    {
      value: 0,
      color: '#158878',
      legend: 'Enviados',
      status: 1
    },
    {
      value: 0,
      color: '#34b8a5',
      legend: 'Finalizados',
      status: 4
    }
  ];

  size = 200;

  innerRadius = 70;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const data = changes.data.currentValue;
    this.slices[0].value = data ? data[0] : 0;
    this.slices[1].value = data ? data[2] : 0;
    const value: number = this.slices.reduce(
      (total: number, element: any) => total + element.value,
      0
    );
    this._updateTitle(value);
  }

  /**
   * Update the donnut inner message
   *
   * @param (number) value to be used to build the message of the donnut
   */
  _updateTitle(value: number) {
    this.title = `Total de ${value}`;
  }
}
