import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SIGNATURE_TYPE } from '@app/core/services/workflow.enum';
var VisibleSignatureComponent = /** @class */ (function () {
    function VisibleSignatureComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        /**
         * Text of button save
         */
        this.btnSaveText = 'Salvar';
        /**
         * Text of button save
         */
        this.showInfoText = true;
        this.showSignature = 'choose';
        this.createForm();
    }
    VisibleSignatureComponent.prototype.ngOnInit = function () {
        if (this.checkRubricType()) {
            if (this.data && this.data.name) {
                this.signatureForm.controls['name'].setValue(this.data.name.substr(0, 2));
                this.fullName = this.data.name.substr(0, 2);
            }
            this.showInfoText = false;
            return;
        }
        if (this.data && this.data.name) {
            this.signatureForm.controls['name'].setValue(this.data.name.substr(0, 30));
            this.fullName = this.data.name;
        }
        if (this.checkStampType()) {
            this.showSignature = 'upload';
        }
    };
    VisibleSignatureComponent.prototype.numberColor = function (value) {
        if (value < 15) {
            return 'success';
        }
        if (value < 27) {
            return 'warning';
        }
        return 'danger';
    };
    /**
     * receives event with subscription
     */
    VisibleSignatureComponent.prototype.typeSignature = function (event) {
        this.dataSign = event;
        this.signatureForm.controls['type'].setValue(this.dataSign['dataSignature'].type);
    };
    VisibleSignatureComponent.prototype.doSubmit = function () {
        if (this.checkRubricType()) {
            this.signatureForm.setValue({
                name: this.f.name.value,
                font: this.dataSign['dataSignature'].sign,
                type: SIGNATURE_TYPE.RUBRIC
            });
        }
        if (this.checkStampType()) {
            this.signatureForm.setValue({
                name: this.dataSign['dataSignature'].sign,
                font: 'Arial.ttf',
                type: SIGNATURE_TYPE.STAMP
            });
        }
        if (this.signatureForm.get('type').value === SIGNATURE_TYPE.GENERATING) {
            this.signatureForm.get('name').setValue(this.f.name.value);
            this.signatureForm
                .get('font')
                .setValue(this.dataSign['dataSignature'].sign);
        }
        if (!this.checkRubricType() &&
            !this.checkStampType() &&
            this.signatureForm.get('type').value !== SIGNATURE_TYPE.GENERATING) {
            this.signatureForm.controls['name'].setValue(this.dataSign['dataSignature'].sign);
        }
        this.modal.close({
            action: 'save',
            form: 'signature',
            formData: this.signatureForm.value
        });
    };
    VisibleSignatureComponent.prototype.checkRubricType = function () {
        return this.typeOfSignature.sign === SIGNATURE_TYPE.RUBRIC;
    };
    VisibleSignatureComponent.prototype.checkStampType = function () {
        return this.typeOfSignature.sign === SIGNATURE_TYPE.STAMP;
    };
    VisibleSignatureComponent.prototype.isValidateFeedback = function () {
        if (this.f.name.errors && this.f.name.errors.maxlength) {
            return (!this.f.name.errors.maxlength.actualLength >
                this.f.name.errors.maxlength.equiredLength);
        }
        return true;
    };
    Object.defineProperty(VisibleSignatureComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.signatureForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    VisibleSignatureComponent.prototype.createForm = function () {
        this.signatureForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(30)
                ])
            ],
            font: [''],
            type: ['', Validators.required]
        });
    };
    return VisibleSignatureComponent;
}());
export { VisibleSignatureComponent };
