import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntegrationRoutingPath } from './integration-routing.path';

import { WorkflowPageComponent } from './pages';
import { Page404Component } from '@app/shared';
import { IntegrationComponent } from './integration.component';
import { IntegrationErrorPageComponent } from './pages/workflow/error/error.component';
import { IntegrationBuildPageComponent } from './pages/workflow/build/build.component';
import { IntegrationReviewPageComponent } from './pages/workflow/review/review.component';
import { IntegrationFinishPageComponent } from './pages/workflow/finish/finish.component';

const routes: Routes = [
  {
    path: IntegrationRoutingPath.INTEGRATION_HOME,
    component: IntegrationComponent,
    data: { reuse: true },
    children: [
      {
        path: IntegrationRoutingPath.INTEGRATION_WORKFLOW,
        component: WorkflowPageComponent,
        children: [
          {
            path: '',
            component: IntegrationBuildPageComponent
          },
          {
            path: IntegrationRoutingPath.INTEGRATION_WORKFLOW_REVIEW,
            component: IntegrationReviewPageComponent
          },
          {
            path: IntegrationRoutingPath.INTEGRATION_WORKFLOW_FINISH,
            component: IntegrationFinishPageComponent
          },
          {
            path: IntegrationRoutingPath.INTEGRATION_WORKFLOW_ERROR,
            component: IntegrationErrorPageComponent
          }
        ]
      },
      {
        path: '**',
        component: Page404Component
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class IntegrationRoutingModule {}
