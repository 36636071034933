<div class="certificate-chooser">
  <form [formGroup]="form" (ngSubmit)="doSubmit()">
    <div class="row pl-3">
      <div class="col-12">
        <p class="description">
          Utilize suas credenciais do aplicativo BirdID para assinar com
          Certificado Digital. Saiba mais sobre o BirdID em
          <a href="https://birdid.com.br" target="_blank" class="help"
            >https://birdid.com.br</a
          >.
        </p>
      </div>
    </div>
    <div class="row texts">
      <div class="col-6">
        <input
          type="text"
          placeholder="CPF"
          formControlName="document"
          mask="000.000.000-00"
        />
      </div>
      <div class="col-6">
        <input
          type="text"
          placeholder="Código OTP"
          formControlName="otp"
          mask="AAA AAA"
        /><br />
        <a href class="help"> Tem dúvidas sobre o que é código OTP? </a>
      </div>
    </div>
    <div class="row actions">
      <div class="col-12 text-right">
        <span class="btn btn-outline-success" (click)="modal.close('cancel')">
          Cancelar
        </span>
        <button
          class="btn btn-secondary"
          type="submit"
          [disabled]="!form.valid"
        >
          Assinar
        </button>
      </div>
    </div>
  </form>
</div>
