import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { Layout } from '@app/core/models';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnChanges {
  /**
   * Provides data that will render the chart
   */
  @Input() data: any;

  /**
   * Provides filter options, placeholder and behavior
   */
  @Input() filterOptions?: Layout.ChartFilterOptions;

  /**
   * Provide the route and name of the action
   */
  @Input() actionOption?: Layout.Options;

  /**
   * Callback of filter
   */
  @Output() filter? = new EventEmitter();

  /**
   * Callback of action in event button
   */
  @Output() actionEvent? = new EventEmitter();

  /**
   * Provides update of chart data
   */
  ngOnChanges() {
    // to do: parse data of service
    // console.log('>>> updated', this.data);
  }

  /**
   * provides a action in click button
   *
   * @param (any) action type
   * @return void
   */
  goTo(action: any) {
    this.actionEvent.emit({ type: action.type });
  }

  /**
   * Inserts the default filter value
   * @return string
   */
  get filterSelected(): string {
    return '30';
  }

  /**
   * provides the necessary management in select filter action
   *
   * @param (string) period of filter
   * @return EventEmitter
   */
  set filterSelected(period: string) {
    this.filter.emit({ value: period });
  }
}
