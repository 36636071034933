import { Injectable, EventEmitter } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { environment } from '@env/environment';
import { UploadFile, UploadInput } from 'ngx-uploader';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';
import { FILES } from './workflow.enum';

/**
 * Provides a base for upload service.
 */
@Injectable()
export class UploadService extends BaseAPI {
  FILES = FILES;

  private queueMaxSize: number = 25; // 25mb

  private currentQueueSize: number = 0;

  private fileQueue: UploadFile[] = [];

  private accessToken: string;

  constructor(
    protected httpClient: HttpClient,
    protected authorizationService: AuthorizationService
  ) {
    super(httpClient);
    this.endpoint = '/file';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];

    this.accessToken = this.authorizationService.getAccessToken();
  }

  public getEndpoint(): string {
    return this.endpoint;
  }

  public getFileQueue(): UploadFile[] {
    return this.fileQueue;
  }

  public hasMoreFiles(): boolean {
    return this.fileQueue.length > 0;
  }

  public addFileToQueue(file: UploadFile) {
    this.currentQueueSize += file.size;
    this.fileQueue.push(file);
  }

  public removeFileFromQueue(fileToRemove: UploadFile) {
    this.fileQueue = this.fileQueue.filter((file: UploadFile) => {
      if (file === fileToRemove) {
        this.currentQueueSize -= file.size;
        return false;
      }
      return true;
    });
  }

  public emptyQueue() {
    this.fileQueue = [];
    this.currentQueueSize = 0;
  }

  public abortAll(uploadInput: EventEmitter<UploadInput>) {
    this.emptyQueue();
    uploadInput.emit({ type: 'cancelAll' });
    uploadInput.emit({ type: 'removeAll' });
  }

  public uploadFilesOnQueue(uploadInput: EventEmitter<UploadInput>) {
    const maxSize = this.queueMaxSize * 1024 * 1024; // MiB
    if (this.currentQueueSize > maxSize) {
      this.abortAll(uploadInput);
      throw new Error(
        `A soma do tamanho de todos os arquivos enviados não pode ser maior que ${
          this.queueMaxSize
        }MiB.`
      );
    }

    const currentFile = this.fileQueue.shift();

    const fileInformation = {
      description: currentFile.name,
      autoConvertToPdf: '0'
    };

    if (currentFile.type !== FILES.PDF) {
      fileInformation.autoConvertToPdf = '1';
    }

    if (currentFile) {
      let url = this.endpoint;
      if (environment.production) {
        url = environment.apiURL + environment.apiVersion + this.endpoint;
      }

      const uploadEvent: UploadInput = {
        type: 'uploadFile',
        url,
        method: 'POST',
        data: fileInformation,
        file: currentFile,
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      };

      uploadInput.emit(uploadEvent);
    }
  }
}
