import * as tslib_1 from "tslib";
import { WORKFLOW_SIGNATURE } from '@app/core/services';
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableVisibleSignatureFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableVisibleSignatureFieldComponent, _super);
    function WorkflowDroppableVisibleSignatureFieldComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.WORKFLOW_SIGNATURE = WORKFLOW_SIGNATURE;
        return _this;
    }
    return WorkflowDroppableVisibleSignatureFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableVisibleSignatureFieldComponent };
