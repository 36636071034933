import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { matchFields } from '@app/core/helpers';
var ConfirmPersonalFormComponent = /** @class */ (function () {
    function ConfirmPersonalFormComponent(fb) {
        this.fb = fb;
        this.formReady = new EventEmitter();
    }
    ConfirmPersonalFormComponent.prototype.ngOnInit = function () {
        this.dataForm = this.fb.group({
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
                ])
            ],
            repeatPassword: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
                ])
            ]
        }, { validator: matchFields('password', 'repeatPassword') });
        this.formReady.emit(this.dataForm);
    };
    Object.defineProperty(ConfirmPersonalFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.dataForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    return ConfirmPersonalFormComponent;
}());
export { ConfirmPersonalFormComponent };
