import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Document, PolicyDocument } from '@app/core/models';
import { Documents } from 'assets/policy/document';

@Component({
  selector: 'app-accept-policy',
  templateUrl: './accept-policy.component.html',
  styleUrls: ['./accept-policy.component.scss']
})
export class AcceptPolicyComponent implements OnInit {
  public termsOfUse: PolicyDocument.File;
  public privacyPolicy: PolicyDocument.File;

  public acceptPolicy = new FormControl();
  private isCollapsed = true;

  @Input() owner: Document.Owner;
  @Input() modal: any;

  constructor() {
    this.acceptPolicy.setValue(false);
    this.termsOfUse = Documents.termsOfUse();
    this.privacyPolicy = Documents.privacyPolicy();
  }

  ngOnInit(): void {}

  public getCollapsed(): boolean {
    return this.isCollapsed;
  }

  public setCollapsed(value: boolean): void {
    this.isCollapsed = value;
  }

  public checkPolicyField(): void {
    if (!this.getAcceptPolicy()) {
      this.setCollapsed(true);
      return;
    }
    this.setCollapsed(false);
  }

  public getAcceptPolicy(): boolean {
    return this.acceptPolicy.value;
  }

  public toLookDocument(): void {
    if (this.getAcceptPolicy()) {
      this.toSubmit();
      return;
    }
    this.setCollapsed(false);
  }

  private toSubmit(): void {
    this.modal.close({
      action: 'save',
      form: 'acceptPolicy',
      formData: this.getAcceptPolicy()
    });
  }
}
