import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IntegrationDocuwareService } from '@app/core/services';

@Component({
  selector: 'app-folder-form',
  templateUrl: './folder-form.component.html',
  styleUrls: ['./folder-form.component.scss']
})
export class FolderFormComponent {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of current folder
   */
  @Input() selected?: any;

  @Input() integrationWithFolder: boolean;

  private checkIntegrationWithFolder: boolean;

  /**
   * Data of Form
   */
  folderForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.checkIntegrationWithFolder = false;
    this.createForm();
  }

  /**
   * Data selection action by clicking the form submit button
   */
  public doSubmit(): void {
    const payalod = this.preseData(this.folderForm);

    this.modal.close({
      action: 'save',
      form: 'folder',
      formData: this.folderForm.value
    });
  }

  private preseData(folderForm: FormGroup): FormGroup {
    if (!this.hasIntegrationWithFolder()) {
      folderForm.setValue({
        name: folderForm.value.name,
        metadata: {
          file_cabinet_id: null
        }
      });
    }
    return folderForm;
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.folderForm.controls;
  }

  public shouldShowInfoIntegration(): boolean {
    return this.integrationWithFolder;
  }

  public infoText(): string {
    return 'Deve preencher com Id File Cabinet, ele e usado para criar a integração com a pasta docuWare';
  }

  public hasIntegrationWithFolder(): boolean {
    return this.checkIntegrationWithFolder;
  }

  public toggleIntegrationWithFolder(): void {
    this.checkIntegrationWithFolder = !this.checkIntegrationWithFolder;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm(): void {
    this.folderForm = this.formBuilder.group({
      name: ['', Validators.required],
      metadata: this.formBuilder.group({
        file_cabinet_id: [null]
      })
    });
  }
}
