<div class="p-1">
  <ng-container *ngIf="!isMobile()">
    <video
      id="video"
      autoplay
      [width]="width"
      [height]="height"
      *ngIf="!show"
    ></video>
  </ng-container>

  <img [src]="img" *ngIf="show" class="img-fluid" alt="Imagem responsiva" />
</div>

<ng-container *ngIf="!isMobile()">
  <div class="text-new-photo">
    <p *ngIf="show">
      Não gostou da foto? <span (click)="newPhoto()">Tirar nova foto</span>
    </p>

    <div class="text-right mt-5" *ngIf="!show">
      <button
        class="btn btn-secondary btn-sm"
        type="button"
        (click)="capture()"
      >
        Capturar foto
      </button>
    </div>
  </div>
</ng-container>

<canvas #canvasImgResponsive *ngIf="isMobile()"></canvas>

<ng-container *ngIf="isMobile() && !shouldShowMobileButton()">
  <label class="capture-button">
    Tirar foto
    <input
      type="file"
      (change)="captureImageMobile($event)"
      id="avatar"
      name="avatar"
      accept="image/*"
      capture
    />
  </label>
</ng-container>
