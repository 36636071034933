import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PlansService } from '@app/core/services';
import { Authentication, User } from '@app/core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  @ViewChild('modaCancelPlan') modaCancelPlan: ElementRef;
  @ViewChild('modalSuccessCancel') modalSuccessCancel: ElementRef;
  @ViewChild('modalErrorCancel') modalErrorCancel: ElementRef;
  @ViewChild('modalLoading') loading: ElementRef;

  @Input() paymentInfo: User.UserPaymentInfo;

  @Input() planInfo: User.UserPlanPayload;
  /**
   * data of the logged user
   */
  @Input() logged: Authentication.SignupUserResponse;

  public modalRef: any;

  constructor(
    private planService: PlansService,
    private modalService: NgbModal
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {}

  public cancelCurrentPlan(): void {
    this.modalRef.data.text = 'Cancelando o plano, aguarde...';
    this.modalRef.ref = this.open(this.loading);

    this.planService.cancelPlan(this.paymentInfo.id).subscribe(
      body => {
        this.modalClose();
        this.modalRef.ref = this.open(this.modalSuccessCancel, 'md');
      },
      (error: any) => {
        this.modalClose();
        this.modalRef.ref = this.open(this.modalErrorCancel, 'md');
      }
    );
  }

  public cancelPlan(): void {
    this.modalClose();
    this.cancelCurrentPlan();
  }

  public retryCancellation(): void {
    this.modalClose();
    this.cancelCurrentPlan();
  }

  public toSubmit(): void {
    this.modalRef.ref = this.open(this.modaCancelPlan, 'md');
  }

  public reloadPage(): void {
    window.location.reload();
  }

  public modalClose(): void {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }
}
