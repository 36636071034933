import { Component } from '@angular/core';
import { UserService } from '@app/core/services';

@Component({
  selector: 'app-check-sign-code-result',
  templateUrl: './code-result.component.html',
  styleUrls: ['./code-result.component.scss']
})
export class CheckSignCodeResultComponent {
  feedback: any = { message: undefined, type: undefined };

  dataFile: any = {};

  data: any = {
    id: '039383',
    name: 'TCD 202903 Ana Lúcia Marcelino Campanha',
    status: true
  };

  constructor(private userService: UserService) {}

  createDocument(response: any) {
    this.feedback = {
      message: undefined,
      type: undefined
    };

    this.dataFile = {
      id: response.files.id,
      base64: response.files.base64,
      name: response.files.name,
      nativeFile: response.files.nativeFile,
      apiData: response.files.response
    };
  }
}
