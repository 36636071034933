<div class="page page-teams">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li><a> Página inicial </a></li>
          <li><a> Departamentos </a></li>
          <li><a> Times </a></li>
        </ul>
      </div>
      <div class="col-12"><h2>Times</h2></div>
      <div class="col-12">
        <div class="teams-list">
          <div class="row">
            <div class="col-12 mt-3">
              <div class="team-nav">
                <div class="buttons">
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary"
                    (click)="open(modalCreateTeam)"
                  >
                    Criar novo time
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-5">
              <div class="team-list">
                <app-folder-list
                  [collection]="collection"
                  (activity)="doAction($event)"
                >
                </app-folder-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalCreateTeam let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Adicionar time'"
  >
    <app-team-form
      [modal]="modal"
      [selected]="selectedTeam"
      [isLoading]="isLoading"
    ></app-team-form>
  </app-modal-action>
</ng-template>
