import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

import {
  WorkflowService,
  DocumentService,
  WORKFLOW_STEP_STATUS,
  WORKFLOW_STATUS,
  AuthorizationService,
  WorkflowTemplateService,
  WorkflowBuildService
} from '@app/core/services';
import { Logger, WorkflowContact, WorkflowFile } from '@app/core/class';

import { AlertSubscription } from '@app/core/subscriptions';
import * as moment from 'moment';
import { debounce, Cancelable } from 'lodash';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
import { parseDate } from '@app/core/helpers';

const log = new Logger('TemplatesPageComponent');

@Component({
  selector: 'app-templates-page',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesPageComponent implements OnInit {
  /**
   * template of modal for loading
   */
  @ViewChild('modalLoading') loading: ElementRef;

  @ViewChild('modalRenameTemplate') modalRenameTemplate: ElementRef;

  @ViewChild('modalDeleteTemplate') modalDeleteTemplate: ElementRef;

  /**
   * collection with data
   */
  collectionDocument: any;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for pagination
   */
  pagination: any;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current user for edit/delete
   */
  item: any;

  /**
   * Data User logged
   */
  userLogged: any;

  /**
   * modal
   */
  modalRef: any;

  details: any;
  loadingDetails: boolean = false;

  currentPage: number;
  currentRoute: string;
  currentSort: string;
  currentSortBy: string;
  currentPeriodFilter: string;

  statusFilterConfig: Array<any>;
  queryStringFilter: any = {};

  searchField: string = '';
  searchPeriod: number | string = '';

  searchDebounce: (() => void) & Cancelable;

  public renameWorkflowForm: FormGroup;

  constructor(
    private authorizationService: AuthorizationService,
    private workflowTemplateService: WorkflowTemplateService,
    private workflowBuildService: WorkflowBuildService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.collectionDocument = { items: [] };
    this.modalRef = { ref: null, data: { text: '' } };
    this.pagination = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
    this.currentRoute = '/manager/manage/templates';

    this.searchDebounce = debounce(this.search.bind(this), 300);

    this.createRenameWorkflowForm();
  }

  ngOnInit() {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );

    this.authorizationService.user.subscribe((user: any) => {
      this.userLogged = user;
    });

    combineLatest(this.route.params, this.route.queryParams)
      .pipe(map((params, qparams) => ({ params, qparams })))
      .subscribe((p: any) => {
        if (p.params.page) {
          this.currentPage = p.params.page;
        }

        if (p.params.sort) {
          this.currentSort = p.params.sort;
        }

        if (p.params.sortBy) {
          this.currentSortBy = p.params.sortBy;
        }

        this.getData(
          {
            page: p.qparams.page,
            sortBy: p.qparams.sortBy,
            sort: p.qparams.sort
          },
          false
        );
      });

    this.sortFields = { id: 'DESC', status: 'DESC' };
  }

  /**
   * provides the data of workflows with params
   *
   * @return void
   */
  getData(params: any, isFiltered: boolean = true) {
    const payload = {
      page: params.page ? params.page : 1,
      area: params.area ? params.area : 'all',
      sortBy: params.sortBy ? params.sortBy : 'id',
      sort: params.sort ? params.sort : 'desc'
    };
    this.getWorkflowTemplates(payload, isFiltered);
  }

  search(page: number = 1) {
    this.queryStringFilter = {};

    const filter = [];
    const sort = [];

    const params = { page };

    if (this.searchPeriod) {
      const format = 'YYYYMMDD';

      const from = moment();

      from.subtract(this.searchPeriod, 'days');

      const value = from.format(format) + '000000';

      filter.push({
        type: 'gte',
        field: 'dateCreated',
        value,
        direction: 'desc'
      });
    }

    if (this.searchField) {
      filter.push({
        type: 'like',
        field: 'name',
        value: `%${this.searchField}%`
      });
    }

    if (filter.length > 0) {
      sort.push({ type: 'field', field: 'id', direction: 'desc' });
    }

    this.queryStringFilter = {
      filter,
      'order-by': sort,
      page: 1
    };

    this.getData(params, filter.length > 0);
  }

  /**
   * provides the data of workflow templates
   *
   * @return void
   */
  getWorkflowTemplates(params: any, isFiltered: boolean) {
    try {
      let queryString;
      this.configList = [
        {
          name: 'Nome',
          klass: '',
          type: 'text',
          fieldId: 'name'
        },
        {
          name: 'Data',
          klass: '',
          type: 'text',
          fieldId: 'dateCreated.formated_date',
          fieldKey: 'id',
          isSortable: true
        },
        {
          name: 'Ações',
          type: 'select',
          icon: 'fa fa-sliders-h',
          width: '9%',
          isRestrict: true,
          options: {
            changeLabel: true,
            items: [
              {
                name: 'Utilizar',
                action: 'use'
              },
              // TODO: descomentar esta opção quando o backend
              //       aceitar as requisições de atualização.
              // {
              //   name: 'Renomear',
              //   action: 'rename'
              // },
              {
                name: 'Excluir',
                action: 'remove'
              }
            ]
          }
        }
      ];

      if (!isFiltered) {
        queryString = {
          'order-by': [
            {
              type: 'field',
              field: params.sortBy,
              direction: params.sort
            }
          ],
          page: params.page
        };
      } else {
        queryString = this.queryStringFilter;
        queryString.page = params.page;
      }

      this.workflowTemplateService.getAll(queryString).subscribe(
        (response: any) => {
          log.debug(`Success [getWorkflowTemplates]:`, response);
          this.collectionDocument = response;
          this.pagination = {
            totalItems: response.total_items,
            pageCount: response.page_count,
            pageSize: response.page_size,
            currentPage: response.page
          };
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
        },
        (error: any) => {
          log.debug(`Error [getWorkflowTemplates]:`, error);
          this.collectionDocument = { items: [] };
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      log.debug(`Error [getWorkflowTemplates]:`, e);
      this.collectionDocument = { items: [] };
      this.modalRef.ref.close();
    }
  }

  /**
   * Action grab all selected items
   *
   * @param (any) items selected
   */
  getSelectedItems(items: any) {
    this.selectedItems = items;
  }

  /**
   * Action grab clicked item
   *
   * @param (any) clicked item
   */
  getClickedItem(item: any) {
    let id;
    if (item.step && item.step.id) {
      id = item.step.id;
    }

    if (!id) {
      return;
    }

    try {
      this.loadingDetails = true;
      this.workflowTemplateService
        .getWorkflowTemplateById(id)
        .pipe(
          finalize(() => {
            this.loadingDetails = false;
          })
        )
        .subscribe(
          (response: any) => {
            log.debug(`Success [getClickedItem]:`, response);
            this.details = response;
          },
          (error: any) => {
            log.debug(`Error [getClickedItem]:`, error);
            this.details = error;
          }
        );
    } catch (e) {
      log.debug(`Error [getClickedItem]:`, e);
      this.details = e;
    }
  }

  /**
   * Action grab data
   *
   * @param (any) items selected
   */
  doAction(event: any) {
    log.debug(`doAction:`, event);

    switch (event.type) {
      case 'use':
        this.doActionUse(event);
        break;

      case 'rename':
        this.renameWorkflowForm.setValue({
          id: event.data.id,
          name: event.data.name
        });
        this.item = event.data;
        this.open(this.modalRenameTemplate);
        break;

      case 'remove':
        this.item = event.data;
        this.open(this.modalDeleteTemplate);
        break;

      default:
        break;
    }
  }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    this.sortFields[field] = this.reorder(this.sortFields[field]);

    if (this.sortFields[field]) {
      setTimeout(
        (() => {
          this.modalRef.data.text = 'carregando dados...';
          this.modalRef.ref = this.open(this.loading);
        }).bind(this)
      );

      const payload = {
        sort: this.sortFields[field],
        sortBy: field,
        page: 1
      };

      this.currentSort = payload.sort;
      this.currentSortBy = payload.sortBy;
      this.router.navigate([this.currentRoute], {
        replaceUrl: true,
        queryParams: payload
      });

      this.getData(payload, false);
    }
  }

  /**
   * Action for change page
   *
   * @param (number) of page
   */
  onChangePage(pageNumber: number) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );

    this.search(pageNumber);
  }

  submitRenameWorkflow() {
    this.workflowTemplateService
      .renameWorkflowTemplate(this.item.id, this.item.name)
      .subscribe((response: any) => {
        const template = this.collectionDocument.items.find(
          (i: any) => i.id === this.item.id
        );
        template.name = response._embedded.workflow_template.name;
        this.item = null;
        this.modalService.dismissAll();
      });
  }

  submitRemoveTemplate() {
    this.workflowTemplateService
      .deleteWorkflowTemplate(this.item.id)
      .subscribe(() => {
        this.collectionDocument.items = this.collectionDocument.items.filter(
          (i: any) => i.id !== this.item.id
        );
        this.item.id = null;
        this.modalService.dismissAll();
      });
  }

  doActionUse(event: any) {
    this.open(this.loading);
    this.workflowTemplateService
      .getWorkflowTemplateById(event.data.id)
      .subscribe(async ({ template }: any) => {
        for (const document of template.files) {
          const workflowFile = await WorkflowFile.MakeWorkflowFileFromURL(
            document._embedded.file._links.download.href
          );
          workflowFile.setName(document.name);
          workflowFile.setIdFile(document.idFile);
          workflowFile.setFolderId(document.idFolder);

          if (document.dueDate) {
            workflowFile.setDueDate(document.dueDate);
          }

          for (const step of document.workflowSteps) {
            let contact = this.workflowBuildService.findContactByEmail(
              step.user.email
            );

            if (!contact) {
              contact = new WorkflowContact(
                {
                  email: step.user.email,
                  id: step.user.id,
                  name: step.user.name
                },
                step.action
              );
              this.workflowBuildService.addContact(contact);
            }

            for (const field of step.fields) {
              const page = workflowFile.getPage(field.page);
              const f = WorkflowDroppedFieldsStrategy.MakeDroppedField(
                field.type,
                contact,
                step.signatureType
              );
              f.setViewportProportion(1);
              f.setSize(field.width, field.height);
              f.setCoordinates(field.x, field.y);
              page.addField(f);
            }
          }

          this.workflowBuildService.addFile(workflowFile);
          this.workflowBuildService.setFileAsVisible(workflowFile);
        }

        this.workflowBuildService.setSettings({
          autoInitiate: template.autoInitiate,
          message: template.message,
          name: template.name,
          priority: template.priority,
          dueDate: template.dueDate
        });

        this.modalService.dismissAll();
        this.router.navigate(['/manager/workflow']);
      });
  }

  formControlValidation(control: AbstractControl): boolean {
    return !control.pristine && control.invalid;
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  private open(content: any, size?: any, data?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  private createRenameWorkflowForm(): any {
    this.renameWorkflowForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required]
    });
  }
}
