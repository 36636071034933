import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Utils } from '@app/utils/utils';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {
  @ViewChild('canvasImgResponsive') canvasPreview: ElementRef<
    HTMLCanvasElement
  >;
  @Output() addPhotoWorkflowStep = new EventEmitter();

  @Output() showButtonToSubmit = new EventEmitter();

  private videoElement: HTMLVideoElement;

  public steam: MediaStream;

  public show: boolean = false;

  public photoMobile: boolean = false;

  private img: string;

  public width: number;
  public height: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.updateSize();
    this.startVideoFromCamera();
  }

  public ngOnDestroy(): void {
    if (!this.isMobile()) {
      this.stopVideoFromCamera();
    }
    this.clearImag();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateSize();
  }

  private updateSize(): void {
    const width = this.elementRef.nativeElement.parentElement.offsetWidth;
    const height = 300;
    this.size(width, height);
  }

  public size(sizeWidth: number, sizeHeight: number): void {
    this.width = sizeWidth;
    this.height = sizeHeight;
  }

  private startVideoFromCamera(): void {
    if (!this.isMobile()) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(steam => {
          this.videoElement = document.querySelector('video');
          this.videoElement.srcObject = steam;
          this.steam = steam;
          this.videoElement.play();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  public stopVideoFromCamera(): void {
    this.steam.getVideoTracks()[0].stop();
  }

  public capture(): void {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.videoElement.videoWidth;
    canvas.height = this.videoElement.videoHeight;

    context.clearRect(
      0,
      0,
      this.videoElement.videoWidth,
      this.videoElement.videoHeight
    );
    context.drawImage(this.videoElement, 0, 0);

    this.img = canvas.toDataURL();
    this.stopVideoFromCamera();
    this.showPhoto();
    this.toSubmit(this.img);
  }

  public captureImageMobile($event: Event): void {
    const canvas = this.canvasPreview.nativeElement;
    const contextCanvas = canvas.getContext('2d');
    const file = ($event.target as HTMLInputElement).files[0];
    Utils.encodeImageFileAsURL(file, (result: any) => {
      const image = new Image();
      image.onload = _ => {
        image.width = (image.width * 8) / 100;
        image.height = (image.height * 8) / 100;
        canvas.width = image.width;
        canvas.height = image.height;
        contextCanvas.drawImage(image, 0, 0, canvas.width, canvas.height);
        const img = canvas
          .toDataURL()
          .replace('data:image/jpeg', 'data:image/png');
        this.showButtonMobile();
        this.toSubmit(img);
      };
      image.src = result;
    });
  }

  public clearImag(): void {
    this.img = null;
  }

  public showPhoto(): void {
    this.show = !this.show;
  }

  public showButtonMobile(): void {
    this.photoMobile = !this.photoMobile;
  }

  public shouldShowMobileButton(): boolean {
    return this.photoMobile;
  }

  public newPhoto(): void {
    this.showPhoto();
    this.clearImag();
    this.startVideoFromCamera();
    this.showButton();
  }

  public showButton(): void {
    this.showButtonToSubmit.emit();
  }

  private toSubmit(photo: string): void {
    this.addPhotoWorkflowStep.emit({ photoSign: photo });
  }

  public isMobile(): boolean {
    return Utils.isMobile();
  }
}
