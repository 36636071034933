import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var PhotoSubscription = /** @class */ (function () {
    function PhotoSubscription() {
        this.subject = new Subject();
    }
    PhotoSubscription.prototype.update = function (data) {
        this.subject.next(data);
    };
    PhotoSubscription.prototype.get = function () {
        return this.subject.asObservable();
    };
    PhotoSubscription.ngInjectableDef = i0.defineInjectable({ factory: function PhotoSubscription_Factory() { return new PhotoSubscription(); }, token: PhotoSubscription, providedIn: "root" });
    return PhotoSubscription;
}());
export { PhotoSubscription };
