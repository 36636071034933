import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DocumentService } from '@app/core/services';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var CheckerComponent = /** @class */ (function () {
    function CheckerComponent(modalService, activeroute, formBuilder, documentService) {
        this.modalService = modalService;
        this.activeroute = activeroute;
        this.formBuilder = formBuilder;
        this.documentService = documentService;
        this.isLoading = false;
        this.isError = false;
        this.errorFinishingValidation = '';
        this.modalRef = {
            ref: null,
            data: { text: '', showClose: false, title: '' }
        };
        this.createForm();
    }
    CheckerComponent.prototype.ngOnInit = function () {
        var query = this.activeroute.snapshot.queryParamMap.get('q');
        if (!query) {
            return;
        }
        if (query.includes('-')) {
            this.verify({ docId: query });
            return;
        }
        this.verify({ token: query });
    };
    CheckerComponent.prototype.newFile = function () {
        this.filename = '';
    };
    CheckerComponent.prototype.generateFileHash = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader, file;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                reader = new FileReader();
                file = event.target.files[0];
                this.filename = file.name;
                reader.readAsArrayBuffer(file);
                return [2 /*return*/, new Promise(function (resolve) {
                        reader.onload = function () {
                            var fileResult = reader.result;
                            var fileWordArray = CryptoJS.lib.WordArray.create(fileResult);
                            _this.hash = CryptoJS.SHA1(fileWordArray);
                            resolve(null);
                        };
                    })];
            });
        });
    };
    CheckerComponent.prototype.doSubmit = function () { };
    CheckerComponent.prototype.sendVerification = function () {
        var verifyJSON = {};
        this.isError = false;
        var verifyCode = this.checkerForm.value.codeField;
        if (this.hash) {
            verifyJSON = {
                docId: verifyCode.toLocaleUpperCase(),
                hash: this.hash.toString()
            };
        }
        else {
            verifyJSON = {
                docId: verifyCode.toLocaleUpperCase()
            };
        }
        this.verify(verifyJSON);
    };
    CheckerComponent.prototype.downloadFile = function (url) {
        window.open(url);
    };
    CheckerComponent.prototype.verify = function (data) {
        var _this = this;
        this.modalRef.data.title = '';
        this.modalRef.data.text = 'Realizando validação...';
        this.modalRef.ref = this.open(this.loading);
        this.documentService.verify(data).subscribe(function (body) {
            var signers = [];
            var steps = body.steps;
            steps.forEach(function (element) {
                var signer = {
                    name: element.user.name,
                    email: element.user.email,
                    status: _this.parseStatus(element.status),
                    type: element.signatureType === 0
                        ? 'Assinatura eletrônica'
                        : 'Assinatura com BirdID'
                };
                signers.push(signer);
            });
            _this.verifyResponse = {
                filename: body.name,
                docId: body.docId,
                date: _this.parseDate(body.dateReceived),
                signers: signers,
                integrity: body.integrity === 'original' || body.integrity === 'latest'
                    ? 'Confirmada'
                    : 'Não confirmada',
                original: body.originalFile
                    ? body.originalFile._links.download.href
                    : '',
                signed: body.file ? body.file._links.download.href : ''
            };
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
        }, function (error) {
            _this.isError = true;
            _this.errorFinishingValidation = _this.errorMsg = error.error.detail;
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
        });
    };
    CheckerComponent.prototype.parseStatus = function (status) {
        var parsedStatus;
        switch (status) {
            case 0:
                parsedStatus = 'Na fila';
                break;
            case 1:
                parsedStatus = 'Aguardando assinatura';
                break;
            case 2:
                parsedStatus = 'Aguardando processo iniciar';
                break;
            case 3:
                parsedStatus = 'Processando assinatura';
                break;
            case 4:
                parsedStatus = 'Assinado';
                break;
            case 5:
                parsedStatus = 'Rejeitado';
                break;
            default:
                parsedStatus = 'Status não reconhecido';
                break;
        }
        return parsedStatus;
    };
    CheckerComponent.prototype.parseDate = function (data) {
        return moment(data.date)
            .utc(data.timezone_type)
            .format('YYYY-MM-DD HH:mm');
    };
    CheckerComponent.prototype.finishValidation = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorFinishingValidation = '';
                        this.checkerForm.setValue({
                            codeField: this.verifyResponse.docId
                        });
                        return [4 /*yield*/, this.generateFileHash(event)];
                    case 1:
                        _a.sent();
                        this.sendVerification();
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckerComponent.prototype.createForm = function () {
        this.checkerForm = this.formBuilder.group({
            codeField: ['', Validators.required]
        });
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    CheckerComponent.prototype.open = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    return CheckerComponent;
}());
export { CheckerComponent };
