import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { WorkflowPageComponent as ManagerWorkflowPageComponent } from '../../../manager/pages/workflow/workflow.component';
import { AuthorizationService, WorkflowBuildService, FeedbackService, WorkflowTemplateService, UserService } from '@app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact, Document } from '@app/core/models';
import { WorkflowFile, WorkflowContact } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
import { Format } from '@app/core/helpers';
var WorkflowPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowPageComponent, _super);
    function WorkflowPageComponent(authorizationService, workflowBuildService, feedbackService, router, route, workflowTemplateService, modalService, userService) {
        var _this = _super.call(this, authorizationService, workflowBuildService, feedbackService, router, userService) || this;
        _this.authorizationService = authorizationService;
        _this.workflowBuildService = workflowBuildService;
        _this.feedbackService = feedbackService;
        _this.router = router;
        _this.route = route;
        _this.workflowTemplateService = workflowTemplateService;
        _this.modalService = modalService;
        _this.userService = userService;
        _this.buildURL = '/integration/workflow';
        _this.reviewURL = '/integration/workflow/review';
        _this.finishURL = '/integration/workflow/finish';
        _this.errorURL = '/integration/workflow/error';
        _this.modalRef = {
            ref: null,
            data: { text: '', showClose: false, title: '' }
        };
        return _this;
    }
    WorkflowPageComponent.prototype.ngOnInit = function () {
        var templateId = this.route.snapshot.queryParams['id'];
        var templateToken = this.route.snapshot.queryParams['token'];
        if (this.route.snapshot.queryParams['whatsapp']) {
            this.setWhatsAppIntegration(Format.conversionToBoolean(this.route.snapshot.queryParams['whatsapp']));
        }
        if (!templateId) {
            this.router.navigate([this.errorURL], {
                queryParams: {
                    error: 'É necessário que informe o ID do modelo a utilizar!'
                }
            });
            return;
        }
        if (!templateToken) {
            this.router.navigate([this.errorURL], {
                queryParams: {
                    error: 'É necessário que informe o token para acessar o modelo!'
                }
            });
            return;
        }
        // Stack trace unit testing errors
        var subs = from(this.loadWorkflowTemplate(templateId, templateToken)).subscribe(function () {
            subs.unsubscribe();
        });
    };
    WorkflowPageComponent.prototype.setWhatsAppIntegration = function (flag) {
        this.workflowBuildService.setWhatsApp(flag);
    };
    /**
     * Don't fetch anything
     */
    WorkflowPageComponent.prototype.fetchDefaultFolder = function () {
        this.workflowBuildService.setDefaultFolderId(0);
        return;
    };
    WorkflowPageComponent.prototype.loadWorkflowTemplate = function (id, token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_1, errorMessage, lastWorkflowfile, template, _i, _a, file, workflowFile, _loop_1, this_1, _b, _c, step, error_2;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.showLoadingDocumentsModal()];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.workflowTemplateService
                                .getWorkflowTemplateById(id, token)
                                .toPromise()];
                    case 3:
                        response = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _d.sent();
                        errorMessage = '';
                        if (error_1.error) {
                            errorMessage = error_1.error.detail;
                        }
                        else {
                            errorMessage = "[" + error_1.status + "] " + error_1.message;
                        }
                        this.feedbackService.error(errorMessage);
                        return [2 /*return*/];
                    case 5:
                        if (!this.isTemplateValid(response.template)) {
                            return [2 /*return*/];
                        }
                        this.workflowTemplateService.setSettings(response.settings);
                        template = response.template;
                        this.workflowBuildService.setSettings({
                            autoRemind: template.autoRemind,
                            dueDate: template.dueDate,
                            message: template.message,
                            sla: template.sla,
                            priority: template.priority,
                            name: template.name,
                            autoInitiate: template.autoInitiate
                        });
                        _i = 0, _a = template.files;
                        _d.label = 6;
                    case 6:
                        if (!(_i < _a.length)) return [3 /*break*/, 12];
                        file = _a[_i];
                        _d.label = 7;
                    case 7:
                        _d.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.createWorkflowFile(file)];
                    case 8:
                        workflowFile = _d.sent();
                        this.workflowBuildService.addFile(workflowFile);
                        lastWorkflowfile = workflowFile;
                        _loop_1 = function (step) {
                            var exists = this_1.workflowBuildService
                                .getContacts()
                                .find(function (contact) {
                                return contact.email === step.user.email;
                            });
                            if (exists) {
                                return "continue";
                            }
                            var workflowContact = this_1.createWorkflowContact(step);
                            this_1.workflowBuildService.addContact(workflowContact);
                            if (step.fields) {
                                for (var _i = 0, _a = step.fields; _i < _a.length; _i++) {
                                    var field = _a[_i];
                                    var page = workflowFile.getPage(field.page);
                                    var f = WorkflowDroppedFieldsStrategy.MakeDroppedField(field.type, workflowContact, step.signatureType);
                                    f.setViewportProportion(1);
                                    f.setSize(field.width, field.height);
                                    f.setCoordinates(field.x, field.y);
                                    page.addField(f);
                                }
                            }
                        };
                        this_1 = this;
                        for (_b = 0, _c = file.workflowSteps; _b < _c.length; _b++) {
                            step = _c[_b];
                            _loop_1(step);
                        }
                        return [3 /*break*/, 10];
                    case 9:
                        error_2 = _d.sent();
                        this.feedbackService.error("N\u00E3o foi poss\u00EDvel fazer download do arquivo " + file.name + ". Erro: " + error_2.message);
                        return [2 /*return*/];
                    case 10:
                        this.workflowBuildService.setFileAsVisible(lastWorkflowfile);
                        if (this.modalRef.ref) {
                            this.modalRef.ref.close();
                        }
                        _d.label = 11;
                    case 11:
                        _i++;
                        return [3 /*break*/, 6];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    WorkflowPageComponent.prototype.createWorkflowFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var downloadLink, workflowFile, defaultFolderId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        downloadLink = file._embedded.file._links.download.href;
                        return [4 /*yield*/, WorkflowFile.MakeWorkflowFileFromURL(downloadLink)];
                    case 1:
                        workflowFile = _a.sent();
                        workflowFile.setName(file.name);
                        workflowFile.setIdFile(file.idFile);
                        defaultFolderId = this.workflowBuildService.getDefaultFolderId();
                        workflowFile.setFolderId(file.idFolder || defaultFolderId);
                        return [2 /*return*/, workflowFile];
                }
            });
        });
    };
    WorkflowPageComponent.prototype.createWorkflowContact = function (step) {
        var workflowContact = {
            id: step.idUser || null,
            email: step.user.email,
            name: step.user.name,
            whatsappNumber: step.user.whatsappNumber,
            notifyWhatsapp: step.user.notifyWhatsapp
        };
        if (step.extraMetadata) {
            workflowContact.extraMetadata = {
                name: step.extraMetadata.name,
                document: step.extraMetadata.document,
                dataNascimento: step.extraMetadata.dataNascimento,
                photo: step.extraMetadata.photo
            };
        }
        return WorkflowContact.MakeWorkflowContact(workflowContact);
    };
    /**
     * provides a action for open modal
     *
     * @param (any) content of modal
     * @return void
     */
    WorkflowPageComponent.prototype.open = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    WorkflowPageComponent.prototype.isTemplateValid = function (template) {
        if (template.files.length === 1) {
            return true;
        }
        var files = template.files.slice();
        // Check if the signers exists in all steps
        var signersEmail = [];
        var signersAmount = 0;
        var firstFileSteps = files.shift();
        for (var _i = 0, _a = firstFileSteps.workflowSteps; _i < _a.length; _i++) {
            var step = _a[_i];
            if (step.user) {
                signersAmount++;
                signersEmail.push(step.user.email);
            }
        }
        var signersErrorText = 'A definição de assinantes está inválida, favor checar a construção do fluxo.';
        // Now check if the other files match
        for (var _b = 0, files_1 = files; _b < files_1.length; _b++) {
            var file = files_1[_b];
            var curSignersAmount = 0;
            var _loop_2 = function (step) {
                if (step.user) {
                    curSignersAmount++;
                    var exists = signersEmail.findIndex(function (email) { return email === step.user.email; });
                    if (exists === -1) {
                        this_2.feedbackService.error(signersErrorText);
                        return { value: false };
                    }
                }
            };
            var this_2 = this;
            for (var _c = 0, _d = file.workflowSteps; _c < _d.length; _c++) {
                var step = _d[_c];
                var state_1 = _loop_2(step);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            // If there are more or there are less signers, show error
            if (curSignersAmount !== signersAmount) {
                this.feedbackService.error(signersErrorText);
                return false;
            }
        }
        return true;
    };
    WorkflowPageComponent.prototype.showLoadingDocumentsModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        setTimeout(function () {
                            _this.modalRef.data.title = '';
                            _this.modalRef.data.text =
                                'Preparando seu(s) documento(s) para contrução';
                            _this.modalRef.ref = _this.open(_this.loading);
                            resolve();
                        });
                    })];
            });
        });
    };
    return WorkflowPageComponent;
}(ManagerWorkflowPageComponent));
export { WorkflowPageComponent };
