<div class="row list-signatures">
  <div
    class="col-12 col-md-6 signature"
    (click)="doChooseFont(font)"
    [class.current]="chosenFont === font"
    *ngFor="let font of listFonts"
  >
    <div
      class="signature-item"
      [ngClass]="font.toLowerCase().substring(0, font.length - 4)"
    >
      <span class="description"><small>Documento assinado por:</small></span>
      <p class="name">{{ fullName || 'Digite um nome' }}</p>
    </div>
  </div>
</div>
