<div class="row comp_">
  <div class="col align-self-center">
    <div class="box-draw">
      <signature-pad
        [options]="signaturePadOptions"
        (onBeginEvent)="drawStart()"
        (onEndEvent)="drawComplete()"
      >
      </signature-pad>

      <div class="position">
        <div class="div_clear">
          <span
            class="btn_clear_sign"
            (click)="ngAfterViewInit()"
            title="Clique para limpar"
          >
            x
          </span>
        </div>

        <hr class="line-sign" />
        <h6 class="line">Desenhe sua assinatura aqui</h6>
      </div>
    </div>
  </div>
</div>
