import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
export var EVENT_STATUS = {
    1: { text: 'Aguardando Assinatura', id: 'wait' },
    2: { text: 'Assinado', id: 'sign' }
};
export var EVENT_ACTION = {
    resend: 'Enviar novamente',
    check: 'Verificar documento'
};
/**
 * Provides a base for events/notification list.
 */
var EventsService = /** @class */ (function (_super) {
    tslib_1.__extends(EventsService, _super);
    function EventsService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/mock/events.json';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Gets all data of a service
     * @param filterObject (optional) arguments for filter data.
     */
    EventsService.prototype.getAll = function (filterObject) {
        var _this = this;
        return _super.prototype.getAll.call(this, filterObject).pipe(map(function (response) {
            return _this._parseData(response);
        }));
    };
    /**
     * Filter all data according to the period
     * @param (number) period number of days
     * @param (Event.EventData) period of filter
     */
    EventsService.prototype.filterItems = function (period, data) {
        var current = new Date();
        var from = new Date();
        from.setDate(from.getDate() - period);
        return data.filter(function (item) {
            var date = new Date(item.date);
            return (date.getTime() >= from.getTime() && date.getTime() <= current.getTime());
        });
    };
    /**
     * Parse of service data and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    EventsService.prototype._parseData = function (items) {
        var _this = this;
        // to do: setar dinamicamente o locale
        moment.locale('pt');
        return items.map(function (item) {
            return {
                name: item.name,
                initials: _this._initials(item.name),
                date: item.date,
                time: moment(new Date(item.date)).fromNow(),
                document: item.document,
                status: EVENT_STATUS[item.status],
                action: item.action,
                actionText: EVENT_ACTION[item.action]
            };
        });
    };
    /**
     * Returns the initials of a name
     *
     * @param (string) name that will be parsed
     * @return (string) name that was parsed
     */
    EventsService.prototype._initials = function (name) {
        var fullname = name.split(' ');
        if (fullname.length === 1) {
            return name.substr(0, 2).toUpperCase();
        }
        else {
            var index = 1;
            if (fullname[index].length < 3) {
                index++;
            }
            return "" + fullname[0].substr(0, 1) + fullname[index].substr(0, 1);
        }
    };
    return EventsService;
}(BaseAPI));
export { EventsService };
