import { Component, OnInit } from '@angular/core';
import { User } from '@app/core/models';
import { RemainingUserBalanceService } from '@app/core/services';

@Component({
  selector: 'app-remaining-user-balance',
  templateUrl: './remaining-user-balance.component.html',
  styleUrls: ['./remaining-user-balance.component.scss']
})
export class RemainingUserBalanceComponent implements OnInit {
  planInfo: User.UserPlanPayload;

  constructor(private remainingUserBalance: RemainingUserBalanceService) {}

  ngOnInit() {
    this.getPlanEvent();
  }

  getPlanEvent() {
    this.remainingUserBalance.getPlanUser();

    this.remainingUserBalance.planUser.subscribe(
      (response: User.UserPlanPayload) => {
        this.planInfo = response;
      }
    );
  }
}
