<div class="form-contact form-modal">
  <form
    (ngSubmit)="doUpdate()"
    [formGroup]="updateForm"
    novalidate
    [hidden]="!selected"
  >
    <div class="row">
      <p>
        O contato <strong>{{ selected?.name }}</strong> está
        <strong>{{ selected?.statusText?.toUpperCase() }}</strong
        >. Ao processar a atualização, o mesmo será
        <strong>{{ newStatus }}</strong
        >.
      </p>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-5 mb-3">
        <button class="btn btn-default" (click)="modal.close('cancel')">
          Cancelar
        </button>
        <button class="btn btn-secondary btn-sm" type="submit">Salvar</button>
      </div>
    </div>
  </form>
  <form
    (ngSubmit)="doSubmit()"
    [formGroup]="contactForm"
    novalidate
    [hidden]="selected"
  >
    <div class="row form-modal">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Nome </label>
              <input
                type="text"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': f.name.errors && !f.name.pristine
                }"
                formControlName="name"
              />
              <div *ngIf="f.name.errors && !f.name.pristine">
                <span class="error" [hidden]="!f.name.errors.required">
                  O nome é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end line -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> E-mail </label>
              <input
                type="email"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': f.email.errors && !f.email.pristine
                }"
                formControlName="email"
              />
              <div *ngIf="f.email.errors && !f.email.pristine">
                <span class="error" [hidden]="!f.email.errors.required">
                  O e-mail é obrigatório
                </span>
                <span class="error" [hidden]="!f.email.errors.email">
                  O e-mail é inválido
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end line -->
        <div class="row">
          <div class="col-12 text-center mt-5 mb-3">
            <button class="btn btn-default" (click)="modal.close('cancel')">
              Cancelar
            </button>
            <button
              class="btn btn-secondary btn-sm"
              type="submit"
              [disabled]="contactForm.invalid || isLoading"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
