/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-change-business-unit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./confirm-change-business-unit.component";
import * as i5 from "../../../core/services/authentication.service";
var styles_ConfirmChangeBusinessUnitComponent = [i0.styles];
var RenderType_ConfirmChangeBusinessUnitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmChangeBusinessUnitComponent, data: {} });
export { RenderType_ConfirmChangeBusinessUnitComponent as RenderType_ConfirmChangeBusinessUnitComponent };
function View_ConfirmChangeBusinessUnitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirmar troca de corpora\u00E7\u00E3o"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "font_1 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Para confirmar sua troca de corpora\u00E7\u00E3o no Assine Online, clique no bot\u00E3o\n      abaixo:\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "d-flex justify-content-start mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-secondary btn-sm "], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Confirmar\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
function View_ConfirmChangeBusinessUnitComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Troca de corpora\u00E7\u00E3o confirmada"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "font_1 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Agora voc\u00EA faz parte de uma corpora\u00E7\u00E3o, envie arquivos em poucos passos,\n      sem complica\u00E7\u00E3o.\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-secondary mt-2"], ["routerLink", "/auth/login"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      Fazer login\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_0 = "/auth/login"; _ck(_v, 9, 0, currVal_0); }, null); }
function View_ConfirmChangeBusinessUnitComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ops! Algo de errado aconteceu."])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "font_1 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      N\u00E3o conseguimos registrar a troca de corpora\u00E7\u00E3o \u00E0 qual voc\u00EA foi convidado,\n      favor refa\u00E7a o processo.\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_ConfirmChangeBusinessUnitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmChangeBusinessUnitComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmChangeBusinessUnitComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmChangeBusinessUnitComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activated == 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.activated == 2); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.activated == 3); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ConfirmChangeBusinessUnitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-change-business-unit", [], null, null, null, View_ConfirmChangeBusinessUnitComponent_0, RenderType_ConfirmChangeBusinessUnitComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmChangeBusinessUnitComponent, [i2.ActivatedRoute, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmChangeBusinessUnitComponentNgFactory = i1.ɵccf("app-confirm-change-business-unit", i4.ConfirmChangeBusinessUnitComponent, View_ConfirmChangeBusinessUnitComponent_Host_0, {}, {}, []);
export { ConfirmChangeBusinessUnitComponentNgFactory as ConfirmChangeBusinessUnitComponentNgFactory };
