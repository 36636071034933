import { Injectable } from '@angular/core';
import { TagMangeEvent } from '../models';

declare var gtag: any;

@Injectable()
export class TagManageService {
  public sendEvent(tagEvent: TagMangeEvent.TagEvent): void {
    gtag(tagEvent.event, tagEvent.value);
  }
}
