import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UserService, AuthorizationService } from '@app/core/services';
import { User, Country, State, Authentication } from '@app/core/models';
import { Router } from '@angular/router';
import { debounce, Cancelable } from 'lodash';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-users-page',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersPageComponent implements OnInit {
  /**
   * template of modal for create user
   */
  @ViewChild('modalCreateUser') modalCreateUser: ElementRef;

  @ViewChild('modalFeedbackMaxUser') modalFeedbackMaxUser: ElementRef;

  @ViewChild('modalRemoveUser') modalRemoveUser: ElementRef;

  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * loading of form
   */
  isLoading: boolean = false;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * default pagination
   */
  pagination: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current user for edit/delete
   */
  selected: User.FormPayload;

  /**
   * Linked users
   */
  users: User.BusinessUnitUser[];

  /**
   * Data User logged
   */
  userLogged: any;

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  dataForm: any;

  paymentInfo: User.UserPlanInfor;
  modalRef: any;
  modal: NgbModalRef;

  deleteUser: any;

  /**
   * Field search data
   */
  queryField = new FormControl();
  searchDebounce: (() => void) & Cancelable;

  constructor(
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.modalRef = { ref: null, data: { text: '' } };

    this.searchDebounce = debounce(this.search.bind(this), 300);

    this.pagination = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
  }

  ngOnInit() {
    this.setTimeout();
    this.configListData();
    this.sortField();

    this.getUser();
    this.getUserBusinessUnit();
    this.getUserPlanInfor();
  }

  private sortField(): void {
    this.sortFields = { name: 'DESC', email: 'DESC' };
  }

  public configListData(): void {
    this.configList = [
      {
        name: 'Nome',
        klass: '',
        type: 'text',
        fieldId: 'name',
        fieldKey: 'name',
        isSortable: true
      },
      {
        name: 'Email',
        type: 'text',
        fieldId: 'email',
        fieldKey: 'email',
        isSortable: true
      },
      {
        name: 'Ações',
        type: 'custom-select',
        icon: 'fa fa-sliders-h',
        width: '5%',
        options: {
          klass: 'text-right',
          primary: {
            name: 'Editar',
            action: 'edit'
          },
          items: [
            {
              name: 'Excluir',
              action: 'delete'
            }
          ]
        }
      }
    ];
  }

  private getUserPlanInfor(): void {
    this.userService
      .getUserPlanInfor()
      .subscribe((body: User.UserPlanInfor) => {
        this.paymentInfo = body;
      });
  }

  private getUser(): void {
    this.authorizationService.user.subscribe(
      response => (this.userLogged = response)
    );
  }

  public search(): void {
    const sort = [];
    let queryStringFilter;

    sort.push({ type: 'field', field: 'id', direction: 'desc' });

    if (this.queryField.value) {
      queryStringFilter = {
        filter: [
          {
            field: 'name',
            type: 'like',
            value: `%${this.queryField.value}%`
          }
        ],
        'order-by': sort,
        page: 1
      };
    }

    this.getUserBusinessUnit(queryStringFilter);
  }

  private getUserBusinessUnit(queryString?: any): void {
    this.userService.getLinkedUsers(queryString).subscribe(
      (body: User.BusinessUnitUserCollection) => {
        const userList = body.users.filter((user: any) => {
          return user.id !== this.userLogged.id;
        });
        this.users = userList;

        this.pagination = {
          totalItems: body.total_items,
          pageCount: body.page_count,
          pageSize: body.page_size,
          currentPage: body.page
        };
        this.closeModal();
      },
      error => {
        this.closeModal();
        throw new Error(`Failed to load user: ${error}`);
      }
    );
  }

  private setTimeout(): void {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando informações, aguarde...';
        this.modalRef.ref = this.openLoading(this.loading);
      }).bind(this)
    );
  }

  public navigeteToPlans(): void {
    this.closeModal();
    this.router.navigate(['/manager/plans']);
  }

  public checkMmaximumUserInPlan(): boolean {
    if (this.paymentInfo.maxUsers > this.users.length) {
      this.open(this.modalCreateUser, 'md');
      return true;
    }
    this.open(this.modalFeedbackMaxUser, 'md');
    return false;
  }

  public getSelectedItems(items: any): void {
    this.selectedItems = items;
  }

  public removeUser(): void {
    this.closeModal();

    this.userService.removeBusinessUnitFromuser(this.deleteUser.id).subscribe(
      (response: any) => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Contato removido com sucesso.';
        this.getUserBusinessUnit();
      },
      error => {
        throw new Error(`Remove user failed.: ${error}`);
      }
    );
  }

  public confirmation(user: any): void {
    this.deleteUser = user;
    this.open(this.modalRemoveUser, 'md');
  }

  public closeModal(): void {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  onChangePage(pageNumber: number): void {
    this.setTimeout();
    const queryString = {
      'order-by': [
        {
          type: 'field',
          field: 'id',
          direction: 'desc'
        }
      ],
      page: pageNumber
    };

    this.getUserBusinessUnit(queryString);
  }

  public doSort(field: string): void {
    this.sortFields[field] = this.reorder(this.sortFields[field]);

    const queryString = {
      'order-by': [
        {
          type: 'field',
          field: field,
          direction: this.sortFields[field]
        }
      ],
      page: 1
    };

    this.getUserBusinessUnit(queryString);
  }

  public doAction(items: any): void {
    switch (items.type) {
      case 'edit':
        this.selected = items.data;
        this.open(this.modalCreateUser);
        break;

      case 'delete':
        this.confirmation(items.data);
        break;

      default:
        throw new Error(`The informed field ${items.type} is invalid!`);
    }
  }

  public doSubmitForm(form: any): void {
    form.roleDefault = 'user';
    this.dataForm = form;
    this.userService.setLinkedUser(form).subscribe(
      () => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Contato adicionado com sucesso.';
        this.getUserBusinessUnit();
      },
      error => {
        switch (error.status) {
          case 422: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Não foi possível utilizar esta e-mail. ( ${
              error.statusText
            } )`;
            break;
          }
          case 202: {
            this.feedbackTheme = 'warning';
            this.feedbackMessage =
              'Usuário já faz parte de uma unidade de negócios, aguardando confirmação por e-mail.';
            break;
          }
          case 500: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Parece que houve um problema ao adicionar o usuário. ( ${
              error.statusText
            } )`;
            break;
          }
          case 400: {
            this.feedbackTheme = 'warning';
            this.feedbackMessage = `Usuário já existe, aguardando confirmação por e-mail. (Alterar unidade de negócios)`;
            break;
          }
          default: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Ocorreu um Erro Inesperado. ${
              error.statusText
            } `;
            break;
          }
        }
      }
    );
  }

  open(content: any, size?: any) {
    this.modalRef.ref = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    this.modalRef.ref.result.then((result: any) => {
      if (result && result.action === 'save') {
        this.doSubmitForm(result.formData);
        return;
      }

      if (result && result.action === 'delete') {
        return;
      }

      if (result && result === 'cancel') {
        return;
      }
    });
  }

  public openLoading(content: any, size?: any): any {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }
}
