import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';
import { FILES } from './workflow.enum';
/**
 * Provides a base for upload service.
 */
var UploadService = /** @class */ (function (_super) {
    tslib_1.__extends(UploadService, _super);
    function UploadService(httpClient, authorizationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authorizationService = authorizationService;
        _this.FILES = FILES;
        _this.queueMaxSize = 25; // 25mb
        _this.currentQueueSize = 0;
        _this.fileQueue = [];
        _this.endpoint = '/file';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        _this.accessToken = _this.authorizationService.getAccessToken();
        return _this;
    }
    UploadService.prototype.getEndpoint = function () {
        return this.endpoint;
    };
    UploadService.prototype.getFileQueue = function () {
        return this.fileQueue;
    };
    UploadService.prototype.hasMoreFiles = function () {
        return this.fileQueue.length > 0;
    };
    UploadService.prototype.addFileToQueue = function (file) {
        this.currentQueueSize += file.size;
        this.fileQueue.push(file);
    };
    UploadService.prototype.removeFileFromQueue = function (fileToRemove) {
        var _this = this;
        this.fileQueue = this.fileQueue.filter(function (file) {
            if (file === fileToRemove) {
                _this.currentQueueSize -= file.size;
                return false;
            }
            return true;
        });
    };
    UploadService.prototype.emptyQueue = function () {
        this.fileQueue = [];
        this.currentQueueSize = 0;
    };
    UploadService.prototype.abortAll = function (uploadInput) {
        this.emptyQueue();
        uploadInput.emit({ type: 'cancelAll' });
        uploadInput.emit({ type: 'removeAll' });
    };
    UploadService.prototype.uploadFilesOnQueue = function (uploadInput) {
        var maxSize = this.queueMaxSize * 1024 * 1024; // MiB
        if (this.currentQueueSize > maxSize) {
            this.abortAll(uploadInput);
            throw new Error("A soma do tamanho de todos os arquivos enviados n\u00E3o pode ser maior que " + this.queueMaxSize + "MiB.");
        }
        var currentFile = this.fileQueue.shift();
        var fileInformation = {
            description: currentFile.name,
            autoConvertToPdf: '0'
        };
        if (currentFile.type !== FILES.PDF) {
            fileInformation.autoConvertToPdf = '1';
        }
        if (currentFile) {
            var url = this.endpoint;
            if (environment.production) {
                url = environment.apiURL + environment.apiVersion + this.endpoint;
            }
            var uploadEvent = {
                type: 'uploadFile',
                url: url,
                method: 'POST',
                data: fileInformation,
                file: currentFile,
                headers: {
                    Authorization: "Bearer " + this.accessToken
                }
            };
            uploadInput.emit(uploadEvent);
        }
    };
    return UploadService;
}(BaseAPI));
export { UploadService };
