import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Output
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl
} from '@angular/forms';

import {
  WORKFLOW_CONTACT_ACTION,
  AuthorizationService,
  WorkflowBuildService,
  COUNTRY_CODES
} from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from '@app/core/models';
import { WorkflowContact } from '@app/core/class';

@Component({
  selector: 'app-workflow-build-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class WorkflowBuildContactFormComponent implements OnInit {
  @ViewChild('modalAddContact')
  public modalContact: ElementRef;

  @ViewChild('nameInput')
  public nameInput: ElementRef;

  public contactForm: FormGroup;

  @Output()
  public contactAdded: EventEmitter<void>;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private authorizationService: AuthorizationService,
    private workflowBuildService: WorkflowBuildService
  ) {
    this.contactAdded = new EventEmitter();
    this.createForm();
  }

  ngOnInit() {
    this.nameInput.nativeElement.focus();
  }

  public isEnabledWhatsapp(): boolean {
    if (this.authorizationService.getUser()) {
      return this.authorizationService.getUser().twilioBuEnabled;
    }
    return false;
  }

  public isEnabledWhatsappIntegration(): boolean {
    return this.workflowBuildService.getWhatsApp();
  }

  private shouldDisableWhatsapp(contact: Contact.Contact): Contact.Contact {
    contact.notifyWhatsapp = false;
    return contact;
  }

  public onContactsSelected(contacts: Contact.Contact[]): void {
    for (const contact of contacts) {
      this.addContact(this.shouldDisableWhatsapp(contact));
    }
    this.emitContactAdded();
  }

  public isIntegration(): boolean {
    return this.authorizationService.isIntegration();
  }

  public addMyselfToSign(): void {
    const myself = this.authorizationService.getUser();
    this.addContact({
      id: parseInt(myself.id),
      name: myself.name,
      email: myself.email,
      whatsappNumber: myself.whatsappNumber,
      notifyWhatsapp: false
    });
    this.emitContactAdded();
  }

  public submitContact(): void {
    const contact: Contact.Contact & { action: number } & {
      hasPhotoSign: boolean;
    } = this.contactForm.value;

    contact.whatsappNumber = contact.whatsappNumber
      ? `${COUNTRY_CODES.BR}${contact.whatsappNumber}`
      : '';
    this.addContact(contact, contact.action, contact.hasPhotoSign);

    this.emitContactAdded();
  }

  public addContact(
    contact: Contact.Contact,
    action?: number,
    hasPhotoSign?: boolean
  ): void {
    const workflowContact = WorkflowContact.MakeWorkflowContact(
      contact,
      action,
      hasPhotoSign
    );
    this.workflowBuildService.addContact(workflowContact);
  }

  public formControlValidation(control: AbstractControl): boolean {
    return !control.pristine && control.invalid;
  }

  /**
   * Open the contact modal
   */
  public openModal(content: ElementRef): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      size: 'lg',
      keyboard: false,
      centered: true,
      backdrop: 'static'
    });
  }

  public isValidInputWhatsapp(): boolean {
    return (
      !this.contactFormAdd.whatsappNumber.value ||
      this.contactFormAdd.whatsappNumber.value.length !== 11
    );
  }

  get contactFormAdd() {
    return this.contactForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm(): void {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(250)
        ])
      ],
      whatsappNumber: [''],
      notifyWhatsapp: [false],
      hasPhotoSign: [false],
      action: [WORKFLOW_CONTACT_ACTION.SIGN, Validators.required]
    });
  }

  private emitContactAdded(): void {
    this.contactAdded.emit();
  }
}
