import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl
} from '@angular/forms';
import * as moment from 'moment';

import { WorkflowFile, WorkflowContact } from '@app/core/class';
import { Workflow, Folder } from '@app/core/models';
import {
  WORKFLOW_PRIORITY,
  FolderService,
  AuthorizationService,
  WorkflowBuildService,
  DocumentService,
  FeedbackService,
  WorkflowTemplateService,
  RemainingUserBalanceService
} from '@app/core/services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Cancelable, debounce } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
export interface IWorkflowFileSettings {
  idFolder: number;
  nameFolder?: String;
  name: string;
  dueDate: string;
}

@Component({
  selector: 'app-workflow-review-page',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewPageComponent implements OnInit, OnDestroy {
  /**
   * This will be overritten by the integration module
   */
  public showFolderSelector: boolean = true;

  /**
   * This will be overritten by the integration module
   */
  public ShowSaveWorkflowTemplate: boolean = true;

  public isSavingWorkflow: boolean = false;

  /**
   * Message Form
   */
  public workflowSettingsForm: FormGroup;

  public workflowFileForm: FormGroup;

  public workflowFiles: WorkflowFile[] = [];
  public selectedWorkflowFile: WorkflowFile;

  public workflowContacts: WorkflowContact[] = [];

  private visibleFileObserver: Subscription;

  queryField = new FormControl();
  searchDebounce: (() => void) & Cancelable;
  public selectedFolder: Folder.Folder;
  public inFolderSelection: boolean = false;
  public spinnerWait: boolean = false;
  public idSelection: number;

  /**
   *Modal List folder
   */
  modalRef: any;

  /**
   * Modal
   */
  modalObj: any;

  public folders: Folder.FolderCollection;

  public minDueDate: string;

  protected buildURL: string = '/manager/workflow';
  protected finishURL: string = '/manager/workflow/finish';

  constructor(
    protected formBuilder: FormBuilder,
    protected folderService: FolderService,
    protected authorizationService: AuthorizationService,
    protected workflowBuildService: WorkflowBuildService,
    protected workflowTemplateService: WorkflowTemplateService,
    protected documentService: DocumentService,
    protected feedbackService: FeedbackService,
    protected router: Router,
    protected modalService: NgbModal,
    protected remainingUserBalance: RemainingUserBalanceService
  ) {
    this.modalObj = { ref: null, data: { text: 'Aguarde, enviando dados...' } };
    this.workflowFiles = [];
    this.workflowContacts = [];
    this.searchDebounce = debounce(this.getFolder.bind(this), 300);
  }

  public ngOnInit(): void {
    this.createWorkflowSettingsForm();
    this.createFileSettingsForm();

    this.feedbackService.clear();

    this.workflowFiles = this.workflowBuildService.getFiles();
    this.workflowContacts = this.workflowBuildService.getContacts();

    if (this.workflowFiles.length === 0 || this.workflowContacts.length === 0) {
      this.goToBuildPage();
      return;
    }

    this.visibleFileObserver = this.workflowBuildService
      .observeVisibleFile()
      .subscribe(visibleWorkflowFile => {
        this.selectedWorkflowFile = visibleWorkflowFile;
        this.defineFileSettings(this.selectedWorkflowFile);
      });

    // It should always be bigger than 0, but anyway...
    if (this.workflowFiles.length > 0) {
      this.workflowBuildService.setFileAsVisible(this.workflowFiles[0]);
    }

    this.minDueDate = moment()
      .add(1, 'day')
      .format('YYYY-MM-DD');

    this.getFolder();
  }

  public ngOnDestroy(): void {
    if (!!this.visibleFileObserver) {
      this.visibleFileObserver.unsubscribe();
    }
  }

  public ShowSaveTemplate(): boolean {
    return this.ShowSaveWorkflowTemplate;
  }

  public formatWhatsapp(whatsapp: string): string {
    const phone = whatsapp
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    return phone;
  }

  public isEnabledWhatsapp(): boolean {
    if (this.authorizationService.getUser()) {
      return this.authorizationService.getUser().twilioBuEnabled;
    }
    return false;
  }

  public isEnabledWhatsappIntegration(): boolean {
    return this.workflowBuildService.getWhatsApp();
  }

  public hasWorkflowFiles(): boolean {
    return this.workflowBuildService.hasFiles();
  }

  public onChangeWorkflowFile(workflowFile: WorkflowFile) {
    this.workflowBuildService.setFileAsVisible(workflowFile);
  }

  public saveFileSettings(): void {
    const fileForm = this.workflowFileForm.value as IWorkflowFileSettings;
    this.selectedWorkflowFile.setFolderId(fileForm.idFolder);
    this.selectedWorkflowFile.setName(fileForm.name);
    if (fileForm.dueDate) {
      this.selectedWorkflowFile.setDueDate(fileForm.dueDate);
    }
  }

  public saveWorkflowSettings(): void {
    const formSettings = this.parseWorkflowSettingFormValues();
    this.workflowBuildService.setSettings(formSettings);
  }

  public goToBuildPage(): void {
    this.saveWorkflowSettings();
    this.router.navigate([this.buildURL], { queryParamsHandling: 'merge' });
  }

  public goToFinishPage(): void {
    this.router.navigate([this.finishURL], { queryParamsHandling: 'merge' });
  }

  /**
   * Action by clicking in message form
   *
   * @param (number) status
   */
  public doSubmit(): void {
    if (!this.validateWorkflowSettingsForm()) {
      return;
    }

    this.saveWorkflowSettings();

    try {
      const compiledWorkflow = this.workflowBuildService.compile();

      // Se o workflow não tiver um nome, dá o nome do primeiro arquivo da lista
      if (!compiledWorkflow.name) {
        compiledWorkflow.name = compiledWorkflow.files[0].name;
      }

      if (this.workflowSettingsForm.value.saveTemplate) {
        this.workflowTemplateService
          .createWorkflowTemplate(compiledWorkflow.name, compiledWorkflow)
          .subscribe(() => {
            this.saveWorkflow(compiledWorkflow);
          });
      } else {
        this.saveWorkflow(compiledWorkflow);
      }
    } catch (error) {
      this.feedbackService.error(error.message);
    }
  }

  public isFormControlValid(control: AbstractControl): boolean {
    return control.errors && !control.pristine;
  }

  public forSelectedFolder(Folder: Folder.Folder): void {
    this.idSelection = Folder.id;
    this.selectedFolder = Folder;
    this.inFolderSelection = true;
  }

  public useSelectedFolder(): void {
    if (this.selectedFolder) {
      this.workflowBuildService.setDefaultFolderId(this.selectedFolder.id);
      this.workflowBuildService.setDefaultFolderName(this.selectedFolder.name);

      this.workflowFileForm.get('idFolder').setValue(this.selectedFolder.id);
      this.workflowFileForm
        .get('nameFolder')
        .setValue(
          this.selectedFolder.name === 'default'
            ? 'Padrão'
            : this.selectedFolder.name
        );

      this.saveFileSettings();
    }

    this.modalRef.close();
  }

  public getFolder(): void {
    const filter: Array<object> = [];

    if (this.queryField.value) {
      if (!isNaN(parseInt(this.queryField.value))) {
        filter.push({
          type: 'eq',
          field: 'id',
          value: this.queryField.value
        });
      } else {
        filter.push({
          type: 'like',
          field: 'name',
          value: `%${this.queryField.value}%`
        });
      }

      const queryStringFilter = {
        filter
      };

      this.fetchFolders(queryStringFilter);
    } else {
      const queryStringFilter = {
        pageSize: '1000'
      };

      this.fetchFolders(queryStringFilter);
    }
  }

  protected fetchFolders(querystring: object): void {
    this.spinnerWait = true;
    this.folderService
      .searchFolder(querystring)
      .subscribe((body: Folder.FolderCollection) => {
        this.folders = body;
        this.spinnerWait = false;
      });
  }

  protected saveWorkflow(workflow: Workflow.Settings): void {
    this.isSavingWorkflow = true;

    const createWorkflowSubscription = this.documentService
      .create(workflow)
      .subscribe(
        (response: { id: number }) => {
          this.feedbackService.clear();

          if (!!createWorkflowSubscription) {
            createWorkflowSubscription.unsubscribe();
          }

          /**
           * User remaining balance
           */
          this.remainingUserBalance.getPlanUser();

          this.workflowBuildService.setWorkflowId(response.id);

          if (!this.firstSignerIsUserHimself()) {
            this.isSavingWorkflow = false;
            this.workflowBuildService.resetWorkflow();
            this.goToFinishPage();
            return;
          }

          this.documentService.getById(response.id).subscribe(workflow => {
            const workflowSteps = {};
            workflow.documents.forEach((document: any) => {
              if (
                document.steps.recipients &&
                document.steps.recipients.length
              ) {
                document.steps.recipients.forEach((step: any) => {
                  workflowSteps[step.stepId] = step;
                });
              }
            });

            const steps: any[] = Object.values(workflowSteps).sort(
              (a: any, b: any) => {
                if (a.stepId < b.stepId) {
                  return -1;
                }
                if (a.stepId > b.stepId) {
                  return 1;
                }
                return 0;
              }
            );

            const step = steps[0] || {};
            const user = this.authorizationService.getUser();
            const token = btoa(
              JSON.stringify({ u: user.email, id: step.stepId })
            );
            this.workflowBuildService.resetWorkflow();

            this.router.navigate([`/subscribe/${token}`]);
          });
        },
        (request: any) => {
          this.isSavingWorkflow = false;
          this.feedbackService.error(request.error.detail);

          if (!!createWorkflowSubscription) {
            createWorkflowSubscription.unsubscribe();
          }
        }
      );
  }

  private parseWorkflowSettingFormValues(): Workflow.Settings {
    const formSettings = this.workflowSettingsForm.value;
    const partialSettings: Workflow.Settings = {
      name: formSettings.name,
      message: formSettings.message,
      priority: parseInt(formSettings.priority),
      dueDate: moment(formSettings.dueDate)
        .startOf('day')
        .format('YYYY-MM-DD 23:59:59')
    };
    return partialSettings;
  }

  private validateWorkflowSettingsForm(): boolean {
    this.feedbackService.clear();
    if (this.workflowSettingsForm.controls.message.errors) {
      this.feedbackService.error(
        'A mensagem informada deve ter apenas 250 caracteres.'
      );
      return false;
    }

    if (this.workflowSettingsForm.controls.name.errors) {
      this.feedbackService.error(
        'A nome informado deve ter apenas 60 caracteres.'
      );
      return false;
    }

    const user = this.authorizationService.getUser();
    if (user && user.itens !== undefined && user.itens === 10000) {
      this.modalObj = {
        ref: null,
        data: {
          title: 'Ops! Você não pode mais enviar documentos.',
          text: 'Seu plano atingiu o máximo de documentos enviados.'
        }
      };
      return false;
    }

    return true;
  }

  private firstSignerIsUserHimself(): boolean {
    const firstContact = this.workflowBuildService.getContacts()[0];
    const user = this.authorizationService.getUser();

    return firstContact.email === user.email;
  }

  private defineFileSettings(file: WorkflowFile, nameFolder?: string): void {
    if (!file) {
      return;
    }

    const idFolder =
      file.getFolderId() || this.workflowBuildService.getDefaultFolderId();

    this.workflowFileForm.setValue({
      idFolder,
      nameFolder:
        this.workflowBuildService.getDefaultFolderName() === 'default'
          ? 'Padrão'
          : this.workflowBuildService.getDefaultFolderName(),
      name: file.getName(),
      dueDate: file
        .getDueDate()
        .split(' ')
        .shift()
    });
    this.workflowFileForm.clearValidators();
  }

  private createFileSettingsForm(): void {
    const defaultFolderId = this.workflowBuildService.getDefaultFolderId();
    const defaultFolderName = this.workflowBuildService.getDefaultFolderName();

    this.workflowFileForm = this.formBuilder.group({
      name: ['', Validators.maxLength(60)],
      dueDate: [''],
      nameFolder: [defaultFolderName],
      idFolder: [defaultFolderId]
    });
  }

  public openModalListFolders(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: content.size || 'md'
    });
    return this.modalRef;
  }

  /**
   * Form creation and validation configuration
   */
  private createWorkflowSettingsForm() {
    const settings = this.workflowBuildService.getSettings();

    let dueDate = settings.dueDate;
    if (dueDate && dueDate.includes(' ')) {
      dueDate = dueDate.split(' ').shift();
    } else {
      dueDate = moment()
        .add(1, 'years')
        .format('YYYY-MM-DD');
    }

    this.workflowSettingsForm = this.formBuilder.group({
      name: [settings.name || '', Validators.maxLength(60)],
      message: [settings.message || '', Validators.maxLength(2000)],
      priority: [
        settings.priority || WORKFLOW_PRIORITY.NORMAL,
        Validators.required
      ],
      dueDate: [dueDate],
      saveTemplate: [false]
    });
  }
}
