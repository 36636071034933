import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { parseDate } from '@app/core/helpers/date';
import { WORKFLOW_STEP_ACTION, WORKFLOW_TEXT_STATUS, WORKFLOW_STEP_TEXT_STATUS, WORKFLOW_STEP_NEXT_ACTION, WORKFLOW_SIGNATURE_TYPE, WORKFLOW_STEP_STATUS, WORKFLOW_STEP_FIELDS, WORKFLOW_STATUS } from '@app/core/services/workflow.enum';
import { AuthorizationService } from './authorization.service';
import { HttpClient } from '@angular/common/http';
import { WorkflowStep } from '../models';
/**
 * Provides a base for workflow.
 */
var WorkflowService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowService, _super);
    function WorkflowService(httpClient, authorizationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authorizationService = authorizationService;
        _this.endpoint = '/workflow-step';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Gets all data of a service
     */
    WorkflowService.prototype.getAll = function (queryString) {
        var _this = this;
        if (this.endpoint === '/workflow-step') {
            queryString['hideExtrametadata'] = 1;
        }
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this._parseListWorkflow(response);
        }));
    };
    WorkflowService.prototype.getSignatureInPerson = function (queryString) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this.parseSignatureInPerson(response);
        }));
    };
    WorkflowService.prototype.filterByPeriod = function (period) {
        var _this = this;
        var filter = "?filter[0][type]=innerjoin&\n    filter[0][field]=workflow&\n    filter[0][alias]=w&\n    filter[1][type]=gte&\n    filter[1][alias]=w&\n    filter[1][field]=dateCreated&\n    filter[1][value]=" + period;
        if (this.endpoint === '/workflow-step') {
            filter += '&hideExtrametadata=1';
        }
        return this.httpClient.get("" + this.endpoint + filter).pipe(map(function (response) {
            return _this._parseListWorkflow(response);
        }));
    };
    WorkflowService.prototype.countByStatus = function (status) {
        var endpoint = '/workflow';
        var filter = "?filter[0][field]=status&filter[0][type]=eq&filter[0][value]=" + status + "&pageSize=1";
        return this.httpClient.get("" + endpoint + filter).pipe(map(function (response) {
            return response.total_items;
        }));
    };
    WorkflowService.prototype.countByStatusAndPeriod = function (status, period) {
        var filter = "?filter[0][field]=status&\n    filter[0][type]=eq&\n    filter[0][value]=" + status + "&\n    filter[1][type]=gte&\n    filter[1][alias]=w&\n    filter[1][field]=workflow&\n    filter[1][child]=dateCreated&\n    filter[1][value]=" + period + "&\n    order-by[0][type]=field&\n    order-by[0][field]=id&\n    order-by[0][direction]=desc";
        if (this.endpoint === '/workflow-step') {
            filter += '&hideExtrametadata=1';
        }
        return this.httpClient.get("" + this.endpoint + filter).pipe(map(function (response) {
            return response.total_items;
        }));
    };
    /**
     * Gets workflow of a service
     */
    WorkflowService.prototype.getWorkflow = function (queryString, q) {
        var _this = this;
        var options;
        if (q) {
            options = { params: q };
        }
        return this.httpClient.get(this.endpoint + "/" + queryString, options).pipe(map(function (body) {
            return _this._parseWorkflow(body);
        }));
    };
    /**
     * Gets document of a service
     */
    WorkflowService.prototype.assignDocument = function (code, data) {
        var _this = this;
        return this.httpClient.patch(this.endpoint + "/" + code, data).pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Gets document of a service
     */
    WorkflowService.prototype.createDocument = function (data) {
        var _this = this;
        return this.httpClient.post(this.endpoint, data).pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Parse list of workflow data and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    WorkflowService.prototype._parseListWorkflow = function (data) {
        var _this = this;
        var items = data._embedded.workflow_step.map(function (item) {
            return {
                step: {
                    id: item.id,
                    action: {
                        id: item.action,
                        type: WORKFLOW_STEP_ACTION[item.action],
                        text: WORKFLOW_STEP_NEXT_ACTION[item.action]
                    },
                    latitude: item.latitude,
                    longitude: item.longitude,
                    reason: item.reason,
                    signatureType: {
                        id: item.signatureType,
                        text: WORKFLOW_SIGNATURE_TYPE[item.signatureType]
                    },
                    status: {
                        id: item.status,
                        text: _this._parseStepText(item._embedded.workflow.status, item.status)
                    },
                    dateStatus: parseDate(item.status),
                    metadata: item.metadata
                },
                workflow: {
                    id: item._embedded.workflow.id,
                    priority: item._embedded.workflow.priority,
                    autoRemind: item._embedded.workflow.autoRemind,
                    sla: item._embedded.workflow.sla,
                    message: item._embedded.workflow.message,
                    name: item._embedded.workflow.name,
                    status: {
                        id: item._embedded.workflow.status,
                        text: WORKFLOW_TEXT_STATUS[item._embedded.workflow.status]
                    },
                    date: {
                        dueDate: parseDate(item._embedded.workflow.dueDate),
                        dateCreated: parseDate(item._embedded.workflow.dateCreated),
                        dateStatus: parseDate(item._embedded.workflow.dateStatus),
                        dateFinished: parseDate(item._embedded.workflow.dateFinished)
                    },
                    user: _this._parseUser(item._embedded.workflow._embedded.user)
                }
            };
        });
        return {
            items: items,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items,
            _raw: data._embedded,
            _links: data._links
        };
    };
    WorkflowService.prototype.parseSignatureInPerson = function (data) {
        var items = [];
        if (data.total_items > 0) {
            items = data._embedded.workflow_step.map(function (item) {
                var extraMetadata = JSON.parse(item.extraMetadata);
                return {
                    id: item.id,
                    action: item.action,
                    hasPhotoSign: item.hasPhotoSign,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    reason: item.reason,
                    signatureType: item.signatureType,
                    status: {
                        id: item.status,
                        text: WORKFLOW_STEP_TEXT_STATUS[item.status]
                    },
                    dateStatus: parseDate(item.dateStatus),
                    extraMetadata: extraMetadata,
                    workflow: {
                        id: item._embedded.workflow.id,
                        name: item._embedded.workflow.name,
                        message: item._embedded.workflow.message,
                        priority: item._embedded.workflow.priority,
                        sla: item._embedded.workflow.sla,
                        status: {
                            id: item._embedded.workflow.status,
                            text: WORKFLOW_TEXT_STATUS[item._embedded.workflow.status]
                        },
                        autoRemind: item._embedded.workflow.autoRemind,
                        dateCreated: parseDate(item._embedded.workflow.dateCreated),
                        dateFinished: parseDate(item._embedded.workflow.dateFinished),
                        dateStatus: parseDate(item._embedded.workflow.dateStatus),
                        dueDate: parseDate(item._embedded.workflow.dueDate),
                        user: {
                            id: item._embedded.workflow._embedded.user.id,
                            name: item._embedded.workflow._embedded.user.name,
                            email: item._embedded.workflow._embedded.user.email
                        }
                    }
                };
            });
        }
        return {
            items: items,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items
        };
    };
    /**
     * Parse of workflow data and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    WorkflowService.prototype._parseWorkflow = function (data) {
        var _this = this;
        var embeddedDocuments = data._embedded.documents;
        var user = this._getCurrentSubscriber(embeddedDocuments);
        var documents = embeddedDocuments.map(function (item) {
            var recipients = _this._parseSubscribers(item.workflowSteps);
            var document = {
                id: item.id,
                docId: item.docId,
                name: item.name,
                status: item.status,
                folder: item._embedded.folder,
                file: {
                    id: item._embedded.originalFile.id,
                    checksum: item._embedded.originalFile.checksum,
                    link: item._embedded.originalFile._links.download.href
                },
                steps: {
                    recipients: recipients,
                    workflowSteps: item.workflowSteps,
                    total: item.workflowSteps.length,
                    sign: _this._filterSubscribers(recipients, [
                        WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.WAITING].action,
                        WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.NOT_STARTED].action
                    ]),
                    signed: _this._filterSubscribers(recipients, [
                        WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.APPROVED].action
                    ]),
                    rejected: _this._filterSubscribers(recipients, [
                        WORKFLOW_STEP_TEXT_STATUS[WORKFLOW_STEP_STATUS.REJECTED].action
                    ])
                },
                signature: _this._getCurrentSignature(user)
            };
            if (item._embedded.originalFile) {
                document['originalFile'] = {
                    id: item._embedded.originalFile.id,
                    checksum: item._embedded.originalFile.checksum,
                    link: item._embedded.originalFile._links.download.href
                };
            }
            if (item._embedded.file) {
                document['signedFile'] = {
                    id: item._embedded.file.id,
                    checksum: item._embedded.file.checksum,
                    link: item._embedded.file._links.download.href
                };
            }
            var signaturePageId = function () {
                var data = item.docId.split('checker?q=');
                return data[1];
            };
            document['signaturePageId'] = signaturePageId();
            var signaturePageLink = function () {
                var apiLink = document.file.link.split('/file?q=')[0];
                return (apiLink + '/document?q=download&docId=' + document['signaturePageId']);
            };
            document['signaturePageLink'] = signaturePageLink();
            return document;
        });
        var workflow = {
            detail: data.detail,
            owner: data._embedded.user,
            currentUser: user,
            workflow: {
                id: data.id,
                sla: data.sla,
                type: data.type,
                status: data.status,
                priority: data.priority,
                message: data.message,
                autoRemind: data.autoRemind,
                download: embeddedDocuments[0]._embedded.originalFile._links.download.href,
                date: {
                    dueDate: parseDate(data.dueDate),
                    dateCreated: parseDate(data.dateCreated)
                }
            },
            documents: documents
        };
        if (embeddedDocuments[0]._embedded.file) {
            workflow.workflow.download =
                embeddedDocuments[0]._embedded.file._links.download.href;
        }
        return workflow;
    };
    /**
     * Get data of current Subscriber
     *
     * @param (any) subscribers collection
     */
    WorkflowService.prototype._getCurrentSubscriber = function (documents) {
        var workflowSteps = {};
        documents.forEach(function (document) {
            if (document.workflowSteps && document.workflowSteps.length) {
                document.workflowSteps.forEach(function (step) {
                    workflowSteps[step.id] = step;
                });
            }
        });
        var subscribers = Object.values(workflowSteps).sort(function (a, b) {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
        var currentUser = subscribers.find(function (element) { return element.status === WORKFLOW_STEP_STATUS.WAITING; });
        var info = currentUser || subscribers[0];
        var output = {
            action: info.action,
            hasPhotoSign: info.hasPhotoSign,
            fields: info.fields,
            signatureType: info.signatureType,
            latitude: info.latitude,
            longitude: info.longitude,
            reason: info.reason,
            status: info.status,
            user: this._parseUser(info._embedded.user),
            date: parseDate(info.dateStatus)
        };
        var documentfields = documents.reduce(function (ret, doc) {
            var step = doc.workflowSteps.filter(function (stepItem) { return output.user.id === stepItem._embedded.user.id; });
            if (step && step.length) {
                var doc_fields = step[0].fields.map(function (field) {
                    field.document = {
                        id: doc.id,
                        name: doc.name
                    };
                    return field;
                });
                ret = ret.concat(doc_fields);
            }
            return ret;
        }, []);
        output.documentsFields = documentfields;
        return output;
    };
    /**
     * Get data of signature
     *
     * @param (any) subscriber
     */
    WorkflowService.prototype._getCurrentSignature = function (subscriber) {
        if (!subscriber) {
            return null;
        }
        var signature = subscriber.fields.filter(function (item) {
            return WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE === item.type;
        });
        return signature.length > 0 ? signature[0] : null;
    };
    /**
     * Parse of document creator data and adjusts to
     * default for printing the component
     *
     * @param (any) user object
     */
    WorkflowService.prototype._parseUser = function (user) {
        return {
            id: user.id,
            name: user.name,
            initial: this._getInitials(user.name),
            email: user.email
        };
    };
    WorkflowService.prototype._getInitials = function (name) {
        var slices = name.split(' ');
        var size = slices.length;
        var initials = slices[0].charAt(0);
        if (size > 1) {
            initials += slices[size - 1].charAt(0);
        }
        return initials;
    };
    /**
     * Parse of subscriber data and adjusts to
     * default for printing the component
     *
     * @param (any) subscribers collection
     */
    WorkflowService.prototype._parseSubscribers = function (subscribers) {
        return subscribers.map(function (item) {
            return {
                id: item._embedded.user.id,
                name: item._embedded.user.name,
                email: item._embedded.user.email,
                status: WORKFLOW_STEP_TEXT_STATUS[item.status],
                fields: item.fields,
                details: {
                    action: {
                        id: item.action,
                        type: WORKFLOW_STEP_ACTION[item.action],
                        text: WORKFLOW_STEP_NEXT_ACTION[item.action]
                    },
                    latitude: item.latitude,
                    longitude: item.longitude,
                    reason: item.reason,
                    signatureType: item.signatureType,
                    extraMetadata: item.extraMetadata,
                    dateStatus: parseDate(item.dateStatus),
                    status: {
                        id: item.status,
                        text: WORKFLOW_STEP_TEXT_STATUS[item.status]
                    }
                }
            };
        });
    };
    /**
     * filter on subscribers to group by type
     *
     * @param (any) subscribers collection
     * @param (any) group of type
     */
    WorkflowService.prototype._filterSubscribers = function (subscribers, group) {
        return subscribers.filter(function (elem, i) {
            return group.indexOf(elem.status.action) !== -1;
        });
    };
    WorkflowService.prototype._parseStepText = function (workflowId, stepId) {
        if (workflowId === WORKFLOW_STATUS.CIRCULATING) {
            return WORKFLOW_STEP_TEXT_STATUS[stepId];
        }
        return WORKFLOW_TEXT_STATUS[workflowId];
    };
    return WorkflowService;
}(BaseAPI));
export { WorkflowService };
