/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-stamp.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feedback-stamp.component";
var styles_FeedbackStampComponent = [i0.styles];
var RenderType_FeedbackStampComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackStampComponent, data: {} });
export { RenderType_FeedbackStampComponent as RenderType_FeedbackStampComponent };
export function View_FeedbackStampComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-custom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Deseja replicar o carimbo em todas as p\u00E1ginas do documento? Essa a\u00E7\u00E3o n\u00E3o\n    poder\u00E1 ser desfeita.\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-default btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    N\u00E3o\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn btn-secondary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sim"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_FeedbackStampComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-stamp", [], null, null, null, View_FeedbackStampComponent_0, RenderType_FeedbackStampComponent)), i1.ɵdid(1, 114688, null, 0, i2.FeedbackStampComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackStampComponentNgFactory = i1.ɵccf("app-feedback-stamp", i2.FeedbackStampComponent, View_FeedbackStampComponent_Host_0, { modal: "modal" }, {}, []);
export { FeedbackStampComponentNgFactory as FeedbackStampComponentNgFactory };
