import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Plan } from '../models';

/**
 * Provides a base for choose plan workflow.
 */
@Injectable()
export class PlansService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/plan';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  public getPlans(): Observable<Plan.PlanCollection> {
    return super.getAll().pipe(
      map((response: any) => {
        return this.parsePlans(response);
      })
    );
  }

  private parsePlans(data: any): Plan.PlanCollection {
    let plans: Plan.Plans[] = [];

    if (data.total_items > 0) {
      plans = data._embedded.plan.map((plan: Plan.Plans) => {
        const price = this.parsePrice(plan.price);
        const cents = this.parseCents(plan.price);
        const description = this.formatText(plan.description);

        const {
          id,
          name,
          shortDescription,
          type,
          usagePerUser,
          usageGlobal,
          usageAmount,
          maxUsers,
          paymentMethod,
          currency,
          billingInterval,
          billingIntervalCount,
          billingType,
          billingDay,
          boletoDueDays,
          statementDescriptor,
          status,
          isDefault
        } = plan;

        return {
          id,
          name,
          shortDescription,
          description,
          usageGlobal,
          usageAmount,
          maxUsers,
          paymentMethod,
          billingInterval,
          price,
          cents,
          status,
          isDefault
        };
      });
    }
    return {
      plan: plans,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items
    };
  }

  public subscription(payload: object): Observable<any> {
    return this.httpClient
      .post('/plan-subscription', this.mapFields(payload))
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  public cancelPlan(id: any): Observable<any> {
    return this.httpClient.delete('/plan-subscription/' + id).pipe(body => {
      return body;
    });
  }

  public formatText(text: string): object {
    const re = /\s*;\s*/;
    return text.split(re);
  }

  private parsePrice(price: any): string {
    return price.slice(0, -2);
  }

  private parseCents(price: any): string {
    return price.slice(-2);
  }
}
