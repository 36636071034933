import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WorkflowService } from '@app/core/services';
import { Document } from '@app/core/models';
import { Logger } from '@app/core/class';
import { ModalComponent } from '@app/shared/components';

const log = new Logger('DocumentsPage');

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsPageComponent implements OnInit {
  /**
   * loading of form
   */
  isLoading: any;

  /**
   * collection with data
   */
  collectionDocument: any;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * config items of the dropdown buttons
   */
  configDropdownItems: any;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current user for edit/delete
   */
  item: any;

  /**
   * array to fill the menu of navigation-lists component
   */
  pages: any = [
    { name: 'Meus Workflows', link: '/manager/workflow/my' },
    { name: 'Workflows', link: '/manager/workflow/other' },
    { name: 'Contatos', link: '/manager/contacts' },
    { name: 'Pastas', link: '/manager/folders' }
  ];

  constructor(
    private workflowService: WorkflowService,
    private modalService: NgbModal
  ) {
    this.collectionDocument = { items: [] };
  }

  ngOnInit() {
    this.isLoading = this.showModalLoading();
    this.getDocuments();

    this.sortFields = { name: 'ASC', data: 'ASC', status: 'ASC' };
    this.configList = [
      {
        name: '',
        klass: '',
        type: 'checkbox',
        fieldId: 'id'
      },
      {
        name: '',
        klass: '',
        type: 'icon',
        fieldId: 'status'
      },
      {
        name: 'Nome',
        klass: '',
        type: 'text',
        fieldId: 'id',
        isSortable: true
      },
      {
        name: 'Data',
        klass: '',
        type: 'date',
        fieldId: 'date',
        isSortable: true
      },
      {
        name: 'Status',
        klass: '',
        type: 'object',
        fieldId: 'status',
        isSortable: true
      },
      {
        name: 'Ações',
        type: 'button',
        icon: 'fa fa-sliders-h',
        options: {
          changeLabel: true,
          primary: {
            name: 'Assinar',
            action: 'sign'
          },
          items: [
            { name: 'Assinar', action: 'sign' },
            { name: 'Enviar novamente', action: 'resend' },
            { name: 'Mover', action: 'move' },
            { name: 'Arquivar', action: 'archive' },
            { name: 'Rejeitar', action: 'reject' }
          ]
        }
      }
    ];

    this.configDropdownItems = {
      archive: [],
      sign: [
        { name: 'Mover', action: 'move' },
        { name: 'Arquivar', action: 'archive' }
      ],
      move: [{ name: 'Arquivar', action: 'archive' }],
      reject: [
        { name: 'Enviar novamente', action: 'resend' },
        { name: 'Mover', action: 'move' },
        { name: 'Arquivar', action: 'archive' },
        { name: 'Rejeitar', action: 'reject' }
      ]
    };
  }

  /**
   * provides the data of documents
   *
   * @return void
   */
  getDocuments() {
    try {
      this.workflowService
        .getAll()
        .pipe()
        .subscribe(
          (response: any) => {
            log.debug(`Success [getWorkflows]:`, response);
            this.collectionDocument = response;
            this.isLoading.close('close');
          },
          (error: any) => {
            log.debug(`Error [getWorkflows]:`, error);
            this.collectionDocument = { items: [] };
            this.isLoading.close('close');
          }
        );
    } catch (e) {
      log.debug(`Error [getWorkflows]:`, e);
      this.collectionDocument = { items: [] };
      this.isLoading.close('close');
    }
  }

  /**
   * Action grab all selected items
   *
   * @param (any) items selected
   */
  getSelectedItems(items: any) {
    this.selectedItems = items;
  }

  /**
   * Action grab clicked item
   *
   * @param (any) clicked item
   */
  getClickedItem(item: any) {
    this.item = item;
  }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    this.sortFields[field] = this.reorder(this.sortFields[field]);
  }

  /**
   * Action grab data
   *
   * @param (any) items selected
   */
  doAction(items: any) {}

  /**
   * delete marked contacts
   *
   * @return void
   */
  deleteMarkedContacts() {}

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }

  /**
   * Show modal
   */
  private showModalLoading(): any {
    const modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });

    modalRef.componentInstance.type = 'loading';
    modalRef.componentInstance.showHeader = false;
    modalRef.componentInstance.showFooter = false;
    modalRef.componentInstance.showClose = false;

    modalRef.componentInstance.title = '';
    modalRef.componentInstance.text = 'Aguarde, carregando dados...';

    return modalRef;
  }
}
