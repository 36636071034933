import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';

import { Logger } from '@app/core/class';
import { ManagerRoutingPath } from '@app/manager';
import {
  AuthorizationService,
  MarketsegmentService,
  UserService
} from '@app/core/services';
import { matchFields } from '@app/core/helpers';
import { Authentication, PolicyDocument, Segment } from '@app/core/models';
import { Documents } from 'assets/policy/document';
import { RecaptchaComponent } from 'ng-recaptcha';

const log = new Logger('RegisterPage');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cellphone') cellphone: ElementRef;

  /**
   * Data of register form
   */
  public registerForm: FormGroup;

  /**
   * Informs if loading is active or not
   */
  public isLoading: boolean = false;

  /**
   * Feedback component message
   */
  public feedbackMessage: string;

  /**
   * Feedback component theme
   */
  public feedbackTheme: string;

  /**
   * market segment list
   */
  segmentList: Array<Segment.MarketSegmentPayload> = this.marketsegment.list();

  public termsOfUse: PolicyDocument.File;
  public privacyPolicy: PolicyDocument.File;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private marketsegment: MarketsegmentService
  ) {
    this.termsOfUse = Documents.termsOfUse();
    this.privacyPolicy = Documents.privacyPolicy();
  }

  ngAfterViewInit() {
    if (this.cellphone) {
      this.cellphone.nativeElement.parentElement.style = 'display: block;';
    }
  }

  ngOnInit() {
    this.createForm();

    if (this.authorizationService.isAuthenticated()) {
      log.debug(`user logged, redirect to /manager`);
      window.location.href = ManagerRoutingPath.MANAGER;
    }
  }

  ngOnDestroy() {
    RecaptchaComponent.prototype.ngOnDestroy = function() {
      // reset the captcha to ensure it does not leave anything behind
      // after the component is no longer needed
      this.grecaptchaReset();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
  }

  public getNumber(number: number): void {
    this.registerForm.value.cellphone = number;
  }

  public isChangeLoading(): void {
    this.isLoading = !this.isLoading;
  }

  private resetRegisterForm(): void {
    this.registerForm.reset();
  }

  public resolved(captchaResponse: string) {
    log.debug(`Register [Resolved captcha with response]: ${captchaResponse}`);
  }

  private recaptchaReset(): void {
    grecaptcha.reset();
  }

  private shouldResetValueRecaptcha(): void {
    this.registerForm.controls.recaptcha.setValue('');
  }

  public shouldResetRecaptchaAndForm(): void {
    this.recaptchaReset();
    this.shouldResetValueRecaptcha();
  }

  private payload(): Authentication.RegisterPayload {
    const payload: Authentication.RegisterPayload = {
      name: this.registerForm.value.name,
      position: this.registerForm.value.position,
      segment: this.registerForm.value.segment,
      cellphone: this.registerForm.value.cellphone,
      email: this.registerForm.value.email,
      username: this.registerForm.value.email,
      password: this.registerForm.value.password,
      company: this.registerForm.value.company,
      businessUnit: {
        name: this.registerForm.value.company,
        segmentMarket: this.registerForm.value.segment
      },
      recaptcha: this.registerForm.value.recaptcha
    };
    return payload;
  }

  doSubmit() {
    const payload = this.payload();
    this.isChangeLoading();

    this.userService.create(payload).subscribe(
      data => {
        log.info(`New user ID => ${data.id}`);
        this.resetRegisterForm();
        this.shouldResetRecaptchaAndForm();
        this.router.navigate(['/auth/login'], {
          queryParams: { register: data.id }
        });
      },

      err => {
        this.shouldResetRecaptchaAndForm();
        if (err.error.status === 422 && err.error.reason === '2') {
          this.userService.setTempNewUserData(this.registerForm.value);
          this.router.navigate(['auth/activate-user']);
        }
        this.isChangeLoading();

        log.error(`Error is: ${err.error.detail}`);
        this.feedbackMessage = err.error.detail;
        this.feedbackTheme = 'error';
      }
    );
  }

  public isNotValidField(controlName: string): boolean {
    return (
      (this.f[controlName].errors && !this.f[controlName].pristine) ||
      (this.group.get(controlName).hasError('required') &&
        this.group.get(controlName).touched)
    );
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.registerForm.controls;
  }

  get group() {
    return this.registerForm;
  }

  public createForm(): void {
    this.registerForm = this.formBuilder.group(
      {
        name: ['', Validators.compose([Validators.required])],
        email: ['', [Validators.required, Validators.email]],
        cellphone: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(11)
          ])
        ],
        position: ['', [Validators.required]],
        segment: ['', [Validators.required]],
        company: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
          ]
        ],
        repeatPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
          ]
        ],
        policy: [
          false,
          [
            Validators.required,
            Validators.min(1),
            Validators.pattern('^([Tt][Rr][Uu][Ee])$')
          ]
        ],
        recaptcha: ['', Validators.required]
      },
      { validator: matchFields('password', 'repeatPassword') }
    );
  }
}
