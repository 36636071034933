import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { WorkflowBuildService } from '@app/core/services';
import { WorkflowFile, WorkflowContact } from '@app/core/class';
import { Router } from '@angular/router';
import { WorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.class';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';

@Component({
  selector: 'app-workflow-build-page',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss']
})
export class BuildPageComponent implements OnInit, OnDestroy {
  /**
   * This will be overritten by the integration module
   */
  public showUpload: boolean = true;

  public workflowFiles: WorkflowFile[] = [];
  public workflowContacts: WorkflowContact[] = [];

  public selectedWorkflowFile: WorkflowFile;

  private contactsObserver: Subscription;
  private filesObserver: Subscription;
  private visibleFileObserver: Subscription;

  public reviewURL: string = '/manager/workflow/review';

  constructor(
    private workflowBuildService: WorkflowBuildService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.workflowFiles = this.workflowBuildService.getFiles();
    this.filesObserver = this.workflowBuildService
      .observeFiles()
      .subscribe(workflowFiles => {
        this.workflowFiles = workflowFiles;
      });

    this.workflowContacts = this.workflowBuildService.getContacts();
    this.contactsObserver = this.workflowBuildService
      .observeContacts()
      .subscribe(workflowContacts => {
        this.workflowContacts = workflowContacts;
      });

    this.selectedWorkflowFile = this.workflowBuildService.getVisibleFile();
    this.visibleFileObserver = this.workflowBuildService
      .observeVisibleFile()
      .subscribe(visibleFile => {
        this.selectedWorkflowFile = visibleFile;
      });
  }

  public ngOnDestroy(): void {
    if (!!this.filesObserver) {
      this.filesObserver.unsubscribe();
    }

    if (!!this.contactsObserver) {
      this.contactsObserver.unsubscribe();
    }

    if (!!this.visibleFileObserver) {
      this.visibleFileObserver.unsubscribe();
    }
  }

  public hasBuildFiles(): boolean {
    return this.workflowBuildService.hasFiles();
  }

  public hasContacts(): boolean {
    return this.workflowBuildService.hasContacts();
  }

  public onChangeFile(file: WorkflowFile) {
    this.workflowBuildService.setFileAsVisible(file);
  }

  public canAdvanceToReview(): boolean {
    return (
      this.workflowFiles.length > 0 &&
      this.workflowContacts.length > 0 &&
      this.hasAtLeastOneSignatureInSomeFile()
    );
  }

  public canAdvanceToReviewText(): string {
    if (!this.hasAtLeastOneSignatureInSomeFile()) {
      return 'Adicione o mesmo tipo de assinatura do contato em todos os documentos antes de prosseguir';
    }

    if (this.workflowFiles.length === 0 || this.workflowContacts.length === 0) {
      return 'Adicione contatos e documentos antes de prosseguir';
    }

    return '';
  }

  public navigateToWorkflowReview(): void {
    this.router.navigate([this.reviewURL], { queryParamsHandling: 'merge' });
  }

  public removeFileFromWorkflow(file: WorkflowFile) {
    this.workflowBuildService.removeFile(file);

    const workflowFiles = this.workflowBuildService.getFiles();

    if (workflowFiles.length > 0) {
      this.workflowBuildService.setFileAsVisible(workflowFiles[0]);
      return;
    }

    this.workflowBuildService.setFileAsVisible(null);
  }

  /**
   * For each contact, search in all files added
   * for at least one signature field
   */
  private hasAtLeastOneSignatureInSomeFile(): boolean {
    const contactsWithSign = {};
    // For each contact added
    this.workflowContacts.forEach((contact, index) => {
      for (const file of this.workflowFiles) {
        // For a signature field
        for (const field of file.getAllFields()) {
          // If there is at least one signature, set to true
          if (field.isSignature() && field.getContact() === contact) {
            contactsWithSign[`${contact.email}${index}`] = true;
            break;
          }
        }
        if (contactsWithSign[`${contact.email}${index}`]) {
          break;
        }
      }
    });

    return (
      Object.keys(contactsWithSign).length === this.workflowContacts.length
    );
  }

  // /**
  //  * Check wether if a file has a dropped signature field
  //  * while others have nothing
  //  */
  // private hasMissingSignatureOnOtherFiles(): boolean {
  //   for (const contact of this.workflowContacts) {
  //     const signatures = this.getSignatureFieldsOfContact(contact);
  //     // If there is any signature for the contact and it is not
  //     // the same amounth of files added, than it is missing a signature
  //     if (
  //       signatures.length > 0 &&
  //       signatures.length !== this.workflowFiles.length
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // private getSignatureFieldsOfContact(
  //   workflowContact: WorkflowContact
  // ): IWorkflowDroppedField[] {
  //   const signatures: IWorkflowDroppedField[] = [];

  //   for (const file of this.workflowFiles) {
  //     for (const field of file.getAllFields()) {
  //       if (field.isSignature() && field.getContact() === workflowContact) {
  //         signatures.push(field);
  //       }
  //     }
  //   }

  //   return signatures;
  // }
}
