<div class="box mt-3">
  <div class="box-content">
    <form (ngSubmit)="doSubmit()" [formGroup]="userData" novalidate>
      <div formGroupName="businessUnit">
        <div class="form-row">
          <div class="form-group col-md-5">
            <label>CPF / CNPJ</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.document.errors && !f.document.pristine
              }"
              formControlName="document"
              [readonly]="isReadonly"
            />
            <div *ngIf="f.document.errors && !f.document.pristine">
              <span class="error" [hidden]="!f.document.errors.required">
                O nome completo é obrigatório
              </span>
            </div>
          </div>

          <div class="form-group col-md-7">
            <label>Nome/Razão Social</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.name.errors && !f.name.pristine }"
              formControlName="name"
              [readonly]="isReadonly"
            />
            <div *ngIf="f.name.errors && !f.name.pristine">
              <span class="error" [hidden]="!f.name.errors.required">
                O Nome/Razão Social é obrigatório
              </span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Cep</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.zipCode.errors && !f.zipCode.pristine
              }"
              formControlName="zipCode"
              mask="00000-000"
              [readonly]="isReadonly"
            />
            <div *ngIf="f.zipCode.errors && !f.zipCode.pristine">
              <span class="error" [hidden]="!f.zipCode.errors.required">
                O Cep é obrigatório
              </span>
            </div>
          </div>

          <div class="form-group col-md-8">
            <label>Endereço</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.address.errors && !f.address.pristine
              }"
              formControlName="address"
              [readonly]="isReadonly"
            />
            <div *ngIf="f.address.errors && !f.address.pristine">
              <span class="error" [hidden]="!f.address.errors.required">
                O Endereço é obrigatório
              </span>
            </div>
          </div>
        </div>

        <div class="form-row">
          <!-- <div class="form-group col-md-4">
            <label>Bairro</label>
            <input
              type="text"
              class="form-control"
              formControlName="region"
              >
          </div> -->

          <div class="form-group col-md-8">
            <label>Cidade</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.city.errors && !f.city.pristine }"
              formControlName="city"
              [readonly]="isReadonly"
            />
            <div *ngIf="f.city.errors && !f.city.pristine">
              <span class="error" [hidden]="!f.city.errors.required">
                Cidade é obrigatório
              </span>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label>Estado</label>
            <select
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.state.errors && !f.state.pristine }"
              formControlName="state"
            >
              <option *ngFor="let e of stateList" [value]="e.id">
                {{ e.name }}
              </option>
            </select>
            <div *ngIf="f.state.errors && !f.state.pristine">
              <span class="error" [hidden]="!f.state.errors.required">
                O estado é obrigatório
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end" *ngIf="roleDefault">
        <button
          class="btn btn-secondary btn-sm"
          type="submit"
          [disabled]="userData.invalid || isLoading"
        >
          <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i> Salvar
        </button>
      </div>
    </form>
  </div>
</div>
