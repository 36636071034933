<div class="chart">
  <p *ngIf="false">Não há dados para renderizar o gráfico.</p>

  <div class="line">
    <canvas
      baseChart
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      [chartType]="lineChartType"
    >
    </canvas>
  </div>
</div>
