<div class="box mt-3 mb-3">
  <div class="box-header button"><h3>Configurações do usuário</h3></div>

  <div class="box-content">
    <div class="row link">
      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Criar sua assinatura digital"
          (click)="openModal(modalVisibleSignature, 'lg')"
          >Criar sua assinatura</span
        >
      </div>

      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Altera minha senha"
          (click)="openModal(modalChangePassword)"
          >Alterar minha Senha</span
        >
      </div>

      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Cancelar minha conta"
          (click)="openModal(modalCancelAccount)"
          >Cancelar minha conta</span
        >
      </div>
    </div>
  </div>
</div>

<div class="box mt-3 mb-3" *ngIf="roleDefault">
  <div class="box-header button"><h3>Configurações da organização</h3></div>

  <div class="box-content">
    <div class="row link">
      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Convidar usuário para organização"
          routerLink="/manager/users"
          >Usuários da organização</span
        >
      </div>

      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Integração API Assine online"
          (click)="openModal(modalAccessToken, 'lg')"
          >Integração API</span
        >
      </div>

      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title=" Integração DocuWare"
          (click)="openModal(modalIntegrationDocuware, 'lg')"
          >Integração DocuWare</span
        >
      </div>

      <div class="col-md-12 mb-2">
        <span
          class="link__item"
          title="Personalizar assunto do e-mail"
          routerLink="/manager/custom-subject-mail"
          >Personalizar assunto do e-mail</span
        >
      </div>
    </div>
  </div>
</div>
