<div class="document-finish">
  <div class="row">
    <div class="col-md-12">
      <div class="document-hero">
        <h3>Modelo salvo com sucesso!</h3>
        <p *ngIf="callbackUrl()">
          Aguarde enquanto fazemos o seu redirecionamento.
        </p>
        <p *ngIf="!callbackUrl()">Sua janela já pode ser encerrada.</p>
      </div>
    </div>
  </div>
</div>
