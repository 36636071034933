import { Component, Input, OnInit } from '@angular/core';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InTextField } from '@app/core/models';

@Component({
  selector: 'app-workflow-droppable-text-field',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss', '../../droppable-field.component.scss']
})
export class WorkflowDroppableTextFieldComponent {
  @Input() public field: IWorkflowDroppedField;

  public properties: string = 'Texto livre';

  public modalService: NgbModal;

  public metadata: InTextField.TextField;

  constructor(modalService: NgbModal) {
    this.modalService = modalService;
  }

  public open(content: any): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        this.modalService.open(content, {
          ariaLabelledBy: 'modal-title',
          size: 'sm',
          keyboard: false,
          backdrop: 'static'
        });
        resolve();
      });
    });
  }

  public onSubmit(event: InTextField.TextField): boolean {
    this.metadata = event;
    if (!(this.metadata.title === '')) {
      this.properties = this.metadata.title;
      this.field.setPropertiesField(this.metadata);
      this.modalService.dismissAll();
      return false;
    }

    this.properties = 'Texto livre';
    this.modalService.dismissAll();

    return true;
  }

  public onCancel(): void {
    this.modalService.dismissAll();
  }
}
