<form class="form" (ngSubmit)="onSubmit()" [formGroup]="cardForm" novalidate>
  <div>
    <div class="row">
      <div class="col-12">
        <span style="color: #606060">Informacões do pagamento</span>
        <hr style="margin-top: 6px;" />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-lg-6">
        <input
          placeholder="CPF/CNPJ"
          class="form-control"
          formControlName="document"
          [ngClass]="{ 'is-invalid': f.document.errors }"
        />
      </div>

      <div class="form-group col-12 col-lg-6">
        <input
          placeholder="CEP"
          class="form-control"
          formControlName="zipCode"
          [ngClass]="{ 'is-invalid': f.zipCode.errors }"
          mask="00000-000"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6">
        <input
          placeholder="Endereço"
          class="form-control"
          formControlName="address"
          [ngClass]="{ 'is-invalid': f.address.errors }"
        />
      </div>
      <div class="form-group col-6 col-lg-6">
        <input
          placeholder="Cidade"
          class="form-control"
          formControlName="city"
          [ngClass]="{ 'is-invalid': f.city.errors }"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-lg-6">
        <select
          class="form-control"
          formControlName="state"
          [ngClass]="{ 'is-invalid': f.state.errors }"
        >
          <option *ngFor="let e of stateList" [value]="e.id">
            {{ e.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-12 col-lg-6">
        <select
          class="form-control"
          formControlName="country"
          [ngClass]="{ 'is-invalid': f.country.errors }"
        >
          <option *ngFor="let e of countryList" [value]="e.id">
            {{ e.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="flip-container">
        <div class="flipper" [ngClass]="cardSide ? '' : 'flip-action'">
          <div class="front">
            <div class="row">
              <div class="card-flag">
                <img
                  src="../../../../assets/icons/mastercard.svg"
                  *ngIf="isMastercard"
                />
                <img src="../../../../assets/icons/visa.svg" *ngIf="isVisa" />
                <img
                  src="../../../../assets/icons/cardbrand.svg"
                  *ngIf="!isMastercard && !isVisa"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card-number">{{ cardinfo.number }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="card-label">Nome impresso</div>
                <div class="card-name">{{ cardinfo.name }}</div>
              </div>
              <div class="col-4">
                <div class="card-label" style="margin-left: -10px;">
                  Válido até
                </div>
                <div class="card-name" style="margin-left: -10px;">
                  {{ cardinfo.month }}/{{ cardinfo.year }}
                </div>
              </div>
            </div>
          </div>
          <div class="back">
            <div class="white-stripe"></div>
            <div class="row">
              <div class="col-8"></div>
              <div class="col-4">
                <div class="card-label" style="margin-left: -10px;">CVV</div>
                <div class="card-name" style="margin-left: -10px;">
                  {{ cardinfo.cvv }}
                </div>
              </div>
              <div class="col-12">
                <div class="card-flag-back">
                  <img
                    src="../../../../assets/icons/mastercard.svg"
                    *ngIf="isMastercard"
                  />
                  <img src="../../../../assets/icons/visa.svg" *ngIf="isVisa" />
                  <img
                    src="../../../../assets/icons/cardbrand.svg"
                    *ngIf="!isMastercard && !isVisa"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" style="padding-top: 15px;">
      <div class="row">
        <div class="col-12">
          <input
            inputmode="numeric"
            pattern="[0-9]*"
            digitOnly
            name="number"
            placeholder="Número do cartão"
            class="form-control"
            formControlName="number"
            [ngClass]="{ 'is-invalid': f.number.errors }"
            required
            ccNumber
            [(ngModel)]="cardinfo.number"
            (ngModelChange)="ValidateCreditCardNumber()"
          />
        </div>

        <div class="col-12 col-md-4" style="margin-top: 15px;">
          <input
            inputmode="numeric"
            pattern="[0-9]*"
            digitOnly
            name="expMonth"
            placeholder="Mês"
            class="form-control card-month"
            formControlName="expMonth"
            [ngClass]="{ 'is-invalid': f.expMonth.errors }"
            minlength="1"
            maxlength="2"
            required
            [(ngModel)]="cardinfo.month"
          />
        </div>

        <div class="col-12 col-md-4" style="margin-top: 15px;">
          <input
            inputmode="numeric"
            pattern="[0-9]*"
            digitOnly
            name="expYear"
            placeholder="Ano"
            class="form-control card-year"
            formControlName="expYear"
            [ngClass]="{ 'is-invalid': f.expYear.errors }"
            minlength="4"
            maxlength="4"
            required
            [(ngModel)]="cardinfo.year"
          />
        </div>

        <div class="col-12 col-md-4" style="margin-top: 15px;">
          <input
            inputmode="numeric"
            pattern="[0-9]*"
            digitOnly
            name="cvv"
            placeholder="CVV"
            class="form-control card-cvv"
            formControlName="cvv"
            [ngClass]="{ 'is-invalid': f.cvv.errors }"
            (focus)="cardSide = !cardSide"
            (blur)="cardSide = !cardSide"
            minlength="3"
            maxlength="3"
            required
            ccCVC
            [(ngModel)]="cardinfo.cvv"
          />
        </div>
        <div class="col-12" style="margin-top: 15px;">
          <input
            name="holderName"
            placeholder="Nome no cartão"
            class="form-control"
            formControlName="holderName"
            [ngClass]="{ 'is-invalid': f.holderName.errors }"
            required
            [(ngModel)]="cardinfo.name"
            (ngModelChange)="cardHolderNameToUppercase()"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 d-flex justify-content-end">
      <div class="captcha">
        <re-captcha
          (resolved)="resolved($event)"
          formControlName="recaptcha"
        ></re-captcha>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12" style="text-align: end;margin-top: 15px;">
      <button
        class="btn btn-secondary btn-sm"
        type="submit"
        [disabled]="cardForm.invalid"
      >
        <span translate>Pagar</span>
      </button>
    </div>
  </div>
</form>
