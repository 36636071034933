import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var NameField = /** @class */ (function (_super) {
    tslib_1.__extends(NameField, _super);
    function NameField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_NAME_TEXT;
        _this.minHeight = _this.height = 25;
        _this.minWidth = _this.width = 100;
        return _this;
    }
    return NameField;
}(WorkflowDroppedField));
export { NameField };
