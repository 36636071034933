import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SIGNATURE_TYPE } from '@app/core/services';
@Component({
  selector: 'app-choose-subscription',
  templateUrl: './choose-subscription.component.html',
  styleUrls: ['./choose-subscription.component.scss']
})
export class ChooseSubscriptionComponent implements OnInit {
  /**
   * Data of Form
   */
  listFonts: Array<string>;

  /**
   * Receive full name signature
   */
  @Input() fullName: string;

  chosenFont: string;

  /**
   * Signature type object
   */
  @Output() dataSignChooseEvent = new EventEmitter();

  public dataSignChoose: Object = {
    type: SIGNATURE_TYPE.GENERATING,
    sign: ''
  };

  constructor() {
    this.listFonts = ['Arial.ttf', 'caveat-regular.ttf', 'Satisfy-Regular.ttf'];
  }

  ngOnInit() {}

  /**
   * Choose font
   *
   * @param (string) font name
   */
  doChooseFont(font: string) {
    this.chosenFont = font;
    this.dataSignChoose['sign'] = font;
    this.dataSignChooseEvent.emit({ dataSignature: this.dataSignChoose });
  }
}
