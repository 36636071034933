<div class="text-custom">
  <p>
    Deseja replicar o carimbo em todas as páginas do documento? Essa ação não
    poderá ser desfeita.
  </p>
</div>

<div class="text-right">
  <button class="btn btn-default btn-sm" (click)="modal.close('cancel')">
    Não
  </button>
  <button class="btn btn btn-secondary btn-sm" (click)="doSubmit()">Sim</button>
</div>
