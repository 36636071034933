import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

/**
 * Provides a base for choose plan workflow.
 */
@Injectable()
export class TeamService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/mock/teams.json';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }
}
