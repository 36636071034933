import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Event } from '@app/core/models';
import {
  WORKFLOW_STATUS,
  WORKFLOW_STEP_STATUS,
  AuthorizationService
} from '@app/core/services';
import * as moment from 'moment';

@Component({
  selector: 'app-list-events',
  templateUrl: './list-events.component.html',
  styleUrls: ['./list-events.component.scss']
})
export class ListEventsComponent implements OnInit {
  /**
   * Provides data that will render the chart
   */
  @Input() events: Array<Event.EventData> = [];

  /**
   * Determinate the visibility of initial div
   */
  @Input() showInitials?: boolean = true;

  /**
   * Determinate which layout apply in the component
   */
  @Input() layout: any;

  /**
   * Callback on click button
   */
  @Output() action? = new EventEmitter();

  /**
   * Data User logged
   */
  userLogged: any;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.authorizationService.user.subscribe((user: any) => {
      this.userLogged = user;
    });
  }

  compileEvents(events: any[]): Event.CompiledEvent[] {
    return events.map(event => {
      const workflowStatus: number = event.workflow.status.id;
      const stepStatus: number = event.step.status.id;

      const compiledEvent: Event.CompiledEvent = {
        id: event.workflow.id,
        userName: event.workflow.user.name,
        eventDateStatus: event.workflow.date.dateStatus._formated,
        message: event.workflow.message,
        title: '',
        titleColorClass: '',
        icon: '',
        link: '',
        actionTitle: ''
      };

      compiledEvent.link = `/manager/manage/workflow-step/detail/${
        event.step.id
      }`;
      compiledEvent.actionTitle = 'Ver Documento';

      if (workflowStatus !== WORKFLOW_STATUS.CIRCULATING) {
        compiledEvent.title = event.workflow.status.text.text;

        if (workflowStatus === WORKFLOW_STATUS.EXPIRED) {
          const dueDate = moment(event.workflow.date.dueDate.date);
          compiledEvent.title += ' em ' + dueDate.format('DD/MM/YYYY');
        }

        Object.assign(
          compiledEvent,
          this._compileEventWorkflow(workflowStatus)
        );
      } else {
        compiledEvent.title = event.step.status.text.text;

        if (stepStatus === WORKFLOW_STEP_STATUS.WAITING) {
          compiledEvent.link = `/subscribe/${this.goTo(event.step.id)}`;
          compiledEvent.actionTitle = 'Assinar';
        }

        Object.assign(compiledEvent, this._compileEventStep(stepStatus));
      }

      return compiledEvent;
    });
  }

  /**
   * provides a action in click button
   *
   * @param (string) action type
   * @return void
   */
  goTo(id: string) {
    let username = '';
    if (this.userLogged && this.userLogged.username) {
      username = this.userLogged.username;
    }

    return encodeURIComponent(
      btoa(
        JSON.stringify({
          u: username,
          id: id
        })
      )
    );
  }

  private _compileEventWorkflow(
    workflowStatus: number
  ): { titleColorClass: string; icon: string } {
    const data: { titleColorClass: string; icon: string } = {
      titleColorClass: '',
      icon: ''
    };

    switch (workflowStatus) {
      case WORKFLOW_STATUS.COMPLETED:
        data.titleColorClass = 'data-document-status--done';
        data.icon = 'fa fa-check';
        break;

      case WORKFLOW_STATUS.CANCELLED:
      case WORKFLOW_STATUS.EXPIRED:
      case WORKFLOW_STATUS.REJECTED:
        data.titleColorClass = 'data-document-status--cancelled';
        data.icon = 'fa fa-ban';
        break;

      default:
        data.titleColorClass = 'data-document-status--waiting';
        data.icon = 'fa fa-clock';
        break;
    }

    return data;
  }

  private _compileEventStep(
    stepStatus: number
  ): { titleColorClass: string; icon: string } {
    const data: { titleColorClass: string; icon: string } = {
      titleColorClass: '',
      icon: ''
    };

    switch (stepStatus) {
      case WORKFLOW_STEP_STATUS.APPROVED:
        data.titleColorClass = 'data-document-status--done';
        data.icon = 'fa fa-check';
        break;

      case WORKFLOW_STEP_STATUS.REJECTED:
        data.titleColorClass = 'data-document-status--cancelled';
        data.icon = 'fa fa-ban';
        break;

      default:
        data.titleColorClass = 'data-document-status--waiting';
        data.icon = 'fa fa-clock';
        break;
    }

    return data;
  }
}
