import { User } from '@app/core/models';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AuthorizationService } from './authorization.service';

@Injectable()
export class LocaldataService extends BaseAPI {
  private _localStorageKey: string = 'localData';
  private _metaDataKey: string = 'localData';

  constructor(
    protected httpClient: HttpClient,
    protected authorizationService: AuthorizationService
  ) {
    super(httpClient);
  }

  private setInLocalStorage(data: object) {
    if (!data) {
      return false;
    }
    return localStorage.setItem(this._localStorageKey, JSON.stringify(data));
  }

  private async getFromLocalStorage() {
    let localData = localStorage.getItem(this._localStorageKey);
    if (!localData) {
      localData = await this.getFromApi();
      if (!localData) {
        return {};
      }
      this.setInLocalStorage(JSON.parse(localData));
    }
    return JSON.parse(localData);
  }

  private removeFromLocalStorage() {
    return localStorage.removeItem(this._localStorageKey);
  }

  clearLocal() {
    return this.removeFromLocalStorage();
  }

  private getFromApi() {
    return this.httpClient
      .get('/user-metadata')
      .pipe(
        map((body: any) => {
          const localData = {
            id: '',
            data: {}
          };
          body._embedded.user_metadata.forEach((item: any) => {
            if (item.dataKey === this._metaDataKey) {
              localData.data = JSON.parse(item.dataValue);
              localData.id = item.id;
            }
          });
          return JSON.stringify(localData);
        })
      )
      .toPromise();
  }

  private async commitInApi(localData: any) {
    if (localData.id) {
      return await this.updateMetadata(localData.id, {
        dataKey: this._metaDataKey,
        dataValue: JSON.stringify(localData.data)
      });
    }
    return await this.createMetadata({
      dataKey: this._metaDataKey,
      dataValue: JSON.stringify(localData.data)
    });
  }

  private updateMetadata(id: number, payload: any) {
    return this.httpClient
      .patch(`/user-metadata/${id}`, payload)
      .pipe(
        map((body: any) => {
          return body.id;
        })
      )
      .toPromise();
  }

  private async createMetadata(payload: User.UserMetadata) {
    return this.httpClient
      .post('/user-metadata', payload)
      .pipe(
        map((body: any) => {
          return body.id;
        })
      )
      .toPromise();
  }

  async getItem(item: string) {
    const localData = await this.getFromLocalStorage();
    return localData.data[item] || {};
  }

  async setItem(item: string, value: any) {
    const localData = await this.getFromLocalStorage();
    if (!localData) {
      return '';
    }
    try {
      localData.data[item] = value;
      localData.id = await this.commitInApi(localData);
      this.setInLocalStorage(localData);
    } catch (error) {
      this.removeFromLocalStorage();
    }
  }
}
