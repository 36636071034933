/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "../../../core/services/authentication.service";
import * as i5 from "../../../core/services/authorization.service";
import * as i6 from "../../../core/services/business-unit-metadata.service";
import * as i7 from "../../../core/services/user.service";
import * as i8 from "../../../core/subscriptions/users";
import * as i9 from "../../../core/subscriptions/photo";
import * as i10 from "../../../core/services/workflow-build.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../core/services/remaining-user-balance.service";
import * as i13 from "./footer/footer.component.ngfactory";
import * as i14 from "./footer/footer.component";
import * as i15 from "./manager.component";
var styles_LayoutManagerComponent = [i0.styles];
var RenderType_LayoutManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutManagerComponent, data: {} });
export { RenderType_LayoutManagerComponent as RenderType_LayoutManagerComponent };
export function View_LayoutManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-manager-header", [], null, null, null, i2.View_LayoutManagerHeaderComponent_0, i2.RenderType_LayoutManagerHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutManagerHeaderComponent, [i4.AuthenticationService, i5.AuthorizationService, i6.BusinessMetadataService, i7.UserService, i8.UserSubscription, i9.PhotoSubscription, i10.WorkflowBuildService, i11.Router, i12.RemainingUserBalanceService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-layout-manager-footer", [], null, null, null, i13.View_LayoutManagerFooterComponent_0, i13.RenderType_LayoutManagerFooterComponent)), i1.ɵdid(7, 114688, null, 0, i14.LayoutManagerFooterComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
export function View_LayoutManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-manager", [], null, null, null, View_LayoutManagerComponent_0, RenderType_LayoutManagerComponent)), i1.ɵdid(1, 114688, null, 0, i15.LayoutManagerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutManagerComponentNgFactory = i1.ɵccf("app-layout-manager", i15.LayoutManagerComponent, View_LayoutManagerComponent_Host_0, {}, {}, []);
export { LayoutManagerComponentNgFactory as LayoutManagerComponentNgFactory };
