import { ErrorHandler } from '@angular/core';
import { Logger } from '../class/logger.class';

const log = new Logger('ErrorLogHandler');

/**
 * Class for an internal error detail
 */
export class ErrorLogHandler implements ErrorHandler {
  handleError(error: any) {
    log.debug(error);
  }
}
