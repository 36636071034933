import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DepartmentService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/mock/departments.json';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }
}
