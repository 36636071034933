import { IWorkflowContact } from './workflow-contact.interface';
import { Contact } from '../models/contact.model';
import { WORKFLOW_CONTACT_ACTION } from '../services/workflow.enum';

export class WorkflowContact implements IWorkflowContact {
  public id: number;
  public name: string = '';
  public email: string = '';
  public whatsappNumber: string = '';
  public notifyWhatsapp: boolean = false;
  public hasPhotoSign: boolean;

  private action: number;
  private orderIndex: number = 0;

  public extraMetadata: any;

  constructor(
    contact: Contact.Contact,
    action: number = WORKFLOW_CONTACT_ACTION.SIGN,
    hasPhotoSign = false
  ) {
    this.id = contact.id;
    this.name = contact.name;
    this.email = contact.email;
    this.whatsappNumber = contact.whatsappNumber;
    this.notifyWhatsapp = contact.notifyWhatsapp;
    this.action = action;
    this.hasPhotoSign = hasPhotoSign;

    if (contact.extraMetadata) {
      this.extraMetadata = contact.extraMetadata;
    }
  }

  public static MakeWorkflowContact(
    contact: Contact.Contact,
    action?: number,
    hasPhotoSign?: boolean
  ): WorkflowContact {
    return new WorkflowContact(contact, action, hasPhotoSign);
  }

  public setExtraMetadata<T>(extraMetadata: T): void {
    this.extraMetadata = extraMetadata;
  }

  public getExtraMetadata<T>(): T {
    return this.extraMetadata as T;
  }

  public setOrderIndex(orderIndex: number): void {
    this.orderIndex = orderIndex;
  }

  public getOrderIndex(): number {
    return this.orderIndex;
  }

  public getAction(): number {
    return this.action;
  }

  public getHasPhotoSign(): boolean {
    return this.hasPhotoSign;
  }

  public isSigner(): boolean {
    return this.action === WORKFLOW_CONTACT_ACTION.SIGN;
  }

  public isViewer(): boolean {
    return this.action === WORKFLOW_CONTACT_ACTION.VIEW;
  }

  public isApprover(): boolean {
    return this.action === WORKFLOW_CONTACT_ACTION.APPROVE;
  }
}
