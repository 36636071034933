import * as tslib_1 from "tslib";
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableNameFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableNameFieldComponent, _super);
    function WorkflowDroppableNameFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowDroppableNameFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableNameFieldComponent };
