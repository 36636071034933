import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Folder } from '@app/core/models';

@Component({
  selector: 'app-change-integration-folder',
  templateUrl: './change-integration-folder.component.html',
  styleUrls: ['./change-integration-folder.component.scss']
})
export class ChangeIntegrationFolderComponent implements OnInit {
  @Input() modal: any;
  @Input() selected: Folder.FolderIntegrationPayloadModal;

  public folderForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
    this.setFolderIntegration();
  }

  public shouldModalClose(): void {
    this.modal.close('cancel');
  }

  public doSubmit(): void {
    this.modal.close({
      action: 'save',
      form: this.actionType(),
      formData: this.prepareToSend()
    });
  }

  private actionType(): string {
    if (this.selected.action === 'change') {
      return 'changeIntegrationWithFolder';
    }
    return 'createIntegrationWithFolder';
  }

  private prepareToSend(): Folder.FolderIntegrationPayload {
    const payload: Folder.FolderIntegrationPayload = {
      idFolder: this.folderForm.value.idFolder,
      idFolderIntegration: this.folderForm.value.idIntegrationFolder,
      metadata: {
        file_cabinet_id: this.folderForm.value.metadata
      }
    };
    return payload;
  }

  private setFolderIntegration(): void {
    if (this.selected.action === 'create') {
      this.folderForm.patchValue({
        idFolder: this.selected.id,
        idIntegrationFolder: 0,
        name: this.selected.name
      });
      return;
    }

    this.folderForm.setValue({
      idFolder: this.selected.id,
      idIntegrationFolder: this.selected.folderIntegration.id,
      name: this.selected.name,
      metadata: this.selected.folderIntegration.metadata.file_cabinet_id
    });
  }

  public infoText(): string {
    return 'Deve preencher com Id File Cabinet, ele e usado para criar a integração com a pasta docuWare';
  }

  get getFolderForm() {
    return this.folderForm.controls;
  }

  private createForm(): void {
    this.folderForm = this.formBuilder.group({
      idFolder: ['', Validators.required],
      idIntegrationFolder: ['', Validators.required],
      name: [{ value: '', disabled: true }, Validators.required],
      metadata: ['', Validators.required]
    });
  }
}
