import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  /**
   * Provides data that will render the chart
   */
  @Input() data: any;

  public lineChartData: Array<any> = [
    { data: [15, 20, 25, 30, 20, 25, 30, 15, 20, 25, 30, 20] }
  ];
  public lineChartLabels: Array<any> = [
    'Jan',
    'Feb',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ];
  public lineChartOptions: any = { responsive: true };
  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(70,130,180,0.4)',
      borderColor: '#0addac',
      tension: 0,
      borderWidth: 4,
      pointRadius: 0
    }
  ];
  public lineChartLegend: boolean = false;
  public lineChartType: string = 'line';

  constructor() {}

  ngOnInit() {}
}
