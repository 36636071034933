import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
import { IntegrationDocuwareService } from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var log = new Logger('Integration');
var IntegrationDocuwareFormComponent = /** @class */ (function () {
    function IntegrationDocuwareFormComponent(formBuilder, integrationDocuwareService, modalService) {
        this.formBuilder = formBuilder;
        this.integrationDocuwareService = integrationDocuwareService;
        this.modalService = modalService;
        /**
         * event emitter to update Feecback Component
         */
        this.setFeedbackMessage = new EventEmitter();
        this.spinnerWait = false;
        this.showButton = false;
        this.modalRef = { ref: null, data: { text: '' } };
        this.createForm();
    }
    IntegrationDocuwareFormComponent.prototype.ngOnInit = function () {
        this.getIntegrationDocuare();
    };
    IntegrationDocuwareFormComponent.prototype.doSubmit = function () {
        this.spinnerWait = true;
        if (this.showButton) {
            this.updateIntegrationDocuware(this.payload());
            return;
        }
        this.createIntegrationDocuware(this.payload());
    };
    IntegrationDocuwareFormComponent.prototype.onDelete = function () {
        this.open(this.modalDelete, 'md');
    };
    IntegrationDocuwareFormComponent.prototype.toDeleteIntegration = function () {
        this.deleteIntegration(this.integrationData.id);
    };
    IntegrationDocuwareFormComponent.prototype.open = function (content, size) {
        this.modalRef.ref = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return this.modalRef;
    };
    IntegrationDocuwareFormComponent.prototype.modalClose = function () {
        if (this.modalRef.ref) {
            this.modalRef.ref.close();
        }
    };
    IntegrationDocuwareFormComponent.prototype.getIntegrationDocuare = function () {
        var _this = this;
        this.integrationDocuwareService.getAll().subscribe(function (data) {
            if (data) {
                _this.showButton = true;
                _this.loadIntegrationData(data);
            }
            _this.integrationData = data;
            log.debug("Success [getIntegration]: " + data);
        }, function (error) {
            log.debug("Error [getIntegration]: " + error);
        });
    };
    IntegrationDocuwareFormComponent.prototype.deleteIntegration = function (id) {
        var _this = this;
        this.integrationDocuwareService.deleteIntegration(id).subscribe(function (data) {
            _this.modalClose();
            _this.toResetForm();
            _this.integrationData = null;
            _this.setFeedbackMessageEvent('success', 'Integração excluída com sucesso');
            log.debug("Success [deleteIntegration]: " + data);
        }, function (error) {
            _this.modalClose();
            log.debug("Error [deleteIntegration]: " + error);
        });
    };
    IntegrationDocuwareFormComponent.prototype.updateIntegrationDocuware = function (payload) {
        var _this = this;
        this.integrationDocuwareService
            .updateIntegration(this.integrationData.id, payload)
            .subscribe(function (data) {
            if (data) {
                _this.showButton = true;
                _this.spinnerWait = false;
                _this.loadIntegrationData(data);
            }
            _this.integrationData = data;
            _this.setFeedbackMessageEvent('success', 'Atualização realizada com sucesso ');
            log.debug("Success [updateIntegration]: " + data);
        }, function (error) {
            log.debug("Error [updateIntegration]: " + error);
        });
    };
    IntegrationDocuwareFormComponent.prototype.createIntegrationDocuware = function (payload) {
        var _this = this;
        this.integrationDocuwareService.create(payload).subscribe(function (reponse) {
            _this.spinnerWait = false;
            _this.setFeedbackMessageEvent('success', 'Integração realizada com sucesso ');
            log.debug("Success [createIntegration]: " + reponse);
        }, function (error) {
            _this.spinnerWait = false;
            _this.feedbackTheme = 'error';
            _this.feedbackMessage =
                'Não foi possível realizar a integração, Tente novamente';
            if (error.error.status === 400) {
                _this.spinnerWait = false;
            }
            else {
                _this.spinnerWait = false;
            }
            log.debug("Error [createIntegration]: " + error);
        });
    };
    IntegrationDocuwareFormComponent.prototype.setFeedbackMessageEvent = function (theme, message) {
        this.setFeedbackMessage.emit({
            theme: theme,
            message: message
        });
        this.modal.close({
            action: 'cancel',
            form: 'integration-docuware',
            formData: ''
        });
    };
    IntegrationDocuwareFormComponent.prototype.payload = function () {
        var payloadData = {
            fileCabinetId: this.dataForm.controls.fileCabinetId.value,
            serverUrl: this.dataForm.controls.serverUrl.value,
            username: this.dataForm.controls.username.value,
            password: this.dataForm.controls.password.value
        };
        return payloadData;
    };
    IntegrationDocuwareFormComponent.prototype.loadIntegrationData = function (data) {
        this.dataForm.setValue({
            fileCabinetId: data.fileCabinetId,
            serverUrl: data.serverUrl,
            username: data.username,
            password: null
        });
    };
    /**
     * Reset form
     */
    IntegrationDocuwareFormComponent.prototype.toResetForm = function () {
        this.dataForm.reset();
    };
    Object.defineProperty(IntegrationDocuwareFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.dataForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    IntegrationDocuwareFormComponent.prototype.createForm = function () {
        this.dataForm = this.formBuilder.group({
            fileCabinetId: ['', Validators.required],
            serverUrl: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    };
    return IntegrationDocuwareFormComponent;
}());
export { IntegrationDocuwareFormComponent };
