<div class="page">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="panel">
          <div class="text main line">Verificador de documentos</div>
          <div class="subtext">
            Veja a integridade das assinaturas realizadas em seus documentos.
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!verifyResponse">
      <div class="col-12">
        <div class="box">
          <div class="box-content">
            <div class="text main line">Envio de documentos</div>
            <div class="subtext">
              Selecione o arquivo em seu computador ou arraste-o para o campo
              abaixo. Se desejar ter o relatório completo da validação, insira
              também a identificação da assinatura. Caso não queira enviar o
              documento, insira
              <span class="text highlight">apenas o código</span> da
              identificação no tópico abaixo.
            </div>
            <div class="tip">
              Dica: o documento assinado tem a palavra "signed" no final de seu
              nome.
            </div>
            <div class="tip bold">Ex: documento_signed.pdf</div>
            <!-- UPLOAD BOX -->
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="upload">
                  <label for="file">
                    <input
                      type="file"
                      id="file"
                      (change)="generateFileHash($event)"
                      (click)="newFile()"
                    />
                    <i
                      class="fas fa-file-upload"
                      [ngStyle]="{ color: filename ? '#3aa67f' : '#BEBEBE' }"
                    ></i>
                  </label>
                  <span class="filename"> {{ filename }} </span>
                </div>
              </div>
            </div>
            <!-- END UPLOAD BOX -->
            <div class="text main line">Identificação da assinatura</div>
            <div class="subtext">
              Para verificar a integridade da assinatura, abra o seu arquivo e
              insira nos campos abaixo a identificação da assinatura desejada.
            </div>
            <div class="tip">
              Dica: A identificação se encontra logo abaixo de sua rubrica e é
              composta por letras e números divididos em três campos.
            </div>
            <div class="tip bold">Ex: 5ABCG8-94ILDK52-5BER56</div>
            <!-- SIGNATURE CODE FIELDS -->
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <form
                  class="mt-3"
                  (ngSubmit)="doSubmit()"
                  [formGroup]="checkerForm"
                  novalidate
                >
                  <div class="form-group">
                    <label class="d-block">
                      <input
                        type="text"
                        formControlName="codeField"
                        class="form-control codeField"
                        placeholder="Ex: 5ABCG8-94ILDK52-5BER56"
                      />
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <!-- END SIGNATURE CODE FIELDS -->
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="errorBar" *ngIf="isError">
                  {{ errorMsg | translate }}
                </div>
              </div>
              <div class="col-12 col-md-6" style="text-align: right;">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="sendVerification()"
                  [disabled]="checkerForm.invalid"
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SUCCESFULL VERIFICATION  -->
    <div class="row" *ngIf="verifyResponse">
      <div class="col-12">
        <div class="box">
          <div class="box-content">
            <div class="text main line">Resultado da validação</div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="subtext">Nome do documento</div>
                <div class="text verify">{{ verifyResponse.filename }}</div>
                <div class="subtext">Identificação do documento</div>
                <div class="text verify">{{ verifyResponse.docId }}</div>
                <div class="subtext">Data de envio</div>
                <div class="text verify">{{ verifyResponse.date }}</div>
                <div *ngIf="verifyResponse.integrity === 'Confirmada'">
                  <div class="subtext">Integridade do documento</div>
                  <div class="text verify" style="color: #3AA67F;">
                    {{ verifyResponse.integrity }}
                  </div>
                </div>

                <div *ngIf="verifyResponse.original">
                  <div class="subtext">Download dos arquivos</div>
                  <div class="mt-1" style="display: flex;">
                    <button
                      class="btn btn-outline-success"
                      style="margin-right: 5px;"
                      (click)="downloadFile(verifyResponse.original)"
                    >
                      Original
                    </button>
                    <button
                      class="btn btn-outline-success"
                      (click)="downloadFile(verifyResponse.signed)"
                    >
                      Assinado
                    </button>
                  </div>
                </div>

                <div
                  class="alert alert-warning mt-5"
                  *ngIf="verifyResponse.integrity !== 'Confirmada'"
                >
                  <div>
                    Selecione o documento digital para concluir a validação
                  </div>
                  <div>
                    <label for="select-file">
                      <input
                        type="file"
                        id="select-file"
                        (change)="finishValidation($event)"
                        (click)="newFile()"
                      />
                      <span class="btn btn-secondary">
                        Selecionar documento
                      </span>
                    </label>
                  </div>
                  <hr *ngIf="errorFinishingValidation" />
                  <p class="text-danger mb-0">
                    {{ errorFinishingValidation | translate }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="subtext">Assinantes</div>
                <div class="scrollable">
                  <div
                    class="signers"
                    *ngFor="let signer of verifyResponse.signers"
                  >
                    <div class="text main">{{ signer.name }}</div>
                    <div class="subtext">{{ signer.email }}</div>
                    <div class="status">{{ signer.status }}</div>
                    <div class="typesign">{{ signer.type }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12" style="text-align: left;">
                <button
                  type="button"
                  class="btn btn-light"
                  (click)="verifyResponse = ''"
                >
                  Nova verificação
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
