import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { LocaldataService } from './localdata.service';
import { HttpClient } from '@angular/common/http';
import { SIGNATORY_CATEGORIES, DOCUMENTS_CATEGORIES } from '@app/core/enums/SignatoryCategories';
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService(httpClient, authorizationService, localdataService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authorizationService = authorizationService;
        _this.localdataService = localdataService;
        _this.userPreferences = null;
        _this.endpoint = '/user';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = ['username', 'email', 'password'];
        return _this;
    }
    UserService.prototype.setTempNewUserData = function (data) {
        this.tempNewUserData = data;
    };
    UserService.prototype.getTempNewUserData = function () {
        return this.tempNewUserData;
    };
    UserService.prototype.me = function () {
        var _this = this;
        var url = this.endpoint + "?filter[0][field]=id&filter[0][type]=eq&filter[0][value]=$USER_ID";
        return this.httpClient.get(url).pipe(map(function (body) {
            var logged = body._embedded.user[0];
            var data = {
                id: logged.id,
                name: logged.name,
                initial: _this._getInitials(logged.name),
                username: logged.email,
                email: logged.email,
                cellphone: logged.cellphone,
                whatsappNumber: logged.whatsappNumber,
                notifyWhatsapp: logged.notifyWhatsapp,
                twilioBuEnabled: logged.twilioBuEnabled,
                document: logged.document,
                roleDefault: logged.roleDefault
            };
            if (logged._embedded !== undefined) {
                data.businessUnit = {
                    id: logged._embedded.businessUnit.id,
                    name: logged._embedded.businessUnit.name,
                    status: logged._embedded.businessUnit.status,
                    document: logged._embedded.businessUnit.document,
                    country: logged._embedded.businessUnit.country,
                    state: logged._embedded.businessUnit.state,
                    city: logged._embedded.businessUnit.city,
                    address: logged._embedded.businessUnit.address,
                    zipCode: logged._embedded.businessUnit.zipCode
                };
            }
            data['signatoryCategory'] = 1;
            if (_this.authorizationService.isGuest()) {
                _this.authorizationService.setTempGuestUser(data);
            }
            else {
                _this.setUser(data);
            }
            return data;
        }));
    };
    UserService.prototype.updateUser = function (id, payload) {
        var _this = this;
        return this.httpClient.patch(this.endpoint + ("/" + id), payload).pipe(map(function (body) {
            var dataUser = {
                id: body.id,
                name: body.name,
                initial: _this._getInitials(body.name),
                username: body.email,
                email: body.email,
                cellphone: body.cellphone,
                document: body.document,
                roleDefault: body.roleDefault,
                businessUnit: {
                    id: body._embedded.businessUnit.id,
                    name: body._embedded.businessUnit.name,
                    status: body._embedded.businessUnit.status,
                    document: body._embedded.businessUnit.document,
                    country: body._embedded.businessUnit.country,
                    state: body._embedded.businessUnit.state,
                    city: body._embedded.businessUnit.city,
                    address: body._embedded.businessUnit.address,
                    zipCode: body._embedded.businessUnit.zipCode
                }
            };
            return dataUser;
        }));
    };
    UserService.prototype.getUserPreferences = function () {
        var _this = this;
        if (this.userPreferences) {
            return of(this.userPreferences);
        }
        return this.getAllMetadata().pipe(map(function (body) {
            var metadata = body.find(function (e) { return e.dataKey === 'userPreferences'; });
            if (metadata) {
                _this.userPreferences = Object.assign({
                    metadataId: metadata.id
                }, JSON.parse(metadata.dataValue));
                return _this.userPreferences;
            }
        }));
    };
    UserService.prototype.saveUserPreferences = function (payload) {
        var _this = this;
        var dataKey = 'userPreferences';
        var observer;
        if (this.userPreferences) {
            var userPreferences = Object.create(this.userPreferences);
            Object.assign(userPreferences, payload);
            var metadataId = userPreferences.metadataId;
            delete userPreferences.metadataId;
            observer = this.updateMetadata(metadataId, {
                dataValue: JSON.stringify(userPreferences),
                dataKey: dataKey
            });
        }
        else {
            observer = this.createMetadata({
                dataKey: dataKey,
                dataValue: JSON.stringify(payload)
            });
        }
        return observer.pipe(map(function (body) {
            _this.userPreferences = JSON.parse(body.dataValue);
            _this.userPreferences.metadataId = body.id;
            return _this.userPreferences;
        }));
    };
    UserService.prototype.getAllMetadata = function () {
        return this.httpClient.get('/user-metadata').pipe(map(function (body) {
            var collection = [];
            body._embedded.user_metadata.forEach(function (e) {
                collection.push({
                    id: e.id,
                    dataKey: e.dataKey,
                    dataValue: e.dataValue
                });
            });
            return collection;
        }));
    };
    UserService.prototype.removeBusinessUnitFromuser = function (userId) {
        return this.httpClient
            .patch(this.endpoint + "/" + userId + "?getOutBusinessUnit=1", {})
            .pipe(map(function (body) {
            return body;
        }));
    };
    UserService.prototype.confirmRegister = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=confirm-register', payload);
    };
    UserService.prototype.createMetadata = function (payload) {
        return this.httpClient.post('/user-metadata', payload).pipe(map(function (body) {
            var data = {
                id: body.id,
                dataKey: body.dataKey,
                dataValue: body.dataValue
            };
            return data;
        }));
    };
    UserService.prototype.updateMetadata = function (id, payload) {
        return this.httpClient.patch("/user-metadata/" + id, payload).pipe(map(function (body) {
            var data = {
                id: body.id,
                dataKey: body.dataKey,
                dataValue: body.dataValue
            };
            return data;
        }));
    };
    UserService.prototype.getPlanInfo = function () {
        var _this = this;
        return this.httpClient.get('/subscription').pipe(map(function (body) {
            if (!body._embedded.subscription[0]) {
                return false;
            }
            var data = {
                id: body._embedded.subscription[0]._embedded.planSubscription.id,
                showName: body._embedded.subscription[0]._embedded.user.name,
                planType: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.name,
                signaturesRemainments: body._embedded.subscription[0].usageRemainments,
                signaturesPerUser: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.usagePerUser,
                planDescription: _this.formatText(body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.description),
                idPlan: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.id,
                planShortDescription: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.shortDescription,
                price: _this.parsePrice(body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.price),
                cents: _this.parseCents(body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.price),
                isDefault: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.isDefault,
                usage: _this.usagePercentage(body._embedded.subscription[0]._embedded.planSubscription
                    .usageRemainments, body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.usagePerUser),
                globalSignaturesRemainments: body._embedded.subscription[0]._embedded.planSubscription
                    .usageRemainments,
                globalSignaturesUsage: body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.usageGlobal,
                globalUsage: _this.usagePercentage(body._embedded.subscription[0]._embedded.planSubscription
                    .usageRemainments, body._embedded.subscription[0]._embedded.planSubscription._embedded
                    .plan.usageGlobal)
            };
            return data;
        }));
    };
    UserService.prototype.getPaymentInfo = function () {
        var _this = this;
        return this.httpClient.get('/plan-subscription').pipe(map(function (body) {
            if (body.total_items > 0) {
                if (body._embedded.plan_subscription[0].metadata) {
                    var metadata = JSON.parse(body._embedded.plan_subscription[0].metadata);
                    var data = {
                        id: body._embedded.plan_subscription[0].id,
                        address: metadata.card.billing_address.line_1,
                        zip_code: metadata.card.billing_address.zip_code,
                        city: metadata.card.billing_address.city,
                        state: metadata.card.billing_address.state,
                        country: metadata.card.billing_address.country,
                        cardNumber: metadata.card.last_four_digits,
                        holder: metadata.card.holder_name,
                        brand: metadata.card.brand,
                        expMonth: metadata.card.exp_month,
                        expYear: metadata.card.exp_year,
                        renewDate: _this.parseRenewDate(metadata.current_cycle.end_at),
                        price: _this.parsePrice(metadata.minimum_price.toString()),
                        cents: _this.parseCents(metadata.minimum_price.toString())
                    };
                    return data;
                }
            }
        }));
    };
    UserService.prototype.getUserPlanInfor = function () {
        return this.httpClient.get('/plan-subscription').pipe(map(function (body) {
            if (body.total_items > 0) {
                if (body._embedded.plan_subscription[0]._embedded) {
                    var data = {
                        id: body._embedded.plan_subscription[0]._embedded.plan.id,
                        name: body._embedded.plan_subscription[0]._embedded.plan.name,
                        maxUsers: body._embedded.plan_subscription[0]._embedded.plan.maxUsers,
                        paymentMethod: body._embedded.plan_subscription[0]._embedded.plan
                            .paymentMethod,
                        type: body._embedded.plan_subscription[0]._embedded.plan.type,
                        usageGlobal: body._embedded.plan_subscription[0]._embedded.plan.usageGlobal
                    };
                    return data;
                }
            }
        }));
    };
    UserService.prototype.setLinkedUser = function (payload) {
        return this.httpClient.post('/user', payload);
    };
    UserService.prototype.disableUser = function (id) {
        return this.httpClient.patch("/user/" + id, {
            status: 0
        });
    };
    UserService.prototype.getLinkedUsers = function (queryString) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this.parseBusinessUnitUser(response);
        }));
    };
    UserService.prototype.parseBusinessUnitUser = function (data) {
        var users = [];
        if (data.total_items > 0) {
            users = data._embedded.user.map(function (user) {
                return {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    roleDefault: user.roleDefault
                };
            });
        }
        return {
            users: users,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items
        };
    };
    UserService.prototype.parseRenewDate = function (renewDate) {
        var data = new Date(renewDate);
        return data.toLocaleDateString();
    };
    UserService.prototype.usagePercentage = function (remain, total) {
        var result = ((total - remain) / total) * 100;
        return parseFloat(result.toFixed(2));
    };
    UserService.prototype.formatText = function (text) {
        var re = /\s*;\s*/;
        return text.split(re);
    };
    UserService.prototype.parsePrice = function (price) {
        return price.slice(0, -2);
    };
    UserService.prototype.parseCents = function (price) {
        return price.slice(-2);
    };
    /**
     * Create new row in database
     * @param payload with data object
     */
    UserService.prototype.create = function (payload, options) {
        var _this = this;
        if (!this._checkHasField(payload)) {
            throw new Error("Field is missing. Check all fields in your request.");
        }
        return this.httpClient
            .disableAuthorization()
            .post(this.endpoint, this.mapFields(payload), options)
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Sets the user data.
     * The user data is persisted after the access token is generated.
     * @param data The user datas.
     */
    UserService.prototype.setUser = function (data) {
        this.authorizationService.setUser(data);
    };
    UserService.prototype._getInitials = function (name) {
        var slices = name.split(' ');
        var size = slices.length;
        var initials = slices[0].charAt(0);
        if (size > 1) {
            initials += slices[size - 1].charAt(0);
        }
        return initials;
    };
    UserService.prototype.getUser = function () {
        return this.authorizationService.getUser();
    };
    UserService.prototype.getUserLocalData = function (key) {
        return this.localdataService.getItem(key);
    };
    UserService.prototype.setUserLocalData = function (key, data) {
        return this.localdataService.setItem(key, data);
    };
    UserService.prototype.removeUserLocalData = function () {
        return this.localdataService.clearLocal();
    };
    UserService.prototype.setUserSignProfileId = function (profileId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userSignProfile;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLocalData('userSignProfile')];
                    case 1:
                        userSignProfile = _a.sent();
                        userSignProfile.signatoryCategory = {
                            id: profileId
                        };
                        return [4 /*yield*/, this.setUserLocalData('userSignProfile', userSignProfile)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.getUserSignProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userSignProfile, userCategory;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLocalData('userSignProfile')];
                    case 1:
                        userSignProfile = _a.sent();
                        if (userSignProfile.signatoryCategory) {
                            userCategory = SIGNATORY_CATEGORIES[userSignProfile.signatoryCategory.id];
                            if (userCategory) {
                                userSignProfile.signatoryCategory = {
                                    id: userSignProfile.signatoryCategory.id,
                                    category: userCategory,
                                    documentsCategory: DOCUMENTS_CATEGORIES[userCategory.documentsCategory]
                                };
                            }
                        }
                        return [2 /*return*/, userSignProfile];
                }
            });
        });
    };
    UserService.prototype.setUserSignProfileFieldsData = function (profileId, fieldsData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userSignProfile;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLocalData('userSignProfile')];
                    case 1:
                        userSignProfile = _a.sent();
                        if (!userSignProfile.fields) {
                            userSignProfile.fields = {};
                        }
                        userSignProfile.fields[profileId] = Object.assign({}, fieldsData);
                        return [4 /*yield*/, this.setUserLocalData('userSignProfile', userSignProfile)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.getUserSignProfileFieldsData = function (profileId) {
        if (profileId === void 0) { profileId = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userSignProfile;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserLocalData('userSignProfile')];
                    case 1:
                        userSignProfile = _a.sent();
                        if (!userSignProfile) {
                            return [2 /*return*/, userSignProfile];
                        }
                        if (!userSignProfile.fields) {
                            return [2 /*return*/, {}];
                        }
                        if (profileId) {
                            return [2 /*return*/, userSignProfile.fields[profileId] || {}];
                        }
                        return [2 /*return*/, userSignProfile.fields];
                }
            });
        });
    };
    return UserService;
}(BaseAPI));
export { UserService };
