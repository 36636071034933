<div class="document-error">
  <div class="row">
    <div class="col-md-12">
      <div class="message">
        <h2>{{ error }}</h2>

        <p class="icon"><i class="icon-document-error"></i></p>
      </div>
    </div>
  </div>
</div>
