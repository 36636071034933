<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div class="container">
  <div class="row mt-2">
    <div class="col-12"><h4>Seu token de API pessoal</h4></div>
  </div>

  <div class="input-group mt-3">
    <div class="input-group-prepend">
      <div class="input-group-text"><i class="fas fa-key"></i></div>
    </div>

    <input
      type="text"
      #copyToken
      class="form-control"
      placeholder="Seu token será gerado aqui"
      [formControl]="session"
      readonly
    />
    <button
      class="btn btn-sm btn-secondary ml-2"
      title="Copiar token de acesso"
      (click)="copyTokenToClipboard(copyToken)"
    >
      Copiar
    </button>
  </div>

  <form (ngSubmit)="doSubmit()" [formGroup]="data" novalidate>
    <div class="row mt-3">
      <div class="col-12 col-md-5 ">
        <input
          type="password"
          class="form-control"
          placeholder="Senha"
          formControlName="password"
        />
      </div>
      <div class="col-12 col-md-5 feed" *ngIf="responseDate">
        <p>Seu Token expira em: {{ dueDate }}</p>
      </div>
    </div>

    <div class="mt-4">
      <button
        class="btn btn-sm btn-secondary"
        type="submit"
        [disabled]="data.invalid"
      >
        <i class="fas fa-circle-notch fa-spin" *ngIf="spinnerWait"></i> Gerar
        novo token
      </button>
    </div>
  </form>

  <div class="info">
    <p>
      A documentação da API pode ser encotrada em
      <a href="https://docs.assine.online/" target="_blank"
        ><strong>docs.assine.online</strong></a
      >
    </p>
    <p>
      <i class="fa fa-info-circle"></i> Mantenha seu token de API seguro, já que
      ele pode ser usado para acessar seus dados no Assine Online.
    </p>
  </div>

  <div class="d-flex justify-content-center mt-5">
    <button
      class="btn btm-sm btn-outline-success"
      (click)="modal.close('cancel')"
    >
      Fechar
    </button>
  </div>
</div>
