import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Logger } from '@app/core/class';
import { BusinessUnitMetadata } from '@app/core/models/business-unit-metadata.model';
import { BusinessMetadataService } from '@app/core/services/business-unit-metadata.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

const log = new Logger('LogoComponent');

@Component({
  selector: 'app-organization-logo',
  templateUrl: './organization-logo.component.html',
  styleUrls: ['./organization-logo.component.scss']
})
export class OrganizationLogoComponent implements OnInit {
  @ViewChild('modalError') modalError: ElementRef;

  /**
   * Logo information
   */
  logoInfo: BusinessUnitMetadata.LogoCollection;

  /**
   * event emitter to update Feecback Component
   */
  @Output() setFeedbackMessage = new EventEmitter();

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  /**
   * logo organization
   */
  logo: string;

  /**
   *check create or update, false create logo
   */
  check: boolean = false;

  /**
   * Spinner Button
   */
  spinnerButton: boolean = false;

  /**
   * Spinner Logo
   */
  spinnerLogo: boolean = true;

  /**
   * modal to load the cropper component
   */
  modal: NgbModalRef;

  /**
   * holds the file to be cropped
   */
  imageChangedEvent: any = '';

  /**
   * cropped image
   */
  croppedImage = '';

  containWithinAspectRatio = false;

  constructor(
    private businessMetadataService: BusinessMetadataService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.businessMetadataService
      .getMetadata()
      .subscribe((response: BusinessUnitMetadata.LogoCollection) => {
        if (response) {
          this.logoInfo = response;
          this.logo = response.dataValue;
          this.checkCreateOrUpdate(true);
        }
        this.spinnerLogo = false;
      });
  }

  private checkCreateOrUpdate(check?: boolean): boolean {
    if (check) {
      this.check = check;
    }
    return this.check;
  }

  private createBusinessUnitMetadata(): void {
    const payload: BusinessUnitMetadata.LogoPayload = {
      dataKey: 'logo',
      dataValue: this.croppedImage
    };

    this.businessMetadataService.createMetadata(payload).subscribe(
      (response: BusinessUnitMetadata.LogoCollection) => {
        this.checkCreateOrUpdate(true);
        this.logo = response.dataValue;
        this.spinnerButton = false;
        this.setFeedbackMessage.emit({
          theme: 'success',
          message: 'Sua marca foi salva com sucesso'
        });
        this.businessMetadataService.inputBrand({
          dataKey: 'logo',
          dataValue: this.logo
        });
        this.modal.close();
      },
      (error: any) => {
        this.spinnerButton = false;
        this.setFeedbackMessage.emit({
          theme: 'error',
          message: 'Não foi possível salvar sua marca, Tente novamente'
        });
        this.modal.close();
      }
    );
  }

  private updateBusinessUnitMetadata(): void {
    const payload: BusinessUnitMetadata.LogoPayload = {
      dataKey: 'logo',
      dataValue: this.croppedImage
    };

    this.businessMetadataService
      .updateMetadata(this.logoInfo.id, payload)
      .subscribe(
        (response: BusinessUnitMetadata.LogoCollection) => {
          this.checkCreateOrUpdate(true);
          this.logo = response.dataValue;
          this.spinnerButton = false;
          this.setFeedbackMessage.emit({
            theme: 'success',
            message: 'Sua marca foi atualizada com sucesso'
          });
          this.businessMetadataService.inputBrand({
            dataKey: 'logo',
            dataValue: this.logo
          });
          this.modal.close();
        },
        (error: any) => {
          this.spinnerButton = false;
          this.setFeedbackMessage.emit({
            theme: 'error',
            message: 'Não foi possível atualizar sua marca, Tente novamente'
          });
          this.modal.close();
        }
      );
  }

  public uploadFile(): void {
    this.spinnerButton = true;
    if (!this.checkCreateOrUpdate()) {
      this.createBusinessUnitMetadata();
    } else {
      this.updateBusinessUnitMetadata();
    }
  }

  public closeModal(): void {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.modal.close();
  }

  public loadImageFailed(): void {
    this.modal.close();
    this.open(this.modalError);
  }

  public toggleContainWithinAspectRatio(): void {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  public imageFileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public openFileSelector(content: any): void {
    this.open(content);
  }

  private open(content: any): void {
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
  }
}
