import { Routes, Route } from '@angular/router';
import { AuthenticationGuard } from '@app/core/guards';

import { Layout } from '@app/core/models';
import { LayoutSimpleComponent } from './simple.component';

/**
 * Provides helper methods to create routes.
 */
export class LayoutSimple {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(
    routes: Routes,
    attributes?: Layout.SimpleConfigLayout
  ): Route {
    return {
      path: '',
      component: LayoutSimpleComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
      // Reuse LayoutSimpleComponent instance when navigating between child views
      data: { reuse: true, attr: attributes }
    };
  }
}
