import { IWorkflowDroppedField } from './workflow-dropped-field.interface';
import { WorkflowContact } from '@app/core/class/workflow-contact.class';
import { InTextField } from '@app/core/models';

export class WorkflowDroppedField implements IWorkflowDroppedField {
  protected contact: WorkflowContact;
  protected type: number;
  protected signatureType: number;
  protected properties: InTextField.TextField;
  protected width: number = 0;
  protected minWidth: number = 0;
  protected height: number = 0;
  protected minHeight: number = 0;
  protected x: number = 0;
  protected y: number = 0;
  protected viewportProportion: number = 1;
  protected pageNumber: number = 1;
  protected spreadOnPages: boolean = false;

  constructor(contact: WorkflowContact) {
    this.contact = contact;
    this.signatureType = null;
    this.properties = null;
  }

  public setPropertiesField<T>(propertiesField: T): void {
    this.properties = propertiesField;
  }

  public getPropertiesField<T>(): T {
    return this.properties as T;
  }

  public isSpecialField(): boolean {
    return false;
  }

  public isSignature(): boolean {
    return false;
  }

  public getType(): number {
    return this.type;
  }

  public setViewportProportion(proportion: number): void {
    this.viewportProportion = proportion;
  }

  public getViewportProportion(): number {
    return this.viewportProportion;
  }

  public canResize(): boolean {
    return true;
  }

  public shouldKeepAspectRatio(): boolean {
    return false;
  }

  public setPageNumber(pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  public getPageNumber(): number {
    return this.pageNumber;
  }

  public setSize(width: number, height: number, proportion?: number) {
    if (typeof proportion === 'number') {
      this.viewportProportion = proportion;
    }
    this.width = width / this.viewportProportion;
    this.height = height / this.viewportProportion;
  }

  public setCoordinates(x: number, y: number, proportion?: number) {
    if (typeof proportion === 'number') {
      this.viewportProportion = proportion;
    }

    if (x < 0) {
      x = 0;
    }

    if (y < 0) {
      y = 0;
    }

    this.x = x / this.viewportProportion;
    this.y = y / this.viewportProportion;
  }

  public getSignatureType(): number {
    return this.signatureType;
  }

  public getContact(): WorkflowContact {
    return this.contact;
  }

  public getWidth(): number {
    return Math.round(this.width * this.viewportProportion);
  }

  public getMinWidth(): number {
    return Math.round(this.minWidth * this.viewportProportion);
  }

  public getRawWidth(): number {
    return Math.round(this.width);
  }

  public getHeight(): number {
    return Math.round(this.height * this.viewportProportion);
  }

  public getMinHeight(): number {
    return Math.round(this.minHeight * this.viewportProportion);
  }

  public getRawHeight(): number {
    return Math.round(this.height);
  }

  public getX(): number {
    return Math.round(this.x * this.viewportProportion);
  }

  public getRawX(): number {
    return Math.round(this.x);
  }

  public getY(): number {
    return Math.round(this.y * this.viewportProportion);
  }

  public getRawY(): number {
    return Math.round(this.y);
  }

  public setSpreadOnPages(value: boolean): void {
    this.spreadOnPages = value;
  }

  public getSpreadOnPages(): boolean {
    return this.spreadOnPages;
  }
}
