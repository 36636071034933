import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var PersonalFormComponent = /** @class */ (function () {
    function PersonalFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        /**
         * Callback with data of submit
         */
        this.doActionSubmit = new EventEmitter();
        /**
         * Show button Organization
         */
        this.buttonOrganization = false;
        this.createForm();
    }
    PersonalFormComponent.prototype.ngAfterViewInit = function () {
        if (this.cellphone) {
            this.cellphone.nativeElement.parentElement.style = 'display: block;';
        }
    };
    PersonalFormComponent.prototype.ngOnInit = function () { };
    /**
     * Data selection action by clicking the form submit button
     */
    PersonalFormComponent.prototype.doSubmit = function () {
        this.doActionSubmit.emit({ values: this.userForm.value });
    };
    PersonalFormComponent.prototype.getNumber = function (number) {
        this.userForm.value.cellphone = number;
    };
    Object.defineProperty(PersonalFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.userForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    PersonalFormComponent.prototype.createForm = function () {
        this.userForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            cellphone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(11),
                    Validators.maxLength(11)
                ])
            ],
            document: [''],
            roleDefault: ['']
        });
    };
    /**
     * Fill the form.
     * Need to remove de + from the
     *  to not conflict with ngmask and reactive form validation.
     */
    PersonalFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes['selected']) {
            if (this.selected.cellphone !== null &&
                this.selected.cellphone !== undefined &&
                this.selected.cellphone.length > 11) {
                this.selected.cellphone = this.selected.cellphone.substr(3);
            }
            this.userForm.patchValue(this.selected);
        }
    };
    return PersonalFormComponent;
}());
export { PersonalFormComponent };
