import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var ChangeIntegrationFolderComponent = /** @class */ (function () {
    function ChangeIntegrationFolderComponent(formBuilder) {
        this.formBuilder = formBuilder;
    }
    ChangeIntegrationFolderComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.setFolderIntegration();
    };
    ChangeIntegrationFolderComponent.prototype.shouldModalClose = function () {
        this.modal.close('cancel');
    };
    ChangeIntegrationFolderComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: this.actionType(),
            formData: this.prepareToSend()
        });
    };
    ChangeIntegrationFolderComponent.prototype.actionType = function () {
        if (this.selected.action === 'change') {
            return 'changeIntegrationWithFolder';
        }
        return 'createIntegrationWithFolder';
    };
    ChangeIntegrationFolderComponent.prototype.prepareToSend = function () {
        var payload = {
            idFolder: this.folderForm.value.idFolder,
            idFolderIntegration: this.folderForm.value.idIntegrationFolder,
            metadata: {
                file_cabinet_id: this.folderForm.value.metadata
            }
        };
        return payload;
    };
    ChangeIntegrationFolderComponent.prototype.setFolderIntegration = function () {
        if (this.selected.action === 'create') {
            this.folderForm.patchValue({
                idFolder: this.selected.id,
                idIntegrationFolder: 0,
                name: this.selected.name
            });
            return;
        }
        this.folderForm.setValue({
            idFolder: this.selected.id,
            idIntegrationFolder: this.selected.folderIntegration.id,
            name: this.selected.name,
            metadata: this.selected.folderIntegration.metadata.file_cabinet_id
        });
    };
    ChangeIntegrationFolderComponent.prototype.infoText = function () {
        return 'Deve preencher com Id File Cabinet, ele e usado para criar a integração com a pasta docuWare';
    };
    Object.defineProperty(ChangeIntegrationFolderComponent.prototype, "getFolderForm", {
        get: function () {
            return this.folderForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ChangeIntegrationFolderComponent.prototype.createForm = function () {
        this.folderForm = this.formBuilder.group({
            idFolder: ['', Validators.required],
            idIntegrationFolder: ['', Validators.required],
            name: [{ value: '', disabled: true }, Validators.required],
            metadata: ['', Validators.required]
        });
    };
    return ChangeIntegrationFolderComponent;
}());
export { ChangeIntegrationFolderComponent };
