import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Logger } from '@app/core/class';
import { CountryService, PlansService, StateService, AuthorizationService, UserService, RemainingUserBalanceService, TagManageService } from '@app/core/services';
import { finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardComponent } from '@app/landing/components';
var log = new Logger('PlansPage');
var PlansPageComponent = /** @class */ (function () {
    function PlansPageComponent(planService, userService, countryService, stateService, modalService, authorizationService, remainingUserBalance, tagManageService) {
        this.planService = planService;
        this.userService = userService;
        this.countryService = countryService;
        this.stateService = stateService;
        this.modalService = modalService;
        this.authorizationService = authorizationService;
        this.remainingUserBalance = remainingUserBalance;
        this.tagManageService = tagManageService;
        this.isLoading = false;
        this.planChoosed = false;
        this.isOnFeedback = false;
        this.modalRef = { ref: null, data: { text: '' } };
    }
    PlansPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando planos...';
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
        this.getPlans();
        this.getRegisterData();
        this.logged = this.authorizationService.getUser();
        this.userService.getPlanInfo().subscribe(function (body) { return (_this.planInfo = body); });
        this.userService
            .getAllMetadata()
            .pipe(map(function (body) {
            return body.filter(function (e) { return e.dataKey === 'picture'; });
        }))
            .subscribe(function (response) {
            _this.photo = response.length ? response[0] : null;
        });
    };
    PlansPageComponent.prototype.getPlans = function () {
        var _this = this;
        this.planService
            .getPlans()
            .pipe(map(function (body) {
            return body.plan.filter(function (plan) {
                return plan.status === 1 &&
                    plan.paymentMethod != null &&
                    !plan.paymentMethod.includes('free');
            });
        }))
            .subscribe(function (response) {
            log.debug("Success [getAllPlans]: " + response);
            _this.data = response;
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
        }, function (error) {
            log.debug("Error [getAllPlans]: " + error);
            _this.data = [];
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
        });
    };
    PlansPageComponent.prototype.open = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    PlansPageComponent.prototype.modalClose = function () {
        if (this.modalRef.ref) {
            this.modalRef.ref.close();
        }
    };
    PlansPageComponent.prototype.getRegisterData = function () {
        var states = this.stateService.list();
        this.stateList = [{ id: '', name: 'Estado' }].concat(states);
        var countries = this.countryService.list();
        this.countryList = [{ id: '', name: 'País' }].concat(countries);
    };
    PlansPageComponent.prototype.choosePlan = function () {
        this.planChoosed = false;
        this.error = null;
        this.selectedId = null;
    };
    PlansPageComponent.prototype.changePlan = function (plan) {
        this.choosedPlan = plan;
        this.selectedId = plan.id;
        this.planChoosed = true;
    };
    PlansPageComponent.prototype.shouldResetRecaptchaCredCard = function () {
        this.creditCardComponent.shouldResetRecaptchaAndForm();
    };
    PlansPageComponent.prototype.pay = function (creditCard) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload, plan, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.modalRef.data.text = 'Processando pedido...';
                        this.modalRef.ref = this.open(this.loading);
                        payload = {
                            paymentMethod: 'credit_card',
                            idPlan: this.selectedId,
                            customer: {
                                document: creditCard.document,
                                address: creditCard.billingAddress
                            },
                            card: creditCard,
                            recaptcha: creditCard.recaptcha
                        };
                        this.isLoading = true;
                        return [4 /*yield*/, this.userService.getPlanInfo().toPromise()];
                    case 1:
                        plan = _a.sent();
                        this.modalRef.data.text = 'Cancelando o plano atual...';
                        if (!plan) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.planService.cancelPlan(plan.id).toPromise()];
                    case 2:
                        _a.sent();
                        this.addPlan(payload);
                        return [3 /*break*/, 4];
                    case 3:
                        this.addPlan(payload);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        this.modalClose();
                        this.isLoading = false;
                        this.shouldResetRecaptchaCredCard();
                        this.tagManageService.sendEvent({
                            event: 'event',
                            value: this.choosedPlan.name + " error"
                        });
                        this.feedBack =
                            'Não foi possível cancelar seu plano atual, Tente novamente ou entrar em contato conosco pelo chat disponível no canto inferior direito desta página.';
                        this.open(this.cardFeedBack);
                        log.debug("Error [createPlan]: " + e_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PlansPageComponent.prototype.addPlan = function (payload) {
        var _this = this;
        this.modalRef.data.text = 'Processando pagamento...';
        this.planService
            .subscription(payload)
            .pipe(finalize(function () {
            _this.modalClose();
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            log.debug("Success [createPlan]: " + response);
            _this.modalClose();
            _this.isOnFeedback = true;
            _this.open(_this.feedbackPayment);
            /**
             * User remaining balance
             */
            _this.remainingUserBalance.getPlanUser();
        }, function (error) {
            _this.modalClose();
            _this.shouldResetRecaptchaCredCard();
            _this.tagManageService.sendEvent({
                event: 'event',
                value: _this.choosedPlan.name + " error"
            });
            _this.feedBack = error.error.detail;
            _this.open(_this.cardFeedBack, 'md');
            log.debug("Error [createPlan]: " + error);
        });
    };
    return PlansPageComponent;
}());
export { PlansPageComponent };
