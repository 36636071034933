import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
var BaseAPI = /** @class */ (function () {
    function BaseAPI(httpClient) {
        this.httpClient = httpClient;
        if (this.apiFieldsMap && 0 === Object.keys(this.apiFieldsMap).length) {
            throw new Error("A mapping configuration between api and application is needed. Set a value to the 'apiFieldsMap' variable.");
        }
    }
    /**
     * Gets all data of a service
     * @param filterObject (optional) arguments for filter data.
     */
    BaseAPI.prototype.getAll = function (filterObject, options) {
        var _this = this;
        var queryString = '';
        if (filterObject) {
            queryString = this._parseFilter(this.mapFields(filterObject));
        }
        return this.httpClient.get("" + this.endpoint + queryString, options).pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Get data by unique identifier
     * @param id identifier of row
     * @param filterObject (optional) arguments for filter data.
     */
    BaseAPI.prototype.getById = function (id, filterObject, options) {
        var _this = this;
        if (!id || 0 === id.length) {
            throw new Error("Field 'id' is required.");
        }
        var queryString = '';
        if (filterObject) {
            queryString = this._parseFilter(this.mapFields(filterObject));
        }
        return this.httpClient
            .get(this.endpoint + "/" + id + queryString, options)
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Create new row in database
     * @param payload with data object
     */
    BaseAPI.prototype.create = function (payload, options) {
        var _this = this;
        if (!this._checkHasField(payload)) {
            throw new Error("Field is missing. Check all fields in your request.");
        }
        return this.httpClient
            .post(this.endpoint, this.mapFields(payload), options)
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Update a row in database
     * @param id identifier of row
     * @param payload with data object
     */
    BaseAPI.prototype.update = function (id, payload, options) {
        var _this = this;
        if (!id || 0 === id.length) {
            throw new Error("Field 'id' is required.");
        }
        return this.httpClient
            .patch(this.endpoint + "/" + id, this.mapFields(payload), options)
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Delete a row in database
     * @param id identifier of row
     */
    BaseAPI.prototype.delete = function (id, options) {
        if (!id || 0 === id.length) {
            throw new Error("Field 'id' is required.");
        }
        return this.httpClient.delete(this.endpoint + "/" + id, options);
    };
    /**
     * Field mapping and key exchange to maintain
     * integrity between application and api
     * @param body object/array with data fields
     * @param swap (optional) key / value
     */
    BaseAPI.prototype.mapFields = function (body, swap) {
        var _this = this;
        var _apiFields = this.apiFieldsMap;
        if (swap) {
            _apiFields = this._swapFields(_apiFields);
        }
        if (Array.isArray(body)) {
            var parseArray = body.map(function (object) {
                var getOnlyObject = Object.keys(object).map(function (key) {
                    var _a, _b;
                    if (Array.isArray(object[key])) {
                        var hasObject = object[key].filter(function (item) {
                            return typeof item === 'object';
                        });
                        if (hasObject.length > 0) {
                            return _a = {}, _a[key] = _this.mapFields(object[key], swap), _a;
                        }
                    }
                    return _b = {}, _b[key] = object[key], _b;
                });
                // @ts-ignore
                var objectParsed = Object.assign.apply(Object, [{}].concat(getOnlyObject));
                return _this._parseFields(objectParsed, _apiFields);
            });
            return parseArray;
        }
        return this._parseFields(body, _apiFields);
    };
    /**
     * Change fields of the API for the app
     * @param body object with data fields
     * @param fields object with data fields of app
     */
    BaseAPI.prototype._parseFields = function (body, fields) {
        var output = Object.keys(body).map(function (key) {
            var _a;
            var newKey = fields[key] || key;
            return _a = {}, _a[newKey] = body[key], _a;
        });
        return Object.assign.apply(Object, [{}].concat(output));
    };
    /**
     * Check is request contain all required fields
     * @param request with data
     */
    BaseAPI.prototype._checkHasField = function (request) {
        var length = this.fieldsRequired.length;
        for (var i = 0; i < length; i++) {
            var field = this.fieldsRequired[i];
            if (!request.hasOwnProperty(field)) {
                return false;
            }
        }
        return true;
    };
    /**
     * Swap keys / values
     * @param fields with data
     */
    BaseAPI.prototype._swapFields = function (fields) {
        var output = Object.keys(fields).map(function (key) {
            var _a;
            return _a = {}, _a[fields[key]] = key, _a;
        });
        return Object.assign.apply(Object, [{}].concat(output));
    };
    /**
     * Parser the object with options and create a querystring
     * @param filterObject with data
     */
    BaseAPI.prototype._parseFilter = function (filterObject) {
        var queryString = '';
        if (filterObject) {
            var fitlerKeys = Object.keys(filterObject);
            if (fitlerKeys.length > 0) {
                queryString = '?';
            }
            queryString += this._serialize(filterObject);
            if (fitlerKeys.length > 0 &&
                queryString[queryString.length - 1] === '&') {
                queryString = queryString.slice(0, -1);
            }
        }
        return queryString;
    };
    /**
     * Serialize object to a querystring
     * @param obj with data
     * @param prefix of key
     */
    BaseAPI.prototype._serialize = function (obj, prefix) {
        if (prefix === void 0) { prefix = ''; }
        var str = [];
        var p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
                str.push(v !== null && typeof v === 'object'
                    ? this._serialize(v, k)
                    : k + "=" + v);
            }
        }
        return str.join('&');
    };
    return BaseAPI;
}());
export { BaseAPI };
