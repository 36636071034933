import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var TextField = /** @class */ (function (_super) {
    tslib_1.__extends(TextField, _super);
    function TextField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT;
        _this.minHeight = 10;
        _this.height = 15;
        _this.minWidth = 50;
        _this.width = 80;
        return _this;
    }
    return TextField;
}(WorkflowDroppedField));
export { TextField };
