import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientOptions } from './HttpClientOptions.interface';
import { HttpClient } from '@angular/common/http';

@Injectable()
export abstract class BaseAPI {
  /**
   * Endpoint of service
   */
  endpoint: string;

  /**
   * Field mapping between the
   * Application and the API
   */
  apiFieldsMap: object;

  /**
   * Fields required of API
   */
  fieldsRequired: any[];

  constructor(protected httpClient: HttpClient) {
    if (this.apiFieldsMap && 0 === Object.keys(this.apiFieldsMap).length) {
      throw new Error(
        `A mapping configuration between api and application is needed. Set a value to the 'apiFieldsMap' variable.`
      );
    }
  }

  /**
   * Gets all data of a service
   * @param filterObject (optional) arguments for filter data.
   */
  getAll(filterObject?: object, options?: HttpClientOptions): Observable<any> {
    let queryString = '';
    if (filterObject) {
      queryString = this._parseFilter(this.mapFields(filterObject));
    }
    return this.httpClient.get(`${this.endpoint}${queryString}`, options).pipe(
      map((body: any) => {
        return this.mapFields(body, true);
      })
    );
  }

  /**
   * Get data by unique identifier
   * @param id identifier of row
   * @param filterObject (optional) arguments for filter data.
   */
  getById(
    id: string,
    filterObject?: object,
    options?: HttpClientOptions
  ): Observable<any> {
    if (!id || 0 === id.length) {
      throw new Error(`Field 'id' is required.`);
    }
    let queryString = '';
    if (filterObject) {
      queryString = this._parseFilter(this.mapFields(filterObject));
    }
    return this.httpClient
      .get(`${this.endpoint}/${id}${queryString}`, options)
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  /**
   * Create new row in database
   * @param payload with data object
   */
  create(payload: object, options?: HttpClientOptions): Observable<any> {
    if (!this._checkHasField(payload)) {
      throw new Error(`Field is missing. Check all fields in your request.`);
    }

    return this.httpClient
      .post(this.endpoint, this.mapFields(payload), options)
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  /**
   * Update a row in database
   * @param id identifier of row
   * @param payload with data object
   */
  update(
    id: string,
    payload: object,
    options?: HttpClientOptions
  ): Observable<any> {
    if (!id || 0 === id.length) {
      throw new Error(`Field 'id' is required.`);
    }

    return this.httpClient
      .patch(`${this.endpoint}/${id}`, this.mapFields(payload), options)
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  /**
   * Delete a row in database
   * @param id identifier of row
   */
  delete(id: string, options?: HttpClientOptions): Observable<any> {
    if (!id || 0 === id.length) {
      throw new Error(`Field 'id' is required.`);
    }
    return this.httpClient.delete(`${this.endpoint}/${id}`, options);
  }

  /**
   * Field mapping and key exchange to maintain
   * integrity between application and api
   * @param body object/array with data fields
   * @param swap (optional) key / value
   */
  mapFields(body: any, swap?: boolean): any {
    let _apiFields = this.apiFieldsMap;

    if (swap) {
      _apiFields = this._swapFields(_apiFields);
    }

    if (Array.isArray(body)) {
      const parseArray = body.map(object => {
        const getOnlyObject = Object.keys(object).map(key => {
          if (Array.isArray(object[key])) {
            const hasObject = object[key].filter(function(item: any): any {
              return typeof item === 'object';
            });
            if (hasObject.length > 0) {
              return { [key]: this.mapFields(object[key], swap) };
            }
          }
          return { [key]: object[key] };
        });

        // @ts-ignore
        const objectParsed = Object.assign({}, ...getOnlyObject);
        return this._parseFields(objectParsed, _apiFields);
      });

      return parseArray;
    }

    return this._parseFields(body, _apiFields);
  }

  /**
   * Change fields of the API for the app
   * @param body object with data fields
   * @param fields object with data fields of app
   */
  _parseFields(body: object, fields: object): object {
    const output = Object.keys(body).map(key => {
      const newKey = fields[key] || key;
      return { [newKey]: body[key] };
    });

    return Object.assign({}, ...output);
  }

  /**
   * Check is request contain all required fields
   * @param request with data
   */
  _checkHasField(request: object): boolean {
    const length = this.fieldsRequired.length;
    for (let i = 0; i < length; i++) {
      const field = this.fieldsRequired[i];
      if (!request.hasOwnProperty(field)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Swap keys / values
   * @param fields with data
   */
  _swapFields(fields: object): object {
    const output = Object.keys(fields).map(key => {
      return { [fields[key]]: key };
    });

    return Object.assign({}, ...output);
  }

  /**
   * Parser the object with options and create a querystring
   * @param filterObject with data
   */
  _parseFilter(filterObject: object): string {
    let queryString = '';

    if (filterObject) {
      const fitlerKeys: any[] = Object.keys(filterObject);
      if (fitlerKeys.length > 0) {
        queryString = '?';
      }
      queryString += this._serialize(filterObject);
      if (
        fitlerKeys.length > 0 &&
        queryString[queryString.length - 1] === '&'
      ) {
        queryString = queryString.slice(0, -1);
      }
    }

    return queryString;
  }

  /**
   * Serialize object to a querystring
   * @param obj with data
   * @param prefix of key
   */
  _serialize(obj: any, prefix: any = ''): string {
    const str = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? this._serialize(v, k)
            : `${k}=${v}`
        );
      }
    }
    return str.join('&');
  }
}
