import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { AuthorizationService, WorkflowTemplateService, WorkflowBuildService, COUNTRY_CODES } from '@app/core/services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var WorkflowBuildContactsComponent = /** @class */ (function () {
    function WorkflowBuildContactsComponent(authorizationService, workflowTemplateService, workflowBuildService, modalService, formBuilder) {
        this.authorizationService = authorizationService;
        this.workflowTemplateService = workflowTemplateService;
        this.workflowBuildService = workflowBuildService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        /**
         * Feedback action
         */
        this.doFeedback = new EventEmitter();
        this.queryField = new FormControl();
        this.authorizationService = authorizationService;
        this.workflowTemplateService = workflowTemplateService;
        this.workflowBuildService = workflowBuildService;
        this.showContactForm = false;
        this.contacts = [];
        this.createEditContactForm();
    }
    WorkflowBuildContactsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contacts = this.workflowBuildService.getContacts();
        this.workflowBuildService.observeContacts().subscribe(function (contacts) {
            _this.contacts = contacts;
            _this.setLastContactAsSelected();
        });
    };
    WorkflowBuildContactsComponent.prototype.isEnabledWhatsapp = function () {
        if (this.authorizationService.getUser()) {
            return this.authorizationService.getUser().twilioBuEnabled;
        }
        return false;
    };
    WorkflowBuildContactsComponent.prototype.isEnabledWhatsappIntegration = function () {
        return this.workflowBuildService.getWhatsApp();
    };
    WorkflowBuildContactsComponent.prototype.replaceDDI = function (phone) {
        if (!phone) {
            return null;
        }
        var ddi = phone.replace('+', '').replace('55', '');
        return ddi;
    };
    WorkflowBuildContactsComponent.prototype.toggleContactForm = function () {
        this.showContactForm = !this.showContactForm;
    };
    WorkflowBuildContactsComponent.prototype.hasContacts = function () {
        return this.contacts.length > 0;
    };
    WorkflowBuildContactsComponent.prototype.totalContacts = function () {
        return this.contacts.length;
    };
    WorkflowBuildContactsComponent.prototype.onContactAdded = function () {
        this.toggleContactForm();
    };
    WorkflowBuildContactsComponent.prototype.isContactSelected = function (contact) {
        return this.selectedContact === contact;
    };
    WorkflowBuildContactsComponent.prototype.toggleSelectedContact = function (contact) {
        if (!contact.isSigner()) {
            return;
        }
        if (this.selectedContact === contact) {
            this.selectedContact = null;
            return;
        }
        this.selectedContact = contact;
    };
    WorkflowBuildContactsComponent.prototype.setLastContactAsSelected = function () {
        if (this.hasContacts()) {
            this.selectedContact = this.contacts[this.contacts.length - 1];
        }
    };
    WorkflowBuildContactsComponent.prototype.removeContact = function (contact) {
        this.workflowBuildService.removeContact(contact);
    };
    WorkflowBuildContactsComponent.prototype.canAddSigners = function () {
        if (!this.authorizationService.isIntegration()) {
            return true;
        }
        return this.workflowTemplateService.canAddSigners();
    };
    WorkflowBuildContactsComponent.prototype.openContactEditForm = function (contact, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.contactEditForm.setValue({
            _original: contact,
            name: contact.name,
            email: contact.email,
            whatsappNumber: this.replaceDDI(contact.whatsappNumber) || '',
            notifyWhatsapp: contact.notifyWhatsapp || false,
            hasPhotoSign: contact.hasPhotoSign || false
        });
        this.contactEditForm.clearValidators();
        this.modalService.open(this.modalEditContact, {
            ariaLabelledBy: 'modal-title',
            size: 'sm',
            centered: true,
            keyboard: false,
            backdrop: 'static'
        });
    };
    WorkflowBuildContactsComponent.prototype.formControlValidation = function (control) {
        return !control.pristine && control.invalid;
    };
    WorkflowBuildContactsComponent.prototype.isValidInputWhatsapp = function () {
        return (!this.contactForm.whatsappNumber.value ||
            this.contactForm.whatsappNumber.value.length !== 11);
    };
    WorkflowBuildContactsComponent.prototype.toggleSwitchPhoto = function (contact) {
        this.contactEditForm.setValue({
            _original: [contact],
            email: contact.email,
            name: contact.name,
            whatsappNumber: contact.whatsappNumber,
            notifyWhatsapp: contact.notifyWhatsapp,
            hasPhotoSign: contact.hasPhotoSign
        });
        this.submitEditContact();
    };
    WorkflowBuildContactsComponent.prototype.submitEditContact = function () {
        var contact = this.contactEditForm.value._original;
        var _a = this.contactEditForm.value, name = _a.name, email = _a.email, whatsappNumber = _a.whatsappNumber, notifyWhatsapp = _a.notifyWhatsapp, hasPhotoSign = _a.hasPhotoSign;
        contact.name = name;
        contact.email = email;
        contact.whatsappNumber = "" + COUNTRY_CODES.BR + whatsappNumber;
        contact.notifyWhatsapp = notifyWhatsapp;
        contact.hasPhotoSign = hasPhotoSign;
        this.modalService.dismissAll();
    };
    Object.defineProperty(WorkflowBuildContactsComponent.prototype, "contactForm", {
        get: function () {
            return this.contactEditForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    WorkflowBuildContactsComponent.prototype.createEditContactForm = function () {
        this.contactEditForm = this.formBuilder.group({
            _original: [],
            email: ['', [Validators.required, Validators.email]],
            name: ['', Validators.required],
            whatsappNumber: [''],
            notifyWhatsapp: [''],
            hasPhotoSign: ['']
        });
    };
    return WorkflowBuildContactsComponent;
}());
export { WorkflowBuildContactsComponent };
