import * as tslib_1 from "tslib";
import { BuildPageComponent } from '@app/manager/pages';
var IntegrationBuildPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationBuildPageComponent, _super);
    function IntegrationBuildPageComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showUpload = false;
        _this.reviewURL = '/integration/workflow/review';
        return _this;
    }
    return IntegrationBuildPageComponent;
}(BuildPageComponent));
export { IntegrationBuildPageComponent };
