<div class="form-modal-signature">
  <form (ngSubmit)="doSubmit()" [formGroup]="signatureForm" novalidate>
    <div class="row nav_s">
      <div
        class="col-4 col-md-2 btn-category"
        [class.active_btn]="showSignature === 'draw'"
        (click)="showSignature = 'draw'"
      >
        <span>Desenhar</span>
      </div>
    </div>

    <div class="comp_">
      <div *ngIf="showSignature == 'draw'">
        <app-draw-signature
          (dataSignDrawEvent)="typeSignature($event)"
        ></app-draw-signature>
      </div>
    </div>

    <div class="buttons">
      <div class="row">
        <div class="col-12 col-md-7">
          <span class="mt-3">
            <i class="fa fa-info-circle"></i> Ao clicar em “Assinar” você
            concorda com os
            <a
              href="assets/termo_uso_assine_online_2022.pdf"
              target="_blank"
              download="Termo de Uso Assine Online 2022.pdf"
              >Termos de Uso</a
            >
            e
            <a
              href="assets/politica_privacidade_assine_online_2022.pdf"
              target="_blank"
              download="Política de Privacidade Assine Online 2022.pdf"
              >Política de Privacidade</a
            >
            do Assine.Online.
          </span>
        </div>

        <div class="col-12 col-md-5">
          <div class="button">
            <button
              class="btn btn-outline-success"
              (click)="modal.close('cancel')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-secondary"
              title="Salvar assinatura presencial"
              [disabled]="signatureForm.invalid"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
