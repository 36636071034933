import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-linked-user-form',
  templateUrl: './linked-user-form.component.html',
  styleUrls: ['./linked-user-form.component.scss']
})
export class LinkedUserFormComponent implements OnInit {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of current user
   */

  /**
   * Data of Form
   */
  userForm: FormGroup;

  modalForm: any;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'user',
      formData: this.userForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.userForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {}
}
