import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '@app/core/models';

@Component({
  selector: 'app-additional-user',
  templateUrl: './additional-user.component.html',
  styleUrls: ['./additional-user.component.scss']
})
export class AdditionalUserComponent implements OnInit {
  /**
   * data of the logged user
   */
  @Input() selected: User.FormPayload;

  @Input() planInfo: User.UserPlanPayload;

  /**
   * Function for open Modal
   */
  @Output() modalAction? = new EventEmitter();

  /**
   * Modal Change Password
   */
  @Input() modalChangePassword: any;

  /**
   * Modal Cancel Account
   */
  @Input() modalCancelAccount: any;

  /**
   * Modal Create Visible Signature
   */
  @Input() modalVisibleSignature: any;

  /**
   * Modal Create access token for integration
   */
  @Input() modalAccessToken: any;

  /**
   * Modal Integration Docware
   */
  @Input() modalIntegrationDocuware: any;

  /**
   * roleDefault of user
   */
  roleDefault: boolean;

  constructor() {}

  ngOnInit() {
    if (this.selected.roleDefault === 'user') {
      return (this.roleDefault = false);
    }
    return (this.roleDefault = true);
  }

  /**
   * Open modal
   *
   * @param (any) modal content
   */
  openModal(modal: any, size: string = 'md') {
    this.modalAction.emit({ modal: modal, size: size });
  }
}
