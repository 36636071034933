<div class="row">
  <div class="col-12 col-md-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text"><i class="fas fa-search"></i></div>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Pesquise aqui"
        [formControl]="queryField"
        (keyup)="searchDebounce()"
      />
    </div>
  </div>

  <div class="col-12 col-md-6 filters">
    <span class="filter-label"> Filtros: </span>

    <select class="form-control" [(ngModel)]="searchStatus" (change)="search()">
      <option disabled>Status</option>
      <option [value]="config.value" *ngFor="let config of statusFilterConfig">
        {{ config.label }}
      </option>
    </select>

    <select class="form-control" [(ngModel)]="searchPeriod" (change)="search()">
      <option disabled>Período</option>
      <!-- Valores equivalentes a dias -->
      <option value="">Todos</option>
      <option value="1">1 dia</option>
      <option value="7">1 semana</option>
      <option value="30">1 mês</option>
    </select>
  </div>
</div>

<div class="alert alert-warning text-center mt-3">
  A assinatura presencial sempre mostrará os destinatários
  <strong>assinantes</strong> conforme a ordem de assinatura.
</div>

<app-lists
  [collection]="collection"
  [fields]="configList"
  [enableDetails]="false"
  [sortableFields]="sortFields"
  (activity)="doAction($event)"
  (sort)="doSort($event)"
></app-lists>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
