import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-in-person-signature',
  templateUrl: './select-in-person-signature.component.html',
  styleUrls: ['./select-in-person-signature.component.scss']
})
export class SelectInPersonSignatureComponent implements OnInit {
  /**
   * Data of Form
   */
  signatureForm: FormGroup;

  @Input() modal: any;

  chosenFont: string;

  showSignature: string;

  constructor(private formBuilder: FormBuilder) {
    this.showSignature = 'draw';
    this.createForm();
  }

  ngOnInit() {}

  typeSignature(event: Event) {
    this.signatureForm.setValue({
      signature: event['dataSignature'].sign
    });
  }

  public doSubmit(): void {
    this.modal.close({
      action: 'save',
      form: 'faceToFaceSubscription',
      formData: this.signatureForm.value.signature
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  public get f() {
    return this.signatureForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm(): void {
    this.signatureForm = this.formBuilder.group({
      signature: ['', Validators.required]
    });
  }
}
