<div class="box" [ngClass]="detailClass()">
  <div class="box-content">
    <div class="row">
      <div class="col-12">
        <span class="plan-name">{{ data.name }}</span>

        <hr style="margin-top: 6px;" />
      </div>
      <div class="col-12">
        <div class="price">
          <span class="symbol">R$</span>

          <span class="main-value">{{ data.price }}</span>
          <span class="cents">,{{ data.cents }}</span>

          <span class="by" *ngIf="data.billingInterval === 'month'"
            >/mensal</span
          >
          <span class="by" *ngIf="data.billingInterval === 'year'">/anual</span>
          <span class="by" *ngIf="data.billingInterval === 'day'">/diário</span>
        </div>
      </div>

      <ul class="description">
        <li *ngFor="let item of data.description">{{ item }}</li>
      </ul>

      <div
        class="col-12"
        *ngIf="isForBusinessAccountsOnly(); else disabledButton"
      >
        <button
          type="button"
          class="btn btn-outline-secondary btn-block"
          style="margin-top: 15px;"
          disabled
        >
          {{ buttonText }}
        </button>
      </div>
      <ng-template #disabledButton>
        <div class="col-12">
          <button
            type="button"
            class="btn btn-outline-success btn-block"
            style="margin-top: 15px;"
            (click)="choose(data)"
            *ngIf="!isCurrentPlan()"
          >
            Escolher
          </button>
          <button
            type="button"
            class="btn btn-outline-light btn-block"
            style="margin-top: 15px;"
            *ngIf="isCurrentPlan()"
            disabled
          >
            Meu plano atual
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
