import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-action',
  templateUrl: './modal-action.component.html',
  styleUrls: ['./modal-action.component.scss']
})
export class ModalActionComponent {
  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * show header of modal
   */
  @Input() showHeader?: boolean = true;

  /**
   * show footer of modal
   */
  @Input() showFooter?: boolean = true;

  /**
   * show button close of modal
   */
  @Input() showClose?: boolean = true;

  /**
   * show button action cancel of modal
   */
  @Input() showBtnCancel?: boolean = true;

  /**
   * show button action save of modal
   */
  @Input() showBtnSave?: boolean = true;

  /**
   * enable button action save of modal
   */
  @Input() disableBtnSave?: boolean = true;

  /**
   * class of button cancel
   */
  @Input() btnClassCancel?: string = 'btn-default';

  /**
   * class of modal
   */
  @Input() typeModal?: string = 'simple';

  /**
   * text of title
   */
  @Input() title?: string;

  /**
   * text of button action cancel
   */
  @Input() btnActionCancel?: string;

  /**
   * text of button action save
   */
  @Input() btnActionSave?: string;
}
