<div class="box-cookie">
  <h3>Cookies</h3>
  <p>
    Utilizamos os cookies para melhorar sua experiência. Para cumprir com a nova
    diretiva de privacidade, nós precisamos pedir seu consentimento para definir
    os cookies. Confira nossa política de cookies. <br /><strong
      ><a
        [href]="cookies.path"
        [download]="cookies.filename"
        [title]="cookies.title"
        >Saiba mais!</a
      ></strong
    >
  </p>
  <button class="btn btn-white btn-sm" (click)="toAccept()">Fechar</button>
</div>
