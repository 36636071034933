import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TestService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/user';

    this.apiFieldsMap = {
      id: 'id',
      cep: 'cep',
      street: 'rua',
      number: 'numero',
      neighborhood: 'bairro',
      complement: 'complemento'
    };

    this.fieldsRequired = ['id', 'cep', 'street', 'number', 'bla'];
  }
}
