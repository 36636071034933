/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../droppable-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./digital-signature.component";
var styles_WorkflowDigitalSignatureFieldComponent = [i0.styles];
var RenderType_WorkflowDigitalSignatureFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDigitalSignatureFieldComponent, data: {} });
export { RenderType_WorkflowDigitalSignatureFieldComponent as RenderType_WorkflowDigitalSignatureFieldComponent };
export function View_WorkflowDigitalSignatureFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "simple-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "signature-type-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assinatura Digital"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact.email; _ck(_v, 9, 0, currVal_0); }); }
export function View_WorkflowDigitalSignatureFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-digital-signature-field", [], null, null, null, View_WorkflowDigitalSignatureFieldComponent_0, RenderType_WorkflowDigitalSignatureFieldComponent)), i1.ɵdid(1, 49152, null, 0, i2.WorkflowDigitalSignatureFieldComponent, [], null, null)], null, null); }
var WorkflowDigitalSignatureFieldComponentNgFactory = i1.ɵccf("app-workflow-digital-signature-field", i2.WorkflowDigitalSignatureFieldComponent, View_WorkflowDigitalSignatureFieldComponent_Host_0, { contact: "contact" }, {}, []);
export { WorkflowDigitalSignatureFieldComponentNgFactory as WorkflowDigitalSignatureFieldComponentNgFactory };
