<div class="form-rename-folder form-modal" *ngIf="!isLoading">
  <div class="form">
    <div class="form-group">
      <label> Nome </label>
      <input
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="folderName"
      />
    </div>
    <div class="rename-modal-footer col-12 text-center mt-5">
      <button class="btn btn-default btn-sm" (click)="modal.close('cancel')">
        Cancelar
      </button>

      <button
        [disabled]="!folderName"
        [ngClass]="folderName ? 'btn-success' : 'btn-secondary'"
        class="btn btn-sm"
        (click)="doSubmit()"
      >
        Salvar
      </button>
    </div>
  </div>
</div>
<div class="text-center" *ngIf="isLoading" style="font-size: .9rem">
  Carregando..
</div>
