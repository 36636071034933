/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-lists.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./navigation-lists.component";
var styles_NavigationListsComponent = [i0.styles];
var RenderType_NavigationListsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationListsComponent, data: {} });
export { RenderType_NavigationListsComponent as RenderType_NavigationListsComponent };
function View_NavigationListsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "link-item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 6, 0, true); var currVal_1 = "active"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.link; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 9).target; var currVal_3 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_5); }); }
export function View_NavigationListsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row navigation-lists"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationListsComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_NavigationListsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-lists", [], null, null, null, View_NavigationListsComponent_0, RenderType_NavigationListsComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavigationListsComponent, [], null, null)], null, null); }
var NavigationListsComponentNgFactory = i1.ɵccf("app-navigation-lists", i4.NavigationListsComponent, View_NavigationListsComponent_Host_0, { pages: "pages" }, {}, []);
export { NavigationListsComponentNgFactory as NavigationListsComponentNgFactory };
