<div class="tools-block">
  <div class="tools-block__item">
    <div class="informative_text">
      {{
        !isMobile()
          ? 'Clique e arraste os campos para dentro do documento.'
          : 'Clique para adicionar os campos dentro do documento'
      }}
    </div>
    <ul class="list-tools">
      <li
        cdkDropList
        *ngFor="let field of draggableFieldList"
        [title]="field.description"
      >
        <span
          cdkDrag
          [cdkDragDisabled]="!canDrag(field)"
          [cdkDragData]="{ field: field, contact: contact }"
          (cdkDragMoved)="onDragMoved($event)"
          class="btn-action"
          (click)="isMobile() ? addFieldByClick(contact, field) : ''"
        >
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <i [ngClass]="'fa ' + field.icon"></i> {{ field.title }}
          <i class="fa fa-grip-vertical tool-drag-icon"></i>
        </span>
      </li>
    </ul>
  </div>
</div>
