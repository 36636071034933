import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationDocuware } from '../models';

@Injectable()
export class IntegrationDocuwareService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/integration-docware';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Create integration docware
   */
  create(
    payload: IntegrationDocuware.Payload
  ): Observable<IntegrationDocuware.FormIntegration> {
    return this.httpClient.post(this.endpoint, payload).pipe(
      map((response: any) => {
        return this.parseIntegration(response);
      })
    );
  }

  updateIntegration(
    id: string,
    payload: IntegrationDocuware.Payload
  ): Observable<IntegrationDocuware.FormIntegration> {
    return super.update(id, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteIntegration(id: string): Observable<any> {
    return super.delete(id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Get integration docware
   */
  getAll(): Observable<IntegrationDocuware.FormIntegration> {
    return super.getAll().pipe(
      map((response: any) => {
        return this.parseIntegration(response);
      })
    );
  }

  private parseIntegration(data: any): IntegrationDocuware.FormIntegration {
    let integration: IntegrationDocuware.FormIntegration;

    if (data.total_items > 0) {
      integration = {
        id: data._embedded.integration_docware[0].id,
        fileCabinetId: data._embedded.integration_docware[0].fileCabinetId,
        serverUrl: data._embedded.integration_docware[0].serverUrl,
        username: data._embedded.integration_docware[0].username,
        businessUnit: {
          id: data._embedded.integration_docware[0]._embedded.businessUnit.id,
          name:
            data._embedded.integration_docware[0]._embedded.businessUnit.name,
          document:
            data._embedded.integration_docware[0]._embedded.businessUnit
              .document,
          status:
            data._embedded.integration_docware[0]._embedded.businessUnit.status,
          country:
            data._embedded.integration_docware[0]._embedded.businessUnit
              .country,
          state:
            data._embedded.integration_docware[0]._embedded.businessUnit.state,
          city:
            data._embedded.integration_docware[0]._embedded.businessUnit.city,
          address:
            data._embedded.integration_docware[0]._embedded.businessUnit
              .address,
          zipCode:
            data._embedded.integration_docware[0]._embedded.businessUnit.zipCode
        }
      };

      return integration;
    }

    return integration;
  }
}
