<app-feedback [message]="feedbackMessage" [theme]="feedbackTheme">
</app-feedback>

<section class="page hero-background">
  <div class="hero"></div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="info">
          <h2>Crie sua conta</h2>
          <h6>Diminua os custos e a burocracia do seu negócio</h6>
        </div>
      </div>
    </div>

    <form (ngSubmit)="doSubmit()" novalidate [formGroup]="registerForm">
      <div class="form-row">
        <div class="form-group col-12">
          <input
            placeholder="Nome Completo *"
            class="form-control"
            [ngClass]="{
              'is-invalid': isNotValidField('name')
            }"
            formControlName="name"
          />
          <div *ngIf="isNotValidField('name')">
            <span class="error" [hidden]="!f.name.errors.required">
              Por favor, insira seu nome completo
            </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12 col-lg-6">
          <input
            #cellphone
            type="text"
            ng2TelInput
            class="form-control"
            placeholder="Celular *"
            formControlName="cellphone"
            [ng2TelInputOptions]="{
              initialCountry: 'br',
              onlyCountries: ['br']
            }"
            (ng2TelOutput)="getNumber($event)"
            [ngClass]="{
              'is-invalid': isNotValidField('cellphone')
            }"
            mask="(00) 00000-0000"
          />
          <div *ngIf="isNotValidField('cellphone')">
            <span class="error" [hidden]="!f.cellphone.errors.required">
              O telefone é obrigatório
            </span>
          </div>
        </div>

        <div class="form-group col-12 col-lg-6">
          <input
            placeholder="E-mail *"
            class="form-control"
            formControlName="email"
            [ngClass]="{ 'is-invalid': isNotValidField('email') }"
          />
          <div *ngIf="isNotValidField('email')">
            <span class="error" [hidden]="!f.email.errors.required">
              O e-mail é obrigatório
            </span>
            <span class="error" [hidden]="!f.email.errors.email">
              Por favor, insira um email válido
            </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <input
            placeholder="Empresa/Organização *"
            class="form-control"
            [ngClass]="{
              'is-invalid': isNotValidField('company')
            }"
            formControlName="company"
          />
          <div *ngIf="isNotValidField('company')">
            <span class="error" [hidden]="!f.company.errors.required">
              O nome da empresa/organização é obrigatório
            </span>
          </div>
        </div>
        <div class="form-group col-12 col-lg-6">
          <input
            type="text"
            class="form-control"
            placeholder="Cargo *"
            [ngClass]="{
              'is-invalid': isNotValidField('position')
            }"
            formControlName="position"
          />
          <div *ngIf="isNotValidField('position')">
            <span class="error" [hidden]="!f.position.errors.required">
              O nome do cargo é obrigatório
            </span>
          </div>
        </div>

        <div class="form-group col-12 col-lg-6">
          <select
            type="text"
            class="form-control"
            placeholder="Segmento de mercado *"
            [ngClass]="{ 'is-invalid': isNotValidField('segment') }"
            formControlName="segment"
          >
            <option value="" disabled selected>Segmento *</option>
            <option *ngFor="let e of segmentList" [value]="e.name">
              {{ e.name }}
            </option>
          </select>

          <div *ngIf="isNotValidField('segment')">
            <span class="error" [hidden]="!f.segment.errors.required">
              O segmento de marcado é obrigatório
            </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12 col-lg-6">
          <input
            placeholder="Senha *"
            type="password"
            class="form-control"
            formControlName="password"
            [ngClass]="{ 'is-invalid': isNotValidField('password') }"
          />
          <div *ngIf="isNotValidField('password')">
            <span class="error" [hidden]="!f.password.errors.required">
              A senha é obrigatória
            </span>
          </div>
        </div>

        <div class="form-group col-12 col-lg-6">
          <input
            placeholder="Repetir Senha *"
            type="password"
            class="form-control"
            formControlName="repeatPassword"
            [ngClass]="{
              'is-invalid':
                f.repeatPassword.errors && !f.repeatPassword.pristine
            }"
          />
          <div *ngIf="f.repeatPassword.errors && !f.repeatPassword.pristine">
            <span class="error" [hidden]="!f.repeatPassword.errors.unmatched">
              A confirmação deve ser igual a senha
            </span>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <span class="password-message"
            >Sua senha deve conter no mínimo 6 dígitos com letras maiúsculas,
            minúsculas e números.</span
          >
        </div>
      </div>

      <div class="captcha">
        <re-captcha
          (resolved)="resolved($event)"
          formControlName="recaptcha"
        ></re-captcha>
      </div>

      <div class="row mt-2">
        <div class="col-md-10">
          <div class="form-group form-check mt-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="check1"
              formControlName="policy"
            />
            <label class="form-check-label" for="check1"
              >Li e concordo com os
              <a
                [href]="termsOfUse.path"
                [download]="termsOfUse.filename"
                [title]="termsOfUse.title"
                >Termos de uso</a
              >
              e
              <a
                [href]="privacyPolicy.path"
                [download]="privacyPolicy.filename"
                [title]="privacyPolicy.title"
                >Política de privacidade</a
              ></label
            >
          </div>
        </div>

        <div class="col-md-2 d-flex justify-content-end">
          <button
            class="btn btn-secondary btn-sm"
            type="submit"
            [disabled]="registerForm.invalid"
          >
            <i class="fas fa-circle-notch fa-spin" [hidden]="!isLoading"></i>
            Criar conta
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
