/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./folder-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../../shared/components/pagination/pagination.component.ngfactory";
import * as i5 from "../../../../shared/components/pagination/pagination.component";
import * as i6 from "./folder-list.component";
import * as i7 from "../../../../core/services/authorization.service";
var styles_FolderListComponent = [i0.styles];
var RenderType_FolderListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FolderListComponent, data: {} });
export { RenderType_FolderListComponent as RenderType_FolderListComponent };
function View_FolderListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("changeIntegrationWithFolder", _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                  Alterar integra\u00E7\u00E3o\n                "]))], null, null); }
function View_FolderListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("createIntegrationWithFolder", _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                  Criar integra\u00E7\u00E3o\n                "]))], null, null); }
function View_FolderListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("deleteIntegrationWithFolder", _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                  Excluir integra\u00E7\u00E3o\n                "]))], null, null); }
function View_FolderListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_6)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.doAction(_v.parent.parent.context.$implicit, "change"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.doAction(_v.parent.parent.context.$implicit, "create"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.doAction(_v.parent.parent.context.$implicit, "delete"); _ck(_v, 9, 0, currVal_2); }, null); }
function View_FolderListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "d-inline-block handle-icon"], ["ngbDropdown", ""], ["style", "position: absolute; right: 5px; top: 14px;"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 212992, null, 2, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "i", [["aria-haspopup", "true"], ["class", "fas fa-ellipsis-v dropdown-toggle"], ["ngbDropdownToggle", ""], ["style", "font-size: 20px; width: 30px; text-align: center;"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(11, 16384, [[1, 4]], 0, i3.NgbDropdownMenu, [i3.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n              "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("share", _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Compartilhar\n              "])), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("rename", _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Renomear pasta\n              "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n          "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_5 = _co.shouldCheckIntegrationFolder(); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = true; var currVal_3 = i1.ɵnov(_v, 11).dropdown.isOpen(); var currVal_4 = i1.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); }); }
function View_FolderListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "col-12 col-md-6 col-lg-4 col-xl-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "slot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["style", "width: 100%; padding-left: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("enter", _v.context.$implicit, (_v.context.$implicit.owner.id === _co.user.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-folder folder-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["\n              ", "\n            "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.owner.id === _co.user.id); _ck(_v, 15, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.name === "default") ? "Padr\u00E3o" : _v.context.$implicit.name); _ck(_v, 11, 0, currVal_0); }); }
export function View_FolderListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "folder-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderListComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-pagination", [], null, [[null, "doPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("doPage" === en)) {
        var pd_0 = (_co.doPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationComponent_0, i4.RenderType_PaginationComponent)), i1.ɵdid(12, 114688, null, 0, i5.PaginationComponent, [], { totalItems: [0, "totalItems"], pageCount: [1, "pageCount"], currentPage: [2, "currentPage"] }, { doPage: "doPage" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.pagination.totalItems; var currVal_2 = _co.pagination.pageCount; var currVal_3 = _co.pagination.currentPage; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FolderListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-folder-list", [], null, null, null, View_FolderListComponent_0, RenderType_FolderListComponent)), i1.ɵdid(1, 49152, null, 0, i6.FolderListComponent, [i7.AuthorizationService], null, null)], null, null); }
var FolderListComponentNgFactory = i1.ɵccf("app-folder-list", i6.FolderListComponent, View_FolderListComponent_Host_0, { collection: "collection", pagination: "pagination", integrationWithFolder: "integrationWithFolder" }, { activity: "activity", doChangePage: "doChangePage" }, []);
export { FolderListComponentNgFactory as FolderListComponentNgFactory };
