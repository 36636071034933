/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "../../../core/services/authorization.service";
import * as i5 from "../../../core/services/business-unit-metadata.service";
import * as i6 from "@angular/router";
import * as i7 from "./footer/footer.component.ngfactory";
import * as i8 from "./footer/footer.component";
import * as i9 from "./simple.component";
var styles_LayoutSimpleComponent = [i0.styles];
var RenderType_LayoutSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutSimpleComponent, data: {} });
export { RenderType_LayoutSimpleComponent as RenderType_LayoutSimpleComponent };
export function View_LayoutSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-layout-simple-header", [], null, null, null, i2.View_LayoutSimpleHeaderComponent_0, i2.RenderType_LayoutSimpleHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.LayoutSimpleHeaderComponent, [i4.AuthorizationService, i5.BusinessMetadataService], { hideInfoBar: [0, "hideInfoBar"], showAuth: [1, "showAuth"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-layout-simple-footer", [], null, null, null, i7.View_LayoutSimpleFooterComponent_0, i7.RenderType_LayoutSimpleFooterComponent)), i1.ɵdid(9, 114688, null, 0, i8.LayoutSimpleFooterComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configLayout.removeInfoBar; var currVal_1 = (_co.configLayout.headerLayout !== "simple"); _ck(_v, 3, 0, currVal_0, currVal_1); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_LayoutSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-simple", [], null, null, null, View_LayoutSimpleComponent_0, RenderType_LayoutSimpleComponent)), i1.ɵdid(1, 114688, null, 0, i9.LayoutSimpleComponent, [i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutSimpleComponentNgFactory = i1.ɵccf("app-layout-simple", i9.LayoutSimpleComponent, View_LayoutSimpleComponent_Host_0, {}, {}, []);
export { LayoutSimpleComponentNgFactory as LayoutSimpleComponentNgFactory };
