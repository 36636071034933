import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { WorkflowContact } from '@app/core/class';

export class TextField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT;
    this.minHeight = 10;
    this.height = 15;
    this.minWidth = 50;
    this.width = 80;
  }
}
