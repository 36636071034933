import * as tslib_1 from "tslib";
import { EletronicSignatureField } from './eletronic-signature-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var InlineSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(InlineSignatureField, _super);
    function InlineSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE_INLINE;
        return _this;
    }
    return InlineSignatureField;
}(EletronicSignatureField));
export { InlineSignatureField };
