import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WorkflowPageComponent as ManagerWorkflowPageComponent } from '../../../manager/pages/workflow/workflow.component';
import {
  AuthorizationService,
  WorkflowBuildService,
  FeedbackService,
  WorkflowTemplateService,
  UserService
} from '@app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact, Document, InPersonSignature } from '@app/core/models';
import { WorkflowFile, WorkflowContact } from '@app/core/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
import { Format } from '@app/core/helpers';

@Component({
  selector: 'app-workflow-page',
  templateUrl: './workflow.component.html',
  styleUrls: ['../../../manager/pages/workflow/workflow.component.scss']
})
export class WorkflowPageComponent extends ManagerWorkflowPageComponent
  implements OnInit {
  /**
   * template of modal for edit recipient
   */
  @ViewChild('modalLoading')
  public loading: ElementRef;

  public buildURL: string = '/integration/workflow';
  public reviewURL: string = '/integration/workflow/review';
  public finishURL: string = '/integration/workflow/finish';
  private errorURL: string = '/integration/workflow/error';

  /**
   * modal
   */
  modalRef: any;

  public contact: WorkflowContact;

  constructor(
    protected authorizationService: AuthorizationService,
    protected workflowBuildService: WorkflowBuildService,
    protected feedbackService: FeedbackService,
    protected router: Router,
    private route: ActivatedRoute,
    private workflowTemplateService: WorkflowTemplateService,
    private modalService: NgbModal,
    protected userService: UserService
  ) {
    super(
      authorizationService,
      workflowBuildService,
      feedbackService,
      router,
      userService
    );
    this.modalRef = {
      ref: null,
      data: { text: '', showClose: false, title: '' }
    };
  }

  public ngOnInit(): void {
    const templateId = this.route.snapshot.queryParams['id'];
    const templateToken = this.route.snapshot.queryParams['token'];

    if (this.route.snapshot.queryParams['whatsapp']) {
      this.setWhatsAppIntegration(
        Format.conversionToBoolean(this.route.snapshot.queryParams['whatsapp'])
      );
    }

    if (!templateId) {
      this.router.navigate([this.errorURL], {
        queryParams: {
          error: 'É necessário que informe o ID do modelo a utilizar!'
        }
      });
      return;
    }

    if (!templateToken) {
      this.router.navigate([this.errorURL], {
        queryParams: {
          error: 'É necessário que informe o token para acessar o modelo!'
        }
      });
      return;
    }

    // Stack trace unit testing errors
    const subs = from(
      this.loadWorkflowTemplate(templateId, templateToken)
    ).subscribe(() => {
      subs.unsubscribe();
    });
  }

  private setWhatsAppIntegration(flag: boolean) {
    this.workflowBuildService.setWhatsApp(flag);
  }

  /**
   * Don't fetch anything
   */
  protected fetchDefaultFolder(): void {
    this.workflowBuildService.setDefaultFolderId(0);
    return;
  }

  private async loadWorkflowTemplate(id: number, token: string): Promise<void> {
    let response;

    await this.showLoadingDocumentsModal();

    try {
      response = await this.workflowTemplateService
        .getWorkflowTemplateById(id, token)
        .toPromise();
    } catch (error) {
      let errorMessage: string = '';

      if (error.error) {
        errorMessage = error.error.detail;
      } else {
        errorMessage = `[${error.status}] ${error.message}`;
      }

      this.feedbackService.error(errorMessage);
      return;
    }

    if (!this.isTemplateValid(response.template)) {
      return;
    }

    this.workflowTemplateService.setSettings(response.settings);

    let lastWorkflowfile: WorkflowFile;

    const template = response.template;
    this.workflowBuildService.setSettings({
      autoRemind: template.autoRemind,
      dueDate: template.dueDate,
      message: template.message,
      sla: template.sla,
      priority: template.priority,
      name: template.name,
      autoInitiate: template.autoInitiate
    });

    for (const file of template.files) {
      try {
        const workflowFile = await this.createWorkflowFile(file);
        this.workflowBuildService.addFile(workflowFile);
        lastWorkflowfile = workflowFile;

        for (const step of file.workflowSteps) {
          const exists = this.workflowBuildService
            .getContacts()
            .find(contact => {
              return contact.email === step.user.email;
            });

          if (exists) {
            continue;
          }

          const workflowContact = this.createWorkflowContact(step);
          this.workflowBuildService.addContact(workflowContact);

          if (step.fields) {
            for (const field of step.fields) {
              const page = workflowFile.getPage(field.page);
              const f = WorkflowDroppedFieldsStrategy.MakeDroppedField(
                field.type,
                workflowContact,
                step.signatureType
              );

              f.setViewportProportion(1);
              f.setSize(field.width, field.height);
              f.setCoordinates(field.x, field.y);
              page.addField(f);
            }
          }
        }
      } catch (error) {
        this.feedbackService.error(
          `Não foi possível fazer download do arquivo ${file.name}. Erro: ${
            error.message
          }`
        );
        return;
      }

      this.workflowBuildService.setFileAsVisible(lastWorkflowfile);

      if (this.modalRef.ref) {
        this.modalRef.ref.close();
      }
    }
  }

  private async createWorkflowFile(
    file: Document.FileWorkflowPayload
  ): Promise<WorkflowFile> {
    const downloadLink = file._embedded.file._links.download.href;
    const workflowFile = await WorkflowFile.MakeWorkflowFileFromURL(
      downloadLink
    );

    workflowFile.setName(file.name);
    workflowFile.setIdFile(file.idFile);

    const defaultFolderId = this.workflowBuildService.getDefaultFolderId();
    workflowFile.setFolderId(file.idFolder || defaultFolderId);

    return workflowFile;
  }

  private createWorkflowContact(
    step: Document.StepsWorkflowPayload
  ): WorkflowContact {
    const workflowContact: Contact.Contact = {
      id: step.idUser || null,
      email: step.user.email,
      name: step.user.name,
      whatsappNumber: step.user.whatsappNumber,
      notifyWhatsapp: step.user.notifyWhatsapp
    };

    if (step.extraMetadata) {
      workflowContact.extraMetadata = {
        name: step.extraMetadata.name,
        document: step.extraMetadata.document,
        dataNascimento: step.extraMetadata.dataNascimento,
        photo: step.extraMetadata.photo
      };
    }

    return WorkflowContact.MakeWorkflowContact(workflowContact);
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  private open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return modalRef;
  }

  private isTemplateValid(template: Document.WorkflowPayload): boolean {
    if (template.files.length === 1) {
      return true;
    }
    const files = template.files.slice();
    // Check if the signers exists in all steps
    const signersEmail: string[] = [];
    let signersAmount: number = 0;
    const firstFileSteps = files.shift();
    for (const step of firstFileSteps.workflowSteps) {
      if (step.user) {
        signersAmount++;
        signersEmail.push(step.user.email);
      }
    }
    const signersErrorText =
      'A definição de assinantes está inválida, favor checar a construção do fluxo.';
    // Now check if the other files match
    for (const file of files) {
      let curSignersAmount: number = 0;
      for (const step of file.workflowSteps) {
        if (step.user) {
          curSignersAmount++;
          const exists = signersEmail.findIndex(
            email => email === step.user.email
          );
          if (exists === -1) {
            this.feedbackService.error(signersErrorText);
            return false;
          }
        }
      }
      // If there are more or there are less signers, show error
      if (curSignersAmount !== signersAmount) {
        this.feedbackService.error(signersErrorText);
        return false;
      }
    }
    return true;
  }

  private async showLoadingDocumentsModal(): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        this.modalRef.data.title = '';
        this.modalRef.data.text =
          'Preparando seu(s) documento(s) para contrução';
        this.modalRef.ref = this.open(this.loading);
        resolve();
      });
    });
  }
}
