import { Component } from '@angular/core';
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';

@Component({
  selector: 'app-workflow-droppable-sign-date-field',
  templateUrl: './sign-date.component.html',
  styleUrls: ['../../droppable-field.component.scss']
})
export class WorkflowDroppableSignDateFieldComponent extends WorkflowBaseDroppedField {
  public date = Date.now();
}
