import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutSimple } from '@app/shared/layouts';

import { SubscribeRoutingPath } from './subscribe-routing.path';
import { SubscribeComponent } from './subscribe.component';

const routes: Routes = [
  LayoutSimple.childRoutes([
    {
      path: SubscribeRoutingPath.SUBSCRIBE,
      component: SubscribeComponent
    },
    {
      path: SubscribeRoutingPath.SUBSCRIBE_DOCUMENT,
      component: SubscribeComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SubscribeRoutingModule {}
