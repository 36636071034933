import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
import { CreditCardValidator } from 'angular-cc-library';
import { RecaptchaComponent } from 'ng-recaptcha';
var log = new Logger('Payment');
var CreditCardComponent = /** @class */ (function () {
    function CreditCardComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.infoFilled = new EventEmitter();
        this.cardSide = true;
        this.cardinfo = {};
        this.isVisa = false;
        this.isMastercard = false;
        this.cardForm = this.formBuilder.group({
            document: [
                '',
                Validators.compose([Validators.required, Validators.minLength(11)])
            ],
            country: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            address: ['', Validators.required],
            zipCode: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(8)
                ])
            ],
            recaptcha: ['', Validators.required],
            holderName: ['', Validators.required],
            number: ['', CreditCardValidator.validateCCNumber],
            expMonth: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(2)
                ])
            ],
            expYear: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4)
                ])
            ],
            cvv: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(3)
                ])
            ]
        });
    }
    CreditCardComponent.prototype.ngOnInit = function () {
        this.provisionalInformation();
    };
    CreditCardComponent.prototype.ngOnDestroy = function () {
        RecaptchaComponent.prototype.ngOnDestroy = function () {
            // reset the captcha to ensure it does not leave anything behind
            // after the component is no longer needed
            this.grecaptchaReset();
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        };
    };
    CreditCardComponent.prototype.resolved = function (captchaResponse) {
        log.debug("Resolved captcha with response: " + captchaResponse);
    };
    CreditCardComponent.prototype.recaptchaReset = function () {
        grecaptcha.reset();
    };
    CreditCardComponent.prototype.shouldResetValueRecaptcha = function () {
        this.cardForm.controls.recaptcha.setValue('');
    };
    CreditCardComponent.prototype.shouldResetRecaptchaAndForm = function () {
        this.recaptchaReset();
        this.shouldResetValueRecaptcha();
    };
    CreditCardComponent.prototype.onSubmit = function () {
        var form = this.cardForm.value;
        this.infoFilled.emit({
            holderName: form.holderName,
            number: form.number,
            expMonth: form.expMonth,
            expYear: form.expYear.substring(2, 4),
            cvv: form.cvv,
            document: form.document,
            billingAddress: {
                country: form.country,
                state: form.state,
                city: form.city,
                address: form.address,
                zipCode: form.zipCode
            },
            recaptcha: form.recaptcha
        });
    };
    CreditCardComponent.prototype.cardHolderNameToUppercase = function () {
        if (this.cardinfo && this.cardinfo.name) {
            this.cardinfo.name = this.cardinfo.name.toUpperCase();
        }
    };
    Object.defineProperty(CreditCardComponent.prototype, "f", {
        get: function () {
            return this.cardForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CreditCardComponent.prototype.ValidateCreditCardNumber = function () {
        var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
        var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
        if (visaRegEx.test(this.cardinfo.number)) {
            this.isVisa = true;
            this.isMastercard = false;
        }
        else if (mastercardRegEx.test(this.cardinfo.number)) {
            this.isMastercard = true;
            this.isVisa = false;
        }
        else {
            this.isVisa = false;
            this.isMastercard = false;
        }
    };
    CreditCardComponent.prototype.provisionalInformation = function () {
        this.cardForm.setValue({
            document: this.logged.businessUnit.document,
            country: 'BR',
            state: this.logged.businessUnit.state,
            city: this.logged.businessUnit.city,
            address: this.logged.businessUnit.address,
            zipCode: this.logged.businessUnit.zipCode,
            recaptcha: '',
            holderName: '',
            number: '',
            expMonth: '',
            expYear: '',
            cvv: ''
        });
    };
    return CreditCardComponent;
}());
export { CreditCardComponent };
