<app-feedback
  [theme]="feedback.type"
  [message]="feedback.message"
></app-feedback>

<div class="page page-checksign">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li class="active"><a routerLink="/manager"> Página inicial </a></li>
          <li class="active"><a routerLink="/manager/check"> Verificar </a></li>
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a routerLink="/manager/check-sign"> Assinatura </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-6 offset-3">
        <form class="form" [formGroup]="dataForm">
          <h2>Verificador de documentos</h2>
          <div class="form-row">
            <div class="form-group col-12">
              <app-upload
                buttonText="Procurar"
                dragDropText="Arraste e solte seus documentos aqui serem verificados."
                showModalStatus="true"
                [layout]="'small'"
                (processFile)="createDocument($event)"
              >
              </app-upload>
            </div>
          </div>
          <div class="form-row verify-code">
            <div class="form-group col-12">
              <label>Verificar pelo código do documento</label>
              <input
                placeholder="Ex.: 300449490440"
                class="form-control"
                formControlName="code"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="doSubmit()"
              >
                Verificar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
