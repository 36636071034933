import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Document, Folder } from 'app/core/models';
import {
  FolderIntegrationService,
  FolderService,
  IntegrationDocuwareService
} from 'app/core/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core/class';
import { AlertSubscription } from '@app/core/subscriptions';

const log = new Logger('FoldersPage');

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersPageComponent implements OnInit {
  /**
   * template of modal for loading
   */
  @ViewChild('modalLoading') loading: ElementRef;
  @ViewChild('modalShareFolder') public modalShareFolder: ElementRef;
  @ViewChild('modalMoverFolder') public modalMoverFolder: ElementRef;
  @ViewChild('modalRenameFolder') public modalRenameFolder: ElementRef;
  @ViewChild('modalIntegrationWithFolder')
  public modalIntegrationWithFolder: ElementRef;
  @ViewChild('modalDeleteIntegrationWithFolder')
  public modalDeleteIntegrationWithFolder: ElementRef;

  /**
   * collection with data
   */
  collection: Array<Folder.FolderPayload>;

  folderIntegration: Folder.FolderCollectionIntegration;
  selectedFolderIntegration: Folder.FolderIntegrationPayloadModal;
  titleModalIntegrationWithFolder: string;

  collectionOwner: Array<object>;

  /**
   * current Folder for edit/delete
   */
  selectedFolder: any;

  /**
   * modal
   */
  modalRef: any;

  pagination: any;
  paginationDocs: any;

  currentPage: number;
  currentRoute: string;
  selected: any = null;
  folderDocuments: any;
  isLoading: boolean = false;

  moveDocument: Document.MoveDocument;
  showComponentList: boolean = false;

  canShowIntegrationInput: boolean;

  constructor(
    private folderService: FolderService,
    private folderIntegrationService: FolderIntegrationService,
    private integrationDocuwareService: IntegrationDocuwareService,
    private modalService: NgbModal,
    private alertSubscription: AlertSubscription,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentRoute = '/manager/manage/folders';
    this.modalRef = { ref: null, data: { text: '' } };
    this.canShowIntegrationInput = false;

    this.pagination = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
    this.paginationDocs = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
  }

  ngOnInit() {
    this.isloading('carregando dados...');
    this.getIntegrationDocware();
    this.getFolders('?page=1');
  }

  /**
   * provides the data of folders
   *
   * @return void
   */
  getFolders(params?: any) {
    try {
      this.folderService.getAll(params).subscribe(
        (response: any) => {
          log.debug(`Success [getFolders]:`, response);
          this.collection = response.items;
          this.collectionOwner = response.items;

          this.pagination = {
            totalItems: response.total_items,
            pageCount: response.page_count,
            pageSize: response.page_size,
            currentPage: response.page
          };
          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }
        },
        (error: any) => {
          log.debug(`Error [getFolders]:`, error);
          this.collection = [];
          this.modalRef.ref.close();
        }
      );
    } catch (e) {
      this.collection = [];
      log.debug(`Error [getFolders]:`, e);
      this.modalRef.ref.close();
    }
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any, size?: any) {
    this.modalRef.ref = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      keyboard: false,
      centered: true,
      backdrop: 'static',
      size: size || 'lg'
    });

    this.modalRef.ref.result.then((result: any) => {
      if (result && result.action === 'save') {
        if (result.form === 'folder') {
          this.doSubmitForm(result.formData);
          return;
        }

        if (result.form === 'moveDocument') {
          this.moveFolderDocument(result.formData);
          return;
        }

        if (result.form === 'renameFolder') {
          this.renameFolder(result.formData);
          return;
        }

        if (result.form === 'changeIntegrationWithFolder') {
          this.updateIntegrationFolder(result.formData);
          return;
        }

        if (result.form === 'createIntegrationWithFolder') {
          const payload: Folder.FolderIntegrationRequest = {
            folder: result.formData.idFolder,
            metadata: result.formData.metadata
          };
          this.addFolderIntegration(payload);
          return;
        }

        if (result.form === 'deleteIntegrationWithFolder') {
          this.deleteIntegrationFolder(result.formData);
          return;
        }
      }

      if (result && result === 'cancel') {
        return;
      }
    });
  }

  closeModal() {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  moveFolderDocument(payload: any) {
    this.isloading('Movendo documento de pasta, aguarde...');

    this.moveDocument.idFolder = payload.id;
    this.moveDocument.nameFolder = payload.name;

    const idFolder = { folder: this.moveDocument.idFolder };

    this.folderService
      .moveFolderDocument(this.moveDocument.idDocument, idFolder)
      .subscribe(
        (response: any) => {
          log.debug(`Success [moveFolderDocument]:`, response);
          this.getDocuments(this.selected, 1);
          this.closeModal();
        },
        (error: any) => {
          log.debug(`Error [moveFolderDocument]:`, error);
          this.closeModal();
        }
      );
  }

  renameFolder(payload: any) {
    this.isloading('atualizando dados...');
    this.folderService
      .renameFolder(this.selectedFolder.id, payload.folderName)
      .subscribe((result: any) => {
        const page = this.currentPage || '1';
        const querystring = `?page=${page}`;
        this.getFolders(querystring);
      });
  }

  isloading(message: string) {
    setTimeout(
      (() => {
        this.modalRef.data.text = message;
        this.open(this.loading);
      }).bind(this)
    );
  }

  showComponentListFile() {
    this.showComponentList = !this.showComponentList;
  }

  /**
   * Action by clicking in submit Folder form
   *
   * @param (any) form with data
   */
  doSubmitForm(form: any) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    const payload: any = {
      name: form.name,
      path: form.name
    };

    this.folderService.create(payload).subscribe(
      (response: any) => {
        this.alertSubscription.update({
          type: 'success',
          message: 'Pasta criada com sucesso'
        });
        const page = this.currentPage || '1';
        const querystring = `?sort=asc&sortBy=name&page=${page}`;
        if (form.metadata.file_cabinet_id) {
          this.addFolderIntegration({
            metadata: form.metadata,
            folder: response.id
          });
          return;
        }
        this.getFolders(querystring);
      },
      (error: any) => {
        this.alertSubscription.update({
          type: 'error',
          message: error.error.detail
        });
      }
    );
  }

  addFolderIntegration(folderIntegration: Folder.FolderIntegrationRequest) {
    this.folderIntegrationService
      .createFolderIntegration(folderIntegration)
      .subscribe(
        (response: any) => {
          this.getFolders();
          log.debug(`Success [createIntegrationFolder]:`, response);
        },
        (error: any) => {
          this.alertSubscription.update({
            type: 'error',
            message: error.error.detail
          });
          log.debug(`Error [createIntegrationFolder]:`, error);
        }
      );
  }

  updateIntegrationFolder(integrationFolder: Folder.FolderIntegrationPayload) {
    this.folderIntegrationService
      .updateFolderIntergation(integrationFolder)
      .subscribe(
        (response: any) => {
          log.debug(`Success [updateIntegrationFolder]:`, response);
          this.getFolders();
          this.alertSubscription.update({
            type: 'success',
            message: 'Integraçao com a pasta foi atualizada'
          });
        },
        (error: any) => {
          log.debug(`Error [updateIntegrationFolder]:`, error);
          this.alertSubscription.update({
            type: 'error',
            message: error.error.detail
          });
        }
      );
  }

  deleteIntegrationFolder(payload: Folder.FolderIntegrationPayloadModal) {
    this.folderIntegrationService
      .deleteIntegrationWithFolder(payload.folderIntegration.id)
      .subscribe(
        (response: any) => {
          log.debug(`Success [deleteIntegrationFolder]:`, response);
          this.getFolders();
          this.alertSubscription.update({
            type: 'success',
            message: 'Integraçao com pasta foi desfeita.'
          });
        },
        (error: any) => {
          log.debug(`Error [deleteIntegrationFolder]:`, error);
          this.alertSubscription.update({
            type: 'error',
            message: error.error.detail
          });
        }
      );
  }

  getIntegrationDocware() {
    this.integrationDocuwareService.getAll().subscribe(response => {
      if (response) {
        this.canShowIntegrationInput = true;
      }
    });
  }

  hasIntegrationWithFolder() {
    return this.canShowIntegrationInput;
  }

  textModal() {
    return 'Tem certeza que deseja excluir a integração com a pasta? Essa ação não poderá ser desfeita.';
  }

  getDocuments(data: any, page: any) {
    this.folderService
      .getFolderDocuments(data.id, page)
      .subscribe((body: any) => {
        this.folderDocuments = body._embedded.document;
        this.paginationDocs = {
          totalItems: body.total_items,
          pageCount: body.page_count,
          pageSize: body.page_size,
          currentPage: body.page
        };
        this.modalRef.ref.close();
      });
  }

  /**
   * Action grab data
   *
   * @param (any) event to process an action
   */
  doAction(event: any) {
    if (event.type === 'share') {
      this.selectedFolder = event.data;
      this.modalService.open(this.modalShareFolder, {
        ariaLabelledBy: 'modal-title',
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });
    }

    if (event.type === 'rename') {
      this.selectedFolder = event.data;
      this.open(this.modalRenameFolder, 'md');
    }

    if (event.type === 'enter') {
      setTimeout(
        (() => {
          this.modalRef.data.text = 'carregando dados...';
          this.open(this.loading);
        }).bind(this)
      );

      this.selected = event.data;
      this.getDocuments(this.selected, 1);
    }

    if (event.type === 'originalFile') {
      let url: string;
      url = event.data._links.self.href;
      url = url.replace('/' + event.data.id, '?q=');

      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url + event.data.uuid
      }).dispatchEvent(
        new MouseEvent(`click`, {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    }

    if (event.type === 'signedFile') {
      let url: string;
      url = event.data._links.self.href;
      url = url.replace('/' + event.data.id, '?q=');

      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url + event.data.uuid
      }).dispatchEvent(
        new MouseEvent(`click`, {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    }

    if (event.type === 'view') {
      this.router.navigate(
        [`/manager/manage/workflow/detail/${event.data.id}`],
        {
          replaceUrl: true
        }
      );
    }

    if (event.type === 'toMove') {
      this.moveDocument = {
        idDocument: event.data.id,
        nameDocument: event.data.name
      };
      this.open(this.modalMoverFolder, 'md');
    }

    if (event.type === 'changeIntegrationWithFolder') {
      this.selectedFolderIntegration = {
        id: event.data.id,
        name: event.data.name,
        path: event.data.path,
        folderIntegration: event.data.folderIntegration,
        titleModal: 'Alterar integração',
        action: 'change'
      };
      this.open(this.modalIntegrationWithFolder, 'md');
    }

    if (event.type === 'createIntegrationWithFolder') {
      this.selectedFolderIntegration = {
        id: event.data.id,
        name: event.data.name,
        path: event.data.path,
        folderIntegration: event.data.folderIntegration,
        titleModal: 'Criar Integração com a pasta',
        action: 'create'
      };
      this.open(this.modalIntegrationWithFolder, 'md');
    }

    if (event.type === 'deleteIntegrationWithFolder') {
      this.selectedFolderIntegration = {
        id: event.data.id,
        name: event.data.name,
        path: event.data.path,
        folderIntegration: event.data.folderIntegration,
        titleModal: 'Criar Integração com a pasta',
        action: 'delete'
      };
      this.open(this.modalDeleteIntegrationWithFolder, 'md');
    }
  }

  /**
   * Action for change page
   *
   * @param (number) of page
   */
  onChangePage(p: number) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    const payload = {
      page: p
    };

    this.currentPage = payload.page;
    this.router.navigate([this.currentRoute], {
      replaceUrl: true,
      queryParams: payload
    });
    this.getFolders('?page=' + p);
  }

  onChangePageDocs(p: number) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    this.getDocuments(this.selected, p);
  }
}
