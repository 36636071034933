<div class="events-list" *ngIf="events && events.length > 0">
  <div class="event-item" *ngFor="let event of compileEvents(events)">
    <div class="event-image" *ngIf="showInitials">
      <div class="image"></div>
      <div class="initial">{{ event.id }}</div>
    </div>
    <div class="event-data" [ngClass]="layout" title="{{ event.message }}">
      <div class="data-title">
        <span class="data-title__user-name">
          <strong> {{ event.userName }}</strong>
        </span>
        <span class="data-title__date"> - {{ event.eventDateStatus }} </span>
      </div>
      <div>
        <div class="data-document-status">
          <div [ngClass]="event.titleColorClass">
            <i [ngClass]="event.icon"></i>
            <span class="text">{{ event.title }}</span>
          </div>
        </div>
        <div class="data-document-status__action">
          <a class="action-link" [href]="event.link" target="_blank">{{
            event.actionTitle
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<p *ngIf="!events || events.length === 0">Não há registros cadastrados</p>
