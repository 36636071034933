import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseAPI } from '../class/baseAPI.class';
import { parseDate } from '../helpers';
var WorkflowTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowTemplateService, _super);
    function WorkflowTemplateService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/workflow-template';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Gets all data of a service
     */
    WorkflowTemplateService.prototype.getAll = function (queryString) {
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            var list = response._embedded.workflow_template.map(function (wt) {
                return Object.assign(wt, {
                    dateCreated: parseDate(wt.dateCreated)
                });
            });
            return {
                items: list,
                page: response.page,
                page_count: response.page_count,
                page_size: response.page_size,
                total_items: response.total_items,
                _raw: response._embedded,
                _links: response._links
            };
        }));
    };
    WorkflowTemplateService.prototype.getWorkflowTemplateById = function (id, token) {
        if (token === void 0) { token = ''; }
        var httpClient = this.httpClient;
        if (!!token) {
            httpClient = httpClient.disableAuthorization();
        }
        return httpClient.get(this.endpoint + "/" + id, {
            params: { token: token }
        });
    };
    WorkflowTemplateService.prototype.createWorkflowTemplate = function (name, workflow, settings) {
        if (settings === void 0) { settings = {}; }
        return this.httpClient.post(this.endpoint, {
            type: 1,
            name: name,
            settings: settings,
            template: workflow
        });
    };
    WorkflowTemplateService.prototype.updateWorkflowTemplate = function (id, workflow, token) {
        var httpClient = this.httpClient;
        if (!!token) {
            httpClient = httpClient.disableAuthorization();
        }
        return httpClient
            .patch(this.endpoint + "/" + id, {
            template: workflow
        }, {
            params: { token: token }
        })
            .pipe(map(function (response) { return response.template; }));
    };
    WorkflowTemplateService.prototype.renameWorkflowTemplate = function (id, name) {
        return this.httpClient.patch(this.endpoint + "/" + id, { name: name });
    };
    WorkflowTemplateService.prototype.deleteWorkflowTemplate = function (id) {
        return this.httpClient.delete(this.endpoint + "/" + id);
    };
    WorkflowTemplateService.prototype.setSettings = function (settings) {
        this.templateSettings = settings;
        // If 'canAddSigners' is not set, default it to true
        if (!settings.hasOwnProperty('canAddSigners')) {
            this.templateSettings.canAddSigners = true;
        }
    };
    WorkflowTemplateService.prototype.canAddSigners = function () {
        if (!this.templateSettings) {
            return true;
        }
        return this.templateSettings.canAddSigners;
    };
    return WorkflowTemplateService;
}(BaseAPI));
export { WorkflowTemplateService };
