<div class="field-block field-sign">
  <div [ngSwitch]="field.getSignatureType()">
    <ng-template [ngSwitchCase]="WORKFLOW_SIGNATURE.ELETRONIC">
      <app-workflow-eletronic-signature-field
        [contact]="contact"
      ></app-workflow-eletronic-signature-field>
    </ng-template>

    <ng-template [ngSwitchCase]="WORKFLOW_SIGNATURE.DIGITAL">
      <app-workflow-digital-signature-field
        [contact]="contact"
      ></app-workflow-digital-signature-field>
    </ng-template>

    <ng-template [ngSwitchCase]="WORKFLOW_SIGNATURE.IN_PERSON">
      <app-workflow-in-person-signature-field
        [field]="field"
      ></app-workflow-in-person-signature-field>
    </ng-template>
  </div>
</div>
