<div class="page page-documents">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li><a routerLink="/manager"> Página inicial </a></li>
          <li><a routerLink="/manager/documents"> Documentos e Pastas </a></li>
          <li><a> Documentos </a></li>
        </ul>
      </div>
      <div class="col-12">
        <app-navigation-lists [pages]="pages"> </app-navigation-lists>
      </div>
      <div class="col-12">
        <div class="documents-list">
          <div class="row" *ngIf="selectedItems.length > 0">
            <div class="col-12">
              <div class="document-nav">
                <div class="buttons">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    *ngIf="selectedItems.length > 0"
                    (click)="deleteMarkedDocuments()"
                  >
                    Remover Selecionados
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="document-table">
                <app-lists
                  [collection]="collectionDocument.items"
                  [fields]="configList"
                  [sortableFields]="sortFields"
                  [enableDetails]="true"
                  (seletec)="getSelectedItems($event)"
                  (choosed)="getClickedItem($event)"
                  (activity)="doAction($event)"
                  (sort)="doSort($event)"
                ></app-lists>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
