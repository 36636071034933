<div class="container-fluid">
  <div class="row navigation-lists">
    <div class="link" *ngFor="let page of pages">
      <div
        class="link-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a [routerLink]="page.link"> {{ page.name }} </a>
      </div>
    </div>
  </div>
</div>
