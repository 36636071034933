<div class="folder-list-container">
  <div class="row">
    <div
      *ngFor="let folder of collection"
      class="col-12 col-md-6 col-lg-4 col-xl-3"
    >
      <div class="slot">
        <div class="cell">
          <div
            style="width: 100%; padding-left: 15px;"
            (click)="goAction('enter', folder, folder.owner.id === user.id)"
          >
            <i class="fas fa-folder folder-icon"></i>
            <span>
              {{ folder.name === 'default' ? 'Padrão' : folder.name }}
            </span>
          </div>
          <div
            style="position: absolute; right: 5px; top: 14px;"
            ngbDropdown
            class="d-inline-block handle-icon"
            *ngIf="folder.owner.id === user.id"
          >
            <i
              class="fas fa-ellipsis-v"
              ngbDropdownToggle
              style="font-size: 20px; width: 30px; text-align: center;"
            >
            </i>
            <div ngbDropdownMenu>
              <ng-container *ngIf="shouldCheckIntegrationFolder()">
                <button
                  class="dropdown-item"
                  *ngIf="doAction(folder, 'change')"
                  (click)="goAction('changeIntegrationWithFolder', folder)"
                >
                  Alterar integração
                </button>
                <button
                  class="dropdown-item"
                  *ngIf="doAction(folder, 'create')"
                  (click)="goAction('createIntegrationWithFolder', folder)"
                >
                  Criar integração
                </button>
                <button
                  class="dropdown-item"
                  *ngIf="doAction(folder, 'delete')"
                  (click)="goAction('deleteIntegrationWithFolder', folder)"
                >
                  Excluir integração
                </button>
                <div class="dropdown-divider"></div>
              </ng-container>

              <button class="dropdown-item" (click)="goAction('share', folder)">
                Compartilhar
              </button>
              <button
                class="dropdown-item"
                (click)="goAction('rename', folder)"
              >
                Renomear pasta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pagination">
  <app-pagination
    [totalItems]="pagination.totalItems"
    [pageCount]="pagination.pageCount"
    [currentPage]="pagination.currentPage"
    (doPage)="doPage($event)"
  ></app-pagination>
</div>
