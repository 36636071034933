export const SIGNATORY_CATEGORIES = {
  1: {
    active: 1,
    id: 1,
    text: 'Conselho Federal de Medicina',
    label: 'Medicines - Para Médicos e Documentos da Área de Saúde',
    oid: '2_16_76_1_4_2_2',
    documentsCategory: 1,
    signatoryData: [
      {
        id: 'crm',
        label: 'Número do CRM',
        text: 'Número de registro do profissional',
        oid: '2_16_76_1_4_2_2_1',
        mask: '0*'
      },
      {
        id: 'crm_uf',
        label: 'UF do CRM',
        text: 'UF de registro profissional',
        oid: '2_16_76_1_4_2_2_2',
        mask: 'SS'
      },
      {
        id: 'especialidade',
        label: 'Especialidade',
        text: 'Especialidade',
        oid: '2_16_76_1_4_2_2_3',
        mask: 'A*'
      }
    ]
  }
};

export const DOCUMENTS_CATEGORIES = {
  1: {
    id: 1,
    oid: '2_16_76_1_12_1',
    text: 'Documentos Digitais da Saúde',
    documents: [
      {
        id: 1,
        oid: '2_16_76_1_12_1_1',
        text: 'Prescrição de Medicamento'
      },
      {
        id: 2,
        oid: '2_16_76_1_12_1_2',
        text: 'Atestado Médico'
      },
      {
        id: 3,
        oid: '2_16_76_1_12_1_3',
        text: 'Solicitação de Exame'
      },
      {
        id: 4,
        oid: '2_16_76_1_12_1_4',
        text: 'Laudo Laboratorial'
      },
      {
        id: 5,
        oid: '2_16_76_1_12_1_5',
        text: 'Sumária de Alta'
      },
      {
        id: 6,
        oid: '2_16_76_1_12_1_6',
        text: 'Registro de Atendimento Clínico'
      },
      {
        id: 7,
        oid: '2_16_76_1_12_1_7',
        text: 'Dispensação de Medicamento'
      },
      {
        id: 8,
        oid: '2_16_76_1_12_1_8',
        text: 'Vacinação'
      },
      {
        id: 9,
        oid: '2_16_76_1_12_1_11',
        text: 'Relatório Médico'
      }
    ]
  }
};
