// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  googleTagManager: 'GTM-PP5K364',
  production: true,
  env: 'development',
  apiURL: 'https://api-dev.assine.online',
  apiVersion: '',
  defaultLanguage: 'pt-BR',
  supportedLanguages: ['pt-BR', 'en-US', 'es'],
  featureFlags: {
    faceToFaceSignature: true
  },
  googleRecaptcha: {
    siteKeyV2: '6LeSICMiAAAAAHq59j80GZ-YJY2jeOpHOSnP6A7a',
    siteKeyV3: '6Ld8LiMiAAAAAG_141tPksRRAQtOJGkHCHxsseO8'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
