<div class="input-group mb-4">
  <div class="input-group-prepend">
    <div class="input-group-text" title="Pesquisar">
      <i class="fas fa-search"></i>
    </div>
  </div>
  <input
    type="text"
    class="form-control"
    placeholder="Pesquisar contato por nome"
    [formControl]="queryField"
    (keyup)="searchDebounce()"
  />
</div>

<div class="row contactPanel">
  <div class="spin" *ngIf="getTotalContactList()">
    <i class="fas fa-spinner fa-spin" *ngIf="isLoading"></i>
    <p *ngIf="emptyContacts">Não há contato cadastrado</p>
  </div>

  <div class="col-12 col-md-6 col-lg-4" *ngFor="let contact of contacts">
    <div class="box">
      <div class="box-content">
        <div class="row">
          <div class="col-9">
            <div class="title" [title]="contact.name">{{ contact.name }}</div>
          </div>
          <div class="col-3">
            <div *ngIf="isSelected(contact)" class="counter">
              <i class="fas fa-check-circle"></i>
            </div>
          </div>
        </div>
        <div class="email">{{ contact.email }}</div>

        <ng-container *ngIf="isEnabledWhatsapp() && contact.whatsappNumber">
          <div class="whatsapp">
            {{ contact.whatsappNumber | mask: '(00) 0 0000-0000' }}
          </div>
        </ng-container>

        <button
          *ngIf="!isSelected(contact)"
          class="btn btn-secondary btn-block"
          (click)="addToSelection(contact)"
        >
          Selecionar
        </button>
        <button
          *ngIf="isSelected(contact)"
          class=" btn btn-outline-danger btn-block"
          (click)="removeSelection(contact)"
        >
          Remover
        </button>
      </div>
    </div>
  </div>
</div>

<div class="text-center mt-5">
  <button class="btn btn-outline-danger" (click)="close()">Cancelar</button>
  <button
    class="btn btn-secondary"
    (click)="emitSelectedContacts()"
    [disabled]="!hasSelectedContacts()"
  >
    Adicionar {{ getTotalSelected() }} selecionados
  </button>
</div>
