import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from '@app/core/models';
import { ContactService, AuthorizationService } from '@app/core/services';
import { FormControl } from '@angular/forms';
import { Cancelable, debounce } from 'lodash';
import { environment } from '@env/environment';

@Component({
  selector: 'app-workflow-build-contact-selection',
  templateUrl: './contact-selection.component.html',
  styleUrls: ['./contact-selection.component.scss']
})
export class WorkflowBuildContactSelectionComponent implements OnInit {
  private isLoading: boolean;
  private emptyContacts: boolean;
  public contacts: Contact.Contact[] = [];
  private selectedContacts: Contact.Contact[] = [];

  private contactService: ContactService;
  private modalService: NgbModal;
  queryField = new FormControl();
  searchDebounce: (() => void) & Cancelable;

  @Output()
  public contactsSelected: EventEmitter<Contact.Contact[]>;

  constructor(
    contactService: ContactService,
    modalService: NgbModal,
    private authorizationService: AuthorizationService
  ) {
    this.isLoading = false;
    this.emptyContacts = false;
    this.contactService = contactService;
    this.modalService = modalService;
    this.contactsSelected = new EventEmitter<Contact.Contact[]>();
    this.searchDebounce = debounce(this.searchContacts.bind(this), 300);
  }

  public ngOnInit() {
    this.searchContacts();
  }

  public isEnabledWhatsapp(): boolean {
    if (this.authorizationService.getUser()) {
      return this.authorizationService.getUser().twilioBuEnabled;
    }
    return false;
  }

  public getTotalContactList(): boolean {
    return this.contacts.length === 0;
  }

  public getTotalSelected(): number {
    return this.selectedContacts.length;
  }

  public isSelected(contact: Contact.Contact): boolean {
    return !!this.selectedContacts.find(
      selectedContact => selectedContact === contact
    );
  }

  public hasSelectedContacts(): boolean {
    return this.getTotalSelected() > 0;
  }

  public addToSelection(contact: Contact.Contact): void {
    this.selectedContacts.push(contact);
  }

  public removeSelection(contact: Contact.Contact): void {
    if (this.selectedContacts.indexOf(contact) !== -1) {
      this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
    }
  }

  public emitSelectedContacts() {
    this.contactsSelected.emit(this.selectedContacts);
    this.close();
  }

  public close() {
    this.modalService.dismissAll();
    this.emptySelectedContacts();
  }

  private emptySelectedContacts() {
    this.selectedContacts = [];
  }

  private getAllContacts(queryString: object): void {
    this.isLoading = true;
    this.contactService.searchContacts(queryString).subscribe(contacts => {
      this.contacts = contacts;
      this.isLoading = false;
      this.emptyContacts = this.contacts.length === 0 ? true : false;
    });
  }

  public searchContacts(): void {
    const filter: Array<object> = [];

    if (this.queryField.value) {
      filter.push({
        type: 'innerjoin',
        field: 'userTwo',
        alias: 'u'
      });

      filter.push({
        type: 'like',
        alias: 'u',
        field: 'name',
        value: `%${this.queryField.value}%`
      });

      filter.push({
        type: 'eq',
        field: 'status',
        value: 1,
        direction: 'desc'
      });

      const queryStringFilter = {
        filter
      };
      this.getAllContacts(queryStringFilter);
    } else {
      filter.push({
        type: 'eq',
        field: 'status',
        value: 1,
        direction: 'desc'
      });

      const queryStringFilter = {
        filter,
        pageSize: '50000'
      };
      this.getAllContacts(queryStringFilter);
    }
  }
}
