<app-feedback
  [message]="feedbackMessage"
  [theme]="feedbackTheme"
></app-feedback>

<div class="page page-profile">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="profile-data">
          <app-profile-data
            [logged]="logged"
            [photo]="photo"
            [planInfo]="planInfo"
            (setFeedbackMessage)="updateFeedback($event)"
          ></app-profile-data>
        </div>

        <app-additional-user
          [selected]="logged"
          [planInfo]="planInfo"
          [modalChangePassword]="modalChangePassword"
          [modalCancelAccount]="modalCancelAccount"
          [modalVisibleSignature]="modalVisibleSignature"
          [modalAccessToken]="modalAccessToken"
          [modalIntegrationDocuware]="modalIntegrationDocuware"
          (modalAction)="open($event)"
        ></app-additional-user>

        <app-organization-logo
          *ngIf="
            planInfo !== undefined && !planInfo?.isDefault && checkAdminOrUser()
          "
          (setFeedbackMessage)="updateFeedback($event)"
        ></app-organization-logo>
      </div>

      <div class="col-12 col-md-8">
        <div class="profile-form">
          <app-personal-form
            [selected]="logged"
            [isLoading]="isLoading"
            (doActionSubmit)="doSubmitPersonalForm($event)"
          ></app-personal-form>

          <app-organization-form
            [selected]="logged"
            [isLoading]="isLoading"
            (doActionSubmit)="doSubmitBusinessForm($event)"
          ></app-organization-form>

          <div
            class="form-personal"
            *ngIf="
              userSignProfile.signatoryCategory &&
              signatoryProfileList &&
              signatureProfileForm &&
              false
            "
          >
            <form
              (ngSubmit)="doSubmitSignatureProfileForm()"
              [formGroup]="signatureProfileForm"
            >
              <div class="box">
                <div class="box-header"><h3>Perfil de assinatura</h3></div>
                <div class="box-content">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Escolha um perfil de assinatura</label>
                        <select
                          class="form-control form-control-sm"
                          formControlName="selectedProfile"
                          (ngModelChange)="_loadSignProfile($event)"
                        >
                          <option
                            [selected]="
                              userSignProfile.signatoryCategory.id == item.id
                            "
                            *ngFor="let item of signatoryProfileList"
                            [value]="item.id"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="col-12"
                      *ngFor="let item of signatoryProfileList"
                    >
                      <div class="row">
                        <div
                          *ngIf="
                            item.id ==
                            signatureProfileForm.controls.selectedProfile.value
                          "
                          class="col-12"
                        >
                          <div class="row">
                            <div
                              *ngFor="let field of item.signatoryData"
                              class="form-group col-12 col-md-6"
                            >
                              <label>{{ field.label }}</label>
                              <input
                                [placeholder]="field.text"
                                class="form-control"
                                [formControlName]="field.oid"
                                [mask]="field.mask"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right mt-4">
                      <button
                        class="btn btn-secondary btn-sm"
                        type="submit"
                        [disabled]="
                          signatureProfileForm.invalid ||
                          isLoadingProfile ||
                          !this.signatureProfileIsChanged
                        "
                      >
                        <i
                          class="fas fa-spinner fa-spin"
                          [hidden]="!isLoadingProfile"
                        ></i>
                        <span translate>Salvar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <app-payment-info
            *ngIf="planInfo !== undefined"
            [paymentInfo]="paymentInfo"
            [planInfo]="planInfo"
            [logged]="logged"
          >
          </app-payment-info>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalVisibleSignature let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'signature green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Crie sua assinatura'"
  >
    <app-visible-signature
      [modal]="modal"
      [data]="logged"
      [btnSaveText]="'Salvar'"
      [showInfoText]="false"
      [isLoading]="isLoadingModal"
      [typeOfSignature]="typeOfSignature"
    ></app-visible-signature>
  </app-modal-action>
</ng-template>

<ng-template #modalChangePassword let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Alterar Senha'"
  >
    <app-change-password-form
      [modal]="modal"
      [isLoading]="isLoadingModal"
    ></app-change-password-form>
  </app-modal-action>
</ng-template>

<ng-template #modalCancelAccount let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Cancelar Conta'"
  >
    <app-cancel-account-form
      [modal]="modal"
      [isLoading]="isLoadingModal"
    ></app-cancel-account-form>
  </app-modal-action>
</ng-template>

<ng-template #modalAccessToken let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Token de integração'"
  >
    <app-access-token-event [modal]="modal"> </app-access-token-event>
  </app-modal-action>
</ng-template>

<ng-template #modalIntegrationDocuware let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Integração DocuWare'"
  >
    <app-integration-docuware-form
      [modal]="modal"
      (setFeedbackMessage)="updateFeedback($event)"
    ></app-integration-docuware-form>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
