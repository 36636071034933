import { WORKFLOW_STEP_FIELDS, WORKFLOW_SIGNATURE } from '@app/core/services';
import { IDraggableField } from '../../../../core/models/draggable-field.model';

export const DRAGGABLE_FIELDS: IDraggableField[] = [
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
    title: 'Assinatura Eletrônica',
    description: 'Assinatura eletrônica selada pelo Assine Online',
    icon: 'fa-paint-brush',
    signatureType: WORKFLOW_SIGNATURE.ELETRONIC
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
    title: 'Assinatura com BirdID',
    description: 'Assinatura digital com certificado BirdID',
    icon: 'fa-shield-alt',
    signatureType: WORKFLOW_SIGNATURE.DIGITAL
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE,
    title: 'Assinatura Presencial',
    description: 'Assinatura presencial feita pelo aplicativo para Smartphones',
    icon: 'fa-file-signature',
    signatureType: WORKFLOW_SIGNATURE.IN_PERSON
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_STAMP,
    title: 'Assinatura com Carimbo',
    description: 'Assinatura por carimbo selada pelo Assine Online',
    icon: 'fa-stamp',
    signatureType: WORKFLOW_SIGNATURE.STAMP
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_RUBRIC,
    title: 'Rubrica',
    description: 'Rubrica é abreviação simples da assinatura',
    icon: 'fa-signature',
    signatureType: WORKFLOW_SIGNATURE.RUBRIC
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_NAME_TEXT,
    title: 'Nome',
    description: 'Nome do contato',
    icon: 'fa-user'
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_EMAIL_TEXT,
    title: 'E-mail',
    description: 'E-mail do contato',
    icon: 'fa-at'
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_PLAIN_TEXT,
    title: 'Texto',
    description: 'Mensagem em texto que o contato possa preencher',
    icon: 'fa-align-left'
  },
  {
    type: WORKFLOW_STEP_FIELDS.TYPE_DATE_SIGNATURE_TEXT,
    title: 'Data de Assinatura',
    description: 'Data em que o documento foi assinado',
    icon: 'fa-calendar-alt'
  },
  {
    type: WORKFLOW_STEP_FIELDS.QR_CODE_SHARE,
    title: 'QR Code',
    description: 'QR Code de validação de integridade do documento',
    icon: 'fa-qrcode'
  }
];
