import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})
export class MemberFormComponent {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of current user
   */
  @Input() selected?: any;

  /**
   * collection with data
   */
  @Input() collection?: any;

  /**
   * Data of Form
   */
  memberForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'member',
      formData: this.memberForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.memberForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.memberForm = this.formBuilder.group({
      member: ['', Validators.required]
    });
  }
}
