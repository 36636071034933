import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WorkflowReportService } from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var WorkflowReportComponent = /** @class */ (function () {
    function WorkflowReportComponent(formBuilder, workflowReportService, modalService) {
        this.formBuilder = formBuilder;
        this.workflowReportService = workflowReportService;
        this.modalService = modalService;
        this.report = [];
        this.createForm();
        this.modalRef = { ref: null, data: { text: '' } };
    }
    WorkflowReportComponent.prototype.ngOnInit = function () {
        this.sortFields = { name: 'ASC', email: 'ASC', statusText: 'ASC' };
        this.configList = [
            {
                name: 'ID',
                klass: '',
                type: 'text',
                fieldId: 'id',
                isSortable: false
            },
            {
                name: 'Nome do fluxo',
                type: 'text',
                fieldId: 'name',
                isSortable: false
            },
            {
                name: 'Status do fluxo',
                type: 'text',
                fieldId: 'status.text',
                isSortable: false
            },
            {
                name: 'Nome e status dos destinatários',
                type: 'text',
                fieldId: 'workflowSteps',
                isSortable: false,
                width: '30%'
            }
        ];
        this.sentOrReceivedFilter = [
            {
                value: '',
                label: 'Selecione'
            },
            {
                value: 0,
                label: 'Enviados'
            },
            {
                value: 1,
                label: 'Recebidos'
            }
        ];
        this.statusFilter = [
            {
                value: 0,
                label: 'Todos'
            },
            {
                value: 6,
                label: 'Assinado'
            },
            {
                value: 1,
                label: 'Aguardando assinatura'
            },
            {
                value: 2,
                label: 'Cancelado'
            },
            {
                value: 3,
                label: 'Expirado'
            },
            {
                value: 4,
                label: 'Rejeitado'
            }
        ];
        this.periodFilter = [
            {
                value: '',
                label: 'Período'
            },
            {
                value: 1,
                label: '1 Dia'
            },
            {
                value: 7,
                label: '1 Semana'
            },
            {
                value: 30,
                label: '30 Dias'
            },
            {
                value: 60,
                label: '60 Dias'
            }
        ];
    };
    WorkflowReportComponent.prototype.setTimeout = function () {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.ref = _this.open(_this.loading);
        }).bind(this));
    };
    WorkflowReportComponent.prototype.open = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    WorkflowReportComponent.prototype.closeModal = function () {
        if (this.modalRef.ref) {
            this.modalRef.ref.close();
        }
    };
    WorkflowReportComponent.prototype.doSubmit = function () {
        this.modalRef.data.text = 'carregando dados, aguarde...';
        this.setTimeout();
        this.queryForm.controls['download'].setValue('0');
        this.getWorkflowReport(this.mountPayload(this.queryForm.value));
    };
    WorkflowReportComponent.prototype.downloadFile = function () {
        this.modalRef.data.text = 'Gerando arquivo CSV, aguarde...';
        this.setTimeout();
        this.queryForm.controls['download'].setValue('1');
        this.getFileCsv(this.mountPayload(this.queryForm.value));
    };
    WorkflowReportComponent.prototype.getFileCsv = function (payload) {
        var _this = this;
        this.workflowReportService.getFileCsv(payload).subscribe(function (response) {
            _this.handleFile(response, 'Relatóro de fluxo.csv');
        }, function (error) {
            _this.closeModal();
        });
    };
    WorkflowReportComponent.prototype.getWorkflowReport = function (payload) {
        var _this = this;
        this.workflowReportService.getReport(payload).subscribe(function (response) {
            _this.report = response.report;
            _this.closeModal();
        }, function (error) {
            _this.closeModal();
        });
    };
    WorkflowReportComponent.prototype.handleFile = function (res, fileName) {
        var file = new Blob([res], { type: res.type });
        var blob = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = blob;
        link.download = fileName;
        // link.click();
        link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        }));
        // firefox
        setTimeout(function () {
            window.URL.revokeObjectURL(blob);
            link.remove();
        }, 100);
        this.closeModal();
    };
    WorkflowReportComponent.prototype.mountPayload = function (data) {
        var payload = {
            period: data.period,
            status: data.status,
            download: data.download,
            received: data.sentOrReceived
        };
        return payload;
    };
    Object.defineProperty(WorkflowReportComponent.prototype, "f", {
        get: function () {
            return this.queryForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    WorkflowReportComponent.prototype.createForm = function () {
        this.queryForm = this.formBuilder.group({
            sentOrReceived: ['', Validators.required],
            status: ['0'],
            period: ['', Validators.required],
            download: ['0']
        });
    };
    return WorkflowReportComponent;
}());
export { WorkflowReportComponent };
