import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, of, from } from 'rxjs';
import {
  AuthorizationService,
  WorkflowBuildService,
  FeedbackService,
  IFeedback,
  UserService
} from '@app/core/services';

export const PAGE_STEPS = {
  STEP_ERROR: -1,
  STEP_BUILD: 0,
  STEP_REVIEW: 1,
  STEP_FINISH: 2
};

@Component({
  selector: 'app-workflow-page',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowPageComponent implements OnInit, OnDestroy {
  public STEP_ERROR: number = PAGE_STEPS.STEP_ERROR;
  public STEP_BUILD: number = 1;
  public STEP_REVIEW: number = 2;
  public STEP_FINISH: number = 3;

  public buildURL: string = '/manager/workflow';
  public reviewURL: string = '/manager/workflow/review';
  public finishURL: string = '/manager/workflow/finish';

  /**
   * Feedback message
   */
  public feedback: IFeedback = { message: null, theme: null };

  private feedbackSubscription: Subscription;

  constructor(
    protected authorizationService: AuthorizationService,
    protected workflowBuildService: WorkflowBuildService,
    protected feedbackService: FeedbackService,
    protected router: Router,
    protected userService: UserService
  ) {}

  public ngOnInit(): void {
    this.myUser();
    this.feedbackSubscription = this.feedbackService
      .observeFeedback()
      .subscribe(feedback => {
        this.feedback = feedback;
      });
    this.fetchDefaultFolder();
  }

  public ngOnDestroy(): void {
    if (!!this.feedbackSubscription) {
      this.feedbackSubscription.unsubscribe();
    }

    this.workflowBuildService.emptyFiles();
  }

  public hasWorkflowfiles(): boolean {
    return this.workflowBuildService.hasFiles();
  }

  public stepClass(step: number): { current: boolean; done: boolean } {
    const isOnBuild = this.router.isActive(this.buildURL, false);
    const isOnReview = this.router.isActive(this.reviewURL, false);
    const isOnFinish = this.router.isActive(this.finishURL, false);

    let curStep: number = 0;

    if (isOnFinish) {
      curStep = 4;
    } else if (isOnReview) {
      curStep = 2;
    } else if (isOnBuild) {
      curStep = 1;
    }

    return { current: curStep === step, done: curStep > step };
  }

  protected fetchDefaultFolder(): void {
    const subs = from(this.workflowBuildService.fetchDefaultFolder()).subscribe(
      defaultFolderId => {
        this.workflowBuildService.setDefaultFolderId(defaultFolderId.id);
        this.workflowBuildService.setDefaultFolderName(defaultFolderId.name);
        subs.unsubscribe();
      }
    );
  }

  private async myUser() {
    await this.userService.me().toPromise();
  }
}
