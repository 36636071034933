import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Layout } from '@app/core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
  /**
   * Identifies whether widgets can be edited
   */
  @Input() canEdit: boolean = false;

  /**
   * show invisible items
   */
  @Input() showHidden: boolean = false;

  /**
   * Wrap container of counters
   */
  @Input() nowrap: boolean = false;

  /**
   * List of available counters
   */
  @Input() available: Array<Layout.ManagerWidgetStatistics>;

  /**
   * List of unavailable counters
   */
  @Input() unavailable?: Array<Layout.ManagerWidgetStatistics>;

  /**
   * List of all counters
   */
  @Input() allItems?: Array<Layout.ManagerWidgetStatistics>;

  /**
   * Callback of rearrange available counters
   */
  @Output() rearrangeAvailable? = new EventEmitter();

  /**
   * Callback of update available counters
   */
  @Output() currentAvailable? = new EventEmitter();

  /**
   * New counter selected
   */
  nextItem: Layout.ManagerWidgetStatistics;

  /**
   * Current counter selected
   */
  selectedItem: Layout.ManagerWidgetStatistics;

  /**
   * constructor of class
   */
  constructor(private modalService: NgbModal, private router: Router) {}

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @param (Layout.ManagerWidgetStatistics) item of counter list
   * @return void
   */
  open(content: any, item: Layout.ManagerWidgetStatistics) {
    this.selectedItem = item;

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-title' })
      .result.then(result => {
        if (result === 'save') {
          this.currentAvailable.emit({
            current: this.selectedItem,
            next: this.nextItem
          });
        }
      });
  }

  filter(value: any) {
    this.router.navigate([value.path], {
      queryParams: {
        filter: value.status
      }
    });
  }

  /**
   * provides the necessary management in drop action
   *
   * @param (any) event object with data action
   * @return void
   */
  drop(event: any) {
    moveItemInArray(this.available, event.previousIndex, event.currentIndex);
    const items = this.available.concat(this.unavailable);
    this.rearrangeAvailable.emit(items);
  }

  hasDocumentTotal(item: any) {
    return item.value >= 0;
  }
}
