import { WorkflowContact } from '@app/core/class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
export class RubricSignatureField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_RUBRIC;
    this.minHeight = this.height = 40;
    this.minWidth = this.width = 140;
  }
}
