<div class="box">
  <div class="box-content">
    <div class="profile-data">
      <div class="profile-personal-data">
        <div class="avatar">
          <img [src]="photo?.dataValue" *ngIf="photo; else defaultPhoto" />
          <input
            type="file"
            id="uploadFile"
            style="display:none"
            (change)="fileChangeEvent($event)"
          />
          <div class="mask" (click)="openFileSelector(modalCropImage)">
            <i class="fa fa-plus-circle"></i>
            <div class="background"></div>
          </div>

          <ng-template #defaultPhoto>
            <div class="empty-avatar">
              <i class="icon far fa-user-circle"></i>
            </div>
          </ng-template>
        </div>

        <ng-template #modalCropImage let-modal>
          <app-modal-action
            [modal]="modal"
            [typeModal]="'simple green'"
            [showHeader]="true"
            [showFooter]="false"
            [showClose]="true"
            [title]="'Recortar imagem'"
          >
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1"
              [resizeToWidth]="128"
              [roundCropper]="true"
              format="png"
              (imageCropped)="imageCropped($event)"
            ></image-cropper>

            <div class="text-center mt-3">
              <button class="btn btn-secondary" (click)="uploadFile()">
                Enviar
              </button>
            </div>
          </app-modal-action>
        </ng-template>

        <h3>{{ planUser.nameUser }}</h3>
        <p>{{ planUser.profile }}</p>

        <button class="btn btn-sm btn-outline-info">
          {{ planUser.planName }}
        </button>
      </div>

      <div class="profile-plan-data">
        <p>
          <strong
            >Consumo do Plano: {{ planUser.planConsumption }} de
            {{ planUser.usageGlobal }}</strong
          >
          <span> {{ planUser.usagePercentage }}% </span>
        </p>
        <ngb-progressbar
          class="bar"
          [type]="planUser.processBarColor"
          [value]="planUser.usagePercentage"
          height=".6rem"
        ></ngb-progressbar>
      </div>

      <div class="profile-plan-alert">
        <p>
          <strong>{{ planUser.shortDescription }}</strong>
        </p>
      </div>

      <div class="profile-plan-upgrade">
        <button class="btn btn-secondary" routerLink="/manager/plans">
          Alterar meu plano
        </button>
      </div>
    </div>
  </div>
</div>
