import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { SIGNATURE_TYPE } from '@app/core/services';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-draw-signature',
  templateUrl: './draw-signature.component.html',
  styleUrls: ['./draw-signature.component.scss']
})
export class DrawSignatureComponent implements AfterViewInit {
  /**
   * Signature Pad components
   */
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  /**
   * Signature type object
   */
  @Output() dataSignDrawEvent = new EventEmitter();

  public dataSignDraw: Object = {
    type: SIGNATURE_TYPE.DRAWING,
    sign: ''
  };

  public signaturePadOptions: Object = {
    backgroundColor: 'rgba(255,255,255,0.1)'
  };

  constructor(private elementRef: ElementRef) {
    // no-op
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateSize();
  }

  private updateSize() {
    const width = this.elementRef.nativeElement.parentElement.offsetWidth;
    const height = 150;
    this.size(width > 721 ? 721 : width, height);
  }
  public size(width: Number, height: Number) {
    this.signaturePad.set('canvasWidth', width);
    this.signaturePad.set('canvasHeight', height);
    this.signaturePad.set('minWidth', 0.2);
    this.signaturePad.set('maxWidth', 1.0);
    this.signaturePad.set('throttle', 1);
    this.signaturePad.set('velocityFilterWeight', 0.9);
    this.signaturePad.set('backgroundColor', 'rgba(255,255,255,0.1)');
  }

  ngAfterViewInit() {
    this.updateSize();
    this.signaturePad.clear();
  }

  drawComplete() {
    this.dataSignDraw['sign'] = this.signaturePad.toDataURL();
    this.dataSignDrawEvent.emit({ dataSignature: this.dataSignDraw });
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }
}
