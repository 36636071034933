<form [formGroup]="metadataForm" (submit)="onSubmit()">
  <div class="modal-metadata__content">
    <div class="form-group">
      <input
        autocomplete="off"
        name="name"
        placeholder="Nome *"
        class="form-control"
        formControlName="name"
        required=""
      />
    </div>
    <div class="form-group">
      <input
        autocomplete="off"
        name="cpf"
        placeholder="CPF *"
        class="form-control"
        formControlName="document"
        [ngClass]="{
          'is-invalid':
            metadataForm.controls.document.errors &&
            !metadataForm.controls.document.pristine
        }"
        mask="000.000.000-00"
        required=""
      />
    </div>
    <div class="form-group">
      <input
        autocomplete="off"
        name="data-nascimento"
        placeholder="Data de nascimento *"
        class="form-control"
        formControlName="dataNascimento"
        [ngClass]="{
          'is-invalid':
            metadataForm.controls.dataNascimento.errors &&
            !metadataForm.controls.dataNascimento.pristine
        }"
        mask="00/00/0000"
        required=""
      />
    </div>
    <div class="form-group">
      <input
        type="email"
        autocomplete="off"
        name="email"
        placeholder="E-mail"
        class="form-control"
        formControlName="email"
        [ngClass]="{
          'is-invalid':
            metadataForm.controls.email.errors &&
            !metadataForm.controls.email.pristine
        }"
      />
    </div>
  </div>
  <div class="modal-metadata__footer mt-4">
    <button type="button" class="btn btn-outline-danger" (click)="onCancel()">
      Cancelar
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      [disabled]="metadataForm.invalid"
    >
      Salvar
    </button>
  </div>
</form>
