import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PhotoSubscription {
  private subject = new Subject<string>();

  update(data: string) {
    this.subject.next(data);
  }

  get(): Observable<any> {
    return this.subject.asObservable();
  }
}
