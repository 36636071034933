import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models';

@Injectable()
export class WorkflowStepUserUpdateService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/workflow-step-user-update';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  updateWorkflowUser(
    idWorkflowStep: string,
    data: Contact.ContactStep
  ): Observable<any> {
    return super.update(idWorkflowStep, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
