import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { parseDate, formatDate } from '@app/core/helpers/date';
import { Document } from '@app/core/models/document.model';
import { Workflow } from '@app/core/models/workflow.model';
import {
  WORKFLOW_SIGNATURE,
  WORKFLOW_STEP_TEXT_STATUS,
  WORKFLOW_TEXT_STATUS
} from '@app/core/services/workflow.enum';
import { HttpClient } from '@angular/common/http';

/**
 * Provides a base for document workflow.
 */
export const DOCUMENT_STATUS = {
  1: { text: 'Aguardando minha assinatura', action: 'sign' },
  2: { text: 'Aguardando assinatura', action: 'move' },
  3: { text: 'Assinado', action: 'archive' },
  4: { text: 'Rejeitado por 2 assinantes', action: 'resend' }
};

export const DOCUMENT_ACTION = {
  archive: 'Arquivar',
  move: 'Mover',
  resend: 'Enviar novamente',
  sign: 'Assinar'
};

export const DOCUMENT_ICON = {
  archive: 'check',
  move: 'clock',
  resend: 'delete',
  sign: ''
};

/**
 * Provides a base for workflow/document.
 */
@Injectable()
export class DocumentService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/workflow';
    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Gets all data of a service
   */
  getAll(queryString?: any): Observable<any> {
    this.endpoint = '/workflow';
    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this._parseData(response);
      })
    );
  }

  /**
   * Gets all data of a service
   */
  getById(id: any, payload?: any): Observable<any> {
    return super.getById(id, payload).pipe(
      map((response: any) => {
        const data = this._parseWorkflow(response);
        data._raw = response;
        return data;
      })
    );
  }

  getWorkflowStepById(id: any, payload?: any): Observable<any> {
    this.endpoint = '/workflow-step';
    return super.getById(id, payload).pipe(
      map((response: any) => {
        return this._parseWorkflow(response);
      })
    );
  }

  /**
   * Gets all data with arguments
   */
  countByStatusAndPeriod(status: number, period?: string): Observable<any> {
    const queryString: Document.Filter = {
      filter: [
        {
          type: 'eq',
          field: 'status',
          alias: status
        },
        {
          type: 'innerjoin',
          field: 'workflow',
          alias: 'wk'
        }
      ],
      'order-by': [
        {
          type: 'field',
          field: 'id',
          direction: 'desc'
        }
      ]
    };

    if (period) {
      queryString['filter'].push({
        type: 'gte',
        field: 'dateStatus',
        alias: 'wk',
        value: period
      });
    }

    return super.getAll(queryString).pipe(
      map((response: any) => {
        return response.total_items;
      })
    );
  }

  /**
   * Sends the document data to the service
   *
   * @param (Workflow.Raw) data of document
   */
  // create(workflow: Workflow.Raw): Observable<any> {
  //   const dueDate = workflow.metadata.dueDate
  //     ? formatDate(workflow.metadata.dueDate, 'YYYY-MM-DD HH:mm:ss')
  //     : '';

  //   const payload = {
  //     autoRemind: parseInt(workflow.metadata.remember),
  //     dueDate,
  //     name: workflow.metadata.name,
  //     message: workflow.metadata.message,
  //     priority: parseInt(workflow.metadata.priority),
  //     sla: 1,
  //     files: this._createFileJSON(workflow)
  //   };

  //   return super.create(payload).pipe(
  //     map((response: any) => {
  //       return {
  //         id: response.id
  //       };
  //     })
  //   );
  // }

  create(workflow: Workflow.Settings): Observable<any> {
    this.endpoint = '/workflow';
    return super.create(workflow).pipe(
      map((response: any) => {
        return {
          id: response.id
        };
      })
    );
  }

  /**
   * count data by status
   *
   * @param (number) status of workflow
   * @return (object) collection with recipients and fields
   */
  countByStatus(status: number, isStep?: boolean): Observable<any> {
    const filter = {
      pageSize: 1
    };
    if (status > -1) {
      filter['filter'] = [
        {
          type: 'eq',
          field: 'status',
          value: status
        }
      ];
    }
    this.endpoint = '/workflow';
    if (isStep) {
      this.endpoint = '/workflow-step';
      filter['hideExtrametadata'] = 1;
    }

    return super.getAll(filter).pipe(
      map((response: any) => {
        return response.total_items;
      })
    );
  }

  public verify(payload: any) {
    return this.httpClient
      .disableAuthorization()
      .post('/document', payload, {
        params: {
          q: 'verify'
        }
      })
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  /**
   * Returns data of file
   *
   * @param (Workflow.Raw) data of document
   * @return (object) collection with recipients and fields
   */
  private _createFileJSON(workflow: Workflow.Raw): any {
    const files: any = [];
    workflow.files.forEach((file: any) => {
      files.push({
        idFolder: parseInt(file.idFolder),
        dueDate: file.dueDate
          ? formatDate(file.dueDate, 'YYYY-MM-DD HH:mm:ss')
          : '',
        idFile: parseInt(file.response.id),
        name: file.name,
        specialFields: this._createFieldsJSON(file.tools, ['special_field']),
        workflowSteps: this._createRecipientJSON(workflow, file.tools)
      });
    });

    return files;
  }

  /**
   * Returns all recipients in the document
   *
   * @param (Workflow.Raw) data of document
   * @return (object) collection with recipients and fields
   */
  private _createRecipientJSON(workflow: Workflow.Raw, tools: any): any {
    const recipients = workflow.recipients.map(
      (recipient: any, index: number) => {
        const ret = {
          idUser: parseInt(recipient.id),
          action: parseInt(recipient.type),
          extraMetadata: recipient.extraMetadata,
          signatureType: this.getSignatureType(recipient),
          fields: this._createFieldsJSONFromRecipient(
            tools,
            recipient,
            ['field', 'text', 'checkbox', 'dropdown'],
            index + 1
          )
        };
        if (ret.fields) {
          return ret;
        }
      }
    );
    return recipients;
  }

  private _createFieldsJSONFromRecipient(
    tools: any,
    recipient: any,
    type: Array<String>,
    recipientIndex: number
  ): Array<Document.FieldWorkflowPayload> {
    const fields: any[] = [];
    tools.forEach((toolsPage: any, index: number) => {
      toolsPage.forEach((tool: any) => {
        if (
          type.includes(tool.type) &&
          tool.data.id === recipient.id &&
          recipientIndex === tool.index
        ) {
          fields.push({
            type: tool.idType,
            x: tool.apiPosition.pixel.x,
            y: tool.apiPosition.pixel.y,
            height: tool.size.apiPixel.height,
            width: tool.size.apiPixel.width,
            page: index + 1,
            name: tool.name,
            value: tool.value
          });
        }
      });
    });
    return fields;
  }

  /**
   * Returns all fields in the document
   *
   * @param (any) fields collection
   * @return (FieldWorkflowPayload) collection with fields
   */
  private _createFieldsJSON(
    pages: any,
    type: Array<String>
  ): Array<Document.FieldWorkflowPayload> {
    const tools = [];

    if (pages.length > 0) {
      for (const page in pages) {
        for (const field of pages[page]) {
          if (type.includes(field.type)) {
            tools.push({
              type: field.idType,
              x: field.position.x,
              y: field.position.y,
              height: field.height,
              width: field.width,
              page: parseInt(page) + 1
            });
          }
        }
      }
    }

    return tools;
  }

  private getSignatureType(recipient: any) {
    let signatureType = null;
    switch (recipient.category) {
      case 'sign_digital':
        signatureType = WORKFLOW_SIGNATURE.DIGITAL;
        break;
      case 'sign_cert':
        signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
        break;
      case 'local_sign':
        signatureType = WORKFLOW_SIGNATURE.IN_PERSON;
        break;
    }
    return signatureType;
  }

  /**
   * Parse of service data and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseData(data: any): any {
    const items = data._embedded.workflow.map((item: any) => {
      return {
        id: item.id,
        priority: item.priority,
        autoRemind: item.autoRemind,
        sla: item.sla,
        name: item.name,
        message: item.message,
        status: {
          id: item.status,
          text: WORKFLOW_TEXT_STATUS[item.status]
        },
        date: {
          dueDate: parseDate(item.dueDate),
          dateCreated: parseDate(item.dateCreated),
          dateStatus: parseDate(item.dateStatus),
          dateFinished: parseDate(item.dateFinished)
        },
        user: item.user,
        workflowSteps: item.workflowSteps || []
      };
    });

    return {
      items: items,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items,
      _raw: data._embedded,
      _links: data._links
    };
  }

  private _parseSubscriber(items: any): Document.SubscriberPayload {
    return items.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        icon: DOCUMENT_ICON[item.action],
        sentDate: item.sentDate,
        status: DOCUMENT_STATUS[item.status]
      };
    });
  }

  /**
   * Parse of workflow data and adjusts to
   * default for printing the component
   *
   * @param (any) items data of service
   */
  private _parseWorkflow(data: any): any {
    const documents = data._embedded.documents.map((item: any) => {
      const recipients = this._parseSubscribers(item.workflowSteps);

      const workflow = {
        id: item.id,
        name: item.name,
        status: item.status,
        folder: item._embedded.folder,
        dueDate: item.dueDate,
        file: {
          id: item._embedded.originalFile.id,
          checksum: item._embedded.originalFile.checksum,
          link: item._embedded.originalFile._links.download.href
        },
        steps: {
          recipients: recipients,
          total: item.workflowSteps.length
        }
      };

      if (item._embedded.file) {
        workflow['signedFile'] = {
          id: item._embedded.file.id,
          checksum: item._embedded.file.checksum,
          link: item._embedded.file._links.download.href
        };
      }

      if (item.docId) {
        const signaturePageId = () => {
          const data = item.docId.split('checker?q=');
          return data[1];
        };
        workflow['signaturePageId'] = signaturePageId();

        const signaturePageLink = () => {
          const apiLink = workflow.file.link.split('/file?q=')[0];
          return (
            apiLink +
            '/document?q=download&docId=' +
            workflow['signaturePageId']
          );
        };
        workflow['signaturePageLink'] = signaturePageLink();
      }

      return workflow;
    });

    data.reason = '';
    if (data.status === 5) {
      documents.forEach((document: any) => {
        const rejectedStep = document.steps.recipients.filter(
          (item: any) => item.status.action === 'reject'
        );
        if (rejectedStep.length > 0) {
          data.reason = rejectedStep[0].reason;
        }
      });
    }

    return {
      detail: data.detail || '',
      workflow: {
        id: data.id,
        sla: data.sla,
        status: data.status,
        reason: data.reason,
        priority: data.priority,
        name: data.name,
        message: data.message,
        autoRemind: data.autoRemind,
        autoInitiate: data.autoInitiate,
        dueDate: data.dueDate,
        date: {
          dueDate: parseDate(data.dueDate)
        }
      },
      documents: documents
    };
  }

  /**
   * Parse of subscriber data and adjusts to
   * default for printing the component
   *
   * @param (any) subscribers collection
   */
  private _parseSubscribers(subscribers: any) {
    return subscribers.map((item: any) => {
      return {
        id: item._embedded.user.id,
        stepId: item.id,
        name: item._embedded.user.name,
        email: item._embedded.user.email,
        status: WORKFLOW_STEP_TEXT_STATUS[item.status],
        reason: item.reason,
        statusCode: item.status,
        signatureType: item.signatureType,
        action: item.action,
        extraMetadata: item.extraMetadata,
        fields: item.fields
      };
    });
  }
}
