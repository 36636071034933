import { Component, Input, OnInit } from '@angular/core';
import { BusinessUnitMetadata } from '@app/core/models/business-unit-metadata.model';
import { AuthorizationService } from '@app/core/services';
import { BusinessMetadataService } from '@app/core/services/business-unit-metadata.service';
@Component({
  selector: 'app-layout-simple-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class LayoutSimpleHeaderComponent implements OnInit {
  /**
   * determines whether or not to print the infobar element
   */
  @Input() hideInfoBar: boolean;

  /**
   * determines whether or not to print the auth buttons
   */
  @Input() showAuth: boolean;

  public logo: string;

  constructor(
    private authorizationService: AuthorizationService,
    public businessMetadataService: BusinessMetadataService
  ) {}

  ngOnInit() {
    this.getOrganizationBrand();
  }

  goToManager() {
    window.location.assign('/manager');
  }

  isAuthenticated(): boolean {
    return this.authorizationService.isAuthenticated();
  }

  getOrganizationBrand(): void {
    this.businessMetadataService.output.subscribe(
      (response: BusinessUnitMetadata.OrganizationBrand) => {
        this.logo = response.dataValue;
      }
    );
  }
}
