import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rename-folder-form',
  templateUrl: './rename-folder-form.component.html',
  styleUrls: ['./rename-folder-form.component.scss']
})
export class RenameFolderFormComponent implements OnInit {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  folderName: string = '';

  constructor() {}

  ngOnInit() {
    this.isLoading = false;
  }

  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'renameFolder',
      formData: {
        folderName: this.folderName
      }
    });
  }
}
