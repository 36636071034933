/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./share-folder-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/components/contacts-add/contacts-add.component.ngfactory";
import * as i5 from "../../../../shared/components/contacts-add/contacts-add.component";
import * as i6 from "../../../../core/services/contact.service";
import * as i7 from "./share-folder-form.component";
import * as i8 from "../../../../core/services/folder-visibility.service";
import * as i9 from "../../../../core/services/authorization.service";
var styles_ShareFolderFormComponent = [i0.styles];
var RenderType_ShareFolderFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShareFolderFormComponent, data: {} });
export { RenderType_ShareFolderFormComponent as RenderType_ShareFolderFormComponent };
function View_ShareFolderFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organiza\u00E7\u00E3o"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "label", [["class", "switch"], ["style", "vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.organizationShare = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.updateOrganizationShare($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(12, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "span", [["class", "slider round"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["style", "vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.organizationShare; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 14).ngClassValid; var currVal_5 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.businessUnit.name; _ck(_v, 20, 0, currVal_8); }); }
function View_ShareFolderFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "form-share-folder form-modal p-0 mt-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareFolderFormComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contatos"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-contacts-add", [], null, [[null, "addContact"], [null, "removeContact"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addContact" === en)) {
        var pd_0 = (_co.addContact($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeContact" === en)) {
        var pd_1 = (_co.removeContact($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ContactsAddComponent_0, i4.RenderType_ContactsAddComponent)), i1.ɵdid(11, 114688, null, 0, i5.ContactsAddComponent, [i6.ContactService, i2.FormBuilder], { selectedContacts: [0, "selectedContacts"], blackList: [1, "blackList"] }, { addContact: "addContact", removeContact: "removeContact" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "col-12 text-center mt-3 mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Fechar\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.businessUnit; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.contacts; var currVal_2 = _co.contactsBlackList; _ck(_v, 11, 0, currVal_1, currVal_2); }, null); }
function View_ShareFolderFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"], ["style", "font-size: .9rem"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  Carregando..\n"]))], null, null); }
export function View_ShareFolderFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareFolderFormComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareFolderFormComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ShareFolderFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-share-folder-form", [], null, null, null, View_ShareFolderFormComponent_0, RenderType_ShareFolderFormComponent)), i1.ɵdid(1, 114688, null, 0, i7.ShareFolderFormComponent, [i8.FolderVisibilityService, i9.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShareFolderFormComponentNgFactory = i1.ɵccf("app-share-folder-form", i7.ShareFolderFormComponent, View_ShareFolderFormComponent_Host_0, { isLoading: "isLoading", modal: "modal", selected: "selected" }, {}, []);
export { ShareFolderFormComponentNgFactory as ShareFolderFormComponentNgFactory };
