import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { parseDate, formatDate } from '@app/core/helpers/date';
import { WORKFLOW_SIGNATURE, WORKFLOW_STEP_TEXT_STATUS, WORKFLOW_TEXT_STATUS } from '@app/core/services/workflow.enum';
import { HttpClient } from '@angular/common/http';
/**
 * Provides a base for document workflow.
 */
export var DOCUMENT_STATUS = {
    1: { text: 'Aguardando minha assinatura', action: 'sign' },
    2: { text: 'Aguardando assinatura', action: 'move' },
    3: { text: 'Assinado', action: 'archive' },
    4: { text: 'Rejeitado por 2 assinantes', action: 'resend' }
};
export var DOCUMENT_ACTION = {
    archive: 'Arquivar',
    move: 'Mover',
    resend: 'Enviar novamente',
    sign: 'Assinar'
};
export var DOCUMENT_ICON = {
    archive: 'check',
    move: 'clock',
    resend: 'delete',
    sign: ''
};
/**
 * Provides a base for workflow/document.
 */
var DocumentService = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentService, _super);
    function DocumentService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/workflow';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Gets all data of a service
     */
    DocumentService.prototype.getAll = function (queryString) {
        var _this = this;
        this.endpoint = '/workflow';
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this._parseData(response);
        }));
    };
    /**
     * Gets all data of a service
     */
    DocumentService.prototype.getById = function (id, payload) {
        var _this = this;
        return _super.prototype.getById.call(this, id, payload).pipe(map(function (response) {
            var data = _this._parseWorkflow(response);
            data._raw = response;
            return data;
        }));
    };
    DocumentService.prototype.getWorkflowStepById = function (id, payload) {
        var _this = this;
        this.endpoint = '/workflow-step';
        return _super.prototype.getById.call(this, id, payload).pipe(map(function (response) {
            return _this._parseWorkflow(response);
        }));
    };
    /**
     * Gets all data with arguments
     */
    DocumentService.prototype.countByStatusAndPeriod = function (status, period) {
        var queryString = {
            filter: [
                {
                    type: 'eq',
                    field: 'status',
                    alias: status
                },
                {
                    type: 'innerjoin',
                    field: 'workflow',
                    alias: 'wk'
                }
            ],
            'order-by': [
                {
                    type: 'field',
                    field: 'id',
                    direction: 'desc'
                }
            ]
        };
        if (period) {
            queryString['filter'].push({
                type: 'gte',
                field: 'dateStatus',
                alias: 'wk',
                value: period
            });
        }
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return response.total_items;
        }));
    };
    /**
     * Sends the document data to the service
     *
     * @param (Workflow.Raw) data of document
     */
    // create(workflow: Workflow.Raw): Observable<any> {
    //   const dueDate = workflow.metadata.dueDate
    //     ? formatDate(workflow.metadata.dueDate, 'YYYY-MM-DD HH:mm:ss')
    //     : '';
    //   const payload = {
    //     autoRemind: parseInt(workflow.metadata.remember),
    //     dueDate,
    //     name: workflow.metadata.name,
    //     message: workflow.metadata.message,
    //     priority: parseInt(workflow.metadata.priority),
    //     sla: 1,
    //     files: this._createFileJSON(workflow)
    //   };
    //   return super.create(payload).pipe(
    //     map((response: any) => {
    //       return {
    //         id: response.id
    //       };
    //     })
    //   );
    // }
    DocumentService.prototype.create = function (workflow) {
        this.endpoint = '/workflow';
        return _super.prototype.create.call(this, workflow).pipe(map(function (response) {
            return {
                id: response.id
            };
        }));
    };
    /**
     * count data by status
     *
     * @param (number) status of workflow
     * @return (object) collection with recipients and fields
     */
    DocumentService.prototype.countByStatus = function (status, isStep) {
        var filter = {
            pageSize: 1
        };
        if (status > -1) {
            filter['filter'] = [
                {
                    type: 'eq',
                    field: 'status',
                    value: status
                }
            ];
        }
        this.endpoint = '/workflow';
        if (isStep) {
            this.endpoint = '/workflow-step';
            filter['hideExtrametadata'] = 1;
        }
        return _super.prototype.getAll.call(this, filter).pipe(map(function (response) {
            return response.total_items;
        }));
    };
    DocumentService.prototype.verify = function (payload) {
        var _this = this;
        return this.httpClient
            .disableAuthorization()
            .post('/document', payload, {
            params: {
                q: 'verify'
            }
        })
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    /**
     * Returns data of file
     *
     * @param (Workflow.Raw) data of document
     * @return (object) collection with recipients and fields
     */
    DocumentService.prototype._createFileJSON = function (workflow) {
        var _this = this;
        var files = [];
        workflow.files.forEach(function (file) {
            files.push({
                idFolder: parseInt(file.idFolder),
                dueDate: file.dueDate
                    ? formatDate(file.dueDate, 'YYYY-MM-DD HH:mm:ss')
                    : '',
                idFile: parseInt(file.response.id),
                name: file.name,
                specialFields: _this._createFieldsJSON(file.tools, ['special_field']),
                workflowSteps: _this._createRecipientJSON(workflow, file.tools)
            });
        });
        return files;
    };
    /**
     * Returns all recipients in the document
     *
     * @param (Workflow.Raw) data of document
     * @return (object) collection with recipients and fields
     */
    DocumentService.prototype._createRecipientJSON = function (workflow, tools) {
        var _this = this;
        var recipients = workflow.recipients.map(function (recipient, index) {
            var ret = {
                idUser: parseInt(recipient.id),
                action: parseInt(recipient.type),
                extraMetadata: recipient.extraMetadata,
                signatureType: _this.getSignatureType(recipient),
                fields: _this._createFieldsJSONFromRecipient(tools, recipient, ['field', 'text', 'checkbox', 'dropdown'], index + 1)
            };
            if (ret.fields) {
                return ret;
            }
        });
        return recipients;
    };
    DocumentService.prototype._createFieldsJSONFromRecipient = function (tools, recipient, type, recipientIndex) {
        var fields = [];
        tools.forEach(function (toolsPage, index) {
            toolsPage.forEach(function (tool) {
                if (type.includes(tool.type) &&
                    tool.data.id === recipient.id &&
                    recipientIndex === tool.index) {
                    fields.push({
                        type: tool.idType,
                        x: tool.apiPosition.pixel.x,
                        y: tool.apiPosition.pixel.y,
                        height: tool.size.apiPixel.height,
                        width: tool.size.apiPixel.width,
                        page: index + 1,
                        name: tool.name,
                        value: tool.value
                    });
                }
            });
        });
        return fields;
    };
    /**
     * Returns all fields in the document
     *
     * @param (any) fields collection
     * @return (FieldWorkflowPayload) collection with fields
     */
    DocumentService.prototype._createFieldsJSON = function (pages, type) {
        var tools = [];
        if (pages.length > 0) {
            for (var page in pages) {
                for (var _i = 0, _a = pages[page]; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (type.includes(field.type)) {
                        tools.push({
                            type: field.idType,
                            x: field.position.x,
                            y: field.position.y,
                            height: field.height,
                            width: field.width,
                            page: parseInt(page) + 1
                        });
                    }
                }
            }
        }
        return tools;
    };
    DocumentService.prototype.getSignatureType = function (recipient) {
        var signatureType = null;
        switch (recipient.category) {
            case 'sign_digital':
                signatureType = WORKFLOW_SIGNATURE.DIGITAL;
                break;
            case 'sign_cert':
                signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
                break;
            case 'local_sign':
                signatureType = WORKFLOW_SIGNATURE.IN_PERSON;
                break;
        }
        return signatureType;
    };
    /**
     * Parse of service data and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    DocumentService.prototype._parseData = function (data) {
        var items = data._embedded.workflow.map(function (item) {
            return {
                id: item.id,
                priority: item.priority,
                autoRemind: item.autoRemind,
                sla: item.sla,
                name: item.name,
                message: item.message,
                status: {
                    id: item.status,
                    text: WORKFLOW_TEXT_STATUS[item.status]
                },
                date: {
                    dueDate: parseDate(item.dueDate),
                    dateCreated: parseDate(item.dateCreated),
                    dateStatus: parseDate(item.dateStatus),
                    dateFinished: parseDate(item.dateFinished)
                },
                user: item.user,
                workflowSteps: item.workflowSteps || []
            };
        });
        return {
            items: items,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items,
            _raw: data._embedded,
            _links: data._links
        };
    };
    DocumentService.prototype._parseSubscriber = function (items) {
        return items.map(function (item) {
            return {
                id: item.id,
                name: item.name,
                email: item.email,
                icon: DOCUMENT_ICON[item.action],
                sentDate: item.sentDate,
                status: DOCUMENT_STATUS[item.status]
            };
        });
    };
    /**
     * Parse of workflow data and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    DocumentService.prototype._parseWorkflow = function (data) {
        var _this = this;
        var documents = data._embedded.documents.map(function (item) {
            var recipients = _this._parseSubscribers(item.workflowSteps);
            var workflow = {
                id: item.id,
                name: item.name,
                status: item.status,
                folder: item._embedded.folder,
                dueDate: item.dueDate,
                file: {
                    id: item._embedded.originalFile.id,
                    checksum: item._embedded.originalFile.checksum,
                    link: item._embedded.originalFile._links.download.href
                },
                steps: {
                    recipients: recipients,
                    total: item.workflowSteps.length
                }
            };
            if (item._embedded.file) {
                workflow['signedFile'] = {
                    id: item._embedded.file.id,
                    checksum: item._embedded.file.checksum,
                    link: item._embedded.file._links.download.href
                };
            }
            if (item.docId) {
                var signaturePageId = function () {
                    var data = item.docId.split('checker?q=');
                    return data[1];
                };
                workflow['signaturePageId'] = signaturePageId();
                var signaturePageLink = function () {
                    var apiLink = workflow.file.link.split('/file?q=')[0];
                    return (apiLink +
                        '/document?q=download&docId=' +
                        workflow['signaturePageId']);
                };
                workflow['signaturePageLink'] = signaturePageLink();
            }
            return workflow;
        });
        data.reason = '';
        if (data.status === 5) {
            documents.forEach(function (document) {
                var rejectedStep = document.steps.recipients.filter(function (item) { return item.status.action === 'reject'; });
                if (rejectedStep.length > 0) {
                    data.reason = rejectedStep[0].reason;
                }
            });
        }
        return {
            detail: data.detail || '',
            workflow: {
                id: data.id,
                sla: data.sla,
                status: data.status,
                reason: data.reason,
                priority: data.priority,
                name: data.name,
                message: data.message,
                autoRemind: data.autoRemind,
                autoInitiate: data.autoInitiate,
                dueDate: data.dueDate,
                date: {
                    dueDate: parseDate(data.dueDate)
                }
            },
            documents: documents
        };
    };
    /**
     * Parse of subscriber data and adjusts to
     * default for printing the component
     *
     * @param (any) subscribers collection
     */
    DocumentService.prototype._parseSubscribers = function (subscribers) {
        return subscribers.map(function (item) {
            return {
                id: item._embedded.user.id,
                stepId: item.id,
                name: item._embedded.user.name,
                email: item._embedded.user.email,
                status: WORKFLOW_STEP_TEXT_STATUS[item.status],
                reason: item.reason,
                statusCode: item.status,
                signatureType: item.signatureType,
                action: item.action,
                extraMetadata: item.extraMetadata,
                fields: item.fields
            };
        });
    };
    return DocumentService;
}(BaseAPI));
export { DocumentService };
