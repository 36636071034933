import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@app/shared';
import { SubscribeModule } from '@app/subscribe';
import { CheckerModule } from '@app/checker';
import { AuthModule } from '@app/auth';
import { LandingModule } from '@app/landing';
import { ManagerModule } from '@app/manager';
import { IntegrationModule } from '@app/integration';
import { CoreModule } from './core/core.module';
import { ErrorLogHandler } from './core/class';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@env/environment';

registerLocaleData(localePT, 'pt');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgbModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    CoreModule,
    SharedModule,
    LandingModule,
    SubscribeModule,
    CheckerModule,
    AuthModule,
    ManagerModule,
    IntegrationModule,
    AppRoutingModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorLogHandler },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleRecaptcha.siteKeyV2
      } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
