import * as tslib_1 from "tslib";
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableEmailFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableEmailFieldComponent, _super);
    function WorkflowDroppableEmailFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowDroppableEmailFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableEmailFieldComponent };
