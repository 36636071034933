<div class="documents-list">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-search"></i></div>
            </div>
            <input
              [(ngModel)]="searchField"
              (keyup)="searchDebounce()"
              type="text"
              class="form-control"
              id="inlineFormInputGroupUsername"
              placeholder="Pesquise aqui"
            />
          </div>
        </div>
        <!-- <div class="col-12 col-md-4" style="text-align: right;">Filtros:</div> -->
        <div class="col-12 col-md-6 filters">
          <span class="filter-label"> Filtros: </span>

          <select
            [(ngModel)]="searchPeriod"
            (change)="search()"
            class="form-control"
          >
            <option disabled>Período</option>
            <!-- Valores equivalentes a dias -->
            <option value="">Todos</option>
            <option value="1">1 dia</option>
            <option value="7">1 semana</option>
            <option value="30">1 mês</option>
          </select>
        </div>
      </div>
      <div class="document-table">
        <app-lists
          [collection]="collectionDocument.items"
          [fields]="configList"
          [pagination]="pagination"
          [sortableFields]="sortFields"
          [enableDetails]="true"
          [loadingDetails]="loadingDetails"
          [dataDetails]="details"
          (selected)="getSelectedItems($event)"
          (choosed)="getClickedItem($event)"
          (activity)="doAction($event)"
          (sort)="doSort($event)"
          (doChangePage)="onChangePage($event)"
        ></app-lists>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalRenameTemplate let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Renomear Modelo'"
  >
    <form (ngSubmit)="submitRenameWorkflow()" [formGroup]="renameWorkflowForm">
      <div class="form-group">
        <input
          #nameInput
          name="name"
          placeholder="Nome"
          class="form-control"
          type="text"
          formControlName="name"
          [ngClass]="{
            'is-invalid': formControlValidation(
              renameWorkflowForm.controls.name
            )
          }"
        />
      </div>

      <div class="mt-3 text-right">
        <button
          class="btn btn-secondary btn-sm"
          type="submit"
          [disabled]="renameWorkflowForm.invalid"
        >
          Salvar
        </button>
      </div>
    </form>
  </app-modal-action>
</ng-template>

<ng-template #modalDeleteTemplate let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Remover Modelo'"
  >
    <form (ngSubmit)="submitRemoveTemplate()" novalidate>
      <div class="row">
        <div class="col-12 text-center mt-5 mb-3">
          <p>Deseja realmente excluir este modelo?</p>
          <button
            type="button"
            class="btn btn-default"
            (click)="modal.close('cancel')"
          >
            Cancelar
          </button>
          <button class="btn btn-danger btn-sm" type="submit">Excluir</button>
        </div>
      </div>
    </form>
  </app-modal-action>
</ng-template>
