import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Department } from 'app/core/models';
import { DepartmentService } from 'app/core/services';
import { finalize } from 'rxjs/operators';
import { Logger } from '@app/core/class';

const log = new Logger('DepartmentsPage');

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsPageComponent implements OnInit {
  /**
   * loading of form
   */
  isLoading: boolean = false;

  /**
   * collection with data
   */
  collection: Array<Department.DepartmentPayload>;

  /**
   * current department for edit/delete
   */

  selectedDepartment: any;

  constructor(
    private departmentService: DepartmentService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // this.getDepartments();
  }

  /**
   * provides the data of departments
   *
   * @return void
   */
  // getDepartments() {
  //   try {
  //     // this.isLoading = true;
  //     this.departmentService
  //       .getAll()
  //       .pipe(
  //         finalize(() => {
  //           // this.isLoading = false;
  //         })
  //       )
  //       .subscribe(
  //         (response: Array<Department.DepartmentPayload>) => {
  //           log.debug(`Success [getDepartments]: ${response}`);
  //           this.collection = response;
  //         },
  //         (error: any) => {
  //           log.debug(`Error [getDepartments]: ${error}`);
  //           this.collection = [];
  //         }
  //       );
  //   } catch (e) {
  //     // this.isLoading = false;
  //     this.collection = [];
  //     log.debug(`Error [getDepartments]: ${e}`);
  //   }
  // }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any) {
    //   this.modalService
    //     .open(content, {
    //       ariaLabelledBy: 'modal-title',
    //       keyboard: false,
    //       backdrop: 'static'
    //     })
    //     .result.then(result => {
    //       if (result.action === 'save') {
    //         if (result.form === 'user') {
    //           this.doSubmitForm(result.formData);
    //           return;
    //         }
    //       }
    //       if (result === 'cancel') {
    //         return;
    //       }
    //     });
  }

  /**
   * Action by clicking in submit department form
   *
   * @param (any) form with data
   */
  // doSubmitForm(form: any) {
  //   console.log(form);
  // }

  /**
   * Action grab data
   *
   * @param (any) event to process an action
   */
  doAction(event: any) {
    //   console.log(event.type);
    //   if (event.type === 'edit') {
    //     console.log(event.obj);
    //   }
    //   if (event.type === 'remove') {
    //     console.log(event.obj);
    //   }
  }
}
