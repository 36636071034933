import { Authentication } from '@app/core/models/authentication.model';
import { User } from '@app/core/models/user.model';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { LocaldataService } from './localdata.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientOptions } from '../class/HttpClientOptions.interface';
import {
  SIGNATORY_CATEGORIES,
  DOCUMENTS_CATEGORIES
} from '@app/core/enums/SignatoryCategories';

@Injectable()
export class UserService extends BaseAPI {
  private tempNewUserData: any;

  private userPreferences: User.UserPreferences = null;

  constructor(
    protected httpClient: HttpClient,
    protected authorizationService: AuthorizationService,
    protected localdataService: LocaldataService
  ) {
    super(httpClient);
    this.endpoint = '/user';

    this.apiFieldsMap = {};

    this.fieldsRequired = ['username', 'email', 'password'];
  }

  setTempNewUserData(data: any) {
    this.tempNewUserData = data;
  }

  getTempNewUserData() {
    return this.tempNewUserData;
  }

  me(): Observable<User.UserPayload> {
    const url = `${
      this.endpoint
    }?filter[0][field]=id&filter[0][type]=eq&filter[0][value]=$USER_ID`;
    return this.httpClient.get(url).pipe(
      map((body: any) => {
        const logged = body._embedded.user[0];
        const data: User.UserPayload = {
          id: logged.id,
          name: logged.name,
          initial: this._getInitials(logged.name),
          username: logged.email,
          email: logged.email,
          cellphone: logged.cellphone,
          whatsappNumber: logged.whatsappNumber,
          notifyWhatsapp: logged.notifyWhatsapp,
          twilioBuEnabled: logged.twilioBuEnabled,
          document: logged.document,
          roleDefault: logged.roleDefault
        };

        if (logged._embedded !== undefined) {
          data.businessUnit = {
            id: logged._embedded.businessUnit.id,
            name: logged._embedded.businessUnit.name,
            status: logged._embedded.businessUnit.status,
            document: logged._embedded.businessUnit.document,
            country: logged._embedded.businessUnit.country,
            state: logged._embedded.businessUnit.state,
            city: logged._embedded.businessUnit.city,
            address: logged._embedded.businessUnit.address,
            zipCode: logged._embedded.businessUnit.zipCode
          };
        }
        data['signatoryCategory'] = 1;
        if (this.authorizationService.isGuest()) {
          this.authorizationService.setTempGuestUser(
            data as Authentication.SignupUserResponse
          );
        } else {
          this.setUser(data);
        }

        return data;
      })
    );
  }

  updateUser(id: string, payload: User.UserPayload) {
    return this.httpClient.patch(this.endpoint + `/${id}`, payload).pipe(
      map((body: any) => {
        const dataUser: User.UserPayload = {
          id: body.id,
          name: body.name,
          initial: this._getInitials(body.name),
          username: body.email,
          email: body.email,
          cellphone: body.cellphone,
          document: body.document,
          roleDefault: body.roleDefault,
          businessUnit: {
            id: body._embedded.businessUnit.id,
            name: body._embedded.businessUnit.name,
            status: body._embedded.businessUnit.status,
            document: body._embedded.businessUnit.document,
            country: body._embedded.businessUnit.country,
            state: body._embedded.businessUnit.state,
            city: body._embedded.businessUnit.city,
            address: body._embedded.businessUnit.address,
            zipCode: body._embedded.businessUnit.zipCode
          }
        };

        return dataUser;
      })
    );
  }

  getUserPreferences(): Observable<User.UserPreferences> {
    if (this.userPreferences) {
      return of(this.userPreferences);
    }

    return this.getAllMetadata().pipe(
      map((body: Array<User.UserMetadata>) => {
        const metadata = body.find(
          (e: User.UserMetadata) => e.dataKey === 'userPreferences'
        );
        if (metadata) {
          this.userPreferences = Object.assign(
            {
              metadataId: metadata.id
            },
            JSON.parse(metadata.dataValue)
          );
          return this.userPreferences;
        }
      })
    );
  }

  saveUserPreferences(
    payload: User.UserPreferences
  ): Observable<User.UserPreferences> {
    const dataKey = 'userPreferences';

    let observer: Observable<User.UserMetadata>;

    if (this.userPreferences) {
      const userPreferences = Object.create(this.userPreferences);
      Object.assign(userPreferences, payload);
      const metadataId = userPreferences.metadataId;
      delete userPreferences.metadataId;
      observer = this.updateMetadata(metadataId, {
        dataValue: JSON.stringify(userPreferences),
        dataKey
      });
    } else {
      observer = this.createMetadata({
        dataKey,
        dataValue: JSON.stringify(payload)
      });
    }

    return observer.pipe(
      map((body: User.UserMetadata) => {
        this.userPreferences = JSON.parse(body.dataValue);
        this.userPreferences.metadataId = body.id;
        return this.userPreferences;
      })
    );
  }

  getAllMetadata(): Observable<User.UserMetadata[]> {
    return this.httpClient.get('/user-metadata').pipe(
      map((body: any) => {
        const collection: Array<User.UserMetadata> = [];

        body._embedded.user_metadata.forEach((e: any) => {
          collection.push({
            id: e.id,
            dataKey: e.dataKey,
            dataValue: e.dataValue
          });
        });

        return collection;
      })
    );
  }

  removeBusinessUnitFromuser(userId: any) {
    return this.httpClient
      .patch(`${this.endpoint}/${userId}?getOutBusinessUnit=1`, {})
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  confirmRegister(payload: User.ConfirmPayload): Observable<any> {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=confirm-register', payload);
  }

  createMetadata(payload: User.UserMetadata): Observable<User.UserMetadata> {
    return this.httpClient.post('/user-metadata', payload).pipe(
      map((body: any) => {
        const data: User.UserMetadata = {
          id: body.id,
          dataKey: body.dataKey,
          dataValue: body.dataValue
        };
        return data;
      })
    );
  }

  updateMetadata(id: number, payload: User.UserMetadata) {
    return this.httpClient.patch(`/user-metadata/${id}`, payload).pipe(
      map((body: any) => {
        const data: User.UserMetadata = {
          id: body.id,
          dataKey: body.dataKey,
          dataValue: body.dataValue
        };

        return data;
      })
    );
  }

  getPlanInfo(): Observable<any> {
    return this.httpClient.get('/subscription').pipe(
      map((body: any) => {
        if (!body._embedded.subscription[0]) {
          return false;
        }

        const data: User.UserPlanPayload = {
          id: body._embedded.subscription[0]._embedded.planSubscription.id,
          showName: body._embedded.subscription[0]._embedded.user.name,
          planType:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.name,
          signaturesRemainments:
            body._embedded.subscription[0].usageRemainments,
          signaturesPerUser:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.usagePerUser,

          planDescription: this.formatText(
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.description
          ),

          idPlan:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.id,
          planShortDescription:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.shortDescription,
          price: this.parsePrice(
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.price
          ),
          cents: this.parseCents(
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.price
          ),
          isDefault:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.isDefault,

          usage: this.usagePercentage(
            body._embedded.subscription[0]._embedded.planSubscription
              .usageRemainments,
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.usagePerUser
          ),
          globalSignaturesRemainments:
            body._embedded.subscription[0]._embedded.planSubscription
              .usageRemainments,
          globalSignaturesUsage:
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.usageGlobal,
          globalUsage: this.usagePercentage(
            body._embedded.subscription[0]._embedded.planSubscription
              .usageRemainments,
            body._embedded.subscription[0]._embedded.planSubscription._embedded
              .plan.usageGlobal
          )
        };
        return data;
      })
    );
  }

  getPaymentInfo(): Observable<User.UserPaymentInfo> {
    return this.httpClient.get('/plan-subscription').pipe(
      map((body: any) => {
        if (body.total_items > 0) {
          if (body._embedded.plan_subscription[0].metadata) {
            const metadata = JSON.parse(
              body._embedded.plan_subscription[0].metadata
            );

            const data: User.UserPaymentInfo = {
              id: body._embedded.plan_subscription[0].id,
              address: metadata.card.billing_address.line_1,
              zip_code: metadata.card.billing_address.zip_code,
              city: metadata.card.billing_address.city,
              state: metadata.card.billing_address.state,
              country: metadata.card.billing_address.country,
              cardNumber: metadata.card.last_four_digits,
              holder: metadata.card.holder_name,
              brand: metadata.card.brand,
              expMonth: metadata.card.exp_month,
              expYear: metadata.card.exp_year,
              renewDate: this.parseRenewDate(metadata.current_cycle.end_at),
              price: this.parsePrice(metadata.minimum_price.toString()),
              cents: this.parseCents(metadata.minimum_price.toString())
            };
            return data;
          }
        }
      })
    );
  }

  getUserPlanInfor(): Observable<User.UserPlanInfor> {
    return this.httpClient.get('/plan-subscription').pipe(
      map((body: any) => {
        if (body.total_items > 0) {
          if (body._embedded.plan_subscription[0]._embedded) {
            const data: User.UserPlanInfor = {
              id: body._embedded.plan_subscription[0]._embedded.plan.id,
              name: body._embedded.plan_subscription[0]._embedded.plan.name,
              maxUsers:
                body._embedded.plan_subscription[0]._embedded.plan.maxUsers,
              paymentMethod:
                body._embedded.plan_subscription[0]._embedded.plan
                  .paymentMethod,
              type: body._embedded.plan_subscription[0]._embedded.plan.type,
              usageGlobal:
                body._embedded.plan_subscription[0]._embedded.plan.usageGlobal
            };
            return data;
          }
        }
      })
    );
  }

  setLinkedUser(payload: any): Observable<any> {
    return this.httpClient.post('/user', payload);
  }

  disableUser(id: any): Observable<any> {
    return this.httpClient.patch(`/user/${id}`, {
      status: 0
    });
  }

  getLinkedUsers(
    queryString?: any
  ): Observable<User.BusinessUnitUserCollection> {
    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this.parseBusinessUnitUser(response);
      })
    );
  }

  parseBusinessUnitUser(data: any): User.BusinessUnitUserCollection {
    let users: User.BusinessUnitUser[] = [];

    if (data.total_items > 0) {
      users = data._embedded.user.map((user: any) => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          roleDefault: user.roleDefault
        };
      });
    }

    return {
      users: users,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items
    };
  }

  parseRenewDate(renewDate: any) {
    const data = new Date(renewDate);
    return data.toLocaleDateString();
  }

  usagePercentage(remain: any, total: any) {
    const result = ((total - remain) / total) * 100;
    return parseFloat(result.toFixed(2));
  }

  public formatText(text: string): object {
    const re = /\s*;\s*/;
    return text.split(re);
  }

  private parsePrice(price: any): string {
    return price.slice(0, -2);
  }

  private parseCents(price: any): string {
    return price.slice(-2);
  }

  /**
   * Create new row in database
   * @param payload with data object
   */
  create(payload: object, options?: HttpClientOptions): Observable<any> {
    if (!this._checkHasField(payload)) {
      throw new Error(`Field is missing. Check all fields in your request.`);
    }

    return this.httpClient
      .disableAuthorization()
      .post(this.endpoint, this.mapFields(payload), options)
      .pipe(
        map((body: any) => {
          return this.mapFields(body, true);
        })
      );
  }

  /**
   * Sets the user data.
   * The user data is persisted after the access token is generated.
   * @param data The user datas.
   */
  private setUser(data: any) {
    this.authorizationService.setUser(data);
  }

  private _getInitials(name: string) {
    const slices = name.split(' ');
    const size = slices.length;
    let initials = slices[0].charAt(0);

    if (size > 1) {
      initials += slices[size - 1].charAt(0);
    }

    return initials;
  }

  getUser() {
    return this.authorizationService.getUser();
  }
  getUserLocalData(key: string) {
    return this.localdataService.getItem(key);
  }
  setUserLocalData(key: string, data: any) {
    return this.localdataService.setItem(key, data);
  }
  removeUserLocalData() {
    return this.localdataService.clearLocal();
  }

  async setUserSignProfileId(profileId: any) {
    const userSignProfile = await this.getUserLocalData('userSignProfile');
    userSignProfile.signatoryCategory = {
      id: profileId
    };
    return await this.setUserLocalData('userSignProfile', userSignProfile);
  }

  async getUserSignProfile() {
    const userSignProfile = await this.getUserLocalData('userSignProfile');
    if (userSignProfile.signatoryCategory) {
      const userCategory =
        SIGNATORY_CATEGORIES[userSignProfile.signatoryCategory.id];
      if (userCategory) {
        userSignProfile.signatoryCategory = {
          id: userSignProfile.signatoryCategory.id,
          category: userCategory,
          documentsCategory:
            DOCUMENTS_CATEGORIES[userCategory.documentsCategory]
        };
      }
    }
    return userSignProfile;
  }

  async setUserSignProfileFieldsData(profileId: number, fieldsData: any) {
    const userSignProfile = await this.getUserLocalData('userSignProfile');
    if (!userSignProfile.fields) {
      userSignProfile.fields = {};
    }
    userSignProfile.fields[profileId] = Object.assign({}, fieldsData);
    return await this.setUserLocalData('userSignProfile', userSignProfile);
  }

  async getUserSignProfileFieldsData(profileId: number = 0) {
    const userSignProfile = await this.getUserLocalData('userSignProfile');
    if (!userSignProfile) {
      return userSignProfile;
    }
    if (!userSignProfile.fields) {
      return {};
    }
    if (profileId) {
      return userSignProfile.fields[profileId] || {};
    }
    return userSignProfile.fields;
  }
}
