import { OnInit, Input } from '@angular/core';
import { WorkflowContact } from '@app/core/class';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export class WorkflowBaseDroppedField implements OnInit {
  @Input() public field: IWorkflowDroppedField;

  public contact: WorkflowContact;

  public ngOnInit() {
    this.contact = this.field.getContact();
  }
}
