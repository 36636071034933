/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../droppable-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./eletronic-signature/eletronic-signature.component.ngfactory";
import * as i3 from "./eletronic-signature/eletronic-signature.component";
import * as i4 from "./digital-signature/digital-signature.component.ngfactory";
import * as i5 from "./digital-signature/digital-signature.component";
import * as i6 from "./in-person-signature/in-person-signature.component.ngfactory";
import * as i7 from "./in-person-signature/in-person-signature.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../../../../../core/services/workflow-build.service";
import * as i10 from "@angular/common";
import * as i11 from "./visible-signature.component";
var styles_WorkflowDroppableVisibleSignatureFieldComponent = [i0.styles];
var RenderType_WorkflowDroppableVisibleSignatureFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDroppableVisibleSignatureFieldComponent, data: {} });
export { RenderType_WorkflowDroppableVisibleSignatureFieldComponent as RenderType_WorkflowDroppableVisibleSignatureFieldComponent };
function View_WorkflowDroppableVisibleSignatureFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-workflow-eletronic-signature-field", [], null, null, null, i2.View_WorkflowEletronicSignatureFieldComponent_0, i2.RenderType_WorkflowEletronicSignatureFieldComponent)), i1.ɵdid(2, 49152, null, 0, i3.WorkflowEletronicSignatureFieldComponent, [], { contact: [0, "contact"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WorkflowDroppableVisibleSignatureFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-workflow-digital-signature-field", [], null, null, null, i4.View_WorkflowDigitalSignatureFieldComponent_0, i4.RenderType_WorkflowDigitalSignatureFieldComponent)), i1.ɵdid(2, 49152, null, 0, i5.WorkflowDigitalSignatureFieldComponent, [], { contact: [0, "contact"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WorkflowDroppableVisibleSignatureFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-workflow-in-person-signature-field", [], null, null, null, i6.View_WorkflowInPersonSignatureFieldComponent_0, i6.RenderType_WorkflowInPersonSignatureFieldComponent)), i1.ɵdid(2, 114688, null, 0, i7.WorkflowInPersonSignatureFieldComponent, [i8.NgbModal, i9.WorkflowBuildService], { field: [0, "field"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WorkflowDroppableVisibleSignatureFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "field-block field-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowDroppableVisibleSignatureFieldComponent_1)), i1.ɵdid(6, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowDroppableVisibleSignatureFieldComponent_2)), i1.ɵdid(9, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkflowDroppableVisibleSignatureFieldComponent_3)), i1.ɵdid(12, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.getSignatureType(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.WORKFLOW_SIGNATURE.ELETRONIC; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.WORKFLOW_SIGNATURE.DIGITAL; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.WORKFLOW_SIGNATURE.IN_PERSON; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_WorkflowDroppableVisibleSignatureFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-visible-signature-field", [], null, null, null, View_WorkflowDroppableVisibleSignatureFieldComponent_0, RenderType_WorkflowDroppableVisibleSignatureFieldComponent)), i1.ɵdid(1, 114688, null, 0, i11.WorkflowDroppableVisibleSignatureFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowDroppableVisibleSignatureFieldComponentNgFactory = i1.ɵccf("app-workflow-droppable-visible-signature-field", i11.WorkflowDroppableVisibleSignatureFieldComponent, View_WorkflowDroppableVisibleSignatureFieldComponent_Host_0, { field: "field" }, {}, []);
export { WorkflowDroppableVisibleSignatureFieldComponentNgFactory as WorkflowDroppableVisibleSignatureFieldComponentNgFactory };
