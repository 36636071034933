import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { DocumentService } from '@app/core/services';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checker',
  templateUrl: './checker.component.html',
  styleUrls: ['./checker.component.scss']
})
export class CheckerComponent implements OnInit {
  @ViewChild('modalLoading') loading: ElementRef;
  modalRef: any;

  error: string;
  checkerForm: FormGroup;
  isLoading = false;
  hash: any;
  verifyResponse: any;
  isError: boolean = false;
  filename: any;
  errorMsg: any;
  errorFinishingValidation: string = '';

  constructor(
    private modalService: NgbModal,
    private activeroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private documentService: DocumentService
  ) {
    this.modalRef = {
      ref: null,
      data: { text: '', showClose: false, title: '' }
    };

    this.createForm();
  }

  ngOnInit() {
    const query = this.activeroute.snapshot.queryParamMap.get('q');

    if (!query) {
      return;
    }

    if (query.includes('-')) {
      this.verify({ docId: query });
      return;
    }

    this.verify({ token: query });
  }

  newFile() {
    this.filename = '';
  }

  async generateFileHash(event: any): Promise<any> {
    const reader = new FileReader();
    const file = event.target.files[0];
    this.filename = file.name;
    reader.readAsArrayBuffer(file);

    return new Promise(resolve => {
      reader.onload = () => {
        const fileResult = reader.result;
        const fileWordArray = CryptoJS.lib.WordArray.create(fileResult);
        this.hash = CryptoJS.SHA1(fileWordArray);
        resolve(null);
      };
    });
  }

  doSubmit() {}

  sendVerification() {
    let verifyJSON = {};
    this.isError = false;

    const verifyCode = this.checkerForm.value.codeField;

    if (this.hash) {
      verifyJSON = {
        docId: verifyCode.toLocaleUpperCase(),
        hash: this.hash.toString()
      };
    } else {
      verifyJSON = {
        docId: verifyCode.toLocaleUpperCase()
      };
    }

    this.verify(verifyJSON);
  }

  downloadFile(url: any) {
    window.open(url);
  }

  verify(data: any) {
    this.modalRef.data.title = '';
    this.modalRef.data.text = 'Realizando validação...';
    this.modalRef.ref = this.open(this.loading);

    this.documentService.verify(data).subscribe(
      body => {
        const signers: Array<any> = [];
        const steps: Array<any> = body.steps;
        steps.forEach((element: any) => {
          const signer = {
            name: element.user.name,
            email: element.user.email,
            status: this.parseStatus(element.status),
            type:
              element.signatureType === 0
                ? 'Assinatura eletrônica'
                : 'Assinatura com BirdID'
          };
          signers.push(signer);
        });
        this.verifyResponse = {
          filename: body.name,
          docId: body.docId,
          date: this.parseDate(body.dateReceived),
          signers: signers,
          integrity:
            body.integrity === 'original' || body.integrity === 'latest'
              ? 'Confirmada'
              : 'Não confirmada',
          original: body.originalFile
            ? body.originalFile._links.download.href
            : '',
          signed: body.file ? body.file._links.download.href : ''
        };

        if (this.modalRef.ref) {
          this.modalRef.ref.close();
        }
      },
      error => {
        this.isError = true;

        this.errorFinishingValidation = this.errorMsg = error.error.detail;

        if (this.modalRef.ref) {
          this.modalRef.ref.close();
        }
      }
    );
  }

  parseStatus(status: any) {
    let parsedStatus;
    switch (status) {
      case 0:
        parsedStatus = 'Na fila';
        break;
      case 1:
        parsedStatus = 'Aguardando assinatura';
        break;
      case 2:
        parsedStatus = 'Aguardando processo iniciar';
        break;
      case 3:
        parsedStatus = 'Processando assinatura';
        break;
      case 4:
        parsedStatus = 'Assinado';
        break;
      case 5:
        parsedStatus = 'Rejeitado';
        break;
      default:
        parsedStatus = 'Status não reconhecido';
        break;
    }

    return parsedStatus;
  }

  parseDate(data: any) {
    return moment(data.date)
      .utc(data.timezone_type)
      .format('YYYY-MM-DD HH:mm');
  }

  async finishValidation(event: any): Promise<void> {
    this.errorFinishingValidation = '';

    this.checkerForm.setValue({
      codeField: this.verifyResponse.docId
    });

    await this.generateFileHash(event);

    this.sendVerification();
  }

  private createForm() {
    this.checkerForm = this.formBuilder.group({
      codeField: ['', Validators.required]
    });
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  private open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return modalRef;
  }
}
