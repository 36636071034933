<div class="document-settings-wrapper">
  <div class="box">
    <div class="box-content">
      <aside class="side-bar">
        <div class="side-bar__block" *ngIf="canAddSigners()">
          <div class="side-bar__block__content">
            <div class="document-contacts">
              <button
                type="button"
                class="btn btn-secondary btn-add"
                *ngIf="!showContactForm"
                (click)="toggleContactForm()"
              >
                <i class="fa fa-plus"></i> Adicionar Destinatário
              </button>

              <button
                type="button"
                class="btn-action btn-cancel"
                *ngIf="showContactForm"
                (click)="toggleContactForm()"
              >
                <i class="fa fa-times"></i> Cancelar
              </button>

              <div class="contact-form-wrapper" *ngIf="showContactForm">
                <app-workflow-build-contact-form
                  (contactAdded)="onContactAdded()"
                >
                </app-workflow-build-contact-form>
              </div>
            </div>
          </div>
        </div>
        <div class="side-bar__block" *ngIf="hasContacts()">
          <div class="side-bar__block__header">
            <div class="row">
              <div class="col-3 pr-1" style="max-width: 55px;">
                <div class="icon"><i class="fa fa-user-edit"></i></div>
              </div>
              <div class="col-9 pl-0">
                <h4 class="text">Assinantes</h4>
                <span class="total-contacts">{{ totalContacts() }}</span>
              </div>
            </div>
          </div>
          <div class="side-bar__block__content">
            <div class="document-contacts-list">
              <div
                class="contact-item-wrapper"
                *ngFor="let contact of contacts; index as i"
                [ngClass]="{ current: isContactSelected(contact) }"
              >
                <a
                  (click)="removeContact(contact)"
                  class="remove"
                  title="Remover Assinante"
                >
                  <i class="fa fa-times-circle"></i>
                </a>
                <div
                  class="contact-item"
                  (click)="toggleSelectedContact(contact)"
                >
                  <span class="number">{{ i + 1 }}° Assinante</span>
                  <strong class="name">{{ contact.name }}</strong>
                  {{ contact.email }}

                  <ng-container
                    *ngIf="
                      (isEnabledWhatsapp() || isEnabledWhatsappIntegration()) &&
                      contact.notifyWhatsapp
                    "
                  >
                    <p class="mb-1">
                      {{
                        replaceDDI(contact.whatsappNumber)
                          | mask: '(00) 0 0000-0000'
                      }}
                    </p>
                  </ng-container>

                  <p *ngIf="contact.hasPhotoSign" title="Assinatura com foto">
                    <i class="far fa-file-image"></i> Assinatura com foto
                  </p>

                  <div class="flex space-between mt-4">
                    <ng-container
                      *ngIf="
                        (isEnabledWhatsapp() ||
                          isEnabledWhatsappIntegration()) &&
                        !contact.whatsappNumber
                      "
                    >
                      <a
                        href="#"
                        class="mb-2"
                        (click)="openContactEditForm(contact, $event)"
                        >Adicionar WhatsApp</a
                      >
                    </ng-container>

                    <a
                      href="#"
                      class="mb-2"
                      (click)="openContactEditForm(contact, $event)"
                      >Editar</a
                    >
                  </div>

                  <div class="flex">
                    <app-toggle-switch
                      [name]="'Exigir foto do assinante'"
                      [data]="contact"
                      (user)="toggleSwitchPhoto($event)"
                    ></app-toggle-switch>
                  </div>
                </div>
                <div
                  class="contact-tools"
                  *ngIf="isContactSelected(contact) && contact.isSigner()"
                >
                  <app-workflow-draggable-field-list
                    [contact]="contact"
                  ></app-workflow-draggable-field-list>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="side-bar__block" style="display: none;">
          <div class="side-bar__block__header">
            <div class="icon"><i class="fa fa-file-alt"></i></div>
            <h4 class="text">Documento</h4>
          </div>
          <div class="side-bar__block__content">
            <app-document-global-tools
              [stage]="stage"
              (doInsertTool)="onInsertTool($event)"
            ></app-document-global-tools>
          </div>
        </div> -->
      </aside>
    </div>
  </div>
</div>

<ng-template #modalEditContact let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Editar Assinante'"
  >
    <form (ngSubmit)="submitEditContact()" [formGroup]="contactEditForm">
      <div class="form-group">
        <input
          #nameInput
          name="name"
          placeholder="Nome"
          class="form-control"
          type="text"
          formControlName="name"
          [ngClass]="{
            'is-invalid': formControlValidation(contactEditForm.controls.name)
          }"
        />
      </div>

      <div class="form-group">
        <input
          name="email"
          placeholder="Email"
          class="form-control"
          type="email"
          formControlName="email"
          [ngClass]="{
            'is-invalid': formControlValidation(contactEditForm.controls.email)
          }"
        />
      </div>

      <ng-container
        *ngIf="isEnabledWhatsapp() || isEnabledWhatsappIntegration()"
      >
        <div class="form-group">
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text">+55</div>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="WhatsApp (opcional)"
              formControlName="whatsappNumber"
              mask="(00) 0 0000-0000"
            />
          </div>
        </div>

        <div class="form-group">
          <div
            class="custom-switch"
            [ngClass]="{ 'disabled-switch': isValidInputWhatsapp() }"
          >
            <label class="switch">
              <input type="checkbox" formControlName="notifyWhatsapp" />
              <span class="slider round"></span>
            </label>
            <label Class="custom-text">Enviar fluxo via WhatsApp</label>
          </div>
        </div>
      </ng-container>

      <div class="mt-3 text-right">
        <button
          class="btn btn-secondary btn-sm"
          type="submit"
          [disabled]="contactEditForm.invalid"
        >
          Salvar
        </button>
      </div>
    </form>
  </app-modal-action>
</ng-template>
