<div class="contact-form">
  <div class="form-group mb-4">
    <div class="row" *ngIf="!isIntegration()">
      <div class="col-6 pr-2">
        <button
          autofocus="none"
          class="btn btn-outline-success btn-block"
          (click)="addMyselfToSign()"
        >
          Me adicionar
        </button>
      </div>
      <div class="col-6 pl-2">
        <button
          autofocus="none"
          class="btn btn-outline-success btn-block"
          (click)="openModal(modalAddContact)"
        >
          Contatos
        </button>
      </div>
    </div>
  </div>
  <form (ngSubmit)="submitContact()" [formGroup]="contactForm">
    <div class="form-group">
      <input
        #nameInput
        name="name"
        placeholder="Nome completo do assinante"
        class="form-control"
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid': formControlValidation(contactForm.controls.name)
        }"
      />
    </div>

    <div class="form-group">
      <input
        name="email"
        placeholder="Email"
        class="form-control"
        type="email"
        formControlName="email"
        [ngClass]="{
          'is-invalid': formControlValidation(contactForm.controls.email)
        }"
      />
    </div>

    <ng-container *ngIf="isEnabledWhatsapp() || isEnabledWhatsappIntegration()">
      <div class="form-group">
        <div class="input-group mb-2 mr-sm-2">
          <div class="input-group-prepend">
            <div class="input-group-text">+55</div>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="WhatsApp (opcional)"
            name="whatsapp"
            formControlName="whatsappNumber"
            mask="(00) 00000-0000"
          />
        </div>
      </div>

      <div class="form-group">
        <div
          class="custom-switch"
          [ngClass]="{ 'disabled-switch': isValidInputWhatsapp() }"
        >
          <label class="switch">
            <input type="checkbox" formControlName="notifyWhatsapp" />
            <span class="slider round"></span>
          </label>
          <label class="custom-text">Enviar fluxo via WhatsApp</label>
        </div>
      </div>
    </ng-container>

    <div class="form-group">
      <div class="custom-switch">
        <label class="switch">
          <input type="checkbox" formControlName="hasPhotoSign" />
          <span class="slider round"></span>
        </label>
        <label class="custom-text">Exigir foto do assinante</label>
      </div>
    </div>

    <div class="mt-3 text-right">
      <button
        class="btn btn-secondary btn-sm"
        type="submit"
        [disabled]="contactForm.invalid"
      >
        Adicionar
      </button>
    </div>
  </form>
</div>

<ng-template #modalAddContact let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Meus contatos'"
  >
    <app-workflow-build-contact-selection
      (contactsSelected)="onContactsSelected($event)"
    >
    </app-workflow-build-contact-selection>
  </app-modal-action>
</ng-template>
