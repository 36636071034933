import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DRAGGABLE_FIELDS } from './draggable-fields';
import { IDraggableField } from '@app/core/models';
import { WorkflowContact, WorkflowFile } from '@app/core/class';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Utils } from '@app/utils/utils';
import {
  VISIBLE_SIGNATURES,
  WorkflowBuildService,
  WORKFLOW_STEP_FIELDS
} from '@app/core/services';
import { Subscription } from 'rxjs';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';
@Component({
  selector: 'app-workflow-draggable-field-list',
  templateUrl: './draggable-field-list.component.html',
  styleUrls: ['./draggable-field-list.component.scss']
})
export class WorkflowDraggableFieldListComponent implements OnInit, OnDestroy {
  @Input()
  public contact: WorkflowContact;

  public connectedTo: string[] = [];

  public draggableFieldList: IDraggableField[];

  private visibleFile: WorkflowFile = null;

  private visibleFileObservable: Subscription;

  private workflowBuildService: WorkflowBuildService;

  constructor(workflowBuildService: WorkflowBuildService) {
    this.workflowBuildService = workflowBuildService;
    this.draggableFieldList = DRAGGABLE_FIELDS;
  }

  public ngOnInit(): void {
    this.visibleFile = this.workflowBuildService.getVisibleFile();

    this.visibleFileObservable = this.workflowBuildService
      .observeVisibleFile()
      .subscribe(file => {
        this.visibleFile = file;
      });
  }

  public ngOnDestroy(): void {
    if (this.visibleFileObservable) {
      this.visibleFileObservable.unsubscribe();
    }
  }

  public onDragMoved($event: CdkDragMove): boolean {
    this.workflowBuildService.setCursorPosition(
      $event.pointerPosition.x,
      $event.pointerPosition.y
    );
    return false;
  }

  public canDrag(draggableField: IDraggableField): boolean {
    if (!this.visibleFile) {
      return false;
    }

    if (VISIBLE_SIGNATURES.includes(draggableField.type)) {
      // Check if there is a dropped signature in the current file
      // and if it different for this current signature type
      const droppedSignature = this.getDroppedSignatureInCurrentFile();
      if (
        !!droppedSignature &&
        droppedSignature.getSignatureType() !== draggableField.signatureType
      ) {
        return false;
      }

      // If there is another signature on another file, only
      // enable the same signature type to be dropped, not the
      // others
      const signatureTypeDroppedOnOtherFiles = this.whichSignatureIsDroppedOnOtherFiles();
      if (
        signatureTypeDroppedOnOtherFiles > -1 &&
        signatureTypeDroppedOnOtherFiles !== draggableField.signatureType
      ) {
        return false;
      }
    }

    if (
      draggableField.type === WORKFLOW_STEP_FIELDS.QR_CODE ||
      draggableField.type === WORKFLOW_STEP_FIELDS.QR_CODE_SHARE
    ) {
      if (this.hasDroppedQRCode()) {
        return false;
      }
    }

    return true;
  }

  /**
   * Get the current dropped signature in current file
   */
  private getDroppedSignatureInCurrentFile(): IWorkflowDroppedField {
    const fields = this.visibleFile.getAllFields();
    // From all the fields, which one is a signature and is from this contact
    return fields.find(f => f.isSignature() && f.getContact() === this.contact);
  }

  /**
   * Return which signature type was dropped on another file
   * for the contact, if any. Return -1 if there was none.
   */
  private whichSignatureIsDroppedOnOtherFiles(): number {
    const files = this.workflowBuildService.getFiles();

    for (const file of files) {
      // skip the current visible file
      // we need to check the other ones
      if (file === this.visibleFile) {
        continue;
      }

      const fields = file.getAllFields();
      const signature = fields.find(
        f => f.isSignature() && f.getContact() === this.contact
      );

      if (!!signature) {
        return signature.getSignatureType();
      }
    }

    return -1;
  }

  private hasDroppedQRCode(): boolean {
    const fields = this.visibleFile.getAllFields();
    const droppedQRCode = fields.find(
      f =>
        f.getType() === WORKFLOW_STEP_FIELDS.QR_CODE ||
        f.getType() === WORKFLOW_STEP_FIELDS.QR_CODE_SHARE
    );
    if (!!droppedQRCode) {
      return true;
    }
    return false;
  }

  public isMobile(): boolean {
    return Utils.isMobile();
  }

  public addFieldByClick(contact: any, draggableField: IDraggableField): void {
    if (this.canDrag(draggableField)) {
      this.workflowBuildService.setCursorPosition(0, 0);
      this.workflowBuildService.setDataFieldAddedByClick(
        contact,
        draggableField
      );
      this.workflowBuildService.setFieldAddedByClick();
      document.documentElement.scrollTop = document.body.scrollTop = 200;
    }
  }
}
