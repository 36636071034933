import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models/contact.model';

@Injectable()
export class ContactService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/contact';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  searchContacts(queryString?: any): Observable<any> {
    return super.getAll(queryString).pipe(
      map((body: any) => {
        const contacts = body._embedded.contact;
        return contacts.map((contact: any) => {
          return {
            id: contact.id,
            name: contact._embedded.userTwo.name,
            email: contact._embedded.userTwo.email,
            notifyWhatsapp: contact._embedded.userTwo.notifyWhatsapp,
            whatsappNumber: this.replaceDDI(
              contact._embedded.userTwo.whatsappNumber
            )
          };
        });
      })
    );
  }

  replaceDDI(phone: string): string {
    if (!phone) {
      return null;
    }
    const ddi = phone.replace('+', '').replace('55', '');
    return ddi;
  }

  getAll(queryString?: any): Observable<any> {
    return super.getAll(queryString).pipe(
      map((response: any) => {
        return this._parseListContact(response);
      })
    );
  }

  /**
   * Gets contact by email
   */
  getContactByEmail(email: string): Observable<any> {
    const filter = {
      filter: [
        {
          type: 'innerjoin',
          field: 'userTwo',
          alias: 'u'
        },
        {
          type: 'eq',
          alias: 'u',
          field: 'email',
          value: `${email}`
        }
      ]
    };

    return super.getAll(filter).pipe(
      map((response: any) => {
        if (response.total_items >= 1) {
          const contact = response._embedded.contact[0];
          return {
            id: contact.id,
            userId: contact._embedded.userTwo.id,
            name: contact._embedded.userTwo.name,
            email: contact._embedded.userTwo.email
          };
        }

        return null;
      })
    );
  }

  /**
   * Gets contact by name
   */
  getContactsByName(name: string): Observable<any> {
    const filter = {
      filter: [
        {
          type: 'innerjoin',
          field: 'userTwo',
          alias: 'u'
        },
        {
          type: 'like',
          alias: 'u',
          field: 'name',
          value: `%${name}%`
        }
      ]
    };

    return super.getAll(filter).pipe(
      map((response: any) => {
        return this._parseListContact(response);
      })
    );
  }

  /**
   * Create contact
   */
  addContact(contact: any): Observable<any> {
    const payload = {
      name: contact.name,
      email: contact.email
    };

    return super.create(payload).pipe(
      map((response: any) => {
        const output: any = { id: null, message: null };

        if (response._embedded) {
          output.id = response._embedded.userTwo.id;
        }
        if (response.detail) {
          output.message = response.detail;
        }

        return output;
      })
    );
  }

  _parseListContact(data: any): any {
    const items = data._embedded.contact.map((e: any) => {
      return {
        id: e.id,
        status: e.status,
        statusText: e.status ? 'Habilitado' : 'Desabilitado',
        embedded_id: e._embedded.userTwo.id,
        userId: e._embedded.userTwo.id,
        name: e._embedded.userTwo.name,
        email: e._embedded.userTwo.email
      };
    });

    return {
      items: items,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items
    };
  }
}
