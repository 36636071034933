import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { matchFields } from '@app/core/helpers';

@Component({
  selector: 'app-confirm-personal-form',
  templateUrl: './confirm-personal-form.component.html',
  styleUrls: ['./confirm-personal-form.component.scss']
})
export class ConfirmPersonalFormComponent implements OnInit {
  dataForm: FormGroup;

  @Output() formReady = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.dataForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
          ])
        ],
        repeatPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,30})')
          ])
        ]
      },
      { validator: matchFields('password', 'repeatPassword') }
    );

    this.formReady.emit(this.dataForm);
  }
  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.dataForm.controls;
  }
}
