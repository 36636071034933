import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';

@Component({
  selector: 'app-certificate-chooser',
  templateUrl: './certificate-chooser.component.html',
  styleUrls: ['./certificate-chooser.component.scss']
})
export class CertificateChooserComponent implements OnInit {
  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data with user logged
   */
  @Input() user: any;

  /**
   * Data of Form
   */
  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.form.controls['document'].setValue(this.user.document);
  }

  createForm() {
    this.form = this.formBuilder.group({
      type: ['Bird ID', [Validators.required]],
      document: ['', [Validators.required]],
      otp: ['', [Validators.required]]
    });
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'certificate-chooser',
      formData: this.form.value
    });
  }
}
