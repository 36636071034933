/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../droppable-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./qr-code.component";
var styles_WorkflowDroppableQrCodeFieldComponent = [i0.styles];
var RenderType_WorkflowDroppableQrCodeFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDroppableQrCodeFieldComponent, data: {} });
export { RenderType_WorkflowDroppableQrCodeFieldComponent as RenderType_WorkflowDroppableQrCodeFieldComponent };
export function View_WorkflowDroppableQrCodeFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "field-block field-qr-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "qr-code"], ["src", "assets/images/qrcode.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_WorkflowDroppableQrCodeFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-qr-code-field", [], null, null, null, View_WorkflowDroppableQrCodeFieldComponent_0, RenderType_WorkflowDroppableQrCodeFieldComponent)), i1.ɵdid(1, 114688, null, 0, i2.WorkflowDroppableQrCodeFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowDroppableQrCodeFieldComponentNgFactory = i1.ɵccf("app-workflow-droppable-qr-code-field", i2.WorkflowDroppableQrCodeFieldComponent, View_WorkflowDroppableQrCodeFieldComponent_Host_0, { field: "field" }, {}, []);
export { WorkflowDroppableQrCodeFieldComponentNgFactory as WorkflowDroppableQrCodeFieldComponentNgFactory };
