import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowService } from '@app/core/services';

import { finalize } from 'rxjs/operators';

import { Logger } from '@app/core/class';
import { Event } from '@app/core/models';

const log = new Logger('PushNotificationComponent');

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  events: Array<Event.EventData> = [];
  notificationQtde = 0;
  constructor(
    config: NgbDropdownConfig,
    private workflowService: WorkflowService
  ) {
    config.placement = 'bottom';
  }

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    try {
      // this.isEventLoading = true;
      this.workflowService
        .getAll()
        .pipe(
          finalize(() => {
            // this.isEventLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            log.debug(`Success [getEvents]:`, response);
            this.events = response.items;
            this.notificationQtde = this.events.length;
          },
          (error: any) => {
            log.debug(`Error [getEvents]:`, error);
            this.events = [];
          }
        );
    } catch (e) {
      // this.isEventLoading = false;
      this.events = [];
      log.debug(`Error [getEvents]:`, e);
    }
  }

  clearNotification() {
    this.notificationQtde = 0;
  }
}
