import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { map } from 'rxjs/operators';
var IntegrationDocuwareService = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationDocuwareService, _super);
    function IntegrationDocuwareService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/integration-docware';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Create integration docware
     */
    IntegrationDocuwareService.prototype.create = function (payload) {
        var _this = this;
        return this.httpClient.post(this.endpoint, payload).pipe(map(function (response) {
            return _this.parseIntegration(response);
        }));
    };
    IntegrationDocuwareService.prototype.updateIntegration = function (id, payload) {
        return _super.prototype.update.call(this, id, payload).pipe(map(function (response) {
            return response;
        }));
    };
    IntegrationDocuwareService.prototype.deleteIntegration = function (id) {
        return _super.prototype.delete.call(this, id).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * Get integration docware
     */
    IntegrationDocuwareService.prototype.getAll = function () {
        var _this = this;
        return _super.prototype.getAll.call(this).pipe(map(function (response) {
            return _this.parseIntegration(response);
        }));
    };
    IntegrationDocuwareService.prototype.parseIntegration = function (data) {
        var integration;
        if (data.total_items > 0) {
            integration = {
                id: data._embedded.integration_docware[0].id,
                fileCabinetId: data._embedded.integration_docware[0].fileCabinetId,
                serverUrl: data._embedded.integration_docware[0].serverUrl,
                username: data._embedded.integration_docware[0].username,
                businessUnit: {
                    id: data._embedded.integration_docware[0]._embedded.businessUnit.id,
                    name: data._embedded.integration_docware[0]._embedded.businessUnit.name,
                    document: data._embedded.integration_docware[0]._embedded.businessUnit
                        .document,
                    status: data._embedded.integration_docware[0]._embedded.businessUnit.status,
                    country: data._embedded.integration_docware[0]._embedded.businessUnit
                        .country,
                    state: data._embedded.integration_docware[0]._embedded.businessUnit.state,
                    city: data._embedded.integration_docware[0]._embedded.businessUnit.city,
                    address: data._embedded.integration_docware[0]._embedded.businessUnit
                        .address,
                    zipCode: data._embedded.integration_docware[0]._embedded.businessUnit.zipCode
                }
            };
            return integration;
        }
        return integration;
    };
    return IntegrationDocuwareService;
}(BaseAPI));
export { IntegrationDocuwareService };
