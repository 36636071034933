import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManagerRoutingPath } from '../../manager-routing.path';
import { UserService } from '@app/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-sign',
  templateUrl: './check-sign.component.html',
  styleUrls: ['./check-sign.component.scss']
})
export class CheckSignComponent implements OnInit {
  feedback: any = { message: undefined, type: undefined };

  dataForm: FormGroup;

  dataFile: any = {};

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.dataForm = this.fb.group({
      code: ['']
    });
  }

  doSubmit() {
    this.feedback = {
      message: undefined,
      type: undefined
    };

    if ((this.dataFile = {} && this.dataForm.controls.code.value === '')) {
      this.feedback = {
        message:
          'Favor informar um documento ou código do documento para verificação.',
        type: 'error'
      };

      return false;
    }

    // @todo fazer a chamada para a API.
    if (true) {
      const url =
        ManagerRoutingPath.MANAGER + '/' + ManagerRoutingPath.CODE_RESULT;

      this.router.navigate([url]);
    }
  }

  createDocument(response: any) {
    this.feedback = {
      message: undefined,
      type: undefined
    };

    this.dataFile = {
      id: response.files.id,
      base64: response.files.base64,
      name: response.files.name,
      nativeFile: response.files.nativeFile,
      apiData: response.files.response
    };

    this.doSubmit();
  }
}
