<div class="form-folder form-modal">
  <form (ngSubmit)="doSubmit()" [formGroup]="folderForm" novalidate>
    <div class="row form-modal">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Nome da pasta </label>
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="name"
              />
            </div>

            <div class="form-group">
              <label
                >Código da integração
                <i [title]="infoText()" class="fa fa-info-circle"></i
              ></label>
              <input
                type="text"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid':
                    getFolderForm.metadata.errors &&
                    !getFolderForm.metadata.pristine
                }"
                formControlName="metadata"
              />
              <div
                *ngIf="
                  getFolderForm.metadata.errors &&
                  !getFolderForm.metadata.pristine
                "
              >
                <span
                  class="error"
                  [hidden]="!getFolderForm.metadata.errors.required"
                >
                  O Id File Cabinet é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-5 mb-3">
            <button
              type="button"
              class="btn btn-default"
              (click)="shouldModalClose()"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-secondary btn-sm"
              [disabled]="folderForm.invalid"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
