import { FormBuilder, Validators } from '@angular/forms';
var FolderFormComponent = /** @class */ (function () {
    function FolderFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.checkIntegrationWithFolder = false;
        this.createForm();
    }
    /**
     * Data selection action by clicking the form submit button
     */
    FolderFormComponent.prototype.doSubmit = function () {
        var payalod = this.preseData(this.folderForm);
        this.modal.close({
            action: 'save',
            form: 'folder',
            formData: this.folderForm.value
        });
    };
    FolderFormComponent.prototype.preseData = function (folderForm) {
        if (!this.hasIntegrationWithFolder()) {
            folderForm.setValue({
                name: folderForm.value.name,
                metadata: {
                    file_cabinet_id: null
                }
            });
        }
        return folderForm;
    };
    Object.defineProperty(FolderFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.folderForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    FolderFormComponent.prototype.shouldShowInfoIntegration = function () {
        return this.integrationWithFolder;
    };
    FolderFormComponent.prototype.infoText = function () {
        return 'Deve preencher com Id File Cabinet, ele e usado para criar a integração com a pasta docuWare';
    };
    FolderFormComponent.prototype.hasIntegrationWithFolder = function () {
        return this.checkIntegrationWithFolder;
    };
    FolderFormComponent.prototype.toggleIntegrationWithFolder = function () {
        this.checkIntegrationWithFolder = !this.checkIntegrationWithFolder;
    };
    /**
     * Form creation and validation configuration
     */
    FolderFormComponent.prototype.createForm = function () {
        this.folderForm = this.formBuilder.group({
            name: ['', Validators.required],
            metadata: this.formBuilder.group({
                file_cabinet_id: [null]
            })
        });
    };
    return FolderFormComponent;
}());
export { FolderFormComponent };
