import * as moment from 'moment';

export function parseDate(dt: any) {
  if (dt && dt.date) {
    const formated = dt.date.replace(/ /g, 'T') + 'Z';
    const dLocal = new Date(formated);
    const u = Date.UTC(
      dLocal.getUTCFullYear(),
      dLocal.getUTCMonth(),
      dLocal.getUTCDate(),
      dLocal.getUTCHours(),
      dLocal.getUTCMinutes(),
      dLocal.getUTCSeconds()
    );
    moment.locale('pt-BR');

    const m = moment(u);
    return {
      _formated: m.fromNow(),
      _date: dt,
      _parsed: dLocal,
      formated_date: m.format('DD, MMM YYYY'),
      formated_hour: m.format('hh:mm:ss A'),
      date: u
    };
  }

  return null;
}

export function formatDate(dt: any, format: string) {
  return moment(dt).format(format);
}
