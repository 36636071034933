import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FinishPageComponent } from '@app/manager/pages';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService, WorkflowBuildService } from '@app/core/services';
var IntegrationFinishPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationFinishPageComponent, _super);
    function IntegrationFinishPageComponent(authorizationService, workflowBuildService, router, route) {
        var _this = _super.call(this, authorizationService, workflowBuildService, router) || this;
        _this.route = route;
        _this.buildURL = '/integration/workflow';
        return _this;
    }
    IntegrationFinishPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.workflowId = this.workflowBuildService.getWorkflowId();
        if (!this.workflowId) {
            this.navigateToWorkflowBuild();
            return;
        }
        var callbackUrl = this.callbackUrl();
        if (callbackUrl) {
            setTimeout(function () {
                _this.workflowBuildService.setWorkflowId(null);
                window.location.href = callbackUrl;
            }, 2500);
        }
    };
    IntegrationFinishPageComponent.prototype.callbackUrl = function () {
        var cbUrl = this.route.snapshot.queryParams['callbackUrl'];
        if (!cbUrl) {
            return null;
        }
        var templateId = this.route.snapshot.queryParams['id'];
        var _a = cbUrl.split('#'), uri = _a[0], hash = _a[1];
        var url = uri;
        // Checa se já possui algum query param na url de callback
        if (url.includes('?')) {
            url += '&';
        }
        else {
            url += '?';
        }
        cbUrl = url + "id=" + templateId;
        if (hash) {
            cbUrl += "#" + hash;
        }
        return cbUrl;
    };
    return IntegrationFinishPageComponent;
}(FinishPageComponent));
export { IntegrationFinishPageComponent };
