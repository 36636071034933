import { Country } from '@app/core/models/country.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CountryService {
  list(): Array<Country.FormPayload> {
    return [
      {
        id: 'BR',
        name: 'Brasil',
        photo:
          'https://seeklogo.com/images/B/bandeira-do-brasil-logo-5D75AAD6AF-seeklogo.com.png'
      }
    ];
  }
}
