<div class="form-custom">
  <form (ngSubmit)="doSubmit()" [formGroup]="customSubjectForm">
    <div class="row form-modal">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Tipo de e-mail </label>
              <select class="form-control" formControlName="event">
                <option value="">Selecione</option>
                <option
                  *ngFor="let eventType of eventsTypeKeys"
                  [value]="eventType"
                >
                  {{ eventsTypes[eventType] }}
                </option>
              </select>
              <div *ngIf="f.event.errors && !f.event.pristine">
                <span class="error" [hidden]="!f.event.errors.required">
                  O tipo de e-mail é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end line -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Assunto do e-mail </label>
              <input
                placeholder="Digite um assunto para o e-mail"
                class="form-control form-control-sm"
                type="text"
                formControlName="subject"
              />
              <div *ngIf="f.subject.errors && !f.subject.pristine">
                <span class="error" [hidden]="!f.subject.errors.required">
                  O assunto é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end line -->
        <div class="row">
          <div class="col-12 text-right mt-5 mb-3">
            <button
              class="btn btn-default"
              type="button"
              (click)="modal.close('cancel')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-secondary btn-sm"
              type="submit"
              [disabled]="customSubjectForm.invalid || isLoading"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
