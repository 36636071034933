import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

/**
 * Prefixes all requests with `environment.apiURL`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Valida se a requisição não começar com http ou https
    // Se não começar, concatena com o endereço do backend
    if (!/^https?/i.test(request.url)) {
      const url = environment.apiURL + environment.apiVersion + request.url;
      request = request.clone({ url });
    }

    return next.handle(request);
  }
}
