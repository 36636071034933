import { OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var ContactFormComponent = /** @class */ (function () {
    function ContactFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.createForm();
    }
    ContactFormComponent.prototype.doUpdate = function () {
        var payload = {
            id: this.selected.id,
            status: this.selected.status === 0 ? 1 : 0
        };
        this.modal.close({
            action: 'edit',
            form: 'contact',
            formData: payload
        });
    };
    /**
     * Data selection action by clicking the form submit button
     */
    ContactFormComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'contact',
            formData: this.contactForm.value
        });
    };
    Object.defineProperty(ContactFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.contactForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    ContactFormComponent.prototype.createForm = function () {
        this.contactForm = this.formBuilder.group({
            id: ['', { disable: true }],
            email: ['', [Validators.required, Validators.email]],
            name: ['', Validators.required]
        });
        this.updateForm = this.formBuilder.group({});
    };
    ContactFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selected.currentValue) {
            this.newStatus =
                this.selected.status === 0 ? 'HABILITADO' : 'DESABILITADO';
            var _a = changes.selected.currentValue, id = _a.id, name_1 = _a.name, email = _a.email;
            this.contactForm.setValue({
                id: id,
                name: name_1,
                email: email
            });
        }
    };
    return ContactFormComponent;
}());
export { ContactFormComponent };
