import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthorizationService, UserService } from '@app/core/services';
var UpdateEasySignComponent = /** @class */ (function () {
    function UpdateEasySignComponent(formBuilder, userService, authorizationService) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.authorizationService = authorizationService;
        /**
         * Spinner Save button
         */
        this.spinnerSave = false;
        this.step = false;
        this.createForm();
    }
    UpdateEasySignComponent.prototype.ngOnInit = function () { };
    /**
     * Data selection action by clicking the submit button on the form. action set cancel or submit
     */
    UpdateEasySignComponent.prototype.doSubmit = function (action) {
        if (action) {
            this.spinnerSave = true;
        }
        var target = {
            name: '',
            cellphone: '',
            businessUnit: {
                name: ''
            }
        };
        target.name = this.data.value.name || this.logged.name;
        target.cellphone = this.data.value.cellphone || this.logged.cellphone;
        target.businessUnit.name = this.data.value.name || this.logged.name;
        this.cancelOrSubmit(action, target);
    };
    UpdateEasySignComponent.prototype.cancelOrSubmit = function (action, form) {
        var _this = this;
        this.userService.updateUser(this.logged.id, form).subscribe(function (data) {
            _this.getPlanSubscription();
            _this.authorizationService.setUser(data);
            _this.spinnerSave = false;
            if (action) {
                _this.step = true;
            }
            else {
                _this.refreshPage();
            }
        }, function (error) {
            _this.spinnerSave = false;
            _this.feedbackTheme = 'error';
            _this.feedbackMessage =
                "N\u00E3o foi poss\u00EDvel atualizar os dados desta conta." +
                    " Tente novamente";
        });
    };
    UpdateEasySignComponent.prototype.refreshPage = function () {
        window.location.reload();
        this.modal.close();
    };
    /**
     * Obtain information from the plan that is written
     */
    UpdateEasySignComponent.prototype.getPlanSubscription = function () {
        var paymentInfo;
        this.userService.getPaymentInfo().subscribe(function (body) { return (paymentInfo = body); });
    };
    Object.defineProperty(UpdateEasySignComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.data.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    UpdateEasySignComponent.prototype.createForm = function () {
        this.data = this.formBuilder.group({
            name: [
                '',
                Validators.compose([Validators.required, Validators.minLength(5)])
            ],
            cellphone: [
                '',
                Validators.compose([Validators.required, Validators.minLength(8)])
            ]
        });
    };
    return UpdateEasySignComponent;
}());
export { UpdateEasySignComponent };
