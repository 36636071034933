import { Component, OnInit } from '@angular/core';
import {
  AuthenticationService,
  UserService,
  AuthorizationService,
  WorkflowBuildService,
  RemainingUserBalanceService
} from '@app/core/services';
import { Router } from '@angular/router';
import { UserSubscription, PhotoSubscription } from '@app/core/subscriptions';
import { map } from 'rxjs/operators';
import { Authentication, User } from '@app/core/models';
import { BusinessMetadataService } from '@app/core/services/business-unit-metadata.service';
import { BusinessUnitMetadata } from '@app/core/models/business-unit-metadata.model';

@Component({
  selector: 'app-layout-manager-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class LayoutManagerHeaderComponent implements OnInit {
  isCollapsed = true;

  user: Authentication.SignupUserResponse;
  photoURL: string;
  logo: string;
  planInfo: User.UserPlanPayload;

  showUserPlanMessage: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private businessMetadataService: BusinessMetadataService,
    private userService: UserService,
    private userSubscription: UserSubscription,
    private photoSubscription: PhotoSubscription,
    private workflowBuildService: WorkflowBuildService,
    public router: Router,
    private remainingUserBalance: RemainingUserBalanceService
  ) {
    this.userSubscription.getUser().subscribe(data => (this.user = data));
    this.photoSubscription.get().subscribe(data => (this.photoURL = data));
  }

  ngOnInit() {
    this.loadUser();
    this.loadAvatar();
    this.getBusinessUnitMetadata();
    this.getOrganizationBrand();
  }

  getBusinessUnitMetadata() {
    this.businessMetadataService
      .getMetadata()
      .subscribe((response: BusinessUnitMetadata.LogoCollection) => {
        this.logo = response ? response.dataValue : null;
      });
    this.getUserPlanInfo();
  }

  getOrganizationBrand(): void {
    this.businessMetadataService.output.subscribe(
      (response: BusinessUnitMetadata.OrganizationBrand) => {
        this.logo = response.dataValue;
      }
    );
  }

  getUserPlanInfo() {
    this.remainingUserBalance.planUser.subscribe((reponse: any) => {
      this.planInfo = reponse;

      if (this.planInfo.globalSignaturesRemainments === 0) {
        this.showUserPlanMessage = true;
      }
    });
  }

  loadAvatar() {
    this.userService
      .getAllMetadata()
      .pipe(
        map((body: any) => {
          return body.filter((e: User.UserMetadata) => e.dataKey === 'picture');
        })
      )
      .subscribe((response: Array<User.UserMetadata>) => {
        this.photoURL = response.length ? response[0].dataValue : null;
      });
  }

  loadUser() {
    this.authorizationService.user.subscribe(
      response => (this.user = response)
    );
  }

  logout() {
    this.resetWorkflow();
    this.userService.removeUserLocalData();
    this.authenticationService.logout().subscribe(() => location.assign('/'));
  }

  profile() {
    this.resetWorkflow();
    this.router.navigate(['/manager/profile']);
  }

  resetWorkflow() {
    this.workflowBuildService.resetWorkflow();
  }
}
