import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
var FolderService = /** @class */ (function (_super) {
    tslib_1.__extends(FolderService, _super);
    function FolderService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/folder';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    /**
     * Gets all data of a service
     */
    FolderService.prototype.getAll = function (querystring) {
        var _this = this;
        return this.httpClient
            .get(this.endpoint + (querystring ? querystring : ''))
            .pipe(map(function (response) {
            return _this._parseFolders(response);
        }));
    };
    /**
     * Gets all data of a service
     */
    FolderService.prototype.searchFolder = function (queryString) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this._parseFolders(response);
        }));
    };
    /**
     * Rename folder
     */
    FolderService.prototype.renameFolder = function (id, name) {
        return this.httpClient.patch(this.endpoint + "/" + id, { name: name });
    };
    /**
     * Create a default folder
     */
    FolderService.prototype.createDefaultFolder = function () {
        var _this = this;
        var payload = {
            name: 'default',
            path: 'default'
        };
        return _super.prototype.create.call(this, payload).pipe(map(function (response) {
            return _this._parseFolders(response);
        }));
    };
    FolderService.prototype.moveFolderDocument = function (idDoc, idFolder) {
        return this.httpClient.patch("/document/" + idDoc, idFolder);
    };
    FolderService.prototype.getFolderDocuments = function (id, page) {
        return this.httpClient.get('/document?filter[0][type]=eq&filter[0][field]=folder&filter[0][value]=' +
            id +
            '&page=' +
            page +
            '&pageSize=28');
    };
    /**
     * Parse list of folders and adjusts to
     * default for printing the component
     *
     * @param (any) items data of service
     */
    FolderService.prototype._parseFolders = function (data) {
        var _this = this;
        var folders = [];
        if (data.total_items > 0) {
            folders = data._embedded.folder.map(function (folder) {
                var folderIntegration = null;
                if (folder.folderIntegration &&
                    !_this.isEmptyObject(folder.folderIntegration)) {
                    folderIntegration = {
                        id: folder.folderIntegration.id,
                        metadata: JSON.parse(folder.folderIntegration.metadata)
                    };
                }
                var id = folder.id, name = folder.name, path = folder.path, businessUnit = folder.businessUnit;
                return {
                    id: id,
                    name: name,
                    path: path,
                    businessUnit: businessUnit,
                    owner: folder._embedded.user,
                    folderIntegration: folderIntegration
                };
            });
        }
        return {
            items: folders,
            default: folders.length > 0 ? folders[0] : null,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items,
            _raw: data._embedded,
            _links: data._links
        };
    };
    FolderService.prototype.isEmptyObject = function (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    return FolderService;
}(BaseAPI));
export { FolderService };
