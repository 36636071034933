import * as CryptoJS from 'crypto-js';

export const generateFileSha1 = (file: File | Blob): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise<string>(resolve => {
    fileReader.addEventListener('load', (e: any) => {
      const i8a = new Uint8Array(e.target.result);
      const a = [];

      for (let i = 0; i < i8a.length; i += 4) {
        a.push(
          (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]
        );
      }

      const hash = CryptoJS.SHA1(CryptoJS.lib.WordArray.create(a)).toString();

      resolve(hash);
    });
    fileReader.readAsArrayBuffer(file);
  });
};

export const generateFileBase64 = (file: File | Blob): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise<string>(resolve => {
    fileReader.addEventListener('load', (e: any) => {
      const base64 = e.target.result as string;
      resolve(base64);
    });
    fileReader.readAsDataURL(file);
  });
};
