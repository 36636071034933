<div
  class="document-dropzone"
  cdkDropList
  (cdkDropListDropped)="onDrop($event)"
  style="display: block;"
>
  <div class="dropped-fields-zone" #dropzone>
    <app-workflow-droppable-field
      (spreadFieldAllPages)="onSpreadFieldAllPages(field)"
      *ngFor="let field of fields"
      [field]="field"
    ></app-workflow-droppable-field>
  </div>
  <ng-content></ng-content>
</div>
