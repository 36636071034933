/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-folder-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/components/pagination/pagination.component.ngfactory";
import * as i5 from "../../../../shared/components/pagination/pagination.component";
import * as i6 from "./document-folder-list.component";
import * as i7 from "../../../../core/services/authorization.service";
var styles_DocumentFolderListComponent = [i0.styles];
var RenderType_DocumentFolderListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentFolderListComponent, data: {} });
export { RenderType_DocumentFolderListComponent as RenderType_DocumentFolderListComponent };
function View_DocumentFolderListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-file file-icon"]], null, null, null, null, null))], null, null); }
function View_DocumentFolderListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-file file-icon"], ["style", "color:#327253"]], null, null, null, null, null))], null, null); }
function View_DocumentFolderListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("view", _v.parent.parent.context.$implicit._embedded.workflow) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Detalhes\n              "]))], null, null); }
function View_DocumentFolderListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("signedFile", _v.parent.parent.context.$implicit._embedded.file) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Download assinado\n              "]))], null, null); }
function View_DocumentFolderListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-lock ml-4"]], null, null, null, null, null))], null, null); }
function View_DocumentFolderListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "d-inline-block handle-icon"], ["ngbDropdown", ""], ["style", "position: absolute; right: 5px; top: 14px; width: 22px; text-align: center;"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 212992, null, 2, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "i", [["aria-haspopup", "true"], ["class", "fas fa-ellipsis-v dropdown-toggle"], ["ngbDropdownToggle", ""], ["style", "font-size: 20px;"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(9, 0, null, null, 17, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 0, i2.NgbDropdownMenu, [i2.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_5)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n              "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("originalFile", _v.parent.context.$implicit._embedded.originalFile) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Download original\n              "])), (_l()(), i1.ɵted(-1, null, ["\n\n              "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_6)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n              "])), (_l()(), i1.ɵeld(21, 0, null, null, 4, "button", [["class", "dropdown-item"]], [[8, "disabled", 0], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("toMove", _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Mover para\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_7)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n          "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_5 = _co.selectedFolder.isOwner; _ck(_v, 13, 0, currVal_5); var currVal_6 = _v.parent.context.$implicit._embedded.file; _ck(_v, 19, 0, currVal_6); var currVal_9 = !_co.moveButtonState(); _ck(_v, 24, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = true; var currVal_3 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_4 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); var currVal_7 = !_co.moveButtonState(); var currVal_8 = _co.titlePhrase(_co.moveButtonState()); _ck(_v, 21, 0, currVal_7, currVal_8); }); }
function View_DocumentFolderListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "col-12 col-md-6 col-lg-4 col-xl-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "slot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["style", "width: 100%; padding-left: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goAction("view", _v.context.$implicit._embedded.workflow) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_3)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_4)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit._embedded.file; _ck(_v, 9, 0, currVal_0); var currVal_1 = _v.context.$implicit._embedded.file; _ck(_v, 12, 0, currVal_1); var currVal_3 = true; _ck(_v, 19, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit._embedded.originalFile.name; _ck(_v, 15, 0, currVal_2); }); }
export function View_DocumentFolderListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "folder-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentFolderListComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-pagination", [], null, [[null, "doPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("doPage" === en)) {
        var pd_0 = (_co.doPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationComponent_0, i4.RenderType_PaginationComponent)), i1.ɵdid(12, 114688, null, 0, i5.PaginationComponent, [], { totalItems: [0, "totalItems"], pageCount: [1, "pageCount"], currentPage: [2, "currentPage"] }, { doPage: "doPage" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.pagination.totalItems; var currVal_2 = _co.pagination.pageCount; var currVal_3 = _co.pagination.currentPage; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DocumentFolderListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-folder-list", [], null, null, null, View_DocumentFolderListComponent_0, RenderType_DocumentFolderListComponent)), i1.ɵdid(1, 114688, null, 0, i6.DocumentFolderListComponent, [i7.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentFolderListComponentNgFactory = i1.ɵccf("app-document-folder-list", i6.DocumentFolderListComponent, View_DocumentFolderListComponent_Host_0, { collection: "collection", selectedFolder: "selectedFolder", pagination: "pagination" }, { activity: "activity", doChangePage: "doChangePage" }, []);
export { DocumentFolderListComponentNgFactory as DocumentFolderListComponentNgFactory };
