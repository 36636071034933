import * as tslib_1 from "tslib";
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableQrCodeFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableQrCodeFieldComponent, _super);
    function WorkflowDroppableQrCodeFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowDroppableQrCodeFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableQrCodeFieldComponent };
