<form [formGroup]="propertiesField">
  <div class="modal-metadata__content mb-2">
    <div class="form-group">
      <input
        autocomplete="off"
        name="title"
        placeholder="Título"
        class="form-control"
        formControlName="title"
      />
    </div>

    <div class="form-group">
      <input
        autocomplete="off"
        name="description"
        placeholder="Descrição"
        class="form-control"
        formControlName="description"
      />
    </div>
  </div>

  <div class="modal-metadata__footer">
    <button type="button" class="btn btn-outline-danger" (click)="onCancel()">
      Cancelar
    </button>

    <button class="btn btn-secondary" type="button" (click)="onSubmit()">
      Salvar
    </button>
  </div>
</form>
