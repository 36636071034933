import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { ChargeComponent } from './components/charge/charge.component';
import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';
import { RegisterComponent } from './pages/register/register.component';

@NgModule({
  declarations: [
    LandingComponent,
    RegisterComponent,
    PaymentCardComponent,
    ChargeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    LandingRoutingModule,
    CreditCardDirectivesModule,
    Ng2TelInputModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingModule {}
