import { Routes } from '@angular/router';
import { LayoutManager } from '@app/shared/layouts';
import { CustomSubjectMailComponent } from './pages/custom-subject-mail/custom-subject-mail.component';
import { ManagerRoutingPath } from './manager-routing.path';
import { ActivitiesPageComponent, ContactsPageComponent, DashboardPageComponent, DepartmentsPageComponent, DocumentsPageComponent, ProfilePageComponent, PlansPageComponent, UsersPageComponent, WorkflowPageComponent, ManagePageComponent, DetailPageComponent, TeamsPageComponent, MembersPageComponent, FoldersPageComponent, WorkflowListPageComponent, CheckSignComponent, CheckSignCodeResultComponent, BuildPageComponent, ReviewPageComponent, FinishPageComponent, TemplatesPageComponent, WorkflowReportComponent, SignatureInPersonComponent } from './pages';
var ɵ0 = {
    isReceived: true
};
var routes = [
    LayoutManager.childRoutes([
        {
            path: ManagerRoutingPath.MANAGER,
            redirectTo: ManagerRoutingPath.MANAGER + "/" + ManagerRoutingPath.MANAGER_DASHBOARD
        },
        {
            path: ManagerRoutingPath.MANAGER,
            children: [
                {
                    path: ManagerRoutingPath.CHECK_SIGN,
                    component: CheckSignComponent
                },
                {
                    path: ManagerRoutingPath.CODE_RESULT,
                    component: CheckSignCodeResultComponent
                },
                {
                    path: ManagerRoutingPath.MANAGER_DASHBOARD,
                    component: DashboardPageComponent
                },
                {
                    path: ManagerRoutingPath.MANAGER_WORKFLOW,
                    component: WorkflowPageComponent,
                    children: [
                        {
                            path: '',
                            component: BuildPageComponent
                        },
                        {
                            path: ManagerRoutingPath.MANAGER_WORKFLOW_REVIEW,
                            component: ReviewPageComponent
                        },
                        {
                            path: ManagerRoutingPath.MANAGER_WORKFLOW_FINISH,
                            component: FinishPageComponent
                        }
                    ]
                },
                {
                    path: ManagerRoutingPath.MANAGER_MANAGE,
                    component: ManagePageComponent,
                    children: [
                        {
                            path: '',
                            pathMatch: 'full',
                            redirectTo: "/" + ManagerRoutingPath.MANAGER + "/" + ManagerRoutingPath.MANAGER_MANAGE + "/" + ManagerRoutingPath.MANAGER_MANAGE_WORKFLOW
                        },
                        {
                            path: ManagerRoutingPath.MANAGER_MANAGE_WORKFLOW,
                            component: WorkflowListPageComponent,
                            data: ɵ0
                        },
                        {
                            path: ManagerRoutingPath.MANAGER_MANAGE_WORKFLOW + "/:area",
                            component: WorkflowListPageComponent
                        },
                        {
                            path: ManagerRoutingPath.TEMPLATES,
                            component: TemplatesPageComponent
                        },
                        {
                            path: ManagerRoutingPath.CONTACTS,
                            component: ContactsPageComponent
                        },
                        {
                            path: ManagerRoutingPath.FOLDERS,
                            component: FoldersPageComponent
                        },
                        {
                            path: ManagerRoutingPath.WORKFLOW_REPORT,
                            component: WorkflowReportComponent
                        },
                        {
                            path: ManagerRoutingPath.SIGNATURE_IN_PERSON,
                            component: SignatureInPersonComponent
                        }
                    ]
                },
                {
                    path: ManagerRoutingPath.ACTIVITIES_REPORT,
                    component: ActivitiesPageComponent
                },
                {
                    path: ManagerRoutingPath.PROFILE,
                    component: ProfilePageComponent
                },
                {
                    path: ManagerRoutingPath.PLANS,
                    component: PlansPageComponent
                },
                {
                    path: ManagerRoutingPath.USERS,
                    component: UsersPageComponent
                },
                {
                    path: ManagerRoutingPath.CUSTOM_SUBJECT_MAIL,
                    component: CustomSubjectMailComponent
                },
                {
                    path: ManagerRoutingPath.DOCUMENTS,
                    component: DocumentsPageComponent
                },
                {
                    path: ManagerRoutingPath.MANAGER_WORKFLOW_DETAIL,
                    component: DetailPageComponent
                },
                {
                    path: ManagerRoutingPath.MANAGER_WORKFLOW_STEP_DETAIL,
                    component: DetailPageComponent
                },
                {
                    path: ManagerRoutingPath.DEPARTMENTS,
                    component: DepartmentsPageComponent
                },
                {
                    path: ManagerRoutingPath.TEAMS,
                    component: TeamsPageComponent
                },
                {
                    path: ManagerRoutingPath.MEMBERS,
                    component: MembersPageComponent
                }
            ]
        }
    ])
];
var ManagerRoutingModule = /** @class */ (function () {
    function ManagerRoutingModule() {
    }
    return ManagerRoutingModule;
}());
export { ManagerRoutingModule };
export { ɵ0 };
