import * as tslib_1 from "tslib";
import { WORKFLOW_STEP_FIELDS, WORKFLOW_SIGNATURE } from '@app/core/services/workflow.enum';
import { EletronicSignatureField } from './eletronic-signature-field.class';
var InPersonSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(InPersonSignatureField, _super);
    function InPersonSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
        _this.signatureType = WORKFLOW_SIGNATURE.IN_PERSON;
        return _this;
    }
    return InPersonSignatureField;
}(EletronicSignatureField));
export { InPersonSignatureField };
