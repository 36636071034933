<div class="documents-list">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-5 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-search"></i></div>
            </div>
            <input
              [(ngModel)]="searchField"
              (keyup)="searchDebounce()"
              type="text"
              class="form-control"
              id="inlineFormInputGroupUsername"
              placeholder="Pesquise aqui"
            />
          </div>
        </div>

        <div class="col-12 col-md-7 filters">
          <span class="filter-label"> Filtros: </span>

          <!-- documento recebido -->
          <ng-container *ngIf="checkCurrentArea()">
            <select
              [(ngModel)]="yourStatusStep"
              (change)="search()"
              class="form-control"
            >
              <option
                [value]="config.value"
                *ngFor="let config of yourStatusStepConfig"
                >{{ config.label }}</option
              >
            </select>

            <select
              [(ngModel)]="statusWorkflow"
              (change)="search()"
              class="form-control"
            >
              <option
                [value]="config.value"
                *ngFor="let config of statusWorkflowConfig"
                >{{ config.label }}</option
              >
            </select>

            <select
              [(ngModel)]="searchPeriod"
              (change)="search()"
              class="form-control"
            >
              <!-- Valores equivalentes a dias -->
              <option value="">Período</option>
              <option value="1">1 dia</option>
              <option value="7">1 semana</option>
              <option value="30">1 mês</option>
            </select>
          </ng-container>

          <!-- documento enviado -->
          <ng-container *ngIf="!checkCurrentArea()">
            <select
              [(ngModel)]="searchStatus"
              (change)="search()"
              class="form-control"
            >
              <option disabled>Status</option>
              <option
                [value]="config.value"
                *ngFor="let config of statusFilterConfig"
                >{{ config.label }}</option
              >
            </select>

            <select
              [(ngModel)]="searchPeriod"
              (change)="search()"
              class="form-control"
            >
              <option disabled>Período</option>
              <!-- Valores equivalentes a dias -->
              <option value="">Todos</option>
              <option value="1">1 dia</option>
              <option value="7">1 semana</option>
              <option value="30">1 mês</option>
            </select>
          </ng-container>
        </div>
      </div>
      <div class="document-table">
        <app-lists
          [collection]="collectionDocument.items"
          [fields]="configList"
          [pagination]="pagination"
          [sortableFields]="sortFields"
          [enableDetails]="true"
          [loadingDetails]="loadingDetails"
          [dataDetails]="details"
          (selected)="getSelectedItems($event)"
          (choosed)="getClickedItem($event)"
          (activity)="doAction($event)"
          (sort)="doSort($event)"
          (doChangePage)="onChangePage($event)"
        ></app-lists>
      </div>
    </div>
  </div>
</div>

<ng-template #modalCancel let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="true"
    [showClose]="false"
    [showBtnCancel]="true"
    [showBtnSave]="true"
    [disableBtnSave]="false"
    [btnClassCancel]="'btn-outline-success'"
    [btnActionCancel]="'Não'"
    [btnActionSave]="'Sim'"
    [title]="'Aviso!'"
  >
    <p>Deseja realmente cancelar este workflow?</p>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
