import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService, WorkflowBuildService } from '@app/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workflow-finish-page',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishPageComponent implements OnInit, OnDestroy {
  public workflowId: number = 0;

  public buildURL: string = '/manager/workflow';
  public detailURL: string = '/manager/manage/workflow/detail/';

  constructor(
    protected authorizationService: AuthorizationService,
    protected workflowBuildService: WorkflowBuildService,
    protected router: Router
  ) {}

  public ngOnInit(): void {
    this.workflowId = this.workflowBuildService.getWorkflowId();

    if (!this.workflowId) {
      this.navigateToWorkflowBuild();
    }

    const user = this.authorizationService.getUser();
    if (user.itens === undefined) {
      user.itens = 1;
    } else {
      user.itens = user.itens + 1;
    }

    this.authorizationService.setUser(user);
  }

  public ngOnDestroy(): void {
    this.workflowBuildService.setWorkflowId(null);
  }

  public navigateToWorkflowBuild(): void {
    this.router.navigate([this.buildURL], { queryParamsHandling: 'merge' });
  }
}
