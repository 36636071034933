import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
/**
 * Provides a base for choose plan workflow.
 */
var PlansService = /** @class */ (function (_super) {
    tslib_1.__extends(PlansService, _super);
    function PlansService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/plan';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    PlansService.prototype.getPlans = function () {
        var _this = this;
        return _super.prototype.getAll.call(this).pipe(map(function (response) {
            return _this.parsePlans(response);
        }));
    };
    PlansService.prototype.parsePlans = function (data) {
        var _this = this;
        var plans = [];
        if (data.total_items > 0) {
            plans = data._embedded.plan.map(function (plan) {
                var price = _this.parsePrice(plan.price);
                var cents = _this.parseCents(plan.price);
                var description = _this.formatText(plan.description);
                var id = plan.id, name = plan.name, shortDescription = plan.shortDescription, type = plan.type, usagePerUser = plan.usagePerUser, usageGlobal = plan.usageGlobal, usageAmount = plan.usageAmount, maxUsers = plan.maxUsers, paymentMethod = plan.paymentMethod, currency = plan.currency, billingInterval = plan.billingInterval, billingIntervalCount = plan.billingIntervalCount, billingType = plan.billingType, billingDay = plan.billingDay, boletoDueDays = plan.boletoDueDays, statementDescriptor = plan.statementDescriptor, status = plan.status, isDefault = plan.isDefault;
                return {
                    id: id,
                    name: name,
                    shortDescription: shortDescription,
                    description: description,
                    usageGlobal: usageGlobal,
                    usageAmount: usageAmount,
                    maxUsers: maxUsers,
                    paymentMethod: paymentMethod,
                    billingInterval: billingInterval,
                    price: price,
                    cents: cents,
                    status: status,
                    isDefault: isDefault
                };
            });
        }
        return {
            plan: plans,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items
        };
    };
    PlansService.prototype.subscription = function (payload) {
        var _this = this;
        return this.httpClient
            .post('/plan-subscription', this.mapFields(payload))
            .pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    PlansService.prototype.cancelPlan = function (id) {
        return this.httpClient.delete('/plan-subscription/' + id).pipe(function (body) {
            return body;
        });
    };
    PlansService.prototype.formatText = function (text) {
        var re = /\s*;\s*/;
        return text.split(re);
    };
    PlansService.prototype.parsePrice = function (price) {
        return price.slice(0, -2);
    };
    PlansService.prototype.parseCents = function (price) {
        return price.slice(-2);
    };
    return PlansService;
}(BaseAPI));
export { PlansService };
