<div class="page page-plans">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="plan-data">
          <app-profile-data
            [logged]="logged"
            [photo]="photo"
            [planInfo]="planInfo"
          >
          </app-profile-data>
          <br />
        </div>
      </div>
      <div class="col-12 col-md-8" *ngIf="!planChoosed">
        <div class="box">
          <div class="box-content">
            <div class="row">
              <div class="col-12">
                <span style="color: #168878; font-weight: bold;"
                  >Planos e Serviços</span
                >
                <hr style="margin-top: 6px;" />
              </div>
              <div class="col-12">
                <span style="color: #606060;">
                  Veja abaixo um resumo dos nossos planos e escolha um que se
                  encaixe com suas necessidades. Caso tenha alguma dúvida por
                  favor entre em contato.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" style="padding: 0;">
          <div class="row">
            <app-plan-detail
              [data]="plan"
              [user]="logged"
              [currentPlan]="planInfo"
              class="col-12 col-sm-6 col-md-6 col-lg-4"
              style="margin-top: 15px;"
              (planChoosed)="changePlan(plan)"
              *ngFor="let plan of data"
            ></app-plan-detail>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8" *ngIf="planChoosed">
        <div class="box">
          <div class="box-content">
            <div class="row">
              <div class="col-12">
                <span style="color: #606060">Dados do plano</span>
                <hr style="margin-top: 6px;" />
              </div>
              <div class="col-12">
                <span style="color: #168878;font-size: 22px;font-weight: bold;">
                  {{ choosedPlan.name }}
                </span>
              </div>
              <div class="col-8">
                <span style="color: #606060"
                  >Diminua os custos e a burocracia do seu negócio</span
                >
              </div>
              <div class="col-4" style="text-align: right;">
                <span
                  style="color: #606060;text-decoration: underline;cursor: pointer;"
                  (click)="choosePlan()"
                  >Trocar plano</span
                >
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="box" *ngIf="!isOnFeedback">
          <div class="box-content">
            <app-credit-card
              [logged]="logged"
              [countryList]="countryList"
              [stateList]="stateList"
              (infoFilled)="pay($event)"
            >
            </app-credit-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalCardFeedBack let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="cancel">
      <div class="row">
        <div class="col-12">
          <p>{{ feedBack | translate }}</p>
        </div>

        <div class="col-12 text-center">
          <button class="btn btn-danger btn-sm" (click)="modal.close()">
            Ok
          </button>
        </div>
      </div>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalFeedbackPayment let-modal>
  <app-payment-feedback [modal]="modal" [plan]="choosedPlan">
  </app-payment-feedback>
</ng-template>
