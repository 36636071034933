import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { ContactService } from './contact.service';
var FolderVisibilityService = /** @class */ (function (_super) {
    tslib_1.__extends(FolderVisibilityService, _super);
    function FolderVisibilityService(httpClient, contactService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.contactService = contactService;
        _this.endpoint = '/folder-visibility';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    FolderVisibilityService.prototype.getAll = function (id) {
        return this.httpClient
            .get(this.endpoint +
            '?filter[0][type]=eq&filter[0][field]=folder&filter[0][value]=' +
            id)
            .pipe(map(function (response) {
            return response;
        }));
    };
    FolderVisibilityService.prototype.shareFolderWithOrganization = function (folderId) {
        var payload = {
            type: 0,
            idFolder: folderId
        };
        return this.httpClient.post(this.endpoint, payload).pipe(map(function (response) {
            return response;
        }));
    };
    FolderVisibilityService.prototype.shareFolderWithEmail = function (folderId, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contact, payload;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contactService
                            .getContactByEmail(email)
                            .toPromise()];
                    case 1:
                        contact = _a.sent();
                        if (!(contact === null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.contactService
                                .addContact({
                                name: email,
                                email: email
                            })
                                .toPromise()];
                    case 2:
                        contact = _a.sent();
                        _a.label = 3;
                    case 3:
                        payload = {
                            type: 2,
                            idFolder: folderId,
                            idUser: contact.userId
                        };
                        return [2 /*return*/, this.httpClient.post(this.endpoint, payload).toPromise()];
                }
            });
        });
    };
    FolderVisibilityService.prototype.shareFolderWithContact = function (folderId, contactId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_a) {
                payload = {
                    type: 2,
                    idFolder: folderId,
                    idUser: contactId
                };
                return [2 /*return*/, this.httpClient.post(this.endpoint, payload).toPromise()];
            });
        });
    };
    FolderVisibilityService.prototype.removeSharingRule = function (shareId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.httpClient.delete(this.endpoint + '/' + shareId).toPromise()];
            });
        });
    };
    FolderVisibilityService.prototype.setFolderVisibility = function () {
        var payload = {
            name: 'default',
            path: 'default'
        };
        return _super.prototype.create.call(this, payload).pipe(map(function (response) {
            return response;
        }));
    };
    FolderVisibilityService.prototype.getFolderVisibility = function (id) {
        return _super.prototype.delete.call(this, id).pipe(map(function (response) {
            return response;
        }));
    };
    FolderVisibilityService.prototype.getContact = function (email) {
        var query = '/contact?filter[0][type]=innerjoin&filter[0][field]=userTwo&filter[0][alias]=u&filter[1][type]=like&filter[1][alias]=u&filter[1][field]=email&filter[1][value]=%' +
            email +
            '%';
        var user = this.httpClient.get(query).pipe(map(function (response) {
            return response;
        }));
        return user;
    };
    return FolderVisibilityService;
}(BaseAPI));
export { FolderVisibilityService };
