<div class="form-share-folder form-modal p-0 mt-0" *ngIf="!isLoading">
  <div class="mb-4" *ngIf="businessUnit">
    <h4>Organização</h4>
    <div>
      <label class="switch" style="vertical-align: middle;">
        <input
          type="checkbox"
          [(ngModel)]="organizationShare"
          (change)="updateOrganizationShare($event)"
        />
        <span class="slider round"></span>
      </label>
      <span style="vertical-align: middle;">{{ businessUnit.name }}</span>
    </div>
  </div>
  <div>
    <h4>Contatos</h4>
    <app-contacts-add
      (addContact)="addContact($event)"
      (removeContact)="removeContact($event)"
      [selectedContacts]="contacts"
      [blackList]="contactsBlackList"
    ></app-contacts-add>
  </div>
  <div class="col-12 text-center mt-3 mb-0">
    <button
      class="btn btn-default"
      type="button"
      (click)="modal.close('cancel')"
    >
      Fechar
    </button>
  </div>
</div>
<div class="text-center" *ngIf="isLoading" style="font-size: .9rem">
  Carregando..
</div>
