import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { workflowReport } from '../models';
import { WORKFLOW_STEP_REPORT, WORKFLOW_TEXT_STATUS } from './workflow.enum';

@Injectable()
export class WorkflowReportService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/workflow-report';

    this.apiFieldsMap = {};

    this.fieldsRequired = [];
  }

  getFileCsv(payload: workflowReport.FormPayload): Observable<any> {
    return this.httpClient.get(
      `/workflow-report?period=${payload.period}&status=${
        payload.status
      }&download=${payload.download}&received=${payload.received}`,
      { responseType: 'blob' as 'json' }
    );
  }

  getReport(
    payload: workflowReport.FormPayload
  ): Observable<workflowReport.ReportCollection> {
    return this.httpClient
      .get(
        `/workflow-report?period=${payload.period}&status=${
          payload.status
        }&download=${payload.download}&received=${payload.received}`
      )
      .pipe(
        map((body: any) => {
          return this.parseWorkflowReport(body);
        })
      );
  }

  private parseWorkflowReport(data: any): workflowReport.ReportCollection {
    let report: workflowReport.ReportResponse[] = [];

    if (data.total_items > 0) {
      report = data._embedded.workflow_report.map((report: any) => {
        const { autoRemind, id, message, name, priority } = report;

        return {
          autoRemind,
          id,
          message,
          name,
          priority,
          status: WORKFLOW_TEXT_STATUS[report.status],
          workflowSteps: this.getMappedWorkflowStep(report.workflowSteps).join(
            ' | '
          )
        };
      });
    }
    return {
      report: report,
      page: data.page,
      page_count: data.page_count,
      page_size: data.page_size,
      total_items: data.total_items
    };
  }

  private getMappedWorkflowStep(workflowStep: []): string[] {
    return workflowStep.map((step: any) => {
      return `${this.firstName(step.user.name)} - ${
        WORKFLOW_STEP_REPORT[step.status].text
      }`;
    });
  }

  private firstName(data: string): any {
    const name = data.split(' ', 1);
    return name;
  }
}
