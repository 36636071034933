/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../droppable-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inline-signature.component";
var styles_WorkflowDroppableInlineSignatureFieldComponent = [i0.styles];
var RenderType_WorkflowDroppableInlineSignatureFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowDroppableInlineSignatureFieldComponent, data: {} });
export { RenderType_WorkflowDroppableInlineSignatureFieldComponent as RenderType_WorkflowDroppableInlineSignatureFieldComponent };
export function View_WorkflowDroppableInlineSignatureFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "field-block field-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "simple-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "signature-type-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assinatura Eletr\u00F4nica"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact.email; _ck(_v, 13, 0, currVal_0); }); }
export function View_WorkflowDroppableInlineSignatureFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-droppable-inline-signature-field", [], null, null, null, View_WorkflowDroppableInlineSignatureFieldComponent_0, RenderType_WorkflowDroppableInlineSignatureFieldComponent)), i1.ɵdid(1, 114688, null, 0, i2.WorkflowDroppableInlineSignatureFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowDroppableInlineSignatureFieldComponentNgFactory = i1.ɵccf("app-workflow-droppable-inline-signature-field", i2.WorkflowDroppableInlineSignatureFieldComponent, View_WorkflowDroppableInlineSignatureFieldComponent_Host_0, { field: "field" }, {}, []);
export { WorkflowDroppableInlineSignatureFieldComponentNgFactory as WorkflowDroppableInlineSignatureFieldComponentNgFactory };
