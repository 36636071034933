import { EventEmitter, Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';

@Injectable()
export class CookiesService {
  public sign = new EventEmitter();
  private cookieKey: string = '__accept_policy';

  constructor() {}

  public set(cookieKey: string, value: string, day?: number): void {
    Cookies.set(cookieKey, value, { expires: day });
    this.signCookie(this.cookieKey);
  }

  public get(cookieKey: string): string {
    return Cookies.get(cookieKey);
  }

  public remove(cookieKey: string): void {
    Cookies.remove(cookieKey);
  }

  public signCookie(event: string): void {
    const value = this.get(event);
    if (value !== undefined && value === 'true') {
      this.sign.emit(true);
      return;
    }
    this.sign.emit(false);
  }

  public getCookieValue(cookieKey: string): boolean {
    const value = this.get(cookieKey);
    if (value !== undefined && value === 'true') {
      return true;
    }
    return false;
  }
}
