import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Folder } from '@app/core/models';
import { AuthorizationService } from 'app/core/services';

@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss']
})
export class FolderListComponent {
  /**
   * Collection with data for list
   */
  @Input() collection: Array<any>;

  /**
   * Callback with action and items
   */
  @Output() activity? = new EventEmitter();

  /**
   * Pagination
   */
  @Input() pagination: any;

  /**
   * Callback with change page
   */
  @Output() doChangePage? = new EventEmitter();

  @Input() integrationWithFolder: boolean;

  user: any;

  constructor(private authorizationService: AuthorizationService) {
    this.pagination = {};
    this.user = this.authorizationService.getUser();
  }

  public doPage(p: number): void {
    this.doChangePage.emit(p);
  }

  public goAction(type: string, obj: any, isOwner: Boolean): void {
    obj.isOwner = isOwner;
    this.activity.emit({ type: type, data: obj });
  }

  public shouldCheckIntegrationFolder(): boolean {
    return this.integrationWithFolder;
  }

  public doAction(folder: Folder.Folder, action: string) {
    switch (action) {
      case 'change':
        return folder.folderIntegration !== null;

      case 'create':
        return folder.folderIntegration === null;

      case 'delete':
        return folder.folderIntegration !== null;

      default:
        return false;
    }
  }
}
