<div class="payment-feedback-template">
  <img src="assets/images/feedback-check.png" />
  <p class="feedback-title">Parabéns!</p>
  <p class="feedback-title">Seu pedido foi concluído com sucesso</p>
  <p>
    A partir de agora você pode enviar documentos com mais agilidade, segurança
    e economia.
  </p>
  <div class="p-4 mt-5">
    <button (click)="profile()" class="btn btn-secondary">Ok</button>
  </div>
</div>
