<div *ngIf="step === 0">
  <h3>Esqueceu sua senha?</h3>
  <p>Você receberá um código em seu e-mail.</p>

  <form (ngSubmit)="doAction()" [formGroup]="forgetPasswordForm" novalidate>
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      E-mail ou telefone incorreto.
    </div>
    <div class="form-group">
      <label class="d-block">
        <input
          type="text"
          class="form-control"
          formControlName="email"
          [placeholder]="'E-mail' | translate"
        />
        <small
          [hidden]="
            forgetPasswordForm.controls.email.valid ||
            forgetPasswordForm.controls.email.untouched
          "
          class="text-danger"
          translate
        >
          E-mail é necessário
        </small>
      </label>
    </div>
    <p class="text-right">
      <a routerLink="/auth/login">Login</a> |
      <a routerLink="/auth/confirm-account/resend"
        >Reenviar e-mail de confirmação</a
      >
    </p>
    <button
      class="btn btn-secondary btn-sm"
      type="submit"
      [disabled]="forgetPasswordForm.invalid || isLoading"
    >
      <i class="fas fa-spinner fa-spin" [hidden]="!isLoading"></i>
      <span translate>Recuperar senha</span>
    </button>
  </form>
</div>

<div *ngIf="step === 1">
  <h3>Preencha o código</h3>
  <p>Informe a sua nova senha e o código enviado para seu e-mail.</p>

  <form (ngSubmit)="doActionCode()" [formGroup]="resetPasswordForm" novalidate>
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      {{ error }}
    </div>
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="code-prev"
            >{{ apiDetails?.pinPrefix }}-</span
          >
        </div>
        <input
          type="text"
          name="pin"
          class="form-control"
          formControlName="code"
          [placeholder]="'Código' | translate"
          autocomplete="false"
        />
      </div>
      <p
        [hidden]="
          resetPasswordForm.controls.code.valid ||
          resetPasswordForm.controls.code.untouched
        "
        class="text-danger"
        translate
      >
        O código é necessário
      </p>
    </div>

    <div class="form-group">
      <label class="d-block">
        <input
          type="password"
          class="form-control"
          formControlName="password"
          [placeholder]="'Nova Senha' | translate"
        />
        <small
          [hidden]="
            resetPasswordForm.controls.password.valid ||
            resetPasswordForm.controls.password.untouched
          "
          class="text-danger"
          translate
        >
          Senha é necessário.
        </small>
      </label>
    </div>
    <div class="form-group">
      <label class="d-block">
        <input
          type="password"
          class="form-control"
          formControlName="repeatPassword"
          [placeholder]="'Confirme sua nova senha' | translate"
        />
        <small
          [hidden]="
            resetPasswordForm.controls.repeatPassword.valid ||
            resetPasswordForm.controls.repeatPassword.untouched ||
            !resetPasswordForm.controls.repeatPassword.errors.unmatched
          "
          class="text-danger"
          translate
        >
          A confirmação deve ser igual a senha
        </small>
      </label>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <span class="password-message"
          >Sua senha deve conter no mínimo 6 dígitos com letras maiúsculas,
          minúsculas e números.</span
        >
      </div>
    </div>

    <button
      class="btn btn-secondary btn-sm"
      type="submit"
      [disabled]="resetPasswordForm.invalid || isLoading"
    >
      <i class="fas fa-spinner fa-spin" [hidden]="!isLoading"></i>
      <span translate>Alterar senha</span>
    </button>

    <a href="/auth/forget-password" class="btn btn-sm btn-outline-success"
      >Informar outro E-mail.</a
    >
  </form>
</div>

<div *ngIf="step === 2">
  <h3>Senha alterada com sucesso!</h3>
  <p>Efetue o login com sua nova senha.</p>
  <p>&nbsp;</p>

  <a
    routerLink="/auth"
    [queryParams]="{ u: forgetPasswordForm.controls.email.value }"
    class="btn btn-sm btn-secondary"
    >Fazer login</a
  >
</div>
