import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';

@Injectable()
export class CustomSubjectMailService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/custom-subject-notification';

    this.apiFieldsMap = {};

    this.fieldsRequired = ['subject', 'event'];
  }
}
