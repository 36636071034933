import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeOfSignature } from '@app/core/models';
import { SIGNATURE_TYPE } from '@app/core/services/workflow.enum';
@Component({
  selector: 'app-visible-signature',
  templateUrl: './visible-signature.component.html',
  styleUrls: ['./visible-signature.component.scss']
})
export class VisibleSignatureComponent implements OnInit {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of user
   */
  @Input() data?: any;

  /**
   * Text of button save
   */
  @Input() btnSaveText?: string = 'Salvar';

  /**
   * Text of button save
   */
  @Input() showInfoText?: boolean = true;

  /**
   * Receive data signature
   */
  @Input() dataSign: Object;

  /**
   * Select subscription type
   */
  @Input() showSignature: string;

  /**
   * Full name orders to choose signature
   */
  @Output() fullName: string;

  /**
   * Data of Form
   */
  signatureForm: FormGroup;

  /**
   * Type of signature
   */
  @Input() typeOfSignature: TypeOfSignature.Payload;

  constructor(private formBuilder: FormBuilder) {
    this.showSignature = 'choose';
    this.createForm();
  }

  ngOnInit() {
    if (this.checkRubricType()) {
      if (this.data && this.data.name) {
        this.signatureForm.controls['name'].setValue(
          this.data.name.substr(0, 2)
        );
        this.fullName = this.data.name.substr(0, 2);
      }
      this.showInfoText = false;
      return;
    }

    if (this.data && this.data.name) {
      this.signatureForm.controls['name'].setValue(
        this.data.name.substr(0, 30)
      );
      this.fullName = this.data.name;
    }

    if (this.checkStampType()) {
      this.showSignature = 'upload';
    }
  }

  numberColor(value: number) {
    if (value < 15) {
      return 'success';
    }

    if (value < 27) {
      return 'warning';
    }

    return 'danger';
  }

  /**
   * receives event with subscription
   */
  typeSignature(event: Event) {
    this.dataSign = event;
    this.signatureForm.controls['type'].setValue(
      this.dataSign['dataSignature'].type
    );
  }

  doSubmit() {
    if (this.checkRubricType()) {
      this.signatureForm.setValue({
        name: this.f.name.value,
        font: this.dataSign['dataSignature'].sign,
        type: SIGNATURE_TYPE.RUBRIC
      });
    }

    if (this.checkStampType()) {
      this.signatureForm.setValue({
        name: this.dataSign['dataSignature'].sign,
        font: 'Arial.ttf',
        type: SIGNATURE_TYPE.STAMP
      });
    }

    if (this.signatureForm.get('type').value === SIGNATURE_TYPE.GENERATING) {
      this.signatureForm.get('name').setValue(this.f.name.value);
      this.signatureForm
        .get('font')
        .setValue(this.dataSign['dataSignature'].sign);
    }

    if (
      !this.checkRubricType() &&
      !this.checkStampType() &&
      this.signatureForm.get('type').value !== SIGNATURE_TYPE.GENERATING
    ) {
      this.signatureForm.controls['name'].setValue(
        this.dataSign['dataSignature'].sign
      );
    }

    this.modal.close({
      action: 'save',
      form: 'signature',
      formData: this.signatureForm.value
    });
  }

  checkRubricType() {
    return this.typeOfSignature.sign === SIGNATURE_TYPE.RUBRIC;
  }

  checkStampType() {
    return this.typeOfSignature.sign === SIGNATURE_TYPE.STAMP;
  }

  isValidateFeedback() {
    if (this.f.name.errors && this.f.name.errors.maxlength) {
      return (
        !this.f.name.errors.maxlength.actualLength >
        this.f.name.errors.maxlength.equiredLength
      );
    }
    return true;
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.signatureForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.signatureForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(30)
        ])
      ],
      font: [''],
      type: ['', Validators.required]
    });
  }
}
