import { WorkflowContact } from '@app/core/class';
import {
  WORKFLOW_STEP_FIELDS,
  WORKFLOW_SIGNATURE
} from '@app/core/services/workflow.enum';
import { EletronicSignatureField } from './eletronic-signature-field.class';

export class InPersonSignatureField extends EletronicSignatureField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
    this.signatureType = WORKFLOW_SIGNATURE.IN_PERSON;
  }
}
