import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CheckerRoutingModule } from './checker-routing.module';
import { CheckerComponent } from './checker.component';

@NgModule({
  declarations: [CheckerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckerRoutingModule,
    SharedModule,
    TranslateModule
  ]
})
export class CheckerModule {}
