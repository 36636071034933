import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

import { ContactService } from '@app/core/services';
import { Contact } from '@app/core/models';
import { Logger } from '@app/core/class';
import { AlertSubscription } from '@app/core/subscriptions';

const log = new Logger('ContactsPage');

@Component({
  selector: 'app-contacts-page',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsPageComponent implements OnInit {
  /**
   * template of modal for loading
   */
  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * template of modal for create contact
   */
  @ViewChild('modalCreateContact') modalCreateContact: ElementRef;

  /**
   * template of modal for delete contact
   */
  @ViewChild('modalDeleteContact') modalDeleteContact: ElementRef;

  /**
   * collection with data contact
   */
  collection: Array<Contact.FormPayload>;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current user for edit/delete
   */
  selected: any;

  /**
   * modal
   */
  modalRef: any;

  modalTitle: string;
  pagination: any;
  currentPage: number;
  currentRoute: string;

  searchStatus: number | string = '';

  isLoading: boolean = false;

  /**
   * holds the string to search contacts by name
   */
  searchName = '';

  constructor(
    private contactService: ContactService,
    private modalService: NgbModal,
    private alertSubscription: AlertSubscription,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentRoute = '/manager/manage/contacts';
    this.modalRef = { ref: null, data: { text: '' } };

    this.pagination = {
      totalItems: 0,
      pageCount: 0,
      pageSize: 0,
      currentPage: 1
    };
  }

  ngOnInit() {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    this.route.queryParams.subscribe((params: any) => {
      if (params.page) {
        this.currentPage = params.page;
      }

      this.getContacts({
        page: params.page
      });
    });

    this.sortFields = { name: 'ASC', email: 'ASC', statusText: 'ASC' };
    this.configList = [
      /*{
        name: '',
        klass: '',
        type: 'checkbox',
        fieldId: 'id'
      },*/
      {
        name: 'Nome',
        type: 'text',
        fieldId: 'name',
        isSortable: false
      },
      {
        name: 'Email',
        type: 'text',
        fieldId: 'email',
        isSortable: false
      },
      {
        name: 'Situação',
        type: 'text',
        fieldId: 'statusText',
        isSortable: false
      },
      {
        name: 'Ações',
        type: 'custom-select',
        icon: 'fa fa-sliders-h',
        width: '5%',
        isRestrict: true,
        options: {
          items: [
            {
              name: 'Desabilitar contato',
              action: 'edit',
              canField: 'status',
              can: [1]
            },
            {
              name: 'Habilitar contato',
              action: 'edit',
              canField: 'status',
              can: [0]
            },
            {
              name: 'Excluir contato',
              action: 'delete',
              canField: 'status',
              can: [1]
            },
            {
              name: 'Excluir contato',
              action: 'delete',
              canField: 'status',
              can: [0]
            }
          ]
        }
      }
    ];
  }

  /**
   * provides the data of contacts
   *
   * @return void
   */
  getContacts(params: any = {}) {
    const filter: Array<object> = [];

    if (params.name) {
      filter.push({
        type: 'innerjoin',
        field: 'userTwo',
        alias: 'u'
      });

      filter.push({
        type: 'like',
        alias: 'u',
        field: 'name',
        value: `%${params.name}%`
      });
    }

    if (this.searchStatus) {
      filter.push({
        type: 'eq',
        field: 'status',
        value: this.searchStatus,
        direction: 'desc'
      });
    }

    const queryString = {
      page: params.page ? params.page : 1,
      filter
    };

    this.contactService.getAll(queryString).subscribe(
      (response: any) => {
        log.debug(`Success [getContacts]:`, response);
        this.collection = response.items;
        this.pagination = {
          totalItems: response.total_items,
          pageCount: response.page_count,
          pageSize: response.page_size,
          currentPage: response.page
        };
        if (this.modalRef.ref) {
          this.modalRef.ref.close();
        }
      },
      (error: any) => {
        log.debug(`Error [getContacts]:`, error);
        this.collection = [];
        this.modalRef.ref.close();
      }
    );
  }

  /**
   * Action grab all selected items
   *
   * @param (any) items selected
   */
  getSelectedItems(items: any) {
    this.selectedItems = items;
  }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    /*this.sortFields[field] = this.reorder(this.sortFields[field]);

    const queryString = {
      'order-by': [
        {
          type: 'field',
          field: '',
          direction: this.sortFields[field]
        }
      ],
      page: 1
    };

    if (field === 'name') {
      queryString['order-by'][0].field = 'name';
      this.getContacts(queryString);
    }
    if (field === 'statusText') {
      queryString['order-by'][0].field = 'status';
      this.getContacts(queryString);
    } else {
      queryString['order-by'][0].field = 'email';
      this.getContacts(queryString);
    }*/
  }

  /**
   * Action for change page
   *
   * @param (number) of page
   */
  onChangePage(p: number) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    const payload = {
      page: p
    };

    this.currentPage = payload.page;
    this.router.navigate([this.currentRoute], {
      replaceUrl: true,
      queryParams: payload
    });

    this.getContacts(payload);
  }

  doCreate() {
    this.selected = null;
    this.modalTitle = 'Criar Contato';
    this.open(this.modalCreateContact, 'md');
  }

  /**
   * Action grab data
   *
   * @param (any) items selected
   */
  doAction(items: any) {
    if (items.type === 'edit') {
      this.modalTitle = 'Editar Contato';
      this.selected = items.data;
      this.open(this.modalCreateContact, 'md');
    } else {
      this.modalTitle = 'Aviso!';
      this.selected = items.data;
      this.open(this.modalDeleteContact, 'md');
    }
  }

  /**
   * Action by clicking in submit user form
   *
   * @param (any) form with data
   */
  doSubmitForm(form: any) {
    const payload = {
      name: form.name,
      email: form.email
    };

    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    this.contactService.create(payload).subscribe(
      () => {
        this.alertSubscription.update({
          type: 'success',
          message: 'Contato criado com sucesso'
        });
        this.getContacts({ page: this.currentPage });
      },
      (error: any) => {
        this.alertSubscription.update({
          type: 'error',
          message: error.error.detail
        });
      }
    );
  }

  doUpdateContact(form: any) {
    const payload = {
      status: form.status
    };

    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    this.contactService.update(form.id, payload).subscribe(
      () => {
        this.alertSubscription.update({
          type: 'success',
          message: 'Contato atualizado com sucesso'
        });
        this.getContacts({ page: this.currentPage });
      },
      (error: any) => {
        this.alertSubscription.update({
          type: 'error',
          message: error.detail
        });
      }
    );
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any, size?: any) {
    this.modalRef.ref = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    this.modalRef.ref.result.then((result: any) => {
      if (result && result.action === 'save') {
        this.doSubmitForm(result.formData);
        return;
      }

      if (result && result.action === 'delete') {
        this.deleteMarkedContact(result.formData);
        return;
      }

      if (result && result.action === 'edit') {
        this.doUpdateContact(result.formData);
        return;
      }

      if (result && result === 'cancel') {
        return;
      }
    });
  }

  /**
   * delete marked contact
   *
   * @return void
   */
  deleteMarkedContact(form: any) {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );
    this.contactService.delete(form.id).subscribe(
      () => {
        this.alertSubscription.update({
          type: 'success',
          message: 'Contato excluido com sucesso'
        });
        this.getContacts({ page: this.currentPage });
      },

      (error: any) => {
        this.alertSubscription.update({
          type: 'error',
          message: error.detail
        });
      }
    );
  }

  getContactsByName() {
    if (this.searchName.length > 0) {
      this.getContacts({ name: this.searchName });
    } else {
      this.getContacts({});
    }
  }

  /**
   * action to reload the contact list
   *
   * @return void
   */
  reset() {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.open(this.loading);
      }).bind(this)
    );

    this.searchName = '';
    this.getContacts({});
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }
}
