import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Logger } from '@app/core/class';
import {
  AuthenticationService,
  AuthorizationService,
  UserService
} from '@app/core/services';
import { Authentication } from '@app/core/models';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: string;
  loginForm: FormGroup;
  isLoading = false;
  lastLocation: string;
  accountCreatedCallback: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.createForm();
  }

  /**
   * If the user is logged, navigate to /manager
   * If user is not logged, render login page and fill email field using the username information from localStorage
   */
  ngOnInit() {
    if (this.authorizationService.isAuthenticated()) {
      log.debug(`user logged, redirect to /manager`);
      window.location.href = 'manager';
      return;
    } else {
      combineLatest(this.authorizationService.user, this.route.queryParams)
        .pipe(map((userData, params) => ({ userData, params })))
        .subscribe((p: any) => {
          if (p.params && p.params.redirect) {
            this.lastLocation = p.params.redirect;

            if (p.params.redirect.indexOf('/subscribe') !== -1) {
              const token = p.params.redirect.replace('/subscribe/', '');
              const signData = JSON.parse(atob(token));

              /** Verifica se existe token de acesso, se sim faz login temporário do usuário */
              if (typeof signData.a === 'string') {
                this.authorizationService.setTempAccessToken(signData.a);
                return;
              }

              this.loginForm.controls.email.setValue(signData.u);
              return;
            }
          }

          if (p.params && p.params.u) {
            this.loginForm.controls.email.setValue(p.params.u);
            return;
          }

          if (p.userData) {
            this.loginForm.controls.email.setValue(p.userData.username);
            return;
          }
        });
    }
    if (this.route.snapshot.queryParams['register']) {
      this.accountCreatedCallback = true;
    }
  }

  login() {
    this.isLoading = true;
    this.error = undefined;

    try {
      this.authenticationService
        .login(this.loginForm.value)
        .pipe(
          finalize(() => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: Authentication.Credentials) => {
            if (response.status !== 200) {
              log.debug(`Login error: ${response.detail}`);
              this.error = response.detail;
              return false;
            }

            log.debug(`${response.access_token} successfully logged in`);

            this.userService.me().subscribe(() => {
              if (this.lastLocation) {
                location.assign(this.lastLocation);
              } else {
                location.assign('/manager');
              }
            });
          },
          (error: any) => {
            log.debug(`Login error: ${error}`);
            this.error = error;
          }
        );
    } catch (e) {
      this.error = e;
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
}
