import { Component } from '@angular/core';
import { ReviewPageComponent } from '@app/manager/pages';
import {
  FolderService,
  AuthorizationService,
  WorkflowBuildService,
  DocumentService,
  FeedbackService,
  WorkflowTemplateService,
  RemainingUserBalanceService
} from '@app/core/services';
import { Workflow } from '@app/core/models';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-workflow-integration-review-page',
  templateUrl:
    '../../../../manager/pages/workflow/review/review.component.html',
  styleUrls: ['../../../../manager/pages/workflow/review/review.component.scss']
})
export class IntegrationReviewPageComponent extends ReviewPageComponent {
  public showFolderSelector: boolean = false;
  public ShowSaveWorkflowTemplate: boolean = false;

  protected buildURL: string = '/integration/workflow';
  protected finishURL: string = '/integration/workflow/finish';

  constructor(
    protected formBuilder: FormBuilder,
    protected folderService: FolderService,
    protected authorizationService: AuthorizationService,
    protected workflowBuildService: WorkflowBuildService,
    protected workflowTemplateService: WorkflowTemplateService,
    protected documentService: DocumentService,
    protected feedbackService: FeedbackService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: NgbModal,
    protected remainingUserBalance: RemainingUserBalanceService
  ) {
    super(
      formBuilder,
      folderService,
      authorizationService,
      workflowBuildService,
      workflowTemplateService,
      documentService,
      feedbackService,
      router,
      modalService,
      remainingUserBalance
    );
  }

  protected fetchFolders(): void {
    return;
  }

  protected saveWorkflow(workflow: Workflow.Settings): void {
    const templateId = this.route.snapshot.queryParams['id'];
    const templateToken = this.route.snapshot.queryParams['token'];

    this.isSavingWorkflow = true;

    const subscription = this.workflowTemplateService
      .updateWorkflowTemplate(templateId, workflow, templateToken)
      .subscribe(
        (response: any) => {
          this.feedbackService.clear();
          this.isSavingWorkflow = false;
          this.workflowBuildService.setWorkflowId(templateId);
          this.workflowBuildService.resetWorkflow();
          subscription.unsubscribe();
          this.goToFinishPage();
        },
        (request: any) => {
          this.isSavingWorkflow = false;
          this.feedbackService.error(request.error.detail);
          subscription.unsubscribe();
        }
      );
  }
}
