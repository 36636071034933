import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { User, Authentication } from '@app/core/models';
import { RemainingUserBalanceService, UserService } from '@app/core/services';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PhotoSubscription } from '@app/core/subscriptions';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.scss']
})
export class ProfileDataComponent implements OnInit, OnChanges {
  /**
   * mocked data of the logged user
   */
  info: User.InfoPayload;

  @Input() planInfo: User.UserPlanPayload;

  /**
   * data of the logged user
   */
  @Input() logged: Authentication.SignupUserResponse;

  /**
   * photo of the logged user
   */
  @Input() photo: User.UserMetadata;

  /**
   * event emitter to update Feecback Component
   */
  @Output() setFeedbackMessage = new EventEmitter();

  /**
   * holds the file to be cropped
   */
  imageChangedEvent: any = '';

  /**
   * cropped image
   */
  croppedImage = '';

  /**
   * modal to load the cropper component
   */
  modal: NgbModalRef;

  processBarColor: string;
  planUser: User.InfoPlan;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private photoSubscription: PhotoSubscription,
    private remainingUserBalance: RemainingUserBalanceService
  ) {}

  ngOnInit() {
    this.remainingUserBalance.planUser.subscribe(
      (response: User.UserPlanPayload) => {
        this.planInfo = response;
      }
    );

    this.planUser = {
      nameUser: 'Nome do usuário',
      profile: 'Usuário',
      planName: 'Nome do Plano',
      usageGlobal: 0,
      usageRemainments: 0,
      usagePercentage: 0,
      planConsumption: 0,
      shortDescription: 'Descrição',
      processBarColor: 'success'
    };
  }

  calcProcessBarColor(value: number): string {
    if (value <= 50) {
      return 'success';
    }

    if (value < 75) {
      return 'warning';
    }

    return 'danger';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['planInfo']) {
      if (this.planInfo && this.logged.name) {
        this.planUser = {
          nameUser: this.logged.name,
          profile:
            this.logged.roleDefault === 'admin' ? 'Administrador' : 'Usuário',
          planName: this.planInfo.planType,
          usageGlobal: this.planInfo.globalSignaturesUsage,
          usageRemainments: this.planInfo.globalSignaturesRemainments,
          usagePercentage: this.planInfo.globalUsage,
          planConsumption:
            this.planInfo.globalSignaturesUsage -
            this.planInfo.globalSignaturesRemainments,
          shortDescription: this.planInfo.planShortDescription,
          processBarColor: this.calcProcessBarColor(this.planInfo.globalUsage)
        };
      }
    }
  }

  /**
   * action to open the default file chooser window
   * action to open a modal to display the crop component
   *
   * @param (any) modal content
   * @return void
   */
  openFileSelector(content: any) {
    document.getElementById('uploadFile').click();
    this.open(content);
  }

  /**
   * provides a action to load the file selected by the user on the cropper
   *
   * @param (any) event fired after selected a file on the file window picker
   * @return void
   */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  availableSignaturesPercentage() {
    if (!this.planInfo) {
      return 0;
    }

    const perc =
      (this.planInfo.signaturesRemainments / this.planInfo.signaturesPerUser) *
      100;

    if (perc > 100) {
      return 100;
    }

    return perc < 0 ? 0 : perc;
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any) {
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      keyboard: false,
      backdrop: 'static'
    });
  }

  /**
   * Action to grab the base64 cropped image
   *
   * @param (ImageCroppedEvent) Event dispatched after releasing the mouse button when cropping a image
   * @return void
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  /**
   * Action to create a User.UserMetadata object and post to the server
   *
   * @param ()
   * @return void
   */
  uploadFile() {
    const payload: User.UserMetadata = {
      dataKey: 'picture',
      dataValue: this.croppedImage
    };

    if (this.photo) {
      this.userService
        .updateMetadata(this.photo.id, payload)
        .subscribe(
          data => this._successOnUpdate(data),
          error => this._failOnUpdate(error)
        );
    } else {
      this.userService
        .createMetadata(payload)
        .subscribe(
          data => this._successOnUpdate(data),
          error => this._failOnUpdate(error)
        );
    }
  }

  /**
   * Action fired after a success on send User.UserMetadata
   *
   * @param (any) Payload returned from the server
   * @return void
   */
  _successOnUpdate(data: any) {
    this.photo = data;
    this.photoSubscription.update(this.photo.dataValue);

    this.setFeedbackMessage.emit({
      theme: 'success',
      message: 'Foto atualizada com sucesso'
    });

    this.modal.close();
  }

  /**
   * Action fired after an error on send User.UserMetadata
   *
   * @param (any) Error catched
   * @return void
   */
  _failOnUpdate(error: any) {
    this.setFeedbackMessage.emit({
      theme: 'error',
      message: error.error.detail
    });

    this.modal.close();
  }
}
