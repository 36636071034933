import * as tslib_1 from "tslib";
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableSharedQrCodeFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableSharedQrCodeFieldComponent, _super);
    function WorkflowDroppableSharedQrCodeFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowDroppableSharedQrCodeFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableSharedQrCodeFieldComponent };
