/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "./footer/footer.component.ngfactory";
import * as i6 from "./footer/footer.component";
import * as i7 from "../../../core/services/cookies.service";
import * as i8 from "./default.component";
var styles_LayoutDefaultComponent = [i0.styles];
var RenderType_LayoutDefaultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutDefaultComponent, data: {} });
export { RenderType_LayoutDefaultComponent as RenderType_LayoutDefaultComponent };
export function View_LayoutDefaultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-default-header", [], null, null, null, i2.View_LayoutDefaultHeaderComponent_0, i2.RenderType_LayoutDefaultHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutDefaultHeaderComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-layout-default-footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i6.FooterComponent, [i7.CookiesService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
export function View_LayoutDefaultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-default", [], null, null, null, View_LayoutDefaultComponent_0, RenderType_LayoutDefaultComponent)), i1.ɵdid(1, 114688, null, 0, i8.LayoutDefaultComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutDefaultComponentNgFactory = i1.ɵccf("app-layout-default", i8.LayoutDefaultComponent, View_LayoutDefaultComponent_Host_0, {}, {}, []);
export { LayoutDefaultComponentNgFactory as LayoutDefaultComponentNgFactory };
