import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State, Country } from '@app/core/models';
import {
  AuthenticationService,
  UserService,
  CountryService,
  StateService
} from '@app/core/services';
import { Logger } from '@app/core/class';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

const log = new Logger('ConfirmAccountPage');

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
  activated: boolean;

  /**
   * Data of business form
   */
  businessForm: FormGroup;

  /**
   * Data of personal form
   */
  personalForm: FormGroup;

  /**
   * Define if the new account is generated from an invite
   * Security token
   */
  token = '';

  /**
   * Hold a boolean to define if the new account is generated from an invite
   */
  invitation = false;

  /**
   * Hold a string to define which form is active
   */
  showForm = 'PERSONAL';

  /**
   * Array of states to use in the forms
   */

  stateList: Array<State.FormPayload>;

  /**
   * Array of country to use in the forms
   */

  countryList: Array<Country.FormPayload>;

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  resendConfirmation: boolean = false;

  resendConfirmationForm: FormGroup;

  isLoading: boolean = false;
  resendConfirmationFormError: any = false;
  resendConfirmationFormSuccess: any = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private countryService: CountryService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];

    if (this.token === 'resend') {
      this.resendConfirmation = true;
      this._createResendConfirmationForm();
      return false;
    }

    const payload = { token: this.token };
    const isInvitation = this.route.snapshot.queryParams['invitation']
      ? true
      : false;

    if (!isInvitation) {
      this.processToken(payload);
    } else {
      this.getRegisterData(payload);
    }
  }

  processToken(payload: any) {
    this.authenticationService.confirmAccount(payload).subscribe(
      () => {
        this.feedbackMessage = `Conta ativada com sucesso!`;
        this.feedbackTheme = 'success';
        this.activated = true;
      },
      (error: any) => {
        this.feedbackMessage = `Não foi possível ativar sua conta. Tente novamente.`;
        this.feedbackTheme = 'danger';
      }
    );
  }

  getRegisterData(payload: any) {
    this.authenticationService.getRegisterData(payload).subscribe(
      (body: any) => {
        this.invitation = true;

        this.personalForm.patchValue(body);

        const states = this.stateService.list();
        this.stateList = [Object.assign({ id: '', name: 'Estado' }, states)];

        const countries = this.countryService.list();
        this.countryList = [Object.assign({ id: '', name: 'País' }, countries)];
      },
      (error: any) => {
        this.invitation = false;
        this.feedbackMessage =
          `Não foi possível buscar as informações do usuário.` +
          `Tente novamente.`;
        this.feedbackTheme = 'danger';
      }
    );
  }

  /**
   * Form selection action
   */
  formSelected(type: string) {
    this.showForm = type;
  }

  /**
   * Nested forms inclusion
   */
  formInitialized(name: string, form: FormGroup) {
    this.personalForm = form;
  }

  /**
   * Form action to submit data using all nested forms to create a new user
   */
  doSubmit() {
    let target = {
      token: '',
      password: ''
    };

    target = Object.assign(target, this.personalForm.value);

    if (this.token) {
      target.token = this.token;
      this.userService.confirmRegister(target).subscribe(
        () => {
          this.feedbackMessage = `Conta ativada com sucesso!`;
          this.feedbackTheme = 'success';
          this.activated = true;
        },
        (err: any) => this._failOnAction(err.error.detail)
      );
    } else {
      delete target.token;
      this.userService
        .create(target)
        .subscribe(
          () => {},
          (err: any) => this._failOnAction(err.error.detail)
        );
    }
  }

  _failOnAction(message: string) {
    log.error(`Error is: ${message}`);
    this.feedbackMessage = `${message}!`;
    this.feedbackTheme = 'danger';
  }

  _createResendConfirmationForm() {
    this.resendConfirmationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  resendAction() {
    this.isLoading = true;
    this.resendConfirmationFormError = false;
    this.resendConfirmationFormSuccess = false;
    try {
      this.authenticationService
        .reSendConfirmAccount(this.resendConfirmationForm.controls.email.value)
        .pipe(
          finalize(() => {
            this.resendConfirmationForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (response.status === 202) {
              this.resendConfirmationFormSuccess = response.detail;
              return;
            }
            this.resendConfirmationFormError = 'Unknown error';
          },
          (e: any) => {
            this.resendConfirmationFormError = e.error.detail;
          }
        );
    } catch (e) {
      this.resendConfirmationFormError = e;
    }
  }
}
