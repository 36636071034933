import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IInPersonSignatureForm } from './in-person-signature-form/in-person-signature-form.interface';
import { WorkflowContact } from '@app/core/class';
import { WorkflowBuildService } from '@app/core/services';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';

@Component({
  selector: 'app-workflow-in-person-signature-field',
  templateUrl: './in-person-signature.component.html',
  styleUrls: [
    './in-person-signature.component.scss',
    '../../../droppable-field.component.scss'
  ]
})
export class WorkflowInPersonSignatureFieldComponent implements OnInit {
  @Input()
  public field: IWorkflowDroppedField;

  @ViewChild('addMetadataModal')
  public addMetadataModal: ElementRef;

  public contact: WorkflowContact;

  public metadata: IInPersonSignatureForm;

  private modalService: NgbModal;
  private workflowBuildService: WorkflowBuildService;

  constructor(
    modalService: NgbModal,
    workflowBuildService: WorkflowBuildService
  ) {
    this.modalService = modalService;
    this.workflowBuildService = workflowBuildService;
  }

  public ngOnInit() {
    this.contact = this.field.getContact();
    this.metadata = this.contact.getExtraMetadata<IInPersonSignatureForm>();

    if (!this.metadata) {
      this.open(this.addMetadataModal);
    }
  }

  public open(content: any): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        this.modalService.open(content, {
          ariaLabelledBy: 'modal-title',
          size: 'sm',
          keyboard: false,
          backdrop: 'static'
        });
        resolve();
      });
    });
  }

  public onSubmit($event: IInPersonSignatureForm): boolean {
    this.metadata = $event;
    this.contact.setExtraMetadata(this.metadata);
    this.modalService.dismissAll();
    return false;
  }

  public onCancel(): void {
    this.modalService.dismissAll();

    if (this.metadata) {
      return;
    }

    this.workflowBuildService
      .getVisibleFile()
      .getCurrentPage()
      .removeField(this.field);
  }
}
