import { FormGroup, FormControl } from '@angular/forms';

export function matchFields(fieldKey: string, fieldConfirmationKey: string) {
  return (group: FormGroup) => {
    const input = group.controls[fieldKey],
      inputConfirmation = group.controls[fieldConfirmationKey];
    if (input.value !== inputConfirmation.value) {
      return inputConfirmation.setErrors({ unmatched: true });
    } else {
      return inputConfirmation.setErrors(null);
    }
  };
}

/*
 * Validation used in subscribe/components/certificate-chooser component
 */
export function requireDocumentAndOTP(control: FormControl) {
  const type = control.value.type;

  if (type === 'CERTILION') {
    if (
      control.value.document.length !== 11 ||
      control.value.otp.length !== 6
    ) {
      return { missingDocumentOrOTP: true };
    }
  }
}
