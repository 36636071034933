import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Authentication } from '@app/core/models';
import { AuthorizationService } from '@app/core/services';

@Component({
  selector: 'app-document-folder-list',
  templateUrl: './document-folder-list.component.html',
  styleUrls: ['./document-folder-list.component.scss']
})
export class DocumentFolderListComponent implements OnInit {
  /**
   * Collection with data for list
   */
  @Input() collection: Array<any>;

  @Input() selectedFolder: any;

  /**
   * Callback with action and items
   */
  @Output() activity? = new EventEmitter();

  /**
   * Pagination
   */
  @Input() pagination: any;

  /**
   * Callback with change page
   */
  @Output() doChangePage? = new EventEmitter();

  logged: Authentication.SignupUserResponse;

  constructor(private authorizationService: AuthorizationService) {
    this.pagination = {};
  }

  ngOnInit() {
    this.logged = this.authorizationService.getUser();
  }

  /**
   * Provides the functionality to change page in pagination
   *
   * @param (number) of page
   */
  doPage(p: number) {
    this.doChangePage.emit(p);
  }

  /**
   * Call the action of buttons
   *
   * @param (string) type of action
   * @param (any) object with data
   */
  goAction(type: string, obj: any) {
    if (type === 'view' && !this.selectedFolder.isOwner) {
      return false;
    }
    this.activity.emit({ type: type, data: obj });
    return true;
  }

  moveButtonState() {
    return this.selectedFolder.owner.id === this.logged.id;
  }

  titlePhrase(sign: boolean) {
    if (sign) {
      return 'Mover documento de pasta';
    }
    return 'Somente os destinatários do documento possuem permissão para mover o arquivo.';
  }
}
