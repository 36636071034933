import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StateService } from '@app/core/services';
var OrganizationFormComponent = /** @class */ (function () {
    function OrganizationFormComponent(formBuilder, stateService) {
        this.formBuilder = formBuilder;
        this.stateService = stateService;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        /**
         * Callback with data of submit
         */
        this.doActionSubmit = new EventEmitter();
        this.createForm();
    }
    OrganizationFormComponent.prototype.ngOnInit = function () {
        var states = this.stateService.list();
        this.stateList = [{ id: '', name: 'Estado' }].concat(states);
        if (this.userData.controls.roleDefault.value === 'user') {
            this.isReadonly = true;
            return (this.roleDefault = false);
        }
        this.isReadonly = false;
        return (this.roleDefault = true);
    };
    /**
     * Data selection action by clicking the form submit button
     */
    OrganizationFormComponent.prototype.doSubmit = function () {
        this.doActionSubmit.emit({ values: this.userData.value });
    };
    Object.defineProperty(OrganizationFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.userData.controls.businessUnit['controls'];
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    OrganizationFormComponent.prototype.createForm = function () {
        this.userData = this.formBuilder.group({
            roleDefault: [''],
            businessUnit: this.formBuilder.group({
                document: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(11),
                        Validators.maxLength(14)
                    ])
                ],
                name: ['', Validators.required],
                address: ['', Validators.required],
                city: ['', Validators.required],
                state: ['', Validators.required],
                zipCode: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(8)
                    ])
                ]
            })
        });
    };
    /**
     * Fill the form.
     * Need to remove de + from the
     *  to not conflict with ngmask and reactive form validation.
     */
    OrganizationFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes['selected']) {
            if (this.selected.businessUnit) {
                this.userData.patchValue(this.selected);
                this.userData.controls.businessUnit.patchValue(this.selected.businessUnit);
            }
        }
    };
    return OrganizationFormComponent;
}());
export { OrganizationFormComponent };
