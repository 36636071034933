import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, ElementRef } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CHECK_WORKFLOW_STEP, ContactService, WorkflowStepUserUpdateService } from '@app/core/services';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
var log = new Logger('PlansPage');
export var PROBLEM_STEP = {
    SUCCESS: 0,
    ERROR: 1,
    REJECTED: 2
};
var SubscribersComponent = /** @class */ (function () {
    function SubscribersComponent(modalService, formBuilder, contactService, workflowStepUserUpdateService) {
        this.formBuilder = formBuilder;
        this.contactService = contactService;
        this.workflowStepUserUpdateService = workflowStepUserUpdateService;
        this.CHECK_WORKFLOW_STEP = CHECK_WORKFLOW_STEP;
        this.PROBLEM_STEP = PROBLEM_STEP;
        /**
         * sets whether the items are sortable
         */
        this.isSortable = false;
        /**
         * Action for delete
         */
        this.doDelete = new EventEmitter();
        /**
         * Action for update
         */
        this.doEdit = new EventEmitter();
        /**
         * Action for sort
         */
        this.doSort = new EventEmitter();
        this.isSavingUser = false;
        this.stepId = null;
        this.stepFeedbabck = 0;
        this.createForm();
        this.modalService = modalService;
        this.modalRef = { ref: null, data: { text: '' } };
    }
    SubscribersComponent.prototype.ngOnInit = function () { };
    /**
     * changes the order of a recipient
     */
    SubscribersComponent.prototype.drop = function (event) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.doSort.emit(this.items);
    };
    /**
     * It performs the deletion action
     */
    SubscribersComponent.prototype.doDeleteAction = function (recipient) {
        this.doDelete.emit(recipient);
    };
    /**
     * It performs the edition action
     */
    SubscribersComponent.prototype.doEditAction = function (recipient) {
        this.doEdit.emit(recipient);
    };
    SubscribersComponent.prototype.changeSubscriberUser = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        data = void 0;
                        return [4 /*yield*/, this.prepareContact(payload)];
                    case 1:
                        data = _a.sent();
                        this.toUpdateWorkflowUser(this.stepId, data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.isSavingUser = false;
                        this.toResetForm();
                        if (this.modalRef.ref) {
                            this.modalRef.ref.close();
                        }
                        this.stepFeedbabck = PROBLEM_STEP.ERROR;
                        this.open(this.modalFeedbabck, 'md');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SubscribersComponent.prototype.prepareContact = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, contact, newContact;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contactService.getContactByEmail(payload.email).toPromise()];
                    case 1:
                        contact = _a.sent();
                        if (contact) {
                            data = {
                                id_user: contact.userId,
                                name: payload.name
                            };
                            return [2 /*return*/, data];
                        }
                        return [4 /*yield*/, this.addNewContact(payload)];
                    case 2:
                        newContact = _a.sent();
                        data = newContact;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    SubscribersComponent.prototype.addNewContact = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contactService.addContact(payload).toPromise()];
                    case 1:
                        resp = _a.sent();
                        data = {
                            id_user: resp.id,
                            name: payload.name
                        };
                        return [2 /*return*/, data];
                }
            });
        });
    };
    SubscribersComponent.prototype.toUpdateWorkflowUser = function (stepId, data) {
        var _this = this;
        this.workflowStepUserUpdateService
            .updateWorkflowUser(stepId, data)
            .subscribe(function (response) {
            _this.toResetForm();
            _this.isSavingUser = false;
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
            _this.stepFeedbabck = PROBLEM_STEP.SUCCESS;
            _this.open(_this.modalFeedbabck, 'md');
            log.debug("Success [updateRecipient]: " + response);
        }, function (error) {
            _this.isSavingUser = false;
            _this.toResetForm();
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
            _this.stepFeedbabck = PROBLEM_STEP.ERROR;
            _this.open(_this.modalFeedbabck, 'md');
            log.debug("Error [updateRecipient]: " + error);
        });
    };
    SubscribersComponent.prototype.openPhoto = function (photo) {
        this.photoData = photo;
        this.open(this.modalShowPhoto, 'md');
    };
    SubscribersComponent.prototype.open = function (content, size) {
        this.modalRef.ref = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return this.modalRef;
    };
    SubscribersComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    SubscribersComponent.prototype.toModalCancel = function () {
        this.modalRef.ref.close({
            action: 'cancel',
            form: 'changeRecipient',
            formData: ''
        });
        this.isSavingUser = false;
        this.toResetForm();
    };
    SubscribersComponent.prototype.getUserAndStep = function (step) {
        if (step.status.action !== CHECK_WORKFLOW_STEP.WAITING) {
            this.stepFeedbabck = PROBLEM_STEP.REJECTED;
            this.open(this.modalFeedbabck, 'md');
            return;
        }
        this.userForm.setValue({
            name: step.name,
            email: step.email
        });
        this.stepId = step.stepId;
        this.open(this.replaceSubscriber, 'md');
    };
    SubscribersComponent.prototype.doSubmit = function () {
        this.isSavingUser = true;
        var payload = {
            name: this.userForm.value.name,
            email: this.userForm.value.email
        };
        this.changeSubscriberUser(payload);
    };
    /**
     * Reset form
     */
    SubscribersComponent.prototype.toResetForm = function () {
        this.userForm.reset();
    };
    Object.defineProperty(SubscribersComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.userForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SubscribersComponent.prototype.createForm = function () {
        this.userForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([Validators.required, Validators.minLength(5)])
            ],
            email: ['', Validators.compose([Validators.required, Validators.email])]
        });
    };
    SubscribersComponent.prototype.toGetPhoto = function (photo) {
        var link = document.createElement('a');
        link.download = 'foto';
        link.href = photo;
        // Firefox requires the link to be in the body
        document.body.appendChild(link);
        link.click();
        // remove the link when done
        document.body.removeChild(link);
    };
    return SubscribersComponent;
}());
export { SubscribersComponent };
