import { OnChanges, EventEmitter } from '@angular/core';
var EventsComponent = /** @class */ (function () {
    function EventsComponent() {
        /**
         * Callback of filter
         */
        this.filter = new EventEmitter();
        /**
         * Callback of action in event button
         */
        this.actionEvent = new EventEmitter();
    }
    /**
     * Provides update of chart data
     */
    EventsComponent.prototype.ngOnChanges = function () {
        // to do: parse data of service
        // console.log('>>> updated', this.data);
    };
    /**
     * provides a action in click button
     *
     * @param (any) action type
     * @return void
     */
    EventsComponent.prototype.goTo = function (action) {
        this.actionEvent.emit({ type: action.type });
    };
    Object.defineProperty(EventsComponent.prototype, "filterSelected", {
        /**
         * Inserts the default filter value
         * @return string
         */
        get: function () {
            return '30';
        },
        /**
         * provides the necessary management in select filter action
         *
         * @param (string) period of filter
         * @return EventEmitter
         */
        set: function (period) {
            this.filter.emit({ value: period });
        },
        enumerable: true,
        configurable: true
    });
    return EventsComponent;
}());
export { EventsComponent };
