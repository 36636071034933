import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  CustomSubjectMailService,
  EVENT_TYPES,
  AuthorizationService
} from '@app/core/services';
import { User, Country, State, Authentication } from '@app/core/models';
import { Router } from '@angular/router';
import { CustomSubjectMail } from '@app/core/models/custom-subject-mail.model';

@Component({
  selector: 'app-custom-subject-mail',
  templateUrl: './custom-subject-mail.component.html',
  styleUrls: ['./custom-subject-mail.component.scss']
})
export class CustomSubjectMailComponent implements OnInit {
  /**
   * template of modal for create/edit subject
   */
  @ViewChild('modalCreateCustomSubject') modalCreateCustomSubject: ElementRef;

  /**
   * template of modal for delete subject
   */
  @ViewChild('modalConfirmDelete') modalConfirmDelete: ElementRef;

  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * loading of form
   */
  isLoading: boolean = false;

  /**
   * config list with fields
   */
  configList: Array<any>;

  /**
   * default value for sort fields
   */
  sortFields: any;

  /**
   * selected items of list
   */
  selectedItems: Array<any> = [];

  /**
   * current subject for edit/delete
   */
  selected: CustomSubjectMail.Payload;

  /**
   * Linked users
   */
  customSubjectMail: CustomSubjectMail.Payload[];

  showErrorBar: Boolean;

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  /**
   * feedback User
   */
  feedbackUser: string;
  modalRef: any;
  modal: NgbModalRef;

  titleModal: string = 'Adicionar assunto';

  constructor(
    private customSubjectMailService: CustomSubjectMailService,
    private modalService: NgbModal,
    private authSerivce: AuthorizationService
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {
    this.init();
    this.getCustomSubjectMail();
    this.setListConfig();
  }

  get userBusinessUnitId(): string {
    return this.authSerivce.getUser().businessUnit.id;
  }

  /**
   * Settings on component init
   */
  init() {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando informações, aguarde...';
        this.modalRef.ref = this.openLoading(this.loading);
      }).bind(this)
    );
  }

  /**
   * Set table list configurations
   */
  setListConfig() {
    this.sortFields = { subject: 'ASC', event: 'ASC' };

    this.configList = [
      {
        name: 'Assunto do e-mail',
        klass: '',
        type: 'text',
        fieldId: 'subject',
        isSortable: true
      },
      {
        name: 'Tipo de e-mail',
        klass: '',
        type: 'text',
        fieldId: 'eventDescribe',
        isSortable: true
      },
      {
        name: 'Ações',
        klass: 'text-center',
        icon: 'fa fa-sliders-h',
        type: 'custom-select',
        clearAfterAction: true,
        options: {
          klass: 'text-center',
          items: [
            {
              name: 'Editar',
              action: 'edit'
            },
            {
              name: 'Remover',
              action: 'delete'
            }
          ]
        }
      }
    ];
  }

  /**
   * Get the list of custom subject mail
   */
  getCustomSubjectMail() {
    this.customSubjectMailService.getAll().subscribe(response => {
      const list = response['_embedded'].custom_subject_notification;
      this.customSubjectMail = list.map((custom: CustomSubjectMail.Payload) => {
        return {
          id: custom.id,
          subject: custom.subject,
          event: custom.event,
          eventDescribe: EVENT_TYPES[custom.event]
        };
      });

      if (this.modalRef.ref) {
        this.modalRef.ref.close();
      }
    });
  }

  addNewSubject(): void {
    this.selected = null;
    this.titleModal = 'Adicionar assunto';
    this.open(this.modalCreateCustomSubject, 'md');
  }

  /**
   * Action grab all selected items
   *
   * @param (any) items selected
   */
  getSelectedItems(items: any) {
    this.selectedItems = items;
  }
  /**
   * Remove (disable) user of businesse accounts
   *
   * @param field id
   */
  removeUser(item: any) {
    this.selected = item;
    this.open(this.modalConfirmDelete, 'md');
  }

  /**
   * Action sort list
   *
   * @param (string) field sort
   */
  doSort(field: string) {
    this.sortFields[field] = this.reorder(this.sortFields[field]);
  }

  /**
   * Action grab data
   *
   * @param (any) items selected
   */
  doAction(items: any) {
    switch (items.type) {
      case 'edit':
        this.titleModal = 'Alterar assunto';
        this.selected = items.data;
        this.open(this.modalCreateCustomSubject, 'md');
        break;
      case 'delete':
        this.removeUser(items.data);
        break;
      default:
        console.error('Ação não reconhecida');
        break;
    }
  }

  closeFeedbackUser() {
    setTimeout(() => {
      this.showErrorBar = false;
    }, 4000);
  }

  /**
   * Action by clicking in submit user form
   *
   * @param (any) form with data
   */
  doSubmitForm(action: 'save' | 'edit', form: any): void {
    this.showErrorBar = false;
    form.businessUnitId = this.userBusinessUnitId;
    let doSubmitObservable = this.customSubjectMailService.create(form);
    if (action === 'edit') {
      doSubmitObservable = this.customSubjectMailService.update(
        `${this.selected.id}`,
        form
      );
    }
    doSubmitObservable.subscribe(
      () => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Assunto de e-mail salvo com sucesso.';
        this.getCustomSubjectMail();
      },
      error => {
        switch (error.status) {
          case 400: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Este tipo de e-mail ja foi customizado. ( ${
              error.statusText
            } )`;
            break;
          }
          case 500: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Parece que houve um problema ao adicionar o assunto. ( ${
              error.statusText
            } )`;
            break;
          }
          default: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Ocorreu um Erro Inesperado. ${
              error.statusText
            } `;
            break;
          }
        }
      }
    );
  }

  open(content: any, size?: any) {
    const modalData: any = {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    };

    this.modalRef.ref = this.modalService.open(content, modalData);

    this.modalRef.ref.result.then((result: any) => {
      if (result && (result.action === 'save' || result.action === 'edit')) {
        this.doSubmitForm(result.action, result.formData);
        return;
      }

      if (result && result.action === 'delete') {
        this.deleteCustomSubject();
        return;
      }

      if (result && result === 'cancel') {
        return;
      }
    });
  }

  deleteCustomSubject() {
    this.customSubjectMailService.delete(`${this.selected.id}`).subscribe(
      _ => {
        this.feedbackTheme = 'success';
        this.feedbackMessage = 'Assunto de e-mail excluído com sucesso.';
        this.getCustomSubjectMail();
      },
      error => {
        switch (error.status) {
          case 500: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Parece que houve um problema ao excluir o assunto. ( ${
              error.statusText
            } )`;
            break;
          }
          default: {
            this.feedbackTheme = 'error';
            this.feedbackMessage = `Ops! Ocorreu um Erro Inesperado. ${
              error.statusText
            } `;
            break;
          }
        }
      }
    );
  }

  openLoading(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  /**
   * reorder field
   *
   * @param (string) field sort
   * @return string
   */
  private reorder(field: string) {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }
}
