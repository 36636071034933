import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { Layout } from '@app/core/models';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  /**
   * title to be displayed in box
   */
  @Input() title: string;

  /**
   * type of chart to be printed in the box
   */
  @Input() type: string;

  /**
   * allows viewing the footer with filters
   */
  @Input() showFooter: boolean = true;

  /**
   * Provides data that will render the chart
   */
  @Input() data: any;

  /**
   * Provides filter options, placeholder and behavior
   */
  @Input() filterOptions?: Layout.ChartFilterOptions;

  /**
   * Provide the route and name of the action
   */
  @Input() actionOption?: Layout.Options;

  /**
   * Callback of filter
   */
  @Output() filter? = new EventEmitter();

  /**
   * Provides update of chart data
   */
  ngOnChanges() {
    // to do: parse data of service
    // console.log('>>> updated', this.data);
  }

  /**
   * Inserts the default filter value
   * @return string
   */
  get filterSelected(): string {
    return '30';
  }

  /**
   * provides the necessary management in select filter action
   *
   * @param (string) period of filter
   * @return EventEmitter
   */
  set filterSelected(period: string) {
    this.filter.emit({ value: period });
  }
}
