<div class="document-build">
  <div class="document-build-wrapper" cdkDropListGroup>
    <div class="row">
      <div class="col-md-9">
        <div clas="row">
          <div class="col-12">
            <div class="box multifiles" *ngIf="hasBuildFiles()">
              <form action="" class="form">
                <div class="form-row align-items-center">
                  <div class="form-group col m-0">
                    <select
                      class="form-control"
                      [(ngModel)]="selectedWorkflowFile"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="onChangeFile(selectedWorkflowFile)"
                    >
                      <option
                        *ngFor="let workflowFile of workflowFiles"
                        [ngValue]="workflowFile"
                      >
                        {{ workflowFile.getName() }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-auto m-0" *ngIf="showUpload">
                    <app-upload
                      buttonText="Incluir mais documentos"
                      showModalStatus="true"
                      [buttonClass]="'btn-block btn-lg'"
                      [raw]="true"
                    ></app-upload>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-12">
            <div class="box">
              <div class="box-content ">
                <div id="stageDocument" class="document" #stage>
                  <div class="components-wrapper">
                    <button
                      class="btn btn-sm btn-danger remove-document-btn"
                      *ngIf="hasBuildFiles() && !!selectedWorkflowFile"
                      (click)="removeFileFromWorkflow(selectedWorkflowFile)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <app-workflow-document
                      [showToolbar]="false"
                      [showUpload]="showUpload"
                      [navigation]="'top'"
                    >
                    </app-workflow-document>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="sticky-top">
          <app-workflow-contacts></app-workflow-contacts>
          <div class="text-right mt-4">
            <button
              type="button"
              class="btn btn-secondary ml-2"
              (click)="navigateToWorkflowReview()"
              [disabled]="!canAdvanceToReview()"
              [title]="canAdvanceToReviewText()"
            >
              Salvar e Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
