<section class="container text-size" *ngIf="!stepPhoto">
  <p>
    <strong>Essa assinatura exige uma foto para validação.</strong>
    Certifique-se de:
  </p>

  <ul>
    <li>Estar em um lugar iluminado;</li>
    <li>Tirar a foto ao lado de seu documento pessoal;</li>
    <li>
      Verificar se não há objetos ou outras pessoas aparecendo na tela de
      captura.
    </li>
  </ul>

  <div class="text-center mt-4 mb-4">
    <img
      src="assets/images/photo-sign.png"
      class="img-fluid"
      alt="Imagem responsiva"
    />
  </div>

  <div class="text-right">
    <button
      class="btn btn-default"
      type="button"
      (click)="modal.close('cancel')"
    >
      Cancelar
    </button>
    <button
      class="btn btn-secondary btn-sm"
      type="button"
      (click)="showPhotoSign()"
    >
      Tirar foto
    </button>
  </div>
</section>

<section class="container" *ngIf="stepPhoto">
  <div class="text-center">
    <app-camera
      (addPhotoWorkflowStep)="addPhotoWorkflowStep($event)"
      (showButtonToSubmit)="showButtonToSubmit()"
    >
    </app-camera>
  </div>

  <ng-container *ngIf="showButtonSubmit">
    <div
      class="mt-5"
      [ngClass]="{ 'text-right': !isMobile(), 'text-center': isMobile() }"
    >
      <button
        class="btn btn-secondary"
        [ngClass]="{ 'btn-sm': !isMobile(), 'btn-lg': isMobile() }"
        type="button"
        (click)="toSubmit()"
      >
        Enviar e assinar
      </button>
    </div>
  </ng-container>
</section>
