import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PriorityPipe } from './pipes/priority.pipe';
import { ResizableDirective } from './directives/resizable.directive';
import { SelectableDirective } from './directives/selectable.directive';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { I18n } from './class/i18n.class';
import { NgSelectModule } from '@ng-select/ng-select';

import { AuthenticationGuard } from './guards/authentication.guard';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { ContentInterceptor } from './http/content.interceptor';
import { AuthorizationInterceptor } from './http/authorization.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationService } from './services/authorization.service';
import { BusinessService } from './services/business.service';
import { ContactService } from './services/contact.service';
import { CountryService } from './services/country.service';
import { DepartmentService } from './services/department.service';
import { DocumentService } from './services/document.service';
import { TestService } from './services/test.service';
import { EventsService } from './services/event.service';
import { TeamService } from './services/team.service';
import { UserService } from './services/user.service';
import { PlansService } from './services/plans.service';
import { StatisticService } from './services/statistic.service';
import { MemberService } from './services/member.service';
import { FolderService } from './services/folder.service';
import { FolderVisibilityService } from './services/folder-visibility.service';
import { StateService } from './services/state.service';
import { WorkflowService } from './services/workflow.service';
import { WorkflowTemplateService } from './services/workflow-template.service';
import { WorkflowBuildService } from './services/workflow-build.service';
import { SignatureService } from './services/signature.service';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { LocaldataService } from './services/localdata.service';
import {
  AccessToken,
  FeedbackService,
  RemainingUserBalanceService,
  UploadService,
  WorkflowStepUserUpdateService,
  IntegrationDocuwareService,
  WorkflowReportService,
  TagManageService,
  MarketsegmentService,
  CookiesService,
  FolderIntegrationService
} from './services';
import { BusinessMetadataService } from './services/business-unit-metadata.service';
import { CustomSubjectMailService } from './services/custom-subject-mail.service';

@NgModule({
  declarations: [PriorityPipe, ResizableDirective, SelectableDirective],
  exports: [PriorityPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    NgSelectModule
  ],
  providers: [
    // Classes
    I18n,

    // Guards
    AuthenticationGuard,

    // Interceptors
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    ContentInterceptor,
    AuthorizationInterceptor,

    // Services
    AuthenticationService,
    AuthorizationService,
    BusinessService,
    ContactService,
    CountryService,
    DepartmentService,
    DocumentService,
    TestService,
    EventsService,
    FeedbackService,
    TeamService,
    UserService,
    PlansService,
    StatisticService,
    MemberService,
    FolderService,
    FolderVisibilityService,
    UploadService,
    StateService,
    WorkflowService,
    WorkflowTemplateService,
    WorkflowBuildService,
    SignatureService,
    HttpCacheService,
    MarketsegmentService,
    BusinessMetadataService,
    AccessToken,
    RemainingUserBalanceService,
    WorkflowStepUserUpdateService,
    IntegrationDocuwareService,
    FolderIntegrationService,
    WorkflowReportService,
    TagManageService,
    CookiesService,

    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    ContentInterceptor,
    AuthorizationInterceptor,
    LocaldataService,
    CustomSubjectMailService,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
