<div class="message" *ngIf="step === LOCAL_STEP.APPROVED && !stepFromUser">
  <!-- <h2>Documento(s) assinado(s) com sucesso</h2>
  <p class="icon"><i class="icon-document-ok"></i></p>

  <div *ngIf="workflow.workflow.status === 6">
    <p class="text">
      Faça o download do documento clicando no botão abaixo.<br />
    </p>
    <div class="buttons">
      <button (click)="downloadFiles()" class="btn btn-secondary">
        Baixar Documento
      </button>
    </div>
  </div> -->

  <div *ngIf="workflow.workflow.status !== 6">
    <h2>Documento(s) assinado(s) com sucesso</h2>
    <p class="icon"><i class="icon-document-ok"></i></p>
    <p class="text">Acompanhe o status na sua conta.<br /></p>
    <div class="buttons">
      <a
        href="/manager/manage/workflow/detail/{{ workflow.workflow.id }}"
        class="btn btn-secondary"
        >Acompanhar</a
      >
    </div>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.APPROVED && stepFromUser">
  <!-- <div *ngIf="isGuest">
    <h2>Assinatura enviada com sucesso.</h2>
    <p class="icon"><i class="icon-document-ok"></i></p>
    <p class="text">
      Faça o download do documento clicando no botão abaixo.<br />
    </p>
    <div class="buttons">
      <button (click)="downloadFiles()" class="btn btn-secondary">
        Baixar Documento
      </button>
    </div>
  </div> -->

  <div *ngIf="!isGuest || isGuest">
    <h2>Assinatura enviada com sucesso.</h2>
    <p class="icon"><i class="icon-document-ok"></i></p>
    <p class="text">Acompanhe o status na sua conta.<br /></p>
    <div class="buttons">
      <a
        href="/manager/manage/workflow/detail/{{ workflow.workflow.id }}"
        class="btn btn-secondary"
        >Acompanhar</a
      >
    </div>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.GENERIC_ERROR">
  <h2>Ops! Ocorreu um erro</h2>
  <p class="icon"><i class="icon-document-error"></i></p>
  <p class="text">Ocorreu um erro inesperado ao efetuar a ação desejada.</p>

  <div class="buttons">
    <button type="button" (click)="doActionReload()" class="btn btn-secondary">
      Tentar novamente
    </button>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.BIRDID_CREDENTIAL_ERROR">
  <h2>Credenciais de assinatura inválidas. Tente novamente.</h2>
  <p class="icon"><i class="icon-document-error"></i></p>
  <p class="text">
    Você também pode entrar em contato conosco pelo chat disponível no canto
    inferior direito desta página.
  </p>

  <div class="buttons">
    <button type="button" (click)="doActionReload()" class="btn btn-secondary">
      Tentar novamente
    </button>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.EXPIRED">
  <h2>Ops! Documento não encontrato</h2>

  <p class="icon"><i class="icon-document-error"></i></p>
  <p class="text">O Documento solicitado não existe ou expirou.</p>

  <div class="buttons">
    <a href="/manager/dashboard" class="btn btn-secondary">Página inicial</a>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.PROCESSING">
  <div *ngIf="isGuest">
    <h2>Assinatura enviada com sucesso.</h2>
    <p class="icon"><i class="icon-document-ok"></i></p>
    <p class="text">
      Recebemos sua assinatura. Ela será processada em nossos servidores e em
      breve você receberá a confirmação de sucesso por e-mail.<br />
    </p>
    <div class="buttons">
      <a href="/register" class="btn btn-secondary">Cadastrar e Acompanhar</a>
    </div>
  </div>
  <div *ngIf="!isGuest">
    <h2>Assinatura enviada com sucesso.</h2>
    <p class="icon"><i class="icon-document-ok"></i></p>
    <p class="text">
      Recebemos sua assinatura. Ela será processada em nossos servidores e em
      breve você receberá a confirmação de sucesso por e-mail.<br />
    </p>
    <div class="buttons">
      <a
        href="/manager/manage/workflow/detail/{{ workflow.workflow.id }}"
        class="btn btn-secondary"
        >Acompanhar</a
      >
    </div>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.REJECTED">
  <h2>Recusa atualizada com sucesso</h2>

  <p class="icon"><i class="icon-document-ok"></i></p>
  <p class="text">Notificamos a sua recusa para este documento.</p>

  <div class="buttons">
    <a href="/manager/dashboard" class="btn btn-secondary">Página inicial</a>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.CANCELLED">
  <h2>Cancelamento efetuado com sucesso</h2>

  <p class="icon"><i class="icon-document-ok"></i></p>
  <p class="text">Notificamos o cancelamento deste documento.</p>

  <div class="buttons">
    <a href="/manager/dashboard" class="btn btn-secondary">Página inicial</a>
  </div>
</div>

<div class="message" *ngIf="step === LOCAL_STEP.NO_GEOLOCATION">
  <h2>Não foi possível confirmar sua localização</h2>

  <p class="icon"><i class="icon-document-ok"></i></p>
  <p class="text">
    Por favor, autorize o navegador a obter a sua localização atual.<br />
    Utilize as instruções abaixo de acordo com seu navegador para autorização.
    <ngb-tabset class="tab-container">
      <ngb-tab>
        <ng-template ngbTabTitle><i class="fab fa-chrome"></i></ng-template>
        <ng-template ngbTabContent>
          <ol>
            <li>No computador, abra o Chrome.</li>
            <li>
              Na parte superior direita, clique em Mais
              <i class="fa fa-ellipsis-v"></i> e, em seguida,
              <strong>Definições</strong>.
            </li>
            <li>Na parte inferior, clique em <strong>Avançadas</strong>.</li>
            <li>
              Em <strong>"Privacidade e segurança"</strong>, clique em
              Definições de conteúdo.
            </li>
            <li>Clique em <strong>Localização</strong>.</li>
            <li>Ative ou desative a opção Perguntar antes de aceder.</li>
          </ol>
          <p>
            Para mais detalhes, acesse
            <a
              href="https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DDesktop&hl=pt"
              target="_blank"
              >Compartilhar Localização</a
            >.
          </p>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle><i class="fab fa-firefox"></i></ng-template>
        <ng-template ngbTabContent>
          <ol>
            <li>
              Clique no menu <i class="fa fa-bars"></i> e selecione
              <strong>Preferências</strong>.
            </li>
            <li>
              Use o campo de pesquisa nas preferências para procurar o texto
              <em>localização</em>.
            </li>
            <li>
              Clique no botão <strong>Configurações</strong> ao lado de
              <strong>Localização</strong> na seção <strong>Permissões</strong>.
            </li>
            <li>
              Reveja ou mude a lista de sites que você concedeu permissão de
              acessar sua <strong>localização</strong>.
            </li>
          </ol>

          <p>
            Para mais detalhes, acesse
            <a
              href="https://support.mozilla.org/pt-BR/kb/firefox-compartilha-localizacao-websites"
              target="_blank"
              >Compartilhar Localização</a
            >.
          </p>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle><i class="fab fa-edge"></i></ng-template>
        <ng-template ngbTabContent>
          <ol>
            <li>
              Abra o Internet Explorer selecionando o ícone do programa Internet
              Explorer na barra de tarefas.
            </li>
            <li>
              Clique no botão <strong>Ferramentas</strong> e selecione
              <strong>Opções da Internet</strong>.
            </li>
            <li>
              Na guia <strong>Privacidade</strong>, em <strong>Local</strong>,
              marque a caixa de seleção para permitir sites da Web que solicitem
              sua localização física.
            </li>
          </ol>

          <p>
            Para mais detalhes, acesse
            <a
              href="https://support.microsoft.com/pt-br/help/17479/windows-internet-explorer-11-change-security-privacy-settings"
              target="_blank"
              >Compartilhar Localização</a
            >.
          </p>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle><i class="fab fa-safari"></i></ng-template>
        <ng-template ngbTabContent>
          <ol>
            <li>No Safari, selecione Safari &gt Preferências.</li>
            <li>Clique no ícone de Privacidade na janela Preferências.</li>
            <li>
              Selecione a opção que permita a localização em "Limitação de
              acesso para o serviço de localização".
            </li>
          </ol>

          <p>
            Para mais detalhes, acesse
            <a href="https://support.apple.com/pt-br/HT5403" target="_blank"
              >Compartilhar Localização</a
            >.
          </p>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </p>

  <div class="buttons">
    <button type="button" (click)="doActionReload()" class="btn btn-secondary">
      Tentar novamente
    </button>
  </div>
</div>
