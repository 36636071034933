import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseAPI } from '../class';
var BusinessMetadataService = /** @class */ (function (_super) {
    tslib_1.__extends(BusinessMetadataService, _super);
    function BusinessMetadataService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.output = new EventEmitter();
        _this.endpoint = '/business-unit-metadata';
        return _this;
    }
    BusinessMetadataService.prototype.createMetadata = function (payload) {
        return this.httpClient.post(this.endpoint, payload).pipe(map(function (body) {
            var logo = {
                id: body.id,
                dataKey: body.dataKey,
                dataValue: body.dataValue,
                businessUnit: {
                    id: body._embedded.businessUnit.id,
                    name: body._embedded.businessUnit.name,
                    document: body._embedded.businessUnit.document
                }
            };
            return logo;
        }));
    };
    BusinessMetadataService.prototype.updateMetadata = function (id, payload) {
        return this.httpClient.patch(this.endpoint + ("/" + id), payload).pipe(map(function (logo) {
            return logo;
        }));
    };
    /**
     * Gets all data of a service
     */
    BusinessMetadataService.prototype.getMetadata = function (querystring) {
        return this.httpClient
            .get(this.endpoint + (querystring ? querystring : ''))
            .pipe(map(function (response) {
            if (!response._embedded.business_unit_metadata[0]) {
                return null;
            }
            var logo = {
                id: response._embedded.business_unit_metadata[0].id,
                dataKey: response._embedded.business_unit_metadata[0].dataKey,
                dataValue: response._embedded.business_unit_metadata[0].dataValue,
                businessUnit: {
                    id: response._embedded.business_unit_metadata[0]._embedded
                        .businessUnit.id,
                    name: response._embedded.business_unit_metadata[0]._embedded
                        .businessUnit.name,
                    document: response._embedded.business_unit_metadata[0]._embedded
                        .businessUnit.document
                }
            };
            return logo;
        }));
    };
    BusinessMetadataService.prototype.inputBrand = function (marca) {
        this.output.emit(marca);
    };
    return BusinessMetadataService;
}(BaseAPI));
export { BusinessMetadataService };
