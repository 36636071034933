<div>
  <div class="upload-content p-0" *ngIf="raw">
    <label class="upload-button">
      <input
        #fileInput
        (click)="fileInput.value = ''"
        type="file"
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        multiple="true"
        [accept]="acceptedExtensions()"
      />
      <span class="btn btn-secondary" [ngClass]="buttonClass">{{
        buttonText
      }}</span>
    </label>
  </div>

  <div
    class="box-upload"
    [ngClass]="layout"
    [class.over]="dragOver"
    *ngIf="!raw"
  >
    <div class="upload-content">
      <div
        class="drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      >
        <div class="upload-text">
          <i class="fas fa-file-upload"></i>
          <h5>Arraste aqui seus documentos</h5>
          <h6>
            Arquivos<strong> {{ extensionText.toUpperCase() }} </strong>no
            máximo 25MBs
          </h6>
          <label class="upload-button">
            <input
              #fileInput
              (click)="fileInput.value = ''"
              type="file"
              ngFileSelect
              [options]="options"
              (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput"
              multiple="true"
              [accept]="acceptedExtensions()"
            />
            <span class="btn btn-secondary" [ngClass]="buttonClass">{{
              buttonText
            }}</span>
          </label>
          <p class="warning" *ngIf="error">
            Não foi possível efetuar o upload.<br />
            Apenas arquivos no formato ({{ extensionText }})
          </p>
        </div>
        <div class="upload-drop"><p>Solte aqui</p></div>
      </div>
      <div class="upload-loading" *ngIf="showRealtimeLoading">
        <div class="upload-item" *ngFor="let f of files; let i = index">
          <div class="upload-item-content">
            <div class="filename">
              <div class="filename-left">
                <i class="fa fa-copy"></i> <span>{{ f.name }}</span>
              </div>
              <div class="filename-right">
                <i class="fa fa-trash-alt" (click)="cancelUpload(f.id)"></i>
              </div>
            </div>
            <div class="progress-content">
              <div class="progress">
                <span
                  class="bar"
                  [style.width]="f?.progress?.data?.percentage + '%'"
                  [class.is-done]="f?.progress?.data?.percentage === 100"
                ></span>
              </div>
            </div>
            <div class="progress-text-content">
              <span
                class="progress-text"
                [class.is-done]="f?.progress?.data?.percentage === 100"
              >
                <span>{{ f.progress?.data?.percentage }}% </span>
                <span *ngIf="f.progress?.data?.percentage !== 100"
                  >Enviando...</span
                >
                <span *ngIf="f.progress?.data?.percentage === 100"
                  >Concluído</span
                >
              </span>
              <span
                class="speed-and-eta-text"
                *ngIf="
                  f.progress?.data?.percentage !== 0 &&
                  f.progress?.data?.percentage !== 100
                "
              >
                <span>{{ f.progress?.data?.speedHuman }} </span>
                <span>ETA {{ f.progress?.data?.etaHuman }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="modalRef.data.showClose"
    [title]="modalRef.data.title"
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
