import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
var QRCodeField = /** @class */ (function (_super) {
    tslib_1.__extends(QRCodeField, _super);
    function QRCodeField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.QR_CODE;
        _this.minHeight = _this.height = 80;
        _this.minWidth = _this.width = _this.height;
        return _this;
    }
    QRCodeField.prototype.isSpecialField = function () {
        return true;
    };
    QRCodeField.prototype.shouldKeepAspectRatio = function () {
        return true;
    };
    return QRCodeField;
}(WorkflowDroppedField));
export { QRCodeField };
