import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { ContactService } from './contact.service';

@Injectable()
export class FolderVisibilityService extends BaseAPI {
  constructor(
    protected httpClient: HttpClient,
    protected contactService: ContactService
  ) {
    super(httpClient);
    this.endpoint = '/folder-visibility';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  getAll(id?: any): Observable<any> {
    return this.httpClient
      .get(
        this.endpoint +
          '?filter[0][type]=eq&filter[0][field]=folder&filter[0][value]=' +
          id
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  shareFolderWithOrganization(folderId: any): Observable<any> {
    const payload = {
      type: 0,
      idFolder: folderId
    };
    return this.httpClient.post(this.endpoint, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  async shareFolderWithEmail(folderId: any, email: any): Promise<any> {
    let contact = await this.contactService
      .getContactByEmail(email)
      .toPromise();
    if (contact === null) {
      contact = await this.contactService
        .addContact({
          name: email,
          email: email
        })
        .toPromise();
    }
    const payload = {
      type: 2,
      idFolder: folderId,
      idUser: contact.userId
    };
    return this.httpClient.post(this.endpoint, payload).toPromise();
  }

  async shareFolderWithContact(folderId: any, contactId: any): Promise<any> {
    const payload = {
      type: 2,
      idFolder: folderId,
      idUser: contactId
    };
    return this.httpClient.post(this.endpoint, payload).toPromise();
  }

  async removeSharingRule(shareId: any): Promise<any> {
    return this.httpClient.delete(this.endpoint + '/' + shareId).toPromise();
  }

  setFolderVisibility(): Observable<any> {
    const payload = {
      name: 'default',
      path: 'default'
    };
    return super.create(payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getFolderVisibility(id: any): Observable<any> {
    return super.delete(id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  private getContact(email: any): Observable<any> {
    const query =
      '/contact?filter[0][type]=innerjoin&filter[0][field]=userTwo&filter[0][alias]=u&filter[1][type]=like&filter[1][alias]=u&filter[1][field]=email&filter[1][value]=%' +
      email +
      '%';
    const user = this.httpClient.get(query).pipe(
      map((response: any) => {
        return response;
      })
    );
    return user;
  }
}
