import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BusinessService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/business-unit';

    this.apiFieldsMap = {
      name: 'name',
      email: 'email',
      document: 'document',
      country: 'country',
      state: 'state',
      city: 'city',
      address: 'address',
      zipCode: 'zipCode'
    };

    this.fieldsRequired = [
      'name',
      'email',
      'password',
      'document',
      'country',
      'state',
      'city',
      'address',
      'zipCode'
    ];
  }
}
