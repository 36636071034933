<nav *ngIf="pageCount > 1">
  <ul class="pagination">
    <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
      <a
        class="page-link"
        (click)="doPrev()"
        aria-label="Previous"
        *ngIf="currentPage > 1"
      >
        <span aria-hidden="true">&laquo;</span>
      </a>
      <span aria-hidden="true" class="page-link" *ngIf="currentPage === 1"
        >&laquo;</span
      >
    </li>
    <li
      class="page-item"
      *ngFor="let p of [].constructor(pageCount); let i = index"
      [ngClass]="{ active: i + 1 === currentPage }"
    >
      <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
    </li>
    <li class="page-item" [ngClass]="{ disabled: currentPage >= pageCount }">
      <a
        class="page-link"
        (click)="doNext()"
        aria-label="Next"
        *ngIf="currentPage < pageCount"
      >
        <span aria-hidden="true">&raquo;</span>
      </a>
      <span
        aria-hidden="true"
        class="page-link"
        *ngIf="currentPage >= pageCount"
        >&raquo;</span
      >
    </li>
  </ul>
</nav>
