<h3>Entrar no Assine Online</h3>
<p>Preencha os dados abaixo</p>

<form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
  <div
    class="alert alert-primary account-created"
    [hidden]="!accountCreatedCallback"
  >
    Sua conta foi criada com sucesso. Para ativá-la, acesse seu e-mail e clique
    no link de confirmação que enviamos.
    <a class="special-link" routerLink="/auth/confirm-account/resend"
      >Não recebeu o e-mail? Clique aqui para reenviá-lo.</a
    >
  </div>

  <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
    Email e/ou senha íncorretos.
  </div>
  <div class="form-group">
    <label class="d-block">
      <input
        type="text"
        class="form-control"
        formControlName="email"
        autocomplete="email"
        [placeholder]="'Email' | translate"
      />
      <small
        [hidden]="
          loginForm.controls.email.valid || loginForm.controls.email.untouched
        "
        class="text-danger"
        translate
      >
        Necessário um e-mail válido
      </small>
    </label>
    <label class="d-block">
      <input
        type="password"
        class="form-control"
        formControlName="password"
        autocomplete="current-password"
        [placeholder]="'Senha' | translate"
        required
      />
      <small
        [hidden]="
          loginForm.controls.password.valid ||
          loginForm.controls.password.untouched
        "
        class="text-danger"
        translate
      >
        Senha é necessária
      </small>
    </label>
  </div>
  <p class="text-right">
    <a routerLink="/auth/forget-password">Esqueceu sua senha?</a> |
    <a routerLink="/auth/confirm-account/resend"
      >Reenviar e-mail de confirmação</a
    >
  </p>
  <button
    class="btn btn-secondary btn-sm"
    type="submit"
    [disabled]="loginForm.invalid || isLoading"
  >
    <i class="fas fa-spinner fa-spin" [hidden]="!isLoading"></i>
    <span translate [hidden]="isLoading">Entrar</span>
  </button>
</form>
