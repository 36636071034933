import { Component } from '@angular/core';
import { WORKFLOW_SIGNATURE } from '@app/core/services';
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';

@Component({
  selector: 'app-workflow-droppable-visible-signature-field',
  templateUrl: './visible-signature.component.html',
  styleUrls: ['../../droppable-field.component.scss']
})
export class WorkflowDroppableVisibleSignatureFieldComponent extends WorkflowBaseDroppedField {
  WORKFLOW_SIGNATURE = WORKFLOW_SIGNATURE;
}
