import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseAPI } from '../class';
var FolderIntegrationService = /** @class */ (function (_super) {
    tslib_1.__extends(FolderIntegrationService, _super);
    function FolderIntegrationService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/folder-integration';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    FolderIntegrationService.prototype.createFolderIntegration = function (payload) {
        var _this = this;
        var toSend = {
            folder: payload.folder,
            metadata: JSON.stringify(payload.metadata)
        };
        return _super.prototype.create.call(this, toSend).pipe(map(function (body) {
            return _this.mapFields(body, true);
        }));
    };
    FolderIntegrationService.prototype.updateFolderIntergation = function (payload) {
        var _this = this;
        var toSend = {
            metadata: JSON.stringify(payload.metadata)
        };
        return _super.prototype.update.call(this, payload.idFolderIntegration, toSend).pipe(map(function (response) {
            return _this.mapFields(response, true);
        }));
    };
    FolderIntegrationService.prototype.deleteIntegrationWithFolder = function (id) {
        return _super.prototype.delete.call(this, id);
    };
    FolderIntegrationService.prototype.listFolderIntegration = function () {
        var _this = this;
        return _super.prototype.getAll.call(this).pipe(map(function (response) {
            return _this.parseFolders(response);
        }));
    };
    FolderIntegrationService.prototype.parseFolders = function (data) {
        var folders = [];
        if (data.total_items > 0) {
            folders = data._embedded.folder_integration.map(function (folder) {
                return {
                    id: folder.id,
                    metadata: folder.metadata,
                    folderIntegration: {
                        id: folder._embedded.folder.id,
                        name: folder._embedded.folder.name
                    }
                };
            });
        }
        return {
            folderIntegration: folders,
            total_items: data.total_items
        };
    };
    return FolderIntegrationService;
}(BaseAPI));
export { FolderIntegrationService };
