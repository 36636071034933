import { OnInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomSubjectMailService, EVENT_TYPES, AuthorizationService } from '@app/core/services';
var CustomSubjectMailComponent = /** @class */ (function () {
    function CustomSubjectMailComponent(customSubjectMailService, modalService, authSerivce) {
        this.customSubjectMailService = customSubjectMailService;
        this.modalService = modalService;
        this.authSerivce = authSerivce;
        /**
         * loading of form
         */
        this.isLoading = false;
        /**
         * selected items of list
         */
        this.selectedItems = [];
        this.titleModal = 'Adicionar assunto';
        this.modalRef = { ref: null, data: { text: '' } };
    }
    CustomSubjectMailComponent.prototype.ngOnInit = function () {
        this.init();
        this.getCustomSubjectMail();
        this.setListConfig();
    };
    Object.defineProperty(CustomSubjectMailComponent.prototype, "userBusinessUnitId", {
        get: function () {
            return this.authSerivce.getUser().businessUnit.id;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Settings on component init
     */
    CustomSubjectMailComponent.prototype.init = function () {
        var _this = this;
        setTimeout((function () {
            _this.modalRef.data.text = 'carregando informações, aguarde...';
            _this.modalRef.ref = _this.openLoading(_this.loading);
        }).bind(this));
    };
    /**
     * Set table list configurations
     */
    CustomSubjectMailComponent.prototype.setListConfig = function () {
        this.sortFields = { subject: 'ASC', event: 'ASC' };
        this.configList = [
            {
                name: 'Assunto do e-mail',
                klass: '',
                type: 'text',
                fieldId: 'subject',
                isSortable: true
            },
            {
                name: 'Tipo de e-mail',
                klass: '',
                type: 'text',
                fieldId: 'eventDescribe',
                isSortable: true
            },
            {
                name: 'Ações',
                klass: 'text-center',
                icon: 'fa fa-sliders-h',
                type: 'custom-select',
                clearAfterAction: true,
                options: {
                    klass: 'text-center',
                    items: [
                        {
                            name: 'Editar',
                            action: 'edit'
                        },
                        {
                            name: 'Remover',
                            action: 'delete'
                        }
                    ]
                }
            }
        ];
    };
    /**
     * Get the list of custom subject mail
     */
    CustomSubjectMailComponent.prototype.getCustomSubjectMail = function () {
        var _this = this;
        this.customSubjectMailService.getAll().subscribe(function (response) {
            var list = response['_embedded'].custom_subject_notification;
            _this.customSubjectMail = list.map(function (custom) {
                return {
                    id: custom.id,
                    subject: custom.subject,
                    event: custom.event,
                    eventDescribe: EVENT_TYPES[custom.event]
                };
            });
            if (_this.modalRef.ref) {
                _this.modalRef.ref.close();
            }
        });
    };
    CustomSubjectMailComponent.prototype.addNewSubject = function () {
        this.selected = null;
        this.titleModal = 'Adicionar assunto';
        this.open(this.modalCreateCustomSubject, 'md');
    };
    /**
     * Action grab all selected items
     *
     * @param (any) items selected
     */
    CustomSubjectMailComponent.prototype.getSelectedItems = function (items) {
        this.selectedItems = items;
    };
    /**
     * Remove (disable) user of businesse accounts
     *
     * @param field id
     */
    CustomSubjectMailComponent.prototype.removeUser = function (item) {
        this.selected = item;
        this.open(this.modalConfirmDelete, 'md');
    };
    /**
     * Action sort list
     *
     * @param (string) field sort
     */
    CustomSubjectMailComponent.prototype.doSort = function (field) {
        this.sortFields[field] = this.reorder(this.sortFields[field]);
    };
    /**
     * Action grab data
     *
     * @param (any) items selected
     */
    CustomSubjectMailComponent.prototype.doAction = function (items) {
        switch (items.type) {
            case 'edit':
                this.titleModal = 'Alterar assunto';
                this.selected = items.data;
                this.open(this.modalCreateCustomSubject, 'md');
                break;
            case 'delete':
                this.removeUser(items.data);
                break;
            default:
                console.error('Ação não reconhecida');
                break;
        }
    };
    CustomSubjectMailComponent.prototype.closeFeedbackUser = function () {
        var _this = this;
        setTimeout(function () {
            _this.showErrorBar = false;
        }, 4000);
    };
    /**
     * Action by clicking in submit user form
     *
     * @param (any) form with data
     */
    CustomSubjectMailComponent.prototype.doSubmitForm = function (action, form) {
        var _this = this;
        this.showErrorBar = false;
        form.businessUnitId = this.userBusinessUnitId;
        var doSubmitObservable = this.customSubjectMailService.create(form);
        if (action === 'edit') {
            doSubmitObservable = this.customSubjectMailService.update("" + this.selected.id, form);
        }
        doSubmitObservable.subscribe(function () {
            _this.feedbackTheme = 'success';
            _this.feedbackMessage = 'Assunto de e-mail salvo com sucesso.';
            _this.getCustomSubjectMail();
        }, function (error) {
            switch (error.status) {
                case 400: {
                    _this.feedbackTheme = 'error';
                    _this.feedbackMessage = "Ops! Este tipo de e-mail ja foi customizado. ( " + error.statusText + " )";
                    break;
                }
                case 500: {
                    _this.feedbackTheme = 'error';
                    _this.feedbackMessage = "Ops! Parece que houve um problema ao adicionar o assunto. ( " + error.statusText + " )";
                    break;
                }
                default: {
                    _this.feedbackTheme = 'error';
                    _this.feedbackMessage = "Ops! Ocorreu um Erro Inesperado. " + error.statusText + " ";
                    break;
                }
            }
        });
    };
    CustomSubjectMailComponent.prototype.open = function (content, size) {
        var _this = this;
        var modalData = {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        };
        this.modalRef.ref = this.modalService.open(content, modalData);
        this.modalRef.ref.result.then(function (result) {
            if (result && (result.action === 'save' || result.action === 'edit')) {
                _this.doSubmitForm(result.action, result.formData);
                return;
            }
            if (result && result.action === 'delete') {
                _this.deleteCustomSubject();
                return;
            }
            if (result && result === 'cancel') {
                return;
            }
        });
    };
    CustomSubjectMailComponent.prototype.deleteCustomSubject = function () {
        var _this = this;
        this.customSubjectMailService.delete("" + this.selected.id).subscribe(function (_) {
            _this.feedbackTheme = 'success';
            _this.feedbackMessage = 'Assunto de e-mail excluído com sucesso.';
            _this.getCustomSubjectMail();
        }, function (error) {
            switch (error.status) {
                case 500: {
                    _this.feedbackTheme = 'error';
                    _this.feedbackMessage = "Ops! Parece que houve um problema ao excluir o assunto. ( " + error.statusText + " )";
                    break;
                }
                default: {
                    _this.feedbackTheme = 'error';
                    _this.feedbackMessage = "Ops! Ocorreu um Erro Inesperado. " + error.statusText + " ";
                    break;
                }
            }
        });
    };
    CustomSubjectMailComponent.prototype.openLoading = function (content, size) {
        var modalRef = this.modalService.open(content, {
            ariaLabelledBy: 'modal-title',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            size: size || 'lg'
        });
        return modalRef;
    };
    /**
     * reorder field
     *
     * @param (string) field sort
     * @return string
     */
    CustomSubjectMailComponent.prototype.reorder = function (field) {
        return field === 'ASC' ? 'DESC' : 'ASC';
    };
    return CustomSubjectMailComponent;
}());
export { CustomSubjectMailComponent };
