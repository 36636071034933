<div class="simple-text">
  <div class="mt-1">
    <span class="signature-subtitle">Assinatura Presencial</span>
  </div>
  <div>{{ metadata?.name }}</div>
  <div class="signature-subtitle" *ngIf="!metadata">
    Clique no ícone para editar
  </div>
</div>
<div class="actions-list">
  <button
    title="Clique para editar o assinante"
    (click)="open(addMetadataModal)"
    class="actions-list__item"
  >
    <i class="fa fa-pen"></i>
  </button>
</div>

<ng-template #addMetadataModal let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Assinante Presencial'"
  >
    <app-workflow-in-person-signature-field-form
      [metadata]="metadata"
      (cancel)="onCancel()"
      (submit)="onSubmit($event)"
    ></app-workflow-in-person-signature-field-form>
  </app-modal-action>
</ng-template>
