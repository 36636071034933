import { Injectable } from '@angular/core';
import { Layout } from '@app/core/models/layout.model';
import { User } from '@app/core/models/user.model';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class StatisticService {
  private counters: Layout.ManagerWidgetStatistics[] = null;

  private loadedCounters: Layout.ManagerWidgetStatistics[] = null;

  private defaultCounters: Layout.ManagerWidgetStatistics[] = [
    {
      title: 'Rascunhos',
      color: '#a3a3a3',
      value: -1,
      status: 0,
      isVisible: false,
      filter: {
        path: '/manager/manage/workflow/me',
        status: 0
      }
    },
    {
      title: 'Pendentes',
      color: '#45996c',
      value: -1,
      status: 1,
      isVisible: true,
      filter: {
        path: '/manager/manage/workflow/me',
        status: 1
      }
    },
    {
      title: 'Cancelados',
      color: '#de4759',
      value: -1,
      status: 2,
      isVisible: false,
      filter: {
        path: '/manager/manage/workflow/me',
        status: 2
      }
    },
    {
      title: 'Expirados',
      color: '#8b572a',
      value: -1,
      status: 3,
      isVisible: false
    },
    {
      title: 'Recebidos',
      color: '#efa522',
      value: -1,
      status: -1,
      isStep: true,
      isVisible: true,
      filter: {
        path: '/manager/manage/workflow',
        status: -1
      }
    },
    {
      title: 'Rejeitados',
      color: '#de4759',
      value: -1,
      status: 5,
      isVisible: false,
      filter: {
        path: '/manager/manage/workflow/me',
        status: 5
      }
    },
    {
      title: 'Finalizados',
      color: '#45996c',
      value: -1,
      status: 6,
      isVisible: true,
      filter: {
        path: '/manager/manage/workflow/me',
        status: 6
      }
    }
  ];

  constructor(private userService: UserService) {}

  available: Layout.ManagerWidgetStatistics[];

  getData(): Observable<Layout.ManagerWidgetStatistics[]> {
    return this.userService.getUserPreferences().pipe(
      map((userPreferences: User.UserPreferences) => {
        if (!userPreferences) {
          return this.cloneCounters(this.defaultCounters);
        }
        if (!userPreferences.statistic) {
          return this.cloneCounters(this.defaultCounters);
        }

        this.loadedCounters = this.cloneCounters(userPreferences.statistic);
        this.counters = this.cloneCounters(userPreferences.statistic);
        return this.counters;
      })
    );
  }

  rollbackData() {
    const counters = this.loadedCounters || this.defaultCounters;
    this.counters = this.cloneCounters(counters);
    return this.counters;
  }

  saveDraft(items: Array<Layout.ManagerWidgetStatistics>) {
    this.counters = items;
  }

  saveData(): Observable<User.UserPreferences> {
    const payload = this.counters || this.defaultCounters;

    return this.userService.saveUserPreferences({
      statistic: payload
    });
  }

  private cloneCounters(counters: Layout.ManagerWidgetStatistics[]) {
    return counters
      .map(counter => {
        return Object.assign({}, counter);
      })
      .slice();
  }
}
