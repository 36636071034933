import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WorkflowFile } from '../class/workflow-file.class';
import { WorkflowContact } from '../class/workflow-contact.class';
import { IDraggableField } from '@app/core/models';
import { FolderService } from './folder.service';
import { Workflow } from '../models/workflow.model';
import { WorkflowBuilder } from '../class/workflow-builder.class';
import { WorkflowDroppedFieldsStrategy } from '../class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
import { Folder } from '../models';

@Injectable()
export class WorkflowBuildService {
  private visibleFile: WorkflowFile;
  private files: WorkflowFile[] = [];
  private contacts: WorkflowContact[] = [];

  private filesObserver: Subject<WorkflowFile[]>;
  private contactsObserver: Subject<WorkflowContact[]>;
  private visibleFileObserver: Subject<WorkflowFile>;
  private subject = new Subject<any>();

  private settings: Workflow.Settings = {};

  private workflowId: number = 0;

  private defaultFolderId: number = 0;

  private defaultFolderName: string = 'default';

  private cursorPosition: { x: number; y: number } = { x: 0, y: 0 };

  private dataFieldAddedByClick: any = { item: { data: {} } };

  private fieldInsertionType: string = 'drop';

  private whatsApp: boolean = false;

  constructor(protected folderService: FolderService) {
    this.filesObserver = new Subject<WorkflowFile[]>();
    this.contactsObserver = new Subject<WorkflowContact[]>();
    this.visibleFileObserver = new Subject<WorkflowFile>();
  }

  public compile(): Workflow.Settings {
    const workflowBuilder = WorkflowBuilder.MakeWorkflowBuilder(
      this.files,
      this.contacts
    );

    return workflowBuilder.compile(this.settings);
  }

  public setWhatsApp(flag: boolean): void {
    this.whatsApp = flag;
  }

  public getWhatsApp(): boolean {
    return this.whatsApp;
  }

  public setDefaultFolderId(id: number): void {
    this.defaultFolderId = id;
  }

  public getDefaultFolderId(): number {
    return this.defaultFolderId;
  }

  public setDefaultFolderName(name: string): void {
    this.defaultFolderName = name;
  }

  public getDefaultFolderName(): string {
    return this.defaultFolderName;
  }

  public setWorkflowId(id: number): void {
    this.workflowId = id;
  }

  public getWorkflowId(): number {
    return this.workflowId;
  }

  public setSettings(settings: Workflow.Settings): void {
    this.settings = settings;
  }

  public getSettings(): Workflow.Settings {
    return this.settings;
  }

  public setCursorPosition(x: number, y: number): void {
    this.cursorPosition.x = x;
    this.cursorPosition.y = y;
  }

  /**
   * add field data by click to use in mobile experience
   */
  public setDataFieldAddedByClick(
    contact: WorkflowContact,
    field: IDraggableField
  ) {
    this.dataFieldAddedByClick.item.data.contact = contact;
    this.dataFieldAddedByClick.item.data.field = field;
    this.fieldInsertionType = 'click';
  }

  public getDataFieldAddedByClick() {
    return this.dataFieldAddedByClick;
  }

  public getInsertionType() {
    return this.fieldInsertionType;
  }

  public getCursorPosition(): { x: number; y: number } {
    return this.cursorPosition;
  }

  /**
   * Add a Workflow file to drop signatures and other fields
   * to build a signature workflow
   */
  public addFile(workflowFile: WorkflowFile): void {
    this.files.push(workflowFile);
    this.pushUpdateOfFilesState();
  }

  public getFiles(): WorkflowFile[] {
    return this.files;
  }

  public observeFiles(): Observable<WorkflowFile[]> {
    return this.filesObserver.asObservable();
  }

  public removeFile(file: WorkflowFile): void {
    this.files = this.files.filter(f => f !== file);
    this.pushUpdateOfFilesState();
  }

  public findFile(file: File): WorkflowFile {
    return this.files.find(f => f.getFile() === file);
  }

  public hasFiles(): boolean {
    return this.files.length > 0;
  }

  public emptyFiles(): void {
    this.files = [];
    this.pushUpdateOfFilesState();
  }

  public setFileAsVisible(workflowFile: WorkflowFile): void {
    this.visibleFile = workflowFile;
    this.visibleFileObserver.next(this.visibleFile);
  }

  public getVisibleFile(): WorkflowFile {
    return this.visibleFile;
  }

  public observeVisibleFile(): Observable<WorkflowFile> {
    return this.visibleFileObserver.asObservable();
  }

  public addContact(contact: WorkflowContact) {
    this.contacts.push(contact);
    this.updateContactsOrderIndexes();
    this.pushUpdateOfContactsState();
  }

  public getContacts(): WorkflowContact[] {
    return this.contacts;
  }

  public hasContacts(): boolean {
    return this.contacts.length > 1;
  }

  public findContactByOrderIndex(index: number): WorkflowContact {
    return this.contacts[index - 1];
  }

  public findContactByEmail(email: string): WorkflowContact {
    return this.contacts.find(contact => contact.email === email);
  }

  public observeContacts(): Observable<WorkflowContact[]> {
    return this.contactsObserver.asObservable();
  }

  public removeContact(contact: WorkflowContact) {
    this.contacts = this.contacts.filter(c => c !== contact);

    for (const file of this.files) {
      for (const page of file.getPages()) {
        page.removeFieldsOfContact(contact);
      }
    }

    this.updateContactsOrderIndexes();
    this.pushUpdateOfContactsState();
  }

  public emptyContacts(): void {
    this.contacts = [];
    this.pushUpdateOfContactsState();
  }

  public resetWorkflow(): void {
    this.setFileAsVisible(null);
    this.emptyContacts();
    this.emptyFiles();
    this.setSettings({});
  }

  public async fetchDefaultFolder(): Promise<Folder.Folder> {
    const response = await this.folderService.getAll().toPromise();
    if (response.default) {
      return response.default;
    } else {
      return this.addDefaultFolder();
    }
  }

  private pushUpdateOfFilesState(): void {
    this.filesObserver.next(this.files);
  }

  private pushUpdateOfContactsState(): void {
    this.contactsObserver.next(this.contacts);
  }

  /**
   * The contact order is always the array index + 1
   */
  private updateContactsOrderIndexes(): void {
    for (let i = 0; i < this.contacts.length; i++) {
      const contact = this.contacts[i];
      contact.setOrderIndex(i + 1);
    }
  }

  private async addDefaultFolder(): Promise<Folder.Folder> {
    const response = await this.folderService.createDefaultFolder().toPromise();
    if (response.default) {
      return response.default;
    }
  }

  /**
   * used to add a field per click
   */
  public setFieldAddedByClick() {
    this.subject.next();
  }

  public getFieldAddedByClick(): Observable<any> {
    return this.subject.asObservable();
  }
}
