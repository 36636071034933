import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { WorkflowContact } from '@app/core/class';

export class NameField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.TYPE_NAME_TEXT;
    this.minHeight = this.height = 25;
    this.minWidth = this.width = 100;
  }
}
