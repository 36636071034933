<div [class]="typeModal">
  <div class="modal-header" *ngIf="showHeader">
    <h4 class="modal-title" id="modal-title" *ngIf="title">{{ title }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close('cancel')"
      *ngIf="showClose"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"><ng-content></ng-content></div>
  <div class="modal-footer" *ngIf="showFooter">
    <button
      type="button"
      class="btn"
      [ngClass]="btnClassCancel"
      (click)="modal.close('cancel')"
      *ngIf="showBtnCancel"
    >
      {{ btnActionCancel }}
    </button>

    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.close('save')"
      [disabled]="disableBtnSave"
      *ngIf="showBtnSave"
    >
      {{ btnActionSave }}
    </button>
  </div>
</div>
