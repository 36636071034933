import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { workflowReport } from '@app/core/models';
import { WorkflowReportService } from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-workflow-report',
  templateUrl: './workflow-report.component.html',
  styleUrls: ['./workflow-report.component.scss']
})
export class WorkflowReportComponent implements OnInit {
  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * modal
   */
  modalRef: any;

  /**
   * config list with fields
   */
  configList: Array<any>;

  statusFilter: Array<any>;

  periodFilter: Array<any>;

  sentOrReceivedFilter: Array<any>;

  /**
   * default value for sort fields
   */
  sortFields: any;

  report: any = [];

  /**
   * Data of Form
   */
  queryForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private workflowReportService: WorkflowReportService,
    private modalService: NgbModal
  ) {
    this.createForm();
    this.modalRef = { ref: null, data: { text: '' } };
  }

  ngOnInit() {
    this.sortFields = { name: 'ASC', email: 'ASC', statusText: 'ASC' };
    this.configList = [
      {
        name: 'ID',
        klass: '',
        type: 'text',
        fieldId: 'id',
        isSortable: false
      },
      {
        name: 'Nome do fluxo',
        type: 'text',
        fieldId: 'name',
        isSortable: false
      },
      {
        name: 'Status do fluxo',
        type: 'text',
        fieldId: 'status.text',
        isSortable: false
      },
      {
        name: 'Nome e status dos destinatários',
        type: 'text',
        fieldId: 'workflowSteps',
        isSortable: false,
        width: '30%'
      }
    ];

    this.sentOrReceivedFilter = [
      {
        value: '',
        label: 'Selecione'
      },
      {
        value: 0,
        label: 'Enviados'
      },
      {
        value: 1,
        label: 'Recebidos'
      }
    ];

    this.statusFilter = [
      {
        value: 0,
        label: 'Todos'
      },
      {
        value: 6,
        label: 'Assinado'
      },
      {
        value: 1,
        label: 'Aguardando assinatura'
      },
      {
        value: 2,
        label: 'Cancelado'
      },
      {
        value: 3,
        label: 'Expirado'
      },
      {
        value: 4,
        label: 'Rejeitado'
      }
    ];

    this.periodFilter = [
      {
        value: '',
        label: 'Período'
      },
      {
        value: 1,
        label: '1 Dia'
      },
      {
        value: 7,
        label: '1 Semana'
      },
      {
        value: 30,
        label: '30 Dias'
      },
      {
        value: 60,
        label: '60 Dias'
      }
    ];
  }

  private setTimeout(): void {
    setTimeout(
      (() => {
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );
  }

  private open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  public closeModal(): void {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  public doSubmit(): void {
    this.modalRef.data.text = 'carregando dados, aguarde...';
    this.setTimeout();
    this.queryForm.controls['download'].setValue('0');
    this.getWorkflowReport(this.mountPayload(this.queryForm.value));
  }

  public downloadFile(): void {
    this.modalRef.data.text = 'Gerando arquivo CSV, aguarde...';
    this.setTimeout();
    this.queryForm.controls['download'].setValue('1');
    this.getFileCsv(this.mountPayload(this.queryForm.value));
  }

  private getFileCsv(payload: workflowReport.FormPayload) {
    this.workflowReportService.getFileCsv(payload).subscribe(
      (response: any) => {
        this.handleFile(response, 'Relatóro de fluxo.csv');
      },
      (error: any) => {
        this.closeModal();
      }
    );
  }

  private getWorkflowReport(payload: workflowReport.FormPayload): void {
    this.workflowReportService.getReport(payload).subscribe(
      (response: workflowReport.ReportCollection) => {
        this.report = response.report;
        this.closeModal();
      },
      (error: any) => {
        this.closeModal();
      }
    );
  }

  private handleFile(res: any, fileName: string): void {
    const file = new Blob([res], { type: res.type });

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = fileName;

    // link.click();
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // firefox
    setTimeout(() => {
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);

    this.closeModal();
  }

  private mountPayload(data: any): workflowReport.FormPayload {
    const payload = {
      period: data.period,
      status: data.status,
      download: data.download,
      received: data.sentOrReceived
    };

    return payload;
  }

  get f() {
    return this.queryForm.controls;
  }

  private createForm(): void {
    this.queryForm = this.formBuilder.group({
      sentOrReceived: ['', Validators.required],
      status: ['0'],
      period: ['', Validators.required],
      download: ['0']
    });
  }
}
