import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { State, User } from '@app/core/models';
import { StateService } from '@app/core/services';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})
export class OrganizationFormComponent implements OnInit, OnChanges {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * Data of current user
   */
  @Input() selected: User.FormPayload;

  /**
   * Form businessUnit
   */
  userData: FormGroup;

  /**
   * roleDefault of user
   */
  roleDefault: boolean;

  /**
   * Block Input
   */
  isReadonly: boolean;

  stateList: Array<State.FormPayload>;

  /**
   * Callback with data of submit
   */
  @Output() doActionSubmit? = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const states = this.stateService.list();
    this.stateList = [{ id: '', name: 'Estado' } as State.FormPayload].concat(
      states
    );
    if (this.userData.controls.roleDefault.value === 'user') {
      this.isReadonly = true;
      return (this.roleDefault = false);
    }
    this.isReadonly = false;
    return (this.roleDefault = true);
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.doActionSubmit.emit({ values: this.userData.value });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.userData.controls.businessUnit['controls'];
  }
  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.userData = this.formBuilder.group({
      roleDefault: [''],
      businessUnit: this.formBuilder.group({
        document: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(14)
          ])
        ],
        name: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(8)
          ])
        ]
      })
    });
  }

  /**
   * Fill the form.
   * Need to remove de + from the
   *  to not conflict with ngmask and reactive form validation.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      if (this.selected.businessUnit) {
        this.userData.patchValue(this.selected);
        this.userData.controls.businessUnit.patchValue(
          this.selected.businessUnit
        );
      }
    }
  }
}
