import { FormBuilder, Validators } from '@angular/forms';
var DepartmentFormComponent = /** @class */ (function () {
    function DepartmentFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.createForm();
    }
    /**
     * Data selection action by clicking the form submit button
     */
    DepartmentFormComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'department',
            formData: this.departmentForm.value
        });
    };
    Object.defineProperty(DepartmentFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.departmentForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    DepartmentFormComponent.prototype.createForm = function () {
        this.departmentForm = this.formBuilder.group({
            name: ['', Validators.required]
        });
    };
    return DepartmentFormComponent;
}());
export { DepartmentFormComponent };
