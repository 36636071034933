import { Component, OnInit } from '@angular/core';
import { PolicyDocument } from '@app/core/models';
import { CookiesService } from '@app/core/services';
import { Documents } from 'assets/policy/document';

@Component({
  selector: 'app-layout-default-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private cookieKey: string = '__accept_policy';

  public termsOfUse: PolicyDocument.File;
  public cookies: PolicyDocument.File;
  public privacyPolicy: PolicyDocument.File;

  public shouoldShowCookieMessage: boolean = false;

  constructor(private cookiesService: CookiesService) {
    this.termsOfUse = Documents.termsOfUse();
    this.cookies = Documents.cookies();
    this.privacyPolicy = Documents.privacyPolicy();

    this.shouoldShowCookieMessage = this.cookiesService.getCookieValue(
      this.cookieKey
    );
  }

  ngOnInit() {
    this.verifyToShowCookieMessage();
  }

  public shouoldShowCookie(): boolean {
    return !this.shouoldShowCookieMessage;
  }

  public verifyToShowCookieMessage(): void {
    this.cookiesService.sign.subscribe((response: any) => {
      if (response) {
        this.shouoldShowCookieMessage = true;
        return;
      }
    });
  }
}
