<div class="wrapper-auth">
  <div class="hero">
    <h2><span class="brand inverse large">Assine Online</span></h2>
    <h3>
      Desburocratize sua empresa e seus processos. Assine documentos
      digitalmente.
    </h3>

    <a href="https://assine.online" class="btn btn-outline-light btn-rounded"
      >Entenda mais</a
    >
  </div>
  <div class="container-form">
    <div class="form">
      <router-outlet></router-outlet>
      <p>&nbsp;</p>
      <p *ngIf="removeRegister">
        Ainda não tem uma conta? <a routerLink="/register/plan">Crie agora!</a>
      </p>
    </div>
  </div>
</div>
