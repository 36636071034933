<div class="page page-dashboard">
  <div class="alerts">
    <div class="alert alert-primary" *ngIf="canEdit">
      <div class="container">
        <div class="message">
          <i class="fa fa-pencil-alt"></i>
          <strong>Modo de edição -</strong> Adicione ou retire os paineis de sua
          preferência
        </div>
        <div class="action">
          <button (click)="cancelChanges()" class="btn btn-default">
            Cancelar alterações
          </button>
          <button (click)="saveData()" class="btn btn-outline-light">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row" [ngStyle]="{ 'margin-top': canEdit ? '40px' : '0' }">
      <div class="col-12 d-md-none">
        <div class="dashboard-upload">
          <app-upload
            buttonText="Novo documento"
            dragDropText="Arraste e solte seus documentos aqui para começar"
            showModalStatus="true"
            showRealtimeLoading="true"
            [layout]="'small'"
            (processFiles)="createDocument()"
            (uploadStarted)="countFiles($event)"
            (click)="emptyAddedFiles()"
          >
          </app-upload>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="dashboard-statistics">
          <app-statistics
            [canEdit]="canEdit"
            [allItems]="allItems"
            [available]="available"
            [unavailable]="unavailable"
            [nowrap]="true"
            (rearrangeAvailable)="receiverData($event)"
            (currentAvailable)="changeData($event)"
          ></app-statistics>
        </div>
      </div>

      <div class="col-12 col-md-5 d-none d-md-block">
        <div class="dashboard-upload">
          <app-upload
            buttonText="Novo documento"
            dragDropText="Arraste e solte seus documentos aqui para começar"
            showModalStatus="true"
            [layout]="'small'"
            (processFiles)="createDocument()"
            (uploadStarted)="countFiles($event)"
            (click)="emptyAddedFiles()"
          ></app-upload>
        </div>
      </div>
    </div>

    <div class="row dashboard-row">
      <div class="col-12 col-md-7">
        <div class="dashboard-events">
          <app-events
            [filterOptions]="filterOptions"
            [data]="dataEvents"
            [actionOption]="{
              id: '/manager/manage/workflow',
              name: 'Ver lista completa'
            }"
            (filter)="filterWorkflow($event)"
            (actionEvent)="doActionEvent($event)"
          ></app-events>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="dashboard-chart">
          <app-chart
            [title]="'Atividades do mês'"
            [type]="'donnut'"
            [showFooter]="true"
            [filterOptions]="filterOptions"
            [data]="dataChart"
            (filter)="filterChart($event)"
          ></app-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalEasySign let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Complete seu cadastro'"
  >
    <app-update-easy-sign [logged]="logged" [modal]="modal">
    </app-update-easy-sign>
  </app-modal-action>
</ng-template>

<ng-template #alertsModal let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Novidade'"
  >
    <div class="alerts-modal" *ngIf="alertsModal?.alert">
      <div class="alerts-modal__body">
        <div
          class="alerts-modal__alert"
          *ngIf="alertsModal?.alert === 'medicines'"
        >
          <a href="/manager/profile" title="Clique aqui">
            <img
              class="img-fluid"
              src="assets/alerts/medicines.svg"
              [alt]="alertsModal?.alert"
            />
          </a>
        </div>
      </div>
      <div class="alerts-modal__footer text-right">
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="closeAlertsModal()"
        >
          Fechar
        </button>
      </div>
    </div>
  </app-modal-action>
</ng-template>
