import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import { RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@env/environment';
registerLocaleData(localePT, 'pt');
var ɵ0 = {
    siteKey: environment.googleRecaptcha.siteKeyV2
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
