import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
var LocaldataService = /** @class */ (function (_super) {
    tslib_1.__extends(LocaldataService, _super);
    function LocaldataService(httpClient, authorizationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authorizationService = authorizationService;
        _this._localStorageKey = 'localData';
        _this._metaDataKey = 'localData';
        return _this;
    }
    LocaldataService.prototype.setInLocalStorage = function (data) {
        if (!data) {
            return false;
        }
        return localStorage.setItem(this._localStorageKey, JSON.stringify(data));
    };
    LocaldataService.prototype.getFromLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var localData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localData = localStorage.getItem(this._localStorageKey);
                        if (!!localData) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getFromApi()];
                    case 1:
                        localData = _a.sent();
                        if (!localData) {
                            return [2 /*return*/, {}];
                        }
                        this.setInLocalStorage(JSON.parse(localData));
                        _a.label = 2;
                    case 2: return [2 /*return*/, JSON.parse(localData)];
                }
            });
        });
    };
    LocaldataService.prototype.removeFromLocalStorage = function () {
        return localStorage.removeItem(this._localStorageKey);
    };
    LocaldataService.prototype.clearLocal = function () {
        return this.removeFromLocalStorage();
    };
    LocaldataService.prototype.getFromApi = function () {
        var _this = this;
        return this.httpClient
            .get('/user-metadata')
            .pipe(map(function (body) {
            var localData = {
                id: '',
                data: {}
            };
            body._embedded.user_metadata.forEach(function (item) {
                if (item.dataKey === _this._metaDataKey) {
                    localData.data = JSON.parse(item.dataValue);
                    localData.id = item.id;
                }
            });
            return JSON.stringify(localData);
        }))
            .toPromise();
    };
    LocaldataService.prototype.commitInApi = function (localData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!localData.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateMetadata(localData.id, {
                                dataKey: this._metaDataKey,
                                dataValue: JSON.stringify(localData.data)
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.createMetadata({
                            dataKey: this._metaDataKey,
                            dataValue: JSON.stringify(localData.data)
                        })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocaldataService.prototype.updateMetadata = function (id, payload) {
        return this.httpClient
            .patch("/user-metadata/" + id, payload)
            .pipe(map(function (body) {
            return body.id;
        }))
            .toPromise();
    };
    LocaldataService.prototype.createMetadata = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.httpClient
                        .post('/user-metadata', payload)
                        .pipe(map(function (body) {
                        return body.id;
                    }))
                        .toPromise()];
            });
        });
    };
    LocaldataService.prototype.getItem = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var localData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFromLocalStorage()];
                    case 1:
                        localData = _a.sent();
                        return [2 /*return*/, localData.data[item] || {}];
                }
            });
        });
    };
    LocaldataService.prototype.setItem = function (item, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var localData, _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getFromLocalStorage()];
                    case 1:
                        localData = _b.sent();
                        if (!localData) {
                            return [2 /*return*/, ''];
                        }
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        localData.data[item] = value;
                        _a = localData;
                        return [4 /*yield*/, this.commitInApi(localData)];
                    case 3:
                        _a.id = _b.sent();
                        this.setInLocalStorage(localData);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        this.removeFromLocalStorage();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return LocaldataService;
}(BaseAPI));
export { LocaldataService };
