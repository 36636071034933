<div class="box">
  <div class="box-header"><h3>Eventos Recentes</h3></div>
  <div class="box-content">
    <app-list-events
      layout="default"
      [events]="data?.items"
      [showInitials]="true"
      (action)="goTo($event)"
    >
    </app-list-events>
  </div>
  <div class="box-footer">
    <div class="filter-actions">
      <div class="filter">
        <select
          class="form-control form-control-sm"
          [(ngModel)]="filterSelected"
        >
          <option *ngFor="let item of filterOptions.options" [value]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="action">
        <a [routerLink]="actionOption.id">
          {{ actionOption.name }} <span>&rarr;</span>
        </a>
      </div>
    </div>
  </div>
</div>
