import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FeedbackService, WorkflowBuildService, WORKFLOW_STEP_FIELDS } from '@app/core/services';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
var WorkflowDroppableFieldsZoneComponent = /** @class */ (function () {
    function WorkflowDroppableFieldsZoneComponent(workflowBuildService, feedbackService) {
        this.feedbackService = feedbackService;
        this.workflowBuildService = workflowBuildService;
        this.fields = [];
    }
    WorkflowDroppableFieldsZoneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var file = this.workflowBuildService.getVisibleFile();
        this.workTheFileOut(file);
        this.workflowBuildService.observeVisibleFile().subscribe(function (visibleFile) {
            _this.workTheFileOut(visibleFile);
        });
        this.workflowBuildService.getFieldAddedByClick().subscribe(function () {
            _this.onDrop(_this.workflowBuildService.getDataFieldAddedByClick());
        });
        this.feedbackService.clear();
    };
    WorkflowDroppableFieldsZoneComponent.prototype.ngOnDestroy = function () {
        if (this.observedFilePages) {
            this.observedFilePages.unsubscribe();
        }
        if (this.observedFilePagesFields) {
            this.observedFilePagesFields.unsubscribe();
        }
        this.feedbackService.clear();
    };
    WorkflowDroppableFieldsZoneComponent.prototype.onSpreadFieldAllPages = function (field) {
        var visibleFile = this.workflowBuildService.getVisibleFile();
        for (var index = 1; index <= this.pdfTotalPages;) {
            if (index > 1) {
                var pageField = Object.create(field);
                pageField.setPageNumber(index);
                visibleFile.getPage(index).addField(pageField);
            }
            index++;
        }
        switch (field.getType()) {
            case WORKFLOW_STEP_FIELDS.TYPE_RUBRIC:
                this.feedbackService.success('Rubrica replicada com sucesso!');
                break;
            case WORKFLOW_STEP_FIELDS.TYPE_STAMP:
                this.feedbackService.success('Carimbo replicada com sucesso!');
                break;
            default:
                throw new Error("The informed field type " + field.getType() + " is invalid!");
        }
        this.feedbackService.clear();
    };
    WorkflowDroppableFieldsZoneComponent.prototype.onDrop = function ($event) {
        var _a;
        var contact = $event.item.data.contact;
        var field = $event.item.data.field;
        var droppedField = WorkflowDroppedFieldsStrategy.MakeDroppedField(field.type, contact, field.signatureType);
        var visibleFile = this.workflowBuildService.getVisibleFile();
        var currentPage = visibleFile.getCurrentPage();
        droppedField.setViewportProportion(currentPage.getProportion());
        var type = this.workflowBuildService.getInsertionType();
        var x, y;
        (_a = this.pinpointDropCoordinates(droppedField), x = _a.x, y = _a.y);
        if (type !== 'drop') {
            x = 0;
            y = 0;
        }
        droppedField.setCoordinates(x, y);
        currentPage.addField(droppedField);
    };
    WorkflowDroppableFieldsZoneComponent.prototype.pinpointDropCoordinates = function (droppedField) {
        var cursorPosition = this.workflowBuildService.getCursorPosition();
        var dzElem = this.dropzoneElem.nativeElement;
        var dzElemBoundaries = dzElem.getBoundingClientRect();
        // Get the cursor position relative to the dropzone box
        var cursorX = cursorPosition.x - dzElemBoundaries.left;
        var cursorY = cursorPosition.y - dzElemBoundaries.top;
        var droppedFieldWidth = droppedField.getWidth();
        var droppedFieldHeight = droppedField.getHeight();
        // Center the field around the mouse cursor
        var xCoordinate = cursorX - droppedFieldWidth / 2;
        var yCoordinate = cursorY - droppedFieldHeight / 2;
        // Check if the field got dropped outside the dropzone and put it back in
        if (xCoordinate < 1) {
            xCoordinate = 1;
        }
        else if (xCoordinate + droppedFieldWidth > dzElem.clientWidth) {
            xCoordinate = dzElem.clientWidth - droppedFieldWidth;
        }
        if (yCoordinate < 1) {
            yCoordinate = 1;
        }
        else if (yCoordinate + droppedFieldHeight > dzElem.clientHeight) {
            yCoordinate = dzElem.clientHeight - droppedFieldHeight;
        }
        return { x: xCoordinate, y: yCoordinate };
    };
    WorkflowDroppableFieldsZoneComponent.prototype.workTheFileOut = function (file) {
        if (!file) {
            return;
        }
        this.associateFilePageFields(file);
        this.getReadyForFilePageChanges(file);
        this.observeForPageFieldsChanges(file.getCurrentPage());
    };
    WorkflowDroppableFieldsZoneComponent.prototype.getReadyForFilePageChanges = function (file) {
        var _this = this;
        if (!!this.observedFilePages) {
            this.observedFilePages.unsubscribe();
        }
        if (!file) {
            this.fields = [];
            return;
        }
        this.observedFilePages = file.observePageChange().subscribe(function (page) {
            _this.fields = page.getFields();
            _this.observeForPageFieldsChanges(page);
        });
    };
    WorkflowDroppableFieldsZoneComponent.prototype.associateFilePageFields = function (file) {
        if (!file) {
            this.fields = [];
            return;
        }
        var currentPage = file.getCurrentPage();
        if (!currentPage) {
            this.fields = [];
            return;
        }
        this.fields = currentPage.getFields();
    };
    WorkflowDroppableFieldsZoneComponent.prototype.observeForPageFieldsChanges = function (page) {
        var _this = this;
        if (!!this.observedFilePagesFields) {
            this.observedFilePagesFields.unsubscribe();
        }
        if (!page) {
            this.fields = [];
            return;
        }
        this.observedFilePagesFields = page.observeFields().subscribe(function (fields) {
            _this.fields = fields;
        });
    };
    return WorkflowDroppableFieldsZoneComponent;
}());
export { WorkflowDroppableFieldsZoneComponent };
