<div class="containe">
  <p>Enviado por:</p>
  <p class="name">{{ owner.name }}</p>
  <p>{{ owner.email }}</p>

  <div class="form-group form-check mt-3">
    <input
      type="checkbox"
      class="form-check-input"
      id="check1"
      [formControl]="acceptPolicy"
      (click)="checkPolicyField()"
    />
    <label class="form-check-label" for="check1"
      >Li e concordo com os
      <a
        [href]="termsOfUse.path"
        [download]="termsOfUse.filename"
        [title]="termsOfUse.title"
        >Termos de uso</a
      >
      e
      <a
        [href]="privacyPolicy.path"
        [download]="privacyPolicy.filename"
        [title]="privacyPolicy.title"
        >Política de privacidade</a
      ></label
    >
  </div>

  <div class="mb-3" [ngbCollapse]="getCollapsed()">
    <span><i class="fas fa-arrow-up"></i></span>
    <p class="collapse-text">
      Para visualizar e assinar o documento é necessário aceitar os Termos de
      uso e Política de privacidade
    </p>
  </div>

  <button
    type="button"
    [ngClass]="getAcceptPolicy() ? 'btn-secondary' : 'btn-disabled'"
    class="btn btn-lg btn-block mt-4"
    (click)="toLookDocument()"
  >
    Ver documento
  </button>
</div>
