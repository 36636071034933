<div class="box">
  <div class="box-header">
    <h3>{{ title }}</h3>
  </div>
  <div class="box-content">
    <app-donnut-chart
      *ngIf="type === 'donnut'"
      [data]="data"
    ></app-donnut-chart>
    <app-line-chart *ngIf="type === 'line'" [data]="data"></app-line-chart>
  </div>
  <div class="box-footer" *ngIf="showFooter">
    <div class="filter-actions">
      <div class="filter" *ngIf="filterOptions">
        <select
          class="form-control form-control-sm"
          [(ngModel)]="filterSelected"
        >
          <option *ngFor="let item of filterOptions.options" [value]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="action" *ngIf="actionOption">
        <a [routerLink]="actionOption.id">
          {{ actionOption.name }} <span>&rarr;</span>
        </a>
      </div>
    </div>
  </div>
</div>
