<div
  cdkDropList
  class="subscribers"
  (cdkDropListDropped)="drop($event)"
  *ngIf="isSortable"
>
  <div
    class="subscribe-item {{ item.type | signAction: 'action' }}"
    *ngFor="let item of items; let i = index"
    cdkDrag
  >
    <span class="number">{{ i + 1 }}</span>
    <span class="type">{{ item.type | signAction: 'text' }}</span>
    <span class="name">
      <strong>{{ item.name }}</strong> - {{ item.email }}
    </span>
    <span class="actions">
      <a (click)="doEditAction(item)"><i class="fa fa-pencil-alt"></i></a>
      <a (click)="doDeleteAction(item)"><i class="fa fa-times-circle"></i></a>
    </span>
  </div>
</div>

<div *ngIf="!isSortable">
  <div class="box mb-3" *ngFor="let item of items; let i = index">
    <div class="box-content">
      <div class="row">
        <div
          class="col col-10 col-sm-11 col-md-10 col-lg-11 col-xl-11 custom-signer"
        >
          <div class="row align-items-center" (click)="getUserAndStep(item)">
            <div class="col col-1 col-sm-1 col-lg-1 col-xl-1">
              <div
                *ngIf="item.status.action === CHECK_WORKFLOW_STEP.WAITING"
                class="waiting-sign"
              >
                <i class="fas fa-stopwatch fa-sm"></i>
              </div>
              <div
                *ngIf="item.status.action === CHECK_WORKFLOW_STEP.DONE"
                class="signed"
              >
                <i class="fas fa-check fa-sm"></i>
              </div>
              <div
                *ngIf="item.status.action === CHECK_WORKFLOW_STEP.REJECT"
                class="rejected"
              >
                <i class="fas fa-ban"></i>
              </div>
              <div
                *ngIf="item.status.action === CHECK_WORKFLOW_STEP.PROCESSING"
                class="processing"
              >
                <i class="fas fa-sync-alt"></i>
              </div>
            </div>

            <div class="col col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3">
              <h6 class="text-truncate mb-0" [title]="item.name">
                {{ item.name }}
              </h6>
            </div>

            <div class="col col-5 col-sm-5 col-lg-4 col-xl-5 d-none d-xl-block">
              <h6 class="text-nowrap mb-0">
                <i
                  class="fas fa-pen fa-lg pencil"
                  *ngIf="item.status.action === CHECK_WORKFLOW_STEP.WAITING"
                ></i>
                {{ item.email }}
              </h6>
            </div>

            <div
              class="col col-5 col-sm-5 col-lg-4 col-xl-3 d-none d-xl-block "
            >
              <h6 class="text-truncate mb-0" [title]="item.status.text">
                {{ item.status.text }}
                <i
                  *ngIf="item.reason && item.status.action === 'reject'"
                  [title]="item.reason"
                  class="fas fa-info-circle"
                ></i>
              </h6>
            </div>

            <div
              class="col col-7 col-sm-7 col-md-6 col-lg-7 col-xl-4 d-block d-xl-none"
            >
              <div class="row align-items-center email-margin">
                <div
                  class="col col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                  *ngIf="item.status.action === CHECK_WORKFLOW_STEP.WAITING"
                >
                  <i class="fas fa-pen fa-lg pencil"></i>
                </div>
                <div class="col col-7 col-sm-10 col-md-9 col-lg-8 col-xl-4 ">
                  <h6 class="mb-0">{{ item.email }}</h6>
                  <h6 class="text-nowrap mb-0 mt-1" [title]="item.status.text">
                    {{ item.status.text }}
                    <i
                      *ngIf="item.reason && item.status.action === 'reject'"
                      [title]="item.reason"
                      class="fas fa-info-circle"
                    ></i>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="item.extraMetadata != null && item.extraMetadata.photo"
        >
          <div
            class="col col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center"
          >
            <i
              *ngIf="item.status.text === 'Assinado'"
              class="far fa-image photo-position"
              title="Foto do Assinante"
              (click)="openPhoto(item.extraMetadata.photoData)"
            ></i>
            <i
              *ngIf="item.status.text !== 'Assinado'"
              class="far fa-image"
              title="Aguardando foto"
              (click)="openPhoto(item.extraMetadata.photoData)"
            ></i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #modalReplaceSubscriber let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Editar assinante'"
  >
    <div class="container">
      <form action="" (ngSubmit)="doSubmit()" [formGroup]="userForm" novalidate>
        <div class="form-group">
          <label>Nome do assinante</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nome do assinante"
            formControlName="name"
          />
          <div *ngIf="f.name.errors && !f.name.pristine">
            <span class="error" [hidden]="!f.name.errors.required">
              O nome completo é obrigatório
            </span>
          </div>
        </div>

        <div class="form-group">
          <label>E-mail do assinante</label>
          <input
            type="text"
            class="form-control"
            placeholder="E-mail do assinante"
            formControlName="email"
          />
          <div *ngIf="f.email.errors && !f.email.pristine">
            <span class="error" [hidden]="!f.email.errors.required">
              O e-mail é obrigatório
            </span>
          </div>
        </div>

        <div class="row d-flex justify-content-end">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-sm m-1 btn-outline-success"
              title="Cancelar alteração"
              (click)="toModalCancel()"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-sm m-1 btn-secondary"
              title="Alterar assinante"
              [disabled]="userForm.invalid"
            >
              <i class="fas fa-circle-notch fa-spin" *ngIf="isSavingUser"></i>
              Alterar
            </button>
          </div>
        </div>
      </form>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalFeedbabck let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <div class="container">
      <ng-container *ngIf="stepFeedbabck == PROBLEM_STEP.SUCCESS">
        <div class="message">
          <p>O destinatário foi alterado com sucesso.</p>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-sm m-1 btn-secondary"
              (click)="reloadPage()"
            >
              Ok
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="stepFeedbabck == PROBLEM_STEP.ERROR">
        <div class="message">
          <p>
            Ocorreu um erro inesperado ao realizar alteração do destinatário,
            tente novamente!
          </p>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-sm m-1 btn-secondary"
              (click)="modal.close()"
            >
              Ok
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="stepFeedbabck == PROBLEM_STEP.REJECTED">
        <div class="message">
          <p>
            Não é possível editar destinatário que já tenha realizado alguma
            ação no documento.
          </p>
        </div>

        <div class="row">
          <div class="col-12 d-flex justify-content-end ">
            <button
              type="button"
              class="btn btn-sm m-1 btn-secondary"
              (click)="modal.close()"
            >
              Ok
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </app-modal-action>
</ng-template>

<ng-template #modalShowPhoto let-modal>
  <div class="container">
    <div class="mt-3">
      <img [src]="photoData" class="img-fluid" alt="Foto do assinante" />
    </div>

    <div class="mb-3 mt-3 float-right">
      <button class="btn btn-default" (click)="modal.close('cancel')">
        Fechar
      </button>
    </div>
    <!-- <div class="mb-3 mt-3 ">
      <button
        class="btn btm-sm btn-outline-success"
        (click)="toGetPhoto(item.extraMetadata.photoData)"
      >
        Baixar
      </button>
    </div> -->
  </div>
</ng-template>
