import { Observable, of } from 'rxjs';
import { Authentication } from '@app/core/models/authentication.model';
import { Injectable } from '@angular/core';

const credentialsKey = 'credentials';
const usersKey = 'user';

/**
 * Provides a base for authentication workflow.
 */
@Injectable()
export class AuthorizationService {
  private _credentials: Authentication.Credentials = {};

  private _user: Authentication.SignupUserResponse = null;

  private _tempGuestUser: Authentication.SignupUserResponse = null;

  private _tempAccessToken: string = null;

  private _isIntegration: boolean = false;

  constructor() {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }

    const savedUser = localStorage.getItem(usersKey);
    if (savedUser) {
      this._user = JSON.parse(savedUser);
    }
  }

  /**
   * Checks is reset token is valid.
   * @return True if the token is valid.
   */
  getResetToken(token: string): Observable<boolean> {
    if (token === 'o53McgvNZ6scR72vKZLX') {
      return of(true);
    } else {
      return of(false);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    const credentialsVal = localStorage.getItem(credentialsKey);

    // Nega acesso se não houver credenciais salvas no localStorage
    if (!credentialsVal) {
      return false;
    }

    const credentials = JSON.parse(credentialsVal);

    // Verifica se token foi expirado.
    return !this.hasExpiredToken(credentials);
  }

  isGuest(): boolean {
    return !!this._tempAccessToken;
  }

  isIntegration(isIntegration?: boolean): boolean {
    if (typeof isIntegration !== 'undefined') {
      this._isIntegration = isIntegration;
    }
    return this._isIntegration;
  }

  getAccessToken(): string {
    if (this._tempAccessToken) {
      return this._tempAccessToken;
    }

    return this._credentials ? this._credentials.access_token : null;
  }

  setTempAccessToken(accessToken?: string): void {
    this._tempAccessToken = accessToken;
  }

  setTempGuestUser(user?: Authentication.SignupUserResponse) {
    this._tempGuestUser = user;
  }

  getUser(): Authentication.SignupUserResponse {
    return this._tempGuestUser || this._user;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Authentication.Credentials | null {
    return this._credentials;
  }

  /**
   * Gets the user information.
   * @return The user credentials or null if the user is not authenticated.
   */
  get user(): Observable<Authentication.SignupUserResponse> {
    const data = localStorage.getItem(usersKey);
    if (data) {
      return of(JSON.parse(data));
    }

    return of(null);
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   */
  setCredentials(credentials?: Authentication.Credentials) {
    this._credentials = credentials || null;

    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }

  hasExpiredToken(credentials: Authentication.Credentials): boolean {
    if (!credentials.expires_at) {
      return false;
    }

    const now = new Date();
    const expires_at = new Date(credentials.expires_at);
    return now.getTime() > expires_at.getTime();
  }

  /**
   * Sets the user informations.
   * @param credentials The user credentials.
   */
  setUser(user?: Authentication.SignupUserResponse) {
    if (user) {
      localStorage.setItem(usersKey, JSON.stringify(user));
      const savedUser = localStorage.getItem(usersKey);
      if (savedUser) {
        this._user = JSON.parse(savedUser);
      }
    } else {
      localStorage.removeItem(usersKey);
    }
  }

  deleteCredentials() {
    localStorage.removeItem(credentialsKey);
  }

  deleteUser() {
    localStorage.removeItem(usersKey);
    localStorage.removeItem('localData');
  }
}
