import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
var ContactService = /** @class */ (function (_super) {
    tslib_1.__extends(ContactService, _super);
    function ContactService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/contact';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    ContactService.prototype.searchContacts = function (queryString) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (body) {
            var contacts = body._embedded.contact;
            return contacts.map(function (contact) {
                return {
                    id: contact.id,
                    name: contact._embedded.userTwo.name,
                    email: contact._embedded.userTwo.email,
                    notifyWhatsapp: contact._embedded.userTwo.notifyWhatsapp,
                    whatsappNumber: _this.replaceDDI(contact._embedded.userTwo.whatsappNumber)
                };
            });
        }));
    };
    ContactService.prototype.replaceDDI = function (phone) {
        if (!phone) {
            return null;
        }
        var ddi = phone.replace('+', '').replace('55', '');
        return ddi;
    };
    ContactService.prototype.getAll = function (queryString) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryString).pipe(map(function (response) {
            return _this._parseListContact(response);
        }));
    };
    /**
     * Gets contact by email
     */
    ContactService.prototype.getContactByEmail = function (email) {
        var filter = {
            filter: [
                {
                    type: 'innerjoin',
                    field: 'userTwo',
                    alias: 'u'
                },
                {
                    type: 'eq',
                    alias: 'u',
                    field: 'email',
                    value: "" + email
                }
            ]
        };
        return _super.prototype.getAll.call(this, filter).pipe(map(function (response) {
            if (response.total_items >= 1) {
                var contact = response._embedded.contact[0];
                return {
                    id: contact.id,
                    userId: contact._embedded.userTwo.id,
                    name: contact._embedded.userTwo.name,
                    email: contact._embedded.userTwo.email
                };
            }
            return null;
        }));
    };
    /**
     * Gets contact by name
     */
    ContactService.prototype.getContactsByName = function (name) {
        var _this = this;
        var filter = {
            filter: [
                {
                    type: 'innerjoin',
                    field: 'userTwo',
                    alias: 'u'
                },
                {
                    type: 'like',
                    alias: 'u',
                    field: 'name',
                    value: "%" + name + "%"
                }
            ]
        };
        return _super.prototype.getAll.call(this, filter).pipe(map(function (response) {
            return _this._parseListContact(response);
        }));
    };
    /**
     * Create contact
     */
    ContactService.prototype.addContact = function (contact) {
        var payload = {
            name: contact.name,
            email: contact.email
        };
        return _super.prototype.create.call(this, payload).pipe(map(function (response) {
            var output = { id: null, message: null };
            if (response._embedded) {
                output.id = response._embedded.userTwo.id;
            }
            if (response.detail) {
                output.message = response.detail;
            }
            return output;
        }));
    };
    ContactService.prototype._parseListContact = function (data) {
        var items = data._embedded.contact.map(function (e) {
            return {
                id: e.id,
                status: e.status,
                statusText: e.status ? 'Habilitado' : 'Desabilitado',
                embedded_id: e._embedded.userTwo.id,
                userId: e._embedded.userTwo.id,
                name: e._embedded.userTwo.name,
                email: e._embedded.userTwo.email
            };
        });
        return {
            items: items,
            page: data.page,
            page_count: data.page_count,
            page_size: data.page_size,
            total_items: data.total_items
        };
    };
    return ContactService;
}(BaseAPI));
export { ContactService };
