import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { map } from 'rxjs/operators';
var AccessToken = /** @class */ (function (_super) {
    tslib_1.__extends(AccessToken, _super);
    function AccessToken(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/access-token';
        return _this;
    }
    /**
     * Create access token
     */
    AccessToken.prototype.createAccessToken = function (payload) {
        return this.httpClient.post(this.endpoint, payload).pipe(map(function (body) {
            var responseDate = {
                id: body.id,
                accessToken: body.accessToken,
                expires: body.expires,
                type: body.type,
                title: body.title,
                status: body.status,
                detail: body.detail
            };
            return responseDate;
        }));
    };
    return AccessToken;
}(BaseAPI));
export { AccessToken };
