import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input
} from '@angular/core';
import {
  FeedbackService,
  WorkflowBuildService,
  WORKFLOW_STEP_FIELDS
} from '@app/core/services';
import { WorkflowFile, WorkflowContact } from '@app/core/class';
import { WorkflowFilePage } from '@app/core/class/workflow-file-page.class';
import { Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IDraggableField } from '@app/core/models';
import { WorkflowDroppedFieldsStrategy } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field-strategy.class';
import { IWorkflowDroppedField } from '@app/core/class/workflow-dropped-fields/workflow-dropped-field.interface';

export interface IParsedDroppedField {
  type: number;
  contactOrderIndex: number;
  signatureType: number;
}

@Component({
  selector: 'app-workflow-droppable-fields-zone',
  templateUrl: './droppable-fields-zone.component.html',
  styleUrls: ['./droppable-fields-zone.component.scss']
})
export class WorkflowDroppableFieldsZoneComponent implements OnInit, OnDestroy {
  @ViewChild('dropzone')
  public dropzoneElem: ElementRef;

  @Input()
  public pdfTotalPages: number;

  public fields: IWorkflowDroppedField[];

  private workflowBuildService: WorkflowBuildService;

  private observedFilePages: Subscription;
  private observedFilePagesFields: Subscription;

  constructor(
    workflowBuildService: WorkflowBuildService,
    protected feedbackService: FeedbackService
  ) {
    this.workflowBuildService = workflowBuildService;
    this.fields = [];
  }

  public ngOnInit(): void {
    const file = this.workflowBuildService.getVisibleFile();
    this.workTheFileOut(file);

    this.workflowBuildService.observeVisibleFile().subscribe(visibleFile => {
      this.workTheFileOut(visibleFile);
    });

    this.workflowBuildService.getFieldAddedByClick().subscribe(() => {
      this.onDrop(this.workflowBuildService.getDataFieldAddedByClick());
    });

    this.feedbackService.clear();
  }

  public ngOnDestroy(): void {
    if (this.observedFilePages) {
      this.observedFilePages.unsubscribe();
    }

    if (this.observedFilePagesFields) {
      this.observedFilePagesFields.unsubscribe();
    }

    this.feedbackService.clear();
  }

  public onSpreadFieldAllPages(field: IWorkflowDroppedField): void {
    const visibleFile = this.workflowBuildService.getVisibleFile();
    for (let index = 1; index <= this.pdfTotalPages; ) {
      if (index > 1) {
        const pageField: any = Object.create(field);
        pageField.setPageNumber(index);
        visibleFile.getPage(index).addField(pageField);
      }
      index++;
    }

    switch (field.getType()) {
      case WORKFLOW_STEP_FIELDS.TYPE_RUBRIC:
        this.feedbackService.success('Rubrica replicada com sucesso!');
        break;

      case WORKFLOW_STEP_FIELDS.TYPE_STAMP:
        this.feedbackService.success('Carimbo replicada com sucesso!');
        break;

      default:
        throw new Error(
          `The informed field type ${field.getType()} is invalid!`
        );
    }
    this.feedbackService.clear();
  }

  public onDrop($event: CdkDragDrop<null>): void {
    const contact = $event.item.data.contact as WorkflowContact;
    const field = $event.item.data.field as IDraggableField;

    const droppedField = WorkflowDroppedFieldsStrategy.MakeDroppedField(
      field.type,
      contact,
      field.signatureType
    );

    const visibleFile = this.workflowBuildService.getVisibleFile();
    const currentPage = visibleFile.getCurrentPage();

    droppedField.setViewportProportion(currentPage.getProportion());
    const type = this.workflowBuildService.getInsertionType();
    let x, y;
    ({ x, y } = this.pinpointDropCoordinates(droppedField));
    if (type !== 'drop') {
      x = 0;
      y = 0;
    }
    droppedField.setCoordinates(x, y);

    currentPage.addField(droppedField);
  }

  private pinpointDropCoordinates(
    droppedField: IWorkflowDroppedField
  ): { x: number; y: number } {
    const cursorPosition = this.workflowBuildService.getCursorPosition();

    const dzElem: Element = this.dropzoneElem.nativeElement;

    const dzElemBoundaries = dzElem.getBoundingClientRect();

    // Get the cursor position relative to the dropzone box
    const cursorX = cursorPosition.x - dzElemBoundaries.left;
    const cursorY = cursorPosition.y - dzElemBoundaries.top;

    const droppedFieldWidth = droppedField.getWidth();
    const droppedFieldHeight = droppedField.getHeight();

    // Center the field around the mouse cursor
    let xCoordinate = cursorX - droppedFieldWidth / 2;
    let yCoordinate = cursorY - droppedFieldHeight / 2;

    // Check if the field got dropped outside the dropzone and put it back in
    if (xCoordinate < 1) {
      xCoordinate = 1;
    } else if (xCoordinate + droppedFieldWidth > dzElem.clientWidth) {
      xCoordinate = dzElem.clientWidth - droppedFieldWidth;
    }

    if (yCoordinate < 1) {
      yCoordinate = 1;
    } else if (yCoordinate + droppedFieldHeight > dzElem.clientHeight) {
      yCoordinate = dzElem.clientHeight - droppedFieldHeight;
    }

    return { x: xCoordinate, y: yCoordinate };
  }

  private workTheFileOut(file: WorkflowFile): void {
    if (!file) {
      return;
    }

    this.associateFilePageFields(file);
    this.getReadyForFilePageChanges(file);
    this.observeForPageFieldsChanges(file.getCurrentPage());
  }

  private getReadyForFilePageChanges(file: WorkflowFile): void {
    if (!!this.observedFilePages) {
      this.observedFilePages.unsubscribe();
    }

    if (!file) {
      this.fields = [];
      return;
    }

    this.observedFilePages = file.observePageChange().subscribe(page => {
      this.fields = page.getFields();
      this.observeForPageFieldsChanges(page);
    });
  }

  private associateFilePageFields(file: WorkflowFile): void {
    if (!file) {
      this.fields = [];
      return;
    }

    const currentPage = file.getCurrentPage();
    if (!currentPage) {
      this.fields = [];
      return;
    }

    this.fields = currentPage.getFields();
  }

  private observeForPageFieldsChanges(page: WorkflowFilePage): void {
    if (!!this.observedFilePagesFields) {
      this.observedFilePagesFields.unsubscribe();
    }

    if (!page) {
      this.fields = [];
      return;
    }

    this.observedFilePagesFields = page.observeFields().subscribe(fields => {
      this.fields = fields;
    });
  }
}
