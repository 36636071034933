import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core/class';
import { IntegrationDocuware } from '@app/core/models';
import { IntegrationDocuwareService } from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('Integration');
@Component({
  selector: 'app-integration-docuware-form',
  templateUrl: './integration-docuware-form.component.html',
  styleUrls: ['./integration-docuware-form.component.scss']
})
export class IntegrationDocuwareFormComponent implements OnInit {
  @ViewChild('modalDelete') modalDelete: ElementRef;
  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Form businessUnit
   */
  dataForm: FormGroup;

  integrationData: IntegrationDocuware.FormIntegration;

  /**
   * event emitter to update Feecback Component
   */
  @Output() setFeedbackMessage = new EventEmitter();

  public spinnerWait: boolean = false;

  public showButton: boolean = false;

  private modalRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private integrationDocuwareService: IntegrationDocuwareService,
    private modalService: NgbModal
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
    this.createForm();
  }

  ngOnInit() {
    this.getIntegrationDocuare();
  }

  public doSubmit(): void {
    this.spinnerWait = true;

    if (this.showButton) {
      this.updateIntegrationDocuware(this.payload());
      return;
    }
    this.createIntegrationDocuware(this.payload());
  }

  public onDelete(): void {
    this.open(this.modalDelete, 'md');
  }

  public toDeleteIntegration(): void {
    this.deleteIntegration(this.integrationData.id);
  }

  open(content: any, size?: any) {
    this.modalRef.ref = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return this.modalRef;
  }

  public modalClose(): void {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }

  private getIntegrationDocuare(): void {
    this.integrationDocuwareService.getAll().subscribe(
      (data: IntegrationDocuware.FormIntegration) => {
        if (data) {
          this.showButton = true;
          this.loadIntegrationData(data);
        }
        this.integrationData = data;
        log.debug(`Success [getIntegration]: ${data}`);
      },
      (error: any) => {
        log.debug(`Error [getIntegration]: ${error}`);
      }
    );
  }

  private deleteIntegration(id: string): void {
    this.integrationDocuwareService.deleteIntegration(id).subscribe(
      (data: IntegrationDocuware.FormIntegration) => {
        this.modalClose();
        this.toResetForm();
        this.integrationData = null;
        this.setFeedbackMessageEvent(
          'success',
          'Integração excluída com sucesso'
        );
        log.debug(`Success [deleteIntegration]: ${data}`);
      },
      (error: any) => {
        this.modalClose();
        log.debug(`Error [deleteIntegration]: ${error}`);
      }
    );
  }

  private updateIntegrationDocuware(
    payload: IntegrationDocuware.Payload
  ): void {
    this.integrationDocuwareService
      .updateIntegration(this.integrationData.id, payload)
      .subscribe(
        (data: IntegrationDocuware.FormIntegration) => {
          if (data) {
            this.showButton = true;
            this.spinnerWait = false;
            this.loadIntegrationData(data);
          }

          this.integrationData = data;
          this.setFeedbackMessageEvent(
            'success',
            'Atualização realizada com sucesso '
          );
          log.debug(`Success [updateIntegration]: ${data}`);
        },
        (error: any) => {
          log.debug(`Error [updateIntegration]: ${error}`);
        }
      );
  }

  private createIntegrationDocuware(
    payload: IntegrationDocuware.Payload
  ): void {
    this.integrationDocuwareService.create(payload).subscribe(
      (reponse: any) => {
        this.spinnerWait = false;
        this.setFeedbackMessageEvent(
          'success',
          'Integração realizada com sucesso '
        );
        log.debug(`Success [createIntegration]: ${reponse}`);
      },
      (error: any) => {
        this.spinnerWait = false;
        this.feedbackTheme = 'error';
        this.feedbackMessage =
          'Não foi possível realizar a integração, Tente novamente';
        if (error.error.status === 400) {
          this.spinnerWait = false;
        } else {
          this.spinnerWait = false;
        }
        log.debug(`Error [createIntegration]: ${error}`);
      }
    );
  }

  private setFeedbackMessageEvent(theme: string, message: string): void {
    this.setFeedbackMessage.emit({
      theme: theme,
      message: message
    });

    this.modal.close({
      action: 'cancel',
      form: 'integration-docuware',
      formData: ''
    });
  }

  private payload(): IntegrationDocuware.Payload {
    const payloadData: IntegrationDocuware.Payload = {
      fileCabinetId: this.dataForm.controls.fileCabinetId.value,
      serverUrl: this.dataForm.controls.serverUrl.value,
      username: this.dataForm.controls.username.value,
      password: this.dataForm.controls.password.value
    };
    return payloadData;
  }

  private loadIntegrationData(data: IntegrationDocuware.FormIntegration): void {
    this.dataForm.setValue({
      fileCabinetId: data.fileCabinetId,
      serverUrl: data.serverUrl,
      username: data.username,
      password: null
    });
  }

  /**
   * Reset form
   */
  public toResetForm() {
    this.dataForm.reset();
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.dataForm.controls;
  }
  /**
   * Form creation and validation configuration
   */
  private createForm(): void {
    this.dataForm = this.formBuilder.group({
      fileCabinetId: ['', Validators.required],
      serverUrl: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
