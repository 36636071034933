import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var AlertSubscription = /** @class */ (function () {
    function AlertSubscription() {
        this.subject = new Subject();
    }
    AlertSubscription.prototype.update = function (data) {
        this.subject.next(data);
    };
    AlertSubscription.prototype.getAlerts = function () {
        return this.subject.asObservable();
    };
    AlertSubscription.ngInjectableDef = i0.defineInjectable({ factory: function AlertSubscription_Factory() { return new AlertSubscription(); }, token: AlertSubscription, providedIn: "root" });
    return AlertSubscription;
}());
export { AlertSubscription };
