import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
var WorkflowStepUserUpdateService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowStepUserUpdateService, _super);
    function WorkflowStepUserUpdateService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/workflow-step-user-update';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    WorkflowStepUserUpdateService.prototype.updateWorkflowUser = function (idWorkflowStep, data) {
        return _super.prototype.update.call(this, idWorkflowStep, data).pipe(map(function (response) {
            return response;
        }));
    };
    return WorkflowStepUserUpdateService;
}(BaseAPI));
export { WorkflowStepUserUpdateService };
