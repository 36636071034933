import * as tslib_1 from "tslib";
import { ReviewPageComponent } from '@app/manager/pages';
import { FolderService, AuthorizationService, WorkflowBuildService, DocumentService, FeedbackService, WorkflowTemplateService, RemainingUserBalanceService } from '@app/core/services';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var IntegrationReviewPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationReviewPageComponent, _super);
    function IntegrationReviewPageComponent(formBuilder, folderService, authorizationService, workflowBuildService, workflowTemplateService, documentService, feedbackService, router, route, modalService, remainingUserBalance) {
        var _this = _super.call(this, formBuilder, folderService, authorizationService, workflowBuildService, workflowTemplateService, documentService, feedbackService, router, modalService, remainingUserBalance) || this;
        _this.formBuilder = formBuilder;
        _this.folderService = folderService;
        _this.authorizationService = authorizationService;
        _this.workflowBuildService = workflowBuildService;
        _this.workflowTemplateService = workflowTemplateService;
        _this.documentService = documentService;
        _this.feedbackService = feedbackService;
        _this.router = router;
        _this.route = route;
        _this.modalService = modalService;
        _this.remainingUserBalance = remainingUserBalance;
        _this.showFolderSelector = false;
        _this.ShowSaveWorkflowTemplate = false;
        _this.buildURL = '/integration/workflow';
        _this.finishURL = '/integration/workflow/finish';
        return _this;
    }
    IntegrationReviewPageComponent.prototype.fetchFolders = function () {
        return;
    };
    IntegrationReviewPageComponent.prototype.saveWorkflow = function (workflow) {
        var _this = this;
        var templateId = this.route.snapshot.queryParams['id'];
        var templateToken = this.route.snapshot.queryParams['token'];
        this.isSavingWorkflow = true;
        var subscription = this.workflowTemplateService
            .updateWorkflowTemplate(templateId, workflow, templateToken)
            .subscribe(function (response) {
            _this.feedbackService.clear();
            _this.isSavingWorkflow = false;
            _this.workflowBuildService.setWorkflowId(templateId);
            _this.workflowBuildService.resetWorkflow();
            subscription.unsubscribe();
            _this.goToFinishPage();
        }, function (request) {
            _this.isSavingWorkflow = false;
            _this.feedbackService.error(request.error.detail);
            subscription.unsubscribe();
        });
    };
    return IntegrationReviewPageComponent;
}(ReviewPageComponent));
export { IntegrationReviewPageComponent };
