/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../shared/layouts/manager/footer/footer.component.ngfactory";
import * as i3 from "../shared/layouts/manager/footer/footer.component";
import * as i4 from "./integration.component";
import * as i5 from "../core/services/authorization.service";
var styles_IntegrationComponent = [];
var RenderType_IntegrationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IntegrationComponent, data: {} });
export { RenderType_IntegrationComponent as RenderType_IntegrationComponent };
export function View_IntegrationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-layout-manager-footer", [], null, null, null, i2.View_LayoutManagerFooterComponent_0, i2.RenderType_LayoutManagerFooterComponent)), i0.ɵdid(4, 114688, null, 0, i3.LayoutManagerFooterComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_IntegrationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-integration", [], null, null, null, View_IntegrationComponent_0, RenderType_IntegrationComponent)), i0.ɵdid(1, 49152, null, 0, i4.IntegrationComponent, [i5.AuthorizationService], null, null)], null, null); }
var IntegrationComponentNgFactory = i0.ɵccf("app-integration", i4.IntegrationComponent, View_IntegrationComponent_Host_0, {}, {}, []);
export { IntegrationComponentNgFactory as IntegrationComponentNgFactory };
