<div *ngIf="step === 1">
  <h3>Olá, {{ userData.name }}</h3>
  <p>
    Notamos que você já usou o Assine Online.<br />
    Enviamos um e-mail com um código pin, para você completar seu cadastro.
  </p>
  <form (ngSubmit)="createUser()" [formGroup]="activateUserForm" novalidate>
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="code-prev"
            >{{ apiDetails?.pinPrefix }}-</span
          >
        </div>
        <input
          type="text"
          name="pin"
          class="form-control"
          formControlName="code"
          [placeholder]="'Código' | translate"
          autocomplete="false"
        />
      </div>
      <p
        [hidden]="
          activateUserForm.controls.code.valid ||
          activateUserForm.controls.code.untouched
        "
        class="text-danger"
        translate
      >
        O código é necessário
      </p>
    </div>
    <a href="/register" class="btn btn-sm btn-outline-success">Voltar</a>

    <button
      class="btn btn-secondary btn-sm"
      type="submit"
      [disabled]="activateUserForm.invalid || isLoading"
    >
      <i class="fas fa-spinner fa-spin" [hidden]="!isLoading"></i>
      <span translate>Finalizar Cadastro</span>
    </button>
  </form>
</div>
<div *ngIf="step === 2">
  <h3>Cadastro realizado com sucesso.</h3>
  <p>Agora você pode aproveitar todos os benefícios do Assine Online.</p>
  <a href="/auth" class="btn btn-sm btn-outline-success"
    >Clique aqui para fazer Login</a
  >
</div>
<div *ngIf="step === 3">
  <h3>Houve um erro! :-(</h3>
  <p>Por favor tente novamente ou entre em contato com o suporte.</p>
  <a href="/register" class="btn btn-sm btn-outline-success">Voltar</a>
</div>
