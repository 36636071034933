import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

import {
  StatisticService,
  WorkflowService,
  DocumentService,
  FolderService,
  WorkflowBuildService,
  LocaldataService,
  AuthorizationService
} from '@app/core/services';
import { Layout, Event, Folder, Authentication } from '@app/core/models';
import { Logger } from '@app/core/class';

const log = new Logger('DashboardPage');

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardPageComponent implements OnInit {
  /**
   * template of modal for loading
   */
  @ViewChild('modalLoading') loading: ElementRef;

  @ViewChild('alertsModal') alertsModal: ElementRef;

  @ViewChild('modalEasySign') modalEasySign: ElementRef;

  /**
   * Informs whether the edit is active or not
   */
  canEdit: boolean = false;

  /**
   * Available data of counters
   */
  available: Array<Layout.ManagerWidgetStatistics> = [];

  /**
   * Unavailable data of counters
   */
  unavailable: Array<Layout.ManagerWidgetStatistics> = [];

  /**
   * All data of counters
   */
  allItems: Array<Layout.ManagerWidgetStatistics> = [];

  /**
   * Data of an external service for rendering the volume chart
   */
  dataChart: any;

  /**
   * Data of an external service for rendering the events list
   */
  dataEvents: any;
  dataAllEvents: any;

  /**
   * Options for render filter
   */
  filterOptions: Layout.ChartFilterOptions;

  folderCollection: Folder.FolderCollection;

  templateCollection: any;

  donnutActivityStatus = [1, 2, 4, -1];

  donnutActivityOtherStatus = [0, 3, 5];

  /**
   * Date in string format of the first day of the current month
   */
  lastMonth: string;

  /**
   * Date in string format of the first day of the current week
   */
  lastWeek: string;

  /**
   * Date in string format of the three days ago
   */
  last3days: string;

  files: any;
  filesQuantity: number;
  /**
   * modal
   */
  modalRef: any;

  alertsModalRef: any;
  showAlertsModal: string = '';

  logged: Authentication.SignupUserResponse;

  isLoadingModal: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statisticService: StatisticService,
    private documentService: DocumentService,
    private workflowService: WorkflowService,
    private workflowBuildService: WorkflowBuildService,
    private folderService: FolderService,
    private modalService: NgbModal,
    private localdataService: LocaldataService,
    private authorizationService: AuthorizationService
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
    this.files = [];
    this.filesQuantity = 0;
  }

  ngOnInit() {
    this.logged = this.authorizationService.getUser();

    this.route.queryParams.subscribe(params => {
      this.canEdit = params.custom ? true : false;
      this.getData();
    });

    this.filterOptions = {
      options: [
        {
          id: '30',
          name: 'Último mês'
        },
        {
          id: '7',
          name: 'Última semana'
        },
        {
          id: '3',
          name: 'Últimos 3 dias'
        }
      ]
    };

    this.lastMonth = moment()
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');
    this.lastWeek = moment()
      .startOf('week')
      .format('YYYY-MM-DD HH:mm:ss');
    this.last3days = moment()
      .subtract('3', 'days')
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss');

    this.filterChart({ value: '30' });
    this.getWorkflows();

    setTimeout(() => {
      if (!this.logged.businessUnit) {
        this.openModalEasySign(this.modalEasySign);
      }
    }, 4000);
  }

  /**
   * provides the data of events
   *
   * @return void
   */
  getWorkflows(params?: any) {
    try {
      const queryString = {
        'order-by': [
          {
            type: 'field',
            field: 'dateStatus',
            direction: 'desc'
          }
        ],
        pageSize: 10
      };

      if (params) {
        queryString['filter'] = params.filter;
      }
      this.workflowService.getAll(queryString).subscribe(
        (response: any) => {
          log.debug(`Success [getWorkflows]:`, response);
          const output = {
            items: response.items,
            page: response.page,
            page_count: response.page_count,
            page_size: response.page_size,
            total_items: response.total_items
          };

          this.dataEvents = output;
          this.dataAllEvents = output;

          if (this.modalRef.ref) {
            this.modalRef.ref.close();
            setTimeout(
              (() => {
                if (this.showAlertsModal) {
                  this.openAlertsModal(this.showAlertsModal);
                }
              }).bind(this),
              1000
            );
          }
        },
        (error: any) => {
          log.debug(`Error [getWorkflows]:`, error);
          this.dataEvents = [];
          this.dataAllEvents = [];
        }
      );
    } catch (e) {
      log.debug(`Error [getEvents]:`, e);
    }
  }

  /**
   * provides the data of folders
   *
   * @return void
   */
  getFolders() {
    try {
      // this.isEventLoading = true;
      this.folderService
        .getAll()
        .pipe(
          finalize(() => {
            // this.isEventLoading = false;
          })
        )
        .subscribe(
          (response: Folder.FolderCollection) => {
            log.debug(`Success [getFolders]:`, response);
            this.folderCollection = response;
          },
          (error: any) => {
            log.debug(`Error [getFolders]:`, error);
            this.folderCollection = null;
          }
        );
    } catch (e) {
      // this.isEventLoading = false;
      this.folderCollection = null;
      log.debug(`Error [getFolders]:`, e);
    }
  }

  /**
   * parse an string to selected by the user to a date
   *
   * @param (any) object holding the value selected by the user
   * @return void
   */
  _getFilterPeriod(period: any) {
    if (period.value === '30') {
      return this.lastMonth;
    } else if (period.value === '7') {
      return this.lastWeek;
    } else {
      return this.last3days;
    }
  }

  /**
   * provides the action of filtering data to build the donnut chart
   *
   * @param (any) object with period of filter
   * @return void
   */
  filterChart(period: any) {
    const filterPeriod = this._getFilterPeriod(period);
    const requests = this.donnutActivityStatus.map((n1: number) => {
      if (n1 === 1) {
        return this.workflowService.countByStatusAndPeriod(n1, filterPeriod);
      } else if (n1 !== -1) {
        return this.workflowService.countByStatusAndPeriod(n1, filterPeriod);
      } else {
        return forkJoin(
          this.donnutActivityOtherStatus.map((n2: number) =>
            this.workflowService.countByStatusAndPeriod(n2, filterPeriod)
          )
        );
      }
    });

    forkJoin(requests).subscribe(values => (this.dataChart = values));
  }

  /**
   * provides the action of filtering the events
   *
   * @param (any) period of filter
   * @return void
   */
  filterWorkflow(period: any) {
    const filterPeriod = this._getFilterPeriod(period);
    const filter = {
      filter: [
        {
          type: 'innerjoin',
          field: 'workflow',
          alias: 'wk'
        },
        {
          type: 'gte',
          field: 'dateCreated',
          alias: 'wk',
          value: filterPeriod
        }
      ]
    };

    this.getWorkflows(filter);
  }

  /**
   * provides the action of button event
   *
   * @param (any) action of action
   * @return void
   */
  doActionEvent(action: any) {
    log.debug(`Event [doActionEvent]:`, action.type);
  }

  /**
   * save all customizations that occurred on the dashboard
   *
   * @return void
   */
  saveData() {
    this.statisticService.saveData().subscribe(() => {
      this.router.navigate(['/manager/dashboard'], { replaceUrl: true });
    });
  }

  /**
   * updates the list of counters by changing a counter type
   *
   * @param (Layout.ManagerChangeStatistics) object with current and next counter
   * @return void
   */
  changeData(counter: Layout.ManagerChangeStatistics) {
    let output = this.allItems;

    output = this.allItems.map((item: Layout.ManagerWidgetStatistics) => {
      if (item.title === counter.current.title) {
        item.isVisible = false;
      }
      if (item.title === counter.next) {
        item.isVisible = true;
      }

      return item;
    });

    this.statisticService.saveDraft(output);
    this.defineStatistics(output);
  }

  /**
   * Cancels changes and removes data saved
   *
   * @return void
   */
  cancelChanges() {
    const statisticData = this.statisticService.rollbackData();
    this.defineStatistics(statisticData);
    this.router.navigate(['/manager/dashboard'], { replaceUrl: true });
  }

  /**
   * get all items for build the elements
   * fetch the number of available workflows
   *
   * @return void
   */
  getData() {
    this.statisticService.getData().subscribe(statisticData => {
      this.defineStatistics(statisticData);
    });
  }

  /**
   * Set statistic data
   *
   * @param (Layout.ManagerWidgetStatistics) item array with data of counter
   */
  defineStatistics(statisticData: Layout.ManagerWidgetStatistics[]) {
    this.allItems = statisticData;
    this.available = statisticData.filter((d: any) => d.isVisible);
    this.unavailable = statisticData.filter((d: any) => d.isVisible !== true);

    this.available.forEach((e: any) => {
      this.documentService
        .countByStatus(e.status, e.isStep || false)
        .subscribe(value => (e.value = value));
    });
  }

  /**
   * receives the updates data and saves
   *
   * @param (Layout.ManagerWidgetStatistics) item array with data of counter
   * @return void
   */
  receiverData(items: Array<Layout.ManagerWidgetStatistics>) {
    this.statisticService.saveDraft(items);
  }

  /**
   * receives the file and send to edit document
   *
   * @param (Layout.ManagerWidgetStatistics) response with data file
   * @return void
   */
  createDocument() {
    this.router.navigateByUrl('/manager/workflow');
  }

  countFiles(quantity: any) {
    this.filesQuantity = quantity;
  }

  emptyAddedFiles() {
    this.workflowBuildService.emptyFiles();
    this.workflowBuildService.emptyContacts();
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  openModalEasySign(content: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: content.size || 'md'
    });
    return modalRef;
  }

  openAlertsModal(alert: string) {
    this.alertsModal['alert'] = alert;
    this.alertsModalRef = this.modalService.open(this.alertsModal, {
      ariaLabelledBy: 'modal-title',
      keyboard: false,
      backdrop: 'static'
    });
  }
  closeAlertsModal() {
    this.alertsModalRef.close();
  }
}
