/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../droppable-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../modal/modal-action/modal-action.component.ngfactory";
import * as i3 from "../../../../../modal/modal-action/modal-action.component";
import * as i4 from "./feedback-stamp/feedback-stamp.component.ngfactory";
import * as i5 from "./feedback-stamp/feedback-stamp.component";
import * as i6 from "./stamp-signature.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_WorkflowStampSignatureFieldComponent = [i0.styles];
var RenderType_WorkflowStampSignatureFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkflowStampSignatureFieldComponent, data: {} });
export { RenderType_WorkflowStampSignatureFieldComponent as RenderType_WorkflowStampSignatureFieldComponent };
function View_WorkflowStampSignatureFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-modal-action", [], null, null, null, i2.View_ModalActionComponent_0, i2.RenderType_ModalActionComponent)), i1.ɵdid(2, 49152, null, 0, i3.ModalActionComponent, [], { modal: [0, "modal"], showHeader: [1, "showHeader"], showFooter: [2, "showFooter"], showClose: [3, "showClose"], typeModal: [4, "typeModal"], title: [5, "title"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-feedback-stamp", [], null, null, null, i4.View_FeedbackStampComponent_0, i4.RenderType_FeedbackStampComponent)), i1.ɵdid(5, 114688, null, 0, i5.FeedbackStampComponent, [], { modal: [0, "modal"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; var currVal_4 = "simple green"; var currVal_5 = "Replicar carimbo"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit; _ck(_v, 5, 0, currVal_6); }, null); }
export function View_WorkflowStampSignatureFieldComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { feedbackStamp: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "field-block field-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Carimbo"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "copy-icon"], ["title", "Replicar carimbo em todas as p\u00E1ginas"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "far fa-copy"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "simple-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(0, [[1, 2], ["feedbackStamp", 2]], null, 0, null, View_WorkflowStampSignatureFieldComponent_1)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contact.email; _ck(_v, 13, 0, currVal_0); }); }
export function View_WorkflowStampSignatureFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-stamp-signature-field", [], null, null, null, View_WorkflowStampSignatureFieldComponent_0, RenderType_WorkflowStampSignatureFieldComponent)), i1.ɵdid(1, 114688, null, 0, i6.WorkflowStampSignatureFieldComponent, [i7.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowStampSignatureFieldComponentNgFactory = i1.ɵccf("app-workflow-stamp-signature-field", i6.WorkflowStampSignatureFieldComponent, View_WorkflowStampSignatureFieldComponent_Host_0, { field: "field" }, { spreadFieldAllPages: "spreadFieldAllPages" }, []);
export { WorkflowStampSignatureFieldComponentNgFactory as WorkflowStampSignatureFieldComponentNgFactory };
