import { Routes, Route } from '@angular/router';
import { AuthenticationGuard } from '@app/core/guards';

import { LayoutManagerComponent } from './manager.component';

/**
 * Provides helper methods to create routes.
 */
export class LayoutManager {
  /**
   * Creates routes using the layout manager component
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: LayoutManagerComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      // Reuse LayoutManagerComponent instance when navigating between child views
      data: { reuse: true }
    };
  }
}
