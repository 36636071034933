<div class="form-modal-signature">
  <form (ngSubmit)="doSubmit()" [formGroup]="signatureForm" novalidate>
    <ng-container *ngIf="!checkStampType()">
      <div class="row">
        <div class="col-12">
          <div class="form-row">
            <div class="form-group col-md-8">
              <ng-container *ngIf="!checkRubricType()">
                <span
                  class="length-info"
                  [ngClass]="numberColor(f.name.value.length)"
                  >{{ f.name.value.length }}/30</span
                >
                <input
                  type="text"
                  class="form-control is-valid"
                  [ngClass]="{
                    'is-invalid': f.name.errors && !f.name.pristine
                  }"
                  formControlName="name"
                  placeholder="Nome Completo"
                />

                <ng-container
                  *ngIf="f.name.errors && !f.name.pristine; else feedback"
                >
                  <span class="error" [hidden]="isValidateFeedback()"
                    ><i class="mr-1 fas fa-times"></i> Tamanho excedido. Limite
                    de 30 caracteres.</span
                  >
                  <span class="error" [hidden]="!f.name.errors.required">
                    Informe um nome
                  </span>
                </ng-container>

                <ng-template #feedback>
                  <span class="input-info"
                    ><i class="mr-1 fas fa-bell"></i> Máximo de 30
                    caracteres</span
                  >
                </ng-template>
              </ng-container>

              <ng-container *ngIf="checkRubricType()">
                <input
                  maxlength="2"
                  type="text"
                  class="form-control is-valid"
                  [ngClass]="{
                    'is-invalid': f.name.errors && !f.name.pristine
                  }"
                  formControlName="name"
                  placeholder="Rubrica"
                />
                <div *ngIf="f.name.errors && !f.name.pristine">
                  <span class="error" [hidden]="!f.name.errors.required">
                    Informe um rubrica
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row nav_s">
      <div
        *ngIf="!checkStampType()"
        class="col-4 col-md-2 btn-category"
        [class.active_btn]="showSignature === 'choose'"
        (click)="showSignature = 'choose'"
      >
        <span>Escolher</span>
      </div>

      <div
        *ngIf="!checkRubricType() && !checkStampType()"
        class="col-4 col-md-2 btn-category"
        [class.active_btn]="showSignature === 'draw'"
        (click)="showSignature = 'draw'"
      >
        <span>Desenhar</span>
      </div>

      <div
        *ngIf="
          (!checkRubricType() && checkStampType()) ||
          (!checkRubricType() && !checkStampType())
        "
        class="col-4 col-md-2 btn-category"
        [class.active_btn]="showSignature === 'upload'"
        (click)="showSignature = 'upload'"
      >
        <span>Upload</span>
      </div>
    </div>

    <div class="comp_">
      <ng-container *ngIf="showSignature == 'choose'">
        <app-choose-subscription
          [fullName]="f.name.value"
          (dataSignChooseEvent)="typeSignature($event)"
        ></app-choose-subscription>
      </ng-container>

      <ng-container *ngIf="showSignature == 'draw'">
        <app-draw-signature
          (dataSignDrawEvent)="typeSignature($event)"
        ></app-draw-signature>
      </ng-container>

      <ng-container *ngIf="showSignature == 'upload'">
        <app-upload-signature
          (dataSignUploadEvent)="typeSignature($event)"
        ></app-upload-signature>
      </ng-container>
    </div>

    <div class="buttons">
      <div class="row">
        <div class="col-12 col-md-7">
          <span class="mt-3" *ngIf="showInfoText">
            <i class="fa fa-info-circle"></i> Ao clicar em “Assinar” você
            concorda com os
            <a
              href="assets/termo_uso_assine_online_2022.pdf"
              target="_blank"
              download="Termo de Uso Assine Online 2022.pdf"
              >Termos de Uso</a
            >
            e
            <a
              href="assets/politica_privacidade_assine_online_2022.pdf"
              target="_blank"
              download="Política de Privacidade Assine Online 2022.pdf"
              >Política de Privacidade</a
            >
            do Assine.Online.
          </span>
        </div>

        <div class="col-12 col-md-5">
          <div class="button">
            <button
              class="btn btn-outline-success"
              (click)="modal.close('cancel')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-secondary"
              [disabled]="signatureForm.invalid || isLoading"
            >
              {{ btnSaveText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
