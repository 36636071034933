import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Subject, from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UploadOutput,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions
} from 'ngx-uploader';

import { Logger, WorkflowFile } from '@app/core/class';
import { UploadService, WorkflowBuildService } from '@app/core/services';
import { generateFileBase64, generateFileSha1 } from '@app/core/helpers';

const log = new Logger('UploadComponent');

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnDestroy {
  /**
   * template of modal for edit recipient
   */
  @ViewChild('modalLoading') loading: ElementRef;

  /**
   * text of button
   */
  @Input() buttonText?: string = 'Upload';

  /**
   * classes of button
   */
  @Input() buttonClass?: string = 'btn-sm';

  /**
   * drag'n drop text
   */
  @Input() dragDropText?: string =
    'Arraste e solte os documentos PDF de no máximo 25MB aqui para começar';

  /**
   * show loading
   */
  @Input() showRealtimeLoading?: boolean = false;

  /**
   * Open modal for status
   */
  @Input() showModalStatus?: boolean = false;

  /**
   * Type of container
   */
  @Input() layout?: string = 'default';

  /**
   * Should show only the button?
   */
  @Input() raw?: boolean = false;

  /**
   * Allowed mimetypes
   */
  @Input() allowedMimetypes?: Array<string> = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/bmp'
  ];

  /**
   * Allowed extensions
   */
  @Input() allowedExtensions?: Array<string> = [
    '.pdf',
    '.doc',
    '.docx',
    '.jpeg',
    '.jpg',
    '.png',
    '.bmp'
  ];

  /**
   * Extension text
   */
  @Input() extensionText?: string = 'pdf, doc, docx, jpeg, jpg, png, bmp';

  /**
   * Informs that the files are ready to be processed
   */
  @Output() processFiles = new EventEmitter();
  @Output() processFile = new EventEmitter();

  /**
   * Informs that the upload of a document has started
   */
  @Output() uploadStarted = new EventEmitter();

  /**
   * Array with images
   */
  files: UploadFile[];

  /**
   * Event of field upload
   */
  uploadInput: EventEmitter<UploadInput>;

  /**
   * Parse bytes of file
   */
  humanizeBytes: Function;

  /**
   * On/Off event drang'n drop
   */
  dragOver: boolean;

  /**
   * Options of uploader
   */
  options: UploaderOptions;

  /**
   * Print a error of upload
   */
  error: boolean = false;

  /**
   * modal
   */
  modalRef: any;

  /**
   * Subscriber
   */
  subscriber = new Subject();

  public hasDocumentRejected: boolean = false;

  constructor(
    private modalService: NgbModal,
    private uploadService: UploadService,
    private workflowBuildService: WorkflowBuildService
  ) {
    this.options = {
      concurrency: 10,
      maxUploads: 10
    };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.modalRef = {
      ref: null,
      data: { text: '', showClose: false, title: '' }
    };
  }

  /**
   * Processes files, performs status and queue management
   *
   * @param (UploadOutput) output data of each file
   * @return void
   */

  public onUploadOutput(output: UploadOutput): void {
    if (output.file && !this.isFileSupported(output.file)) {
      output.type = 'rejected';
    }

    switch (output.type) {
      case 'allAddedToQueue':
        if (!this.hasDocumentRejected) {
          setTimeout(() => {
            this.modalRef.data.title = '';
            this.modalRef.data.text =
              'Preparando seu(s) documento(s) para análise';
            this.modalRef.ref = this.open(this.loading);
          });

          const length = this.uploadService.getFileQueue().length;
          this.uploadStarted.emit(length);

          this.doUpload();
        }

        this.hasDocumentRejected = false;

        break;
      case 'addedToQueue':
        this.uploadService.addFileToQueue(output.file);
        break;
      case 'uploading':
        if (!output.file) {
          break;
        }

        if (this.showModalStatus) {
          const name = output.file.name;
          const percentage = output.file.progress.data.percentage;

          this.modalRef.data.text = `Efetuando upload de: ${name} (${percentage}%)`;
        }
        break;
      case 'removed':
        this.uploadService.removeFileFromQueue(output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'cancelled':
        log.debug(`drop:`, output);
        this.uploadService.removeFileFromQueue(output.file);
        break;
      case 'rejected':
        this.showUnsupportedFileFormatModal();
        break;
      case 'done':
        const status = output.file.responseStatus;
        if (status !== 201) {
          this.uploadService.abortAll(this.uploadInput);
          const message = output.file.response.detail;

          if (this.modalRef.ref) {
            this.modalRef.ref.close();
          }

          alert(`Ops! Ocorreu um problema! [${status}]\n${message}`);
          break;
        }

        const subs = from(this.addWorkflowFile(output.file)).subscribe(() => {
          if (!!subs) {
            subs.unsubscribe();
          }

          if (this.uploadService.hasMoreFiles()) {
            // Chama de novo até esvaziar a fila de arquivos
            this.doUpload();
          } else {
            if (this.modalRef.ref) {
              this.modalRef.ref.close();
            }

            this.uploadService.emptyQueue();
            this.processFiles.emit(null);

            // Set the last uploaded file as visible
            const workflowFile = this.workflowBuildService.findFile(
              output.file.nativeFile
            );
            this.workflowBuildService.setFileAsVisible(workflowFile);
          }

          this.processFile.emit(output);
        });
        break;
    }

    if (this.showModalStatus && this.modalRef.ref) {
      this.modalRef.ref.result.then(
        (data: any) => {
          log.debug(`onModal (close):`, data);
          this.modalRef.ref = undefined;
        },
        (reason: any) => {
          this.modalRef.ref = undefined;
        }
      );
    }
  }

  doUpload() {
    try {
      this.uploadService.uploadFilesOnQueue(this.uploadInput);
    } catch (error) {
      setTimeout(() => {
        this.modalRef.data.title = 'Ops! Ocorreu um erro';
        this.modalRef.data.text = error.message;
        this.modalRef.data.showClose = true;
        this.modalRef.data.showReloadButton = true;
      });
    }
  }

  /**
   * Cancel and remove a file of queue
   *
   * @param (string) id of file
   * @return void
   */
  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  ngOnDestroy() {
    this.subscriber.next();
    this.subscriber.complete();
  }

  acceptedExtensions() {
    if (Array.isArray(this.allowedExtensions)) {
      return this.allowedExtensions.join(',');
    }

    return '';
  }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  private open(content: any, size?: any) {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });

    return modalRef;
  }

  private extractExtensionFromFilename(filename?: string): string {
    if (!filename) {
      return '';
    }

    const extension = filename.split('.').pop();

    if (extension === filename) {
      return '';
    }

    return extension;
  }

  private isFileSupported(file: UploadFile): boolean {
    if (!file.type) {
      const extension = this.extractExtensionFromFilename(file.name);
      return this.isExtensionSupported(`.${extension}`);
    }

    return this.isMimetypeSupported(file.type);
  }

  private isMimetypeSupported(mimetype: string): boolean {
    return this.allowedMimetypes.includes(mimetype);
  }

  private isExtensionSupported(extension?: string): boolean {
    if (!extension) {
      return false;
    }
    return this.allowedExtensions.includes(extension);
  }

  private showUnsupportedFileFormatModal() {
    this.hasDocumentRejected = true;
    this.uploadService.abortAll(this.uploadInput);

    if (!this.showModalStatus) {
      this.error = true;
      return;
    }

    this.modalRef.data.title = 'Este formato de arquivo não é suportado.';
    this.modalRef.data.text = `Selecione arquivos com extensão em ${this.extensionText.toUpperCase()} e tente novamente.`;
    this.modalRef.data.showClose = true;
    this.modalRef.data.showReloadButton = true;
    this.modalRef.ref = this.open(this.loading);
  }

  private async addWorkflowFile(file: UploadFile): Promise<void> {
    const workflowFile = WorkflowFile.MakeWorkflowFile(file.nativeFile);
    workflowFile.setDownloadURL(
      this.uploadService.getEndpoint(),
      file.response.uuid
    );
    workflowFile.setIdFile(file.response.id);
    workflowFile.setName(file.nativeFile.name);

    const defaultFolderId = this.workflowBuildService.getDefaultFolderId();
    workflowFile.setFolderId(defaultFolderId);

    const [base64, sha1] = await WorkflowFile.GenerateBase64AndSha1OfFile(
      file.nativeFile
    );
    workflowFile.setBase64(base64);
    workflowFile.setSha1(sha1);

    this.workflowBuildService.addFile(workflowFile);
  }
}
