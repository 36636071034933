/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./line-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng2-charts/charts/charts";
import * as i4 from "./line-chart.component";
var styles_LineChartComponent = [i0.styles];
var RenderType_LineChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LineChartComponent, data: {} });
export { RenderType_LineChartComponent as RenderType_LineChartComponent };
function View_LineChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00E3o h\u00E1 dados para renderizar o gr\u00E1fico."]))], null, null); }
export function View_LineChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "chart"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LineChartComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(8, 737280, null, 0, i3.BaseChartDirective, [i1.ElementRef], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], legend: [5, "legend"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.lineChartData; var currVal_2 = _co.lineChartLabels; var currVal_3 = _co.lineChartOptions; var currVal_4 = _co.lineChartType; var currVal_5 = _co.lineChartColors; var currVal_6 = _co.lineChartLegend; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_LineChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-line-chart", [], null, null, null, View_LineChartComponent_0, RenderType_LineChartComponent)), i1.ɵdid(1, 114688, null, 0, i4.LineChartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LineChartComponentNgFactory = i1.ɵccf("app-line-chart", i4.LineChartComponent, View_LineChartComponent_Host_0, { data: "data" }, {}, []);
export { LineChartComponentNgFactory as LineChartComponentNgFactory };
