import * as tslib_1 from "tslib";
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';
import { QRCodeField } from './qr-code-field.class';
var SharedQRCodeField = /** @class */ (function (_super) {
    tslib_1.__extends(SharedQRCodeField, _super);
    function SharedQRCodeField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.QR_CODE_SHARE;
        return _this;
    }
    return SharedQRCodeField;
}(QRCodeField));
export { SharedQRCodeField };
