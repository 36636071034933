<footer>
  <div class="row mt-3">
    <div class="col col-12 col-sm-12 col-md-3 text-center">
      <p class="mb-0">
        Precisa de Ajuda? <i class="fas fa-phone"></i>
        <strong>(62) 3412-0240</strong>
      </p>
    </div>
    <div class="col col-12 col-sm-12 col-md-6 text-center">
      <ul>
        <li>
          <a
            [href]="termsOfUse.path"
            [download]="termsOfUse.filename"
            [title]="termsOfUse.title"
            >Termos de uso</a
          >
        </li>
        <li>
          <a
            [href]="cookies.path"
            [download]="cookies.filename"
            [title]="cookies.title"
            >Política de cookies</a
          >
        </li>
        <li>
          <a
            [href]="privacyPolicy.path"
            [download]="privacyPolicy.filename"
            [title]="privacyPolicy.title"
            >Política de privacidade</a
          >
        </li>
      </ul>
    </div>
    <div class="col col-12 col-sm-12 col-md-3 text-center">
      <h6 title="Versão %%VERSION%%">
        Copyright &copy; {{ year }} Assine Online
      </h6>
    </div>
  </div>
</footer>
