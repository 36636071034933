import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Logger } from '@app/core/class';
import {
  AuthorizationService,
  SELECTION_TYPE,
  WorkflowService,
  WORKFLOW_STEP_STATUS
} from '@app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cancelable, debounce } from 'lodash';
import * as moment from 'moment';

const log = new Logger('SignatureInPerson');

@Component({
  selector: 'app-signature-in-person',
  templateUrl: './signature-in-person.component.html',
  styleUrls: ['./signature-in-person.component.scss']
})
export class SignatureInPersonComponent implements OnInit {
  @ViewChild('modalLoading') loading: ElementRef;

  public collection: any = [];

  /**
   * Field search data
   */
  public queryField = new FormControl();
  public searchStatus: number | string = '';
  public searchPeriod: number | string = '';
  public searchDebounce: (() => void) & Cancelable;

  /**
   * modal
   */
  public modalRef: any;

  /**
   * config list with fields
   */
  public configList: Array<any>;

  public statusFilterConfig: Array<any>;

  public sortFields: any;

  /**
   * Data User logged
   */
  public userLogged: any;

  constructor(
    private workflowService: WorkflowService,
    private modalService: NgbModal,
    private authorizationService: AuthorizationService
  ) {
    this.modalRef = { ref: null, data: { text: '' } };
    this.searchDebounce = debounce(this.search.bind(this), 300);
  }

  public ngOnInit(): void {
    this.user();
    this.setLoading();
    this.filterList();
    this.configurationList();
    this.search();
  }

  public user(): void {
    this.authorizationService.user.subscribe((user: any) => {
      this.userLogged = user;
    });
  }

  public setLoading(): void {
    setTimeout(
      (() => {
        this.modalRef.data.text = 'carregando dados...';
        this.modalRef.ref = this.open(this.loading);
      }).bind(this)
    );
  }

  public open(content: any, size?: any): any {
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: size || 'lg'
    });
    return modalRef;
  }

  public configurationList(): void {
    this.sortFields = { id: 'DESC', status: 'DESC' };

    this.configList = [
      {
        name: '',
        klass: '',
        type: 'icon',
        fieldId: 'workflow.status'
      },
      {
        name: 'ID',
        klass: '',
        type: 'text',
        fieldId: 'id',
        isSortable: false
      },
      {
        name: 'Nome',
        klass: '',
        type: 'text',
        fieldId: 'workflow.name',
        isSortable: false
      },
      {
        name: 'Assinante',
        klass: '',
        type: 'text',
        fieldId: 'extraMetadata.name',
        isSortable: false
      },
      {
        name: 'Data',
        type: 'text',
        klass: '',
        fieldId: 'dateStatus.formated_date',
        fieldKey: 'id',
        isSortable: true
      },
      {
        name: 'Status',
        klass: '',
        type: 'status',
        fieldId: 'workflow.status',
        fieldKey: 'status',
        isSortable: true
      },
      {
        name: 'Ações',
        type: 'custom-select',
        icon: 'fa fa-sliders-h',
        width: '15%',
        isRestrict: false,
        options: {
          changeLabel: true,
          items: [
            {
              name: 'Assinar',
              action: 'sign',
              canField: 'id',
              can: [WORKFLOW_STEP_STATUS.WAITING]
            }
          ]
        }
      }
    ];
  }

  public filterList(): void {
    this.statusFilterConfig = [
      {
        value: '',
        label: 'Todos'
      },
      {
        value: 6,
        label: 'Assinado'
      },
      {
        value: 1,
        label: 'Aguardando assinatura'
      },
      {
        value: 2,
        label: 'Cancelado'
      },
      {
        value: 3,
        label: 'Expirado'
      },
      {
        value: 4,
        label: 'Rejeitado'
      }
    ];
  }

  public doAction(event: any): void {
    log.debug(`doAction:`, event);

    switch (event.type) {
      case SELECTION_TYPE.SIGN:
        this.toSign(event.data);
        break;

      default:
        throw new Error(`The informed field ${event.type} is invalid!`);
    }
  }

  public toSign(data: any): void {
    const hash = btoa(
      JSON.stringify({
        u: this.userLogged.username,
        id: data.id
      })
    );

    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `/subscribe/${hash}`
    }).dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  }

  public search(): void {
    const filter = [];
    const sort = [];
    let queryStringFilter = {};

    filter.push({
      field: 'signatureType',
      type: 'eq',
      value: '2'
    });

    sort.push({ type: 'field', field: 'id', direction: 'desc' });

    if (this.searchPeriod) {
      const format = 'YYYYMMDD';
      const from = moment();
      from.subtract(this.searchPeriod, 'days');
      const value = from.format(format) + '000000';

      filter.push({
        type: 'gte',
        field: 'workflow',
        alias: 'w',
        child: 'dateCreated',
        value
      });
    }

    if (this.searchStatus) {
      filter.push({
        type: 'eq',
        field: 'workflow',
        alias: 'w',
        child: 'status',
        value: this.searchStatus
      });
    }

    if (this.queryField.value) {
      filter.push({
        type: 'like',
        field: 'extraMetadata',
        value: `%${this.queryField.value}%`
      });
    }

    queryStringFilter = {
      filter,
      'order-by': sort,
      page: 1
    };

    this.signatureInPerson(queryStringFilter);
  }

  private signatureInPerson(queryString: any): void {
    this.workflowService.getSignatureInPerson(queryString).subscribe(
      (response: any) => {
        log.debug(`Success [getSignatureInPerson]:`, response);
        this.collection = response.items;
        this.closeModal();
      },
      (error: any) => {
        log.debug(`Error [getSignatureInPerson]:`, error);
        this.collection = { items: [] };
        this.closeModal();
      }
    );
  }

  public doSort($event: string): void {
    this.setLoading();
    this.sortFields[$event] = this.reorder(this.sortFields[$event]);

    const queryString = {
      filter: [
        {
          field: 'signatureType',
          type: 'eq',
          value: '2'
        }
      ],

      'order-by': [
        {
          type: 'field',
          field: $event,
          direction: this.sortFields[$event]
        }
      ],
      page: 1
    };

    this.signatureInPerson(queryString);
  }

  private reorder(field: string): string {
    return field === 'ASC' ? 'DESC' : 'ASC';
  }

  public closeModal(): void {
    if (this.modalRef.ref) {
      this.modalRef.ref.close();
    }
  }
}
