import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Authentication, User } from '@app/core/models';
import { AuthorizationService, UserService } from '@app/core/services';

@Component({
  selector: 'app-update-easy-sign',
  templateUrl: './update-easy-sign.component.html',
  styleUrls: ['./update-easy-sign.component.scss']
})
export class UpdateEasySignComponent implements OnInit {
  /**
   * Data of Form
   */
  data: FormGroup;

  @Input() logged: Authentication.SignupUserResponse;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Feedback component message
   */
  feedbackMessage: string;

  /**
   * Feedback component theme
   */
  feedbackTheme: string;

  /**
   * Spinner Save button
   */
  spinnerSave: boolean = false;

  step: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authorizationService: AuthorizationService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  /**
   * Data selection action by clicking the submit button on the form. action set cancel or submit
   */
  doSubmit(action: boolean) {
    if (action) {
      this.spinnerSave = true;
    }

    const target: User.UserPayload = {
      name: '',
      cellphone: '',
      businessUnit: {
        name: ''
      }
    };
    target.name = this.data.value.name || this.logged.name;
    target.cellphone = this.data.value.cellphone || this.logged.cellphone;
    target.businessUnit.name = this.data.value.name || this.logged.name;

    this.cancelOrSubmit(action, target);
  }

  cancelOrSubmit(action: boolean, form: User.UserPayload) {
    this.userService.updateUser(this.logged.id, form).subscribe(
      (data: any) => {
        this.getPlanSubscription();
        this.authorizationService.setUser(data);
        this.spinnerSave = false;
        if (action) {
          this.step = true;
        } else {
          this.refreshPage();
        }
      },
      error => {
        this.spinnerSave = false;
        this.feedbackTheme = 'error';
        this.feedbackMessage =
          `Não foi possível atualizar os dados desta conta.` +
          ` Tente novamente`;
      }
    );
  }

  refreshPage() {
    window.location.reload();
    this.modal.close();
  }

  /**
   * Obtain information from the plan that is written
   */
  getPlanSubscription() {
    let paymentInfo: User.UserPaymentInfo;
    this.userService.getPaymentInfo().subscribe(body => (paymentInfo = body));
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.data.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.data = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.minLength(5)])
      ],
      cellphone: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)])
      ]
    });
  }
}
