import { WORKFLOW_SIGNATURE } from '../services/workflow.enum';
var WorkflowBuilder = /** @class */ (function () {
    function WorkflowBuilder(files, contacts) {
        this.files = [];
        this.contacts = [];
        this.files = files;
        this.contacts = contacts;
    }
    WorkflowBuilder.MakeWorkflowBuilder = function (files, contacts) {
        return new WorkflowBuilder(files, contacts);
    };
    WorkflowBuilder.prototype.compile = function (settings) {
        var _this = this;
        var workflowSettings = Object.assign({
            autoInitiate: 1,
            files: []
        }, settings);
        var _loop_1 = function (file) {
            var specialFields = [];
            var steps = [];
            var fields = file.getAllFields();
            this_1.shouldChangeSubscription(file.getAllFields());
            specialFields = this_1.extractSpecialFields(fields);
            // This will create the steps, each contact is one step
            this_1.contacts.forEach(function (contact, index) {
                var step = _this.extractStep(file.getPages(), contact);
                if (step) {
                    step.index = index;
                    steps.push(step);
                }
            });
            var compiledFile = {
                idFile: file.getIdFile(),
                idFolder: file.getFolderId(),
                name: file.getName(),
                dueDate: file.getDueDate(),
                specialFields: specialFields,
                workflowSteps: steps
            };
            workflowSettings.files.push(compiledFile);
        };
        var this_1 = this;
        for (var _i = 0, _a = this.files; _i < _a.length; _i++) {
            var file = _a[_i];
            _loop_1(file);
        }
        return workflowSettings;
    };
    WorkflowBuilder.prototype.shouldChangeSubscription = function (fields) {
        fields.forEach(function (signature) {
            if (signature.signatureType === WORKFLOW_SIGNATURE.STAMP) {
                signature.signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
            }
        });
    };
    WorkflowBuilder.prototype.signatureByContact = function (contact) {
        var contactSignatureType;
        // Search in each file added
        for (var _i = 0, _a = this.files; _i < _a.length; _i++) {
            var file = _a[_i];
            // For a signature field
            for (var _b = 0, _c = file.getAllFields(); _b < _c.length; _b++) {
                var field = _c[_b];
                // If there is at least one signature, set to true
                if (field.isSignature() && field.getContact() === contact) {
                    contactSignatureType = field.getSignatureType();
                    break;
                }
            }
            if (contactSignatureType) {
                break;
            }
        }
        return contactSignatureType;
    };
    WorkflowBuilder.prototype.extractSpecialFields = function (fields) {
        var specialFields = [];
        var droppedSpecialFields = fields.filter(function (field) { return field.isSpecialField(); });
        for (var _i = 0, droppedSpecialFields_1 = droppedSpecialFields; _i < droppedSpecialFields_1.length; _i++) {
            var field = droppedSpecialFields_1[_i];
            var specialField = {
                type: field.getType(),
                x: field.getRawX(),
                y: field.getRawY(),
                height: field.getRawHeight(),
                width: field.getRawWidth(),
                page: field.getPageNumber()
            };
            specialFields.push(specialField);
        }
        return specialFields;
    };
    WorkflowBuilder.prototype.extractStep = function (pages, contact) {
        var contactFields = [];
        for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
            var page = pages_1[_i];
            for (var _a = 0, _b = page.getFieldsOfContact(contact); _a < _b.length; _a++) {
                var field = _b[_a];
                if (field.isSpecialField()) {
                    continue;
                }
                contactFields.push(field);
            }
        }
        var signatureFieldIdx = contactFields.findIndex(function (f) { return f.isSignature(); });
        if (signatureFieldIdx === -1) {
            return null;
        }
        var step = {
            user: {
                name: contact.name,
                email: contact.email,
                whatsappNumber: contact.whatsappNumber,
                notifyWhatsapp: contact.notifyWhatsapp
            },
            extraMetadata: contact.getExtraMetadata(),
            action: contact.getAction(),
            hasPhotoSign: contact.getHasPhotoSign(),
            signatureType: contactFields[signatureFieldIdx].getSignatureType(),
            fields: []
        };
        // if (contact.id) {
        //   step.idUser = contact.id;
        //   delete step.user;
        // }
        for (var _c = 0, contactFields_1 = contactFields; _c < contactFields_1.length; _c++) {
            var contactField = contactFields_1[_c];
            var field = this.extractStepField(contactField);
            step.fields.push(field);
        }
        return step;
    };
    WorkflowBuilder.prototype.extractStepField = function (field) {
        return {
            type: field.getType(),
            x: field.getRawX(),
            y: field.getRawY(),
            height: field.getRawHeight(),
            width: field.getRawWidth(),
            page: field.getPageNumber(),
            properties: field.getPropertiesField()
        };
    };
    return WorkflowBuilder;
}());
export { WorkflowBuilder };
