import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WorkflowContact } from '@app/core/class';
import { WORKFLOW_STEP_FIELDS } from '@app/core/services/workflow.enum';

export class QRCodeField extends WorkflowDroppedField {
  constructor(contact: WorkflowContact) {
    super(contact);
    this.type = WORKFLOW_STEP_FIELDS.QR_CODE;
    this.minHeight = this.height = 80;
    this.minWidth = this.width = this.height;
  }

  public isSpecialField(): boolean {
    return true;
  }

  public shouldKeepAspectRatio(): boolean {
    return true;
  }
}
