import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { FolderService } from './folder.service';
import { WorkflowBuilder } from '../class/workflow-builder.class';
var WorkflowBuildService = /** @class */ (function () {
    function WorkflowBuildService(folderService) {
        this.folderService = folderService;
        this.files = [];
        this.contacts = [];
        this.subject = new Subject();
        this.settings = {};
        this.workflowId = 0;
        this.defaultFolderId = 0;
        this.defaultFolderName = 'default';
        this.cursorPosition = { x: 0, y: 0 };
        this.dataFieldAddedByClick = { item: { data: {} } };
        this.fieldInsertionType = 'drop';
        this.whatsApp = false;
        this.filesObserver = new Subject();
        this.contactsObserver = new Subject();
        this.visibleFileObserver = new Subject();
    }
    WorkflowBuildService.prototype.compile = function () {
        var workflowBuilder = WorkflowBuilder.MakeWorkflowBuilder(this.files, this.contacts);
        return workflowBuilder.compile(this.settings);
    };
    WorkflowBuildService.prototype.setWhatsApp = function (flag) {
        this.whatsApp = flag;
    };
    WorkflowBuildService.prototype.getWhatsApp = function () {
        return this.whatsApp;
    };
    WorkflowBuildService.prototype.setDefaultFolderId = function (id) {
        this.defaultFolderId = id;
    };
    WorkflowBuildService.prototype.getDefaultFolderId = function () {
        return this.defaultFolderId;
    };
    WorkflowBuildService.prototype.setDefaultFolderName = function (name) {
        this.defaultFolderName = name;
    };
    WorkflowBuildService.prototype.getDefaultFolderName = function () {
        return this.defaultFolderName;
    };
    WorkflowBuildService.prototype.setWorkflowId = function (id) {
        this.workflowId = id;
    };
    WorkflowBuildService.prototype.getWorkflowId = function () {
        return this.workflowId;
    };
    WorkflowBuildService.prototype.setSettings = function (settings) {
        this.settings = settings;
    };
    WorkflowBuildService.prototype.getSettings = function () {
        return this.settings;
    };
    WorkflowBuildService.prototype.setCursorPosition = function (x, y) {
        this.cursorPosition.x = x;
        this.cursorPosition.y = y;
    };
    /**
     * add field data by click to use in mobile experience
     */
    WorkflowBuildService.prototype.setDataFieldAddedByClick = function (contact, field) {
        this.dataFieldAddedByClick.item.data.contact = contact;
        this.dataFieldAddedByClick.item.data.field = field;
        this.fieldInsertionType = 'click';
    };
    WorkflowBuildService.prototype.getDataFieldAddedByClick = function () {
        return this.dataFieldAddedByClick;
    };
    WorkflowBuildService.prototype.getInsertionType = function () {
        return this.fieldInsertionType;
    };
    WorkflowBuildService.prototype.getCursorPosition = function () {
        return this.cursorPosition;
    };
    /**
     * Add a Workflow file to drop signatures and other fields
     * to build a signature workflow
     */
    WorkflowBuildService.prototype.addFile = function (workflowFile) {
        this.files.push(workflowFile);
        this.pushUpdateOfFilesState();
    };
    WorkflowBuildService.prototype.getFiles = function () {
        return this.files;
    };
    WorkflowBuildService.prototype.observeFiles = function () {
        return this.filesObserver.asObservable();
    };
    WorkflowBuildService.prototype.removeFile = function (file) {
        this.files = this.files.filter(function (f) { return f !== file; });
        this.pushUpdateOfFilesState();
    };
    WorkflowBuildService.prototype.findFile = function (file) {
        return this.files.find(function (f) { return f.getFile() === file; });
    };
    WorkflowBuildService.prototype.hasFiles = function () {
        return this.files.length > 0;
    };
    WorkflowBuildService.prototype.emptyFiles = function () {
        this.files = [];
        this.pushUpdateOfFilesState();
    };
    WorkflowBuildService.prototype.setFileAsVisible = function (workflowFile) {
        this.visibleFile = workflowFile;
        this.visibleFileObserver.next(this.visibleFile);
    };
    WorkflowBuildService.prototype.getVisibleFile = function () {
        return this.visibleFile;
    };
    WorkflowBuildService.prototype.observeVisibleFile = function () {
        return this.visibleFileObserver.asObservable();
    };
    WorkflowBuildService.prototype.addContact = function (contact) {
        this.contacts.push(contact);
        this.updateContactsOrderIndexes();
        this.pushUpdateOfContactsState();
    };
    WorkflowBuildService.prototype.getContacts = function () {
        return this.contacts;
    };
    WorkflowBuildService.prototype.hasContacts = function () {
        return this.contacts.length > 1;
    };
    WorkflowBuildService.prototype.findContactByOrderIndex = function (index) {
        return this.contacts[index - 1];
    };
    WorkflowBuildService.prototype.findContactByEmail = function (email) {
        return this.contacts.find(function (contact) { return contact.email === email; });
    };
    WorkflowBuildService.prototype.observeContacts = function () {
        return this.contactsObserver.asObservable();
    };
    WorkflowBuildService.prototype.removeContact = function (contact) {
        this.contacts = this.contacts.filter(function (c) { return c !== contact; });
        for (var _i = 0, _a = this.files; _i < _a.length; _i++) {
            var file = _a[_i];
            for (var _b = 0, _c = file.getPages(); _b < _c.length; _b++) {
                var page = _c[_b];
                page.removeFieldsOfContact(contact);
            }
        }
        this.updateContactsOrderIndexes();
        this.pushUpdateOfContactsState();
    };
    WorkflowBuildService.prototype.emptyContacts = function () {
        this.contacts = [];
        this.pushUpdateOfContactsState();
    };
    WorkflowBuildService.prototype.resetWorkflow = function () {
        this.setFileAsVisible(null);
        this.emptyContacts();
        this.emptyFiles();
        this.setSettings({});
    };
    WorkflowBuildService.prototype.fetchDefaultFolder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.folderService.getAll().toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.default) {
                            return [2 /*return*/, response.default];
                        }
                        else {
                            return [2 /*return*/, this.addDefaultFolder()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkflowBuildService.prototype.pushUpdateOfFilesState = function () {
        this.filesObserver.next(this.files);
    };
    WorkflowBuildService.prototype.pushUpdateOfContactsState = function () {
        this.contactsObserver.next(this.contacts);
    };
    /**
     * The contact order is always the array index + 1
     */
    WorkflowBuildService.prototype.updateContactsOrderIndexes = function () {
        for (var i = 0; i < this.contacts.length; i++) {
            var contact = this.contacts[i];
            contact.setOrderIndex(i + 1);
        }
    };
    WorkflowBuildService.prototype.addDefaultFolder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.folderService.createDefaultFolder().toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.default) {
                            return [2 /*return*/, response.default];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * used to add a field per click
     */
    WorkflowBuildService.prototype.setFieldAddedByClick = function () {
        this.subject.next();
    };
    WorkflowBuildService.prototype.getFieldAddedByClick = function () {
        return this.subject.asObservable();
    };
    return WorkflowBuildService;
}());
export { WorkflowBuildService };
