import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { SIGNATURE_TYPE } from './workflow.enum';
/**
 * Provides a base for workflow.
 */
var SignatureService = /** @class */ (function (_super) {
    tslib_1.__extends(SignatureService, _super);
    function SignatureService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/visible-signature';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    SignatureService.prototype.getAll = function (queryParams) {
        var _this = this;
        return _super.prototype.getAll.call(this, queryParams).pipe(map(function (response) {
            return {
                items: response._embedded.visible_signature,
                current: _this.getSignatureByType(response._embedded.visible_signature, [
                    SIGNATURE_TYPE.DRAWING,
                    SIGNATURE_TYPE.GENERATING,
                    SIGNATURE_TYPE.UPLOADING
                ]),
                rubric: _this.getSignatureByType(response._embedded.visible_signature, [SIGNATURE_TYPE.RUBRIC]),
                stamp: _this.getSignatureByType(response._embedded.visible_signature, [
                    SIGNATURE_TYPE.STAMP
                ])
            };
        }));
    };
    /**
     * Gets all data of a service
     */
    SignatureService.prototype.create = function (payload) {
        var timezone = 'Sao_paulo';
        try {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        catch (e) {
            // tslint:disable-next-line: no-console
            console.log('O browser não suporta Intl, não foi possível detectar o timezone.');
        }
        var body = {
            showBorder: 1,
            showReason: 0,
            showDate: 1,
            isDefault: true,
            dateFormat: "d/m/Y H:i:s|TZ|" + timezone,
            handSigning: payload.name,
            handSigningType: payload.type,
            handSigningFont: payload.font,
            watermark: ''
        };
        if (payload.type === SIGNATURE_TYPE.DRAWING ||
            payload.type === SIGNATURE_TYPE.UPLOADING) {
            body.watermark = null;
            body.handSigningFont = 'Arial.ttf';
        }
        return _super.prototype.create.call(this, body).pipe(map(function (response) {
            return response;
        }));
    };
    SignatureService.prototype.getSignatureByType = function (signtures, types) {
        return signtures.find(function (sign) { return types.includes(sign.handSigningType); });
    };
    return SignatureService;
}(BaseAPI));
export { SignatureService };
