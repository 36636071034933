/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../shared/components/list-events/list-events.component.ngfactory";
import * as i4 from "../../../shared/components/list-events/list-events.component";
import * as i5 from "../../../core/services/authorization.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "./events.component";
var styles_EventsComponent = [i0.styles];
var RenderType_EventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsComponent, data: {} });
export { RenderType_EventsComponent as RenderType_EventsComponent };
function View_EventsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["\n            ", "\n          "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
export function View_EventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "box-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Eventos Recentes"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "box-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-list-events", [["layout", "default"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.goTo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ListEventsComponent_0, i3.RenderType_ListEventsComponent)), i1.ɵdid(9, 114688, null, 0, i4.ListEventsComponent, [i5.AuthorizationService], { events: [0, "events"], showInitials: [1, "showInitials"], layout: [2, "layout"] }, { action: "action" }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 28, "div", [["class", "box-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 25, "div", [["class", "filter-actions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, null, 12, "div", [["class", "filter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 9, "select", [["class", "form-control form-control-sm"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.filterSelected = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(22, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(24, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsComponent_1)), i1.ɵdid(27, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(31, 0, null, null, 8, "div", [["class", "action"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(33, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(35, null, ["\n          ", " "])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2192"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.items); var currVal_1 = true; var currVal_2 = "default"; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.filterSelected; _ck(_v, 22, 0, currVal_10); var currVal_11 = _co.filterOptions.options; _ck(_v, 27, 0, currVal_11); var currVal_14 = _co.actionOption.id; _ck(_v, 34, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 24).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 24).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 24).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 24).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 24).ngClassValid; var currVal_8 = i1.ɵnov(_v, 24).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 34).target; var currVal_13 = i1.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_12, currVal_13); var currVal_15 = _co.actionOption.name; _ck(_v, 35, 0, currVal_15); }); }
export function View_EventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-events", [], null, null, null, View_EventsComponent_0, RenderType_EventsComponent)), i1.ɵdid(1, 573440, null, 0, i8.EventsComponent, [], null, null)], null, null); }
var EventsComponentNgFactory = i1.ɵccf("app-events", i8.EventsComponent, View_EventsComponent_Host_0, { data: "data", filterOptions: "filterOptions", actionOption: "actionOption" }, { filter: "filter", actionEvent: "actionEvent" }, []);
export { EventsComponentNgFactory as EventsComponentNgFactory };
