/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../../manager/pages/workflow/finish/finish.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./finish.component";
import * as i4 from "../../../../core/services/authorization.service";
import * as i5 from "../../../../core/services/workflow-build.service";
import * as i6 from "@angular/router";
var styles_IntegrationFinishPageComponent = [i0.styles];
var RenderType_IntegrationFinishPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationFinishPageComponent, data: {} });
export { RenderType_IntegrationFinishPageComponent as RenderType_IntegrationFinishPageComponent };
function View_IntegrationFinishPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          Aguarde enquanto fazemos o seu redirecionamento.\n        "]))], null, null); }
function View_IntegrationFinishPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sua janela j\u00E1 pode ser encerrada."]))], null, null); }
export function View_IntegrationFinishPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "document-finish"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "document-hero"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Modelo salvo com sucesso!"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationFinishPageComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationFinishPageComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callbackUrl(); _ck(_v, 12, 0, currVal_0); var currVal_1 = !_co.callbackUrl(); _ck(_v, 15, 0, currVal_1); }, null); }
export function View_IntegrationFinishPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-integration-finish-page", [], null, null, null, View_IntegrationFinishPageComponent_0, RenderType_IntegrationFinishPageComponent)), i1.ɵdid(1, 245760, null, 0, i3.IntegrationFinishPageComponent, [i4.AuthorizationService, i5.WorkflowBuildService, i6.Router, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationFinishPageComponentNgFactory = i1.ɵccf("app-workflow-integration-finish-page", i3.IntegrationFinishPageComponent, View_IntegrationFinishPageComponent_Host_0, {}, {}, []);
export { IntegrationFinishPageComponentNgFactory as IntegrationFinishPageComponentNgFactory };
