/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draw-signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/angular2-signaturepad/signature-pad.ngfactory";
import * as i3 from "angular2-signaturepad/signature-pad";
import * as i4 from "./draw-signature.component";
var styles_DrawSignatureComponent = [i0.styles];
var RenderType_DrawSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawSignatureComponent, data: {} });
export { RenderType_DrawSignatureComponent as RenderType_DrawSignatureComponent };
export function View_DrawSignatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { signaturePad: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "row comp_"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "col align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 19, "div", [["class", "box-draw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "signature-pad", [], null, [[null, "onBeginEvent"], [null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeginEvent" === en)) {
        var pd_0 = (_co.drawStart() !== false);
        ad = (pd_0 && ad);
    } if (("onEndEvent" === en)) {
        var pd_1 = (_co.drawComplete() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SignaturePad_0, i2.RenderType_SignaturePad)), i1.ɵdid(8, 1228800, [[1, 4]], 0, i3.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onBeginEvent: "onBeginEvent", onEndEvent: "onEndEvent" }), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n\n      "])), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [["class", "position"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "div_clear"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "btn_clear_sign"], ["title", "Clique para limpar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ngAfterViewInit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            x\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "hr", [["class", "line-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "h6", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desenhe sua assinatura aqui"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signaturePadOptions; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_DrawSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-draw-signature", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DrawSignatureComponent_0, RenderType_DrawSignatureComponent)), i1.ɵdid(1, 4243456, null, 0, i4.DrawSignatureComponent, [i1.ElementRef], null, null)], null, null); }
var DrawSignatureComponentNgFactory = i1.ɵccf("app-draw-signature", i4.DrawSignatureComponent, View_DrawSignatureComponent_Host_0, {}, { dataSignDrawEvent: "dataSignDrawEvent" }, []);
export { DrawSignatureComponentNgFactory as DrawSignatureComponentNgFactory };
