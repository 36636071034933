<div class="page page-members">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb">
          <li><a> Página inicial </a></li>
          <li><a> Departamentos </a></li>
          <li><a> Times </a></li>
          <li><a> Time 1 </a></li>
        </ul>
      </div>
      <div class="col-12"><h2>Time 1</h2></div>
      <div class="col-12">
        <div class="members-list">
          <div class="row">
            <div class="col-12 mt-3">
              <div class="member-nav">
                <div class="buttons">
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary"
                    (click)="open(modalCreateMember)"
                  >
                    Criar novo usuário
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-5">
              <div class="member-list">
                <app-lists
                  [collection]="collection"
                  [fields]="configList"
                  [sortableFields]="sortFields"
                  (activity)="doAction($event)"
                  (sort)="doSort($event)"
                ></app-lists>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalCreateMember let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Adicionar usuário'"
  >
    <app-member-form
      [modal]="modal"
      [selected]="selectedMember"
      [isLoading]="isLoading"
      [collection]="collection"
    ></app-member-form>
  </app-modal-action>
</ng-template>
