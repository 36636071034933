import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * type of modal
   */
  @Input() type: string;

  /**
   * show header of modal
   */
  @Input() showHeader?: boolean = true;

  /**
   * show footer of modal
   */
  @Input() showFooter?: boolean = true;

  /**
   * show button close of modal
   */
  @Input() showClose?: boolean = true;

  /**
   * text of title
   */
  @Input() title?: string;

  /**
   * text of modal
   */
  @Input() text?: string;
}
