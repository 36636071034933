<div class="form-team form-modal">
  <form (ngSubmit)="doSubmit()" [formGroup]="teamForm" novalidate>
    <div class="row form-modal">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> Nome </label>
              <input
                type="text"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': f.name.errors && !f.name.pristine
                }"
                formControlName="name"
              />
              <div *ngIf="f.name.errors && !f.name.pristine">
                <span class="error" [hidden]="!f.name.errors.required">
                  O nome é obrigatório
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end line -->
        <div class="row">
          <div class="col-12 text-center mt-5 mb-3">
            <button class="btn btn-default" (click)="modal.close('cancel')">
              Cancelar
            </button>
            <button
              class="btn btn-secondary btn-sm"
              type="submit"
              [disabled]="teamForm.invalid || isLoading"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
