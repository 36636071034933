<div class="folders-list" *ngIf="selected === null">
  <div class="row">
    <div class="col-12">
      <div class="folder-nav">
        <div class="buttons">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            (click)="open(modalCreateFolder, 'md')"
          >
            Nova pasta
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-5">
      <div class="folder-list">
        <app-folder-list
          [collection]="collection"
          [pagination]="pagination"
          [integrationWithFolder]="hasIntegrationWithFolder()"
          (activity)="doAction($event)"
          (doChangePage)="onChangePage($event)"
        >
        </app-folder-list>
      </div>
    </div>
  </div>
</div>

<div class="folders-list" *ngIf="selected !== null">
  <div class="row">
    <div class="col-6 col-md-8 col-lg-10">
      <h2>{{ selected.name === 'default' ? 'Padrão' : selected.name }}</h2>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
      <div class="previous" (click)="selected = null">Voltar</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="folder-list">
        <app-document-folder-list
          [selectedFolder]="selected"
          [collection]="folderDocuments"
          [pagination]="paginationDocs"
          (activity)="doAction($event)"
          (doChangePage)="onChangePageDocs($event)"
        >
        </app-document-folder-list>
      </div>
    </div>
  </div>
</div>

<ng-template #modalCreateFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Nova pasta'"
  >
    <app-folder-form
      [modal]="modal"
      [selected]="selectedFolder"
      [integrationWithFolder]="hasIntegrationWithFolder()"
      [isLoading]="isLoading"
    ></app-folder-form>
  </app-modal-action>
</ng-template>

<ng-template #modalIntegrationWithFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="selectedFolderIntegration.titleModal"
  >
    <app-change-integration-folder
      [modal]="modal"
      [selected]="selectedFolderIntegration"
    >
    </app-change-integration-folder>
  </app-modal-action>
</ng-template>

<ng-template #modalDeleteIntegrationWithFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple red'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Aviso!'"
  >
    <app-action-feedback
      [modal]="modal"
      [payload]="selectedFolderIntegration"
      [text]="textModal()"
    ></app-action-feedback>
  </app-modal-action>
</ng-template>

<ng-template #modalShareFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Compartilhar pasta'"
  >
    <app-share-folder-form
      [modal]="modal"
      [selected]="selectedFolder"
      [isLoading]="isLoading"
    ></app-share-folder-form>
  </app-modal-action>
</ng-template>

<ng-template #modalRenameFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="false"
    [title]="'Renomear pasta'"
  >
    <app-rename-folder-form
      [modal]="modal"
      [isLoading]="isLoading"
    ></app-rename-folder-form>
  </app-modal-action>
</ng-template>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>

<ng-template #modalMoverFolder let-modal>
  <app-modal-action
    [modal]="modal"
    [typeModal]="'simple green'"
    [showHeader]="true"
    [showFooter]="false"
    [showClose]="true"
    [title]="'Mover para'"
  >
    <app-move-folder [modal]="modal"></app-move-folder>
  </app-modal-action>
</ng-template>
