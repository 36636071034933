import * as tslib_1 from "tslib";
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
/**
 * Provides a base for choose plan workflow.
 */
var TeamService = /** @class */ (function (_super) {
    tslib_1.__extends(TeamService, _super);
    function TeamService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.endpoint = '/mock/teams.json';
        _this.apiFieldsMap = {};
        _this.fieldsRequired = [];
        return _this;
    }
    return TeamService;
}(BaseAPI));
export { TeamService };
