import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-rejection-form',
  templateUrl: './rejection-form.component.html',
  styleUrls: ['./rejection-form.component.scss']
})
export class RejectionFormComponent {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of Form
   */
  rejectForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'rejection',
      formData: this.rejectForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.rejectForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.rejectForm = this.formBuilder.group({
      reason: ['', Validators.required]
    });
  }
}
