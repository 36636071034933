<div class="table-container">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr [ngClass]="{ hover: hover }">
          <th
            *ngFor="let field of fields"
            [ngClass]="field.klass"
            [style.width]="field.width"
          >
            <i [ngClass]="field.icon" *ngIf="field.icon"></i>
            <input type="checkbox" *ngIf="field.type === 'checkbox'" />
            <span *ngIf="!field.isSortable"> {{ field.name }} </span>
            <span
              class="sortable"
              *ngIf="field.isSortable"
              (click)="sortBy(field.fieldKey)"
            >
              {{ field.name }}
              <i
                class="fas fa-sort-down arrow"
                *ngIf="
                  !sortableFields || sortableFields[field.fieldKey] !== 'ASC'
                "
              ></i>
              <i
                class="fas fa-sort-up arrow"
                *ngIf="
                  sortableFields && sortableFields[field.fieldKey] === 'ASC'
                "
              ></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of collection; let i = index">
          <tr [ngClass]="{ hover: hover }" (click)="chooseItem(item, i)">
            <td *ngFor="let field of fields" [ngClass]="field.options?.klass">
              <!-- begin type: icon -->
              <i
                class="fa icon"
                *ngIf="
                  field.type === 'icon' &&
                  _getValue(item, field.fieldId).text.action
                "
                [ngClass]="_getValue(item, field.fieldId).text.action"
              ></i>
              <!-- end type: icon -->

              <!-- begin type: text -->
              <span *ngIf="field.type === 'text'">
                {{ _getValue(item, field.fieldId) }}
              </span>
              <!-- end type: text -->

              <!-- begin type: status -->
              <span
                *ngIf="field.type === 'status'"
                [ngClass]="getStatusClass(item, field)"
              >
                {{ _getValue(item, field.fieldId).text.text }}
              </span>
              <!-- end type: status -->

              <!-- begin type: select -->
              <div *ngIf="field.type === 'select'">
                <select
                  #action
                  class="custom"
                  (click)="$event.stopPropagation()"
                  (change)="goAction($event, item)"
                >
                  <option value="">Selecione</option>
                  <ng-container *ngFor="let i of field.options.items">
                    <option [value]="i.action"> {{ i.name }} </option>
                  </ng-container>
                </select>
              </div>
              <!-- end type: select -->

              <!-- begin type: workflow-actions -->
              <div
                *ngIf="
                  field.type === 'custom-select' &&
                  _getAction(field.options.items, item, field) as actions
                "
              >
                <select
                  #action
                  class="custom"
                  (click)="$event.stopPropagation()"
                  (change)="goAction($event, item, field.clearAfterAction)"
                  [disabled]="actions.length === 0"
                >
                  <option value="">Selecione</option>
                  <ng-container *ngFor="let action of actions">
                    <option [value]="action.action"> {{ action.name }} </option>
                  </ng-container>
                </select>
              </div>
              <!-- end type: workflow-actions -->
            </td>
          </tr>
          <ng-container
            *ngIf="
              moreInfo &&
              moreInfo.workflow &&
              moreInfo.workflow?.id === item.workflow?.id
            "
          >
            <tr [ngClass]="{ hover: hover }" class="child">
              <td [colSpan]="fields.length">
                <i
                  class="fas fa-spinner fa-spin"
                  [hidden]="!loadingDetails"
                ></i>
                <div class="details" [hidden]="loadingDetails">
                  <p *ngIf="!dataDetails || !dataDetails.documents">
                    Não há detalhes cadastrados
                  </p>
                  <div
                    *ngIf="
                      dataDetails &&
                      dataDetails.documents &&
                      dataDetails.documents.length > 0
                    "
                  >
                    <ol *ngFor="let document of dataDetails.documents">
                      <li
                        *ngFor="
                          let recipient of document.steps.recipients;
                          let k = index
                        "
                      >
                        <span>
                          <b>Assinante: </b> {{ recipient.name }} -
                          {{ recipient.status.text }}
                        </span>
                      </li>
                    </ol>
                  </div>
                  <button class="btn btn-list" (click)="redirectToDownload()">
                    Documento Original
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="collection && collection.length === 0">
          <tr>
            <td [colSpan]="fields.length" class="text-center">
              <span>Não há registros cadastrados</span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination-block">
  <app-pagination
    [totalItems]="pagination.totalItems"
    [pageCount]="pagination.pageCount"
    [currentPage]="pagination.currentPage"
    (doPage)="doPage($event)"
  ></app-pagination>
</div>
