import { Component, Input } from '@angular/core';
import { WorkflowContact } from '@app/core/class';

@Component({
  selector: 'app-workflow-digital-signature-field',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['../../../droppable-field.component.scss']
})
export class WorkflowDigitalSignatureFieldComponent {
  @Input()
  public contact: WorkflowContact;
}
