import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { WorkflowBuildService, WORKFLOW_STEP_FIELDS } from '@app/core/services';
import { WorkflowDroppableFieldsZoneComponent } from './droppable-fields-zone';
var WorkflowDocumentComponent = /** @class */ (function () {
    function WorkflowDocumentComponent(workflowBuildService) {
        this.WORKFLOW_STEP_FIELDS = WORKFLOW_STEP_FIELDS;
        /**
         * Navigation of pages
         *
         * @uses (top | bottom | both)
         */
        this.navigation = 'bottom';
        this.showUpload = true;
        this.pageSign = -1;
        this.pagesToSign = [];
        /**
         * Show toobar functions of document
         */
        this.showToolbar = false;
        this.fileUrl = '';
        // Older code retrocompatibility
        this.pageSize = new EventEmitter();
        // Older code retrocompatibility
        this.pageChange = new EventEmitter();
        this.page = 1;
        this.isLoaded = false;
        this.isRendering = false;
        this.renderProgress = 0;
        this.file = null;
        this.viewPortParameters = { scale: 1 };
        this.workflowBuildService = workflowBuildService;
    }
    WorkflowDocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.file = this.workflowBuildService.getVisibleFile();
        this.defineCurrentPageToShow();
        this.workflowBuildService.observeVisibleFile().subscribe(function (visibleFile) {
            _this.isRendering = true;
            _this.renderProgress = 0;
            _this.file = visibleFile;
            _this.defineCurrentPageToShow();
            if (!_this.file) {
                _this.isLoaded = false;
                _this.isRendering = false;
            }
        });
        this.filterPagesToSign();
    };
    WorkflowDocumentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('fileUrl')) {
            // If a workflow file was not provided but a link instead, we flag
            // the rendering process when the link changes
            if (changes.fileUrl.previousValue !== changes.fileUrl.currentValue) {
                this.isRendering = true;
            }
            return;
        }
    };
    WorkflowDocumentComponent.prototype.hasFileReady = function () {
        if (this.fileUrl) {
            return true;
        }
        return !!this.file && !!this.pdf && !this.isRendering;
    };
    WorkflowDocumentComponent.prototype.hasFiles = function () {
        return this.workflowBuildService.getFiles().length > 0;
    };
    WorkflowDocumentComponent.prototype.getFileURL = function () {
        if (this.fileUrl) {
            return this.fileUrl;
        }
        if (!this.file) {
            return '';
        }
        return this.file.getDownloadURL() || this.file.getBase64();
    };
    WorkflowDocumentComponent.prototype.getPDFTotalPages = function () {
        if (!this.pdf) {
            return 0;
        }
        return this.pdf.numPages;
    };
    /**
     * Calls after document loaded
     *
     * @param (any) pdfdata config of document
     */
    WorkflowDocumentComponent.prototype.afterLoadComplete = function (pdfData) {
        this.isLoaded = true;
        this.pdf = pdfData;
        // Load previous set page or the first one
        var loadPageNumber = 1;
        if (this.file) {
            var currentPage = this.file.getCurrentPage();
            if (currentPage) {
                loadPageNumber = currentPage.getPageNumber();
            }
        }
        this.changePage(loadPageNumber);
    };
    WorkflowDocumentComponent.prototype.onProgress = function (progressData) {
        this.renderProgress = progressData.total;
    };
    WorkflowDocumentComponent.prototype.onPageRendered = function (e) {
        var _this = this;
        this.isRendering = false;
        if (this.file) {
            this.file.setCurrentPage(this.page);
            this.calcProportion();
        }
        // Older code retrocompatibility
        this.pdf.getPage(this.page).then(function (p) {
            var viewport = p.getViewport(_this.viewPortParameters);
            _this.pageSize.emit({
                width: viewport.width,
                height: viewport.height,
                pageNumber: _this.page
            });
        });
    };
    /**
     * Get total page
     */
    WorkflowDocumentComponent.prototype.getTotalPage = function () {
        return this.pagesToSign.length;
    };
    /**
     * Filter Pages To Sign
     */
    WorkflowDocumentComponent.prototype.filterPagesToSign = function () {
        var _this = this;
        if (this.recipients) {
            this.recipients.forEach(function (item) {
                for (var _i = 0, _a = item.fields; _i < _a.length; _i++) {
                    var fields = _a[_i];
                    if (fields.type === WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE) {
                        _this.pagesToSign.push(fields.page);
                    }
                }
            });
            this.pagesToSign = this.pagesToSign.filter(function (el, i) {
                return _this.pagesToSign.indexOf(el) === i;
            });
        }
    };
    WorkflowDocumentComponent.prototype.changeToPageExpectingSignature = function (nPage) {
        this.changePage(this.pagesToSign[nPage]);
    };
    WorkflowDocumentComponent.prototype.changePage = function (pageNumber) {
        if (pageNumber < 1) {
            return;
        }
        if (pageNumber > this.getPDFTotalPages()) {
            return;
        }
        if (this.file) {
            this.file.setCurrentPage(pageNumber);
            this.calcProportion();
        }
        this.page = pageNumber;
        // Older code retrocompatibility
        this.pageChange.emit({
            page: pageNumber,
            total: this.getPDFTotalPages()
        });
    };
    /**
     * Print file
     */
    WorkflowDocumentComponent.prototype.printFile = function () {
        var win = window.open(this.getFileURL(), '_blank');
        setTimeout(function () {
            win.print();
        }, 0);
    };
    WorkflowDocumentComponent.prototype.defineCurrentPageToShow = function () {
        if (!this.file) {
            this.page = 1;
            return;
        }
        var currentPage = this.file.getCurrentPage();
        if (!currentPage) {
            this.page = 1;
            return;
        }
        this.page = currentPage.getPageNumber();
    };
    WorkflowDocumentComponent.prototype.calcProportion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pageSize, dropzoneSize, workflowFilePage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPDFPageSize()];
                    case 1:
                        pageSize = _a.sent();
                        dropzoneSize = this.getDropzoneSize();
                        workflowFilePage = this.file.getCurrentPage();
                        workflowFilePage.calcPageViewportProportion(dropzoneSize.width, pageSize.width);
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkflowDocumentComponent.prototype.getDropzoneSize = function () {
        var dropzoneElement = this.worfklowDropZoneComponent.dropzoneElem
            .nativeElement;
        return {
            width: dropzoneElement.clientWidth,
            height: dropzoneElement.clientHeight
        };
    };
    WorkflowDocumentComponent.prototype.getPDFPageSize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pdfPage, pageViewport;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.pdf.getPage(this.page)];
                    case 1:
                        pdfPage = _a.sent();
                        pageViewport = pdfPage.getViewport(this.viewPortParameters);
                        return [2 /*return*/, {
                                width: pageViewport.width,
                                height: pageViewport.height
                            }];
                }
            });
        });
    };
    return WorkflowDocumentComponent;
}());
export { WorkflowDocumentComponent };
