<div class="form-cancel-account form-modal">
  <form (ngSubmit)="doSubmit()" [formGroup]="cancelForm" novalidate>
    <div class="step-1" *ngIf="!hasFormFilled">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label> Motivo do cancelamento </label>
            <textarea
              class="form-control form-control-sm"
              rows="4"
              [ngClass]="{
                'is-invalid': f.reason.errors && !f.reason.pristine
              }"
              formControlName="reason"
            ></textarea>
            <div *ngIf="f.reason.errors && !f.reason.pristine">
              <span class="error" [hidden]="!f.reason.errors.required">
                O motivo do cancelamento é obrigatório
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn-default"
            (click)="modal.close('cancel')"
          >
            Cancelar
          </button>
          <button
            class="btn btn-secondary btn-sm"
            (click)="doConfirm()"
            [disabled]="cancelForm.invalid || isLoading"
          >
            Desativar
          </button>
        </div>
      </div>
    </div>

    <div class="step-2" *ngIf="hasFormFilled">
      <div class="row">
        <div class="col-12">
          <p>Aviso, todos os dados serão perdidos. Deseja continuar?</p>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-default" (click)="modal.close('cancel')">
            Cancelar
          </button>
          <button class="btn btn-secondary btn-sm" type="submit">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
