<app-feedback
  [message]="feedback.message"
  [theme]="feedback.theme"
></app-feedback>

<div class="page page-document">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="document-breadcrumb">
          <ul class="document-nav">
            <li [ngClass]="stepClass(STEP_BUILD)"><span>Informações</span></li>
            <li [ngClass]="stepClass(STEP_REVIEW)"><span>Preparação</span></li>
            <li [ngClass]="stepClass(STEP_FINISH)"><span>Conclusão</span></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="steps"><router-outlet></router-outlet></div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]="modalRef.data.title"
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
