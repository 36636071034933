import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Team } from 'app/core/models';
import { TeamService } from 'app/core/services';
import { finalize } from 'rxjs/operators';
import { Logger } from '@app/core/class';

const log = new Logger('TeamsPage');

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsPageComponent implements OnInit {
  /**
   * loading of form
   */
  isLoading: boolean = false;

  /**
   * collection with data
   */
  collection: Array<Team.TeamPayload>;

  /**
   * current team for edit/delete
   */

  selectedTeam: any;

  constructor(
    private teamService: TeamService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // this.getTeams();
  }

  /**
   * provides the data of teams
   *
   * @return void
   */
  // getTeams() {
  //   try {
  //     // this.isLoading = true;
  //     this.teamService
  //       .getAll()
  //       .pipe(
  //         finalize(() => {
  //           // this.isLoading = false;
  //         })
  //       )
  //       .subscribe(
  //         (response: Array<Team.TeamPayload>) => {
  //           log.debug(`Success [getTeams]: ${response}`);
  //           this.collection = response;
  //         },
  //         (error: any) => {
  //           log.debug(`Error [getTeams]: ${error}`);
  //           this.collection = [];
  //         }
  //       );
  //   } catch (e) {
  //     // this.isLoading = false;
  //     this.collection = [];
  //     log.debug(`Error [getTeams]: ${e}`);
  //   }
  // }

  /**
   * provides a action for open modal
   *
   * @param (any) content of modal
   * @return void
   */
  open(content: any) {
    //   this.modalService
    //     .open(content, {
    //       ariaLabelledBy: 'modal-title',
    //       keyboard: false,
    //       backdrop: 'static'
    //     })
    //     .result.then(result => {
    //       if (result.action === 'save') {
    //         if (result.form === 'user') {
    //           this.doSubmitForm(result.formData);
    //           return;
    //         }
    //       }
    //       if (result === 'cancel') {
    //         return;
    //       }
    //     });
  }

  /**
   * Action by clicking in submit teams form
   *
   * @param (any) form with data
   */
  // doSubmitForm(form: any) {
  //   console.log(form);
  // }

  /**
   * Action grab data
   *
   * @param (any) event to process an action
   */
  doAction(event: any) {
    //   console.log(event.type);
    //   if (event.type === 'edit') {
    //     console.log(event.obj);
    //   }
    //   if (event.type === 'remove') {
    //     console.log(event.obj);
    //   }
  }
}
