import * as tslib_1 from "tslib";
import { WorkflowBaseDroppedField } from '../base-dropped-field.component';
var WorkflowDroppableInlineSignatureFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowDroppableInlineSignatureFieldComponent, _super);
    function WorkflowDroppableInlineSignatureFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkflowDroppableInlineSignatureFieldComponent;
}(WorkflowBaseDroppedField));
export { WorkflowDroppableInlineSignatureFieldComponent };
