import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-cancel-account-form',
  templateUrl: './cancel-account-form.component.html',
  styleUrls: ['./cancel-account-form.component.scss']
})
export class CancelAccountFormComponent {
  /**
   * Informs if loading is active or not
   */
  @Input() isLoading: boolean = false;

  /**
   * object of modal
   */
  @Input() modal: any;

  /**
   * Data of Form
   */
  cancelForm: FormGroup;

  /**
   * Checks if the form is completed
   */
  hasFormFilled: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * Confirm form action
   */
  doConfirm() {
    this.hasFormFilled = true;
  }

  /**
   * Data selection action by clicking the form submit button
   */
  doSubmit() {
    this.modal.close({
      action: 'save',
      form: 'cancel-account',
      formData: this.cancelForm.value
    });
  }

  /**
   *  Getter to access the form fields
   *
   *  @return AbstractControl
   */
  get f() {
    return this.cancelForm.controls;
  }

  /**
   * Form creation and validation configuration
   */
  private createForm() {
    this.cancelForm = this.formBuilder.group({
      reason: ['', Validators.required],
      status: ['0']
    });
  }
}
