import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseAPI } from '../class/baseAPI.class';
/**
 * Provides a base for authentication workflow.
 */
var AuthenticationService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticationService, _super);
    function AuthenticationService(httpClient, authorizationService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authorizationService = authorizationService;
        return _this;
    }
    /**
     * Authenticates the user.
     * @param payload The login parameters.
     * @return The user credentials.
     */
    AuthenticationService.prototype.login = function (payload) {
        var _this = this;
        var data = {
            grant_type: 'password',
            client_id: 'app',
            client_secret: 'app',
            username: payload.email,
            password: payload.password
        };
        var timestamp = new Date();
        return this.httpClient
            .disableAuthorization()
            .post('/oauth', data)
            .pipe(map(function (body) {
            if (typeof body.access_token !== 'undefined') {
                timestamp.setTime(timestamp.getTime() + body.expires_in * 1000);
                var result = {
                    access_token: body.access_token,
                    expires_in: body.expires_in,
                    expires_at: timestamp,
                    token_type: body.token_type,
                    scope: body.scope,
                    refresh_token: body.refresh_token,
                    status: 200
                };
                _this.authorizationService.setCredentials(result);
                return result;
            }
            else {
                return {
                    detail: body.detail,
                    status: body.status
                };
            }
        }));
    };
    /**
     * Reset password of user.
     * @param payload The reset password parameters.
     * @return object The data with success
     */
    AuthenticationService.prototype.resetPassword = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=recovery-password', payload)
            .pipe(map(function (body) {
            if (body.status === 202) {
                return {
                    success: true,
                    details: body.detail
                };
            }
            else {
                return {
                    success: false,
                    details: body.detail
                };
            }
        }));
    };
    /**
     * Forget password of user.
     * @param payload The forget password parameters.
     * @return object The data with success
     */
    AuthenticationService.prototype.forgetPassword = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=forgot-password', payload)
            .pipe(map(function (body) {
            if (body.status === 202) {
                return {
                    success: true,
                    pinPrefix: body.pinPrefix,
                    token: body.token,
                    details: body.detail
                };
            }
            else {
                return {
                    success: false,
                    details: body.detail
                };
            }
        }));
    };
    /**
     * Confirm account.
     * @param payload The confirm account parameters.
     * @return object The data with success
     */
    AuthenticationService.prototype.confirmAccount = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=confirm-register', payload);
    };
    AuthenticationService.prototype.getRegisterData = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=confirm-register-data', payload);
    };
    AuthenticationService.prototype.confirmChangeBusinessUnit = function (payload) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=change-business-unit', payload);
    };
    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    AuthenticationService.prototype.logout = function () {
        var _this = this;
        var token = this.authorizationService.getAccessToken();
        if (token) {
            // Revoke token
            return this.httpClient.post('/oauth/revoke', { token: token }).pipe(tap(function () {
                // Customize credentials invalidation here
                _this.authorizationService.setCredentials();
                _this.authorizationService.setUser();
            }));
        }
        return of(null);
    };
    AuthenticationService.prototype.reSendConfirmAccount = function (email) {
        return this.httpClient
            .disableAuthorization()
            .post('/user?q=resend-confirm-register', {
            email: email
        });
    };
    return AuthenticationService;
}(BaseAPI));
export { AuthenticationService };
