<nav class="navbar navbar-expand-lg navbar-light header">
  <div class="container" style="padding: 0;">
    <ng-container *ngIf="logo; else defaultlogo">
      <a routerLink="/manager" class="logo-img">
        <img [src]="logo" class="img-fluid" />
      </a>
    </ng-container>

    <ng-template #defaultlogo>
      <a routerLink="/manager" class="brand large header-logo">Assine Online</a>
    </ng-template>

    <div class="mobile-actions">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div
      class="collapse navbar-collapse header-collapse"
      [ngbCollapse]="isCollapsed"
      id="navbarToggler"
    >
      <ul class="navbar-nav mt-2 mt-lg-0 header-menu">
        <li
          class="nav-item"
          (mouseover)="dashboardDrop.open()"
          (mouseout)="dashboardDrop.close()"
        >
          <div class="d-inline-block" ngbDropdown #dashboardDrop="ngbDropdown">
            <a
              class="nav-link"
              ngbDropdownAnchor
              id="dropdownDashboardWidgets"
              routerLink="/manager/dashboard"
              routerLinkActive="header-active"
              (click)="resetWorkflow()"
            >
              <i class="fas fa-th"></i> Painel
            </a>
            <div
              class="dropdown-menu-modal"
              ngbDropdownMenu
              aria-labelledby="dropdownDashboardWidgets"
              [hidden]="router.url !== '/manager/dashboard'"
            >
              <a
                class="dropdown-item"
                routerLinkActive="header-active"
                [routerLink]="['/manager/dashboard']"
                [queryParams]="{ custom: 'true' }"
              >
                Customizar painel
              </a>
              <!-- <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                routerLink="/manager/dashboard"
                routerLinkActive="header-active"
                >Carregar painel</a
              > -->
            </div>
          </div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/manager/workflow"
            routerLinkActive="header-active"
            (click)="resetWorkflow()"
          >
            <i class="fas fa-plus-circle"> </i> Iniciar
          </a>
        </li>
        <li
          class="nav-item"
          (mouseover)="managerDrop.open()"
          (mouseout)="managerDrop.close()"
        >
          <div class="d-inline-block" ngbDropdown #managerDrop="ngbDropdown">
            <a
              class="nav-link"
              ngbDropdownAnchor
              routerLink="/manager/manage"
              routerLinkActive="header-active"
              (click)="resetWorkflow()"
            >
              <i class="fas fa-paste"></i> Documentos e Pastas
            </a>
            <!--div class="dropdown-menu-modal" ngbDropdownMenu>
              <a class="dropdown-item" routerLink="/manager/documents">
                Documentos
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/manager/contacts">Contatos</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/manager/folders">Pastas</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/manager/departments"
                >Departamentos</a
              >
            </div-->
          </div>
        </li>
        <li class="nav-item">
          <div class="d-inline-block">
            <a
              class="nav-link"
              id="dropdownCheckItens"
              routerLink="/checker"
              routerLinkActive="header-active"
              (click)="resetWorkflow()"
            >
              <i class="fas fa-check"></i> Verificador
            </a>
          </div>
        </li>

        <li class="nav-item">
          <div class="d-inline-block">
            <a
              class="nav-link"
              href="https://ajuda.assine.online/pt-br/knowledge"
              target="_blank"
            >
              <i class="fas fa-question"></i> Ajuda
            </a>
          </div>
        </li>
      </ul>

      <app-remaining-user-balance></app-remaining-user-balance>

      <div class="header-profile-notification">
        <div class="profile">
          <div class="avatar">
            <img
              *ngIf="photoURL; else defaultPhoto"
              class="header-avatar"
              [src]="photoURL"
            />
            <ng-template #defaultPhoto>
              <span class="header-avatar">
                <i class="icon far fa-user-circle"></i>
              </span>
            </ng-template>
            <a (mouseover)="optionsDrop.open()">
              <div
                class="d-inline-block"
                ngbDropdown
                #optionsDrop="ngbDropdown"
              >
                <a class="username" ngbDropdownAnchor id="dropdownOptions">{{
                  user?.name
                }}</a>
                <div class="dropdown-menu-modal" ngbDropdownMenu>
                  <button (click)="profile()" class="dropdown-item">
                    Perfil
                  </button>
                  <button class="dropdown-item pointer" (click)="logout()">
                    Sair
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="cred-information" *ngIf="showUserPlanMessage">
  <p>
    Seus créditos acabaram. Para continuar enviando documentos, atualize seu
    plano <a routerLink="/manager/plans"><strong>clicando aqui</strong></a>
  </p>
</div>
