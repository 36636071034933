import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseAPI } from '../class/baseAPI.class';
import { WorkflowTemplate } from '../models/workflow-template.model';
import { Workflow } from '../models/workflow.model';
import { parseDate } from '../helpers';

@Injectable()
export class WorkflowTemplateService extends BaseAPI {
  private templateSettings: WorkflowTemplate.WorkflowTemplateSettings;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/workflow-template';
    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  /**
   * Gets all data of a service
   */
  getAll(queryString?: any): Observable<any> {
    return super.getAll(queryString).pipe(
      map((response: any) => {
        const list = response._embedded.workflow_template.map((wt: any) => {
          return Object.assign(wt, {
            dateCreated: parseDate(wt.dateCreated)
          });
        });
        return {
          items: list,
          page: response.page,
          page_count: response.page_count,
          page_size: response.page_size,
          total_items: response.total_items,
          _raw: response._embedded,
          _links: response._links
        };
      })
    );
  }

  getWorkflowTemplateById(
    id: number,
    token: string = ''
  ): Observable<WorkflowTemplate.WorkflowTemplatePayload> {
    let httpClient = this.httpClient;

    if (!!token) {
      httpClient = httpClient.disableAuthorization();
    }

    return httpClient.get<WorkflowTemplate.WorkflowTemplatePayload>(
      `${this.endpoint}/${id}`,
      {
        params: { token }
      }
    );
  }

  createWorkflowTemplate(
    name: string,
    workflow: Workflow.Settings,
    settings: any = {}
  ) {
    return this.httpClient.post(this.endpoint, {
      type: 1,
      name,
      settings,
      template: workflow
    });
  }

  updateWorkflowTemplate(
    id: number,
    workflow: Workflow.Settings,
    token?: string
  ): Observable<any> {
    let httpClient = this.httpClient;

    if (!!token) {
      httpClient = httpClient.disableAuthorization();
    }

    return httpClient
      .patch(
        `${this.endpoint}/${id}`,
        {
          template: workflow
        },
        {
          params: { token }
        }
      )
      .pipe(map((response: any) => response.template));
  }

  renameWorkflowTemplate(id: number, name: string): Observable<any> {
    return this.httpClient.patch(`${this.endpoint}/${id}`, { name });
  }

  deleteWorkflowTemplate(id: number): Observable<any> {
    return this.httpClient.delete(`${this.endpoint}/${id}`);
  }

  public setSettings(settings: WorkflowTemplate.WorkflowTemplateSettings) {
    this.templateSettings = settings;

    // If 'canAddSigners' is not set, default it to true
    if (!settings.hasOwnProperty('canAddSigners')) {
      this.templateSettings.canAddSigners = true;
    }
  }

  public canAddSigners(): boolean {
    if (!this.templateSettings) {
      return true;
    }

    return this.templateSettings.canAddSigners;
  }
}
