<div class="page page-document">
  <div class="container" *ngIf="workflow">
    <div class="row">
      <div class="col-12 title"><h5>Acompanhamento do(s) documento(s)</h5></div>
      <div class="col-12 col-md-7">
        <h3>ID do Fluxo: {{ workflow.workflow.id }}</h3>
        <div class="status-element">
          <div *ngIf="workflow.workflow.name">
            Nome: {{ workflow.workflow.name }}
          </div>
          <div>Prazo para a assinatura: {{ formattedDueDate }}</div>
          <div>Status: {{ workflowStatus.label }}</div>
          <div *ngIf="workflow.workflow.reason && workflowStatus.id === 5">
            Motivo da Rejeição: {{ workflow.workflow.reason }}
          </div>
        </div>
        <div class="description">Mensagem: {{ workflow.workflow.message }}</div>
        <span class="label">Documentos anexados:</span>
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="multifiles">
              <form action="" class="form">
                <div class="form-row">
                  <div class="form-group col-12 col-lg-12">
                    <select
                      class="form-control"
                      [(ngModel)]="file"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option
                        *ngFor="let f of workflow.documents"
                        [ngValue]="f"
                      >
                        {{ f.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="col-12 mb-4" style="text-align: left;">
            <div class="btn-group" ngbDropdown role="group">
              <button class="btn btn-secondary" ngbDropdownToggle>
                Baixar
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button
                  class="dropdown-item"
                  (click)="
                    optionToDownloadFiles(LOCAL_STEP.DOCUMENT_SELECT, file)
                  "
                >
                  Documento selecionado
                </button>

                <button
                  class="dropdown-item"
                  *ngIf="workflow.documents.length > 1"
                  (click)="
                    optionToDownloadFiles(
                      LOCAL_STEP.ALL_DOCUMENTS,
                      workflow.documents
                    )
                  "
                >
                  Todos os documentos
                </button>

                <button
                  class="dropdown-item"
                  (click)="
                    optionToDownloadFiles(
                      LOCAL_STEP.SIGNATURE_SHEET_SELECTED,
                      file
                    )
                  "
                >
                  Página de assinatura selecionada
                </button>

                <button
                  class="dropdown-item"
                  *ngIf="workflow.documents.length > 1"
                  (click)="
                    optionToDownloadFiles(
                      LOCAL_STEP.ALL_SIGNATURE_SHEETS,
                      workflow.documents
                    )
                  "
                >
                  Todas as páginas de assinaturas
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="description">Assinantes:</div>
        <app-subscribers
          [items]="file?.steps.recipients"
          [isSortable]="false"
        ></app-subscribers>
      </div>

      <div class="col-12 col-md-5">
        <ng-container *ngIf="isSigned()">
          <div class="div-alert">
            <i class="fas fa-check"></i>
            <div class="text">
              Documento assinado! As assinaturas ficam visíveis apenas ao baixar
              o documento.
            </div>
          </div>
        </ng-container>

        <div class="box">
          <div class="box-content">
            <app-workflow-document
              [fileUrl]="getFileLink(file)"
              [showUpload]="false"
              navigation="bottom"
            ></app-workflow-document>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="'carregando dados...'"
  >
  </app-modal>
</ng-template>
