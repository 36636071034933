<div class="chart">
  <p *ngIf="false">Não há dados para renderizar o gráfico.</p>
  <div class="row">
    <div class="col-12 col-lg-7">
      <div class="donut">
        <ngx-donutchart
          [title]="title"
          [slices]="slices"
          [size]="size"
          [innerRadius]="innerRadius"
        >
        </ngx-donutchart>
      </div>
    </div>
    <div class="col-12 col-lg-5">
      <div class="legend">
        <div class="legend__item" *ngFor="let s of slices">
          <span class="square" [ngStyle]="{ 'background-color': s.color }">
            {{ s.value }}
          </span>
          <span class="text">{{ s.legend }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
