<div class="row">
  <div class="col-12" *ngIf="!showForm">
    <div class="row">
      <div class="col-12 mb-3">
        <button
          (click)="showForm = true"
          class="btn btn-secondary btn-sm"
          type="button"
        >
          Novo Contato
        </button>
      </div>
      <div class="col-12">
        <ng-select
          placeholder="Selecione os contatos"
          [items]="contacts"
          bindLabel="name"
          (add)="onAdd($event)"
          (remove)="onRemove($event)"
          (search)="onSearch($event)"
          [(ngModel)]="selectedContacts"
          typeToSearchText="Please enter 2 or more characters"
          [multiple]="true"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="showForm">
    <form (ngSubmit)="createContact()" [formGroup]="contactForm" novalidate>
      <div class="row">
        <div class="col-6 pr-2">
          <div class="form-group">
            <label>Nome</label>
            <input
              type="text"
              class="form-control form-control-sm"
              [ngClass]="{
                'is-invalid':
                  contactForm.controls.name.errors &&
                  !contactForm.controls.name.pristine
              }"
              formControlName="name"
              placeholder="José da Silva"
            />
            <div
              *ngIf="
                contactForm.controls.name.errors &&
                !contactForm.controls.name.pristine
              "
            >
              <span
                class="error"
                [hidden]="!contactForm.controls.name.errors.required"
              >
                O nome é obrigatório
              </span>
            </div>
          </div>
        </div>
        <div class="col-6 pl-2">
          <div class="form-group">
            <label>E-mail</label>
            <input
              type="text"
              class="form-control form-control-sm"
              [ngClass]="{
                'is-invalid':
                  contactForm.controls.email.errors &&
                  !contactForm.controls.email.pristine
              }"
              formControlName="email"
              placeholder="contato@email.com"
            />
            <div
              *ngIf="
                contactForm.controls.email.errors &&
                !contactForm.controls.email.pristine
              "
            >
              <span
                class="error"
                [hidden]="!contactForm.controls.email.errors.required"
              >
                O e-mail é obrigatório
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <button class="btn btn-default" (click)="showForm = false">
            Cancelar
          </button>
          <button
            class="btn btn-secondary btn-sm"
            type="submit"
            [disabled]="contactForm.invalid || isLoading"
          >
            Salvar Contato
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
