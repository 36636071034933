<div class="modal-header" *ngIf="showHeader">
  <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    *ngIf="showClose"
    (click)="modal.close('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="loading">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close('close')"
      *ngIf="showClose"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h4>{{ title }}</h4>
    <div class="loader">
      <span class="icon"></span> <span class="background"></span>
    </div>
    <p>{{ text | capitalize }}</p>
    <div *ngIf="showClose">
      <br />
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        (click)="modal.close()"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="showFooter">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Save click')"
  >
    Save
  </button>
</div>
