import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { WORKFLOW_STATUS, WORKFLOW_STEP_STATUS } from '@app/core/services';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
  providers: [NgbDropdownConfig]
})
export class ListsComponent implements OnInit {
  @Input() hover: boolean = false;

  /**
   * Collection with data for list
   */
  @Input() collection: Array<any>;

  /**
   * Collection with data for list
   */
  @Input() fields: Array<any>;

  /**
   * Pagination
   */
  @Input() pagination: any;

  /**
   * Object with sortable fields
   */
  @Input() sortableFields: any;

  /**
   * Boolean to define if details TR is enable
   */
  @Input() enableDetails: boolean;

  /**
   * Loading for details row
   */
  @Input() loadingDetails?: boolean;

  /**
   * Data with details of row
   */
  @Input() dataDetails?: any;

  /**
   * Callback with action and items
   */
  @Output() activity? = new EventEmitter();

  /**
   * Callback with selected items
   */
  @Output() selected? = new EventEmitter();

  /**
   * Callback with choosed item
   */
  @Output() choosed? = new EventEmitter();

  /**
   * Callback with sort items
   */
  @Output() sort? = new EventEmitter();

  /**
   * Callback with change page
   */
  @Output() doChangePage? = new EventEmitter();

  /**
   * List with all items marked
   */
  markedItems: Array<any> = [];

  /**
   * Current line selected
   */
  moreInfo: any;

  constructor(config: NgbDropdownConfig) {
    config.placement = 'bottom-right';
    this.collection = [];
    this.pagination = {};
  }

  ngOnInit() {}

  /**
   * Call the action of buttons
   *
   * @param (string) type of action
   * @param (any) object with data
   */
  goAction(event: any, obj: any, clearAfterAction?: boolean) {
    this.activity.emit({ type: event.target.value, data: obj });
    event.target.value = '';
  }

  /**
   * Creates the action of filtering all items marked in the list
   *
   * @param (any) target of checkbox
   * @param (number) id of item
   */
  markItems(target: any, id: number) {
    if (target.checked) {
      this.markedItems.push(id);
    } else {
      this.markedItems = this.markedItems.filter(e => e !== id);
    }

    this.selected.emit(this.markedItems);
  }

  /**
   * Creates the action to catch the element clicked inside a tr
   *
   * @param (number) index of item
   * @param (id) clicked item
   *
   */
  chooseItem(element: any, index?: any) {
    if (!this.enableDetails) {
      return;
    }

    if (element.workflowSteps) {
      this.moreInfoWithWorkflowSteps(element, index);
    } else if (this.moreInfo && this.moreInfo.workflow) {
      if (this.moreInfo.workflow.id !== element.workflow.id) {
        this.moreInfo = element;
        this.choosed.emit(element);
      } else {
        this.moreInfo = undefined;
      }
    } else {
      this.moreInfo = element;
      this.choosed.emit(element);
    }
  }

  moreInfoWithWorkflowSteps(element: any, index: any) {
    this.moreInfo = {
      workflow: { ...element },
      step: element.workflowSteps
    };
    this.collection[index].workflow = this.moreInfo.workflow;
    this.choosed.emit(this.moreInfo);
  }

  /**
   * Provides the functionality to reorganize the list according to the field
   *
   * @param (string) field
   * @param (number) id of item
   */
  sortBy(field: string) {
    this.sort.emit(field);
  }

  /**
   * Provides the functionality to change page in pagination
   *
   * @param (number) of page
   */
  doPage(p: number) {
    this.doChangePage.emit(p);
  }

  /**
   * Redirect to download route
   */
  redirectToDownload() {
    window.open(this.dataDetails.documents[0].originalFile.link, '_blank');
  }

  /**
   * Provides the functionality to return data with dot notation
   *
   * @param (number) of page
   */
  _getValue(obj: any, is: any, value?: any): any {
    if (typeof is === 'string') {
      if (is === 'workflow.name') {
        return this.limitFileNameSize(
          this._getValue(obj, is.split('.'), value),
          35
        );
      }
      return this._getValue(obj, is.split('.'), value);
    } else if (is.length === 1 && value !== undefined) {
      return (obj[is[0]] = value);
    } else if (is.length === 0) {
      return obj;
    } else {
      return this._getValue(obj[is[0]], is.slice(1), value);
    }
  }

  /**
   * Provides the functionality to return data of action
   *
   * @param (number) of page
   */
  _getAction(options: any, item: any, field?: any): any {
    const filter = options.filter((option: any) => {
      const workflow = item.workflow || item;
      let isCanceledToSign = false;
      let shouldHideView = false;

      if (workflow.status) {
        isCanceledToSign =
          workflow.status.id !== WORKFLOW_STATUS.CIRCULATING &&
          option.action === 'sign';

        // Hide the option if is 'view' and is waiting for a signature
        if (item.step && item.step.status) {
          shouldHideView =
            option.action === 'view' &&
            workflow.status.id === WORKFLOW_STATUS.CIRCULATING &&
            item.step.status.id === WORKFLOW_STEP_STATUS.WAITING;
        }
      }

      let isNotAlgo = false;
      if (option.can) {
        isNotAlgo =
          option.can.indexOf(this._getValue(item, option.canField)) !== -1;
      }
      if (
        !isCanceledToSign &&
        !shouldHideView &&
        field &&
        (!field.isRestrict || (field.isRestrict && isNotAlgo))
      ) {
        return option;
      }
    });
    return uniqBy(filter, 'action');
  }

  limitFileNameSize(file: string, size: number) {
    if (file) {
      const split = file.split('.');
      let filename = split[0];
      const extension = split[1];

      if (filename.length > size) {
        filename = `${filename.substring(0, size)}...`;
      }

      if (!extension) {
        return `${filename}`;
      }

      return `${filename}.${extension}`;
    }
    return file;
  }

  getStatusClass(item: any, field: any) {
    return this._getValue(item, field.fieldId).class
      ? this._getValue(item, field.fieldId).class
      : this._getValue(item, field.fieldId).text.action;
  }
}
