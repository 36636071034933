import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Authentication } from '@app/core/models/authentication.model';
import { AuthorizationService } from './authorization.service';
import { BaseAPI } from '../class/baseAPI.class';
import { HttpClient } from '@angular/common/http';

/**
 * Provides a base for authentication workflow.
 */
export class AuthenticationService extends BaseAPI {
  constructor(
    protected httpClient: HttpClient,
    private authorizationService: AuthorizationService
  ) {
    super(httpClient);
  }

  /**
   * Authenticates the user.
   * @param payload The login parameters.
   * @return The user credentials.
   */
  login(
    payload: Authentication.LoginPayload
  ): Observable<Authentication.Credentials> {
    const data = {
      grant_type: 'password',
      client_id: 'app',
      client_secret: 'app',
      username: payload.email,
      password: payload.password
    };

    const timestamp = new Date();

    return this.httpClient
      .disableAuthorization()
      .post('/oauth', data)
      .pipe(
        map((body: Authentication.Credentials) => {
          if (typeof body.access_token !== 'undefined') {
            timestamp.setTime(timestamp.getTime() + body.expires_in * 1000);

            const result: Authentication.Credentials = {
              access_token: body.access_token,
              expires_in: body.expires_in,
              expires_at: timestamp,
              token_type: body.token_type,
              scope: body.scope,
              refresh_token: body.refresh_token,
              status: 200
            };

            this.authorizationService.setCredentials(result);
            return result;
          } else {
            return {
              detail: body.detail,
              status: body.status
            };
          }
        })
      );
  }

  /**
   * Reset password of user.
   * @param payload The reset password parameters.
   * @return object The data with success
   */
  resetPassword(payload: Authentication.ResetPasswordPayload): Observable<any> {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=recovery-password', payload)
      .pipe(
        map((body: any) => {
          if (body.status === 202) {
            return {
              success: true,
              details: body.detail
            };
          } else {
            return {
              success: false,
              details: body.detail
            };
          }
        })
      );
  }

  /**
   * Forget password of user.
   * @param payload The forget password parameters.
   * @return object The data with success
   */
  forgetPassword(
    payload: Authentication.ForgetPasswordPayload
  ): Observable<Authentication.ForgetPasswordResponse> {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=forgot-password', payload)
      .pipe(
        map((body: any) => {
          if (body.status === 202) {
            return {
              success: true,
              pinPrefix: body.pinPrefix,
              token: body.token,
              details: body.detail
            };
          } else {
            return {
              success: false,
              details: body.detail
            };
          }
        })
      );
  }

  /**
   * Confirm account.
   * @param payload The confirm account parameters.
   * @return object The data with success
   */
  confirmAccount(payload: Authentication.ConfirmAccountPayload): any {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=confirm-register', payload);
  }

  getRegisterData(payload: Authentication.ConfirmAccountPayload): any {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=confirm-register-data', payload);
  }

  confirmChangeBusinessUnit(
    payload: Authentication.ConfirmAccountPayload
  ): any {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=change-business-unit', payload);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<any> {
    const token = this.authorizationService.getAccessToken();

    if (token) {
      // Revoke token
      return this.httpClient.post('/oauth/revoke', { token }).pipe(
        tap(() => {
          // Customize credentials invalidation here
          this.authorizationService.setCredentials();
          this.authorizationService.setUser();
        })
      );
    }

    return of(null);
  }

  reSendConfirmAccount(email: string): any {
    return this.httpClient
      .disableAuthorization()
      .post('/user?q=resend-confirm-register', {
        email
      });
  }
}
