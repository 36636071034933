/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./finish.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./finish.component";
import * as i5 from "../../../../core/services/authorization.service";
import * as i6 from "../../../../core/services/workflow-build.service";
var styles_FinishPageComponent = [i0.styles];
var RenderType_FinishPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinishPageComponent, data: {} });
export { RenderType_FinishPageComponent as RenderType_FinishPageComponent };
export function View_FinishPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "document-finish"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 23, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "document-hero"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Documento enviado!"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          O documento foi enviado com sucesso aos seus destinat\u00E1rios de forma\n          segura e eficiente. "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [["class", "visible-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          Aproveite para acompanhar o andamento da coleta de assinaturas\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["class", "btn btn-secondary"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Acompanhar andamento"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["style", "margin-top: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "a", [["class", "new-document"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Novo Documento"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.detailURL + _co.workflowId); _ck(_v, 17, 0, currVal_2); var currVal_5 = _co.buildURL; _ck(_v, 23, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).target; var currVal_1 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 23).target; var currVal_4 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_3, currVal_4); }); }
export function View_FinishPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workflow-finish-page", [], null, null, null, View_FinishPageComponent_0, RenderType_FinishPageComponent)), i1.ɵdid(1, 245760, null, 0, i4.FinishPageComponent, [i5.AuthorizationService, i6.WorkflowBuildService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinishPageComponentNgFactory = i1.ɵccf("app-workflow-finish-page", i4.FinishPageComponent, View_FinishPageComponent_Host_0, {}, {}, []);
export { FinishPageComponentNgFactory as FinishPageComponentNgFactory };
