import { FormBuilder, Validators } from '@angular/forms';
import { matchFields } from '@app/core/helpers';
var ChangePasswordFormComponent = /** @class */ (function () {
    function ChangePasswordFormComponent(formBuilder) {
        this.formBuilder = formBuilder;
        /**
         * Informs if loading is active or not
         */
        this.isLoading = false;
        this.createForm();
    }
    /**
     * Data selection action by clicking the form submit button
     */
    ChangePasswordFormComponent.prototype.doSubmit = function () {
        this.modal.close({
            action: 'save',
            form: 'change-password',
            formData: this.passwordForm.value
        });
    };
    Object.defineProperty(ChangePasswordFormComponent.prototype, "f", {
        /**
         *  Getter to access the form fields
         *
         *  @return AbstractControl
         */
        get: function () {
            return this.passwordForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Form creation and validation configuration
     */
    ChangePasswordFormComponent.prototype.createForm = function () {
        this.passwordForm = this.formBuilder.group({
            currentPassword: ['', [Validators.required]],
            newPassword: [
                '',
                [
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
                ]
            ],
            confirmNewPassword: [
                '',
                [
                    Validators.required,
                    Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')
                ]
            ]
        }, { validator: matchFields('newPassword', 'confirmNewPassword') });
    };
    return ChangePasswordFormComponent;
}());
export { ChangePasswordFormComponent };
