import * as tslib_1 from "tslib";
import { WorkflowDroppedField } from '../workflow-dropped-field.class';
import { WORKFLOW_STEP_FIELDS, WORKFLOW_SIGNATURE } from '@app/core/services/workflow.enum';
var EletronicSignatureField = /** @class */ (function (_super) {
    tslib_1.__extends(EletronicSignatureField, _super);
    function EletronicSignatureField(contact) {
        var _this = _super.call(this, contact) || this;
        _this.type = WORKFLOW_STEP_FIELDS.TYPE_VISIBLE_SIGNATURE;
        _this.signatureType = WORKFLOW_SIGNATURE.ELETRONIC;
        _this.minHeight = _this.height = 46;
        _this.minWidth = _this.width = 200;
        return _this;
    }
    EletronicSignatureField.prototype.isSignature = function () {
        return true;
    };
    EletronicSignatureField.prototype.shouldKeepAspectRatio = function () {
        return true;
    };
    return EletronicSignatureField;
}(WorkflowDroppedField));
export { EletronicSignatureField };
