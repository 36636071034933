import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var UserSubscription = /** @class */ (function () {
    function UserSubscription() {
        this.subject = new Subject();
    }
    UserSubscription.prototype.update = function (data) {
        this.subject.next(data);
    };
    UserSubscription.prototype.getUser = function () {
        return this.subject.asObservable();
    };
    UserSubscription.ngInjectableDef = i0.defineInjectable({ factory: function UserSubscription_Factory() { return new UserSubscription(); }, token: UserSubscription, providedIn: "root" });
    return UserSubscription;
}());
export { UserSubscription };
