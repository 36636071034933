<form (ngSubmit)="doSubmit()" [formGroup]="queryForm" novalidate>
  <div class="row">
    <div class="col-md-4 filters">
      <span class="filter-label text-nowrap"> Extrair relatório de: </span>
      <select class="form-control" formControlName="sentOrReceived">
        <option
          [value]="config.value"
          *ngFor="let config of sentOrReceivedFilter"
          >{{ config.label }}</option
        >
      </select>
    </div>

    <div class="col-12 col-md-3 filters">
      <span class="filter-label"> Filtros: </span>
      <select class="form-control" formControlName="status">
        <option [value]="config.value" *ngFor="let config of statusFilter">{{
          config.label
        }}</option>
      </select>
    </div>

    <div class="col-12 col-md-2 filters">
      <select class="form-control" formControlName="period">
        <option [value]="config.value" *ngFor="let config of periodFilter">{{
          config.label
        }}</option>
      </select>
    </div>

    <div class="col-12 col-md-3 d-flex justify-content-end">
      <button
        type="submit"
        class="btn btn-sm btn-secondary"
        [disabled]="queryForm.invalid"
      >
        Gerar relatório
      </button>
    </div>

    <div
      class="col-12 mt-3 d-flex justify-content-end"
      *ngIf="report.length > 0"
    >
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        (click)="downloadFile()"
      >
        <i class="fa fa-download"></i> Baixar relatório em CSV
      </button>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-12">
    <app-lists
      [collection]="report"
      [fields]="configList"
      [sortableFields]="sortFields"
    ></app-lists>
  </div>
</div>

<ng-template #modalLoading let-modal>
  <app-modal
    [modal]="modal"
    [type]="'loading'"
    [showHeader]="false"
    [showFooter]="false"
    [showClose]="false"
    [title]=""
    [text]="modalRef.data.text"
  >
  </app-modal>
</ng-template>
