import { Component } from '@angular/core';
import { AuthorizationService } from '@app/core/services';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html'
})
export class IntegrationComponent {
  constructor(private authorizationService: AuthorizationService) {
    this.authorizationService.isIntegration(true);
  }
}
