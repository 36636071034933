/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cookies.component";
import * as i3 from "../../../core/services/cookies.service";
var styles_CookiesComponent = [i0.styles];
var RenderType_CookiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookiesComponent, data: {} });
export { RenderType_CookiesComponent as RenderType_CookiesComponent };
export function View_CookiesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "box-cookie"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cookies"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Utilizamos os cookies para melhorar sua experi\u00EAncia. Para cumprir com a nova\n    diretiva de privacidade, n\u00F3s precisamos pedir seu consentimento para definir\n    os cookies. Confira nossa pol\u00EDtica de cookies. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [], [[8, "href", 4], [8, "download", 0], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Saiba mais!"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-white btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toAccept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fechar"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cookies.path; var currVal_1 = _co.cookies.filename; var currVal_2 = _co.cookies.title; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CookiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookies", [], null, null, null, View_CookiesComponent_0, RenderType_CookiesComponent)), i1.ɵdid(1, 114688, null, 0, i2.CookiesComponent, [i3.CookiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookiesComponentNgFactory = i1.ɵccf("app-cookies", i2.CookiesComponent, View_CookiesComponent_Host_0, {}, {}, []);
export { CookiesComponentNgFactory as CookiesComponentNgFactory };
