import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPI } from '@app/core/class/baseAPI.class';
import { HttpClient } from '@angular/common/http';
import { SIGNATURE_TYPE } from './workflow.enum';

/**
 * Provides a base for workflow.
 */
@Injectable()
export class SignatureService extends BaseAPI {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.endpoint = '/visible-signature';

    this.apiFieldsMap = {};
    this.fieldsRequired = [];
  }

  getAll(queryParams?: any): Observable<any> {
    return super.getAll(queryParams).pipe(
      map((response: any) => {
        return {
          items: response._embedded.visible_signature,
          current: this.getSignatureByType(
            response._embedded.visible_signature,
            [
              SIGNATURE_TYPE.DRAWING,
              SIGNATURE_TYPE.GENERATING,
              SIGNATURE_TYPE.UPLOADING
            ]
          ),
          rubric: this.getSignatureByType(
            response._embedded.visible_signature,
            [SIGNATURE_TYPE.RUBRIC]
          ),
          stamp: this.getSignatureByType(response._embedded.visible_signature, [
            SIGNATURE_TYPE.STAMP
          ])
        };
      })
    );
  }

  /**
   * Gets all data of a service
   */
  create(payload: any): Observable<any> {
    let timezone = 'Sao_paulo';
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(
        'O browser não suporta Intl, não foi possível detectar o timezone.'
      );
    }

    const body = {
      showBorder: 1,
      showReason: 0,
      showDate: 1,
      isDefault: true,
      dateFormat: `d/m/Y H:i:s|TZ|${timezone}`,
      handSigning: payload.name,
      handSigningType: payload.type,
      handSigningFont: payload.font,
      watermark: ''
    };

    if (
      payload.type === SIGNATURE_TYPE.DRAWING ||
      payload.type === SIGNATURE_TYPE.UPLOADING
    ) {
      body.watermark = null;
      body.handSigningFont = 'Arial.ttf';
    }
    return super.create(body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSignatureByType(signtures: any[], types: any[]) {
    return signtures.find((sign: any) => types.includes(sign.handSigningType));
  }
}
