<form class="form" novalidate [formGroup]="dataForm">
  <div class="form-row">
    <div class="form-group col-12 col-lg-6">
      <input
        placeholder="Senha"
        type="password"
        class="form-control"
        formControlName="password"
        [ngClass]="{ 'is-invalid': f.password.errors && !f.password.pristine }"
      />
      <div>
        <span class="password-message">
          Sua senha deve conter no mínimo 6 dígitos com letras maiúsculas,
          minúsculas e números.
        </span>
      </div>
      <div *ngIf="f.password.errors && !f.password.pristine">
        <span class="error" [hidden]="!f.password.errors.required">
          A senha é obrigatória
        </span>
      </div>
    </div>
    <div class="form-group col-12 col-lg-6">
      <input
        placeholder="Repetir Senha"
        type="password"
        class="form-control"
        formControlName="repeatPassword"
        [ngClass]="{
          'is-invalid': f.repeatPassword.errors && !f.repeatPassword.pristine
        }"
      />
      <div *ngIf="f.repeatPassword.errors && !f.repeatPassword.pristine">
        <span class="error" [hidden]="!f.repeatPassword.errors.unmatched">
          A confirmação deve ser igual a senha
        </span>
      </div>
    </div>
  </div>
</form>
