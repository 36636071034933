import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core/class';

const log = new Logger('Manager');

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
